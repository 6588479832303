import { KeyboardEvent, useEffect, useState } from 'react';

const KEY_CODES = {
  escape: 27,
  leftArrow: 37,
  rightArrow: 39,
};

export const ROTATIONS = {
  right: 90,
  upside: 180,
  left: 270,
};

const usePrevNextPreview = ({ open, isLoading, showNextPrevButtons, onPrevious, onNext, onClose }) => {
  const [fadeOutClass, setFadeOutClass] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [viewRotation, setViewRotation] = useState(0);

  useEffect(() => {
    if (isLoading) {
      setFadeOutClass('');
      setViewRotation(0);
    }
  }, [isLoading]);

  const rotateLeft = () => {
    const newValue = viewRotation - 90;

    setViewRotation(newValue < 0 ? ROTATIONS.left : newValue);
  };

  const rotateRight = () => {
    const newValue = viewRotation + 90;

    setViewRotation(newValue > ROTATIONS.left ? 0 : newValue);
  };

  const handleNext = () => {
    setRefresh(true);
    setViewRotation(0);
    setFadeOutClass('slide-out--right');
    setTimeout(() => {
      onNext();
      setRefresh(false);
    }, 200);
  };

  const handlePrev = () => {
    setRefresh(true);
    setViewRotation(0);
    setFadeOutClass('slide-out--left');
    setTimeout(() => {
      onPrevious();
      setRefresh(false);
    }, 200);
  };

  const onKeyDown = (e: KeyboardEvent) => {
    const { keyCode } = e;

    if (open) {
      if (showNextPrevButtons && keyCode === KEY_CODES.leftArrow) {
        handlePrev();
      } else if (showNextPrevButtons && keyCode === KEY_CODES.rightArrow) {
        handleNext();
      } else if (keyCode === KEY_CODES.escape) {
        onClose();
      }
    }
  };

  return [fadeOutClass, handleNext, handlePrev, onKeyDown, refresh, viewRotation, rotateLeft, rotateRight];
};

export default usePrevNextPreview;
