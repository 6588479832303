import React, { FC } from 'react';
import Dropzone from 'react-dropzone';

import { FileUpload } from 'assets/icons/index';
import HelperTooltip from 'modules/common/components/helperTooltip/helperTooltip.component';
import PropTypes from 'prop-types';

import { Icon, Typography } from '@material-ui/core';

import FilesAllowed from './filesAllowed.component';
import { ALLOWED_FILES_DESC } from './filesList.actions';

interface IFileDropzoneProps {
  onDrop: (files: File[]) => Promise<void>;
  onCancel?: () => void;
}

const FileDropzone: FC<IFileDropzoneProps> = ({ onDrop, onCancel }) => (
  <>
    <div className="dropzone p-3">
      <Dropzone onDrop={onDrop} onFileDialogCancel={onCancel}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="text-center svg-extra-large" id="dropzone">
              <FileUpload className="icon-dark" />
            </div>
            <Typography className="text-center" color="textSecondary" variant="caption">
              Drop your files or click here to upload
            </Typography>
          </div>
        )}
      </Dropzone>
    </div>
    <div className="p-2">
      <HelperTooltip
        title={`Allowed file types: ${ALLOWED_FILES_DESC}`}
        icon={<Icon color="disabled">help_outline</Icon>}
      >
        <FilesAllowed txt="Only listed" />
      </HelperTooltip>
    </div>
  </>
);

FileDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

FileDropzone.defaultProps = {
  onCancel: () => {},
};

export default FileDropzone;
