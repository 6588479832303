// import '@babel/polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { createMsalInstance } from 'services/azure';

import { MsalProvider } from '@azure/msal-react';
import MomentUtils from '@date-io/moment';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import App from './modules/app/components/app/app.container';
import ReduxWrapper, { store } from './modules/app/components/reduxWrapper/reduxWrapper.component';
import theme from './modules/app/config/config';
import './scss/app.scss';
import Api from './services/api';
import Sync from './services/sync';

const msalInstance = createMsalInstance();

Api.setStore(store);
Api.setAzureInstance(msalInstance);
Sync.setStore(store);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <MsalProvider instance={msalInstance}>
    <ReduxWrapper>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </ReduxWrapper>
  </MsalProvider>,
);
