import React, { PureComponent, Fragment } from 'react';

import { FindingListXLS } from 'assets/icons';
import { saveAs } from 'file-saver';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import Loader from 'modules/common/components/loader/loader.component';
import { getAllFindingsExport } from 'modules/inspections/components/inspections/inspections.actions';

import IconButton from '@material-ui/core/IconButton';

class FindingsExport extends PureComponent {
  state = {
    isFetching: false,
  };

  handleDownload = async () => {
    try {
      this.setState({ isFetching: true });

      const response = await getAllFindingsExport();
      const jsonBlob = new Blob([response], { type: 'charset=utf-8' });

      saveAs(jsonBlob, 'findings.xlsx');
      this.setState({ isFetching: false });
    } catch (err) {
      this.setState({ isFetching: false });
      genericErrorHandler(err);
    }
  };

  render() {
    const { isFetching } = this.state;
    return (
      <>
        {isFetching ? (
          <Loader />
        ) : (
          <IconButton disabled={isFetching} onClick={this.handleDownload} title="Export all Findings in MS Excel file">
            <FindingListXLS className="icon-dark" />
          </IconButton>
        )}
      </>
    );
  }
}

export default FindingsExport;
