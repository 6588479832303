import React, { Children, cloneElement, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { GlobalActionDropUp } from 'assets/icons/index';
import PropTypes from 'prop-types';

import { Dialog, InputAdornment, Icon, TextField, DialogActions, Button, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Loader from '../loader/loader.component';
import TabPanel from '../tabs/tabPanel.component';
import TabsHeader from '../tabs/tabsHeader.component';

const inputLabelProps = {
  classes: {
    focused: 'input-label',
  },
};

const styles = (theme) => ({
  action: {
    color: theme.palette.secondary.light,
  },
});

const InputModalWithTabs = ({
  className,
  tabs,
  tabNames,
  placeholder,
  id,
  value,
  error,
  errorText,
  classes,
  isLoadingIndicator,
  disabled,
  fieldName,
  clearFieldValue,
  onClick,
  ...rest
}) => {
  const { isMobile } = useSelector((state) => state.app);

  const [open, setOpen] = useState(false);
  const [tabsState, setTabsState] = useState(0);

  const inputRef = useRef(null);

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <Icon className="svg-small">
          <GlobalActionDropUp className="icon-dark" />
        </Icon>
      </InputAdornment>
    ),
    classes: {
      root: 'input-underline',
    },
    inputRef,
    autoComplete: 'off',
  };

  const loadingIndicator = {
    ...inputProps,
    endAdornment: (
      <InputAdornment position="end">
        <Loader input />
      </InputAdornment>
    ),
  };

  const inputPropsLoading = isLoadingIndicator ? loadingIndicator : inputProps;

  const handleClick = () => {
    if (disabled) {
      return;
    }

    if (onClick) {
      onClick(openDialog);
    } else {
      openDialog();
    }
  };

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);

    setTimeout(() => {
      inputRef.current.blur();
    }, 100);
  };

  const handleClearFieldValue = () => {
    if (clearFieldValue) {
      clearFieldValue(fieldName);
    }
    closeDialog();
  };

  const handleChangeTab = (event, newValue) => {
    setTabsState(newValue);
  };

  return (
    <div className={`input-modal-component mt-4 ${className}`}>
      <Dialog
        open={open}
        fullScreen={isMobile}
        fullWidth={!isMobile}
        maxWidth={!isMobile ? 'md' : undefined}
        onClose={!isMobile ? closeDialog : undefined}
        onEscapeKeyDown={!isMobile ? closeDialog : undefined}
      >
        <TabsHeader
          className="input-modal-tabs-header"
          tabNames={tabNames}
          tabsState={tabsState}
          onChangeTab={handleChangeTab}
          style={{ marginTop: '56px' }}
        />
        <Divider />
        <div style={{ overflowY: 'auto' }}>
          {tabs.map((tab, i) => (
            <TabPanel value={tabsState} index={i} key={i}>
              {Children.map(tab, (child) =>
                cloneElement(child, {
                  onClose: closeDialog,
                  fieldName,
                  isMobile,
                  value,
                  clearFieldValue: handleClearFieldValue,
                  ...rest,
                }),
              )}
            </TabPanel>
          ))}
        </div>
        {!isMobile && (
          <DialogActions>
            {handleClearFieldValue && (
              <Button onClick={handleClearFieldValue} className={classes.action}>
                Clear
              </Button>
            )}
            <Button onClick={closeDialog} className={classes.action}>
              Close
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <TextField
        className="w-100 cursor-pointer"
        InputLabelProps={inputLabelProps}
        error={error}
        helperText={errorText}
        label={placeholder}
        id={id}
        puppet-data={id}
        value={value}
        onClick={handleClick}
        InputProps={inputPropsLoading}
        readOnly
        disabled={disabled}
        multiline
      />
    </div>
  );
};

InputModalWithTabs.propTypes = {
  tabs: PropTypes.node.isRequired,
  tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  clearFieldValue: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  isLoadingIndicator: PropTypes.bool,
};

InputModalWithTabs.defaultProps = {
  value: '',
  error: false,
  errorText: '',
  className: 'w-100',
  onClick: null,
  disabled: false,
  clearFieldValue: null,
  isLoadingIndicator: false,
};

export default withStyles(styles)(InputModalWithTabs);
