import jwtDecode from 'jwt-decode';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import { USER_ROLES_ID, GLOBAL_WBS, USER_DETAILS_ID } from 'modules/app/config/config';
import { setCookie, eraseCookie } from 'modules/app/helpers/utils';
import Acl from 'services/acl';
// eslint-disable-next-line import/no-cycle
import Api from 'services/api';
import { acquireAccessToken, getToken, loginAzure, logoutAzure } from 'services/azure';
import Db from 'services/db';

const namespace = 'AUTH';
const SET_IS_LOGGED = `${namespace}_SET_IS_LOGGED`;
const LOGOUT = `${namespace}_LOGOUT`;
const SET_USER = `${namespace}_SET_USER`;
const SET_ROLES_DETAILS = `${namespace}_SET_ROLES_DETAILS`;
const SET_IS_LOGGING = `${namespace}_SET_IS_LOGGING`;
const SET_AUTH_TYPE = `${namespace}_SET_AUTH_TYPE`;

const ACTIVE_ROLE_STATUS = 'ACTIVE';

const setIsLogged = (payload) => ({
  type: SET_IS_LOGGED,
  payload,
});

const setIsLogin = (payload) => ({
  type: SET_IS_LOGGING,
  payload,
});

const setUser = (payload) => ({
  type: SET_USER,
  payload,
});

const setRolesDetails = (payload) => ({
  type: SET_ROLES_DETAILS,
  payload,
});

const getUserRoles = async (userId) => {
  if (navigator.onLine) {
    const userWbses = await Api.get(`/api/user-management/user/${userId}`);
    const details = await Api.get(`/api/user-management/user/${userId}/details`);
    const rolesDetails = await Api.get('/api/user-management/roles/details');

    const { roles, wbses } = Object.values(userWbses).reduce(
      (acc, curr) => {
        let wbsId = _get(curr, 'wbs.id');

        if (_get(curr, 'wbs.name') === GLOBAL_WBS) {
          wbsId = GLOBAL_WBS;
        }

        const role = curr.userRole;

        if (curr.status !== ACTIVE_ROLE_STATUS || !wbsId) {
          return acc;
        }

        acc.roles.push(role);

        if (acc.wbses[wbsId]) {
          acc.wbses[wbsId].push(role);
        } else {
          acc.wbses[wbsId] = [role];
        }

        return acc;
      },
      { wbses: {}, roles: [] },
    );

    const uniqRoles = _uniq(roles);

    await Db.retryUntilWritten('user_roles', USER_ROLES_ID, (obj = { _id: USER_ROLES_ID }) => ({
      ...obj,
      wbses,
      roles: uniqRoles,
      rolesDetails,
      updatedAt: new Date().toISOString(),
    }));

    await Db.retryUntilWritten('user_details', USER_DETAILS_ID, (obj = { _id: USER_DETAILS_ID }) => ({
      ...obj,
      details: details.userDetails,
      updatedAt: new Date().toISOString(),
    }));

    const isGlobalAdmin = details?.userRoleAssociations?.some((role) => role?.wbs?.name === GLOBAL_WBS);

    return {
      roles: uniqRoles,
      wbses,
      rolesDetails,
      userDetails: details.userDetails,
      isGlobalAdmin,
    };
  }

  const { roles, wbses, rolesDetails } = await Db.get('user_roles', USER_ROLES_ID);

  const { userDetails } = await Db.get('user_details', USER_DETAILS_ID);

  return { roles, wbses, rolesDetails, userDetails };
};

const afterTokenReceive = (token) =>
  async function after(dispatch) {
    const decoded = jwtDecode(token);
    Db.setUserId(decoded.oid);
    const { roles, wbses, rolesDetails, userDetails, isGlobalAdmin } = await getUserRoles(decoded.oid);
    Acl.setData(roles, wbses);

    dispatch(
      setUser({
        firstName: _get(userDetails, 'firstName', ''),
        lastName: _get(userDetails, 'lastName', ''),
        fullName: userDetails ? userDetails.fullName || userDetails.displayName : '',
        email: _get(userDetails, 'email', ''),
        id: decoded.oid,
        roles,
        isGlobalAdmin,
      }),
    );

    dispatch(setRolesDetails(rolesDetails));
  };

const login = (msalInstance) => async (dispatch) => {
  try {
    if (!navigator.onLine) {
      return;
    }
    await dispatch(setIsLogin(true));
    await loginAzure(msalInstance);
    await dispatch(setIsLogin(false));
  } catch (e) {
    console.log('login action', e);
    await dispatch(setIsLogin(false));
    await logoutAzure(msalInstance);
    throw e;
  }
};

const logout = (msalInstance) => async (dispatch) => {
  eraseCookie('redirectToPathname');
  dispatch(setIsLogged(false));
  Acl.clearData();
  await logoutAzure(msalInstance);
};

const checkIsLogged = (pathname, msalInstance) => async (dispatch) => {
  try {
    if (pathname && !['/login', '/'].includes(pathname)) {
      setCookie('redirectToPathname', pathname, 1);
    }
    const token = await acquireAccessToken(msalInstance);
    if (token.data) {
      await dispatch(afterTokenReceive(token.data));
    }
  } catch (e) {
    throw e;
  }
};

export { SET_IS_LOGGED, LOGOUT, SET_USER, SET_ROLES_DETAILS, SET_IS_LOGGING, SET_AUTH_TYPE };

export { checkIsLogged, login, logout, setIsLogged, setUser, getUserRoles };
