import React, { PureComponent } from 'react';

import _get from 'lodash/get';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import PropTypes from 'prop-types';
import notifications from 'services/notifications';

import { Dialog, TextField, Icon } from '@material-ui/core';

import AudioPlayer from '../audioPlayer/audioPlayer.component';
import FileListNames from '../filesList/FileListNames';
import { getAttachment, saveAttachmentWebWithResponse } from '../filesList/filesList.actions';
import Image from '../image/image.component';
import ImageCrop from '../imageCrop';
import Loader from '../loader/loader.component';
import SaveHeader from '../saveHeader/saveHeaderMobile.component';
import { editAttachment } from './attachmentEdit.actions';

const initialState = {
  isLoading: false,
  description: '',
  isError: '',
  imageToSave: null,
};

const MAX_DESCRIPTION_LENGTH = 2000;

class AttachmentEdit extends PureComponent {
  state = initialState;

  componentDidUpdate({ open }) {
    const { open: currentOpen } = this.props;
    if (!open && currentOpen) {
      this.handleGetAttachment();
    }
  }

  handleGetAttachment = async () => {
    try {
      const { id, description } = this.props;

      this.setState({ isLoading: true });

      const data = await getAttachment(id);
      this.setState({
        data,
        isLoading: false,
        description: description || '',
      });
    } catch (e) {
      genericErrorHandler(e);
      this.setState({ isLoading: false });
    }
  };

  handleDescriptionChange = (e) => {
    const { value } = e.target;
    const stateToSet = {
      description: value,
    };

    if (value.length > MAX_DESCRIPTION_LENGTH) {
      stateToSet.isError = `Description can have only ${MAX_DESCRIPTION_LENGTH} characters max`;
    } else {
      stateToSet.isError = '';
    }

    this.setState(stateToSet);
  };

  handleOnSave = async () => {
    try {
      const { description, data, imageToSave } = this.state;
      const { parentId, type, isWeb, fileListNames, id } = this.props;

      let attachmentData = { ...data };

      this.setState({ isLoading: true });

      if (isWeb && imageToSave) {
        try {
          const response = await fetch(imageToSave);
          const blob = await response.blob();

          const newFile = new File([blob], data.title, {
            type: 'image/png',
          });

          const newData = await saveAttachmentWebWithResponse(newFile, parentId, type, fileListNames, description);

          notifications.showSuccess('Attachment has been edited');

          attachmentData = newData;
        } catch (e) {
          genericErrorHandler(e);
        }
      }

      if (data.description !== description) {
        await editAttachment(
          id,
          {
            description,
          },
          attachmentData,
        );
      }

      this.handleOnClose();
    } catch (err) {
      genericErrorHandler(err);
    }
  };

  handleOnClose = () => {
    const { closeFn, registerSync } = this.props;
    const { data } = this.state;

    if (_get(data, 'file')) {
      registerSync();
    }

    this.setState(initialState, () => closeFn(_get(data, 'fileType')));
  };

  fetchCroppedImage = async (imageToSave) => {
    this.setState({ imageToSave });
  };

  render() {
    const { open, isWeb, id } = this.props;
    const { description, isLoading, data, isError, imageToSave } = this.state;

    const src = _get(data, 'file') || _get(data, 'path');

    const mimeType = data?.file.type;

    const isImage = mimeType?.includes('image');
    const isAudio = mimeType?.includes('audio');

    return (
      <Dialog open={open} fullScreen>
        <SaveHeader
          title={isWeb && isImage ? 'Edit picture' : 'Add Description'}
          onSave={this.handleOnSave}
          onClose={this.handleOnClose}
          disableSave={!!isError}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="edit-attachment-component content-wrapper container-wrapper" id="attachment-preview:edit">
            {isImage && (
              <>
                {isWeb && (
                  <ImageCrop
                    src={`/api/attachments/${id}/download?thumbnailType=PREVIEW`}
                    mimeType={mimeType}
                    fetchCroppedImage={this.fetchCroppedImage}
                    blockApplying={!!imageToSave}
                  />
                )}
                {!isWeb && <div className="image-wrapper">{!!src && <Image src={src} />}</div>}
              </>
            )}
            {isAudio && !!src && <AudioPlayer src={src} mimeType={mimeType} />}
            {!isImage && !isAudio && (
              <div className="icon-wrapper">
                <Icon>attachment</Icon>
              </div>
            )}
            <TextField
              className="w-100 mt-4"
              label="Description"
              multiline
              onChange={this.handleDescriptionChange}
              margin="normal"
              value={description}
              error={!!isError}
              helperText={isError}
            />
          </div>
        )}
      </Dialog>
    );
  }
}

AttachmentEdit.propTypes = {
  closeFn: PropTypes.func.isRequired,
  isWeb: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  parentId: PropTypes.string,
  registerSync: PropTypes.func,
  type: PropTypes.string,
  fileListNames: PropTypes.shape({}),
  description: PropTypes.string,
};

AttachmentEdit.defaultProps = {
  isWeb: false,
  parentId: '',
  registerSync: () => {},
  type: '',
  fileListNames: new FileListNames(),
  description: '',
};

export default AttachmentEdit;
