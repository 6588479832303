import React from 'react';

const FindingSeverityNonConformity = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fill="#F27B30"
      d="M12 20c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-18C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm-1 5h2v6h-2V7zm0 8h2v2h-2v-2z"
    />
  </svg>
);

export default FindingSeverityNonConformity;
