import Api from '../../../../services/api';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'ADMIN';
const SET_WORK_PACKAGES = `${namespace}_SET_WORK_PACKAGES`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;

const getWorkPackages =
  (id = '') =>
  async (dispatch) => {
    try {
      return id !== ''
        ? Api.get(`/api/user-management/wbs-role-users/${id}`)
        : Api.get(`/api/user-management/wbs-role-users`);

      // dispatch({
      //   type: SET_WORKPACKAGES,
      //   payload: items,
      // });
    } catch (e) {
      dispatch({
        type: SET_IS_LOADING,
        payload: false,
      });
      throw e;
    }
  };

export { getWorkPackages };

export { SET_WORK_PACKAGES, SET_IS_LOADING };
