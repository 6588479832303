import React, { PureComponent, Fragment, FC } from 'react';

import { generateFindingTitle } from 'modules/app/helpers/utils';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';

import { FINDING_TYPES } from '../../modules/findings/components/newFinding/newFinding.model';

interface DescriptionColumnFieldProps {
  description: string;
  value: string | null;
}

interface FindingDetailsFieldsProps {
  failureModeDetailsObj?: any;
  failureModeDetails?: string;
  serialNumber: string;
  location?: string;
  referenceDocument: string;
  identifier?: string;
  mainSystem?: string;
  subSystem?: string;
  findingType?: any;
  checkListElementDefinition?: any;
}

const DescriptionColumnField: FC<DescriptionColumnFieldProps> = ({ description, value }) =>
  value ? (
    <Grid container item xs={6} style={{ paddingTop: '0.5rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
      <Grid alignItems="center">
        <Typography color="textSecondary" variant="caption" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
          {description}
        </Typography>
        <Typography
          color="textSecondary"
          variant="subtitle2"
          style={{ paddingLeft: '1rem', paddingRight: '1rem', wordBreak: 'break-word' }}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  ) : null;

DescriptionColumnField.defaultProps = {
  value: null,
};

DescriptionColumnField.propTypes = {
  description: PropTypes.string.isRequired,
  value: PropTypes.string,
};

const FindingDetailsFields: FC<FindingDetailsFieldsProps> = ({
  failureModeDetailsObj,
  failureModeDetails,
  serialNumber,
  location,
  referenceDocument,
  identifier,
  mainSystem,
  subSystem,
  findingType,
  checkListElementDefinition,
}) => {
  return (
    <>
      <Typography
        color="textPrimary"
        variant="body1"
        style={{ paddingLeft: '1rem', paddingRight: '1rem', wordBreak: 'break-word', paddingTop: '1rem' }}
      >
        {`${generateFindingTitle(mainSystem, subSystem, failureModeDetailsObj, failureModeDetails)}`}
      </Typography>
      {identifier && (
        <Typography
          color="textSecondary"
          variant="caption"
          style={{ paddingLeft: '1rem', paddingRight: '1rem', wordBreak: 'break-word', paddingTop: '0.5rem' }}
        >
          {identifier}
        </Typography>
      )}
      {location && (
        <Typography
          color="textSecondary"
          variant="subtitle2"
          style={{ paddingLeft: '1rem', paddingRight: '1rem', wordBreak: 'break-word', paddingTop: '0.5rem' }}
        >
          {location}
        </Typography>
      )}

      <div className="row justify-content-between mb-4">
        {mainSystem && <DescriptionColumnField description="Main System" value={mainSystem} />}
        {subSystem && <DescriptionColumnField description="Sub System" value={subSystem} />}
        {findingType && <DescriptionColumnField description="Type" value={FINDING_TYPES[findingType].label} />}
        {failureModeDetailsObj && (
          <DescriptionColumnField description="Failure Mode" value={failureModeDetailsObj.description} />
        )}
        {failureModeDetails && <DescriptionColumnField description="Failure Mode Details" value={failureModeDetails} />}
        {serialNumber && <DescriptionColumnField description="Serial / ID Number" value={serialNumber} />}
        {referenceDocument && <DescriptionColumnField description="Reference Document" value={referenceDocument} />}
        {checkListElementDefinition?.description && (
          <DescriptionColumnField description="Checklist Element" value={checkListElementDefinition?.description} />
        )}
      </div>
    </>
  );
};

FindingDetailsFields.propTypes = {
  referenceDocument: PropTypes.string.isRequired,
  serialNumber: PropTypes.string.isRequired,
  failureModeDetails: PropTypes.string,
  identifier: PropTypes.string,
  location: PropTypes.string,
  failureModeDetailsObj: PropTypes.shape({}),
  mainSystem: PropTypes.string,
  subSystem: PropTypes.string,
};

FindingDetailsFields.defaultProps = {
  failureModeDetails: '',
  mainSystem: '',
  subSystem: '',
  failureModeDetailsObj: {},
  identifier: '',
  location: '',
};

export default FindingDetailsFields;
