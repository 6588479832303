import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { FormControl, Input, InputLabel, InputAdornment, IconButton, Icon } from '@material-ui/core';

import ChipWrapper from '../chipWrapper/chipWrapper.component';

const inputAdornmentClasses = {
  positionStart: 'inner-chips-container',
};

class Chips extends PureComponent {
  render() {
    const {
      className,
      labelClasses,
      id,
      title,
      icon,
      items,
      onIconClick,
      onItemClick,
      onItemDelete,
      itemLabelKey,
      itemAvatarKey,
      itemAvatar,
      disabled,
    } = this.props;

    return (
      <FormControl className={`chips-component ${className}`}>
        <InputLabel className={labelClasses} htmlFor={id}>
          {title}
        </InputLabel>
        <Input
          id={id}
          value=""
          disabled
          className="chips-container fake-disabled"
          startAdornment={
            <InputAdornment position="start" classes={inputAdornmentClasses}>
              {items.map((item) => {
                let avatar;

                if (itemAvatarKey && item[itemAvatarKey]) {
                  avatar = itemAvatar;
                } else if (!itemAvatarKey && itemAvatar) {
                  avatar = itemAvatar;
                }

                return (
                  <ChipWrapper
                    key={item.id}
                    item={item}
                    onClick={onItemClick}
                    onDelete={onItemDelete}
                    label={item[itemLabelKey]}
                    disabled={disabled}
                    avatar={avatar}
                  />
                );
              })}
            </InputAdornment>
          }
          endAdornment={
            icon && (
              <InputAdornment position="end">
                <IconButton puppet-data={id} onClick={onIconClick}>
                  <Icon>{icon}</Icon>
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </FormControl>
    );
  }
}

Chips.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onIconClick: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemDelete: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
  className: PropTypes.string,
  itemLabelKey: PropTypes.string,
  labelClasses: PropTypes.string,
  itemAvatarKey: PropTypes.string,
  itemAvatar: PropTypes.node,
};

Chips.defaultProps = {
  icon: '',
  onIconClick: undefined,
  onItemClick: undefined,
  onItemDelete: undefined,
  className: 'mt-4 w-100',
  itemLabelKey: 'label',
  labelClasses: '',
  itemAvatarKey: '',
  itemAvatar: null,
};

export default Chips;
