import React from 'react';

import { makeWbsName, wordCaseNormaliser } from 'modules/app/helpers/utils';
import Loader from 'modules/common/components/loader/loader.component';
import PropTypes from 'prop-types';

import { FormLabel, Typography } from '@material-ui/core';

const ChecklistDataViewer = ({ checklist, isLoading }) => {
  if (isLoading) return <Loader />;

  return (
    <>
      <div className="col-md-4 pr-md-3 mt-1">
        <FormLabel className="data-label">Park</FormLabel>
        <Typography>{makeWbsName(checklist?.park) || ''}</Typography>
      </div>
      <div className="col-md-4 pr-md-3 mt-1">
        <FormLabel className="data-label">Main system</FormLabel>
        <Typography>{checklist?.mainSystem?.description || '—'}</Typography>
      </div>
      <div className="col-md-4 pr-md-3 mt-1">
        <FormLabel className="data-label">Status</FormLabel>
        <Typography>{wordCaseNormaliser(checklist?.status)}</Typography>
      </div>
    </>
  );
};

ChecklistDataViewer.propTypes = {
  checklist: PropTypes.shape({}),
  isLoading: PropTypes.bool,
};

export default ChecklistDataViewer;
