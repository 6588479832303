import { useEffect, useState } from 'react';

import { MOBILE_BROWSER } from '../components/app/app.actions';

export const isMobile = () => !!navigator.userAgent.match(MOBILE_BROWSER);

export const isOnline = () => navigator.onLine;

export const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
};
