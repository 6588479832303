import React from 'react';

import _get from 'lodash/get';
import { DATETIME_FORMAT } from 'modules/app/config/config';
import Image from 'modules/common/components/image/image.component';
import moment from 'moment';

import { ListItemText } from '@material-ui/core';

const RELATED_FINDINGS_CONFIG = {
  columns: [
    {
      caption: 'Work package',
      dataField: 'wbs',
      sort: false,
      renderCell: (_, { inspection }) => <ListItemText secondary={_get(inspection, 'wbs', '')} />,
    },
    {
      caption: 'Failure Mode',
      dataField: 'failureMode',
      sort: false,
      renderCell: (_, { failureMode }) => <ListItemText secondary={_get(failureMode, 'description', '')} />,
    },
    {
      caption: 'Description',
      dataField: 'description',
      sort: false,
      renderCell: (_, { mainImageThumbnailPath, description }) => (
        <div className="d-flex flex-row align-items-center">
          {mainImageThumbnailPath && <Image src={mainImageThumbnailPath} className="finding-thumbnail" />}
          <ListItemText secondary={description} />
        </div>
      ),
    },
    {
      caption: 'Date',
      dataField: 'createDate',
      sort: true,
      renderCell: (_, item) => <ListItemText secondary={moment(item.createDate).format(DATETIME_FORMAT)} />,
    },
    {
      caption: 'Main System',
      dataField: 'mainSystem',
      sort: false,
      renderCell: (_, { mainSystem }) => {
        return <ListItemText secondary={_get(mainSystem, 'description', '')} />;
      },
    },
    {
      caption: 'Sub System',
      dataField: 'subSystem',
      sort: false,
      renderCell: (_, { subSystem }) => <ListItemText secondary={_get(subSystem, 'description', '')} />,
    },
  ],
  noItemsFoundText: 'No findings found',
  initialSort: {
    sortField: 'title',
    sortDirection: 'asc',
  },
};

export default RELATED_FINDINGS_CONFIG;
