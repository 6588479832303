import { SET_IS_LOGGED, LOGOUT, SET_USER, SET_ROLES_DETAILS, SET_IS_LOGGING } from './login.actions';

const initialState = {
  isLogged: false,
  isLogin: false,
  user: {},
  rolesDetails: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOGGED:
      return {
        ...state,
        isLogged: payload,
      };
    case SET_IS_LOGGING:
      return {
        ...state,
        isLogin: payload,
      };
    case LOGOUT:
      return { ...initialState };
    case SET_USER:
      return {
        ...state,
        user: payload,
        isLogged: true,
      };
    case SET_ROLES_DETAILS:
      return {
        ...state,
        rolesDetails: payload,
      };
    default:
      return state;
  }
};
