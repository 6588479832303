import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'modules/common/components/loader/loader.component';

import { Button, Typography, Toolbar } from '@material-ui/core';

import { reorderGroups } from './checklistEditor.actions';
import ChecklistGroup from './checklistGroup.component';
import NewGroup from './newGroup.component';
import { useDnDMove } from './useDnD';

const ChecklistEditor = ({ viewMode }) => {
  const dispatch = useDispatch();
  const [openGroupEditor, setOpenGroupEditor] = useState(false);
  const [scrolledId, setScrolledId] = useState('');

  const { isLoadingBuilder, currentChecklistDetails } = useSelector((state) => state.checklistEditor);

  const checklistGroups = currentChecklistDetails?.checkListGroupDefinitions || [];
  const [groups, moveGroup] = useDnDMove(checklistGroups);

  const changeOrder = () => {
    dispatch(
      reorderGroups({
        checkListDefinitionId: currentChecklistDetails?.id,
        groupsOrder: Object.fromEntries(groups.map((item, index) => [item.id, index + 1])),
      }),
    );
  };

  const handleOnAddGroup = () => {
    setOpenGroupEditor(!openGroupEditor);
  };

  const handleOnClose = (id) => {
    setOpenGroupEditor(false);
    setScrolledId(id);
  };

  if (isLoadingBuilder) return <Loader />;

  const renderGroups = groups.map((group, index) => (
    <ChecklistGroup
      key={group.id}
      id={group.id}
      moveGroup={moveGroup}
      changeOrder={changeOrder}
      index={index}
      scrolledId={scrolledId}
      viewMode={viewMode}
    />
  ));

  return (
    <>
      <Toolbar className="save-header-web-component p-0 mt-5">
        <Typography variant="h5" color="textPrimary" className="title" noWrap>
          Checklist {!viewMode ? 'builder' : 'viewer'}
        </Typography>
        <div className="grow" />
        {!viewMode && (
          <Button onClick={handleOnAddGroup} className="noTextTransform">
            Add group
          </Button>
        )}
      </Toolbar>
      {openGroupEditor && <NewGroup onClose={handleOnClose} />}
      <DndProvider backend={HTML5Backend}>{Boolean(checklistGroups.length) && renderGroups}</DndProvider>
    </>
  );
};

export default ChecklistEditor;
