import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import { DATE_FORMAT } from 'modules/app/config/config';
import DatepickerWrapper from 'modules/common/components/datepickerWrapper/datepickerWrapper.component';
import PropTypes from 'prop-types';

import { Dialog, DialogContent, DialogActions, Button, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  button: {
    color: theme.palette.secondary.light,
  },
});

const WorkflowDueDateDialog = ({ changeDueDate, createNc, workflowId, classes, history, isLoading }) => {
  const [modalOpen, setModalOpen] = useState(true);
  const [dueDate, setDueDate] = useState(null);

  const closeModal = () => setModalOpen(false);
  const onSetFieldValue = (name, value) => {
    setDueDate(value);
  };
  const handleChangeDuteDate = () => {
    changeDueDate(workflowId, dueDate);
  };
  const handleCreateNc = async () => {
    const ncId = await createNc(workflowId);
    history.push(`/workflowDetails/${ncId}`);
  };

  return (
    <Dialog open={modalOpen} maxWidth="lg" onClose={closeModal} onEscapeKeyDown={closeModal}>
      <DialogContent>
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6 border-right">
            <FormControl>
              <DatepickerWrapper
                label="Select due date"
                format={DATE_FORMAT}
                value={dueDate || null}
                onChange={onSetFieldValue}
                name="DueDate"
                disablePast
                animateYearScrolling={false}
              />
              <div className="mt-3">
                <Button
                  disabled={!dueDate || isLoading}
                  onClick={handleChangeDuteDate}
                  className={classes.button}
                  color="secondary"
                >
                  Change Due Date
                </Button>
              </div>
            </FormControl>
          </div>
          <div className="col-md-6">
            <Button onClick={handleCreateNc} className={classes.button} color="secondary" disabled={isLoading}>
              Create nonconformity
            </Button>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} className={classes.button} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WorkflowDueDateDialog.propTypes = {
  role: PropTypes.string.isRequired,
  changeDueDate: PropTypes.func.isRequired,
  createNc: PropTypes.func.isRequired,
  workflowId: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const enchanted = withStyles(styles)(WorkflowDueDateDialog);

export default withRouter(enchanted);
