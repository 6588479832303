import { MutableRefObject, useEffect } from 'react';

const useOutsideClick = <T extends HTMLElement>(ref: MutableRefObject<T>, callback: () => void) => {
  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  });
};

export { useOutsideClick };
