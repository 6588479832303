import { SET_USER, SET_USER_ROLES, CLEAR_DATA, SET_IS_LOADING } from './user.actions';

const initialState = {
  data: {},
  roles: [],
  isLoading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_USER:
      return {
        ...state,
        data: payload,
        isLoading: false,
      };
    case SET_USER_ROLES:
      return {
        ...state,
        roles: payload,
        isLoading: false,
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
