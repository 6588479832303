import React from 'react';

import { GlobalActionKeyboardArrowLeft, GlobalActionKeyboardArrowRight } from 'assets/icons';
import { bool, string, func, instanceOf, oneOfType, number } from 'prop-types';

import { IconButton, Icon } from '@material-ui/core';

import Image from '../image/image.component';
import Loader from '../loader/loader.component';

const ImageSlider = ({
  description,
  fadeOutClass,
  handleNext,
  handlePrev,
  isLoading,
  refresh,
  showNextPrevButtons,
  src,
  viewRotation,
}) => (
  <div
    className={`d-flex align-items-center justify-content-${
      showNextPrevButtons ? 'between' : 'center'
    } image-wrapper image-wrapper--WEB`}
  >
    {showNextPrevButtons && (
      <IconButton aria-label="Previous image" onClick={handlePrev}>
        <Icon className="svg-extra-large">
          <GlobalActionKeyboardArrowLeft className="icon-light--hovered" />
        </Icon>
      </IconButton>
    )}

    {!isLoading && (
      <Image src={src} alt={description} className={fadeOutClass} refresh={refresh} viewRotation={viewRotation} />
    )}
    {isLoading && <Loader />}

    {showNextPrevButtons && (
      <IconButton aria-label="Next image" onClick={handleNext}>
        <Icon className="svg-extra-large">
          <GlobalActionKeyboardArrowRight className="icon-light--hovered" />
        </Icon>
      </IconButton>
    )}
  </div>
);

ImageSlider.propTypes = {
  description: string,
  fadeOutClass: string,
  handleNext: func.isRequired,
  handlePrev: func.isRequired,
  isLoading: bool,
  refresh: bool,
  showNextPrevButtons: bool,
  src: oneOfType([instanceOf(Blob), instanceOf(ArrayBuffer), string]).isRequired,
  viewRotation: number,
};

ImageSlider.defaultProps = {
  description: '',
  fadeOutClass: '',
  isLoading: false,
  refresh: false,
  showNextPrevButtons: false,
  viewRotation: 0,
};

export default ImageSlider;
