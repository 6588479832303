import React, { PureComponent } from 'react';

import _get from 'lodash/get';

import { Snackbar, Button } from '@material-ui/core';

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

class Toast extends PureComponent {
  static toastInstance;

  static show(config) {
    if (!this.toastInstance) {
      return;
    }

    this.toastInstance.setState({ open: true, ...config });
  }

  state = {
    open: false,
    message: '',
  };

  componentDidUpdate() {
    const { setIsToastVisible } = this.props;
    const { open } = this.state;

    setIsToastVisible(open);
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.resetState();
  };

  handleButtonClick = () => {
    const { cb } = this.state;

    this.resetState();
    cb();
  };

  resetState = () => {
    this.setState({
      open: false,
      message: '',
      cb: null,
      autoHideDuration: undefined,
    });
  };

  renderButton = () => {
    const { buttonLabel } = this.state;

    return (
      <Button color="default" size="small" onClick={this.handleButtonClick}>
        {buttonLabel}
      </Button>
    );
  };

  render() {
    const { open, message, type, cb, buttonLabel, autoHideDuration = 5000, ...rest } = this.state;

    const contentProps = {
      classes: {
        root: `${type}-message`,
      },
    };

    const parsedMessage = message instanceof Error ? _get(message, 'message', message.toString()) : message;

    return (
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={this.handleClose}
        message={parsedMessage}
        ContentProps={contentProps}
        action={!!cb && this.renderButton()}
        {...rest}
      />
    );
  }
}

export default Toast;
