import React, { PureComponent, Fragment } from 'react';

import classNames from 'classnames';
import { Formik } from 'formik';
import { charsLeft } from 'modules/app/helpers/utils';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { TextField, Dialog, DialogActions, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import SaveHeader from '../../../common/components/saveHeader/saveHeaderMobile.component';

const requiredText = 'This field is required';
const schema = Yup.object().shape({
  firstName: Yup.string().min(2).max(100).required(requiredText),
  lastName: Yup.string().min(2).max(100).required(requiredText),
  company: Yup.string().max(200).required(requiredText),
  email: Yup.string().email().max(150).required(requiredText),
  phone: Yup.string()
    .matches(/^[0-9+]{0,15}$/, 'Phone number is not valid')
    .required(requiredText),
});

const emailInputProps = {
  type: 'email',
};
const phoneInputProps = {
  type: 'tel',
};

const styles = (theme) => ({
  action: {
    color: theme.palette.secondary.light,
  },
});

class CoInspectorDialog extends PureComponent {
  handleOnSubmit = (values) => {
    const { onSave } = this.props;
    onSave(values);
  };

  render() {
    const { open, onClose, item, isMobile, classes } = this.props;

    return (
      <Dialog
        open={open}
        fullScreen={isMobile}
        fullWidth={!isMobile}
        maxWidth={!isMobile ? 'sm' : undefined}
        onEscapeKeyDown={!isMobile ? onClose : undefined}
      >
        <Formik initialValues={item} onSubmit={this.handleOnSubmit} validationSchema={schema}>
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <>
              {isMobile && <SaveHeader title="Add Co-Inspector" onSave={handleSubmit} onClose={onClose} />}
              <div
                className={classNames('content-wrapper', {
                  'container-wrapper': isMobile,
                })}
              >
                <TextField
                  label="Family Name"
                  className="w-100 mt-4"
                  id="last-name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  error={!!errors.lastName && touched.lastName}
                  helperText={(touched.lastName && errors.lastName) || charsLeft(values.lastName, 100)}
                />
                <TextField
                  label="Given Name"
                  className="w-100 mt-4"
                  id="first-name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  error={!!errors.firstName && touched.firstName}
                  helperText={(touched.firstName && errors.firstName) || charsLeft(values.firstName, 100)}
                />
                <TextField
                  label="Employer"
                  className="w-100 mt-4"
                  id="company"
                  name="company"
                  value={values.company}
                  onChange={handleChange}
                  error={!!errors.company && touched.company}
                  helperText={(touched.company && errors.company) || charsLeft(values.company, 200)}
                />
                <TextField
                  label="E-mail"
                  className="w-100 mt-4"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  error={!!errors.email && touched.email}
                  helperText={touched.email && errors.email}
                  InputProps={emailInputProps}
                />
                <TextField
                  label="Phone Number"
                  className="w-100 mt-4"
                  id="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  error={!!errors.phone && touched.phone}
                  helperText={touched.phone && errors.phone}
                  InputProps={phoneInputProps}
                />
              </div>
              {!isMobile && (
                <DialogActions>
                  <Button onClick={onClose} color="secondary" className={classes.action}>
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit} color="secondary" className={classes.action}>
                    Save
                  </Button>
                </DialogActions>
              )}
            </>
          )}
        </Formik>
      </Dialog>
    );
  }
}

CoInspectorDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  isMobile: PropTypes.bool.isRequired,
};

CoInspectorDialog.defaultProps = {
  item: {
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
  },
};

export default withStyles(styles)(CoInspectorDialog);
