import React from 'react';

import Chip from 'modules/common/components/chipWrapper/chipWrapper.component';
import SimpleInputModal from 'modules/common/components/simpleInputModal/simpleInputModal.component';
import SearchUsersModal from 'modules/common/components/usersSearchModal/usersSearchModal.component';
import PropTypes from 'prop-types';

import { Icon, Typography, IconButton } from '@material-ui/core';

const contactEmailValidation = [
  {
    regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
    msg: 'Not a valid email address',
  },
  {
    maxLength: 150,
    msg: 'Provied email is longer than 150 chars',
  },
];

const RoleRow = ({
  role,
  data,
  confirmDelete,
  handleSelect,
  handleSelectMain,
  isMainRequired,
  title,
  additionalQuery,
  readOnly,
  withEmailInput,
  emails,
  addEmail,
  removeEmail,
}) => {
  const usersForMain = data.filter((u) => !u.inherited && !u.main);
  const handleAddEmail = (email) => {
    addEmail(email, role.key);
  };
  const handleRemoveEmail = (email) => {
    removeEmail(email, role.key);
  };

  const chips = data.map((u) => (
    <Chip
      item={{ ...u, role }}
      key={`${u.id}-${u.inherited}`}
      label={u.fullName}
      className="default-chip"
      clickable={false}
      variant={!u.inherited ? 'default' : 'outlined'}
      onDelete={!readOnly && !u.inherited && (!u.main || !isMainRequired) ? confirmDelete : undefined}
      icon={!readOnly && u.main && isMainRequired ? <Icon>star</Icon> : undefined}
    />
  ));

  const emailChips = emails.map((email) => (
    <Chip
      item={email}
      key={`email-role-item-${email}`}
      label={email}
      className="default-chip"
      clickable={false}
      onDelete={!readOnly ? handleRemoveEmail : undefined}
    />
  ));

  return (
    <div className="row no-gutters justify-content-between align-items-center py-3 pr-2" key={role}>
      <div className="col-12 col-lg-2">
        <Typography color="textSecondary" variant="subtitle2">
          {title}
        </Typography>
      </div>
      <div className="col-12 col-lg-10">
        <div className="row no-gutters">
          <div className="col-2 d-flex justify-content-end pr-2">
            {!readOnly && isMainRequired && (
              <SearchUsersModal users={usersForMain} onSelect={handleSelectMain} extraData={role} local>
                <IconButton>
                  <Icon>star</Icon>
                </IconButton>
              </SearchUsersModal>
            )}
            <SearchUsersModal onSelect={handleSelect} extraData={role} additionalQueryParams={additionalQuery}>
              <IconButton disabled={readOnly}>
                <Icon>person_add_outline</Icon>
              </IconButton>
            </SearchUsersModal>
          </div>
          <div className="col-10">{chips}</div>
        </div>
        {withEmailInput && (
          <div className="row no-gutters">
            <div className="col-2 d-flex justify-content-end pr-2">
              <SimpleInputModal
                label="Contact email"
                name="email"
                placeholderText="Type contact email"
                submitFn={handleAddEmail}
                title="Add contact email"
                validationRules={contactEmailValidation}
              >
                <IconButton disabled={readOnly}>
                  <Icon>contact_mail</Icon>
                </IconButton>
              </SimpleInputModal>
            </div>
            <div className="col-10 border-top pt-2">{emailChips}</div>
          </div>
        )}
      </div>
    </div>
  );
};

RoleRow.propTypes = {
  role: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMainRequired: PropTypes.bool,
  confirmDelete: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleSelectMain: PropTypes.func.isRequired,
  additionalQuery: PropTypes.shape({}),
  readOnly: PropTypes.bool,
  withEmailInput: PropTypes.bool,
  emails: PropTypes.arrayOf(PropTypes.string),
  addEmail: PropTypes.func,
  removeEmail: PropTypes.func,
};

RoleRow.defaultProps = {
  additionalQuery: null,
  readOnly: false,
  withEmailInput: false,
  emails: [],
  addEmail: () => {},
  removeEmail: () => {},
  isMainRequired: false,
};

export default RoleRow;
