import React, { Fragment } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import { WORKFLOWS_TYPES } from 'modules/app/config/config';
import Table from 'modules/common/components/table';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

import RELATED_FINDINGS_CONFIG from './workflowFindings.config';

const FindingsList = ({ selectedIds, history, findings, isLoading, handleRowCheck, withCheckboxes, totalCount }) => {
  const { type } = useParams();

  const redirectToFinding = (_, { inspection: { id: inspectionId }, id: findingId }) => {
    history.push(`/inspectionDetails/${inspectionId}/findingDetails/${findingId}`, {
      prevPath: history.location.pathname,
    });
  };

  const AUGMENTED_CONFIG = {
    ...RELATED_FINDINGS_CONFIG,
  };

  if (type.toUpperCase() === WORKFLOWS_TYPES.defect_notification) {
    AUGMENTED_CONFIG.columns[4].caption = 'Main systems';
  }

  return (
    <>
      <div className="col-md-12 mt-4">
        <Typography variant="h5" color="textPrimary" style={{ fontSize: '1.25rem' }}>
          Related Findings:
        </Typography>
      </div>
      <div className="col-md-12 mt-3 border-top">
        <Table
          className="mt-3"
          isLoading={isLoading}
          totalCount={totalCount}
          config={RELATED_FINDINGS_CONFIG}
          items={findings}
          onRowClick={redirectToFinding}
          withCheckboxes={withCheckboxes}
          selectedIds={selectedIds}
          handleRowCheck={handleRowCheck}
          pagination
          hideSelectAll
        />
      </div>
    </>
  );
};

FindingsList.propTypes = {
  findings: PropTypes.arrayOf(PropTypes.shape).isRequired,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.shape({}).isRequired,
  withCheckboxes: PropTypes.bool,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  totalCount: PropTypes.number.isRequired,
  handleRowCheck: PropTypes.func,
};

FindingsList.defaultProps = {
  withCheckboxes: false,
  selectedIds: [],
  handleRowCheck: () => {},
};

export default withRouter(FindingsList);
