import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import SignaturePad from 'signature_pad/dist/signature_pad.umd';

import { Dialog, Switch, FormControlLabel, Typography, Divider } from '@material-ui/core';

import SaveHeader from '../../../common/components/saveHeader/saveHeaderMobile.component';

class SignModal extends PureComponent {
  state = {
    absent: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.resizeCanvas);
  }

  componentDidUpdate({ open: prevOpen }) {
    const { open, signData } = this.props;

    if (!prevOpen && open) {
      this.setState({ absent: !!signData.absent });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeCanvas);
  }

  setContainerRef = (ref) => {
    if (!ref) {
      return;
    }

    this.containerRef = ref;
    this.resizeCanvas();
  };

  setCanvasRef = (ref) => {
    if (!ref) {
      return;
    }

    const { absent } = this.state;

    this.canvasRef = ref;
    this.signaturePad = new SignaturePad(ref);
    this.resizeCanvas();

    if (absent) {
      this.disableSignaturePad();
    }
  };

  resizeCanvas = () => {
    if (!this.containerRef) {
      return;
    }

    setTimeout(() => {
      this.canvasRef.width = this.containerRef.offsetWidth;
      this.canvasRef.height = this.containerRef.offsetHeight;
    }, 100);
  };

  handleOnClose = () => {
    const { onClose } = this.props;
    this.signaturePad.clear();
    onClose();
  };

  handleOnSave = () => {
    const { onSave, inspector, signData = {} } = this.props;
    const { absent } = this.state;

    let data = {
      ...signData,
      inspectorId: inspector.id,
      leadInspector: !!inspector.leadInspector,
      absent,
    };

    if (!this.signaturePad.isEmpty()) {
      data.imageBytes = this.signaturePad.toDataURL();
      data.mimeType = 'image/png';
    } else if (!absent && !data.imageBytes) {
      data = null;
    }

    onSave(data);
    this.signaturePad.clear();
  };

  handleSwitchChange = ({ target }) => {
    const absent = target.checked;

    if (absent) {
      this.disableSignaturePad();
    } else {
      this.signaturePad.on();
    }

    this.setState({ absent });
  };

  disableSignaturePad = () => {
    if (!this.signaturePad) {
      return;
    }

    this.signaturePad.clear();
    this.signaturePad.off();
  };

  getSwitch = (checked) => (
    <Switch checked={checked} name="checkAbsent" onChange={this.handleSwitchChange} color="secondary" />
  );

  render() {
    const { open, inspector, inspection } = this.props;
    const { absent } = this.state;

    return (
      <Dialog open={open} fullScreen>
        <SaveHeader title="Inspection Signature" onSave={this.handleOnSave} onClose={this.handleOnClose} />
        <div className="container-wrapper sign-modal-component p-3">
          <div className="content-container">
            <div className="row no-gutters pb-3 justify-content-between">
              <div>
                <Typography variant="h6">{inspection.title}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {inspection.wbs}
                </Typography>
              </div>
              <FormControlLabel control={this.getSwitch(absent)} label="Absent" labelPlacement="start" />
            </div>
            <div className="sign-pad-container px-3">
              <Typography variant="body1" color="textSecondary" className="pb-1 pt-3">
                {inspector.fullName}
              </Typography>
              <div ref={this.setContainerRef} className="sign-pad">
                <canvas ref={this.setCanvasRef} />
              </div>
              <Divider className="mt-1" />
              <Typography variant="caption" color="textSecondary" className="py-1">
                Sign with your finger in this field
              </Typography>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

SignModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  inspector: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
  }),
  inspection: PropTypes.shape({
    title: PropTypes.string,
    wbs: PropTypes.string,
  }),
  signData: PropTypes.shape({
    imageBytes: PropTypes.string,
    inspectorId: PropTypes.string,
    mimeType: PropTypes.string,
    leadInspector: PropTypes.bool,
    absent: PropTypes.bool,
  }),
};

SignModal.defaultProps = {
  inspector: {},
  inspection: {},
  signData: {},
};

export default SignModal;
