import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from 'modules/common/components/loader/loader.component';

import { Paper, Tab, Tabs, Tooltip, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FiltersContext } from '../../filters.context';
import { GlobalFilterReducerType } from '../../global.reducer';
import SwitchActiveTab from './activeTab';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'white',
  },
  tabs: {
    position: 'absolute',
    width: '100%',
    marginTop: '-20px',
    zIndex: 999,
  },
}));

const FilterTabs: FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { items, isLoading } = useSelector((state: { filtersConfig: GlobalFilterReducerType }) => state.filtersConfig);

  const classes = useStyles();

  const { state, config } = useContext(FiltersContext);

  const handleChangeTab = (e: ChangeEvent, newTab: number) => {
    e.preventDefault();

    setActiveTab(newTab);
  };

  const renderTabs = Object.keys(config.filters)
    .sort((a, b) => {
      if (config.filtersOrderIndex) {
        return config.filtersOrderIndex[a] - config.filtersOrderIndex[b];
      }
      return a.localeCompare(b);
    })
    .map((key) => {
      const prepareLabel = (() => {
        if (key in config.chipLabels) {
          return `${config.chipLabels?.[key]} ${
            state.filters?.[key] && state.filters?.[key].length > 0 ? `(${state.filters?.[key].length})` : ''
          } `;
        }
        return `${key} ${
          state.filters?.[key] && state.filters?.[key].length > 0 ? `(${state.filters?.[key].length})` : ''
        } `;
      })();

      const isTabDisabled = (() => {
        if (config.filters?.[key]?.dependsOn) {
          return state.filters[config.filters?.[key]?.dependsOn].length === 0;
        }

        return false;
      })();

      if (config.filters?.[key].disabledTooltip && isTabDisabled) {
        return (
          <Tooltip title={config.filters?.[key].disabledTooltip} key={key}>
            <span>
              <Tab label={prepareLabel} key={key} disabled={isTabDisabled} />
            </span>
          </Tooltip>
        );
      }

      return <Tab label={prepareLabel} key={key} disabled={isTabDisabled} />;
    });

  const breakpoint = useMediaQuery('(min-width: 1400px)');

  return (
    <div className={classes.root}>
      <Paper square>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          indicatorColor="secondary"
          textColor="secondary"
          variant={breakpoint ? 'fullWidth' : 'scrollable'}
          scrollButtons="auto"
          centered
        >
          {renderTabs}
        </Tabs>
      </Paper>
      {!isLoading && <SwitchActiveTab activeTab={activeTab} nodes={items} />}
      {isLoading && <Loader inline mask />}
    </div>
  );
};

export default FilterTabs;
