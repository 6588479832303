import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import Acl from 'services/acl';

const checkAccess = (WrappedComponent, privilege) =>
  class CheckAccess extends PureComponent {
    noAccessRedirect = () => <Redirect to="/noAccess" />;

    render() {
      if (Acl.hasPrivilege(privilege)) {
        return <WrappedComponent {...this.props} noAccessRedirect={this.noAccessRedirect} />;
      }

      return this.noAccessRedirect();
    }
  };

export default checkAccess;
