import React from 'react';
import { FilteringContainer } from 'react-virtualized-tree';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ListItemText } from '@material-ui/core';

import ListItemWrapper from '../listItemWrapper/listItemWrapper.component';
import SearchHeader from '../searchHeader/searchHeader.container';

const nameMatchesSearchTerm =
  (searchTerm, primaryTextField, secondaryTextField = '') =>
  (node) => {
    const upperCasePrimary = (node[primaryTextField] && `${node[primaryTextField]}`?.toUpperCase()) || '';
    const upperCaseSearchTerm = searchTerm.toUpperCase();

    if (secondaryTextField) {
      const upperCaseSecondary = (node[secondaryTextField] && `${node[secondaryTextField]}`?.toUpperCase()) || '';
      return (
        upperCasePrimary.indexOf(upperCaseSearchTerm.trim()) > -1 ||
        upperCaseSecondary.indexOf(upperCaseSearchTerm.trim()) > -1
      );
    }

    return upperCasePrimary.indexOf(upperCaseSearchTerm.trim()) > -1;
  };

const INITIAL_FILTERED_VALUE = { nodes: [], nodeParentMappings: {} };

const filterNodes = (filter, nodes) => {
  const parents = nodes.length > 2 && nodes[2] !== undefined ? nodes[2] : [];

  return nodes.reduce((filtered, n) => {
    const _extends2 = {
      [n.id]: parents,
    };

    const _ref = n.children ? filterNodes(filter, n.children) : INITIAL_FILTERED_VALUE;
    const filteredChildren = _ref.nodes;
    const childrenNodeMappings = _ref.nodeParentMappings;

    if (!(filter(n) || filteredChildren.length)) {
      return filtered;
    }

    return {
      nodes: [].concat(filtered.nodes, [
        {
          ...n,
          children: filteredChildren,
          state: {
            expanded: true,
          },
        },
      ]),
      nodeParentMappings: {
        ...filtered.nodeParentMappings,
        ...childrenNodeMappings,
        ..._extends2,
      },
    };
  }, INITIAL_FILTERED_VALUE);
};

export default class ModalFilteringContainer extends FilteringContainer {
  render() {
    const { filterTerm = '', filterText } = this.state;
    const {
      nodes,
      children: treeRenderer,
      groups,
      selectedGroup,
      groupRenderer: GroupRenderer,
      onSelectedGroupChange,
      searchPlaceholder,
      onClose,
      primaryTextField,
      secondaryTextField,
      searchEnabled,
      isMobile,
      clearFieldValue,
      value,
      searchPosition,
    } = this.props;

    const relevantNodes =
      groups && selectedGroup && groups[selectedGroup]
        ? filterNodes(groups[selectedGroup].filter, nodes)
        : { nodes, nodeParentMappings: [] };

    const trimmedFilterTerm = filterTerm.trim();
    const { nodes: filteredNodes, nodeParentMappings } = trimmedFilterTerm
      ? filterNodes(nameMatchesSearchTerm(trimmedFilterTerm, primaryTextField, secondaryTextField), relevantNodes.nodes)
      : relevantNodes;

    return (
      <div className={`tree-filter-component content-wrapper-no-padding ${searchPosition}`}>
        <div className={classNames('tree-lookup-input', { group: !!groups })}>
          {searchEnabled && (
            <SearchHeader
              onChange={this.handleFilterTextChange}
              onClose={onClose}
              value={filterText}
              placeholder={searchPlaceholder}
              position={searchPosition}
            />
          )}
          {isMobile && value && (
            <div className="list-item flex-nowrap">
              <ListItemWrapper onClick={clearFieldValue} className="display-grid" button>
                <ListItemText className="list-item-text" primary="Clear current selection" />
              </ListItemWrapper>
            </div>
          )}
          {groups && <GroupRenderer groups={groups} selectedGroup={selectedGroup} onChange={onSelectedGroupChange} />}
        </div>
        {treeRenderer({ nodes: filteredNodes, nodeParentMappings })}
      </div>
    );
  }
}

FilteringContainer.propTypes = {
  children: PropTypes.func.isRequired,
  debouncer: PropTypes.func,
  selectedGroup: PropTypes.string,
  groupRenderer: PropTypes.func,
  onSelectedGroupChange: PropTypes.func,
  primaryTextField: PropTypes.string.isRequired,
  secondaryTextField: PropTypes.string,
  searchEnabled: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  clearFieldValue: PropTypes.func.isRequired,
  value: PropTypes.string,
};
