import React from 'react';
import { withRouter } from 'react-router-dom';

import { DATETIME_FORMAT, WORKFLOWS_TYPES } from 'modules/app/config/config';
import Table, { TableUniqueKey } from 'modules/common/components/table';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ListItemText, Typography } from '@material-ui/core';

const RELATED_FINDINGS_CONFIG = {
  columns: [
    {
      caption: 'Work package',
      dataField: 'wbs',
      sort: false,
      align: 'left',
      renderCell: (_, { wbs, parkName }) => (
        <ListItemText className="text-nowrap" secondary={parkName ? `${parkName} - ${wbs}` : wbs} />
      ),
    },
    {
      caption: 'Location',
      dataField: 'location',
      align: 'center',
      sort: false,
    },
    {
      caption: 'Failure Mode',
      dataField: 'failureMode',
      sort: false,
      align: 'center',
    },
    {
      caption: 'Main System',
      dataField: 'mainSystem',
      sort: false,
      align: 'center',
      renderCell: (_, { mainSystem }) => {
        return (
          <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
            {mainSystem}
          </Typography>
        );
      },
    },
    {
      caption: 'ReportedDate',
      dataField: 'createDate',
      align: 'right',
      renderCell: (_, item) => (
        <ListItemText className="text-nowrap" secondary={moment(item.reportedDate).format(DATETIME_FORMAT)} />
      ),
    },
  ],
  noItemsFoundText: 'No findings found',
  initialSort: {
    sortField: 'title',
    sortDirection: 'asc',
  },
};

const FindingsList = ({ findings, history, isContractor, type }) => {
  const redirectToFinding = (_, { inspectionId, id: findingId }) => {
    const url = isContractor
      ? `/relatedInspection/${inspectionId}/relatedFinding/${findingId}`
      : `/inspectionDetails/${inspectionId}/findingDetails/${findingId}`;

    history.push(url, { prevPath: history.location.pathname });
  };

  const AUGMENTED_CONFIG = {
    ...RELATED_FINDINGS_CONFIG,
  };

  if (type?.toUpperCase() === WORKFLOWS_TYPES.defect_notification) {
    AUGMENTED_CONFIG.columns[3].caption = 'Main systems';
  }

  return (
    <Table
      className="workflow-related-findings-component"
      config={AUGMENTED_CONFIG}
      items={findings}
      onRowClick={redirectToFinding}
      tableUniqueKey={TableUniqueKey.RELATED_FINDINGS}
    />
  );
};

FindingsList.propTypes = {
  findings: PropTypes.arrayOf(PropTypes.shape),
  history: PropTypes.shape({}).isRequired,
  isContractor: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

FindingsList.defaultProps = {
  findings: [],
  isContractor: false,
};

export default withRouter(FindingsList);
