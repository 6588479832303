import { SET_WORKFLOWS, CLEAR_DATA, SET_IS_LOADING, SET_IS_ERROR } from './workflows.actions';

const initialState = {
  items: [],
  totalCount: 0,
  isLoading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_WORKFLOWS:
      return {
        ...state,
        items: payload.content,
        totalCount: payload.totalElements,
        isLoading: false,
      };
    case CLEAR_DATA:
    case SET_IS_ERROR:
      return {
        ...initialState,
        isLoading: false,
      };
    default:
      return state;
  }
};
