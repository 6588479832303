import React, { FC, useContext } from 'react';

import _get from 'lodash/get';

import { Checkbox, Icon, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';

import { validateTableItem } from './table.actions';
import { TableContext } from './table.context';
import BodyCell from './tableBodyCell.component';

const renderDefaultCell = (value) => (
  <Typography color="textSecondary" variant="caption">
    {value}
  </Typography>
);

interface ITableBodyRowProps {
  config: any[];
  item: any;
  idProperty: string;
  onRowClick: (e: any, item: any) => void;
  withCheckboxes: boolean;
  marked: boolean;
  withMarkedRows: boolean;
  isChecked: boolean;
  handleRowCheck: (id: string) => void;
  disableFromRowSelecting: any[];
  skipCells: string[];
  isChecklistTable: boolean;
}

const TableBodyRow: FC<ITableBodyRowProps> = ({
  config,
  idProperty,
  item,
  onRowClick,
  handleRowCheck,
  withCheckboxes,
  disableFromRowSelecting = [],
  isChecked,
  skipCells = [],
  marked = false,
  isChecklistTable,
  withMarkedRows,
}) => {
  const { columnsConfig } = useContext(TableContext);

  const isHidden = (dataField: string) => {
    const column = columnsConfig.find((conf) => conf.dataField === dataField);

    return column?.showed;
  };

  const handleRowClick = (e) => {
    if (typeof onRowClick === 'function') {
      return onRowClick(e, item);
    }
    return false;
  };

  const onRowCheck = (e) => {
    e.stopPropagation();
    handleRowCheck(e.currentTarget.id);
  };

  const disableRowSelect = () => {
    if (!disableFromRowSelecting.length) {
      return false;
    }

    return validateTableItem(disableFromRowSelecting, item);
  };

  const renderRowCells = config
    .filter((conf) => !skipCells.includes(conf.dataField))
    .filter((conf) => isHidden(conf.dataField))
    .map((conf) => {
      const value = _get(item, conf.dataField);
      const cell = conf.renderCell ? conf.renderCell(value, item, withMarkedRows) : renderDefaultCell(value);

      return (
        <BodyCell
          onClick={handleRowClick}
          key={`${item.id}-${conf.dataField}`}
          padding={conf.padding}
          align={conf.align}
        >
          {cell}
        </BodyCell>
      );
    });

  const isClickable = typeof onRowClick === 'function';
  const rowId = _get(item, idProperty);
  const isRowCheckDisabled = disableRowSelect();

  const checklistTableFirstCell =
    item.status === 'DRAFT' ? (
      <TableCell padding="checkbox">
        <IconButton onClick={handleRowClick}>
          <Icon className="icon-dark">edit</Icon>
        </IconButton>
      </TableCell>
    ) : (
      <TableCell>
        <div />
      </TableCell>
    );

  return (
    <TableRow hover={isClickable} style={{ background: marked ? 'antiquewhite' : 'initial' }}>
      {withCheckboxes && (
        <TableCell padding="checkbox">
          <Checkbox id={rowId} checked={isChecked} onChange={onRowCheck} disabled={isRowCheckDisabled} />
        </TableCell>
      )}
      {isChecklistTable && checklistTableFirstCell}
      {renderRowCells}
    </TableRow>
  );
};

export default TableBodyRow;
