import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DATE_FORMAT } from 'modules/app/config/config';
import DatepickerWrapper from 'modules/common/components/datepickerWrapper/datepickerWrapper.component';
import { changeDueDate } from 'modules/workflows/components/workflowDetails/workflowDetails.actions';
import {
  getCurrentWorkflowRole,
  getObservationDueDate,
} from 'modules/workflows/components/workflowDetails/workflowDetails.selectors';
import PropTypes from 'prop-types';

import { Dialog, DialogContent, DialogActions, Button, FormControl, MenuItem, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const contractorRole = 'CONTRACTOR';

const styles = (theme) => ({
  button: {
    color: theme.palette.secondary.light,
  },
});

export const ChangeDueDateComponent = ({ workflowId, classes }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [dueDate, setDueDate] = useState(null);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.workflowDetails.isLoading);
  const role = useSelector((state) => getCurrentWorkflowRole(state));
  const usedDueDate = useSelector((state) => getObservationDueDate(state));

  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);
  const onSetFieldValue = (name, value) => {
    setDueDate(value);
  };
  const handleChangeDuteDate = () => {
    dispatch(changeDueDate(workflowId, dueDate));
  };

  return (
    <>
      {role && role !== contractorRole && (
        <MenuItem onClick={openModal}>
          <Icon className="mr-2">calendar_today</Icon>
          Change Due Date
        </MenuItem>
      )}
      <Dialog open={modalOpen} maxWidth="lg" onClose={closeModal} onEscapeKeyDown={closeModal}>
        <DialogContent>
          <div className="row justify-content-between align-items-center">
            <FormControl>
              <DatepickerWrapper
                label="Select due date"
                format={DATE_FORMAT}
                value={dueDate || usedDueDate}
                onChange={onSetFieldValue}
                name="DueDate"
                disablePast
                animateYearScrolling={false}
              />
              <div className="mt-3">
                <Button
                  disabled={!dueDate || isLoading}
                  onClick={handleChangeDuteDate}
                  className={classes.button}
                  color="secondary"
                >
                  Change Due Date
                </Button>
              </div>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} className={classes.button} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ChangeDueDateComponent.propTypes = {
  workflowId: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ChangeDueDateComponent);
