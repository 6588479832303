import React, { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../common/components/loader/loader.component';
import { handleElementChange } from './checklist.actions';
import ChecklistGroup from './partials/checklistGroup.component';

interface ChecklistProps {
  canModify: boolean;
  goToFindingAdd: (e: SyntheticEvent, checklist: any) => void;
  scrolledId: string;
  onClose?: () => void;
}

const Checklist = ({ goToFindingAdd, canModify = false, scrolledId = '', onClose }: ChecklistProps) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state: IStore) => state.checklists.isLoading);
  const { checklist } = useSelector((state: IStore) => state.checklists.currentChecklistDetails);

  // recursive function passed down in component tree with nest level indicator
  const renderGroup =
    (index = 0) =>
    // eslint-disable-next-line react/no-unstable-nested-components
    (group) => {
      const expanded = group.checkListElementDefinitions.find((element) => !element.isChecked);

      const onElementCheck = (element) => () => {
        dispatch(handleElementChange(element));
      };

      return (
        <ChecklistGroup
          checklist={group}
          key={group.id}
          checked={!expanded}
          onClose={onClose}
          onElementCheck={onElementCheck}
          onAddFindingClick={goToFindingAdd}
          defaultExpanded={!!expanded}
          // renderGroup is passed  along with nest level index
          renderGroup={renderGroup(index + 1)}
          index={index}
          canModify={canModify}
          scrolledId={scrolledId}
        />
      );
    };

  // 0 level nest renderGroupCall
  const renderGroups = checklist?.map((checklistGroup) => renderGroup()(checklistGroup));

  return (
    <>
      {isLoading && <Loader mask />}
      {renderGroups}
    </>
  );
};

export default Checklist;
