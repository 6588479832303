import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';

import { saveAs } from 'file-saver';
import isArray from 'lodash/isArray';
import { FILE_PARENTS, INTERNAL_ERROR_CODE, ONLY_FILES_ATTACHED_MSG } from 'modules/app/config/config';
import Loader from 'modules/common/components/loader/loader.component';
import Api from 'services/api';
import Notifications from 'services/notifications';

import { Grid, Typography } from '@material-ui/core';

import AttachmentEdit from '../attachmentEdit/attachmentEdit.component';
import AttachmentPreviewWeb from '../attachmentPreview/attachmentPreviewWeb.component';
import { UploadWarningMsg } from '../fileUpload/fileUpload.component';
import FileListNames from './FileListNames';
import FileDropzone from './fileDropzone.component';
import FileRow from './fileRow.component';
import { getAttachmentsWeb, groupAttachmentsByType, removeAttachment, saveAttachmentsWeb } from './filesList.actions';
import ImagesFiles from './imagesFiles.component';

interface IAttachmentWebProps {
  id: string | string[];
  type: string;
  title: string | null;
  canEdit: boolean;
  local: boolean;
  data: any[];
  isLoading: boolean;
  callbackFn: () => Promise<void> | null;
  warningMsg: string;
  groupedByMainSystem: boolean;
}

const AttachmentWeb: FC<IAttachmentWebProps> = ({
  id,
  type,
  title = null,
  canEdit = true,
  local = false,
  data = [],
  isLoading = false,
  callbackFn = null,
  warningMsg = '',
  groupedByMainSystem = false,
}) => {
  const [state, setState] = useState({
    photos: [],
    audios: [],
    docs: [],
    previewOpen: false,
    editOpen: false,
    isLoading: false,
    selectedFile: null,
  });

  const ref = useRef(null);

  const fileListNames = new FileListNames();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (type === FILE_PARENTS.findingsWorkflow) {
      getRemoteData();
    }
  }, [id, type]);

  const getData = async (firstTime = true) => {
    setState((state) => ({
      ...state,
      isLoading: false,
    }));

    if (local) {
      if (callbackFn && !firstTime) {
        await callbackFn();
      }

      return getLocalData();
    }

    return getRemoteData(firstTime);
  };

  const getLocalData = () => {
    if (data && data.length) {
      data.forEach((file) => {
        fileListNames.setNameAndIncrement(file.title);
      });
    }

    setState((state) => ({
      ...state,
      isLoading: false,
      ...groupAttachmentsByType(data),
    }));
  };

  const getRemoteData = async (firstTime = false) => {
    try {
      setState((state) => ({ ...state, isLoading: true }));

      let files;

      if (type === FILE_PARENTS.findingsWorkflow) {
        files = await addMultipleFindingsFiles(firstTime);
      } else {
        files = await getAttachmentsWeb(id, type, fileListNames, firstTime);
      }

      setState((state) => ({
        ...state,
        isLoading: false,
        ...files,
      }));
    } catch (err) {
      Notifications.showError(err);
      setState((state) => ({ ...state, isLoading: false }));
    }
  };

  const addMultipleFindingsFiles = async (firstTime = false) => {
    const files = {
      photos: [],
      audios: [],
      docs: [],
    };

    for (const fileId of id) {
      const newFiles = await getAttachmentsWeb(fileId, FILE_PARENTS.finding, fileListNames, firstTime);

      Object.keys(newFiles).forEach((group) => {
        files[group] = [...files[group], ...newFiles[group]];
      });
    }

    return files;
  };

  const onDrop = async (files: File[]) => {
    try {
      const filesWithContent = files.filter((file) => file.size > 0);

      if (filesWithContent.length) {
        setState((state) => ({ ...state, isLoading: true }));

        try {
          await saveAttachmentsWeb(filesWithContent, id, type, fileListNames);
        } catch (e) {
          if (isArray(e)) {
            const internalErrors = e.filter((i) => i.code === INTERNAL_ERROR_CODE);

            if (internalErrors.length && files.length !== internalErrors.length) {
              Notifications.showError(e);
            } else {
              throw e;
            }
          } else {
            throw e;
          }
        }

        if (callbackFn) {
          await callbackFn();
        }

        getData(false);
      }

      if (filesWithContent.length !== files.length) {
        throw new Error('Cannot add empty files');
      }
    } catch (err) {
      Notifications.showError(err);
      setState((state) => ({ ...state, isLoading: false }));
    }
  };

  const openPreview = (e: MouseEvent<HTMLDivElement>) => {
    const {
      currentTarget: { id },
    } = e;

    setState((state) => ({
      ...state,
      selectedFile: id,
      previewOpen: true,
    }));
  };

  const closePreview = (reload: boolean) => {
    setState((state) => ({
      ...state,
      previewOpen: false,
    }));

    if (reload) {
      getData();
    }
  };

  const openEdit = () => {
    setState((state) => ({
      ...state,
      editOpen: true,
    }));
  };

  const closeEdit = () => {
    setState((state) => ({
      ...state,
      editOpen: false,
    }));

    getData();
  };

  const onRemoveFile = async (e: MouseEvent, params: { id: string }) => {
    try {
      setState((state) => ({ ...state, isLoading: true }));

      await removeAttachment(params.id);
      getData();
    } catch (err) {
      setState((state) => ({ ...state, isLoading: false }));
      Notifications.showError(err);
    }
  };

  const handleDownload = async (e: MouseEvent<HTMLLIElement>) => {
    try {
      const { currentTarget } = e;
      const title = currentTarget.getAttribute('data-title');
      const path = currentTarget.getAttribute('data-path');

      const response = await Api.get(`/api/attachments/${path}/download`, null, 'blob');
      const jsonBlob = new Blob([response], { type: 'charset=utf-8' });
      saveAs(jsonBlob, title);
    } catch (err) {
      Notifications.showError(err);
    }
  };

  const goPrevious = () => {
    const { photos, selectedFile } = state;

    const index = photos.findIndex((file) => file.id === selectedFile);
    const prevIndex = index - 1;
    let prevId;

    if (prevIndex === -1) {
      prevId = photos[photos.length - 1].id;
    } else {
      prevId = photos[prevIndex].id;
    }

    setState((state) => ({ ...state, selectedFile: prevId }));
  };

  const goNext = () => {
    const { photos, selectedFile } = state;

    const index = photos.findIndex((file) => file.id === selectedFile);
    const nextIndex = index + 1;
    let nextId;

    if (nextIndex > photos.length - 1) {
      nextId = photos[0].id;
    } else {
      nextId = photos[nextIndex].id;
    }

    setState((state) => ({ ...state, selectedFile: nextId }));
  };

  const { photos, audios, docs, isLoading: isLoadingState, previewOpen, editOpen, selectedFile } = state;

  const withPhotos = photos.length > 0;
  const withAudios = audios.length > 0;
  const withDocs = docs.length > 0;

  if (!withPhotos && !withAudios && !withDocs && !canEdit) {
    return null;
  }

  const isWorkflow = [FILE_PARENTS.workflow, FILE_PARENTS.workflowPart].includes(type);
  const showNextPrevButtons = photos.length > 1;
  const selectedPhoto = photos.find((photo) => photo.id === selectedFile);

  return (
    <Grid container spacing={2} className="row col-md-12 no-gutters py-3 attachments-component-web">
      {warningMsg && <UploadWarningMsg warningMsg={warningMsg} />}
      <Grid xs={12} md={6} className="col-md-4">
        {canEdit && <FileDropzone onDrop={onDrop} />}
      </Grid>
      <Grid xs={12} md={6} />
      <Grid xs={12} className="col-md-12">
        {title && (
          <Typography color="textSecondary" variant="body1" className="pb-3">
            {ONLY_FILES_ATTACHED_MSG}
          </Typography>
        )}
        {withPhotos && (
          <Grid container spacing={2} className="no-gutters">
            <ImagesFiles photos={photos} openPreview={openPreview} groupedByMainSystem={groupedByMainSystem} />
          </Grid>
        )}
      </Grid>

      {(withAudios || withDocs) && (
        <Grid xs={12} className="row col-md-12 mt-3 no-gutters" container spacing={2}>
          <Typography color="textSecondary" variant="body2" className="pb-3 mt-3">
            {`${title} - other files`}
          </Typography>
          <Grid xs={12} className="row col-md-12 mt-3" container>
            {audios.map((attachment) => (
              <Grid xs={12} sm={6} lg={4} className="col-md-4" key={attachment.id} item>
                <FileRow
                  attachment={attachment}
                  canEdit={canEdit}
                  onRemoveFile={onRemoveFile}
                  handleDownload={handleDownload}
                />
              </Grid>
            ))}
            {docs.map((attachment) => (
              <Grid xs={12} sm={6} lg={4} className="col-md-4" key={attachment.id} item>
                <FileRow
                  attachment={attachment}
                  canEdit={canEdit}
                  onRemoveFile={onRemoveFile}
                  handleDownload={handleDownload}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {isLoading && <Loader inline mask />}
      <AttachmentEdit
        open={editOpen}
        closeFn={closeEdit}
        id={selectedFile}
        description={selectedPhoto?.description || ''}
        parentId={id}
        type={type}
        fileListNames={fileListNames}
        isWeb
      />
      <AttachmentPreviewWeb
        canEdit={canEdit}
        closeFn={closePreview}
        editFn={openEdit}
        id={selectedFile}
        isMobile={false}
        /* @ts-ignore */
        isWorkflow={isWorkflow as any}
        onNext={goNext}
        onPrevious={goPrevious}
        open={previewOpen}
        photo={selectedPhoto}
        showNextPrevButtons={showNextPrevButtons}
        callbackFn={callbackFn}
      />
    </Grid>
  );
};

export default AttachmentWeb;
