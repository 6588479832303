import React, { FC, ReactNode, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Modal } from '@material-ui/core';

export interface IDrawerContext {
  open: boolean;
  setOpen: (drawerContent: ReactNode) => void;
  setClosed: () => void;
}

export const DrawerContext = createContext<IDrawerContext>({
  open: false,
  setOpen: (drawerContent: ReactNode) => {},
  setClosed: () => {},
});

interface IDrawerContextProviderProps {
  children: ReactNode;
}

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80vw',
  background: '#FFF',
  boxShadow: 24,
  p: 0,
};

const DrawerContextProvider: FC<IDrawerContextProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const contentRef = useRef<ReactNode>(null);

  useEffect(() => {
    if (!open && contentRef.current) {
      contentRef.current = null;
    }

    return () => {
      if (contentRef.current) {
        contentRef.current = null;
      }
    };
  }, [open]);

  const handleOpen = useCallback((drawerContent: ReactNode) => {
    setOpen(true);
    contentRef.current = drawerContent;
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const value = useMemo(() => ({ open, setOpen: handleOpen, setClosed: handleClose }), [open, handleOpen, handleClose]);

  return (
    <>
      <Modal open={open}>
        <Box sx={modalStyle}>{contentRef.current}</Box>
      </Modal>
      <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
    </>
  );
};

export { DrawerContextProvider };
