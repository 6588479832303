import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import _get from 'lodash/get';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { makeWbsName } from 'modules/app/helpers/utils';
import OneFileUploader from 'modules/common/components/fileUpload/oneFileUploader.component';
import { validateFiles } from 'modules/common/components/filesList/filesList.actions';
import FilteringTree from 'modules/common/components/filteringTree/filteringTree';
import InputModal from 'modules/common/components/inputModal/inputModal.container';
import Loader from 'modules/common/components/loader/loader.component';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, TextField } from '@material-ui/core';

import { addNewReport, fetchSuppliers, getWorkPackages } from './statusReports.actions';

const requiredText = 'This field is required';
const requiredFile = 'You need to upload report';
const validationSchema = Yup.object().shape({
  title: Yup.string().min(2).max(100).required(requiredText),
  supplier: Yup.object().shape({
    id: Yup.string().required(requiredText),
  }),
  files: Yup.array().min(1, requiredFile),
  wbs: Yup.object().shape({
    id: Yup.string().required(requiredText),
  }),
});

const initialFormValues = {
  wbs: {},
  comments: '',
  files: [],
  title: '',
  supplier: {},
};

const ReportFormFields = ({
  formData,
  errors,
  handleChange,
  setFieldValue,
  workPackages,
  getSuppliers,
  suppliers,
  suppliersIsLoading,
}) => {
  const onFileAdd = (files) => setFieldValue('files', files);
  const onFileDelete = () => setFieldValue('files', []);
  const onWbsSelect = (_, wbs) => {
    setFieldValue('wbs', wbs || {});
    getSuppliers(wbs ? wbs.id : '');
  };
  const onSupplierSelect = (_, supplier) => setFieldValue('supplier', supplier || {});

  return (
    <>
      <div className="col-md-6">
        <TextField
          className="col-md-12 mt-4"
          label="Title"
          id="statusReports:title"
          puppet-data="statusReports:title-input"
          name="title"
          value={formData.title}
          onChange={handleChange}
          error={errors && !!errors.title}
          helperText={errors && errors.title}
        />

        <InputModal
          className="w-100"
          id="statusReports:wbsId"
          fieldName="wbs"
          title="Park/Package"
          placeholder="Park/Package"
          value={makeWbsName(formData.wbs) || ''}
          clearFieldValue={onWbsSelect}
          error={!!errors && !!_get(errors, 'wbs.id', '')}
          errorText={errors && _get(errors, 'wbs.id', '')}
          blockRootClick
        >
          <FilteringTree
            onSelect={onWbsSelect}
            nodes={workPackages}
            searchPlaceholderText="Search for Park/Package"
            primaryTextField="description"
            secondaryTextField="name"
            alternativePrimaryTextField="name"
          />
        </InputModal>

        <InputModal
          className="w-100"
          id="statusReports:supplierId"
          fieldName="supplier"
          title="supplier"
          placeholder="Supplier"
          value={_get(formData, 'supplier.name', '') || ''}
          clearFieldValue={onSupplierSelect}
          error={!!errors && !!_get(errors, 'supplier.id', '')}
          errorText={errors && _get(errors, 'supplier.id', '')}
          isLoadingIndicator={suppliersIsLoading}
        >
          <FilteringTree
            onSelect={onSupplierSelect}
            nodes={formData.wbs.id && suppliers}
            searchPlaceholderText="Search for Supplier"
            primaryTextField="name"
          />
        </InputModal>

        <TextField
          className="col-md-12 mt-4"
          label="Comments"
          id="statusReports:comments"
          name="comments"
          multiline
          value={formData.comments}
          onChange={handleChange}
        />
      </div>
      <div className="col-md-6">
        <OneFileUploader
          setFiles={onFileAdd}
          files={formData.files}
          handleDelete={onFileDelete}
          error={errors && errors.files}
        />
      </div>
    </>
  );
};

ReportFormFields.propTypes = {
  formData: PropTypes.shape({
    wbs: PropTypes.shape({}),
    comments: PropTypes.string,
    title: PropTypes.string,
    files: PropTypes.array,
  }).isRequired,
  errors: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  workPackages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  suppliers: PropTypes.arrayOf(PropTypes.any).isRequired,
  suppliersIsLoading: PropTypes.bool.isRequired,
  getSuppliers: PropTypes.func.isRequired,
};

const NewStatusReport = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const { isLoading, workPackages, suppliers, suppliersIsLoading } = useSelector((state) => state.statusReports);

  useEffect(() => {
    dispatch(getWorkPackages());
  }, []);

  const openModal = () => setShowForm(true);
  const closeModal = () => setShowForm(false);
  const onSubmit = async (values, { resetForm }) => {
    const filesErrors = validateFiles(values.files);

    if (filesErrors.length) {
      genericErrorHandler(filesErrors);
      return;
    }

    try {
      await dispatch(addNewReport(values));
    } catch (e) {
      genericErrorHandler(e);
    }

    resetForm();
    closeModal();
  };

  const getSuppliers = (wbsId) => {
    dispatch(fetchSuppliers(wbsId));
  };

  return (
    <div className="row mt-3 mb-3">
      <div className="col-md-12">
        <Button size="medium" variant="contained" color="secondary" onClick={openModal} puppet-data="add-report:button">
          <Icon className="add-icon svg-medium">add</Icon>
          Upload Report
        </Button>
        <Dialog open={showForm} maxWidth="lg" onClose={closeModal} onEscapeKeyDown={closeModal}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialFormValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            render={({ errors, handleSubmit, handleChange, setFieldValue, values }) => (
              <>
                <DialogContent puppet-data="add-report:modal">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-12">
                      <DialogTitle className="modal-title--center" disableTypography>
                        Upload New Report
                      </DialogTitle>
                    </div>
                    {isLoading && <Loader inline mask />}
                    {!isLoading && (
                      <ReportFormFields
                        formData={values}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        workPackages={workPackages}
                        getSuppliers={getSuppliers}
                        suppliers={suppliers}
                        suppliersIsLoading={suppliersIsLoading}
                      />
                    )}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeModal} color="secondary">
                    Close
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    puppet-data="statusRaports:submit"
                    disabled={isLoading}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </>
            )}
          />
        </Dialog>
      </div>
    </div>
  );
};

export default NewStatusReport;
