import {
  CLEAR_DATA,
  SET_DASHBOARD_FILTERS,
  SET_IS_LOADING_FILTERS,
  SET_IS_LOADING_CHARTS,
  SET_DASHBOARD_CHARTS,
  SET_DASHBOARD_TABLES,
  SET_IS_LOADING_TABLES,
} from './dashboard.actions';

const initialState = {
  filters: {
    isLoading: false,
    data: [],
  },
  charts: {
    isLoading: false,
    data: [],
  },
  tables: {
    isLoading: false,
    data: [],
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          isLoading: payload,
        },
      };
    case SET_DASHBOARD_FILTERS:
      return {
        ...state,
        filters: {
          data: payload,
          isLoading: false,
        },
      };
    case SET_IS_LOADING_CHARTS:
      return {
        ...state,
        charts: {
          ...state.charts,
          isLoading: payload,
        },
      };
    case SET_DASHBOARD_CHARTS:
      return {
        ...state,
        charts: {
          data: payload,
          isLoading: false,
        },
      };
    case SET_IS_LOADING_TABLES:
      return {
        ...state,
        tables: {
          ...state.tables,
          isLoading: payload,
        },
      };
    case SET_DASHBOARD_TABLES:
      return {
        ...state,
        tables: {
          data: payload,
          isLoading: false,
        },
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
