import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { useMsal } from '@azure/msal-react';
import { Avatar, Button, Divider, Icon, ListItemIcon, ListItemText, MenuItem, MenuList } from '@material-ui/core';

import ConfirmModal from '../confirmModal/confirmModal';

const Menu = ({
  firstName,
  fullName,
  email,
  logout,
  isOnline,
  isMobile,
  registerSync,
  syncInProgress,
  syncProgress,
  getNotSynchronizedAttachments,
}) => {
  const { instance } = useMsal();
  const [confirmText, setConfirmText] = useState('Are you sure you want to logout?');

  const refreshApp = async () => {
    if ('serviceWorker' in navigator) {
      try {
        const register = await navigator.serviceWorker.register('/service-worker.js');
        register.waiting.postMessage({ type: 'SKIP_WAITING' });
        console.log('App refresh by skip waiting');
      } catch (e) {
        console.log('regirester error', e);
        window.location.reload(true);
      }
    } else {
      console.log('App refresh by window reload');
      window.location.reload(true);
    }
  };

  const syncAll = () => {
    registerSync();
  };

  const preLogout = async (cb) => {
    const docs = await getNotSynchronizedAttachments();

    if (docs.length) {
      setConfirmText('You still have not synchronized attachments. Are you sure you want to logout?');
      cb();
    } else {
      cb();
    }
  };

  const prepareSyncStatus = (() => {
    return syncInProgress
      ? `Findings - ${syncProgress.findings}/${syncProgress.findingsTotal} 
        Attachments - ${syncProgress.attachments}/${syncProgress.attachmentsTotal}`
      : 'Nothing is syncing now';
  })();

  const avatarLetter = (firstName || email).charAt(0).toUpperCase();

  const handleLogout = () => {
    logout(instance);
  };

  return (
    <div className="menu-component">
      <div className="header-wrapper">
        <Avatar className="avatar mt-0">{avatarLetter}</Avatar>
        <p className="title break-word">{`${fullName}`}</p>
        <p className="subtitle break-word">{email}</p>
      </div>
      <Divider />
      <MenuList>
        {(isOnline || !isMobile) && (
          <ConfirmModal confirmFn={handleLogout} confirmTitle={confirmText} preOpenFn={preLogout} confirmText="Logout">
            <MenuItem id="logout:button" puppet-data="logout:button">
              <ListItemIcon>
                <Icon>input</Icon>
              </ListItemIcon>
              <ListItemText inset primary="Log out" />
            </MenuItem>
          </ConfirmModal>
        )}
        {isOnline && (
          <MenuItem>
            <ListItemText primary="Synchronization status" secondary={prepareSyncStatus} />
          </MenuItem>
        )}
        {isOnline && (
          <MenuItem>
            <Button size="small" color="secondary" variant="contained" onClick={syncAll}>
              <Icon className="svg-small">sync</Icon>
            </Button>
            <ListItemText inset primary="Synchronize data" />
          </MenuItem>
        )}
        {isOnline && (
          <MenuItem>
            <Button size="small" variant="contained" color="primary" onClick={refreshApp}>
              <Icon className="svg-small">refresh</Icon>
            </Button>
            <ListItemText inset primary="Refresh app" />
          </MenuItem>
        )}
        <MenuItem>
          <ListItemText primary="Version: " />
          <ListItemText secondary={process.env.REACT_APP_VERSION} />
        </MenuItem>
      </MenuList>
    </div>
  );
};

Menu.propTypes = {
  logout: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  fullName: PropTypes.string,
  email: PropTypes.string,
  getNotSynchronizedAttachments: PropTypes.func.isRequired,
};

Menu.defaultProps = {
  firstName: '',
  fullName: '',
  email: '',
};

export default Menu;
