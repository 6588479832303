import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import _get from 'lodash/get';
import _pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';
import { setShowHeader, createCrumb } from 'modules/app/components/app/app.actions';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { WORKFLOWS_TYPES, FILE_PARENTS } from 'modules/app/config/config';
import { parseFileSize, findNodeInTree, decodeParam } from 'modules/app/helpers/utils';
import FileListNames from 'modules/common/components/filesList/FileListNames';
import { saveAttachmentsWeb, validateFiles } from 'modules/common/components/filesList/filesList.actions';
import Loader from 'modules/common/components/loader/loader.component';
import UnsavedChanges from 'modules/common/components/unsavedChanges/unsavedChanges.hoc';
import {
  getFindingsByIds,
  clearData as clearFindingsData,
} from 'modules/findings/components/findings/findings.actions';
import { getFindings, getIsLoading, getTotalCount } from 'modules/findings/components/findings/findings.selectors';
import {
  getInspection,
  clearData as clearInspectionData,
} from 'modules/inspections/components/inspectionDetails/inspectionDetails.actions';
import PropTypes from 'prop-types';
import Notifications from 'services/notifications';

import { getWorkflow } from '../workflowDetails/workflowDetails.actions';
import { clearData, saveWorkflow, updateWorkflow, getWorkPackage, setIsLoading } from './newWorkflow.actions';
import NewWorkflowModel from './newWorkflow.model';

const SINGLE_FINDING_LENGTH = 1;

const mapStateToProps = (state) => ({
  isLoadingCreate: state.newWorkflow.isLoading,
  isLoadingEdit: state.workflowDetails.isLoading,
  workPackage: state.newWorkflow.workPackage,
  severity: state.newFinding.severity,
  workflow: state.workflowDetails.data,
  findings: getFindings(state),
  findingsIsLoading: getIsLoading(state),
  findingsTotalCount: getTotalCount(state),
  inspection: state.inspection.data,
  rolesDetails: state.auth.rolesDetails,
});

const mapDispatchToProps = {
  setIsLoading,
  setShowHeader,
  clearData,
  getInspection,
  createCrumb,
  getWorkPackage,
  getWorkflow,
  clearFindingsData,
  clearInspectionData,
  getFindingsByIds,
};

const isOriginatedDataLoaded = (findings, inspection) => Object.keys(findings).length && inspection.id;

const populateMainSystems = (inspection, findings) => {
  if (findings.length) {
    const mainSystems = findings.map((finding) => finding.mainSystem).filter((n) => n); // remove nulls

    return uniqBy(mainSystems, 'id');
  }

  if (inspection?.mainSystem) {
    return [inspection.mainSystem];
  }

  return [];
};

const withNewWorkflow = (NewWorkflowComponent) => {
  class NewWorkflow extends PureComponent {
    state = {
      newWorkflow: {},
      files: [],
      roles: [],
      formDataIsLoading: false,
    };

    fileListNames = new FileListNames();

    async componentDidMount() {
      let workflow = {};
      let roles = [];
      let inspection = {};
      const {
        setShowHeader,
        getWorkflow,
        match: { params },
        getWorkPackage,
        getInspection,
        getFindingsByIds,
      } = this.props;

      setShowHeader(false);
      this.setState({ formDataIsLoading: true });

      if (params.editWorkflowId || params.workflowId) {
        workflow = await getWorkflow(params.editWorkflowId || params.workflowId);

        const inspectionId = _get(workflow, 'findings[0].inspectionId', false);

        if (inspectionId) {
          inspection = await getInspection(inspectionId);
        }
      }

      await getWorkPackage(params.type || workflow.type);

      if (this.isWithFindings()) {
        const originInspection = await getInspection(params.inspectionId);
        const { workPackage } = this.props;

        await getFindingsByIds(decodeParam(params.findingId));
        roles = _get(findNodeInTree(workPackage, 'id', _get(originInspection, 'wbs.id'), 'children'), 'roles', []);
      }

      if (params.workflowId) {
        workflow = {
          ...workflow,
          type: WORKFLOWS_TYPES.nonconformity,
          ancestorWorkflows: [params.workflowId],
          id: null,
          frontendId: null,
          inspectionType: _get(inspection, 'type'),
        };
      }

      if (params.editWorkflowId || params.workflowId) {
        const workPackage = await getWorkPackage(workflow.type);

        workflow = { ...workflow, inspectionType: _get(inspection, 'type') };

        roles = await _get(findNodeInTree(workPackage, 'id', workflow.wbs.id, 'children'), 'roles', []);
      }

      const filteredRoles = this.filterCreatorsRole(roles, workflow);

      this.setState({
        newWorkflow: workflow,
        roles: filteredRoles,
        formDataIsLoading: false,
      });
    }

    componentDidUpdate({ match: { params: prevParams } }) {
      const {
        match: { params, path },
        createCrumb,
      } = this.props;

      if (JSON.stringify(prevParams) !== JSON.stringify(params)) {
        this.setState({
          newWorkflow: {},
          files: [],
        });
      }

      createCrumb(path, params);
    }

    componentWillUnmount() {
      const { setShowHeader, clearData, clearInspectionData, clearFindingsData } = this.props;

      setShowHeader(true);
      clearData();

      if (this.isWithFindings()) {
        clearFindingsData();
        clearInspectionData();
      }
    }

    static getDerivedStateFromProps(props, state) {
      const {
        workflow,
        match: { params },
        findings,
        inspection,
      } = props;

      if (params.findingId && isOriginatedDataLoaded(findings, inspection) && !state.newWorkflow.findings) {
        const baseWorkflow = {
          ...workflow,
          inspectionType: _get(inspection, 'type'),
          findings: decodeParam(params.findingId),
        };

        if (findings.length > SINGLE_FINDING_LENGTH) {
          return {
            newWorkflow: {
              ...baseWorkflow,
              wbs: inspection.wbs,
              siteFacility: inspection.siteFacility || '',
              mainSystems: populateMainSystems(inspection, findings),
            },
          };
        }

        const findingData = _pick(findings[0], [
          'serialNumber',
          'referenceDocument',
          'location',
          'description',
          'failureMode',
          'subSystem',
        ]);

        const mappedFindings = {
          ...findingData,
          serialNumber: findingData.serialNumber || '',
          referenceDocument: findingData.referenceDocument || '',
        };

        return {
          newWorkflow: {
            ...baseWorkflow,
            ..._pick(inspection, ['siteFacility', 'wbs', 'subSystem']),
            ...mappedFindings,
            mainSystems: populateMainSystems(inspection, findings),
          },
        };
      }

      if ((!!params.editWorkflowId && (state.newWorkflow.id || state.newWorkflow._id)) || !params.editWorkflowId) {
        return null;
      }

      return {
        newWorkflow: workflow,
      };
    }

    filterCreatorsRole = (roles, workflow) => {
      const {
        rolesDetails,
        match: { params },
      } = this.props;
      const workflowType = WORKFLOWS_TYPES[params.type] || workflow.type;

      return roles.filter((role) => {
        const foundedRoleDetails = rolesDetails.find((roleDetails) => roleDetails.name === role);

        if (
          foundedRoleDetails &&
          foundedRoleDetails.manageableWorkflows &&
          foundedRoleDetails.manageableWorkflows.indexOf(workflowType) !== -1
        ) {
          return role;
        }

        return null;
      });
    };

    deleteFile = (id) => {
      this.setState((prevState) => ({
        files: prevState.files.filter((file) => file.id !== id),
      }));
    };

    handleSubmit = async () => {
      const {
        history,
        setIsLoading,
        match: {
          params: { type },
        },
        setDirty,
      } = this.props;

      setDirty(false);

      const { newWorkflow, files } = this.state;
      const workflowType = WORKFLOWS_TYPES[type] || newWorkflow.type;

      const newWorkflowModel = new NewWorkflowModel({
        type: workflowType,
        ...newWorkflow,
      });

      const newErrors = newWorkflowModel.validateSave();

      const filesErrors = validateFiles(files);

      if (filesErrors.length) {
        genericErrorHandler(filesErrors);
        return;
      }

      if (newErrors) {
        this.setState({
          errors: newErrors,
        });
        return;
      }

      setIsLoading(true);

      try {
        const id = newWorkflowModel.id ? await updateWorkflow(newWorkflowModel) : await saveWorkflow(newWorkflowModel);

        // check if files are in state and add them to finding
        if (files.length > 0) {
          try {
            await saveAttachmentsWeb(files, id, FILE_PARENTS.workflow, this.fileListNames);
          } catch (e) {
            genericErrorHandler(e);
          }
        }

        setDirty(false);
        setIsLoading(false);

        if (id) {
          history.replace(`/workflowDetails/${id}`);
        }
      } catch (err) {
        setIsLoading(false);
        genericErrorHandler(err);
      }
    };

    setFiles = (files) => {
      this.setState({ files });
    };

    setRoles = (roles) => {
      const { newWorkflow } = this.state;
      const filteredRoles = this.filterCreatorsRole(roles, newWorkflow);

      this.setState({
        roles: filteredRoles,
      });
    };

    generateOriginatedSubtitle = () => {
      const {
        match: { params },
      } = this.props;

      const { newWorkflow } = this.state;

      if (params.workflowId && newWorkflow.identifier) {
        return `Observation: ${newWorkflow.identifier}`;
      }
      return null;
    };

    handleChange = (e) => {
      const {
        currentTarget: { name, value },
      } = e;

      this.changeFieldsValue([{ name, value }]);
    };

    handleSelectChange = (e) => {
      const {
        target: { name, value },
      } = e;

      this.changeFieldsValue([{ name, value }]);
    };

    handleChangeRole = (name, value) => {
      const { roles = [] } = value || {};

      this.changeFieldsValue([
        { name, value },
        { name: 'subSystem', value: '' },
        { name: 'siteFacility', value: '' },
        { name: 'mainSystems', value: [] },
      ]);
      this.setRoles(roles);

      roles.length >= 1
        ? this.changeFieldsValue([{ name: 'creatorRole', value: roles[0] }])
        : this.changeFieldsValue([{ name: 'creatorRole', value: '' }]);
    };

    setFieldValue = (name, value) => {
      this.changeFieldsValue([{ name, value }]);
    };

    setMainSystemValue = async (name, value) => {
      const newMainSystemsValue = value ? [value] : [];

      this.changeFieldsValue([
        { name, value: newMainSystemsValue },
        { name: 'subSystem', value: '' },
      ]);
    };

    mainSystemIdsCheckFunction = (mainSystem) => {
      const {
        newWorkflow: { mainSystems = [] },
      } = this.state;

      return mainSystem && mainSystem.id ? mainSystems.map((ms) => ms.id).includes(mainSystem.id) : false;
    };

    setMainSystemIds = async (name, selectedMainSystem) => {
      const {
        newWorkflow: { mainSystems = [] },
      } = this.state;

      if (selectedMainSystem === undefined) {
        this.changeFieldsValue([
          { name, value: [] },
          { name: 'subSystem', value: '' },
        ]);

        return;
      }

      const mainSystemIds = mainSystems.map((ms) => ms.id);
      const newMainSystens = mainSystemIds.includes(selectedMainSystem.id)
        ? mainSystems.filter((ms) => ms.id !== selectedMainSystem.id)
        : [...mainSystems, selectedMainSystem];

      this.changeFieldsValue([
        { name, value: newMainSystens },
        { name: 'subSystem', value: '' },
      ]);
    };

    changeFieldsValue(values = []) {
      const { newWorkflow } = this.state;

      const { setDirty } = this.props;

      values.forEach((item) => {
        newWorkflow[item.name] = item.value;
      });

      const newFindingModel = new NewWorkflowModel(newWorkflow);

      this.setState({
        newWorkflow: newFindingModel,
      });
      setDirty();
    }

    isWithFindings() {
      const {
        match: { params },
      } = this.props;

      return params.inspectionId && params.findingId;
    }

    withRelatedFindings() {
      const {
        newWorkflow: { findings },
      } = this.state;
      return this.isWithFindings() && findings;
    }

    validateForMultiple() {
      const {
        newWorkflow: { findings },
      } = this.state;

      return this.withRelatedFindings() && findings.length > SINGLE_FINDING_LENGTH;
    }

    handleFindingsRowCheck = (id) => {
      const { newWorkflow } = this.state;
      const { setDirty } = this.props;

      newWorkflow.findings = newWorkflow.findings.includes(id)
        ? newWorkflow.findings.filter((selectedId) => selectedId !== id)
        : [...newWorkflow.findings, id];

      if (!newWorkflow.findings.length) {
        Notifications.showError('Please select at least one finding to start the workflow');
        return;
      }

      const workflowToUpdate = this.workflowDataComplete(newWorkflow);
      const newFindingModel = new NewWorkflowModel(workflowToUpdate);

      this.setState({
        newWorkflow: newFindingModel,
      });

      setDirty();
    };

    workflowDataComplete = (newWorkflow) => {
      const { findings, inspection } = this.props;

      if (newWorkflow.findings.length > SINGLE_FINDING_LENGTH) {
        return {
          ...newWorkflow,
          failureMode: '',
          mainSystems: populateMainSystems(inspection, findings),
          subSystem: '',
        };
      }

      return {
        ...newWorkflow,
        mainSystems: populateMainSystems(inspection, findings),
        ..._pick(inspection, ['siteFacility', 'subSystem']),
        ..._pick(findings[0], ['failureMode', 'subSystem']),
      };
    };

    render() {
      const {
        isLoadingCreate,
        isLoadingEdit,
        isFilesLoading,
        match: { params },
        findings,
        findingsIsLoading,
        findingsTotalCount,
        inspection,
      } = this.props;

      let title;
      let { newWorkflow } = this.state;
      const { roles, errors, files, formDataIsLoading } = this.state;
      const isCreate = !params.editWorkflowId;

      if (isCreate && (isLoadingCreate || isFilesLoading || !newWorkflow)) {
        return <Loader />;
      }

      if (!isCreate && (isLoadingCreate || isLoadingEdit || isFilesLoading)) {
        return <Loader />;
      }

      if (params.findingId && !isOriginatedDataLoaded(findings, inspection)) {
        return <Loader />;
      }

      newWorkflow = new NewWorkflowModel(newWorkflow).parseDetails();
      const workflowType = WORKFLOWS_TYPES[params.type] || newWorkflow.type;

      if (isCreate) {
        title = (() => {
          switch (workflowType) {
            case WORKFLOWS_TYPES.nonconformity:
              return 'Register NonConformity';
            case WORKFLOWS_TYPES.observation:
              return 'Register Observation';
            case WORKFLOWS_TYPES.defect_notification:
              return 'Register Defect Notification';
            case WORKFLOWS_TYPES.audit:
              return 'Register Audit';
            default:
              return 'Register Workflow';
          }
        })();
      }

      if (!isCreate) {
        title = (() => {
          switch (workflowType) {
            case WORKFLOWS_TYPES.nonconformity:
              return 'Edit NonConformity';
            case WORKFLOWS_TYPES.observation:
              return 'Edit Observation';
            case WORKFLOWS_TYPES.defect_notification:
              return 'Edit Defect Notification';
            case WORKFLOWS_TYPES.audit:
              return 'Edit Audit';
            default:
              return 'Edit Workflow';
          }
        })();
      }

      const {
        deadlineError,
        deleteFile,
        handleChange,
        handleChangeRole,
        handleFindingsRowCheck,
        handleSelectChange,
        handleSubmit,
        isConstruction,
        mainSystemIdsCheckFunction,
        onCancel,
        onDrop,
        setDeadlineError,
        setFieldValue,
        setFiles,
        setLiabalityError,
        setMainSystemValue,
        setMainSystemIds,
      } = this;

      const props = {
        deadlineError,
        deleteFile,
        errors,
        files,
        findings,
        findingsIsLoading,
        findingsTotalCount,
        formDataIsLoading,
        handleChange,
        handleChangeRole,
        handleFindingsRowCheck,
        handleSelectChange,
        handleSubmit,
        isConstruction,
        isCreate,
        mainSystemIdsCheckFunction,
        newWorkflow,
        onCancel,
        onDrop,
        originated: this.generateOriginatedSubtitle(),
        parseFileSize,
        roles,
        setDeadlineError,
        setFieldValue,
        setFiles,
        setLiabalityError,
        setMainSystemValue,
        setMainSystemIds,
        title,
        validateForMultiple: this.validateForMultiple(),
        withRelatedFindings: this.withRelatedFindings(),
        workflowType,
      };

      return <NewWorkflowComponent {...this.props} {...props} />;
    }
  }

  NewWorkflow.propTypes = {
    history: PropTypes.objectOf(PropTypes.shape).isRequired,
    match: PropTypes.objectOf(PropTypes.shape).isRequired,
    setDirty: PropTypes.func.isRequired,
    createCrumb: PropTypes.func.isRequired,
    getWorkPackage: PropTypes.func.isRequired,
    setShowHeader: PropTypes.func.isRequired,
    clearData: PropTypes.func.isRequired,
  };

  return UnsavedChanges(connect(mapStateToProps, mapDispatchToProps)(NewWorkflow));
};

export default withNewWorkflow;
