import React from 'react';
import FindingPackageCell from 'modules/inspections/components/inspectionDetails/findingPackageCell';
import { Tooltip, Typography } from '@material-ui/core';
import { SELECTING_CONFIG } from 'modules/findings/components/findings/findings.config';
import moment from 'moment';
import { DATETIME_FORMAT } from 'modules/app/config/config';

const shortenTo = (text = '', shortenBy = 10) => {
  if (text) {
    return `${text.slice(0, shortenBy)}${text.length > shortenBy ? '...' : ''}`;
  }
  return '';
};

export const TABLE_CONFIG = {
  columns: [
    {
      caption: 'Failure Mode',
      dataField: 'failureMode.description',
      sortField: 'failureMode.description',
      renderCell: (_, item) => (
        <Tooltip
          title={item.failureMode ? item.failureMode.description : ''}
          aria-label={item.failureMode}
          placement="top"
        >
          <Typography color="textSecondary" variant="caption">
            {item.failureMode !== null ? shortenTo(item.failureMode.description, 20) : '(Not Provided)'}
          </Typography>
        </Tooltip>
      ),
    },

    {
      caption: 'Description',
      dataField: 'description',
      sortField: 'description',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption">
          {shortenTo(item.description, 100)}
        </Typography>
      ),
    },
    {
      caption: 'Date and Time',
      dataField: 'lastActivityDate',
      sortField: 'lastActivityDate',
      style: { whiteSpace: 'nowrap' },
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption">
          {moment(item.lastActivityDate).format(DATETIME_FORMAT)}
        </Typography>
      ),
    },
    {
      caption: 'Main System',
      dataField: 'mainSystem',
      sortField: 'mainSystem',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption">
          {item.mainSystem !== null ? item.mainSystem.description : '(Not Provided)'}
        </Typography>
      ),
    },
    {
      caption: 'Sub System',
      dataField: 'subSystem.description',
      sortField: 'subSystem.description',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption">
          {item.subSystem ? item.subSystem.description || `<${item.subSystem.subSystemCode}>` : '(Not Provided)'}
        </Typography>
      ),
    },
    {
      caption: 'Inspector',
      dataField: 'inspection.leadInspector',
      sortField: 'inspection.leadInspector',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption">
          {item.inspection.leadInspector}
        </Typography>
      ),
    },
  ],
  noItemsFoundText: 'No findings found',
  disableFromRowSelecting: SELECTING_CONFIG,
};
