import React, { FC, memo, useContext } from 'react';

import { Checkbox, TableCell, TableRow, Tooltip } from '@material-ui/core';

import { TableContext } from './table.context';
import HeaderCell from './tableHeaderCell.component';

interface ITableHeaderRowProps {
  config: any[];
  currentSort: string;
  currentSortDirection: 'asc' | 'desc';
  withCheckboxes: boolean;
  isCheckedAll: boolean;
  isChecklistTable: boolean;
  selectAllCheckboxes: () => void;
  hideSelectAll?: boolean;
  skipCells?: string[];
}

const TableHeaderRow: FC<ITableHeaderRowProps> = memo(
  ({
    config,
    currentSort,
    currentSortDirection,
    withCheckboxes,
    selectAllCheckboxes,
    isCheckedAll,
    isChecklistTable,
    hideSelectAll = false,
    skipCells = [],
  }) => {
    const { columnsConfig } = useContext(TableContext);

    const isHidden = (dataField: string) => {
      const column = columnsConfig?.find((conf) => conf.dataField === dataField);

      return column?.showed;
    };

    const renderHeaders = config
      .filter((conf) => !skipCells.includes(conf.dataField))
      .filter((conf) => isHidden(conf.dataField))
      .map((conf) => (
        <HeaderCell
          key={conf.dataField}
          align={conf.align}
          sortingId={conf.dataField}
          currentSort={currentSort}
          currentDirection={currentSortDirection}
        >
          {conf.caption}
        </HeaderCell>
      ));

    return (
      <TableRow>
        {withCheckboxes && (
          <Tooltip title={`${isCheckedAll ? 'Deselect' : 'Select'} all on the page`}>
            <TableCell padding="checkbox">
              {!hideSelectAll && (
                <Checkbox
                  checked={isCheckedAll}
                  onChange={selectAllCheckboxes}
                  inputProps={{ 'aria-label': 'Select all' }}
                />
              )}
            </TableCell>
          </Tooltip>
        )}

        {isChecklistTable && <TableCell padding="checkbox" />}
        {renderHeaders}
      </TableRow>
    );
  },
);

export default TableHeaderRow;
