import React, { PureComponent } from 'react';

import _get from 'lodash/get';
import { CONSTRUCTION_TYPE, DATE_FORMAT } from 'modules/app/config/config';
import { charsLeft } from 'modules/app/helpers/utils';
import DatepickerWrapper from 'modules/common/components/datepickerWrapper/datepickerWrapper.component';
import FilteringTree from 'modules/common/components/filteringTree/filteringTree';
import InputModal from 'modules/common/components/inputModal/inputModal.container';
import Loader from 'modules/common/components/loader/loader.component';
import SaveHeaderWeb from 'modules/common/components/saveHeader/saveHeaderWeb.component';

import { FormControl, InputAdornment, MenuItem, Switch, TextField, Typography } from '@material-ui/core';

import CoInspectors from '../coInspectors/coInspectors.container';
import Facilities from '../facilities/facilities.container';
import LeadInspector from '../leadInspector/leadInspector.container';
import { getMainSystems, getSubSystems } from './newInspection.actions';
import withNewInsepction, { defaultProps, propTypes } from './newInspection.hoc';
import { INSPECTION_STRUCTURE } from './newInspection.model';

const renderItemFacilities = ({ supplier, location, country }) => `${supplier}, ${location}, ${country}`;
const renderItemLeadInspector = ({ fullName }) => fullName;

class NewInspection extends PureComponent {
  render() {
    const {
      activeChecklists,
      errors,
      handleChange,
      handleChangeCheckbox,
      handleOnSubmit,
      history,
      inspection,
      isDisabledForEditionOtherThanDraft,
      isDisabledForEditionOtherThanDraftOrPlanned,
      isLoading,
      isLoadingDetails,
      isLoadingChecklists,
      mainInspection,
      match,
      newInspection,
      isUserWithoutProperRole,
      preLeadInspectorClick,
      setChecklistValue,
      setDeadlineError,
      setDirty,
      setFieldValue,
      setMainSystemValue,
      setWbsValue,
      title,
      workPackage,
    } = this.props;

    if (isLoading || isLoadingDetails) {
      return <Loader />;
    }

    const isConstruction = newInspection.type === CONSTRUCTION_TYPE;
    const isMainInspection = newInspection.structureType === INSPECTION_STRUCTURE.main;

    const renderChecklistItems = activeChecklists?.map((checklist) => {
      return (
        <MenuItem key={checklist.id} value={checklist.id}>
          {checklist.description}
        </MenuItem>
      );
    });

    const loadingIndicator = {
      endAdornment: (
        <InputAdornment position="end">
          <Loader textField />
        </InputAdornment>
      ),
    };

    const inputPropsLoading = isLoadingChecklists ? loadingIndicator : {};
    const minDateCondition = isUserWithoutProperRole && inspection?.latestDeadline;

    return (
      <div className="mt-3 p-3 shadow rounded">
        <SaveHeaderWeb title={title} onSave={handleOnSubmit} history={history} />
        <div className="content-wrapper row no-gutters p-0" id="new-inspection" puppet-data="new-inspection">
          <InputModal
            className="col-md-4 pr-md-3"
            id="wbsId"
            fieldName="wbs"
            title="Park/Package"
            placeholder="Park/Package"
            value={newInspection.wbs}
            filterTreeId={mainInspection.wbsDetails ? mainInspection.wbsDetails.id : null}
            clearFieldValue={setWbsValue}
            error={!!errors && !!errors.wbs}
            errorText={errors && errors.wbs}
            disabled={
              !!_get(mainInspection, 'mainSystem') || isUserWithoutProperRole || isDisabledForEditionOtherThanDraft
            }
            blockRootClick
          >
            <FilteringTree
              onSelect={setWbsValue}
              nodes={workPackage}
              searchPlaceholderText="Search for Park/Package"
              primaryTextField="description"
              secondaryTextField="name"
              alternativePrimaryTextField="name"
              checkForActive
            />
          </InputModal>
          <InputModal
            className="col-md-4 pr-md-3"
            id="mainSystem"
            title="Main system"
            placeholder={`Main system${isConstruction || isMainInspection ? ` (optional)` : ''}`}
            fieldName="mainSystem"
            value={newInspection.mainSystem}
            clearFieldValue={setMainSystemValue}
            getNodes={getMainSystems}
            getNodesParams={newInspection}
            filterTreeId={mainInspection.mainSystemDetails ? mainInspection.mainSystemDetails.id : null}
            error={!!errors && !!errors.mainSystem}
            errorText={errors && errors.mainSystem}
            disabled={
              !!_get(mainInspection, 'mainSystem') || isUserWithoutProperRole || isDisabledForEditionOtherThanDraft
            }
          >
            <FilteringTree
              onSelect={setMainSystemValue}
              searchPlaceholderText="Search for Main System"
              primaryTextField="description"
              secondaryTextField="mainSystemCode"
              checkForActive
            />
          </InputModal>
          <InputModal
            className="col-md-4 pr-md-3"
            id="subSystem"
            title="Sub system"
            fieldName="subSystem"
            placeholder="Sub system (optional)"
            value={newInspection.subSystem}
            clearFieldValue={setFieldValue}
            getNodes={getSubSystems}
            getNodesParams={newInspection}
            filterTreeId={mainInspection.subSystemDetails ? mainInspection.subSystemDetails.id : null}
            error={!!errors && !!errors.subSystem}
            errorText={errors && errors.subSystem}
            disabled={isUserWithoutProperRole || isDisabledForEditionOtherThanDraft}
          >
            <FilteringTree
              onSelect={setFieldValue}
              searchPlaceholderText="Search for Sub System"
              primaryTextField="description"
              secondaryTextField="subSystemCode"
              checkForActive
            />
          </InputModal>
          <TextField
            label="Title"
            className="col-md-8 mt-4 pr-md-3"
            id="title"
            name="title"
            value={newInspection.title}
            onChange={handleChange}
            error={!!errors && !!errors.title}
            helperText={(errors && errors.title) || charsLeft(newInspection.title, 200)}
            disabled={isUserWithoutProperRole || isDisabledForEditionOtherThanDraft}
            multiline
          />
          <LeadInspector
            className="col-md-4 pr-md-3"
            clearFieldValue={setFieldValue}
            disabled={isUserWithoutProperRole}
            error={!!errors && !!errors.leadInspector}
            errorText={errors && errors.leadInspector}
            extraParam={_get(newInspection, 'wbsDetails.id')}
            fieldName="leadInspector"
            onClick={preLeadInspectorClick}
            onSelect={setFieldValue}
            renderItem={renderItemLeadInspector}
            value={newInspection.leadInspector}
          />
          <Facilities
            className="col-md-4 pr-md-3"
            fieldName="siteFacility"
            onSelect={setFieldValue}
            clearFieldValue={setFieldValue}
            value={newInspection.siteFacility}
            error={!!errors && !!errors.siteFacility}
            errorText={errors && errors.siteFacility}
            renderItem={renderItemFacilities}
            rowHeight={65}
            extraParam={newInspection}
            disabled={isUserWithoutProperRole || isDisabledForEditionOtherThanDraft}
            autoSelect
            checkForActive
            placeholder={`Site/Facility${isConstruction ? '' : ` (optional)`}`}
            predefinePropName="location"
            predefineIfOne={isConstruction}
          />
          {!isConstruction && (
            <CoInspectors
              className="mt-3 col-md-4 pr-md-3"
              disabled={isUserWithoutProperRole || isDisabledForEditionOtherThanDraftOrPlanned}
              id="co-inspectors"
              setDirty={setDirty}
            />
          )}
          <FormControl className="col-md-4 mt-4 pr-md-3">
            <DatepickerWrapper
              animateYearScrolling={false}
              clearable
              disabled={isUserWithoutProperRole || isDisabledForEditionOtherThanDraftOrPlanned}
              format={DATE_FORMAT}
              label="Deadline (optional)"
              maxDate={mainInspection?.deadline || undefined}
              minDate={minDateCondition ? inspection.latestDeadline : undefined}
              name="deadline"
              onChange={setFieldValue}
              setError={setDeadlineError}
              value={newInspection.deadline}
            />
          </FormControl>
          <TextField
            label="Checklist (optional)"
            select
            className="col-md-4 mt-4 pr-md-3"
            id="checkListDefinitionId"
            name="checkListDefinitionId"
            value={newInspection.checkListDefinitionId || ''}
            onChange={setChecklistValue}
            InputProps={inputPropsLoading}
            disabled={
              isUserWithoutProperRole || isDisabledForEditionOtherThanDraft || isMainInspection || isLoadingChecklists
            }
          >
            {renderChecklistItems}
          </TextField>
          {!newInspection.mainInspectionId && !match.params.inspectionId && (
            <div className="row no-gutters justify-content-between align-items-center mt-4 col-md-4">
              <Typography color="textSecondary" variant="subtitle1">
                Main Inspection
              </Typography>
              <Switch
                id="structureType"
                name="structureType"
                checked={newInspection.structureTypeChecked}
                onChange={handleChangeCheckbox}
                value={INSPECTION_STRUCTURE.main}
                color="secondary"
                disabled={isUserWithoutProperRole || isDisabledForEditionOtherThanDraft}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

NewInspection.defaultProps = defaultProps;
NewInspection.propTypes = propTypes;

export default withNewInsepction(NewInspection);
