import Api from 'services/api';

const searchUser = (id, additionalQueryParams) =>
  Api.get(`/api/user-management/wbs/${id}/users`, additionalQueryParams);

const changeParticipant = (additionalQueryParams) => Api.put('/api/workflows/reassign', additionalQueryParams);

const reassignUser = (additionalQueryParams) => Api.put('/api/workflows/part/reassign', additionalQueryParams);

export { changeParticipant, reassignUser, searchUser };
