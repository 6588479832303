import _orderBy from 'lodash/orderBy';

import Api from '../../../../services/api';
import Db from '../../../../services/db';
import { INSPECTION_STATUSES } from '../../../app/config/config';
import { getInspection } from '../inspectionDetails/inspectionDetails.actions';
import NewInspection from '../newInspection/newInspection.model';
import NewWebInspection from './webInspection.model';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'INSPECTIONS';
const SET_INSPECTIONS = `${namespace}_SET_INSPECTIONS`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;

/*
 * REDUX ACTIONS
 */

const getInspectionsMobile = async (loggedUser) => {
  const { docs } = await Db.find('inspections', {
    selector: {
      $or: [
        { 'leadInspector.id': { $eq: loggedUser.id } },
        { 'leadInspectorDetails.id': { $eq: loggedUser.id } },
        {
          partOfPlanInspections: {
            $elemMatch: { 'leadInspector.id': { $eq: loggedUser.id } },
          },
        },
        {
          status: { $eq: INSPECTION_STATUSES.draft },
        },
      ],
    },
  });

  return [
    docs.filter(
      (inspection) => ![INSPECTION_STATUSES.completed, INSPECTION_STATUSES.finished].includes(inspection.status),
    ),
    null,
  ];
};

const getInspectionsWeb = async (params, filterState) => {
  const url = '/api/inspections/filter-for-web';
  const queryParams = {
    ...params,
  };

  delete queryParams.status;
  const status = params.status === INSPECTION_STATUSES.invalidated ? INSPECTION_STATUSES.ongoing : params.status || '';

  const body = {
    status: status.toUpperCase(),
    sort: params.sort,
  };

  const mapNamesToParams = {
    state: 'states',
    search: 'title',
    wbs: 'wbsIds',
    states: 'states',
    showStandaloneInspections: 'showStandaloneInspections',
  };

  if (filterState) {
    Object.entries(filterState).forEach(([key, val]) => {
      if (val.length || typeof val === 'boolean') {
        if (key === 'wbs') {
          body.wbsIds = val;
          return;
        }

        body[mapNamesToParams[key]] = val;
      }
    });
  }

  const res = await Api.post(url, body, queryParams);

  return [res.content, res.totalElements];
};

const getInspectionModel = (inspection, isMobile) =>
  isMobile ? new NewInspection(inspection).parseDetails() : new NewWebInspection(inspection).parseDetails();

const getInspections =
  (params, filterState, isWaiting = false) =>
  async (dispatch, getState) => {
    const {
      app: { isMobile },
      auth: { user },
    } = getState();

    if (!isWaiting) {
      dispatch({
        type: SET_IS_LOADING,
        payload: true,
      });
    }

    try {
      const [items, itemsTotal] = isMobile
        ? await getInspectionsMobile(user)
        : await getInspectionsWeb(params, filterState);
      const mainInspections = [];
      const subInspections = [];

      items.forEach((inspection) => {
        inspection.mainInspectionId
          ? subInspections.push(getInspectionModel(inspection, isMobile))
          : mainInspections.push(getInspectionModel(inspection, isMobile));
      });

      const filterSubInspection = (item) =>
        subInspections.filter(
          (inspection) =>
            item.localId === inspection.mainInspectionId ||
            item.id === inspection.mainInspectionId ||
            item._id === inspection.mainInspectionId ||
            item.mainInspectionInspector?.id === user.id,
        );

      const inspections = mainInspections.map((item) =>
        Object.assign(item, {
          partOfPlanInspections: isMobile
            ? _orderBy(filterSubInspection(item), 'createDate', 'desc')
            : filterSubInspection(item),
        }),
      );

      dispatch({
        type: SET_INSPECTIONS,
        payload: {
          content: isMobile ? _orderBy(inspections, 'createDate', 'desc') : inspections,
          itemsTotal,
        },
      });
    } catch (e) {
      dispatch({
        type: SET_IS_LOADING,
        payload: false,
      });
      throw e;
    }
  };

const getAllFindingsExport = async () => Api.get('/api/findings/export/xlsx', null, 'blob');

const checkOutForMobile = (id) => async (dispatch) => {
  try {
    await Api.put(`/api/inspections/${id}/checkout-for-mobile`);

    dispatch(getInspection(id));
  } catch (err) {
    console.error(err);
  }
};

const removeCheckOutForMobile = (id) => async (dispatch) => {
  try {
    await Api.delete(`/api/inspections/${id}/checkout-for-mobile`);
    dispatch(getInspection(id));
  } catch (err) {
    console.error(err);
  }
};

export { checkOutForMobile, getAllFindingsExport, getInspections, removeCheckOutForMobile };

export { SET_INSPECTIONS, SET_IS_LOADING };
