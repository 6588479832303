import React, { PureComponent } from 'react';

import _get from 'lodash/get';
import { GUTTER_SIZE, INSPECTION_STATUSES, FILE_PARENTS } from 'modules/app/config/config';
import FilesList from 'modules/common/components/filesList/filesListWeb.component';
import Loader from 'modules/common/components/loader/loader.component';
import FindingDetailsFieldsWeb from 'modules/findings/components/findingDetailsFields/findingDetailsFieldsWeb.component';
import NewFindingModel, { FINDING_TYPES } from 'modules/findings/components/newFinding/newFinding.model';
import PropTypes from 'prop-types';
import Acl, { PRIVILEGE_KEYS } from 'services/acl';

import { withStyles } from '@material-ui/core/styles';

import withFindingDetails from './findingDetails.hoc';

const styles = (theme) => ({
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  iconColor: {
    fill: '#FFF',
  },
  iconActiveColor: {
    fill: '#FFF',
  },
  addButton: {
    position: 'fixed',
    backgroundColor: theme.palette.secondary.light,
    bottom: GUTTER_SIZE,
    right: GUTTER_SIZE,
  },
});

class FindingDetailsWeb extends PureComponent {
  static canEditFinding = (wbs) => Acl.hasPrivilege(PRIVILEGE_KEYS.FINDINGS_EDIT, wbs);

  static canDeleteFinding = (wbs) => Acl.hasPrivilege(PRIVILEGE_KEYS.FINDINGS_DELETE, wbs);

  async componentDidMount() {
    const { getFinding, match } = this.props;
    await getFinding(match.params.inspectionId, match.params.id);
  }

  componentDidUpdate() {
    const {
      createCrumb,
      match: { path, params },
    } = this.props;

    createCrumb(path, params);
  }

  componentWillUnmount() {
    const { clearData } = this.props;

    clearData();
  }

  reloadInpsection = async () => {
    const {
      getInspection,
      match: { params },
    } = this.props;

    await getInspection(params.inspectionId);
  };

  render() {
    const { isLoading, inspection, removeFinding, match } = this.props;
    let { finding } = this.props;

    if (isLoading || !inspection || !finding) {
      return <Loader />;
    }

    const inspectionClosed = inspection.status === INSPECTION_STATUSES.completed;

    finding = new NewFindingModel(finding, inspection).parseDetails();
    const wbs = _get(inspection, 'wbs.id', null);
    const canEditFinding = FindingDetailsWeb.canEditFinding(wbs);
    const canDeleteFinding = FindingDetailsWeb.canDeleteFinding(wbs);
    const isTodoCheckedOutForMobile =
      finding.findingType === FINDING_TYPES.TODO.value && inspection.checkedOutForMobile;

    if (!(_get(finding, '_id') || _get(finding, 'id'))) {
      return null;
    }

    return (
      <div id="finding-details:div" className="d-flex flex-column finding-details-component">
        <FindingDetailsFieldsWeb
          getFinding={this.props.getFinding}
          findings={inspection.findings}
          id={match.params.id}
          inspectionId={match.params.inspectionId}
          removeFinding={removeFinding}
          finding={finding}
          edit={!inspectionClosed && canEditFinding && !isTodoCheckedOutForMobile}
          remove={!inspectionClosed && canDeleteFinding}
        />
        {finding.findingType !== 'TODO' && (
          <FilesList
            key={match.params.id}
            title="Attachments"
            id={match.params.id}
            type={FILE_PARENTS.finding}
            allowDelete={!inspectionClosed && canEditFinding}
            canEdit={canEditFinding && !inspectionClosed}
            callbackFn={this.reloadInpsection}
          />
        )}
      </div>
    );
  }
}

FindingDetailsWeb.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getFinding: PropTypes.func.isRequired,
  removeFinding: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  createCrumb: PropTypes.func.isRequired,
  inspection: PropTypes.objectOf(PropTypes.shape).isRequired,
  finding: PropTypes.objectOf(PropTypes.shape).isRequired,
  match: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default withFindingDetails(withStyles(styles)(FindingDetailsWeb));
