import _get from 'lodash/get';
import { CONSTRUCTION_TYPE, WORKFLOWS_TYPES } from 'modules/app/config/config';
import { makeWbsName } from 'modules/app/helpers/utils';
import * as yup from 'yup';

const requiredText = 'This field is required';

export const SERIAL_DEFECTS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: '0',
    value: false,
  },
];

export const SEVERITY_OPTIONS = new Map(
  [
    { value: null, label: '—' },
    { value: 'HIGH', label: 'High' },
    { value: 'MODERATE', label: 'Moderate' },
    { value: 'LOW', label: 'Low' },
    { value: 'OBSERVATIC', label: 'Observatic' },
  ].map((item) => [item.value, item]),
);

export const AREA_OPTIONS = new Map(
  [
    { value: null, label: '—' },
    { value: 1, label: 'Outside / HV-Transformer / Documentation/logs' },
    { value: 2, label: 'Towerbase / Tower Inside' },
    { value: 3, label: 'Converter' },
    { value: 4, label: 'Yaw Section / Nacelle inside / Nacelle Roof' },
    { value: 5, label: 'Drivetrain' },
    { value: 6, label: 'Rotor' },
    { value: 7, label: 'SCADA' },
  ].map((item) => [item.value, item]),
);

const schemaToSave = {
  failureModeDetails: yup.string().nullable().max(100),
  location: yup.string().nullable().max(200),
  title: yup
    .string()
    .max(200)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  type: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  wbs: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  creatorRole: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  description: yup
    .string()
    .max(2000)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  failureMode: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  siteFacility: yup
    .string()
    .nullable()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  referenceDocument: yup
    .string()
    .max(100)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  serialNumber: yup
    .string()
    .max(50)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
};

const auditSchema = {
  title: yup
    .string()
    .max(200)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  type: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  wbs: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  creatorRole: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  description: yup
    .string()
    .max(2000)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  siteFacility: yup
    .string()
    .nullable()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  referenceDocument: yup
    .string()
    .max(100)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
};

const defectNotificationSchema = {
  title: yup
    .string()
    .max(200)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  type: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  wbs: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  creatorRole: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  description: yup
    .string()
    .max(2000)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  mainSystems: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  siteFacility: yup
    .string()
    .nullable()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  reference: yup
    .string()
    .max(255)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  legalEntity: yup
    .string()
    .max(255)
    .transform((value) => String(value))
    .trim(),
  defectLiabilityTo: yup
    .string()
    .nullable()
    .transform((value) => String(value))
    .trim(),
  defectLiabilityFrom: yup
    .string()
    .nullable()
    .transform((value) => String(value))
    .trim(),
};

const schemaToSaveMultipleFindings = {
  location: yup.string().nullable().max(200),
  title: yup
    .string()
    .max(200)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  type: yup
    .string()
    .max(2000)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  creatorRole: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  description: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  siteFacility: yup
    .string()
    .nullable()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  referenceDocument: yup
    .string()
    .max(100)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  serialNumber: yup
    .string()
    .max(50)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
};

const schemaToSaveConstruction = {
  referenceDocument: yup
    .string()
    .max(100)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  serialNumber: yup
    .string()
    .max(50)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
};

class NewWorkflow {
  static parseErrors(err) {
    const error = {};
    if (err instanceof yup.ValidationError) {
      err.inner.forEach((item) => {
        error[item.path] = item.message;
      });
    }
    return error;
  }

  constructor(finding) {
    Object.keys(finding || {}).forEach((key) => {
      this[key] = finding[key];
    });

    this.findingAttachments = (finding.findingAttachments || []).map((att) => {
      const finding = this.findings.find((f) => f.id === att.findingId);

      return {
        ...att,
        finding,
      };
    });
  }

  parseDetails() {
    const workflowModel = {
      _id: this._id,
      ancestorWorkflows: this.ancestorWorkflows || [],
      createdByDetails: this.createdBy,
      createdBy: _get(this.createdBy, 'fullName', ''),
      createDate: this.createdAt || this.createDate || new Date().toISOString(),
      currentPart: this.currentPart,
      currentActionTakerRole: this.currentActionTakerRole,
      description: this.description || '',
      failureMode: this.failureMode ? this.failureMode.description : '',
      failureModeDetails: this.failureModeDetails || '',
      failureModeDetailsObj: (this.failureModeDetailsObj ? this.failureModeDetailsObj : this.failureMode) || {},
      files: this.files || [],
      canAssignFindings: this.canAssignFindings || false,
      id: this.id,
      identifier: this.identifier || '',
      localId: this.frontendId || this._id,
      location: this.location || '',
      referenceDocument: this.referenceDocument || '',
      serialNumber: this.serialNumber || '',
      siteFacility: this.siteFacility
        ? `${this.siteFacility.supplier}, ${this.siteFacility.location}, ${this.siteFacility.country}`
        : '',
      siteFacilityDetails: this.siteFacilityDetails ? this.siteFacilityDetails : this.siteFacility,
      status: this.status,
      title: this.title || '',
      type: this.type,
      area: this.area || null,
      findings: this.findings || [],
      wbs: makeWbsName(this.wbs),
      wbsDetails: this.wbsDetails ? this.wbsDetails : this.wbs,
      workflowHistory: this.workflowHistory,
      creatorRole: this.creatorRole || '',
      mainSystems: this.mainSystems || [],
      mainSystemDetails: this.getMainSystemDetails(),
      subSystem: this.subSystem ? this.subSystem.description || `<${this.subSystem.subSystemCode}>` : '',
      subSystemDetails: this.subSystemDetails || this.subSystem,
      attachments: this.attachments || [],
      findingAttachments: (this.findingAttachments || []).map((att) => {
        const finding = this.findings.find((f) => f.id === att.findingId);

        return {
          ...att,
          finding,
        };
      }),
      inspectionType: this.inspectionType,
      invalidatedBy: this.invalidatedBy,
      invalidationDate: this.invalidationDate || '',
      invalidationReason: this.invalidationReason || '',
      reportUrl: this.reportUrl || null,
      reportAttachmentId: this.reportAttachmentId || null,
      reference: this.reference || '',
      legalEntity: this.legalEntity || '',
      serialDefect: this.serialDefect || SERIAL_DEFECTS[1].value,
      defectLiabilityTo: this.defectLiabilityTo || null,
      defectLiabilityFrom: this.defectLiabilityFrom || null,
      severity: this.severity || SEVERITY_OPTIONS.get(null).value,
    };

    return workflowModel;
  }

  getMainSystemDetails() {
    if (this.mainSystemDetails) {
      return this.mainSystemDetails;
    }

    if (this.mainSystems && this.mainSystems[0]) {
      return this.mainSystems[0];
    }

    return {};
  }

  parseDetailsForSynchronization(edit) {
    return {
      ancestorWorkflows: edit ? (this.ancestorWorkflows || []).map((item) => item.id) : this.ancestorWorkflows,
      createDate: this.createdAt || this.createDate || new Date().toISOString(),
      description: this.description,
      failureModeDetails: this.failureModeDetails,
      failureModeId: _get(this, 'failureMode.id'),
      files: this.files,
      frontendId: !edit ? this._id : undefined,
      id: edit ? this.id : undefined,
      location: this.location,
      modifyDate: edit ? this.updatedAt : this.createdAt,
      referenceDocument: this.referenceDocument,
      serialNumber: this.serialNumber,
      siteFacilityId: _get(this, 'siteFacility.id'),
      title: this.title,
      type: this.type,
      findings: (this.findings || []).map((item) => (item.id ? item.id : item)),
      creatorRole: this.creatorRole,
      wbsId: _get(this, 'wbs.id'),
      mainSystemIds: (this.mainSystems || []).map((mS) => mS.id),
      area: this.area,
      subSystemId: _get(this, 'subSystem.id'),
      reference: this.reference,
      legalEntity: this.legalEntity,
      serialDefect: this.serialDefect || SERIAL_DEFECTS[1].value,
      defectLiabilityFrom: this.defectLiabilityFrom,
      defectLiabilityTo: this.defectLiabilityTo,
      severity: this.severity,
    };
  }

  validationSchemaSelection() {
    const isConstructionType = this.inspectionType === CONSTRUCTION_TYPE;
    const isMultipleFindings = this.findings && this.findings.length > 1;
    const baseSchema = isMultipleFindings ? schemaToSaveMultipleFindings : schemaToSave;

    // at least one finding has to have mainSystem
    // the condition is related to disabledCreateDefectNotification in createWorkflowsFromSelected
    const defectNotificationSchemaWithCondition = isMultipleFindings
      ? {
          ...defectNotificationSchema,
          mainSystems: yup
            .string()
            .transform((value) => String(value))
            .trim(),
        }
      : defectNotificationSchema;

    if (this.type === WORKFLOWS_TYPES.audit) {
      return auditSchema;
    }

    if (this.type === WORKFLOWS_TYPES.defect_notification) {
      return defectNotificationSchemaWithCondition;
    }

    return isConstructionType ? { ...baseSchema, ...schemaToSaveConstruction } : baseSchema;
  }

  validateSave() {
    try {
      const schemaToValidate = this.validationSchemaSelection();
      const schema = yup.object().shape(schemaToValidate);

      schema.validateSync(this, {
        abortEarly: false,
      });

      return false;
    } catch (err) {
      return NewWorkflow.parseErrors(err);
    }
  }
}

export default NewWorkflow;
