import React from 'react';

const ClearFieldInput = (props) => (
  <svg viewBox="0 0 24 24" version="1.1" {...props}>
    <g id="Icon-/-Clear-Input-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="baseline-clear-24px" transform="translate(1.000000, 5.000000)" fill="#0000008a">
        <path
          d="M7.00343693,0 L21.2100107,0 L21.2100107,15 L7.00343693,15 C2.34804581,10.0193358 0.013566829,7.51933582 1.77635684e-15,7.5 C4.66895795,2.5 7.00343693,0 7.00343693,0 Z M19,3.50714286 L17.9928571,2.5 L14,6.49285714 L10.0071429,2.5 L9,3.50714286 L12.9928571,7.5 L9,11.4928571 L10.0071429,12.5 L14,8.50714286 L17.9928571,12.5 L19,11.4928571 L15.0071429,7.5 L19,3.50714286 Z"
          id="Rectangle-2"
        />
      </g>
    </g>
  </svg>
);

export default ClearFieldInput;
