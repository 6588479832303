import React, { useEffect, useState } from 'react';
import Tree from 'react-virtualized-tree';

import find from 'lodash/find';
import _get from 'lodash/get';
import { filterNodesInTree, findNodeInTree } from 'modules/app/helpers/utils';
import Loader from 'modules/common/components/loader/loader.component';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

import FilteringContainer from './filteringContainer.component';
import ItemRenderer from './itemRenderer.component';

const FilteringTree = ({
  alternativePrimaryTextField,
  blockRootClick,
  canSelectParent,
  checkForActive,
  checkFunction,
  clearFieldValue,
  dontClose,
  fieldName,
  filterTreeId,
  getNodes,
  getNodesParams,
  isMobile,
  noDataText = 'No data available',
  nodes,
  onClose,
  onSelect,
  primaryTextField,
  searchEnabled,
  searchPlaceholderText,
  searchPosition,
  secondaryTextField,
  value,
  withCheck,
}) => {
  const [state, setState] = useState({ items: nodes, isLoading: false });

  const filterTree = (items, val, key = 'id') => {
    const filteredNodes = findNodeInTree(items, key, val, 'children', (node) =>
      Object.assign(node, {
        state: {
          expanded: true,
        },
      }),
    );

    return filteredNodes ? [filteredNodes] : [];
  };

  useEffect(() => {
    setState({
      ...state,
      isLoading: true,
    });

    (async () => {
      let newNodes = [...nodes];

      if (getNodes && getNodesParams) {
        newNodes = await getNodes(getNodesParams);
      }

      if (filterTreeId) {
        newNodes = filterTree(newNodes, filterTreeId);
      }

      if (checkForActive) {
        newNodes = filterNodesInTree(newNodes, 'active', true);
      }

      const items = newNodes.filter((it) => Object.keys(it).length > 0 && !('views' in it));

      setState({
        ...state,
        items,
        isLoading: false,
      });
    })();
  }, [nodes]);

  const handleChange = (changedNodes) => {
    setState({ ...state, items: changedNodes });
  };

  const handleSelect = ({ children, ...node }) => {
    const rootNodeId = _get(node, 'parents[0]', false);

    const hasChildren = children && children.length > 0;

    // TODO: if (hasChildren) expand()
    if (!rootNodeId && !canSelectParent && hasChildren && blockRootClick) {
      return;
    }

    let extendedNode = node;

    if (rootNodeId) {
      const rootNode = find(state.items, { id: rootNodeId });
      if (rootNode) {
        const { description } = rootNode;
        extendedNode = { ...node, rootDescription: description };
      }
    }

    if (fieldName) {
      onSelect(fieldName, extendedNode);
    } else {
      onSelect(extendedNode);
    }

    if (dontClose === false) {
      onClose();
    }
  };

  return (
    <FilteringContainer
      onClose={onClose}
      searchPosition={searchPosition}
      searchPlaceholder={searchPlaceholderText}
      nodes={state.items}
      primaryTextField={primaryTextField}
      alternativePrimaryTextField={alternativePrimaryTextField}
      secondaryTextField={secondaryTextField}
      searchEnabled={searchEnabled}
      isMobile={isMobile}
      clearFieldValue={clearFieldValue}
      value={value}
    >
      {({ nodes: renderNodes }) => (
        <>
          {state.isLoading && <Loader inline mask />}
          {renderNodes.length === 0 && !state.isLoading && (
            <div className="text-center p-3">
              <Typography color="textSecondary" variant="subtitle1">
                {noDataText}
              </Typography>
            </div>
          )}
          {renderNodes.length > 0 && !state.isLoading && (
            <Tree nodes={renderNodes} onChange={handleChange}>
              {(
                { node, ...rest }, // TODO: Test if we have to check children
              ) => (
                <ItemRenderer
                  blockRootClick={!canSelectParent && blockRootClick}
                  withCheck={withCheck}
                  checkFunction={checkFunction}
                  node={node}
                  onSelect={handleSelect}
                  {...rest}
                  primaryTextField={primaryTextField}
                  alternativePrimaryTextField={alternativePrimaryTextField}
                  secondaryTextField={secondaryTextField}
                />
              )}
            </Tree>
          )}
        </>
      )}
    </FilteringContainer>
  );
};

FilteringTree.propTypes = {
  alternativePrimaryTextField: PropTypes.string,
  blockRootClick: PropTypes.bool,
  canSelectParent: PropTypes.bool,
  checkForActive: PropTypes.bool,
  checkFunction: PropTypes.func,
  clearFieldValue: PropTypes.func,
  dontClose: PropTypes.bool,
  fieldName: PropTypes.string,
  filterTreeId: PropTypes.string,
  getNodes: PropTypes.func,
  getNodesParams: PropTypes.object,
  isMobile: PropTypes.bool,
  noDataText: PropTypes.string,
  nodes: PropTypes.array,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  primaryTextField: PropTypes.string,
  searchEnabled: PropTypes.bool,
  searchPlaceholderText: PropTypes.string,
  searchPosition: PropTypes.string,
  secondaryTextField: PropTypes.string,
  value: PropTypes.object,
};

FilteringTree.defaultProps = {
  alternativePrimaryTextField: '',
  blockRootClick: false,
  canSelectParent: false,
  checkForActive: false,
  clearFieldValue: () => false,
  dontClose: false,
  filterTreeId: null,
  getNodes: null,
  getNodesParams: {},
  isMobile: false,
  noDataText: 'No data available',
  nodes: [],
  onClose: () => false,
  searchEnabled: true,
  searchPlaceholderText: '',
  secondaryTextField: '',
  value: null,
};

export default FilteringTree;
