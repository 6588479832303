import React, { Fragment } from 'react';

import _get from 'lodash/get';
import { FILE_PARENTS, WORKFLOWS_TYPES } from 'modules/app/config/config';
import { charsLeft, findNodeInTree, renderItemFacilities } from 'modules/app/helpers/utils';
import FileUploadZone from 'modules/common/components/fileUpload/fileUpload.component';
import FilesList from 'modules/common/components/filesList/filesListWeb.component';
import FilteringTree from 'modules/common/components/filteringTree/filteringTree';
import InputModal from 'modules/common/components/inputModal/inputModal.container';
import { InputLoader } from 'modules/common/components/loader/loader.component';
import SaveHeaderWeb from 'modules/common/components/saveHeader/saveHeaderWeb.component';
import { getFailureModes } from 'modules/findings/components/newFinding/newFinding.actions';
import Facilities from 'modules/inspections/components/facilities/facilities.container';
import { getMainSystems, getSubSystems } from 'modules/inspections/components/newInspection/newInspection.actions';
import { bool } from 'prop-types';

import { FormControl, TextField, Typography, Select, InputLabel, MenuItem, FormHelperText } from '@material-ui/core';

import FindingsList from '../workflowFindings/workflowFindings.component';
import DefectNotification from './defectNotification';
import withNewWorkflow from './newWorkflow.hoc';

const NewWorkflow = ({
  deleteFile,
  errors,
  files,
  findings,
  findingsIsLoading,
  findingsTotalCount,
  formDataIsLoading,
  handleChange,
  handleChangeRole,
  handleFindingsRowCheck,
  handleSelectChange,
  handleSubmit,
  history,
  isCreate,
  mainSystemIdsCheckFunction,
  newWorkflow,
  originated,
  roles,
  setFieldValue,
  setFiles,
  setLiabalityError,
  setMainSystemValue,
  setMainSystemIds,
  title,
  validateForMultiple,
  withRelatedFindings,
  workflowType,
  workPackage,
}) => {
  const rolesDurningEdition = _get(
    findNodeInTree(workPackage, 'id', _get(newWorkflow, 'wbsDetails.id'), 'children'),
    'roles',
    [],
  );

  if (workflowType === WORKFLOWS_TYPES.defect_notification) {
    // TODO: refactor whole component when bussines approve defect notification, for all 4 kind of types, too many ifs now

    return (
      <>
        <DefectNotification
          {...{
            deleteFile,
            errors,
            files,
            formDataIsLoading,
            handleChange,
            handleChangeRole,
            handleSelectChange,
            handleSubmit,
            history,
            isCreate,
            mainSystemIdsCheckFunction,
            newWorkflow,
            roles,
            setFieldValue,
            setFiles,
            setLiabalityError,
            setMainSystemIds,
            title,
            workPackage,
            withRelatedFindings,
          }}
        />
        <div id="new-finding:div" className="content-wrapper row no-gutters p-0" puppet-data="new-workflow">
          {withRelatedFindings && (
            <div className="col-md-12 mt-4 border-top">
              <FilesList
                title="Attachments from findings"
                id={newWorkflow.findings}
                type={FILE_PARENTS.findingsWorkflow}
                canEdit={false}
              />
            </div>
          )}
          {withRelatedFindings && (
            <FindingsList
              withCheckboxes
              selectedIds={newWorkflow.findings}
              handleRowCheck={handleFindingsRowCheck}
              findings={findings}
              isLoading={findingsIsLoading}
              totalCount={findingsTotalCount}
            />
          )}
        </div>
      </>
    );
  }

  const renderRoles = isCreate
    ? roles.map((role) => (
        <MenuItem key={role} value={role} puppet-data={`${role}-select`}>
          {role}
        </MenuItem>
      ))
    : rolesDurningEdition.map((role) => (
        <MenuItem key={role} value={role} puppet-data={`${role}-select`}>
          {role}
        </MenuItem>
      ));

  return (
    <div className="mt-3 p-3 shadow rounded">
      <SaveHeaderWeb
        title={title}
        onSave={handleSubmit}
        history={history}
        isLoading={findingsIsLoading && withRelatedFindings}
      />
      {originated && (
        <Typography color="textSecondary" variant="subtitle2">
          {`Originated from ${originated}`}
        </Typography>
      )}
      <div id="new-finding:div" className="content-wrapper row no-gutters p-0" puppet-data="new-workflow">
        {!validateForMultiple && (
          <>
            <FormControl className="col-md-4 pr-md-3">
              <InputModal
                id="wbsId"
                title="Work package"
                placeholder="Work Package"
                fieldName="wbs"
                value={newWorkflow.wbs}
                clearFieldValue={handleChangeRole}
                error={!!errors && !!errors.wbs}
                errorText={errors && errors.wbs}
                disabled={withRelatedFindings || !!originated}
                blockRootClick
                isLoadingIndicator={formDataIsLoading}
              >
                <FilteringTree
                  onSelect={handleChangeRole}
                  nodes={workPackage}
                  searchPlaceholderText="Search for Work Package"
                  primaryTextField="description"
                  secondaryTextField="name"
                  alternativePrimaryTextField="name"
                  sortProperty="name"
                  checkForActive
                />
              </InputModal>
            </FormControl>

            {workflowType !== WORKFLOWS_TYPES.audit && (
              <>
                <InputModal
                  className="col-md-4 pr-md-3"
                  id="mainSystem"
                  title="Main system"
                  placeholder="Main system (optional)"
                  fieldName="mainSystems"
                  value={(newWorkflow.mainSystems[0] && newWorkflow.mainSystems[0].description) || ''}
                  clearFieldValue={setMainSystemValue}
                  getNodes={getMainSystems}
                  getNodesParams={newWorkflow}
                  error={!!errors && !!errors.mainSystem}
                  errorText={errors && errors.mainSystem}
                  disabled={newWorkflow.mainSystem && withRelatedFindings}
                  isLoadingIndicator={formDataIsLoading}
                >
                  <FilteringTree
                    onSelect={setMainSystemValue}
                    searchPlaceholderText="Search for Main System"
                    primaryTextField="description"
                    secondaryTextField="mainSystemCode"
                    checkForActive
                  />
                </InputModal>

                <InputModal
                  className="col-md-4 pr-md-3"
                  id="subSystem"
                  title="Sub system"
                  placeholder="Sub system (optional)"
                  fieldName="subSystem"
                  value={newWorkflow.subSystem}
                  clearFieldValue={setFieldValue}
                  getNodes={getSubSystems}
                  getNodesParams={newWorkflow}
                  error={!!errors && !!errors.subSystem}
                  errorText={errors && errors.subSystem}
                  isLoadingIndicator={formDataIsLoading}
                >
                  <FilteringTree
                    onSelect={setFieldValue}
                    searchPlaceholderText="Search for Sub System"
                    primaryTextField="description"
                    secondaryTextField="subSystemCode"
                    checkForActive
                  />
                </InputModal>
              </>
            )}
          </>
        )}

        <FormControl className="col-md-4 mt-4 pr-md-3" error={!!(errors && errors.creatorRole)}>
          <InputLabel htmlFor="creatorRole">Creator`s role</InputLabel>
          <Select
            value={newWorkflow.creatorRole}
            onChange={handleSelectChange}
            className="select"
            puppet-data="creatorsRole"
            inputProps={{
              id: 'creatorRole',
              name: 'creatorRole',
            }}
            error={!!errors && !!errors.creatorRole}
            IconComponent={formDataIsLoading ? InputLoader : undefined}
          >
            {renderRoles}
          </Select>
          {errors && errors.creatorRole && <FormHelperText>{errors.creatorRole}</FormHelperText>}
        </FormControl>

        <FormControl className="col-md-8 mt-4 pr-md-3">
          <TextField
            label="Title"
            id="title"
            name="title"
            value={newWorkflow.title}
            onChange={handleChange}
            error={errors && !!errors.title}
            helperText={(errors && errors.title) || charsLeft(newWorkflow.title, 200)}
            multiline
          />
        </FormControl>

        {!validateForMultiple && workflowType !== WORKFLOWS_TYPES.audit && (
          <InputModal
            id="failureMode"
            className="col-md-4 mt-4 pr-md-3"
            title="Failure Mode"
            placeholder="Failure Mode"
            fieldName="failureMode"
            value={newWorkflow.failureMode}
            clearFieldValue={setFieldValue}
            error={errors && !!errors.failureMode}
            getNodes={getFailureModes}
            getNodesParams={newWorkflow}
            errorText={errors && errors.failureMode}
            isLoadingIndicator={formDataIsLoading}
          >
            <FilteringTree
              onSelect={setFieldValue}
              searchPlaceholderText="Search for Failure Mode"
              primaryTextField="description"
              secondaryTextField="code"
              blockRootClick
              checkForActive
            />
          </InputModal>
        )}

        <Facilities
          className="col-md-4 pr-md-3"
          fieldName="siteFacility"
          clearFieldValue={setFieldValue}
          onSelect={setFieldValue}
          value={newWorkflow.siteFacility}
          error={!!errors && !!errors.siteFacility}
          errorText={errors && errors.siteFacility}
          renderItem={renderItemFacilities}
          rowHeight={65}
          extraParam={newWorkflow}
          checkForActive
          placeholder="Site/Facility"
        />

        <FormControl className="col-md-4 mt-4 pr-md-3">
          <TextField
            label="Reference Document"
            id="referenceDocument"
            name="referenceDocument"
            value={newWorkflow.referenceDocument}
            onChange={handleChange}
            error={errors && !!errors.referenceDocument}
            helperText={(errors && errors.referenceDocument) || charsLeft(newWorkflow.referenceDocument, 100)}
            multiline
          />
        </FormControl>

        {workflowType !== WORKFLOWS_TYPES.audit && (
          <>
            <FormControl className="col-md-4 mt-4 pr-md-3">
              <TextField
                label="Location of Finding (optional)"
                id="location"
                name="location"
                value={newWorkflow.location}
                onChange={handleChange}
                error={errors && !!errors.location}
                helperText={(errors && errors.location) || charsLeft(newWorkflow.location, 200)}
                multiline
              />
            </FormControl>

            <FormControl className="col-md-4 mt-4 pr-md-3">
              <TextField
                label="Serial Number / ID Number"
                id="serialNumber"
                name="serialNumber"
                value={newWorkflow.serialNumber}
                onChange={handleChange}
                error={errors && !!errors.serialNumber}
                helperText={(errors && errors.serialNumber) || charsLeft(newWorkflow.serialNumber, 50)}
                multiline
              />
            </FormControl>
          </>
        )}

        <FormControl className="col-md-8 mt-4 pr-md-3">
          <TextField
            label="Description"
            id="description"
            name="description"
            multiline
            value={newWorkflow.description}
            onChange={handleChange}
            error={!!errors && !!errors.description}
            helperText={(errors && errors.description) || charsLeft(newWorkflow.description, 2000)}
          />
        </FormControl>

        {isCreate && <FileUploadZone setFiles={setFiles} files={files} handleDelete={deleteFile} isWorkflow />}

        {withRelatedFindings && (
          <div className="col-md-12 mt-4 border-top">
            <FilesList
              title="Attachments from findings"
              id={newWorkflow.findings}
              type={FILE_PARENTS.findingsWorkflow}
              canEdit={false}
            />
          </div>
        )}
        {withRelatedFindings && (
          <FindingsList
            withCheckboxes
            selectedIds={newWorkflow.findings}
            handleRowCheck={handleFindingsRowCheck}
            findings={findings}
            isLoading={findingsIsLoading}
            totalCount={findingsTotalCount}
          />
        )}
      </div>
    </div>
  );
};

NewWorkflow.propTypes = {
  formDataIsLoading: bool,
  findingsIsLoading: bool,
};

NewWorkflow.defaultProps = {
  formDataIsLoading: false,
  findingsIsLoading: false,
};

export default withNewWorkflow(NewWorkflow);
