/* eslint-disable no-useless-catch */
import { generateUId, sortTree } from 'modules/app/helpers/utils';
import Api from 'services/api';
import Db from 'services/db';

import NewWorkflowModel from './newWorkflow.model';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'NEW_WORKFLOW';
const CLEAR_DATA = `${namespace}_CLEAR_DATA`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const SAVE_WORKFLOW = `${namespace}_SAVE_WORKFLOW`;
const SET_FAILURE_MODES = `${namespace}_SET_FAILURE_MODES`;
const SET_WORK_PACKAGE = `${namespace}_SET_WORKFLOWS`;

/*
 * REDUX ACTIONS
 */
const saveWorkflow = async (data) => {
  try {
    const newData = { ...data, _id: generateUId() };

    const parsedData = new NewWorkflowModel(newData).parseDetailsForSynchronization();

    const workflow = await Api.post('/api/workflows', parsedData);

    return workflow.id;
  } catch (e) {
    throw e;
  }
};

const updateWorkflow = async (data) => {
  try {
    const parsedData = new NewWorkflowModel({
      ...data,
      updatedAt: new Date().toISOString(),
    }).parseDetailsForSynchronization(true);
    const workflow = await Api.put('/api/workflows', parsedData);

    return workflow.id;
  } catch (e) {
    throw e;
  }
};

const getWorkPackage = (type) => async (dispatch) => {
  try {
    const data = await Db.getAll(`wbs_workflow_${type.toLowerCase()}`);
    const payload = data.rows.map((i) => i.doc);

    dispatch({
      type: SET_WORK_PACKAGE,
      payload,
    });

    return payload;
  } catch (e) {
    throw e;
  }
};

const setIsLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  payload: isLoading,
});

const clearData = () => ({
  type: CLEAR_DATA,
});

const getSubSystemsFromMultipleMainSystems = async ({ mainSystems }) => {
  try {
    if (!mainSystems && !mainSystems.length) {
      return [];
    }

    const mainSystemTypes = mainSystems.map((ms) => ms.mainSystemType);
    const uniquedMainSystemsSelector = [...new Set(mainSystemTypes)].map((msType) => ({
      mainSystemType: { $eq: msType },
    }));

    const data = await Db.find('sub_systems', {
      selector: { $or: uniquedMainSystemsSelector },
    });

    return sortTree(data.docs, 'subSystemCode', 'asc');
  } catch (e) {
    throw e;
  }
};

export { clearData, getSubSystemsFromMultipleMainSystems, getWorkPackage, saveWorkflow, setIsLoading, updateWorkflow };

export { SET_FAILURE_MODES, CLEAR_DATA, SET_IS_LOADING, SAVE_WORKFLOW, SET_WORK_PACKAGE };
