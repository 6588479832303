import _get from 'lodash/get';
import { CONSTRUCTION_TYPE } from 'modules/app/config/config';
import { generateFindingTitle, makeSiteFacilityName, makeWbsName } from 'modules/app/helpers/utils';
import Db from 'services/db';
import * as yup from 'yup';

export const INSPECTION_STRUCTURE = {
  main: 'MAIN',
  standalone: 'STANDALONE',
};

const requiredText = 'This field is required';

const schemaToSave = {
  wbs: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  type: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  leadInspector: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
  title: yup
    .string()
    .max(200)
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
};

const schemaToSaveConstruction = {
  ...schemaToSave,
  siteFacility: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
};

const schemaToSaveOM = {
  ...schemaToSave,
  mainSystem: yup
    .string()
    .required(requiredText)
    .transform((value) => String(value))
    .trim(),
};

class NewInspection {
  static parseErrors(err) {
    const error = {};
    if (err instanceof yup.ValidationError) {
      err.inner.forEach((item) => {
        error[item.path] = item.message;
      });
    }
    return error;
  }

  constructor(inspection, mainInspection = {}) {
    Object.keys(inspection || {}).forEach((key) => {
      this[key] = inspection[key];
    });

    if (!this.functionalLocation && mainInspection.functionalLocation) {
      this.functionalLocation = _get(mainInspection, 'functionalLocationDetails', {});
    }

    if (!this.siteFacility && mainInspection.siteFacility) {
      this.siteFacility = _get(mainInspection, 'siteFacilityDetails', {});
    }

    if (!this.wbs && mainInspection.wbs) {
      this.wbs = _get(mainInspection, 'wbsDetails', {});
    }

    if (!this.mainSystem && mainInspection.mainSystem) {
      this.mainSystem = _get(mainInspection, 'mainSystemDetails', {});
    }

    if (!this.subSystem && mainInspection.subSystem) {
      this.subSystem = _get(mainInspection, 'subSystemDetails', {});
    }
  }

  parseDetails() {
    const inspectionModel = {
      _id: this._id,
      id: this.id,
      localId: this.frontendId || this._id,
      frontendId: this.frontendId,
      checkedOutForMobile: this.checkedOutForMobile,
      checkListDefinitionId: this.checkListDefinition?.id || this.checkListDefinitionId,
      title: this.title || '',
      mainSystem: this.mainSystem ? this.mainSystem.description : '',
      mainSystemDetails: this.mainSystemDetails ? this.mainSystemDetails : this.mainSystem,
      subSystem: this.subSystem ? this.subSystem.description || `<${this.subSystem.subSystemCode}>` : '',
      subSystemDetails: this.subSystemDetails ? this.subSystemDetails : this.subSystem,
      deadline: this.deadline || null,
      identifier: this.identifier || '',
      wbs: makeWbsName(this.wbs),
      wbsDetails: this.wbsDetails ? this.wbsDetails : this.wbs,
      siteFacility: makeSiteFacilityName(this.siteFacility),
      siteFacilityDetails: this.siteFacilityDetails ? this.siteFacilityDetails : this.siteFacility,
      functionalLocation: this.functionalLocation
        ? this.functionalLocation.description || this.functionalLocation.value
        : '',
      functionalLocationDetails: this.functionalLocationDetails
        ? this.functionalLocationDetails
        : this.functionalLocation,
      coInspectors: (this.coInspectors || [])?.map((coInspector) => {
        const item = { ...coInspector };
        item.fullName = `${item.lastName} ${item.firstName}`;
        return item;
      }),
      leadInspector: this.leadInspector ? this.leadInspector.fullName : '',
      leadInspectorDetails: this.leadInspectorDetails ? this.leadInspectorDetails : this.leadInspector,
      type: this.type,
      mainInspectionId: this.mainInspectionId,
      mainInspectionInspector: this.mainInspectionInspector,
      numberOfFindings: this.numberOfFindings,
      numberOfSynchronizedFindings: this.numberOfSynchronizedFindings,
      structureType: this.structureType,
      structureTypeChecked: this.structureType === 'MAIN',
      status: this.status,
      parkType: this.parkType,
      createDate: this.createDate ? this.createDate : new Date().toISOString(),
      publicationDate: this.publicationDate,
      partOfPlanInspections: this.partOfPlanInspections || [],
      startDate: this.startDate,
      finishDate: this.finishDate,
      closingDate: this.closingDate,
      closeReportAttachmentId: this.closeReportAttachmentId,
      finishReportAttachmentId: this.finishReportAttachmentId,
      numberOfAttachments: this.numberOfAttachments,
      numberOfAudios: this.numberOfAudios,
      numberOfImages: this.numberOfImages,
      park: this.park,
      findings: (this.findings || [])
        .filter((x) => x)
        ?.map((finding) => {
          const item = { ...finding };

          const mainSystem = _get(item.mainSystem, 'description') || _get(this.mainSystem, 'description');
          const subSystem = _get(item.subSystem, 'description') || _get(this.subSystem, 'description');
          const failureMode = item.failureModeDetailsObj || item.failureMode;

          item.title = generateFindingTitle(mainSystem, subSystem, failureMode, item.failureModeDetails);

          item.localId = item.frontendId || item.localId || item.id;

          item.numberOfAudios = item.localNumberOfAudios || item.numberOfAudios || 0;
          item.numberOfImages = item.localNumberOfImages || item.numberOfImages || 0;
          item.numberOfDocuments = item.localNumberOfDocuments || item.numberOfDocuments || 0;
          item.numberOfAttachments = item.localNumberOfAttachments || item.numberOfAttachments || 0;

          return item;
        }),
    };

    const inspectors = [];
    if (Object.keys(_get(inspectionModel, 'leadInspectorDetails', {})).length) {
      inspectors.push({
        ...inspectionModel.leadInspectorDetails,
        leadInspector: true,
      });
    }

    return {
      ...inspectionModel,
      inspectors: inspectors.concat(inspectionModel.coInspectors),
    };
  }

  async parseDetailsForSynchronization(edit, web = false) {
    const data = {
      coInspectors: this.coInspectors?.map(({ id, label, ...rest }) =>
        Object.assign(rest, {
          createDate: rest.createDate ? rest.createDate : new Date().toISOString(),
          modifyDate: new Date().toISOString(),
        }),
      ),
      deadline: this.deadline,
      leadInspectorId: _get(this, 'leadInspector.id'),
      wbsId: _get(this, 'wbs.id'),
      mainSystemId: _get(this, 'mainSystem.id'),
      subSystemId: _get(this, 'subSystem.id'),
      siteFacilityId: _get(this, 'siteFacility.id'),
      checkListDefinitionId: this.checkListDefinition?.id,
      title: this.title,
      type: this.type,
      structureType:
        this.structureType === INSPECTION_STRUCTURE.main ? INSPECTION_STRUCTURE.main : INSPECTION_STRUCTURE.standalone,
      frontendId: !edit ? this.localId : undefined,
      id: edit ? this.id : undefined,
      createDate: this.createDate ? this.createDate : new Date().toISOString(),
      mainInspectionId: this.mainInspectionId,
    };

    if (edit) {
      Object.assign(data, {
        modifyDate: edit ? this.modifyDate : this.createDate,
      });
    }

    if (data.mainInspectionId && !edit && !web) {
      const mainInspection = await Db.findOne('inspections', data.mainInspectionId);
      if (!_get(mainInspection, 'id')) {
        throw new Error('No main inspection');
      }

      data.mainInspectionId = mainInspection.id;
    }

    return data;
  }

  validateSave() {
    try {
      let shape = schemaToSaveOM;

      if (this.structureType === INSPECTION_STRUCTURE.main) {
        shape = schemaToSave;
      }
      if (this.type === CONSTRUCTION_TYPE) {
        shape = schemaToSaveConstruction;
      }

      const schema = yup.object().shape(shape);

      schema.validateSync(this, {
        abortEarly: false,
      });

      return false;
    } catch (err) {
      return NewInspection.parseErrors(err);
    }
  }
}

export default NewInspection;
