import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';

import { AvatarOffshore, AvatarOnshore, GlobalActionBack } from 'assets/icons/index';
import { DATE_FORMAT, GUTTER_SIZE, INSPECTION_STATUSES, OM_TYPE } from 'modules/app/config/config';
import ConfirmModal from 'modules/common/components/confirmModal/confirmModal';
import IconButtonTooltip from 'modules/common/components/iconButtonTooltip/iconButtonTooltip.component';
import LinkHref from 'modules/common/components/link/link.component';
import Loader from 'modules/common/components/loader/loader.component';
import Table, { TableUniqueKey } from 'modules/common/components/table';
import checkAccess from 'modules/common/hoc/checkAccess/checkAccess.hoc';
import FindingDetailsWeb from 'modules/findings/components/findingDetails/findingDetailsWeb.component';
import CreateWorkflowFromSelected from 'modules/findings/components/findings/createWorkflowFromSelected.component';
import NewFindingWeb from 'modules/findings/components/newFinding/newFindingWeb.component';
import InspectionItem from 'modules/inspections/components/inspectionItem/inspectionItem.component';
import InspectionReport from 'modules/inspections/components/inspectionReports/inspectionReport.component';
import NewInspectionModel, {
  INSPECTION_STRUCTURE,
} from 'modules/inspections/components/newInspection/newInspection.model';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PRIVILEGE_KEYS } from 'services/acl';

import { Avatar, Fab, Grid, Icon, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Lock, ScreenLockPortrait } from '@material-ui/icons';

import { getChecklistsDetails } from '../checklists/checklist.actions';
import InspectionDetailsHeader from '../inspectionDetailsHeader/inspectionDetailsHeader.container';
import InspectionStatusLabel from '../inspectionStatusLabel/inspectionStatusLabel.component';
import ChecklistDrawer from './checklistDrawerWeb.component';
import { InspectionDetailsTodosList } from './insectionTodos/inspectionDetailsTodosList.component';
import { getInspection } from './inspectionDetails.actions';
import withInspectionDetails from './inspectionDetails.hoc';
import { FINDINGS_CONFIG } from './inspectionDetailsFindings.config';

const NewFindingWebWithAuth = checkAccess(NewFindingWeb, PRIVILEGE_KEYS.FINDINGS_CREATE);
const FindingEditWebWithAuth = checkAccess(NewFindingWeb, PRIVILEGE_KEYS.FINDINGS_EDIT);
const FindingDetailsWebWithAuth = checkAccess(FindingDetailsWeb, PRIVILEGE_KEYS.FINDINGS_DETAILS);

const styles = (theme) => ({
  button: {
    margin: GUTTER_SIZE,
  },
  addButton: {
    backgroundColor: theme.palette.secondary.light,
  },
  checklistButton: {
    backgroundColor: theme.palette.secondary.light,
    marginRight: 'auto',
  },
});

const InspectionDetailsWeb = ({
  checklistAssign,
  checkOutForMobile,
  classes,
  goToFindingAdd,
  goToInspectionDetails,
  goToMainInspection,
  handleAddSubInspection,
  handlePublish,
  inspection,
  inspectionDetails,
  isExact,
  removeInspection,
  selectAllFindings,
  selectedIds,
  selectFinding,
  setInspectionFindings,
  showAddFindingButton,
  showSubInspectionButton,
  user,
}) => {
  const [drawerIsOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();

  const showInspectionReportLoader =
    (inspection.status === INSPECTION_STATUSES.finished && inspection.finishReportAttachmentId === null) ||
    (inspection.status === INSPECTION_STATUSES.completed && inspection.closeReportAttachmentId === null);

  useEffect(() => {
    if (inspectionDetails.findings.length > 1) {
      setInspectionFindings(inspectionDetails.findings);
    }
  }, [inspectionDetails.findings]);

  useEffect(() => {
    if (inspectionDetails?.checkListDefinitionId) getChecklistsDetails(inspectionDetails);
  }, [inspectionDetails]);

  useEffect(() => {
    if (showInspectionReportLoader) {
      setTimeout(() => {
        dispatch(getInspection(inspection.id));
      }, 10 * 1000);
    }
  }, [showInspectionReportLoader]);

  const isLeadInspector = (inspection) => inspection.leadInspector?.id === user.id;

  const isInspectionCreator = (inspection) => inspection.createdBy?.id === user.id;

  const shouldExecuteBeDisabled = () => {
    if (isInspectionCreator(inspection)) {
      return false;
    } else if (isLeadInspector(inspection)) {
      return false;
    } else {
      return true;
    }
  };

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const handleExecuteOnMobile = () => {
    checkOutForMobile(inspection.id);
  };

  const canManipulateTableData = user.roles.length === 1 && ['INSPECTOR', 'VIEWER'].includes(user.roles[0]);
  const inspectionIsClosed = inspection.status === INSPECTION_STATUSES.completed;
  const inspectionIsDraft = inspection.status === INSPECTION_STATUSES.draft;
  const isOMInspection = inspection.type === OM_TYPE;

  const canCreateFinding = showAddFindingButton(inspection);

  return (
    <>
      <ChecklistDrawer
        open={drawerIsOpen}
        onClose={closeDrawer}
        goToFindingAdd={goToFindingAdd}
        canCreateFinding={canCreateFinding && !inspectionIsDraft}
        checkedOutForMobile={inspection.checkedOutForMobile}
      />
      <Grid container className="inspection-details-web-component w-100">
        <Grid
          xs={12}
          container
          id="inspection-details:div"
          puppet-data="inspection-details"
          className="inspection-card p-3"
        >
          <Grid container className="row no-gutters flex-nowrap">
            <Grid xs={8} className="col-md-8 row no-gutters align-items-center">
              {inspectionDetails.mainInspectionId && (
                <IconButtonTooltip
                  tooltipTxt="Go back to Main Inspection"
                  onClick={goToMainInspection}
                  className="right-button"
                  icon={<GlobalActionBack className="icon-dark" />}
                />
              )}
              <Avatar className="avatar">
                <Icon className="svg-extra-large">
                  {inspectionDetails.parkType === 'ONSHORE' && <AvatarOnshore />}
                  {inspectionDetails.parkType !== 'ONSHORE' && <AvatarOffshore />}
                </Icon>
              </Avatar>
              <div className="d-flex flex-column" style={{ maxWidth: '400px' }}>
                {isExact && (
                  <Tooltip title={inspection.title} placement="top">
                    <Typography className="title" color="textPrimary">
                      {inspection.title}
                    </Typography>
                  </Tooltip>
                )}
                {!isExact && <LinkHref title={inspection.title} goToFunc={goToInspectionDetails} className="title" />}
                <Typography color="textSecondary">{inspectionDetails.wbs}</Typography>
              </div>
            </Grid>
            <Grid xs={4} className="col-md-4">
              <Grid className="row align-items-center justify-content-end">
                {inspection.checkedOutForMobile && (
                  <div className="status-lock">
                    <Lock />
                    <p>Inspection in progress on mobile</p>
                  </div>
                )}
                {isExact && !inspection.checkedOutForMobile && <InspectionDetailsHeader />}
              </Grid>
              {!inspection.checkedOutForMobile && (
                <InspectionStatusLabel removeInspection={removeInspection} inspection={inspection} />
              )}
            </Grid>
          </Grid>
          <Grid>
            <Grid className="row no-gutters justify-content-start align-items-center">
              <Typography color="textSecondary">{inspectionDetails.identifier}</Typography>
            </Grid>
          </Grid>
          <div className="w-100">
            {showInspectionReportLoader ? <Loader alignEnd /> : <InspectionReport inspection={inspectionDetails} />}
          </div>
          <Grid container className="row no-gutters align-items-center">
            {inspectionDetails.wbs && (
              <Grid xs={12} md={4} className="col-md-4 pt-3">
                <Typography color="textSecondary" variant="body2">
                  Park/Package
                </Typography>

                <Typography color="textPrimary" variant="body1">
                  {inspectionDetails.wbs}
                </Typography>
              </Grid>
            )}

            {inspectionDetails.mainSystem && (
              <Grid xs={12} md={4} className="col-md-4 pt-3">
                <Typography color="textSecondary" variant="body2">
                  Main System
                </Typography>

                <Typography color="textPrimary" variant="body1">
                  {inspectionDetails.mainSystem}
                </Typography>
              </Grid>
            )}

            {inspectionDetails.subSystem && (
              <Grid xs={12} md={4} className="col-md-4 pt-3">
                <Typography color="textSecondary" variant="body2">
                  Sub System
                </Typography>

                <Typography color="textPrimary" variant="body1">
                  {inspectionDetails.subSystem}
                </Typography>
              </Grid>
            )}

            {inspectionDetails.siteFacility && (
              <Grid xs={12} md={4} className="col-md-4 pt-3">
                <Typography color="textSecondary" variant="body2">
                  Site / Facility
                </Typography>

                <Typography color="textPrimary" variant="body1">
                  {inspectionDetails.siteFacility}
                </Typography>
              </Grid>
            )}

            {inspectionDetails.leadInspector && (
              <Grid xs={12} md={4} className="col-md-4 pt-3">
                <Typography color="textSecondary" variant="body2">
                  Inspector
                </Typography>

                <Typography color="textPrimary" variant="body1">
                  {inspectionDetails.leadInspector}
                </Typography>
              </Grid>
            )}

            {inspectionDetails.coInspectors.length > 0 && isOMInspection && (
              <Grid xs={12} md={12} className="col-md-12 pt-3">
                <Typography color="textSecondary" variant="body2">
                  Co-Inspectors
                </Typography>
                {inspectionDetails.coInspectors?.map((inspector) => (
                  <Typography key={inspector.id} color="textPrimary" variant="body1">
                    {inspector.fullName}
                  </Typography>
                ))}
              </Grid>
            )}

            {inspectionDetails.deadline && (
              <Grid xs={12} md={12} className="col-md-12 pt-3">
                <Typography color="textSecondary" variant="body2">
                  Deadline
                </Typography>
                <Typography color="textPrimary" variant="body1">
                  {moment(inspectionDetails.deadline).format(DATE_FORMAT)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        {inspection.status === INSPECTION_STATUSES.draft && inspection?.mainSystem?.mainSystemCode && (
          <InspectionDetailsTodosList
            mainSystemId={inspection?.mainSystem?.id}
            inspectionId={inspection.id}
            subSystemId={inspection?.subSystem?.id}
          />
        )}
        {isExact && inspectionDetails?.findings?.length > 0 && (
          <>
            {inspectionIsClosed && (
              <CreateWorkflowFromSelected selectedIds={selectedIds} findings={inspectionDetails?.findings} />
            )}
            <Table
              className="mt-3"
              config={FINDINGS_CONFIG}
              items={inspectionDetails.findings}
              withCheckboxes={inspectionIsClosed && !canManipulateTableData}
              selectedIds={selectedIds}
              handleRowCheck={selectFinding}
              selectAllCheckboxes={selectAllFindings}
              tableUniqueKey={TableUniqueKey.INSPECTION_DETAILS}
            />
          </>
        )}
      </Grid>

      <Route path="/inspectionDetails/:inspectionId/newFinding" component={NewFindingWebWithAuth} />
      <Route path="/inspectionDetails/:inspectionId/editFinding/:editFindingId" component={FindingEditWebWithAuth} />
      <Route path="/inspectionDetails/:inspectionId/findingDetails/:id" component={FindingDetailsWebWithAuth} />
      <div className="row no-gutters justify-content-end">
        {isExact && inspectionDetails.structureType === INSPECTION_STRUCTURE.standalone && (
          <Fab
            id="show-checklist:button"
            puppet-data="show-checklist:button"
            size="medium"
            variant="extended"
            color="secondary"
            className={`${classes.button} ${classes.checklistButton}`}
            onClick={openDrawer}
            disabled={!checklistAssign}
          >
            <Icon className="svg-medium">fact_check outline</Icon>
            <span className="ml-2">Checklist</span>
          </Fab>
        )}

        {isExact &&
          !inspection.checkedOutForMobile &&
          [INSPECTION_STATUSES.planned, INSPECTION_STATUSES.ongoing].includes(inspection.status) &&
          inspection.structureType !== 'MAIN' && (
            <ConfirmModal
              confirmFn={handleExecuteOnMobile}
              confirmTitle="Are you sure you want to checkout this inspection for mobile execution only?"
            >
              <Fab
                id="execute-on-mobile:button"
                puppet-data="execute-on-mobile:button"
                size="medium"
                variant="extended"
                color="primary"
                disabled={shouldExecuteBeDisabled(inspection)}
                className={`${classes.button}`}
              >
                <ScreenLockPortrait />
                Execute on Mobile
              </Fab>
            </ConfirmModal>
          )}
        {isExact && canCreateFinding && (
          <Fab
            id="add-finding:button"
            puppet-data="add-finding:button"
            size="medium"
            variant="extended"
            color="secondary"
            disabled={inspection.checkedOutForMobile}
            className={`${classes.button} ${classes.addButton}`}
            onClick={goToFindingAdd}
          >
            <Icon className="add-icon svg-medium">add</Icon>
            {inspection.status === INSPECTION_STATUSES.draft ? 'Todo' : 'Finding'}
          </Fab>
        )}
        {isExact && inspectionDetails.status === INSPECTION_STATUSES.draft && (
          <ConfirmModal confirmFn={handlePublish} confirmTitle="Are you sure you want to publish inspection?">
            <Fab
              id="publish-inspection:button"
              puppet-data="publish-inspection:button"
              size="medium"
              variant="extended"
              color="primary"
              className={classes.button}
              disabled={inspection.checkedOutForMobile}
            >
              Publish Inspection
            </Fab>
          </ConfirmModal>
        )}
        {isExact && showSubInspectionButton(inspectionDetails) && (
          <Fab
            id="add-subinspection:button"
            puppet-data="add-subinspection:button"
            size="medium"
            variant="extended"
            color="primary"
            className={classes.button}
            onClick={handleAddSubInspection}
            disabled={inspection.checkedOutForMobile}
          >
            <Icon className="add-icon svg-medium">add</Icon>
            Sub-inspection
          </Fab>
        )}

        {isExact && inspectionDetails.partOfPlanInspections.length > 0 && (
          <div className="col-md-12 pt-3">
            <Typography color="textSecondary" variant="body2">
              Sub Inspections
            </Typography>
            {inspectionDetails.partOfPlanInspections?.map((item, index) => {
              const inspectionModel = new NewInspectionModel(item).parseDetails();
              return (
                <InspectionItem
                  className={`w-100 ${index === 0 ? 'mt-1' : 'mt-4'}`}
                  key={inspectionModel.id}
                  inspection={inspectionModel}
                  index={index}
                  idKey="id"
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

InspectionDetailsWeb.propTypes = {
  checklistAssign: PropTypes.bool,
  checkOutForMobile: PropTypes.func.isRequired,
  classes: PropTypes.shape({}),
  goToFindingAdd: PropTypes.func.isRequired,
  goToInspectionDetails: PropTypes.func.isRequired,
  goToMainInspection: PropTypes.func.isRequired,
  handleAddSubInspection: PropTypes.func.isRequired,
  handlePublish: PropTypes.func.isRequired,
  inspection: PropTypes.objectOf(PropTypes.any).isRequired,
  inspectionDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  isExact: PropTypes.bool.isRequired,
  removeInspection: PropTypes.func.isRequired,
  selectAllFindings: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  selectFinding: PropTypes.func.isRequired,
  setInspectionFindings: PropTypes.func,
  showAddFindingButton: PropTypes.func.isRequired,
  showSubInspectionButton: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

InspectionDetailsWeb.defaultProps = {
  checklistAssign: false,
  classes: {},
  selectedIds: [],
};

export default withInspectionDetails(withStyles(styles)(InspectionDetailsWeb));
