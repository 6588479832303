import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CHECKLIST_STATUSES } from 'modules/app/config/config';

import { Icon, IconButton, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  getChecklists,
  setChecklist,
  toggleChecklistDuplicateModal,
  toggleChecklistStatus,
} from './checklistEditor.actions';

const styles = {
  icon: {
    marginRight: '1em',
  },
};

enum MenuButtonNames {
  EDIT = 'Edit',
  DUPLICATE = 'Duplicate',
  ACTIVATE = 'Activate',
  DEACTIVATE = 'Deactivate',
}

interface ChecklistsActionMenuProps {
  item: IChecklist;
}

const ChecklistsActionMenu = ({ item }: ChecklistsActionMenuProps) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { currentWbs } = useSelector((state: IStore) => state.checklistEditor);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  // eslint-disable-next-line consistent-return
  const displayToggleName = (name): MenuButtonNames => {
    switch (name) {
      case CHECKLIST_STATUSES.active:
        return MenuButtonNames.DEACTIVATE;
      case CHECKLIST_STATUSES.deactivated:
        return MenuButtonNames.ACTIVATE;
      case CHECKLIST_STATUSES.draft:
        return MenuButtonNames.ACTIVATE;
      default:
        break;
    }
  };

  // eslint-disable-next-line consistent-return
  const handleStatusName = (name) => {
    switch (name) {
      case CHECKLIST_STATUSES.active:
        return CHECKLIST_STATUSES.deactivated;
      case CHECKLIST_STATUSES.deactivated:
        return CHECKLIST_STATUSES.active;
      case CHECKLIST_STATUSES.draft:
        return CHECKLIST_STATUSES.active;
      default:
        break;
    }
  };

  const handleItemClick = async (_, key) => {
    switch (key) {
      case 'EDIT':
        history.push(`/admin/checklists/${item?.id}`, { item });
        break;
      case 'DUPLICATE':
        dispatch(setChecklist(item));
        dispatch(toggleChecklistDuplicateModal());
        break;
      case 'STATUS':
        await dispatch(toggleChecklistStatus(item?.id, { status: handleStatusName(item.status) }));
        dispatch(getChecklists(currentWbs));
        break;
      default:
        break;
    }
  };

  const actionMenuListDraft = {
    EDIT: MenuButtonNames.EDIT,
    DUPLICATE: MenuButtonNames.DUPLICATE,
    STATUS: displayToggleName(item.status),
  };

  const actionMenuDeactivated = {
    DUPLICATE: MenuButtonNames.DUPLICATE,
  };

  const actionMenuActive = {
    DUPLICATE: MenuButtonNames.DUPLICATE,
    STATUS: displayToggleName(item.status),
  };

  const handleActionMenuOptions = (status) => {
    switch (status) {
      case CHECKLIST_STATUSES.active:
        return actionMenuActive;
      case CHECKLIST_STATUSES.deactivated:
        return actionMenuDeactivated;
      case CHECKLIST_STATUSES.draft:
        return actionMenuListDraft;
      default:
        return actionMenuListDraft;
    }
  };

  const renderMenu = Object.entries(handleActionMenuOptions(item.status)).map(([key, value]) => {
    const handleMenuItemClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleItemClick(e, key);
    };

    return (
      <MenuItem key={key} onClick={handleMenuItemClick}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {value}
        </div>
      </MenuItem>
    );
  });

  return (
    <div>
      <IconButton aria-label="More" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <Icon className="MoreActionMenu">more_vert</Icon>
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: 200,
          },
        }}
      >
        {renderMenu}
      </Menu>
    </div>
  );
};

export default withStyles(styles)(ChecklistsActionMenu);
