import _get from 'lodash/get';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { CONNECTION_STATUS_ID, dataToStoreOffline } from 'modules/app/config/config';
import BREADCRUMB_ROUTING_CONFING from 'modules/common/components/breadcrumb/breadcrumb.actions';
import moment from 'moment';
import Api from 'services/api';
import Db from 'services/db';
import Notifications from 'services/notifications';
import Sync from 'services/sync';

import appInsights from '../../../../services/telemetry';

export const MOBILE_BROWSER =
  /Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i;

const namespace = 'APP';

const SET_TITLE = `${namespace}_SET_TITLE`;
const SET_SHOW_HEADER = `${namespace}_SHOW_HEADER`;
const SET_INIT_IN_PROGRESS = `${namespace}_SET_INIT_IN_PROGRESS`;
const SET_MOBILE = `${namespace}_SET_MOBILE`;
const SET_SHOW_BANNER = `${namespace}_SET_SHOW_BANNER`;
const SET_SYNC_ERRORS = `${namespace}_SET_SYNC_ERRORS`;
const CLEAR_SYNC_ERRORS = `${namespace}_CLEAR_SYNC_ERRORS`;
const SET_CRUMB = `${namespace}_SET_CRUMB`;
const SET_IS_TOAST_VISIBLE = `${namespace}_SET_IS_TOAST_VISIBLE`;
const SET_ONLINE = `${namespace}_SET_ONLINE`;
const SET_OFFLINE = `${namespace}_SET_OFFLINE`;
const SET_SYNC_STATUS = `${namespace}_SET_SYNC_STATUS`;
const SET_SYNC_INDICATOR = `${namespace}_SET_SYNC_INDICATOR`;
const SET_SYNC_STARTED = `${namespace}_SET_SYNC_STARTED`;
const SET_SYNC_PROGRESS = `${namespace}_SET_SYNC_PROGRESS`;
const INCREMENT_SYNC_PROGRESS = `${namespace}_INCREMENT_SYNC_PROGRESS`;

const setTitle = (payload) => ({
  type: SET_TITLE,
  payload,
});

const setShowHeader = (payload) => ({
  type: SET_SHOW_HEADER,
  payload,
});

const setShowBanner = (payload) => ({
  type: SET_SHOW_BANNER,
  payload,
});

const setSyncStatus = (payload) => ({
  type: SET_SYNC_STATUS,
  payload,
});

const setSyncProgress = (payload) => ({
  type: SET_SYNC_PROGRESS,
  payload,
});

const incrementProgress = (payload) => ({
  type: INCREMENT_SYNC_PROGRESS,
  payload,
});

const setSyncIndicator = (payload) => ({
  type: SET_SYNC_INDICATOR,
  payload,
});

const setSyncErrors = (payload) => {
  appInsights.trackException(payload);

  return {
    type: SET_SYNC_ERRORS,
    payload: [...payload],
  };
};

const clearSyncErrors = () => ({
  type: CLEAR_SYNC_ERRORS,
});

const getData = async ({ url, dbName, index, mapData, syncDateField, syncDate }) => {
  try {
    let data = await Api.get(url);
    await Db.removeDb(dbName);

    if (index) {
      await Db.createIndex(dbName, index);
    }

    if (mapData) {
      data = mapData(data);
    }

    await Db.batchInsert(dbName, data);

    // check and update synchro update dates
    const synchroItem = await Db.findOne(
      'synchro_update_date',
      {
        selector: { dbName },
      },
      false,
    );

    if (synchroItem) {
      synchroItem.syncDate = syncDate;
      await Db.update('synchro_update_date', synchroItem);
      return;
    }

    await Db.insert('synchro_update_date', {
      syncDate,
      syncDateField,
      url,
      dbName,
    });
  } catch (e) {
    throw e;
  }
};

const checkUpdateDates = async () => {
  const dates = await Api.get('/api/structures/sync/status');
  const data = await Db.getAll('synchro_update_date');
  const synchroDates = Db.mapData(data);

  // if (dates.status === 'IN_PROGRESS') {
  //   return [];
  // }
  const dataToSync = dataToStoreOffline.reduce((reduced, item) => {
    if (item.index) {
      Db.createIndex(item.dbName, item.index);
    }

    const response = dates[item.syncDateField];
    const savedDate = synchroDates.find((synchroDate) => synchroDate.syncDateField === item.syncDateField);
    const itemToSynchronize = Object.assign(item, {
      syncDateField: item.syncDateField,
      syncDate: response,
    });

    const responseUnix = moment(response).unix();
    const savedDateUnix = moment(_get(savedDate, 'syncDate', new Date()) || new Date()).unix();

    if (!_get(savedDate, 'syncDate') || !response || moment(responseUnix).isAfter(savedDateUnix)) {
      reduced.push(itemToSynchronize);
    }

    return reduced;
  }, []);

  return dataToSync;
};

const getDataForOffline = async (dispatch, isWeb = false) => {
  if (!navigator.onLine) {
    return;
  }

  try {
    let itemsToSynchronize = await checkUpdateDates();
    if (isWeb) {
      itemsToSynchronize = itemsToSynchronize.filter((item) => !item.mobileOnly);
    }

    await Promise.all(itemsToSynchronize.map((i) => getData(i)));
  } catch (e) {
    console.error(e);
    appInsights.trackException(e);
    if (e.code === 401) {
      genericErrorHandler(e);
    }
  }
};

const doSync = async (dispatch, throwError = false) => {
  const SyncErrors = await import('../syncErrors/syncErrors.container');

  try {
    await Sync.syncAll(true, (errors) => {
      dispatch(setSyncErrors(errors));
      Notifications.showBanner({ component: SyncErrors.default });
    });
  } catch (errors) {
    dispatch(setSyncErrors(errors));
    Notifications.showBanner({ component: SyncErrors.default });

    if (throwError) {
      throw errors;
    }
  }
};

const registerSync = () => async (dispatch) => {
  const SyncErrors = await import('../syncErrors/syncErrors.container');

  try {
    await Sync.registerSync((errors) => {
      dispatch(setSyncErrors(errors));
      Notifications.showBanner({ component: SyncErrors.default });
    });
  } catch (errors) {
    dispatch(setSyncErrors(errors));
    Notifications.showBanner({ component: SyncErrors.default });
    throw errors;
  }
};

const setOnlineConnectionStatus = () => async (dispatch) => {
  if (!navigator.onLine) {
    return;
  }

  await Db.retryUntilWritten('connection_status', CONNECTION_STATUS_ID, (obj = { _id: CONNECTION_STATUS_ID }) => ({
    ...obj,
    online: true,
    updatedAt: new Date().toISOString(),
  }));

  dispatch(registerSync());
};

const showConnectionStatusInfo = async () => {
  const ConnectionStatus = await import('../connectionStatus/connectionStatus.container');
  Notifications.showBanner({ component: ConnectionStatus.default });
};

const closeConnectionStatusInfo = async () => {
  const ConnectionStatus = await import('../connectionStatus/connectionStatus.container');
  Notifications.closeBanner({ component: ConnectionStatus.default });
};

const handleOfflineStatus = async (dispatch) => {
  await Db.retryUntilWritten('connection_status', CONNECTION_STATUS_ID, (obj = { _id: CONNECTION_STATUS_ID }) => ({
    ...obj,
    online: false,
    updatedAt: new Date().toISOString(),
  }));

  closeConnectionStatusInfo();
  dispatch({ type: SET_OFFLINE });
};

const handleOnlineStatus = async (dispatch) => {
  if (!('SyncManager' in window)) {
    await doSync(dispatch);
  }

  showConnectionStatusInfo();
  dispatch(setSyncStarted(false));
  dispatch({ type: SET_ONLINE });
};

const initListeners = async (dispatch) => {
  window.addEventListener('online', async () => await handleOnlineStatus(dispatch));
  window.addEventListener('offline', async () => await handleOfflineStatus(dispatch));

  if (navigator && navigator.serviceWorker) {
    const SyncErrors = await import('../syncErrors/syncErrors.container');

    navigator.serviceWorker.addEventListener('message', ({ data }) => {
      dispatch(setSyncErrors(data));
      Notifications.showBanner({ component: SyncErrors.default });
    });
  }
};

const initApp = async (dispatch, isMobile) => {
  if (!isMobile) {
    await getDataForOffline(dispatch, true);
    return;
  }

  await initListeners(dispatch);
  if (!navigator.onLine) {
    await handleOfflineStatus();
    return;
  }

  dispatch({ type: SET_ONLINE });

  const connectionStatusDoc = await Db.findOne('connection_status', CONNECTION_STATUS_ID, false);

  if (connectionStatusDoc && connectionStatusDoc.online === false) {
    showConnectionStatusInfo();
  } else {
    await doSync(dispatch, true);
    await getDataForOffline(dispatch);
  }
};

const init = () => (dispatch, getState) => {
  const {
    app: { isMobile },
  } = getState();

  dispatch({
    type: SET_INIT_IN_PROGRESS,
    payload: true,
  });

  initApp(dispatch, isMobile)
    .catch(() => {
      console.log('init catch');
    })
    .finally(() => {
      dispatch({
        type: SET_INIT_IN_PROGRESS,
        payload: false,
      });
    });
};

const checkMobile = () => (dispatch) => {
  const isMobile = !!navigator.userAgent.match(MOBILE_BROWSER);

  dispatch({
    type: SET_MOBILE,
    payload: isMobile,
  });

  return Promise.resolve(isMobile);
};

const discardSyncChanges = (dataToDiscard) => async (dispatch) => {
  const promises = dataToDiscard.map(async (item) => {
    const id = _get(item, 'data._id');
    await Db.delete(item.db, id, _get(item, 'data._rev'));

    if (item.db === 'sync_attachments') {
      const file = await Db.get('sync_attachments_files', id);
      await Db.delete('sync_attachments_files', file._id, file._rev);
    }
  });

  await Promise.all(promises);
  dispatch(init());
};

const setIsToastVisible = (payload) => ({
  type: SET_IS_TOAST_VISIBLE,
  payload,
});

const setSyncStarted = (payload) => ({
  type: SET_SYNC_STARTED,
  payload,
});

const createCrumb = (path, params) => async (dispatch, getState) => {
  const config = BREADCRUMB_ROUTING_CONFING[path];

  if (!config) {
    return;
  }

  const state = getState();
  const {
    app: { isMobile },
  } = state;

  if (!isMobile) {
    const payload = config(state, params);

    dispatch({
      type: SET_CRUMB,
      payload,
    });
  }
};

export {
  checkMobile,
  clearSyncErrors,
  createCrumb,
  discardSyncChanges,
  getData,
  getDataForOffline,
  init,
  registerSync,
  setIsToastVisible,
  setOnlineConnectionStatus,
  setShowBanner,
  setShowHeader,
  setSyncErrors,
  setSyncIndicator,
  setSyncStarted,
  setSyncStatus,
  setTitle,
  setSyncProgress,
  incrementProgress,
};

export {
  CLEAR_SYNC_ERRORS,
  SET_CRUMB,
  SET_INIT_IN_PROGRESS,
  SET_IS_TOAST_VISIBLE,
  SET_MOBILE,
  SET_OFFLINE,
  SET_ONLINE,
  SET_SHOW_BANNER,
  SET_SHOW_HEADER,
  SET_SYNC_ERRORS,
  SET_SYNC_INDICATOR,
  SET_SYNC_STARTED,
  SET_SYNC_STATUS,
  SET_TITLE,
  SET_SYNC_PROGRESS,
  INCREMENT_SYNC_PROGRESS,
};
