import React, { FC, memo } from 'react';

import { TableBody, TableCell, TableRow } from '@material-ui/core';

import Loader from '../loader/loader.component';
import { useTableStyles } from './styles';

interface ITableLoaderProps {
  columns: number;
}

const TableLoader: FC<ITableLoaderProps> = memo(({ columns }) => {
  const classes = useTableStyles();

  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={columns} className={classes.cell}>
          <Loader />
        </TableCell>
      </TableRow>
    </TableBody>
  );
});

export default TableLoader;
