import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import { FindingAttachment, FindingPhoto, AvatarOnshore, AvatarOffshore, FindingDescription } from 'assets/icons/index';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { registerSync } from 'modules/app/components/app/app.actions';
import { FILE_PARENTS, ALLOWED_FILE_EXTENSIONS } from 'modules/app/config/config';
import { PHOTO_TYPE, AUDIO_TYPE, DOC_TYPE } from 'modules/common/components/filesList/filesList.actions';
import FilesList from 'modules/common/components/filesList/filesListMobile.component';
import Loader from 'modules/common/components/loader/loader.component';
import Acl, { PRIVILEGE_KEYS } from 'services/acl';

import { Box, Card, CardContent, Avatar, Icon, Tab, Tabs, Typography, Grid } from '@material-ui/core';
import { FolderOpen } from '@material-ui/icons';

import { getFinding, clearData } from '../../modules/findings/components/findingDetails/findingDetails.actions';
import NewFindingModel from '../../modules/findings/components/newFinding/newFinding.model';
import FindingDetailsFieldsMobile from './findingDetailsFieldsMobile.component';

const swipeableConatinerStyles = {
  minHeight: 140,
};

const slideStyle = {
  position: 'relative',
};

const DEFAULT_TAB_INDEX = 1;

interface IIsLoading {
  finding: {
    isLoading: boolean;
  };
}
interface IFindingData {
  inspection: {
    id: string;
    title: string;
    wbs: string;
    leadInspector: string;
    status: string;
    parkName: string;
  };
  finding: IFinding;
}

interface RouteParams {
  inspectionId: string;
  id: string;
}

const FindingDetails = () => {
  const [tabIndex, setTabIndex] = useState(DEFAULT_TAB_INDEX);
  const [shouldRefreshFiles, setShouldRefreshFiles] = useState({});
  const [pending, setPending] = useState(false);
  const [swipeableActions, setSwipeableActions] = useState<any>(null);

  const findingData: IFindingData = useSelector((state: any) => state.finding.data);
  const inspectionData: IInspectionData = useSelector((state: any) => state.inspection.data);
  const isLoading: IIsLoading = useSelector((state: any) => state.finding.isLoading);

  const dispatch = useDispatch();
  const { inspectionId, id } = useParams<RouteParams>();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getFinding(inspectionId, id));
    };

    fetchData();

    return () => {
      dispatch(clearData());
    };
  }, []);

  const finding = new NewFindingModel(findingData, inspectionData).parseDetails();

  const extraParams = {
    inspectionId,
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isEmpty(inspectionData) || isEmpty(finding)) {
    return null;
  }

  const updateTabHeight = () => {
    if (swipeableActions) {
      setTimeout(() => {
        swipeableActions.updateHeight();
      }, 200);
    }
  };

  const handleShouldRefreshFiles = (type) => {
    setShouldRefreshFiles((prev) => ({
      ...prev,
      [type]: Date.now(),
    }));
  };

  const tabConfig = {
    0: {
      id: 'description',
      tabIcon: <FindingDescription className="icon-dark" />,
      renderTab: (finding) => (
        <Box
          key="finding-description-tab"
          sx={{
            padding: 4,
            whiteSpace: 'pre-wrap',
            overflow: 'auto',
          }}
        >
          {finding.description}
        </Box>
      ),
    },
    1: {
      id: 'photos',
      tabIcon: <FindingPhoto className="icon-dark" />,
      attachmentOptions: {
        icon: <FolderOpen />,
        inputProps: {
          accept: ALLOWED_FILE_EXTENSIONS.photos.mimes.join(', '),
        },
        type: PHOTO_TYPE,
      },
      renderTab: (finding, selectedTab, isEditable) => {
        return (
          <FilesList
            id={id}
            finding={finding}
            extraParams={extraParams}
            key="finding-photos-tab"
            fileType={PHOTO_TYPE}
            afterLoad={updateTabHeight}
            type={FILE_PARENTS.finding}
            registerSync={registerSync}
            tab={tabConfig[1]}
            selectedTab={selectedTab}
            refresh={shouldRefreshFiles[PHOTO_TYPE]}
            setPendingCb={setPending}
            canAdd={isEditable}
            canEdit={isEditable}
            allowedTxt={ALLOWED_FILE_EXTENSIONS.photos.desc}
          />
        );
      },
    },
    2: {
      id: 'docs',
      tabIcon: <FindingAttachment className="icon-dark" />,
      attachmentOptions: {
        icon: <FindingAttachment className="icon-light" />,
        inputProps: { accept: ALLOWED_FILE_EXTENSIONS.docs.mimes.join(', ') },
        type: DOC_TYPE,
      },
      renderTab: (finding, selectedTab, isEditable) => (
        <FilesList
          id={id}
          extraParams={extraParams}
          key="finding-docs-tab"
          finding={finding}
          fileType={DOC_TYPE}
          afterLoad={updateTabHeight}
          type={FILE_PARENTS.finding}
          registerSync={registerSync}
          tab={tabConfig[2]}
          selectedTab={selectedTab}
          differentFileTypeCb={handleShouldRefreshFiles}
          setPendingCb={setPending}
          canAdd={isEditable}
          canEdit={isEditable}
          allowedTxt={ALLOWED_FILE_EXTENSIONS.docs.desc}
        />
      ),
    },
  };

  const canEditFinding = (wbs) => Acl.hasPrivilege(PRIVILEGE_KEYS.FINDINGS_EDIT, wbs);

  const handleChange = (_, newTabIndex) => {
    if (pending) return;
    setTabIndex(newTabIndex);
  };

  const handleChangeIndex = (newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleSwipeableActions = (actions) => {
    setSwipeableActions(actions);
  };

  const tabs = Object.values(tabConfig);
  const selectedTab = tabConfig[tabIndex];
  const wbs = _get(inspectionData, 'wbsDetails.name', null);
  const wbsId = _get(inspectionData, 'wbsDetails.id', null);
  const isEditable = canEditFinding(wbsId);

  return (
    <Grid id="finding-details:div" className="d-flex flex-column flex-fill flex-shrink-0">
      <Card className="finding-details-component finding-card">
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
            <Avatar className="avatar">
              <Icon className="svg-extra-large">
                {inspectionData.parkType === 'ONSHORE' ? <AvatarOnshore /> : <AvatarOffshore />}
              </Icon>
            </Avatar>
            <Box sx={{ minWidth: 0, overflow: 'hidden', whiteSpace: 'break-spaces' }}>
              <Typography className="title" color="textPrimary">
                {inspectionData.title}
              </Typography>
              {wbs && (
                <Box style={{ overflowWrap: 'break-word' }}>
                  <Typography color="textSecondary">{wbs}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      <FindingDetailsFieldsMobile {...finding} />
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        indicatorColor="primary"
        variant="fullWidth"
        scrollButtons="on"
        className="tabs"
      >
        {tabs.map((tab) => (
          <Tab id={`${tab.id}-tab:button`} key={tab.id} icon={<Icon className="svg-medium">{tab.tabIcon}</Icon>} />
        ))}
      </Tabs>
      <SwipeableViews
        index={tabIndex}
        onChangeIndex={handleChangeIndex}
        action={handleSwipeableActions}
        animateHeight
        containerStyle={swipeableConatinerStyles}
        slideStyle={slideStyle}
      >
        {tabs.map((tab) => (tab.renderTab ? tab.renderTab(finding, selectedTab.id, isEditable) : null))}
      </SwipeableViews>
    </Grid>
  );
};

export default FindingDetails;

// 2: {  // TODO: tmp solution
//   id: 'audio',
//   tabIcon: <FindingVoiceRecording className="icon-dark" />,
//   attachmentOptions: {
//     icon: <FindingVoiceRecording className="icon-light" />,
//     inputProps: {
//       accept: ALLOWED_FILE_EXTENSIONS.audios.mimes.join(', '),
//     },
//     type: AUDIO_TYPE,
//   },
//   renderTab: (finding, selectedTab, canEditFinding) => {
//     const { shouldRefreshFiles } = this.state;

//     return (
//       <FilesList
//         id={params.id}
//         extraParams={extraParams}
//         finding={finding}
//         key="finding-audio-tab"
//         fileType={AUDIO_TYPE}
//         afterLoad={this.updateTabHeight}
//         type={FILE_PARENTS.finding}
//         registerSync={registerSync}
//         tab={this.tabConfig[2]}
//         selectedTab={selectedTab}
//         refresh={shouldRefreshFiles[AUDIO_TYPE]}
//         setPendingCb={this.setPending}
//         canAdd={canEditFinding}
//         canEdit={canEditFinding}
//         allowedTxt={ALLOWED_FILE_EXTENSIONS.audios.desc}
//       />
//     );
//   },
// },
