export default {
  1000: 'Cannot publish invalid inspection.',
  1001: 'Inspection already published.',
  1002: 'Cannot publish ongoing inspection.',
  1003: 'Cannot publish finished inspection.',
  1004: 'Cannot publish closed inspection.',
  1005: 'Cannot start draft inspection.',
  1006: 'Cannot start finished inspection.',
  1007: 'Inspection already started.',
  1008: 'Cannot start closed inspection.',
  1009: 'Cannot finish draft inspection.',
  1010: 'Cannot finish planned inspection.',
  1011: 'Cannot finish closed inspection.',
  1012: 'Inspection already finished.',
  1013: 'Cannot close draft inspection.',
  1014: 'Cannot close planned inspection.',
  1015: 'Cannot close ongoing inspection.',
  1016: 'Inspection already closed.',
  1017: 'Inspection cannot be edited.',
  1018: 'Cannot add finding to invalid inspection.',
  1019: 'Cannot add subinspection to invalid inspection.',
  1020: 'Cannot add subinspection. Multilevel hierarchies are not allowed.',
  1021: "Subinspection doesn't match main inspection domain.",
  1022: "Cannot add subinspection. It doesn't match main inspection deadline.",
  1023: 'Cannot edit inspection with findings.',
  1024: 'Cannot edit inspection with subinspections.',
  1025: 'Cannot finish inspection with not finished, draft or closed subinspection.',
  1026: 'Cannot close inspection with not closed or draft subinspection.',
  1027: 'Cannot find user.',
  1028: 'Cannot find site.',
  1029: 'Cannot find failure mode.',
  1030: 'Cannot find WBS.',
  1031: 'Cannot find inspection.',
  1032: 'Cannot find finding.',
  1033: 'Cannot find RDS.',
  1034: 'Cannot find site facility.',
  1035: 'Cannot close invalid inspection.',
  1036: 'Cannot generate inspection report.',
  1037: 'Cannot find coinspector.',
  1038: 'User is not permitted to perform action.',
  1039: 'Invalid inspection type.',
  1040: 'Invalid inspection status.',
  1041: 'User is not permitted to save attachment.',
  1043: 'User role association already exists.',
  1044: 'User has no appropriate role in WBS.',
  1045: 'Cannot find active user role association.',
  1046: 'Wrong email address.',
  1047: 'Missing sendgrid value.',
  1048: 'Cannot deactivate user role association main user required.',
  1049: 'User has no permission to attachment.',
  1050: 'User role cannot be determined automatically.',
  1051: 'User role is not assigned to the user in role association.',
  1052: 'Invalid attachment metadata.',
  1053: 'Cannot deactivate user role association. There is an ongoing workflow user is assigned as participant.',
  1054: 'Cannot deactivate user role association. There is an ongoing inspection currently assigned to the user.',
  1055: 'Invalid signature metadata.',
  1056: 'User has no permission to parent of attachment.',
  1057: 'Cannot save attachment. Wrong workflow part type.',
  1058: 'Cannot find attachment.',
  1059: 'Cannot save attachment. Wrong workflow part status.',
  1060: 'Cannot save attachment. Wrong workflow status.',
  1061: 'Error creating report',
  1062: 'Cannot find park',
  1063: 'Cannot find main system',
  1064: 'Cannot find sub system',
  1065: 'Park code is required in functional location code',
  1066: 'Main system code is required in function location code if sub system code is provided',
  1067: 'File is too big',
  1068: 'Cannot modify finding from closed inspection',
  1069: 'Cannot save attachment. Wrong inspection status.',
  1070: 'Inspection has invalid findings',
  1071: 'Inspection is invalid',
  1072: 'Cannot close inspection with invalid findings.',
  1073: 'Finding is already assigned to workflow.',
  1074: 'Only ongoing workflow due date can be changed.',
  1075: 'Only frozen workflow due date can be changed',
  1076: 'Only ongoing workflow can be used to auto generate nc.',
  1077: 'Only frozen workflow can be used to auto generate nc.',
  1078: 'Workflow is frozen, no actions allowed.',
  1079: 'Main inspection deadline cannot be prior its subinspection deadlines.',
  1080: 'Contractor user role association requires site facility.',
  1081: 'Contractor user role association requires site facility from selected WBS.',
  1082: 'Only single active contractor for particular wbs and site facility can exist.',
  1083: 'Only draft workflow can be deleted',
  1084: 'Only ongoing workflow can be invalidated',
  1085: 'Cannot generate Non-Conformity report.',
  1086: 'User exists',
  1087: 'User is not external',
  1088: 'Cannot find contractor email group',
  1089: 'Cannot modify inspection. It is checked out for mobile',
  1090: 'Cannot checked out for mobile main inspeciton.',
  1091: 'Cannot create inspection for inactive wbs',
  1092: 'Cannot create inspection for inactive main system',
  1093: 'Cannot create inspection for inactive sub system',
  1094: 'Cannot create inspection for inactive site facility',
  1095: 'Cannot create workflow for inactive wbs',
  1096: 'Cannot create workflow for inactive sub system',
  1097: 'Cannot create workflow for inactive site facility',
  1098: 'Cannot create workflow for inactive main system',
  1099: 'Cannot create workflow for inactive failure mode',
  1100: 'Cannot generate workflow preliminary report.',
  1101: 'Cannot finish main inspection without any sub-inspection.',
  1102: 'Cannot close main inspection without any sub-inspection.',
  1103: 'User is not an admin for park.',
  1104: 'Cannot find checklist definition.',
  1105: 'Cannot find checklist group definition.',
  1106: 'Cannot find checklist element definition.',
  1107: "Main system doesn't match park for checklist definition.",
  1108: "Sub system doesn't match main system type for checklist element definition.",
  1109: 'Cannot modify active checklist.',
  1110: 'Invalid checklist status change request.',
  1111: 'Checklist definition is not active.',
  1112: 'Checklist park or main system is different from assigned to inspection.',
  1113: "Checklist element definition don't match inspection checklist.",
  1114: 'Invalid checklist items order.',
  1115: 'Cannot finish inspection with ToDo findings.',
  1116: 'Finding sub system is not a child of sub system assigned to inspection.',
  1117: 'Finding main system is different from inspection main system.',
  1118: 'Finding failure mode is not assigned to inspection work package.',
  1119: 'Finding main system type is different from inspection main system type.',
  1120: 'Cannot close inspection before finish report is generated.',
  1121: 'User is not assigned in any role in park or its WBS.',
  1124: 'User cannot modify finding.',
  1125: 'Lead inspector is not inspector in WBS.',
  2001: 'WBS structure incorrect.',
  2003: 'Site facilities inconsistent with WBS.',
  2004: 'Inspection non synchronized with API.',
  2005: 'Workflow not synchronized with API.',
  2006: 'Parks inconsistent with WBS.',
  2007: 'Main systems inconsistent with WBS.',
  2008: 'Sub systems inconsistent with WBS.',
  2009: 'Main systems inconsistent with Parks.',
  2010: 'Sub systems inconsistent with Main systems.',
  2011: 'Main system code is missing',
  2012: 'Site facilities inconsistent with Park.',
  2013: 'Cannot upload attachment.',
  2014: 'Invalid request sent to API',
  3001: 'No active workflow part available.',
  3002: 'Workflow structure incorrect.',
  3003: 'Workflow part transition not allowed.',
  3004: 'Wrong field.',
  3005: 'Cannot edit completed workflow part.',
  3006: 'Cannot reassign workflow part.',
  3007: 'Cannot find workflow part.',
  3008: 'Cannot find workflow.',
  3009: 'Finding is not assigned to workflow.',
  3010: 'Cannot find action taker in role %s. Please contact Work Package Administrator to provide one.',
  3011: 'Cannot change ongoing workflow.',
  3012: 'Incorrect workflow creator.',
  3013: 'Cannot rollback workflow part in workflow that is draft.',
  3014: 'Cannot rollback workflow part in workflow that is closed.',
  3015: 'Cannot rollback the only workflow part in workflow.',
  3016: 'Current workflow part rollback is not permitted.',
  3017: 'User not permitted to rollback workflow part.',
  3018: 'Cannot create workflow from workflow with wrong status.',
  3019: 'Cannot find workflow part definition.',
  3020: 'Cannot find start workflow part.',
  3021: 'Cannot find finish workflow part.',
  3022: 'Cannot create workflow from workflow with wrong type.',
  3023: 'User not permitted to save data. Only current part action taker is allowed to save data',
  3024: 'Cannot assign to workflow finding with wrong inspection status.',
  3025: 'Cannot invalidate origin workflow.',
  3026: 'Failure mode is required.',
  3027: 'Finding WBSes are incorrect. They should match workflow wbs.',
  3028: 'Cannot assign finding to workflow, as it has already been associated with another one.',
  3029: 'Cannot assign WBS on project level. Only package level allowed.',
  3030: 'Contractor is not allowed to create workflow out of finding or observation',
  3031: 'Operation not allowed',
  3032: 'Contractor is not allowed to create observation',
  3033: 'Cannot assign WORK_INFO type of finding to workflow.',
  3034: 'Contractor is not allowed to create Audit',
  3035: 'Not allowed to create Audit workflow out of finding or workflow',
  3036: 'Contractor is not allowed to create Defect Notification',
  4001: 'This field is obligatory.',
  4002: 'Invalid date format.',
  4003: 'Date cannot be from the past.',
  4050: 'Date validation can be applied only to date fields.',
  5001: 'Cannot find report',
  5002: 'Cannot save status report attachement',
  5003: 'Status report cannot be deleted',
  6001: 'Error while acquiring access token',
};
