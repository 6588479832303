import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { debounce } from 'lodash';
import { createCrumb, setShowHeader, setTitle } from 'modules/app/components/app/app.actions';
import { eraseCookie, getCookie, setCookie } from 'modules/app/helpers/utils';
import Filters from 'modules/common/components/filters';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import {
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_SORTING_DIRECTION,
  FILTER_COOKIE_NAMES,
  INSPECTION_STATUSES,
  SORT_DIRECTION,
} from '../../../app/config/config';
import { getInspections } from './inspections.actions';

const mapStateToProps = (state) => ({
  isMobile: state.app.isMobile,
  inspections: state.inspections.items,
  inspectionsTotal: state.inspections.itemsTotal,
  isLoading: state.inspections.isLoading,
});

const mapDispatchToProps = {
  getInspections,
  setTitle,
  setShowHeader,
  createCrumb,
};

const withInspections = (InspectionsComponent) => {
  const Inspections = (props) => {
    const {
      setTitle,
      setShowHeader,
      createCrumb,
      getInspections,
      isMobile,
      match: { params, path },
    } = props;

    const initialValues = JSON.parse(getCookie(FILTER_COOKIE_NAMES.INSPECTIONS));
    const initialSortValue = JSON.parse(getCookie(FILTER_COOKIE_NAMES.INSPECTION_SORT));

    const emptyFilters = {
      search: '',
      showStandaloneInspections: true,
      filters: {
        wbs: [],
        state: [],
      },
    };

    if (initialValues && initialValues.filters && initialValues.filters.state) {
      initialValues.filters.state = emptyFilters.filters.state;
    }

    const [state, setState] = useState({
      ...(initialValues || emptyFilters),
      size: DEFAULT_PAGINATION_PAGE_SIZE,
      sortDirection: initialSortValue || DEFAULT_SORTING_DIRECTION,
      page: 0,
    });

    const [currentType, setCurrentType] = useState(params.type);

    const title = `My inspections${
      ['development', 'test'].includes(process.env.NODE_ENV) ? ` - ${process.env.NODE_ENV}` : ''
    } `;

    const statusFilters = (() => {
      switch (params.type) {
        case 'open':
          return Object.values(INSPECTION_STATUSES)
            .filter((item) => !['INVALIDATED', 'FINISHED', 'CLOSED'].includes(item))
            .map((item) => ({ id: item }));
        case 'finished':
          return Object.values(INSPECTION_STATUSES)
            .filter((item) => ['FINISHED'].includes(item))
            .map((item) => ({ id: item }));
        case 'closed':
          return Object.values(INSPECTION_STATUSES)
            .filter((item) => ['CLOSED'].includes(item))
            .map((item) => ({ id: item }));
        default:
          return [];
      }
    })();

    const config = {
      name: FILTER_COOKIE_NAMES.INSPECTIONS,
      initialFilters: state,
      filters: {
        wbs: {
          caption: 'WBS',
          primaryText: 'description',
          secondaryText: 'name',
        },
        state: {
          caption: 'State',
          primaryText: 'id',
          secondaryText: '',
        },
      },
      sortDirection: state.sortDirection,
      filtersOrderIndex: {
        wbs: 0,
        state: 1,
      },
      chipLabels: {
        wbs: 'Park/Package',
      },
      additionalFilters: {
        state: statusFilters,
      },
    };

    useEffect(() => {
      setShowHeader(true);
      setTitle(title);
    }, [setShowHeader, setTitle, title]);

    const setSorting = () => {
      let sortDirection;
      if (state.sortDirection === SORT_DIRECTION.ASC) {
        sortDirection = SORT_DIRECTION.DESC;
      } else {
        sortDirection = SORT_DIRECTION.ASC;
      }

      const newState = {
        ...state,
        sortDirection,
      };
      setState((state) => newState);
      setCookie(FILTER_COOKIE_NAMES.INSPECTION_SORT, JSON.stringify(sortDirection), 1);
    };

    const setPageSize = useCallback(
      (newPageSize) => {
        const newState = {
          ...state,
          size: newPageSize,
          page: 0,
        };
        setState((state) => newState);
      },
      [state],
    );

    const setPageNumber = (newPageNumber) => {
      const newState = {
        ...state,
        page: newPageNumber,
      };
      setState((state) => newState);
    };

    useEffect(() => {
      createCrumb(path, params);

      if (currentType !== params.type) {
        setCurrentType(params.type);
        setPageSize(DEFAULT_PAGINATION_PAGE_SIZE);
      }

      getInspections(
        {
          status: params.type,
          page: state.page,
          size: state.size,
          sort: state.sortDirection.toUpperCase(),
        },
        { ...state.filters, search: state.search, showStandaloneInspections: state.showStandaloneInspections },
      );
    }, [createCrumb, getInspections, params, path, state, currentType, setPageSize]);

    const handleFiltersChange = (filterState) => {
      const newState = {
        ...state,
        ...filterState,
        page: 0,
      };
      setState((state) => newState);
      setCookie(config.name, JSON.stringify(newState), 1);
    };

    const handleChangeStandalone = () => {
      const newState = {
        ...state,
        filters: {
          ...state.filters,
        },
        showStandaloneInspections: !state.showStandaloneInspections,
      };
      setState(newState);
    };

    const debounced = debounce(handleFiltersChange, 250, {
      maxWait: 1300,
    });

    const pagination = {
      setPageNumber,
      setPageSize,
      page: state.page,
      size: state.size,
    };

    const newProps = { ...props, handleFiltersChange, setSorting };

    const onClearAll = () => {
      eraseCookie(FILTER_COOKIE_NAMES.INSPECTIONS);
      setState((state) => ({
        ...state,
        ...emptyFilters,
        search: state.search,
        showStandaloneInspections: state.showStandaloneInspections,
      }));
    };

    const extendedParameters = {
      showStandaloneInspections: state.showStandaloneInspections,
    };

    return (
      <>
        {!isMobile && (
          <div className="row no-gutters align-items-start justify-content-end pr-5 pl-5 pt-2 pb-2">
            <FormControlLabel
              control={
                <Checkbox
                  checked={!state.showStandaloneInspections}
                  onChange={handleChangeStandalone}
                  name="Show standalone inspections"
                />
              }
              className="m-0 mr-4 mt-2"
              labelPlacement="end"
              label="By Main Inspection"
            />
            <Filters
              withTextSearch
              searchLabel="title"
              callback={debounced}
              config={config}
              extendedParameters={extendedParameters}
              onClearAll={onClearAll}
            />
          </div>
        )}
        <InspectionsComponent {...newProps} pagination={pagination} {...props} state={state} />
      </>
    );
  };

  Inspections.propTypes = {
    setTitle: PropTypes.func.isRequired,
    getInspections: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  return connect(mapStateToProps, mapDispatchToProps)(Inspections);
};

export default withInspections;
