import _get from 'lodash/get';
import { registerSync } from 'modules/app/components/app/app.actions';
import api from 'services/api';

import Db from '../../../../services/db';
import Checklist from './checklist.model';

const DB_NAME = 'sync_inspections_checklist';

/*
 * REDUX ACTION TYPES
 */

const namespace = 'CHECKLISTS';
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const SET_CHECKLIST_DETAILS = `${namespace}_SET_CHECKLIST_DETAILS`;
const CLEAR_DATA = `${namespace}_CLEAR_DATA`;

const getChecklistsByParkId = async (parkId, status = 'ACTIVE') => {
  const doc = await Db.find(
    'checklists',
    {
      selector: {
        parkId,
        status,
      },
    },
    false,
  );

  return doc || [];
};

const getChecklistById = async (id) => {
  const doc = await Db.findOne(
    'checklists',
    {
      selector: {
        id,
      },
    },
    false,
  );
  return doc || {};
};

const getInspectionChecklists = async (inspectionId) => {
  const doc = await Db.findOne(
    DB_NAME,
    {
      selector: {
        inspectionId,
      },
    },
    false,
  );

  return doc;
};

const getChecklistsDetails = (inspection) => async (dispatch) => {
  try {
    const { _id, frontendId, checkListDefinition, checkListDefinitionId } = inspection;
    const inspectionId = frontendId || _id;

    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const checklistId = checkListDefinition?.id || checkListDefinitionId;

    if (inspectionId && checklistId) {
      const template = await getChecklistById(checklistId);

      const checklistDbState = await getInspectionChecklists(inspectionId);
      const elements = _get(checklistDbState, 'elements', []);

      const payload = new Checklist(template, elements, inspectionId).createChecklist();

      dispatch({
        type: SET_CHECKLIST_DETAILS,
        payload,
      });
    }
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const getChecklistFromApiById = (checklistId, inspectionId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const payload = await api.get(`/api/checklists/${checklistId}`);
    dispatch({
      type: SET_CHECKLIST_DETAILS,
      payload: {
        checklistId: payload.id,
        checklist: payload.checkListGroupDefinitions,
        inspectionId,
      },
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const handleElementChange = (element) => async (dispatch, getState) => {
  dispatch({
    type: SET_IS_LOADING,
    payload: true,
  });

  const {
    checklists: {
      currentChecklistDetails: { inspectionId, checklistId },
    },
  } = getState();

  try {
    const newElement = {
      checkListElementDefinitionId: element.id,
      isChecked: !element.isChecked,
    };

    const checklistDbState = await getInspectionChecklists(inspectionId);
    const elements = _get(checklistDbState, 'elements', []);

    if (checklistDbState) {
      const indexOfItem = elements.findIndex(
        (item) => item.checkListElementDefinitionId === newElement.checkListElementDefinitionId,
      );
      if (indexOfItem > -1) {
        elements[indexOfItem] = newElement;
        Db.update(DB_NAME, { ...checklistDbState, elements });
      } else {
        Db.update(DB_NAME, {
          ...checklistDbState,
          elements: [...elements, newElement],
        });
      }
    } else {
      Db.insert(DB_NAME, { inspectionId, elements: [...elements, newElement] });
    }

    const template = await getChecklistById(checklistId);

    const payload = new Checklist(template, [...elements, newElement], inspectionId).createChecklist();

    dispatch({
      type: SET_CHECKLIST_DETAILS,
      payload,
    });

    dispatch(registerSync());
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};
const clearData = () => ({ type: CLEAR_DATA });

export { handleElementChange, getChecklistsDetails, clearData, getChecklistFromApiById, getChecklistsByParkId };

export { SET_CHECKLIST_DETAILS, SET_IS_LOADING, CLEAR_DATA };
