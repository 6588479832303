import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import classNames from 'classnames';
import FindingsExport from 'modules/findings/components/findingsExport/findingsExport.component';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

class Breadcrumb extends PureComponent {
  linkTo = (e) => {
    const {
      currentTarget: { id: link },
    } = e;
    const { history } = this.props;

    if (link) {
      history.push(link);
    }
  };

  render() {
    const {
      breadcrumbs,
      location: { pathname },
    } = this.props;
    const showFindingExportIcon = pathname.includes('findings/list');

    return (
      <div className="row no-gutters breadcrumb-component justify-content-between">
        <div className="row no-gutters align-items-center">
          {breadcrumbs.length > 0 &&
            breadcrumbs.map((crumb, i, arr) =>
              arr.length - 1 === i ? (
                <Typography key={`crumb-${crumb.title}`} variant="subtitle1" className="pr-2">
                  {crumb.title}
                </Typography>
              ) : (
                <Fragment key={`crumb-${crumb.title}`}>
                  <Typography
                    id={crumb.linkTo}
                    onClick={this.linkTo}
                    variant="subtitle1"
                    className={classNames('p-1 pr-2', {
                      'cursor-pointer': crumb.linkTo,
                    })}
                  >
                    {crumb.title}
                  </Typography>
                  <Typography variant="subtitle1" className="pr-2">
                    &gt;
                  </Typography>
                </Fragment>
              ),
            )}
        </div>
        <div className="pt-1 pb-1">{showFindingExportIcon && <FindingsExport />}</div>
      </div>
    );
  }
}

Breadcrumb.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.objectOf(PropTypes.shape).isRequired,
  location: PropTypes.objectOf(PropTypes.shape).isRequired,
};

Breadcrumb.defaultProps = {
  breadcrumbs: [],
};

export default withRouter(Breadcrumb);
