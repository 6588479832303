import {
  CLEAR_DATA,
  GlobalFilterActionsTypes,
  SET_IS_LOADING,
  SET_ITEMS,
  SET_SIMILAR_FILTERS_FROM,
} from './global.actions';

const initialState = {
  items: {},
  similarFiltersFrom: {
    item: {},
  },
  isLoading: true,
};

export type GlobalFilterReducerType = typeof initialState;

export default (state = initialState, action: GlobalFilterActionsTypes) => {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_ITEMS:
      return {
        ...state,
        items: payload,
        isLoading: false,
      };
    case SET_SIMILAR_FILTERS_FROM:
      return {
        ...state,
        similarFiltersFrom: payload,
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
