import React, { PureComponent, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import _get from 'lodash/get';
import {
  FILE_PARENTS,
  DATE_FORMAT,
  DATETIME_FORMAT,
  WORKFLOWS_TYPES,
  ONLY_FILES_ATTACHED_MSG,
} from 'modules/app/config/config';
import ConfirmModal from 'modules/common/components/confirmModal/confirmModal';
import DropdownMenu from 'modules/common/components/dropdownMenu/dropdownMenu.component';
import FilesList from 'modules/common/components/filesList/filesListWeb.component';
import ChangeDueDate from 'modules/workflows/components/changeDueDate/changeDueDate.component';
import ReassignUser from 'modules/workflows/components/reassignUser/reassignUser.component';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Icon, MenuItem, Typography } from '@material-ui/core';

const parseData = (data) =>
  data.reduce((reduced, item) => {
    const { fields, ...rest } = item;

    const newItem = {
      fields: fields.filter((field) => field.uiControlType !== 'DATE'),
      dueDate: fields.find((field) => field.uiControlType === 'DATE'),
      ...rest,
    };

    reduced.push(newItem);
    return reduced;
  }, []);

export const assigneeChanges = (assignee, user) => {
  if (!assignee) {
    return false;
  }
  return assignee.id !== user.id;
};

const AssigneeActionTaker = ({ actionTaker, actionTakerRole, workflowType }) => (
  <>
    <Typography color="textSecondary" variant="body2">
      {workflowType === WORKFLOWS_TYPES.defect_notification ? 'Employer' : 'Assignee'}
    </Typography>
    <Typography color="textPrimary" variant="body1">
      {actionTaker.fullName}
    </Typography>
    {actionTaker.email && (
      <Typography className="word-wrap" color="textPrimary" variant="caption">
        <a href={`mailto:${actionTaker.email}`}> {actionTaker.email}</a>
      </Typography>
    )}
    {actionTakerRole && (
      <Typography color="textPrimary" variant="body2">
        {actionTakerRole}
      </Typography>
    )}
    {actionTaker.jobTitle && (
      <Typography className="word-wrap" color="textPrimary" variant="caption">
        {actionTaker.jobTitle}
      </Typography>
    )}
  </>
);

AssigneeActionTaker.propTypes = {
  actionTaker: PropTypes.shape({}).isRequired,
  actionTakerRole: PropTypes.string,
  workflowType: PropTypes.string,
};

AssigneeActionTaker.defaultProps = {
  actionTakerRole: '',
  workflowType: '',
};

const AssigneeContractor = ({ siteFacility, actionTaker }) => {
  const hasActionTaker = actionTaker && Object.keys(actionTaker).length > 0;

  return (
    <div className="col-md-8 mt-2 pl-3">
      {hasActionTaker && <AssigneeActionTaker actionTaker={actionTaker} actionTakerRole="CONTRACTOR" />}

      {!hasActionTaker && (
        <>
          <Typography color="textSecondary" variant="body2">
            Assignee
          </Typography>
          <Typography color="textPrimary" variant="body2">
            CONTRACTORS
          </Typography>
        </>
      )}

      {siteFacility && (
        <>
          <Typography color="textSecondary" variant="body2">
            Site/Facility
          </Typography>
          <Typography color="textPrimary" variant="body1">
            {siteFacility}
          </Typography>
        </>
      )}
    </div>
  );
};

AssigneeContractor.propTypes = {
  siteFacility: PropTypes.string,
  actionTaker: PropTypes.shape({}),
};

AssigneeContractor.defaultProps = {
  siteFacility: '',
  actionTaker: {},
};

const FormDetailsFields = ({ classes, item }) => (
  <>
    <div className="col-md-12 no-gutters p-0">
      <Typography className={`title font--bold ${classes}-title`} color="textPrimary" variant="subtitle1">
        {item.name}
      </Typography>
    </div>
    {item.fields.map((field) => (
      <div key={`${field.label}-${field.value}`} className="col-md-12 p-0 mt-2">
        <Typography color="textSecondary" variant="body2">
          {field.label}
        </Typography>
        <Typography className="word-wrap" color="textPrimary" variant="body1">
          {field.value}
        </Typography>
      </div>
    ))}
  </>
);

FormDetailsFields.propTypes = {
  classes: PropTypes.string.isRequired,
  item: PropTypes.shape({}).isRequired,
};

const DueDateDetails = ({ label, value }) => (
  <div className="col-md-12 mt-2">
    <Typography color="textSecondary" variant="body2">
      {label}
    </Typography>
    <Typography color="textPrimary" variant="body1">
      {moment(value).format(DATE_FORMAT)}
    </Typography>
  </div>
);

DueDateDetails.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const SendMenuItem = ({ onSend, item: { name, actions } }) => {
  const user = useSelector((state) => state.auth.user);
  const sendAction = actions.find(
    (action) => action.label && ['Send', 'send', 'Notify Defect'].includes(action.label) && action.assignee,
  );

  const assignee = _get(sendAction, 'assignee', null);
  const assigneeChanged = assigneeChanges(assignee, user);
  const buttonLabel = _get(sendAction, 'label', 'Send');

  return assigneeChanged ? (
    <ConfirmModal
      confirmLabel="Yes"
      cancelLabel="No"
      confirmTitle={`Are you sure you want to send ${name}?`}
      confirmFn={onSend}
    >
      <MenuItem>
        <Icon className="mr-2">send</Icon>
        {buttonLabel}
      </MenuItem>
    </ConfirmModal>
  ) : (
    <MenuItem onClick={onSend}>
      <Icon className="mr-2">send</Icon>
      {buttonLabel}
    </MenuItem>
  );
};

SendMenuItem.propTypes = {
  onSend: PropTypes.func.isRequired,
  item: PropTypes.objectOf(PropTypes.shape).isRequired,
};

class DynamicFormDetails extends PureComponent {
  render() {
    const { data, onEdit, onSend, onRollback, workflowDetails, getWorkflow, setIsLoading, canEdit, callbackFn } =
      this.props;

    const parsedData = parseData(data);
    const actionsAvailable = onEdit || onSend || onRollback;

    const details = parsedData.map((historyItem) => {
      const isContractor = historyItem.actionTakerRole === 'CONTRACTOR';
      const historyItemClass = isContractor ? 'history__item-contractor' : 'history__item-vf';

      const actionsClass =
        historyItem.actionTaker || isContractor
          ? 'col-md-4 d-flex align-items-start justify-content-end'
          : 'row no-gutters align-items-center justify-content-end';

      const allowToChangeDueDate = workflowDetails.type === WORKFLOWS_TYPES.observation && !isContractor;
      const isNC = workflowDetails.type === WORKFLOWS_TYPES.nonconformity;

      return (
        <div key={historyItem.id} className={`border-bottom ${historyItemClass}`}>
          <div className="row no-gutters py-3">
            <div className="col-md-8">
              <FormDetailsFields item={historyItem} classes={historyItemClass} />
            </div>
            <div className="col-md-4">
              {historyItem.createDate && (
                <Typography className="title col-md-12" color="textSecondary" variant="subtitle1">
                  {moment(historyItem.createDate).format(DATETIME_FORMAT)}
                </Typography>
              )}
              <div className="row no-gutters">
                {historyItem.actionTaker && !isContractor && (
                  <div className="col-md-8 mt-2 pl-3">
                    <AssigneeActionTaker
                      actionTaker={historyItem.actionTaker}
                      actionTakerRole={historyItem.actionTakerRole}
                      workflowType={workflowDetails.type}
                    />
                  </div>
                )}
                {isContractor && (
                  <AssigneeContractor
                    siteFacility={workflowDetails.siteFacility}
                    actionTaker={historyItem.actionTaker}
                  />
                )}
                <div className={actionsClass}>
                  {actionsAvailable && (
                    <DropdownMenu>
                      {onEdit && (
                        <MenuItem onClick={onEdit}>
                          <Icon className="mr-2">edit</Icon>
                          Edit
                        </MenuItem>
                      )}
                      {onSend && <SendMenuItem onSend={onSend} item={historyItem} />}
                      {onRollback && (
                        <MenuItem onClick={onRollback}>
                          <Icon className="mr-2">settings_backup_restore</Icon>
                          Rollback
                        </MenuItem>
                      )}
                      {allowToChangeDueDate && <ChangeDueDate workflowId={workflowDetails.id} />}
                      {!isContractor && (
                        <ReassignUser
                          workflowDetails={workflowDetails}
                          getWorkflow={getWorkflow}
                          setIsLoading={setIsLoading}
                        />
                      )}
                    </DropdownMenu>
                  )}
                </div>
              </div>

              {historyItem.dueDate && historyItem.dueDate.value && <DueDateDetails {...historyItem.dueDate} />}
            </div>
          </div>
          <FilesList
            title="Attachments"
            id={historyItem.id}
            type={FILE_PARENTS.workflowPart}
            canEdit={canEdit}
            data={historyItem.attachments}
            callbackFn={callbackFn}
            local
            warningMsg={isNC && canEdit ? ONLY_FILES_ATTACHED_MSG : ''}
          />
        </div>
      );
    });

    return details;
  }
}

DynamicFormDetails.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
  workflowDetails: PropTypes.objectOf(PropTypes.shape),
  onEdit: PropTypes.func,
  onSend: PropTypes.func,
  onRollback: PropTypes.func,
  canEdit: PropTypes.bool,
  setIsLoading: PropTypes.func,
  getWorkflow: PropTypes.func,
  callbackFn: PropTypes.func,
};

DynamicFormDetails.defaultProps = {
  onEdit: null,
  onSend: null,
  onRollback: null,
  canEdit: false,
  setIsLoading: null,
  getWorkflow: null,
  workflowDetails: {},
  callbackFn: null,
};

export default withRouter(DynamicFormDetails);
