import React from 'react';

const ObservationStatusRepliedByContractor = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g data-name="Layer 2">
      <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 16a6 6 0 1 1 6-6 6 6 0 0 1-6 6z" />
      <circle cx={12} cy={12} r={3} />
    </g>
  </svg>
);

export default ObservationStatusRepliedByContractor;
