import Api from 'services/api';

const makeTransition = (params) => Api.post('/api/workflows/transition', params);

const createPart = (id, data) => {
  const parsedData = Object.keys(data || {}).map((key) => ({
    key,
    value: data[key],
  }));

  return Api.put('/api/workflows/part', { id, values: parsedData });
};

const rollbackWorkflow = (id) => Api.post(`/api/workflows/${id}/rollback`);

export { makeTransition, createPart, rollbackWorkflow };
