import Api from '../../../../services/api';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'USER';
const SET_USER = `${namespace}_SET_USER`;
const SET_USER_ROLES = `${namespace}_SET_USER_ROLES`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const CLEAR_DATA = `${namespace}_CLEAR_DATA`;

/*
 * REDUX ACTIONS
 */
const getUser = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const user = await Api.get(`/api/user-management/user/${userId}/details`);

    dispatch({
      type: SET_USER,
      payload: user.userDetails,
    });

    dispatch({
      type: SET_USER_ROLES,
      payload: user.userRoleAssociations,
    });

    return user.userDetails;
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const clearData = () => ({ type: CLEAR_DATA });

export { getUser, clearData };

export { SET_USER, SET_USER_ROLES, SET_IS_LOADING, CLEAR_DATA };
