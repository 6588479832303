import { connect } from 'react-redux';

import { checkIsLogged } from 'modules/auth/components/login/login.actions';

import App from './app.component';

const mapDispatchToProps = {
  checkIsLogged,
};

export default connect(null, mapDispatchToProps)(App);
