import _get from 'lodash/get';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import errorCodes from 'modules/app/config/errorCodes';
import Banner from 'modules/common/components/banner/banner.component';
import Toast from 'modules/common/components/toast/toast.component';

import appInsights from './telemetry';

const DEFAULT_ERROR = 'Something went wrong';
const DEFAULT_SUCCESS = 'Success!';

const parseMessage = (message) => {
  let text = isString(message)
    ? message
    : _get(errorCodes, _get(message, 'code'), _get(message, 'message')) || DEFAULT_ERROR;

  if (message && message.params) {
    text = text.replace('%s', message.params.toString());
  }

  return text;
};

class Notifiactions {
  showError(message, options) {
    const text = isArray(message) ? message.map((m) => parseMessage(m)).join(', ') : parseMessage(message);

    this.show(text, 'error', options);
  }

  showSuccess(message = DEFAULT_SUCCESS, options) {
    this.show(message, 'success', options);
  }

  show(message, type, options) {
    appInsights.trackEvent('USER_NOTIFICATION', {
      message,
      type,
    });

    Toast.show({
      message,
      type,
      ...options,
    });
  }

  showBanner(options) {
    Banner.show(options);
  }

  closeBanner(options) {
    Banner.close(options);
  }
}

export default new Notifiactions();
