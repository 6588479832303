import { connect } from 'react-redux';

import { createCrumb } from 'modules/app/components/app/app.actions';

import { getDetails, clearData } from './wbsDetails.actions';
import WbsDetails from './wbsDetails.component';

const mapStateToProps = (state) => ({
  data: state.wbsDetails.data,
  isLoading: state.wbsDetails.isLoading,
});

const mapDispatchToProps = {
  getDetails,
  clearData,
  createCrumb,
};

export default connect(mapStateToProps, mapDispatchToProps)(WbsDetails);
