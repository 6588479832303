import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { Paper } from '@material-ui/core';

class Banner extends PureComponent {
  static bannerInstance;

  static show(config) {
    if (!this.bannerInstance) {
      return;
    }
    this.bannerInstance.setState({ open: true, ...config });
    this.bannerInstance.props.setShowBanner(true);
  }

  static close(config) {
    if (!this.bannerInstance) {
      return;
    }
    this.bannerInstance.setState({ open: false, ...config });
    this.bannerInstance.props.setShowBanner(false);
  }

  static getDerivedStateFromProps(props) {
    if (!props.isLogged) {
      return {
        open: false,
      };
    }
    return null;
  }

  state = {
    open: false,
  };

  handleClose = () => {
    const { setShowBanner } = this.props;

    this.setState({ open: false });
    setShowBanner(false);
  };

  render() {
    const { open, component: Component, ...rest } = this.state;

    return open ? (
      <Paper className="banner-component" elevation={1}>
        <Component {...rest} handleClose={this.handleClose} />
      </Paper>
    ) : null;
  }
}

Banner.propTypes = {
  setShowBanner: PropTypes.func.isRequired,
};

export default Banner;
