import React, { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import _get from 'lodash/get';
import { FINDING_TYPES } from 'modules/findings/components/newFinding/newFinding.model';

import { Box, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { DoneAll } from '@material-ui/icons';

import Image from '../../../common/components/image/image.component';
import FindingsAttachmentsCounter from '../../../findings/components/findigsAttachmentsCounter/findingsAttachmentsCounter.component';

interface FindingListMobileProps {
  className?: string | number;
  findings: any[];
  goToFindingDetails: null | ((e: SyntheticEvent) => void);
  todos?: boolean;
}

const FindingListMobile = ({
  className = '',
  findings,
  goToFindingDetails = null,
  todos = false,
}: FindingListMobileProps) => {
  const { isOnline, syncInProgress, syncStarted } = useSelector((state: IStore) => state.app);

  const sortedFindings = [...findings].sort((a, b) => (a?.createDate < b?.createDate ? 1 : -1));

  const renderThumbnail = (thumbnail) =>
    thumbnail ? (
      <Image
        className="finding-thumbnail"
        src={_get(thumbnail, 'data', thumbnail)}
        mimeType={_get(thumbnail, 'content_type')}
      />
    ) : null;

  return (
    <List id="findings-list:ul" className={`subinspections-list ${className}`}>
      {sortedFindings?.map(
        ({
          id,
          localId,
          title,
          identifier,
          numberOfImages,
          numberOfDocuments,
          numberOfAudios,
          thumbnail,
          mainImageThumbnailPath,
          findingType,
          invalid,
          syncCompletionDate,
          description,
        }) => {
          const isSynchronized = () => {
            if (syncCompletionDate && !isOnline) {
              return true;
            }

            if (isOnline) {
              if (syncCompletionDate && !syncStarted) {
                return true;
              }
              if (syncInProgress && !syncCompletionDate) {
                return true;
              }
              if (!syncInProgress) {
                return false;
              }
            }
            return false;
          };

          const isAnyAttachment = Boolean(numberOfImages || numberOfDocuments || numberOfAudios);
          const todoItemStyle = !isAnyAttachment ? { width: 'calc(100vw - 96px)' } : {};

          return (
            <ListItem
              id={localId}
              key={`findings-list-item-${id}:li`}
              className={classNames('border-bottom pb-3 d-block mb-0', {
                invalid,
                synchronized: isSynchronized(),
                'work-item': findingType === FINDING_TYPES.WORK_INFO.value,
              })}
              puppet-data="finding-item:mobile"
              onClick={goToFindingDetails}
            >
              {todos && (
                <Grid container className="flex-nowrap justify-content-between">
                  <Box width="100%">
                    <ListItemText primary={description} style={todoItemStyle} />
                    {isAnyAttachment && (
                      <FindingsAttachmentsCounter
                        {...{
                          numberOfImages,
                          numberOfDocuments,
                          numberOfAudios,
                        }}
                      />
                    )}
                  </Box>
                  {renderThumbnail(thumbnail || mainImageThumbnailPath)}
                </Grid>
              )}
              {identifier && !todos && (
                <Grid container className="flex-nowrap justify-content-between">
                  <Box width="100%">
                    <ListItemText primary={title} />
                    <Box
                      className={classNames({
                        'd-inline-flex justify-content-between w-100': !thumbnail && !mainImageThumbnailPath,
                      })}
                    >
                      <Typography variant="subtitle2" color="textSecondary">
                        {identifier}
                      </Typography>
                      <FindingsAttachmentsCounter
                        {...{
                          numberOfImages,
                          numberOfDocuments,
                          numberOfAudios,
                        }}
                      />
                    </Box>
                  </Box>
                  {renderThumbnail(thumbnail || mainImageThumbnailPath)}
                </Grid>
              )}
              {!identifier && !todos && (
                <ListItemText
                  className="p-0"
                  primary={
                    <Grid container className="flex-nowrap justify-content-between">
                      <Box
                        className={classNames('d-flex justify-content-between w-100', {
                          'flex-column': thumbnail || mainImageThumbnailPath,
                        })}
                      >
                        {title}
                        <FindingsAttachmentsCounter
                          {...{
                            numberOfImages,
                            numberOfDocuments,
                            numberOfAudios,
                          }}
                        />
                      </Box>
                      {renderThumbnail(thumbnail || mainImageThumbnailPath)}
                    </Grid>
                  }
                />
              )}
              {syncCompletionDate && !todos && (
                <ListItemText
                  className="p-0"
                  primary={
                    <Box className={`sync-indicator ${syncCompletionDate ? 'synced' : ''}`}>
                      <DoneAll />
                    </Box>
                  }
                />
              )}
            </ListItem>
          );
        },
      )}
    </List>
  );
};

export default FindingListMobile;
