import React, { Component } from 'react';

import appInsights from '../../../../services/telemetry';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(e) {
    appInsights.trackException(e);

    this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <div className="d-flex flex-fill align-items-center justify-content-center">Something went wrong.</div>;
    }

    return children;
  }
}

export default ErrorBoundary;
