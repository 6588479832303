import React from 'react';

import { getActionTaker } from 'modules/app/helpers/utils';
import moment from 'moment';

import { ListItemText, Typography } from '@material-ui/core';

const WORK_PACKAGES_TABLE_CONFIG = {
  columns: [
    {
      caption: 'Description',
      dataField: 'description',
      sort: false,
      align: 'left',
    },
    {
      caption: 'Observations'.toUpperCase(),
      dataField: 'observations',
      sort: false,
      renderCell: (_, item) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Typography
          key={`description-observations-${item.id}`}
          className="text-nowrap mb-0"
          style={{ width: '100%', alignItems: 'inherit' }}
        >
          <span className="text-warning">{item.openObservations}</span>
          {' / '}
          <span className="text-info">{item.closedObservations}</span>
        </Typography>
      ),
      align: 'right',
    },
    {
      caption: 'NonConformities'.toUpperCase(),
      dataField: 'nonconformities',
      sort: false,
      renderCell: (_, item) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Typography
          key={`description-nonconformities-${item.id}`}
          className="text-nowrap mb-0"
          style={{ width: '100%', alignItems: 'inherit' }}
        >
          <span className="text-warning">{item.openNonConformities}</span>
          {' / '}
          <span className="text-info">{item.closedNonConformities}</span>
        </Typography>
      ),
      align: 'right',
    },
  ],
  noItemsFoundText: 'No workflows found',
  initialSort: {
    sortField: 'name',
    sortDirection: 'asc',
  },
};

const SUPPLIERS_TABLE_CONFIG = {
  columns: [
    {
      caption: 'Supplier',
      dataField: 'supplier',
      sort: false,
      align: 'left',
    },
    {
      caption: 'Observations'.toUpperCase(),
      dataField: 'observations',
      sort: false,
      align: 'right',
      renderCell: (_, item) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        // eslint-disable-next-line react/react-in-jsx-scope
        <Typography
          key={`supplier-observations-${item.id}`}
          className="text-nowrap mb-0"
          style={{ width: '100%', alignItems: 'inherit' }}
        >
          <span className="text-warning">{item.openObservations}</span>
          {' / '}
          <span className="text-info">{item.closedObservations}</span>
        </Typography>
      ),
    },
    {
      caption: 'NonConformities'.toUpperCase(),
      dataField: 'nonconformities',
      sort: false,
      renderCell: (_, item) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Typography
          key={`supplier-nonconformities-${item.id}`}
          className="text-nowrap mb-0"
          style={{ width: '100%', alignItems: 'inherit' }}
        >
          <span className="text-warning">{item.openNonConformities}</span>
          {' / '}
          <span className="text-info">{item.closedNonConformities}</span>
        </Typography>
      ),
      align: 'right',
    },
  ],
  noItemsFoundText: 'No workflows found',
  initialSort: {
    sortField: 'name',
    sortDirection: 'asc',
  },
};

const switchTextColorClass = (days) => {
  if (days >= 20) return 'text-danger';
  if (days >= 10) return 'text-warning';
  return 'text-info';
};

const WORKFLOWS_TABLE_CONFIG = {
  columns: [
    {
      caption: 'Title',
      dataField: 'title',
      sort: false,
      align: 'left',
    },
    {
      caption: 'Current Assignee',
      dataField: 'currentAssignee',
      sort: false,
      align: 'right',
      renderCell: (_, item) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <ListItemText key={`assignee-${item.id}`} className="text-nowrap" secondary={getActionTaker(item)} />
      ),
    },
    {
      caption: 'Open (#days)',
      dataField: 'daysClosed',
      sort: false,
      align: 'right',
      renderCell: (_, item) => {
        const days = moment().diff(moment(item.startDate), 'days');
        return (
          // eslint-disable-next-line react/react-in-jsx-scope
          <Typography
            key={`daysClosed-${item.id}`}
            className={`text-nowrap mb-0 ${switchTextColorClass(days)}`}
            style={{ width: '100%', textAlign: 'inherit' }}
          >
            {`${days} days`}
          </Typography>
        );
      },
    },
  ],
  noItemsFoundText: 'No unassigned findings found',
  initialSort: {
    sortField: 'createDate',
    sortDirection: 'asc',
  },
};

const UNASSIGNED_FINDINGS_TABLE_CONFIG = {
  columns: [
    {
      caption: 'Inspection Title',
      dataField: 'inspection.title',
      align: 'left',
      sort: false,
      renderCell: (_, item) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <ListItemText key={`inspection-${item.id}`} className="text-nowrap" secondary={`${item.inspection.title}`} />
      ),
    },
    {
      caption: 'Description of Finding',
      dataField: 'description',
      sort: false,
      align: 'center',
    },
    {
      caption: 'Current Assignee',
      dataField: 'currentAssignee',
      sort: false,
      align: 'right',

      renderCell: (_, item) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <ListItemText
          key={`unassigned-currentAssignee-${item.id}`}
          className="text-nowrap"
          secondary={`${item.inspection.leadInspector}`}
        />
      ),
    },
    {
      caption: 'Inspection closed (#days)',
      dataField: 'daysClosed',
      sort: false,
      align: 'right',

      renderCell: (_, item) => {
        const days = moment().diff(moment(item.inspection.closingDate), 'days');
        return (
          // eslint-disable-next-line react/react-in-jsx-scope
          <Typography
            key={`inspection-closed-${item.id}`}
            className={`text-nowrap mb-0 ${switchTextColorClass(days)}`}
            style={{ width: '100%', textAlign: 'inherit' }}
          >
            {`${days} days`}
          </Typography>
        );
      },
    },
  ],
  noItemsFoundText: 'No unassigned findings found',
  initialSort: {
    sortField: 'lastActivityDate',
    sortDirection: 'asc',
  },
};

export { WORK_PACKAGES_TABLE_CONFIG, SUPPLIERS_TABLE_CONFIG, UNASSIGNED_FINDINGS_TABLE_CONFIG, WORKFLOWS_TABLE_CONFIG };
