import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FiletypePDF } from 'assets/icons/index';
import { saveAs } from 'file-saver';
import _get from 'lodash/get';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { DATETIME_FORMAT } from 'modules/app/config/config';
import ConfirmModal from 'modules/common/components/confirmModal/confirmModal';
import Loader from 'modules/common/components/loader/loader.component';
import moment from 'moment';
import PropTypes from 'prop-types';
import Api from 'services/api';

import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography, Icon } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

import { deleteStatusReport } from './statusReports.actions';

const StatusRow = ({ name, value }) => (
  <div className="row no-gutters align-items-center ">
    <Typography align="left" color="textSecondary" variant="body2">
      {`${name}:`}&nbsp;
    </Typography>
    <Typography color="textPrimary" variant="subtitle2" className="word-wrap">
      {value}
    </Typography>
  </div>
);

StatusRow.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

StatusRow.defaultProps = {
  value: '',
};

const styles = () => ({
  button: {
    backgroundColor: red[400],
    '&:hover': {
      backgroundColor: red[600],
    },
  },
});

const StatusReportsDetails = ({ open, closeModal, classes }) => {
  const dispatch = useDispatch();
  const [isDownloading, setDownloading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const selectedIsLoading = useSelector((state) => state.statusReports.selectedIsLoading);
  const { id, title, workPackage, comments, createDate, createdBy, reportFileName, reportAttachmentId } = useSelector(
    (state) => state.statusReports.selected,
  );

  const userIsCreator = user.id === _get(createdBy, 'id');

  const handleConfirmDelete = () => {
    dispatch(deleteStatusReport(id));
    closeModal();
  };

  const downloadReport = () => async (e) => {
    e.stopPropagation();
    setDownloading(true);
    try {
      const blob = await Api.get(`/api/attachments/${reportAttachmentId}/download`, null, 'blob');
      const fileName = reportFileName || `${title}.pdf`;

      saveAs(blob, fileName);
      setDownloading(false);
    } catch (err) {
      setDownloading(false);
      genericErrorHandler(err);
    }
  };

  const showLoader = selectedIsLoading || isDownloading;

  return (
    <Dialog open={open} maxWidth="lg" onClose={closeModal} onEscapeKeyDown={closeModal}>
      <DialogContent>
        <div className="row justify-content-center align-items-center">
          <div className="col-md-12">
            <DialogTitle className="modal-title--center" disableTypography>
              Status report {title}
            </DialogTitle>
          </div>
          {showLoader && <Loader inline mask />}
          {!showLoader && (
            <>
              <div className="col-md-9">
                <StatusRow name="Park" value={_get(workPackage, 'rootDescription', '')} />
                <StatusRow name="Package" value={_get(workPackage, 'description', '')} />
                <StatusRow name="Comments" value={comments} />
                <StatusRow name="Uploaded by" value={_get(createdBy, 'fullName', '')} />
                <StatusRow name="Date" value={moment(createDate).format(DATETIME_FORMAT)} />
              </div>
              <div className="col-md-3">
                <div className="row justify-content-center">
                  <Icon className="svg-large cursor-pointer border rounded icon--red" onClick={downloadReport()}>
                    <FiletypePDF />
                  </Icon>
                </div>
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="body2"
                  className="cursor-pointer"
                  onClick={downloadReport()}
                >
                  Click to Download
                </Typography>
              </div>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div
          className={`w-100 row no-gutters align-items-center ${
            userIsCreator ? 'justify-content-between' : 'justify-content-end'
          }`}
        >
          {userIsCreator && (
            <ConfirmModal confirmFn={handleConfirmDelete} confirmTitle="Are you sure you want to delete this report?">
              <Button className={classes.button} color="secondary" variant="contained">
                Delete
              </Button>
            </ConfirmModal>
          )}

          <Button onClick={closeModal} color="secondary">
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

StatusReportsDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(StatusReportsDetails);
