import React, { PureComponent, Fragment, createRef } from 'react';
import { withRouter } from 'react-router-dom';

import _get from 'lodash/get';
import withTabs from 'modules/common/hoc/withTabs/withTabs.hoc';
import PropTypes from 'prop-types';
import Acl, { PRIVILEGE_KEYS } from 'services/acl';

import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';

import { makeWbsName, findNodeInTree } from '../../../app/helpers/utils';
import Loader from '../../../common/components/loader/loader.component';
import ContractorsTabRows from './contractorsTabRows.component';
import PermissionsTabRows from './permissionsTabRows.component';

const TABS = [
  {
    id: 'permissions',
    label: 'Permissions',
    deleteMsg: (clickedItem, selected) =>
      `Sure to delete ${_get(clickedItem, 'fullName', 'this user')} from ${
        selected || 'selected'
      } role in Work Package?`,
  },
  {
    id: 'site-facilities',
    label: 'Site Facilities',
    deleteMsg: (clickedItem, selected) =>
      `Sure to delete ${_get(clickedItem, 'fullName', 'this user')} from ${
        selected || 'site facility'
      } in Work Package?`,
  },
];

class WbsDetails extends PureComponent {
  state = {
    open: false,
    clickedItem: null,
    selected: '',
  };

  REFS = TABS.map(() => createRef());

  componentDidMount() {
    const { getDetails, match } = this.props;

    getDetails(match.params.id);
  }

  componentDidUpdate({ data: prevData }) {
    const {
      data,
      createCrumb,
      match: { path, params },
      workPackages,
      setWorkPackages,
    } = this.props;

    createCrumb(path, params);

    if (Object.keys(prevData).length && prevData !== data) {
      const node = findNodeInTree(workPackages, 'id', data.id);

      if (!node) {
        return;
      }

      node.roles = data.roles;
      setWorkPackages([...workPackages]);
    }
  }

  componentWillUnmount() {
    const { clearData } = this.props;

    clearData();
  }

  onDelete = () => {
    const { tabIndex } = this.props;
    const { clickedItem } = this.state;

    this.REFS[tabIndex].current.handleDelete(clickedItem);
    this.closeDialog();
  };

  confirmDelete = (item, selected) => {
    this.setState({ open: true, clickedItem: item, selected });
  };

  closeDialog = () => {
    this.setState({ open: false, clickedItem: null, selected: '' });
  };

  render() {
    const { isLoading, data, noAccessRedirect, renderTabs, tabIndex, history } = this.props;
    const { open, clickedItem, selected } = this.state;
    const hasData = !!Object.keys(data).length;

    if (isLoading && !hasData) {
      return <Loader />;
    }

    this.title = makeWbsName(data);
    const wbs = _get(data, 'id');

    if (wbs && !Acl.hasPrivilege(PRIVILEGE_KEYS.WBSES_EDIT, wbs)) {
      return noAccessRedirect();
    }

    if (!hasData) {
      return null;
    }

    const deleteDialogTitle = TABS[tabIndex].deleteMsg(clickedItem, selected);

    return (
      <>
        {renderTabs(TABS)}
        {isLoading && hasData && <Loader mask />}
        <div>
          {tabIndex === 0 && (
            <PermissionsTabRows history={history} ref={this.REFS[0]} data={data} confirmDelete={this.confirmDelete} />
          )}
          {tabIndex === 1 && <ContractorsTabRows ref={this.REFS[1]} data={data} confirmDelete={this.confirmDelete} />}

          <Dialog open={open} onClose={this.closeDialog}>
            <DialogTitle>{deleteDialogTitle}</DialogTitle>
            <DialogActions>
              <Button onClick={this.closeDialog} color="secondary">
                No, keep
              </Button>
              <Button onClick={this.onDelete} color="secondary" autoFocus disabled={isLoading}>
                Yes, delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }
}

WbsDetails.propTypes = {
  getDetails: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  renderTabs: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  createCrumb: PropTypes.func.isRequired,
  workPackages: PropTypes.arrayOf(PropTypes.shape({})),
  match: PropTypes.shape({}).isRequired,
  setWorkPackages: PropTypes.func,
  noAccessRedirect: PropTypes.func,
  history: PropTypes.shape({}).isRequired,
};

WbsDetails.defaultProps = {
  workPackages: null,
  setWorkPackages: null,
  noAccessRedirect: () => {},
};

const Enchanted = withTabs(WbsDetails);

export default withRouter(Enchanted);
