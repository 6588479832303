import React, { FC } from 'react';
import { NodeRenderOptions, RendererProps, selectors } from 'react-virtualized-tree';

import _get from 'lodash/get';

import { Checkbox, Icon, IconButton, ListItemText } from '@material-ui/core';

import { GlobalActionKeyboardArrowDown, GlobalActionKeyboardArrowUp, Segment } from '../../../../assets/icons/index';
import ListItemWrapper from '../listItemWrapper/listItemWrapper.component';

const renderListItemPrimary = (node, primaryTextField, alternativePrimaryTextField) => (
  <ListItemText
    className="list-item-text"
    primary={node[primaryTextField] ? node[primaryTextField] : node[alternativePrimaryTextField]}
    puppet-data={node[primaryTextField]}
  />
);

const renderListItem = (node, primaryTextField, alternativePrimaryTextField, secondaryTextField) => (
  <ListItemText
    className="list-item-text"
    puppet-data={node[primaryTextField]}
    primary={node[primaryTextField] ? node[primaryTextField] : node[alternativePrimaryTextField]}
    secondary={node[secondaryTextField]}
  />
);

interface ItemRendererProps extends RendererProps<NodeRenderOptions> {
  blockRootClick?: boolean;
  primaryTextField: string;
  secondaryTextField?: string;
  alternativePrimaryTextField?: string;
  withCheck?: boolean;
  checkFunction?: (node: any) => boolean;
  onSelect: (node: any) => void;
}

const ItemRenderer: FC<ItemRendererProps> = ({
  onChange,
  node,
  onSelect,
  node: { children },
  primaryTextField,
  secondaryTextField,
  alternativePrimaryTextField,
  withCheck,
  checkFunction,
  blockRootClick,
}) => {
  const isChecked = checkFunction && checkFunction(node);

  const handleChange = () => {
    onChange(selectors.updateNode(node, { expanded: !_get(node, 'state.expanded', false) }));
  };

  const { hasChildren, isExpanded } = selectors.getNodeRenderOptions(node);

  const handleSelect = () => {
    if (hasChildren && blockRootClick) {
      handleChange();
      return;
    }

    onSelect(node);
  };

  const renderIcon = () => {
    if (hasChildren && isExpanded) {
      return <GlobalActionKeyboardArrowUp />;
    }

    if (hasChildren && !isExpanded) {
      return <GlobalActionKeyboardArrowDown />;
    }

    return null;
  };

  const listItem = secondaryTextField
    ? renderListItem(node, primaryTextField, alternativePrimaryTextField, secondaryTextField)
    : renderListItemPrimary(node, primaryTextField, alternativePrimaryTextField);

  const listWrapperPadding = children && children.length === 0 ? 'pl-5' : '';

  const expandOnClick = hasChildren && blockRootClick;

  return (
    <div className="row justify-content-between align-items-center no-gutters list-item flex-nowrap">
      {withCheck && <Checkbox checked={isChecked} onClick={handleSelect} />}
      <ListItemWrapper
        item={node}
        className={`${listWrapperPadding} ${expandOnClick ? 'expand-on-click' : ''}`}
        button
        onClick={handleSelect}
      >
        <div className="list-item-container">
          {expandOnClick && (
            <Icon>
              <Segment />
            </Icon>
          )}
          {listItem}
        </div>
      </ListItemWrapper>
      {!!children && !!children.length && (
        <IconButton
          className="left-button"
          puppet-data-mobile="button:expand-item"
          puppet-data={`expand-button:${node[primaryTextField]}`}
          aria-label="Close"
          onClick={handleChange}
        >
          <Icon>{renderIcon()}</Icon>
        </IconButton>
      )}
    </div>
  );
};

export default ItemRenderer;
