import React, { PureComponent } from 'react';

import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import SearchUsersModal from 'modules/common/components/usersSearchModal/usersSearchModal.component';
import PropTypes from 'prop-types';

import { searchUser, changeParticipant } from './changeParticipant.actions';

class ChangeParticipant extends PureComponent {
  searchUsers = () => {
    const { wbsId, role, siteFacilityId } = this.props;
    const additionalQueryParams = {
      role,
      excludeLoggedUser: false,
      ...(role === 'CONTRACTOR' && { siteFacilityId }),
    };

    return searchUser(wbsId, additionalQueryParams);
  };

  confirmContent = (data) => {
    const { role } = this.props;

    return `You are about to change ${role} to ${data.firstName}, ${data.lastName} (${data.email})`;
  };

  onChangeClick = async (_, data) => {
    const { getWorkflow, setIsLoading, workflowId, role, siteFacilityId } = this.props;

    try {
      setIsLoading(true);

      const additionalQueryParams = {
        id: workflowId,
        userId: data.id,
        userRole: role,
        ...(role === 'CONTRACTOR' && { siteFacilityId }),
      };

      await changeParticipant(additionalQueryParams);
      await getWorkflow(workflowId, true);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      genericErrorHandler(err);
    }
  };

  render() {
    const { children } = this.props;

    return (
      <SearchUsersModal
        confirmFn={this.onChangeClick}
        confirmIcon="swap_horiz"
        confirmLabel="Change"
        confirmTitle="Confirm change participant"
        confirmContent={this.confirmContent}
        getUsers={this.searchUsers}
        searchOnOpen
        local
      >
        {children}
      </SearchUsersModal>
    );
  }
}

ChangeParticipant.propTypes = {
  getWorkflow: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  wbsId: PropTypes.string.isRequired,
  workflowId: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  siteFacilityId: PropTypes.string.isRequired,
};

export default ChangeParticipant;
