export default (errorHandler) => (store) => (next) => async (action) => {
  try {
    if (action.name) {
      return next(action);
    }
    return await next(action);
  } catch (e) {
    errorHandler(e, store.getState, action, store.dispatch);
    return null;
  }
};
