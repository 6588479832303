import React, { PureComponent, memo } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import Acl, { PRIVILEGE_KEYS } from 'services/acl';

import { Icon, Typography, Tooltip, IconButton } from '@material-ui/core';

import {
  GlobalActionDropRight,
  GlobalActionDropDown,
  GlobalActionEdit,
  UserRoleOverride,
} from '../../../../assets/icons/index';
import { USER_ROLES } from '../../../app/config/config';

const propTypes = {
  node: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object]))
    .isRequired,
};

const Toggle = memo(({ node: { toggled, hasChildren } }) => (
  <>
    {!hasChildren && <GlobalActionDropRight className="empty" />}
    {toggled && hasChildren && <GlobalActionDropDown />}
    {!toggled && hasChildren && <GlobalActionDropRight />}
  </>
));

Toggle.propTypes = propTypes;

class Container extends PureComponent {
  constructor({ node }) {
    super();
    this.showEditButton = Acl.hasPrivilege(PRIVILEGE_KEYS.WBSES_EDIT, node.id);
  }

  goToEdit = () => {
    const { node, history } = this.props;
    history.push(`/admin/wbses/${node.id}`);
  };

  render() {
    const { node, onClick } = this.props;
    const nodeTitle =
      !node.hasChildren && node.rootDescription && node.isRoot
        ? `${node.rootDescription} - ${node.description || node.name}`
        : node.description || node.name;

    return (
      <>
        <div className="row no-gutters justify-content-between align-items-center flex-fill table-row">
          <div className="title flex-column no-gutters  cursor-pointer" role="presentation" onClick={onClick}>
            <div className="row flex-nowrap">
              <Toggle node={node} />
              <Typography color="textSecondary" variant="subtitle1">
                {nodeTitle}
              </Typography>
            </div>
            <Typography className="ml-2" color="textSecondary" variant="body2">
              {node.name}
            </Typography>
          </div>

          <div className="display-table">
            {USER_ROLES.map((item) => {
              const roles = (node.roles[item.key] || []).filter((u) => !u.inherited);

              return (
                <span className="table-cell" key={item.key}>
                  {!!roles.length && (
                    <Tooltip
                      title={
                        <>
                          {roles.map((user) => (
                            <div key={user.id}>{user.fullName}</div>
                          ))}
                        </>
                      }
                    >
                      <i>
                        <UserRoleOverride className="person-icon" />
                      </i>
                    </Tooltip>
                  )}
                  {!roles.length && <Icon className="person-icon" />}
                </span>
              );
            })}
            <div className="table-cell">
              {this.showEditButton && (
                <IconButton className="edit-button" onClick={this.goToEdit}>
                  <GlobalActionEdit />
                </IconButton>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Container.propTypes = propTypes;

const decorators = {
  Container: withRouter(Container),
};

export default decorators;
