import { CLEAN_DATA, SET_IS_LOADING } from './newFinding.actions';

const initialState = {
  inspectors: [],
  coInspectors: [],
  workPackage: [],
  facilities: [],
  rdsPp: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case CLEAN_DATA: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
