import React, { memo, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { FiletypePDF, GlobalActionDownload, GlobalActionEdit, GlobalActionMore } from 'assets/icons/index';
import { DrawerContext } from 'context';
import { saveAs } from 'file-saver';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { DATETIME_FORMAT, WORKFLOW_STATUSES } from 'modules/app/config/config';
import ConfirmModal from 'modules/common/components/confirmModal/confirmModal';
import withPrompt from 'modules/common/components/confirmModal/withPrompt.hoc';
import LinkHref from 'modules/common/components/link/link.component';
import moment from 'moment';
import PropTypes from 'prop-types';
import Notifications from 'services/notifications';

import { Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core';
import { Add, Build } from '@material-ui/icons';

import { AddFindingsForm } from '../addFindingsForm';
import { getPreliminaryReport } from '../workflows/workflows.actions';

const ConfirmModalWithPrompt = withPrompt(ConfirmModal);

const IsFrozenInfo = () => (
  <div className="col-md-12 d-flex justify-content-center align-items-center pb-3 ">
    <Icon color="primary">warning</Icon>
    <Typography className="pl-3" color="textPrimary" variant="h6">
      The due date of this workflow has been reached. Please wait for an update from Vattenfall.
    </Typography>
  </div>
);

export const DeleteMenuItem = memo(({ itemText }) => (
  <>
    <ListItemIcon>
      <Icon>delete</Icon>
    </ListItemIcon>
    <ListItemText>{itemText}</ListItemText>
  </>
));

DeleteMenuItem.propTypes = {
  itemText: PropTypes.string,
};

DeleteMenuItem.defaultProps = {
  itemText: 'Delete',
};

const PROMPT_PARAM = 'invalidationReason';

export const InvalidatedInfo = ({ workflow: { invalidatedBy, invalidationDate, invalidationReason } }) => (
  <div className="row pb-3 justify-content-between">
    <div className="col-md-12 d-flex justify-content-center align-items-center pb-3 ">
      <Icon color="primary">warning</Icon>
      <Typography className="pl-3" color="textPrimary" variant="h6">
        This workflow has been invalidated
      </Typography>
    </div>
    {invalidatedBy && (
      <div className="col-md-4">
        <Typography color="textSecondary" variant="body2">
          Invalidated By
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {invalidatedBy.fullName}
        </Typography>
      </div>
    )}

    {invalidationDate && (
      <div className="col-md-4">
        <Typography color="textSecondary" variant="body2">
          Invalidation date
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {moment(invalidationDate).format(DATETIME_FORMAT)}
        </Typography>
      </div>
    )}
    {invalidationReason && (
      <div className="col-md-4">
        <Typography color="textSecondary" variant="body2">
          Invalidation reason
        </Typography>

        <Typography color="textPrimary" variant="body1" className="word-wrap">
          {invalidationReason}
        </Typography>
      </div>
    )}
  </div>
);

InvalidatedInfo.propTypes = {
  workflow: PropTypes.shape({
    invalidatedReason: PropTypes.string,
    invalidatedBy: PropTypes.shape({}),
    invalidationDate: PropTypes.string,
  }),
};

InvalidatedInfo.defaultProps = {
  workflow: {
    invalidatedReason: '',
    invalidatedBy: {},
    invalidationDate: '',
  },
};

class WorkflowDetailsFields extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static contextType = DrawerContext;

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleMenuOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleEditWorkflow = () => {
    const {
      match: { params },
      history,
    } = this.props;

    history.push(`/editWorkflow/${params.workflowId}`);
  };

  goToAncestor = () => {
    const { history, workflow } = this.props;
    history.push(`/workflowDetails/${workflow.ancestorWorkflows[0].id}`);
  };

  handleRemoveWorkflow = async () => {
    try {
      const {
        match: { params },
        removeWorkflow,
      } = this.props;

      await removeWorkflow(params.workflowId);
      this.handleSuccessRemove('deleted');
    } catch (err) {
      genericErrorHandler(err);
    }
  };

  handleInvalidateWorkflow = async (_, promptParams) => {
    try {
      const {
        match: { params },
        invalidateWorkflow,
      } = this.props;

      await invalidateWorkflow({
        id: params.workflowId,
        [PROMPT_PARAM]: promptParams[PROMPT_PARAM],
      });
      this.handleSuccessRemove('invalidated');
    } catch (err) {
      genericErrorHandler(err);
    }
  };

  handleSuccessRemove = (subject = 'deleted') => {
    const {
      history,
      workflow: { type },
    } = this.props;

    Notifications.showSuccess(`Workflow has been ${subject}`);
    history.replace(`/workflows/${type.toLowerCase()}`);
  };

  handleGeneratePreliminaryReport = async () => {
    const {
      workflow: { id },
    } = this.props;

    try {
      const response = await getPreliminaryReport(id);
      const jsonBlob = new Blob([response], { type: 'charset=utf-8' });

      saveAs(jsonBlob, 'preliminaryReport.pdf');
    } catch (err) {
      genericErrorHandler(err);
    }
  };

  handleAddFindingsOpen = () => {
    const { setOpen, setClosed } = this.context;
    const { workflow } = this.props;
    setOpen(<AddFindingsForm workflow={workflow} onClose={setClosed} />);
  };

  renderMenu = () => {
    const {
      canEdit,
      canDelete,
      workflow: { reportAttachmentId, status, canAssignFindings },
      getNcReport,
    } = this.props;

    const { anchorEl } = this.state;
    const isMenuOpen = !!anchorEl;

    const isClosed = status === WORKFLOW_STATUSES.closed;

    return (
      <Menu
        id="inspection-details-menu:ul"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem id="inspection-details-edit:li" onClick={this.handleGeneratePreliminaryReport}>
          <ListItemIcon>
            <Icon className="svg-medium">
              <GlobalActionDownload className="icon-dark" />
            </Icon>
          </ListItemIcon>
          <ListItemText>Preliminary report</ListItemText>
        </MenuItem>

        {canEdit && (
          <MenuItem id="inspection-details-edit:li" onClick={this.handleEditWorkflow}>
            <ListItemIcon>
              <Icon className="svg-medium">
                <GlobalActionEdit className="icon-dark" />
              </Icon>
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}

        {canAssignFindings && (
          <MenuItem id="inspection-details-edit:li" onClick={this.handleAddFindingsOpen}>
            <ListItemIcon>
              <Icon className="svg-medium">
                <Add className="icon-dark" />
              </Icon>
            </ListItemIcon>
            <ListItemText>Add Findings</ListItemText>
          </MenuItem>
        )}

        {canDelete && canEdit && (
          <ConfirmModal
            confirmFn={this.handleRemoveWorkflow}
            confirmTitle="Are you sure you want to delete this workflow?"
            confirmIcon="delete"
            onClose={this.handleMenuClose}
          >
            <MenuItem id="inspection-details-edit:li">
              <DeleteMenuItem />
            </MenuItem>
          </ConfirmModal>
        )}

        {reportAttachmentId && (
          <MenuItem id="inspection-details-get-nc-report:li" onClick={() => getNcReport(reportAttachmentId)}>
            <ListItemIcon>
              <Icon className="svg-medium">
                <FiletypePDF className="icon-dark" />
              </Icon>
            </ListItemIcon>
            <ListItemText>Download Report</ListItemText>
          </MenuItem>
        )}

        {canDelete && !canEdit && !isClosed && (
          <ConfirmModalWithPrompt
            confirmFn={this.handleInvalidateWorkflow}
            confirmTitle="Are you sure you want to invalidate this workflow?"
            confirmIcon="delete"
            onClose={this.handleMenuClose}
            promptName={PROMPT_PARAM}
            placeholder="Please provide a reason"
            required
          >
            <MenuItem id="inspection-details-edit:li">
              <DeleteMenuItem itemText="Invalidate" />
            </MenuItem>
          </ConfirmModalWithPrompt>
        )}
      </Menu>
    );
  };

  render() {
    const { workflow, canEdit, canDelete, isFrozen } = this.props;
    const isInvalidated = workflow.status === WORKFLOW_STATUSES.invalidated;
    const frozenOrInvalidated = !(isFrozen || isInvalidated);
    const isClosed = workflow.status === WORKFLOW_STATUSES.closed;
    const showMenu =
      frozenOrInvalidated && (canEdit || (canDelete && !isClosed) || (isClosed && workflow.reportAttachmentId));

    return (
      <>
        <div id="workflow-details:div" className="flex-fill workflow-details-web-component inspection-card">
          <div className="row no-gutters justify-content-between align-items-center py-4">
            <Typography className="break-word col-md-11" color="textPrimary" variant="h5">
              {workflow.title}
            </Typography>
            {showMenu && (
              <div className="col-md-1">
                <IconButton
                  id="menu-open:button"
                  className="float-right"
                  aria-label="Menu"
                  onClick={this.handleMenuOpen}
                >
                  <Icon className="svg-medium">
                    <GlobalActionMore className="icon-dark" />
                  </Icon>
                </IconButton>
              </div>
            )}
          </div>
          <div className="row justify-content-between">
            {(workflow.ancestorWorkflows || []).length > 0 && (
              <div className="col-md-8 pb-3">
                <div className="row no-gutters align-items-center pr-2">
                  <Typography className="mr-2" color="textSecondary" variant="subtitle2">
                    Originated from Observation
                  </Typography>
                  <LinkHref
                    title={workflow.ancestorWorkflows[0].identifier || workflow.ancestorWorkflows[0].id}
                    goToFunc={this.goToAncestor}
                  />
                </div>
              </div>
            )}
            <div className="col-md-4 pb-3">
              <Typography color="textSecondary" variant="subtitle2">
                {moment(workflow.createDate).format(DATETIME_FORMAT)}
              </Typography>
            </div>
          </div>
          {isInvalidated && <InvalidatedInfo workflow={workflow} />}
          {isFrozen && <IsFrozenInfo />}
        </div>
        {showMenu && this.renderMenu()}
      </>
    );
  }
}

WorkflowDetailsFields.defaultProps = {
  workflow: {},
};

WorkflowDetailsFields.propTypes = {
  workflow: PropTypes.objectOf(PropTypes.shape),
  removeWorkflow: PropTypes.func.isRequired,
  invalidateWorkflow: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.shape).isRequired,
  history: PropTypes.objectOf(PropTypes.shape).isRequired,
  canDelete: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  getNcReport: PropTypes.func.isRequired,
};

export default withRouter(WorkflowDetailsFields);
