import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import moment from 'moment';
import PropTypes from 'prop-types';

import { Typography, Popover } from '@material-ui/core';

import {
  InspectionStatusOngoing,
  InspectionStatusClosed,
  InspectionStatusFinished,
  InspectionStatusInvalidated,
  InspectionStatusPlanned,
  InspectionStatusDraft,
} from '../../../../assets/icons/index';
import {
  DATETIME_FORMAT,
  INSPECTION_STATUSES,
  INSPECTION_STATUSES_DATE_FIELDS,
  INSPECTION_STATUSES_DATE_LABELS,
} from '../../../app/config/config';

const INSPECTION_STATUSES_FLOW = Object.values(INSPECTION_STATUSES).filter(
  (status) => status !== INSPECTION_STATUSES.invalidated,
);

class InspectionStatusLabel extends PureComponent {
  state = {
    anchorEl: null,
  };

  getParamId = () => {
    const { location } = this.props;
    return location.pathname.split('inspectionDetails/')[1];
  };

  handleEditInspection = () => {
    const { history } = this.props;
    history.push(`/editInspection/${this.getParamId()}`);
  };

  handleRemoveInpsection = async () => {
    const { removeInspection, history } = this.props;

    await removeInspection(this.getParamId());
    history.push('/inspections/');
  };

  handleMenuOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  renderPopoverStatuses = (status, noClick = false) => {
    const flowIndex = INSPECTION_STATUSES_FLOW.indexOf(status);

    if (flowIndex > -1) {
      return INSPECTION_STATUSES_FLOW.slice(0, flowIndex + 1).map((o, i) =>
        this.renderStatus(INSPECTION_STATUSES_FLOW[i], noClick),
      );
    }

    if (INSPECTION_STATUSES.invalidated) {
      return this.renderStatus(INSPECTION_STATUSES.invalidated, noClick);
    }

    return null;
  };

  renderStatus = (status = null, noClick = false) => {
    const { inspection } = this.props;
    const newStatus = status || inspection.status;
    const text = INSPECTION_STATUSES_DATE_LABELS[newStatus] || 'Default';
    let icon;

    switch (newStatus) {
      case INSPECTION_STATUSES.draft:
        icon = <InspectionStatusDraft className="icon-light pr-2" />;
        break;
      case INSPECTION_STATUSES.planned:
        icon = <InspectionStatusPlanned className="icon-light pr-2" />;
        break;
      case INSPECTION_STATUSES.ongoing:
        icon = <InspectionStatusOngoing className="icon-light pr-2" />;
        break;
      case INSPECTION_STATUSES.finished:
        icon = <InspectionStatusFinished className="icon-light pr-2" />;
        break;
      case INSPECTION_STATUSES.completed:
        icon = <InspectionStatusClosed className="icon-light pr-2" />;
        break;
      case INSPECTION_STATUSES.invalidated:
        icon = <InspectionStatusInvalidated className="icon-light pr-2" />;
        break;
      default:
        break;
    }

    const newDate = inspection[INSPECTION_STATUSES_DATE_FIELDS[newStatus]] || moment();

    return (
      <div className="row no-gutters align-items-center justify-content-between" key={`inspection-status-${text}`}>
        <Typography variant="body2" color="textSecondary" className="mr-2" noWrap>
          {moment(newDate).format(DATETIME_FORMAT)}
        </Typography>

        <div
          id="inspectionDetails:statuses"
          puppet-data="inspectionDetails:statuses"
          className={`row no-gutters align-items-center inspection-status-label inspection-status-${newStatus} svg-small cursor-pointer`}
          onClick={!noClick && this.handleMenuOpen}
          onKeyPress={!noClick && this.handleMenuOpen}
          role="button"
          tabIndex={0}
        >
          {icon}
          <Typography variant="caption" color="inherit" noWrap>
            {text}
          </Typography>
        </div>
      </div>
    );
  };

  renderStatusMenu = () => {
    const { anchorEl } = this.state;
    const isMenuOpen = !!anchorEl;
    const {
      inspection: { status },
    } = this.props;

    return (
      <>
        {this.renderStatus()}
        <Popover
          open={isMenuOpen}
          anchorEl={anchorEl}
          onClose={this.handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="inspection-status-popover p-3">
            <div className="titlerow no-gutters justify-content-start align-items-center">
              <Typography className="font-weight-bold pt-2 pb-2" variant="body2" color="textPrimary">
                Status Updates
              </Typography>
            </div>
            <div className="d-flex flex-column statuses">{this.renderPopoverStatuses(status, true)}</div>
          </div>
        </Popover>
      </>
    );
  };

  render() {
    return <div className="d-flex flex-column">{this.renderStatusMenu()}</div>;
  }
}

InspectionStatusLabel.defaultProps = {
  inspection: {},
};

InspectionStatusLabel.propTypes = {
  inspection: PropTypes.objectOf(PropTypes.any),
  removeInspection: PropTypes.func.isRequired,
};

export default withRouter(InspectionStatusLabel);
