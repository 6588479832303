import React from 'react';

import { FiletypePDF } from 'assets/icons/index';
import { saveAs } from 'file-saver';
import { INSPECTION_STATUSES } from 'modules/app/config/config';
import { INSPECTION_STRUCTURE } from 'modules/inspections/components/newInspection/newInspection.model';
import PropTypes from 'prop-types';

import { Icon, Tooltip } from '@material-ui/core';

import Api from '../../../../services/api';

const InspectionReport = ({ inspection }) => {
  const inspectionIsClosed = inspection.status === INSPECTION_STATUSES.completed;

  const isStandaloneInspection = inspection.structureType === INSPECTION_STRUCTURE.standalone;

  const inspectionAtLeastFinished = [INSPECTION_STATUSES.finished, INSPECTION_STATUSES.completed].includes(
    inspection.status,
  );

  const saveInspectionReport = (url, identifier, title, type) => async (e) => {
    e.stopPropagation();
    const response = await Api.get(`${url}`, null, 'blob');
    const jsonBlob = new Blob([response], { type: 'charset=utf-8' });
    const fileName = identifier || title;
    saveAs(jsonBlob, `${fileName}__${type}.pdf`);
  };

  return (
    <>
      {inspectionAtLeastFinished && isStandaloneInspection && (
        <div className="row no-gutters align-items-center justify-content-end flex-wrap">
          {inspectionIsClosed && inspection.closeReportAttachmentId && (
            <Tooltip title="Closed Report">
              <Icon
                className="svg-large cursor-pointer border rounded"
                onClick={saveInspectionReport(
                  `/api/attachments/${inspection.closeReportAttachmentId}/download`,
                  inspection.identifier,
                  inspection.title,
                  'closed',
                )}
              >
                <FiletypePDF />
              </Icon>
            </Tooltip>
          )}
          {inspection.finishReportAttachmentId && (
            <Tooltip title="Finished Report">
              <Icon
                className="svg-large cursor-pointer border rounded icon--red"
                onClick={saveInspectionReport(
                  `/api/attachments/${inspection.finishReportAttachmentId}/download`,
                  inspection.identifier,
                  inspection.title,
                  'finished',
                )}
              >
                <FiletypePDF />
              </Icon>
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
};

InspectionReport.propTypes = {
  inspection: PropTypes.shape({}).isRequired,
};

export default InspectionReport;
