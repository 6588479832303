import React from 'react';

const AvatarOffshore = (props) => (
  <svg xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40" {...props}>
    <defs>
      <circle id="a" cx={20} cy={20} r={20} />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <path clipPath="url(#b)" fill="#FFF" d="M-4-4h48v48H-4z" />
    <path clipPath="url(#b)" fill="#FCD5C0" d="M-4-4h48v48H-4z" />
    <path
      clipPath="url(#b)"
      fill="#FFF"
      d="M39.5 20.2c-1.1.5-2.3.8-3.5.8-2.8 0-5.5-1.3-7.2-3.7l-.8-1-.8 1.1C25.5 19.7 22.8 21 20 21s-5.5-1.3-7.2-3.7l-.8-1-.8 1.1C9.5 19.7 6.8 21 4 21c-1.2 0-2.4-.3-3.5-.8L0 20c0 11 9 20 20 20s20-8.9 20-20l-.5.2z"
    />
    <g clipPath="url(#b)">
      <defs>
        <path
          id="c"
          d="M-4 44V17.9C-2.2 20.3.7 22 4 22s6.2-1.6 8-4.1c1.8 2.4 4.7 4.1 8 4.1s6.2-1.6 8-4.1c1.8 2.4 4.7 4.1 8 4.1s6.2-1.6 8-4.1V44H-4z"
        />
      </defs>
      <clipPath id="d">
        <use xlinkHref="#c" overflow="visible" />
      </clipPath>
      <path clipPath="url(#d)" fill="#045D64" d="M-4-4h48v48H-4z" />
      <g clipPath="url(#d)">
        <path
          opacity={0.2}
          fill="#FFF"
          d="M-4-4v33.9C-2.2 32.3.7 34 4 34s6.2-1.6 8-4.1c1.8 2.4 4.7 4.1 8 4.1s6.2-1.6 8-4.1c1.8 2.4 4.7 4.1 8 4.1s6.2-1.6 8-4.1V-4H-4z"
        />
      </g>
      <g clipPath="url(#d)">
        <path
          opacity={0.2}
          fill="#FFF"
          d="M-4-4v27.9C-2.2 26.3.7 28 4 28s6.2-1.6 8-4.1c1.8 2.4 4.7 4.1 8 4.1s6.2-1.6 8-4.1c1.8 2.4 4.7 4.1 8 4.1s6.2-1.6 8-4.1V-4H-4z"
        />
      </g>
      <g clipPath="url(#d)">
        <path
          opacity={0.2}
          fill="#FFF"
          d="M-4-4v21.9C-2.2 20.3.7 22 4 22s6.2-1.6 8-4.1c1.8 2.4 4.7 4.1 8 4.1s6.2-1.6 8-4.1c1.8 2.4 4.7 4.1 8 4.1s6.2-1.6 8-4.1V-4H-4z"
        />
      </g>
    </g>
  </svg>
);

export default AvatarOffshore;
