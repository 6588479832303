import React, { FC, KeyboardEvent, MouseEvent } from 'react';

import { FindingPhotoMain } from 'assets/icons/index';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import Image from 'modules/common/components/image/image.component';

import { Grid, GridListTileBar, Typography } from '@material-ui/core';

interface IAttachment extends File {
  path?: string;
  id: string;
  title?: string;
  name: string;
  mimeType: string;
  description: string;
  main: boolean;
  finding: any;
}

interface IImagesFilesProps {
  photos: IAttachment[];
  openPreview: (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => void;
  groupedByMainSystem?: boolean;
  isCreate?: boolean;
  isWorkflow?: boolean;
}

const ImagesFiles: FC<IImagesFilesProps> = ({
  photos,
  openPreview,
  groupedByMainSystem = false,
  isCreate = false,
  isWorkflow = false,
}) => {
  const renderPhoto = (attachment: IAttachment) => {
    const thumbnail =
      _get(attachment, 'thumbnailPath') || `/api/attachments/${attachment.id}/download?thumbnailType=SMALL`;

    return (
      <Grid
        xs={12}
        md={5}
        lg={4}
        key={attachment.id}
        id={attachment.id}
        className="tile col-md-4"
        onClick={openPreview}
        onKeyDown={openPreview}
        role="button"
        tabIndex={0}
      >
        {!!thumbnail && (
          <div className="d-flex flex-column justify-content-between p-3 h-100">
            <div className="row no-gutters align-items-center flex-fill">
              <div className="image-wrapper">
                <Image src={thumbnail} alt={attachment.description} mimeType={attachment.type} />
                {attachment.main && (
                  <GridListTileBar
                    titlePosition="top"
                    actionPosition="left"
                    className="marked-tile"
                    actionIcon={
                      <div className="icon-background--white">
                        <FindingPhotoMain className="icon-status-ongoing" />
                      </div>
                    }
                    classes={{ actionIcon: 'd-flex' }}
                  />
                )}
              </div>
            </div>

            <Typography className="pt-2" color="textSecondary" variant="caption">
              {attachment.description}
            </Typography>
          </div>
        )}
      </Grid>
    );
  };

  if (groupedByMainSystem) {
    const mainSystem = _uniq(photos.map((p) => p.finding.mainSystem));
    const renderGroupedPhotos = mainSystem.map((ms) => {
      const renderPhotosByGroup = photos.filter((p) => p.finding.mainSystem === ms).map(renderPhoto);

      return (
        <Grid className="col no-gutters" key={ms} xs={12}>
          <Typography color="textSecondary" variant="body2" className="pb-3">
            {ms || 'Main System not selected'}
          </Typography>
          <Grid container spacing={2} className="row no-gutters">
            {renderPhotosByGroup}
          </Grid>
        </Grid>
      );
    });

    return <>{renderGroupedPhotos}</>;
  }

  const renderPhotos = photos.map(renderPhoto);

  return <>{renderPhotos}</>;
};

export default ImagesFiles;
