import {
  SET_FINDINGS,
  CLEAR_DATA,
  SET_IS_LOADING,
  CLEAR_SELECTED_FINDINGS,
  SELECT_FINDING,
  SET_SELECTED_FINDING_WBS,
  SET_SIMILAR_FINDINGS,
  SET_SIMILAR_FINDING_ID,
} from './findings.actions';

const initialState = {
  items: [],
  selected: {
    ids: [],
    wbs: '',
  },
  similarFindingId: '',
  totalCount: 0,
  isLoading: true,
  similar: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_FINDINGS:
      return {
        ...state,
        items: payload.content,
        totalCount: payload.totalElements,
        similarFindingId: initialState.similarFindingId,
        isLoading: false,
        similar: false,
      };
    case SET_SIMILAR_FINDING_ID:
      return {
        ...state,
        similarFindingId: payload,
      };
    case SET_SIMILAR_FINDINGS:
      return {
        ...state,
        items: payload.content,
        totalCount: payload.totalElements,
        isLoading: false,
        similar: true,
      };
    case SELECT_FINDING:
      return {
        ...state,
        selected: {
          ...state.selected,
          ids: payload,
        },
      };
    case SET_SELECTED_FINDING_WBS:
      return {
        ...state,
        selected: {
          ...state.selected,
          wbs: payload,
        },
      };
    case CLEAR_SELECTED_FINDINGS:
      return {
        ...state,
        selected: initialState.selected,
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
