import React, { forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Notifications from 'services/notifications';

import RoleRow from './roleRow.component';
import { addEmail, addUser, deactivateUser, removeEmail, setMainUser } from './wbsDetails.actions';

const userRole = 'CONTRACTOR';

const ContractorsTabRows = forwardRef(({ confirmDelete, data: { contractors = [], hasChildren, roles } }, ref) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.wbsDetails.isLoading);
  const userIdsAssignInOtherRoles = Object.values(roles)
    .flat()
    .map((user) => user.id);

  const uniquedUserIds = [...new Set(userIdsAssignInOtherRoles)];

  const handleSelect = (item, { key }) => {
    if (uniquedUserIds.includes(item.id)) {
      Notifications.showError(`User ${item.fullName} is already assigned to another role in this park`);
      return;
    }
    dispatch(addUser(item, { siteFacilityId: key, userRole }));
  };

  const handleSelectMain = (item, { key }) => {
    dispatch(setMainUser(item, { siteFacilityId: key, userRole }));
  };

  const handleAddEmail = (email, siteFacilityId) => {
    dispatch(addEmail(email, siteFacilityId));
  };

  const handleRemoveEmail = (email, siteFacilityId) => {
    dispatch(removeEmail(email, siteFacilityId));
  };

  const onDelete = (item) => {
    const {
      role: { value },
    } = item;
    confirmDelete(item, value);
  };

  useImperativeHandle(ref, () => ({
    handleDelete(clickedItem) {
      const {
        role: { key },
      } = clickedItem;
      dispatch(deactivateUser(clickedItem, { siteFacilityId: key, userRole }));
    },
  }));

  return (
    <>
      {contractors.map(({ siteFacility, users, contractorEmailGroup }) => (
        <RoleRow
          key={siteFacility.key}
          role={siteFacility}
          data={users}
          title={siteFacility.value}
          confirmDelete={onDelete}
          handleSelect={handleSelect}
          handleSelectMain={handleSelectMain}
          additionalQuery={{ siteFacilityId: siteFacility.key }}
          readOnly={!hasChildren || isLoading}
          withEmailInput
          emails={contractorEmailGroup}
          addEmail={handleAddEmail}
          removeEmail={handleRemoveEmail}
        />
      ))}
    </>
  );
});

export default ContractorsTabRows;
