import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import Api from '../../../../services/api';

class AudioPlayer extends PureComponent {
  state = {};

  async componentDidMount() {
    const { src, mimeType } = this.props;
    let parsedSrc = src;
    try {
      if (src instanceof Blob) {
        parsedSrc = URL.createObjectURL(src);
      } else if (src.includes('/api/attachments')) {
        const blob = await Api.get(src, null, 'blob');
        parsedSrc = URL.createObjectURL(blob);
      } else {
        parsedSrc = URL.createObjectURL(await (await fetch(`data:${mimeType};base64,${src}`)).blob());
      }
      this.setState({ parsedSrc });
    } catch (e) {
      this.setState({ error: true });
    }
  }

  render() {
    const { parsedSrc, error } = this.state;

    return (
      <div className="audio-player-component">
        {parsedSrc && (
          <audio controls>
            <track kind="captions" />
            <source src={parsedSrc} />
          </audio>
        )}
        {!parsedSrc && !error && <div>Loading...</div>}
        {!parsedSrc && error && <div>No content available</div>}
      </div>
    );
  }
}

AudioPlayer.propTypes = {
  src: PropTypes.oneOfType([PropTypes.instanceOf(Blob), PropTypes.string]).isRequired,
};

export default AudioPlayer;
