import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { AppBar, Toolbar, IconButton, Icon, Typography, Drawer, Hidden } from '@material-ui/core';

import { MainNavigationMenu } from '../../../../assets/icons/index';
import Menu from '../menu/menu.container';
import withHeader from './header.hoc';

const drawerClasses = {
  paper: 'drawer',
};

const drawerModalProps = {
  keepMounted: true,
};

class Header extends PureComponent {
  state = {
    drawerOpen: false,
  };

  handleDrawerToggle = () => {
    this.setState((prev) => ({ drawerOpen: !prev.drawerOpen }));
  };

  render() {
    const { title, showHeader, forcedTitle } = this.props;
    const { drawerOpen } = this.state;

    if (!showHeader && !forcedTitle) {
      return null;
    }

    return (
      <div className="w-100 header-component">
        <AppBar position="static" color="primary">
          <Toolbar>
            <IconButton className="left-button" aria-label="Open drawer" onClick={this.handleDrawerToggle}>
              <Icon className="svg-medium">
                <MainNavigationMenu className="icon-dark" />
              </Icon>
            </IconButton>
            <Typography className="title" noWrap>
              {forcedTitle || title}
            </Typography>
            <div className="grow" />
            <IconButton className="right-button" aria-label="Search">
              <Icon color="primary">search</Icon>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor="left"
            open={drawerOpen}
            onClose={this.handleDrawerToggle}
            classes={drawerClasses}
            ModalProps={drawerModalProps}
          >
            <Menu />
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string,
  forcedTitle: PropTypes.string,
  showHeader: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  title: '',
  forcedTitle: '',
};

export default withHeader(Header);
