import React from 'react';
import { withRouter } from 'react-router-dom';

import { FindingSeverityObservation, FindingSeverityNonConformity, RelatedItem } from 'assets/icons/index';
import classNames from 'classnames';
import { INSPECTION_STATUSES, WORKFLOWS_TYPES, WORKFLOW_STATUS_ICON } from 'modules/app/config/config';
import Image from 'modules/common/components/image/image.component';
import { FINDING_TYPES } from 'modules/findings/components/newFinding/newFinding.model';
import Acl, { PRIVILEGE_KEYS } from 'services/acl';

import { ListItemText, Typography } from '@material-ui/core';

const FindingTitleCell = ({
  item: { mainImageId, id, identifier, invalid, title, workflows, inspection, findingType },
  history,
}) => {
  const canShowFindingDetails = Acl.hasPrivilege(PRIVILEGE_KEYS.FINDINGS_DETAILS);

  const goToWorkflowDetails = (e) => {
    const {
      currentTarget: { id },
    } = e;

    history.push(`/workflowDetails/${id}`);
  };

  const goToDetails = () => {
    if (!canShowFindingDetails) {
      return;
    }

    history.push(`/inspectionDetails/${inspection.id}/editFinding/${id}`);
  };

  const goToPreview = () => {
    if (!canShowFindingDetails) {
      return;
    }

    history.push(`/inspectionDetails/${inspection.id}/findingDetails/${id}`);
  };

  const renderWorkflowItem = workflows?.map((workflow) => {
    const { id, type, title, mergedStatus } = workflow;
    const icon =
      type === WORKFLOWS_TYPES.nonconformity ? (
        <FindingSeverityNonConformity className="icon-min-width" />
      ) : (
        <FindingSeverityObservation className="icon-min-width" />
      );

    return (
      <div key={id} className="row no-gutters align-items-center justify-content-between mt-3">
        <div className="col-md-6 d-flex align-items-center">
          <RelatedItem className="icon-dark icon-min-width" />
          {icon}
          <Typography
            color="textSecondary"
            variant="caption"
            className="pl-2 text-nowrap text-truncate cursor-pointer MoreActionMenu"
            onClick={goToWorkflowDetails}
            id={id}
          >
            {title}
          </Typography>
        </div>
        <div className="col-md-6 d-flex align-items-center MoreActionMenu">
          {WORKFLOW_STATUS_ICON[mergedStatus]}
          <Typography color="textSecondary" variant="caption" className="pl-2">
            {mergedStatus}
          </Typography>
        </div>
      </div>
    );
  });

  const isPlanned = inspection.status === INSPECTION_STATUSES.planned;
  const isOngoing = inspection.status === INSPECTION_STATUSES.ongoing;
  const isTodo = findingType === FINDING_TYPES.TODO.value;
  const canEditTodo = isTodo && (isOngoing || isPlanned);

  return (
    <div className="d-flex flex-column">
      <div
        className={classNames('d-flex flex-row align-items-center', {
          invalid,
        })}
      >
        {mainImageId && (
          <Image
            src={`/api/attachments/${mainImageId}/download?thumbnailType=SMALL`}
            className="finding-thumbnail mr-2"
          />
        )}
        <ListItemText
          id={id}
          onDoubleClick={goToPreview}
          onClick={invalid || canEditTodo ? goToDetails : goToPreview}
          primary={title}
          secondary={identifier}
          className="cursor-pointer"
        />
      </div>
      {renderWorkflowItem}
    </div>
  );
};

export default withRouter(FindingTitleCell);
