import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FilteringTree from 'modules/common/components/filteringTree/filteringTree';
import InputModal from 'modules/common/components/inputModal/inputModal.container';
import Loader from 'modules/common/components/loader/loader.component';
import PropTypes from 'prop-types';
import Notifications from 'services/notifications';

import { FormControl, MenuItem, TextField } from '@material-ui/core';

import { getWorkPackagesForAdmin } from './checklistEditor.actions';

const ChecklistDataForm = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  isSubmitting,
  getMainSystems,
  isElements,
}) => {
  const dispatch = useDispatch();
  const wbsesForAdmin = useSelector((state) => state.checklistEditor.wbsesForAdmin);
  const { isLoading } = useSelector((state) => state.checklistEditor);

  useEffect(() => {
    if (!wbsesForAdmin || wbsesForAdmin?.length === 0) {
      dispatch(getWorkPackagesForAdmin());
    }
  }, []);

  const onWbsSelect = (e) => {
    setFieldValue('park', wbsesForAdmin.find((park) => park.id === e.target.value) || {});
    setFieldValue('parkId', e.target.value || '');
    setFieldValue('mainSystem', {});
    setFieldValue('mainSystemId', '');
  };

  const onMainSystemSelect = (_, mainSystem) => {
    setFieldValue('mainSystem', mainSystem || {});
    setFieldValue('mainSystemId', mainSystem?.id || '');
  };

  const checkIsParkSelected = (callback) => {
    if (!values.park?.id) {
      Notifications.show('Select Park first', 'warning');
      return;
    }
    callback();
  };

  if (isLoading) return <Loader />;

  const renderWorkPackages = wbsesForAdmin?.map((park) => (
    <MenuItem key={park.id} value={park.id}>
      {park.description} - {park.name}
    </MenuItem>
  ));

  return (
    <>
      <FormControl className="col-md-6 pr-md-3 mt-4">
        <TextField
          label="Park"
          id="park"
          name="park"
          select
          onChange={onWbsSelect}
          value={values.park.id || values.parkId || ''}
          disabled={isSubmitting || isElements}
          error={errors && !!errors.parkId}
          helperText={errors && errors.parkId}
        >
          {renderWorkPackages}
        </TextField>
      </FormControl>
      <InputModal
        className="col-md-6 pr-md-3"
        id="mainSystem"
        title="Main system"
        placeholder="Main system (optional)"
        fieldName="mainSystem"
        value={values?.mainSystem?.description || ''}
        clearFieldValue={onMainSystemSelect}
        getNodes={getMainSystems}
        getNodesParams={{ wbsDetails: values.park }}
        filterTreeId={null}
        error={!!errors && !!errors.mainSystem}
        errorText={errors && errors.mainSystem}
        disabled={isSubmitting || isElements}
        onClick={checkIsParkSelected}
      >
        <FilteringTree
          onSelect={onMainSystemSelect}
          searchPlaceholderText="Search for Main System"
          primaryTextField="description"
          secondaryTextField="mainSystemCode"
          checkForActive
        />
      </InputModal>
      <TextField
        label="Title"
        className="col-md-12 pr-md-3 mt-4"
        id="description"
        name="description"
        onChange={handleChange}
        value={values.description}
        error={!!errors && !!errors.description}
        helperText={errors && errors.description}
        disabled={isSubmitting}
      />
    </>
  );
};

ChecklistDataForm.propTypes = {
  errors: PropTypes.object.isRequired,
  getMainSystems: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default ChecklistDataForm;
