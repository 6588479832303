import React, { FC, useContext } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import { SettingsOutlined } from '@material-ui/icons';

import { TableContext } from './table.context';

const TableSettingsButton: FC = () => {
  const { handleOpenSettings } = useContext(TableContext);
  return (
    <Tooltip title="Table Settings">
      <IconButton onClick={handleOpenSettings}>
        <SettingsOutlined />
      </IconButton>
    </Tooltip>
  );
};

export { TableSettingsButton };
