import Api from 'services/api';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'WORKFLOWS';
const SET_WORKFLOWS = `${namespace}_SET_WORKFLOWS`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const SET_IS_ERROR = `${namespace}_SET_IS_ERROR`;
const CLEAR_DATA = `${namespace}_CLEAR_DATA`;

/*
 * REDUX ACTIONS
 */
const getWorkflows = (params, filterState) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const queryParams = {
      ...params,
    };

    delete queryParams.types;

    const body = {
      types: [(params.types || '').toUpperCase()],
    };

    const mapNamesToParams = {
      actionee: 'actioneeIds',
      status: 'statuses',
      siteFacility: 'siteFacilityIds',
      failureMode: 'failureModeIds',
      severity: 'severities',
      area: 'areas',
      serialDefect: 'serialDefect',
    };

    if (filterState) {
      Object.entries(filterState.filters).forEach(([key, val]) => {
        if (val && val.length > 0) {
          if (key === 'wbs') {
            body.wbsIds = val;
            return;
          }

          if (key === 'serialDefect') {
            body.serialDefect = val[val.length - 1];

            return;
          }

          body[mapNamesToParams[key]] = val;
        }

        if (filterState.search) {
          body.text = filterState.search;
        }
      });
    }

    const { content, totalElements } = await Api.post('/api/workflows/filter', body, queryParams);

    dispatch({
      type: SET_WORKFLOWS,
      payload: {
        content,
        totalElements,
      },
    });
  } catch (e) {
    dispatch({
      type: SET_IS_ERROR,
    });
    throw e;
  }
};

const clearData = () => ({ type: CLEAR_DATA });

const getPreliminaryReport = async (workflowId) =>
  Api.get(`/api/workflows/${workflowId}/preliminary-report`, null, 'blob');

export { getWorkflows, clearData, getPreliminaryReport };

export { SET_WORKFLOWS, SET_IS_LOADING, CLEAR_DATA, SET_IS_ERROR };
