import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import ConfirmModal from 'modules/common/components/confirmModal/confirmModal';
import { assigneeData } from 'modules/common/components/dynamicForm/dynamicForm.component';
import { assigneeChanges } from 'modules/common/components/dynamicFormDetails/dynamicFormDetails.component';
import PropTypes from 'prop-types';

import { Toolbar, Button, Typography, Fab, Icon, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const styles = (theme) => ({
  checkIcon: {
    fill: theme.palette.secondary.light,
  },
});

const WithAssigneeConfirmation = ({ assigneeChanged, assigneeInfo, children, customConfirmation, onSend, title }) =>
  assigneeChanged ? (
    <ConfirmModal
      confirmLabel="Yes"
      cancelLabel="No"
      confirmTitle={customConfirmation || `Are you sure you want to send ${title}?`}
      confirmFn={onSend}
    >
      <Tooltip title={assigneeInfo}>
        <Fab id="send:button" size="medium" variant="extended" color="primary">
          {children}
        </Fab>
      </Tooltip>
    </ConfirmModal>
  ) : (
    <Tooltip title={assigneeInfo}>
      <Fab id="send:button" size="medium" variant="extended" color="primary" onClick={onSend}>
        {children}
      </Fab>
    </Tooltip>
  );

WithAssigneeConfirmation.propTypes = {
  assigneeChanged: PropTypes.bool.isRequired,
  assigneeInfo: PropTypes.string.isRequired,
  customConfirmation: PropTypes.string.isRequired,
  onSend: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

class SaveHeaderWorkflowWeb extends PureComponent {
  handleCloseClick = () => {
    const { history, onClose } = this.props;

    if (onClose) {
      onClose();
    } else {
      history.goBack();
    }
  };

  render() {
    let modalTitle;
    const {
      assignee,
      customConfirmation,
      nextActionLabel,
      onClose,
      onSave,
      onSend,
      rollbackAllowed,
      saved,
      title,
      user,
    } = this.props;

    if ((!saved && rollbackAllowed) || (saved && rollbackAllowed)) {
      modalTitle = 'Are you sure you want to  return to previous step?';
    }

    if (saved && !rollbackAllowed) {
      modalTitle = 'Are you sure you want to discard changes?';
    }

    const assigneeChanged = assigneeChanges(assignee, user);
    const assigneeInfo = assigneeData(assignee);

    return (
      <Toolbar className="save-header-web-component p-0">
        <Typography variant="h5" color="textPrimary" className="title" noWrap>
          {title}
        </Typography>
        <div className="grow" />
        <ConfirmModal confirmLabel="Yes" cancelLabel="No" confirmTitle={modalTitle} confirmFn={this.handleCloseClick}>
          <Button id="close:button" className="left-button" aria-label="Close" disabled={!onClose}>
            Cancel
          </Button>
        </ConfirmModal>
        {onSave && (
          <Button id="save:button" className="right-button" aria-label="Save" onClick={onSave}>
            Save
          </Button>
        )}
        {onSend && (
          <WithAssigneeConfirmation
            assigneeChanged={assigneeChanged}
            title={title}
            onSend={onSend}
            assigneeInfo={assigneeInfo}
            customConfirmation={customConfirmation}
          >
            <Icon className="svg-medium">send</Icon>
            {nextActionLabel}
          </WithAssigneeConfirmation>
        )}
      </Toolbar>
    );
  }
}

SaveHeaderWorkflowWeb.propTypes = {
  assignee: PropTypes.objectOf(PropTypes.shape),
  customConfirmation: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.shape).isRequired,
  nextActionLabel: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onSend: PropTypes.func,
  rollbackAllowed: PropTypes.bool,
  saved: PropTypes.bool,
  title: PropTypes.string,
  user: PropTypes.objectOf(PropTypes.shape),
};

SaveHeaderWorkflowWeb.defaultProps = {
  assignee: {},
  customConfirmation: '',
  nextActionLabel: 'Send',
  onClose: null,
  onSave: null,
  onSend: null,
  rollbackAllowed: false,
  saved: false,
  title: '',
};

const Enchanted = withStyles(styles)(SaveHeaderWorkflowWeb);

export default connect(mapStateToProps)(Enchanted);
