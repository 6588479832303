import React, { PureComponent, Fragment } from 'react';

import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import PropTypes from 'prop-types';

import { Dialog, Typography, DialogActions, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import FilteringList from '../filteringList/filteringList.component';
import Loader from '../loader/loader.component';
import getUsers from './usersSearch.actions';

const renderItem = ({ fullName, company }) => (
  <div className="row no-gutters justify-content-between">
    <span>{fullName}</span>
    <Typography variant="caption">{company}</Typography>
  </div>
);

const renderItemSecondary = ({ email }) => email;

const styles = (theme) => ({
  action: {
    color: theme.palette.secondary.light,
  },
});

class UsersSearchModal extends PureComponent {
  constructor({ users }) {
    super();

    this.state = {
      open: false,
      isLoading: false,
      users,
      originalUsers: users,
    };
  }

  componentDidUpdate({ users }) {
    const { searchOnOpen } = this.props;
    const { originalUsers } = this.state;

    if (!searchOnOpen && users !== originalUsers) {
      this.setState({
        users,
        originalUsers: users,
      });
    }
  }

  openDialog = async () => {
    try {
      const { searchOnOpen, getUsers } = this.props;

      if (searchOnOpen) {
        this.setState({
          isLoading: true,
          open: true,
        });

        const users = await getUsers();

        this.setState({
          isLoading: false,
          users,
          originalUsers: users,
        });
      }

      this.setState({ open: true });
    } catch (e) {
      this.setState({ isLoading: false });
      genericErrorHandler(e);
    }
  };

  closeDialog = () => {
    this.setState({ open: false });
  };

  getUsersLocal = (q) => {
    const { originalUsers } = this.state;

    if (!q) {
      return originalUsers;
    }

    return originalUsers.filter((u) => u.fullName.toLowerCase().includes(q.toLowerCase()));
  };

  handleGetData = async (q) => {
    try {
      const { local, getUsers, additionalQueryParams } = this.props;
      this.setState({ isLoading: true });

      const users = local ? this.getUsersLocal(escape(q)) : await getUsers(escape(q), additionalQueryParams);

      this.setState({ users, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      genericErrorHandler(e);
    }
  };

  handleOnSelect = (item) => {
    const { onSelect, extraData } = this.props;

    onSelect(item, extraData);
    this.closeDialog();
  };

  handleConfirm = (_, item) => {
    const { confirmFn, extraData } = this.props;

    confirmFn(_, item, extraData);
    this.closeDialog();
  };

  render() {
    const { open, users, isLoading } = this.state;
    const { children, classes, confirmFn, confirmIcon, confirmLabel, confirmTitle, confirmContent } = this.props;

    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        onClick: this.openDialog,
      }),
    );

    return (
      <>
        {childrenWithProps}
        <Dialog open={open} onClose={this.closeDialog} onEscapeKeyDown={this.closeDialog} fullWidth maxWidth="md">
          <FilteringList
            data={users}
            renderItem={renderItem}
            renderItemSecondary={renderItemSecondary}
            onSearch={this.handleGetData}
            onSelect={this.handleOnSelect}
            onClose={this.closeDialog}
            searchPlaceholder="Search for users"
            confirmFn={confirmFn ? this.handleConfirm : null}
            confirmIcon={confirmIcon}
            confirmLabel={confirmLabel}
            confirmTitle={confirmTitle}
            confirmContent={confirmContent}
          />
          {isLoading && <Loader mask />}
          <DialogActions>
            <Button onClick={this.closeDialog} color="secondary" className={classes.action}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

UsersSearchModal.propTypes = {
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func,
  extraData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.shape,
    PropTypes.object,
  ]),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
      company: PropTypes.string,
    }),
  ),
  confirmFn: PropTypes.func,
  confirmIcon: PropTypes.string,
  confirmLabel: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmContent: PropTypes.func,
  getUsers: PropTypes.func,
  local: PropTypes.bool,
  searchOnOpen: PropTypes.bool,
  additionalQueryParams: PropTypes.shape({}),
  classes: PropTypes.shape({}),
};

UsersSearchModal.defaultProps = {
  extraData: null,
  users: [],
  confirmFn: null,
  onSelect: null,
  confirmIcon: '',
  confirmLabel: '',
  confirmTitle: '',
  confirmContent: null,
  getUsers,
  local: false,
  searchOnOpen: false,
  additionalQueryParams: {},
  classes: {},
};

export default withStyles(styles)(UsersSearchModal);
