import React from 'react';

import {
  NonconformityStatusCarIssued,
  NonconformityStatusCarRequested,
  NonconformityStatusImmediateActionIssued,
  NonconformityStatusMitigationPlanIssued,
  NonconformityStatusMitigationPlanRequested,
  NonconformityStatusNew,
  NonconformityStatusQaNonCompliant,
  ObservationStatusIssuedToContractor,
  ObservationStatusNew,
  ObservationStatusRepliedByContractor,
  ObservationStatusReviewedWithComments,
} from 'assets/icons/index';
import find from 'lodash/find';
import _pick from 'lodash/pick';

import { createTheme } from '@material-ui/core/styles';

const API_URL = process.env.REACT_APP_API_URL;

const scopeUrl = process.env.REACT_APP_SCOPE;

const B2BConfig = {
  auth: {
    clientId: process.env.REACT_APP_B2B_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/f8be18a6-f648-4a47-be73-86d6c5c6604d',

    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage', // TODO: Daniel change
    storeAuthStateInCookie: true,
  },
};
const FILTER_COOKIE_NAMES = {
  INSPECTIONS: 'FILTERS_INSPECTIONS',
  OBSERVATION: 'OBSERVATION',
  DEFECT_NOTIFICATION: 'DEFECT_NOTIFICATION',
  NONCONFORMITY: 'NONCONFORMITY',
  FINDINGS: 'FILTERS_FINDINGS',
  CHECKLISTS: 'FILTERS_CHECKLISTS',
  INSPECTION_SORT: 'INSPECTION_SORT',
  WORKFLOW_ADD_FINDINGS: 'WORKFLOW_ADD_FINDINGS',
};
const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};
const text1 = 'rgba(0, 0, 0, 0.87)';
const text2 = 'rgba(0, 0, 0, 0.60)';
const text3 = 'rgba(0, 0, 0, 0.38)';

const CONSTRUCTION_TYPE = 'CONSTRUCTION';
const OM_TYPE = 'OPERATIONS_AND_MAINTENANCE';

const DATE_FORMAT = 'MMMM DD, YYYY';
const DATETIME_FORMAT = 'MMMM DD, YYYY, HH:mm';
const GUTTER_SIZE = 16;

const DEFAULT_PAGINATION_PAGE_SIZE = 25;
const DEFAULT_SORTING_DIRECTION = 'asc';

const FILE_SIZE_LIMIT = 30; // MB
const INTERNAL_ERROR_CODE = 'INTERNAL';

const INSPECTION_STATUSES = {
  invalidated: 'INVALIDATED',
  draft: 'DRAFT',
  planned: 'PLANNED',
  ongoing: 'ONGOING',
  finished: 'FINISHED',
  completed: 'CLOSED',
};

const CHECKLIST_STATUSES = {
  draft: 'DRAFT',
  active: 'ACTIVE',
  deactivated: 'DEACTIVATED',
};

const INSPECTION_FILTER_STATUSES = {
  closed: INSPECTION_STATUSES.completed,
  finished: INSPECTION_STATUSES.finished,
  open: [INSPECTION_STATUSES.draft, INSPECTION_STATUSES.ongoing, INSPECTION_STATUSES.planned],
  invalidated: INSPECTION_STATUSES.invalidated,
};

const INSPECTION_STATUSES_DATE_FIELDS = {
  [INSPECTION_STATUSES.invalidated]: 'closingDate',
  [INSPECTION_STATUSES.draft]: 'createDate',
  [INSPECTION_STATUSES.planned]: 'publicationDate',
  [INSPECTION_STATUSES.ongoing]: 'startDate',
  [INSPECTION_STATUSES.finished]: 'finishDate',
  [INSPECTION_STATUSES.completed]: 'closingDate',
};

const WORKFLOWS_TYPES = {
  nonconformity: 'NONCONFORMITY',
  observation: 'OBSERVATION',
  audit: 'AUDIT',
  defect_notification: 'DEFECT_NOTIFICATION',
};

const FINDINGS_TYPES = {
  list: 'List',
};

const DASHBOARDS_TYPES = {
  general: 'General',
};

const WORKFLOWS_TYPES_PLURAL = {
  nonconformity: 'NonConformities',
  observation: 'Observations',
  audit: 'Audit',
  defect_notification: 'Defect Notifications',
};

const STATUS_TO_ENDPOINT = {
  [INSPECTION_STATUSES.planned]: 'publish',
  [INSPECTION_STATUSES.ongoing]: 'start',
  [INSPECTION_STATUSES.finished]: 'finish',
  [INSPECTION_STATUSES.completed]: 'close',
};

const INSPECTION_STATUSES_DATE_LABELS = {
  [INSPECTION_STATUSES.invalidated]: 'Invalidated',
  [INSPECTION_STATUSES.draft]: 'Draft',
  [INSPECTION_STATUSES.planned]: 'Planned',
  [INSPECTION_STATUSES.ongoing]: 'Ongoing',
  [INSPECTION_STATUSES.finished]: 'Finished',
  [INSPECTION_STATUSES.completed]: 'Closed',
};

const SYNC_ACTIONS_TYPES = {
  INSPECTION_ADD: 'INSPECTION_ADD',
  INSPECTION_EDIT: 'INSPECTION_EDIT',
  INSPECTION_DELETE: 'INSPECTION_DELETE',
  INSPECTION_STATUS: 'INSPECTION_STATUS',
  FINDING_ADD: 'FINDING_ADD',
  FINDING_EDIT: 'FINDING_EDIT',
  FINDING_DELETE: 'FINDING_DELETE',
  ATTACHMENT_ADD: 'ATTACHMENT_ADD',
  ATTACHMENT_EDIT: 'ATTACHMENT_EDIT',
  ATTACHMENT_DELETE: 'ATTACHMENT_DELETE',
  CHECKLIST_SYNC: 'CHECKLIST_SYNC',
};

const WORKFLOW_STATUS_ICON = {
  'New Nonconformity': <NonconformityStatusNew className="icon-min-width" />,
  'New Observation': <ObservationStatusNew className="icon-min-width" />,
  'CAR Issued': <NonconformityStatusCarIssued className="icon-min-width" />,
  'CAR Requested': <NonconformityStatusCarRequested className="icon-min-width" />,
  'Immediate Action Issued': <NonconformityStatusImmediateActionIssued className="icon-min-width" />,
  'Issued to Contractor': <ObservationStatusIssuedToContractor className="icon-min-width" />,
  'Mitigation Plan Issued': <NonconformityStatusMitigationPlanIssued className="icon-min-width" />,
  'Mitigation Plan Requested': <NonconformityStatusMitigationPlanRequested className="icon-min-width" />,
  'QA Requested': <NonconformityStatusQaNonCompliant className="icon-min-width" />,
  'Replied by Contractor': <ObservationStatusRepliedByContractor className="icon-min-width" />,
  'Reviewed with Comments': <ObservationStatusReviewedWithComments className="icon-min-width" />,
};

const palette = {
  primary: {
    50: '#FFFDE5',
    100: '#FFFAC0',
    200: '#FFF695',
    300: '#FEF167',
    400: '#FBEC3F',
    500: '#F9E700',
    600: '#FFDA00',
    700: '#FFC100',
    800: '#FFA800',
    900: '#FF7B00',
  },
  secondary: {
    50: '#E1F4FD',
    100: '#B4E3FB',
    200: '#83D0F7',
    300: '#55BEF2',
    400: '#39B0F0',
    500: '#29A2EC',
    600: '#2795DD',
    700: '#2382C9',
    800: '#2071B5',
    900: '#1A5192',
  },
  error: {
    100: '#d32f2f',
  },
};

const USER_ROLES = [
  {
    key: 'INSPECTOR',
    label: 'Inspector',
    redirectUrl: '/inspections/open/asc',
  },
  {
    key: 'VF_MANAGER',
    label: 'VF Manager',
    redirectUrl: '/dashboard',
  },
  {
    key: 'QA_MANAGER',
    label: 'QA Manager',
    redirectUrl: '/inspections/open/asc',
  },
  {
    key: 'PCB_MEMBER',
    label: 'PCB Member',
    redirectUrl: '/workflows/nonconformity',
  },
  {
    key: 'CONTRACTOR',
    label: 'Contractor',
    redirectUrl: '/workflows/nonconformity',
  },
  {
    key: 'VIEWER',
    label: 'Viewer',
    redirectUrl: '/inspections/open/asc',
  },
  {
    key: 'REVIEWER',
    label: 'Reviewer',
    redirectUrl: '/workflows/nonconformity',
  },
  {
    key: 'ADMINISTRATOR',
    label: 'Admin',
    redirectUrl: '/dashboard',
  },
];

const GLOBAL_WBS = 'Global';

const PRIVILEGE_KEYS = {
  INSPECTIONS_LIST: 'INSPECTIONS.LIST',
  INSPECTIONS_DETAILS: 'INSPECTIONS.DETAILS',
  INSPECTIONS_CREATE: 'INSPECTIONS.CREATE',
  INSPECTIONS_EDIT: 'INSPECTIONS.EDIT',
  INSPECTIONS_DELETE: 'INSPECTIONS.DELETE',
  INSPECTIONS_EXECUTE: 'INSPECTIONS.EXECUTE',
  INSPECTIONS_FINISH: 'INSPECTIONS.FINISH',
  INSPECTIONS_CLOSE: 'INSPECTIONS.CLOSE',
  FINDINGS_LIST: 'FINDINGS.LIST',
  FINDINGS_CREATE: 'FINDINGS.CREATE',
  FINDINGS_DELETE: 'FINDINGS.DELETE',
  FINDINGS_DETAILS: 'FINDINGS.DETAILS',
  FINDINGS_EDIT: 'FINDINGS.EDIT',
  FINDINGS_RELATED: 'FINDINGS.RELATED',
  WBSES_LIST: 'WBSES.LIST',
  WBSES_EDIT: 'WBSES.EDIT',
  USERS_LIST: 'USERS.LIST',
  USERS_DETAILS: 'USERS.DETAILS',
  WORKFLOWS_LIST: 'WORKFLOWS.LIST',
  WORKFLOWS_CREATE: 'WORKFLOWS.CREATE',
  WORKFLOWS_DETAILS: 'WORKFLOWS.DETAILS',
  WORKFLOWS_DELETE: 'WORKFLOWS.DELETE',
  REPORTS_LIST: 'REPORTS.LIST',
  DASHBOARDS_VIEW: 'DASHBOARDS.VIEW',
  CHECKLISTS_LIST: 'CHECKLISTS.LIST',
  CHECKLISTS_EDIT: 'CHECKLISTS.EDIT',
};

const SYNC_ERRORS_TO_SKIP = [1001, 1007, 1012, 1016];

const FILE_PARENTS = {
  finding: 'Finding',
  findingsWorkflow: 'FindingsWorkflow',
  workflow: 'Workflow',
  mapParkWorkPackage: 'MapParkWorkPackage',
  workflowPart: 'WorkflowPart',
};

const WORKFLOW_STATUSES = {
  created: 'DRAFT',
  started: 'ONGOING',
  closed: 'CLOSED',
  invalidated: 'INVALIDATED',
};

const WORKFLOW_ACTION_TYPES = {
  information: 'REQUEST_FOR_INFORMATION',
  action: 'ACTION_SELECTOR',
};

const WORKFLOWS_DIALOG_TYPES = {
  SHOW_IMAGE_SELECTOR_POPUP: 'SHOW_IMAGE_SELECTOR_POPUP',
};

const CONNECTION_STATUS_ID = 'cs1';
const USER_ROLES_ID = 'ur1';
const USER_DETAILS_ID = 'ud1';

const EXCEL_MIMETYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
];

const WORD_MIMETYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
];

const ALLOWED_FILE_EXTENSIONS = {
  photos: {
    desc: 'PNG, JPG, BMP',
    mimes: ['image/png', 'image/jpeg', 'image/bmp'],
  },
  audios: {
    desc: 'WAV, OGG, WMA, MP3, MP4',
    mimes: [
      'audio/vnd.wav',
      'audio/wav',
      'audio/x-wav',
      'audio/ogg',
      'audio/x-ms-wma',
      'audio/mpeg',
      'audio/mp3',
      'audio/mpeg3',
      'audio/mp4',
    ],
  },
  docs: {
    desc: 'DOC, DOCX, XLS, XLSX, PPT, PPTX, PDF, TXT',
    mimes: [
      ...EXCEL_MIMETYPES,
      ...WORD_MIMETYPES,
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'text/plain',
      'application/pdf',
    ],
  },
  webOnly: {
    desc: 'ZIP, RAR',
    mimes: [' application/zip', 'application/x-zip-compressed', 'application/x-rar-compressed', 'application/x-rar'],
  },
};

const dataToStoreOffline = [
  {
    key: 'insepctions',
    url: '/api/inspections/filter?page=0&size=500',
    dbName: 'inspections',
    index: ['id'],
    mapData: (data) => data.content,
    mobileOnly: true,
  },
  {
    key: 'mainSystems',
    url: '/api/structures/main-systems',
    dbName: 'main_systems',
    syncDateField: 'mainSystemsLastSyncDate',
    mapData: (data) => data.filter((ms) => ms.active),
  },
  {
    key: 'subSystems',
    url: '/api/structures/sub-systems',
    dbName: 'sub_systems',
    syncDateField: 'subSystemsLastSyncDate',
  },
  {
    key: 'wbses',
    url: '/api/structures/wbses',
    syncDateField: 'wbsesLastSyncDate',
    dbName: 'wbs_all',
  },
  {
    key: 'wbses',
    url: '/api/structures/wbses?role=INSPECTOR,VF_MANAGER',
    syncDateField: 'wbsesLastSyncDate',
    dbName: 'wbs_inpsection',
  },

  {
    key: 'wbses',
    url: '/api/structures/wbses?role=ADMINISTRATOR',
    syncDateField: 'wbsesLastSyncDate',
    dbName: 'wbs_admin',
  },

  {
    key: 'wbses',
    url: '/api/structures/wbses?role=INSPECTOR,VF_MANAGER,PCB_MEMBER,QA_MANAGER',
    syncDateField: 'wbsesLastSyncDate',
    dbName: 'wbs_workflow_observation',
  },
  {
    key: 'wbsesQaManager',
    url: '/api/structures/wbses?role=INSPECTOR,VF_MANAGER,QA_MANAGER,CONTRACTOR',
    syncDateField: 'wbsesLastSyncDate',
    dbName: 'wbs_workflow_nonconformity',
  },
  {
    key: 'wbses',
    url: '/api/structures/wbses?role=INSPECTOR,VF_MANAGER,PCB_MEMBER,QA_MANAGER',
    syncDateField: 'wbsesLastSyncDate',
    dbName: 'wbs_workflow_audit',
  },
  {
    key: 'wbses',
    url: '/api/structures/wbses?role=VF_MANAGER',
    syncDateField: 'wbsesLastSyncDate',
    dbName: 'wbs_workflow_defect_notification',
  },
  {
    key: 'wbsStatusReports',
    url: '/api/structures/wbses?role=VF_MANAGER,QA_MANAGER',
    syncDateField: 'wbsesLastSyncDate',
    dbName: 'wbs_status_reports',
  },
  {
    key: 'failureModes',
    url: '/api/structures/failure-modes',
    syncDateField: 'failureModesLastSyncDate',
    dbName: 'failure_modes',
    mapData: (
      // remove wbses from structures (for failure mode filters)
      data,
    ) =>
      data.map((item) => {
        const pickFields = ['id', 'description', 'code', 'children'];
        const newItem = _pick(item, pickFields);
        newItem.children = newItem.children.map((child) => _pick(child, pickFields));
        return newItem;
      }),
  },
  {
    key: 'failureModesWbs',
    url: '/api/structures/failure-modes',
    syncDateField: 'failureModesLastSyncDate',
    dbName: 'failure_modes_v2',
    index: ['wbses'],
    mapData: (
      // transform into array of children: { childrenData, wbsesId: array<string>, parentObject}
      data,
    ) =>
      data.reduce((acc, curr) => {
        const { children } = curr;
        children.forEach((child) => {
          acc = [
            ...acc,
            {
              ...child,
              wbses: child.wbses.map((wbs) => wbs.id),
              parent: {
                id: curr.id,
                description: curr.description,
                code: curr.code,
              },
            },
          ];
        });
        return acc;
      }, []),
  },
  {
    key: 'topFailureModesWbs',
    url: '/api/structures/top-failure-modes/?top=10',
    syncDateField: 'topFailureModesLastSyncDate',
    dbName: 'top_failure_modes',
    index: ['workPackage'],
  },
  {
    key: 'users',
    url: '/api/user-management/wbs/users?role=INSPECTOR',
    syncDateField: 'usersLastSyncDate',
    dbName: 'inspectors_wbses',
    index: ['firstName', 'lastName', 'wbses'],
    mapData: (data) =>
      data.reduce((acc, curr) => {
        curr.users.forEach((user) => {
          const existingUser = find(acc, { id: user.id });

          if (existingUser) {
            existingUser.wbses.push(curr.workPackage);
          } else {
            acc.push({ ...user, wbses: [curr.workPackage] });
          }
        });

        return acc;
      }, []),
  },
  {
    key: 'facilities',
    url: '/api/structures/site-facilities',
    syncDateField: 'siteFacilitiesLastSyncDate',
    dbName: 'facilities',
    index: ['supplier'],
  },
  {
    key: 'checklists',
    url: '/api/checklists/mobile',
    syncDateField: 'checkListsLastSyncDate',
    dbName: 'checklists',
    index: ['parkId', 'status'],
    mapData: (data) =>
      data.map((item) => ({
        ...item,
        parkId: item.park.id,
      })),
  },
  {
    key: 'inspections_checklists',
    url: '/api/checklists/ongoing-inspections',
    dbName: 'sync_inspections_checklist',
    syncDateField: 'checkListsLastSyncDate',
    index: ['inspectionId'],
    mobileOnly: true,
    mapData: (data) => Object.keys(data).map((id) => ({ inspectionId: id, elements: data[id] })),
  },
];

export default createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: palette.primary[600] },
    secondary: {
      main: palette.secondary[800],
      light: palette.secondary[600],
    },
    error: {
      main: palette.error[100],
    },
    text: {
      primary: text1,
      secondary: text2,
      disabled: text3,
      hint: text3,
    },
  },
  overrides: {
    MuiFab: {
      root: {
        textTransform: 'capitalize',
        color: 'rgba(0,0,0,0.87)',
        fontSize: '0.875rem',
        letterSpacing: '0.0625rem',
        fontWeight: 300,
      },
      secondary: {
        color: '#FFF',
      },
    },
    MuiButton: {
      root: {
        letterSpacing: '0.0625rem',
        fontSize: '0.875rem',
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: palette.secondary[600],
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: palette.secondary[600],
        },
      },
      focused: {},
    },
    MuiPickersModal: {
      dialogAction: {
        color: text1,
        backgroundColor: '#fff',
      },
    },
    MuiInputAdornment: {
      root: {
        height: 'unset !important',
      },
    },
    MuiTableCell: {
      root: {
        padding: '16px 4px',
      },
    },
  },
});

const ONLY_FILES_ATTACHED_MSG = 'Only PDFs and pictures will be attached to final report';

export {
  ALLOWED_FILE_EXTENSIONS,
  API_URL,
  B2BConfig,
  CHECKLIST_STATUSES,
  CONNECTION_STATUS_ID,
  CONSTRUCTION_TYPE,
  DASHBOARDS_TYPES,
  dataToStoreOffline,
  DATE_FORMAT,
  DATETIME_FORMAT,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_SORTING_DIRECTION,
  EXCEL_MIMETYPES,
  FILE_PARENTS,
  FILE_SIZE_LIMIT,
  FILTER_COOKIE_NAMES,
  FINDINGS_TYPES,
  GLOBAL_WBS,
  GUTTER_SIZE,
  INSPECTION_FILTER_STATUSES,
  INSPECTION_STATUSES_DATE_FIELDS,
  INSPECTION_STATUSES_DATE_LABELS,
  INSPECTION_STATUSES,
  INTERNAL_ERROR_CODE,
  OM_TYPE,
  ONLY_FILES_ATTACHED_MSG,
  palette,
  PRIVILEGE_KEYS,
  scopeUrl,
  SORT_DIRECTION,
  STATUS_TO_ENDPOINT,
  SYNC_ACTIONS_TYPES,
  SYNC_ERRORS_TO_SKIP,
  USER_DETAILS_ID,
  USER_ROLES_ID,
  USER_ROLES,
  WORD_MIMETYPES,
  WORKFLOW_ACTION_TYPES,
  WORKFLOW_STATUS_ICON,
  WORKFLOW_STATUSES,
  WORKFLOWS_DIALOG_TYPES,
  WORKFLOWS_TYPES_PLURAL,
  WORKFLOWS_TYPES,
};
