import { connect } from 'react-redux';

import { createCrumb } from 'modules/app/components/app/app.actions';
import { clearSelectedFindings } from 'modules/findings/components/findings/findings.actions';

import {
  getWorkflow,
  startWorkflow,
  removeWorkflow,
  clearData,
  setIsLoading,
  createNc,
  changeDueDate,
  invalidateWorkflow,
  generateNCReport,
  getNcReport,
} from './workflowDetails.actions';
import WorkflowDetails from './workflowDetails.component';
import { getCurrentWorkflowRole } from './workflowDetails.selectors';

const mapStateToProps = (state) => ({
  isLoading: state.workflowDetails.isLoading,
  workflow: state.workflowDetails.data,
  participants: state.workflowDetails.participants,
  user: state.auth.user,
  currentWorkflowRole: getCurrentWorkflowRole(state),
});

const mapDispatchToProps = {
  getWorkflow,
  startWorkflow,
  removeWorkflow,
  invalidateWorkflow,
  clearData,
  setIsLoading,
  createCrumb,
  changeDueDate,
  createNc,
  generateNCReport,
  getNcReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDetails);
