import React, { useState } from 'react';

import { InspectionStatusClosed } from 'assets/icons/index';
import { DATETIME_FORMAT } from 'modules/app/config/config';
import Image from 'modules/common/components/image/image.component';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  withStyles,
} from '@material-ui/core';

const styles = (theme) => ({
  button: {
    color: theme.palette.secondary.light,
  },

  dialogContent: {
    overflow: 'auto',
    height: '50vh',
    width: '100%',
    marginTop: '50px',
  },
});

const WorkflowImageSelector = ({
  opened,
  submitCallback,
  closeCallback,
  classes,
  workflowDetails: { workflowHistory, attachments },
}) => {
  const [attachedToReport, setAttachedToReport] = useState([]);

  const submit = () => {
    submitCallback(attachedToReport);
  };

  const addToReport = (id) => {
    if (!attachedToReport.includes(id)) {
      setAttachedToReport([...attachedToReport, id]);
    } else {
      setAttachedToReport(attachedToReport.filter((item) => item !== id));
    }
  };

  const renderWorkflowAttachments = attachments.map((attachment) => (
    <FormControlLabel
      key={attachment.id}
      color="textPrimary"
      variant="body1"
      // eslint-disable-next-line prettier/prettier
      control={
        <>
          <Checkbox
            checked={attachedToReport.includes(attachment.id)}
            onChange={() => addToReport(attachment.id)}
            value={attachment.title}
            color="secondary"
          />
          <Image src={`/api/attachments/${attachment.id}/download?thumbnailType=SMALL`} className="attachment__thumb" />
        </>
        // eslint-disable-next-line prettier/prettier
      }
      label={`${attachment.title} - (${moment(attachment.modifyDate).format(DATETIME_FORMAT)})`}
    />
  ));

  const renderSections = workflowHistory
    .filter((item) => item.requiredForReport)
    .map((item) => {
      const renderAttachments = item.attachments.map((attachment) => (
        <FormControlLabel
          key={attachment.id}
          color="textPrimary"
          variant="body1"
          // eslint-disable-next-line prettier/prettier
          control={
            <>
              <Checkbox
                checked={attachedToReport.includes(attachment.id)}
                onChange={() => addToReport(attachment.id)}
                value={attachment.title}
                color="secondary"
              />
              <Image
                src={`/api/attachments/${attachment.id}/download?thumbnailType=SMALL`}
                className="attachment__thumb"
              />
            </>
            // eslint-disable-next-line prettier/prettier
          }
          label={`${attachment.title} - (${moment(attachment.modifyDate).format(DATETIME_FORMAT)})`}
        />
      ));

      if (item.attachments.length) {
        return (
          <div key={item.id}>
            <Typography color="textSecondary" variant="body2">
              {item.name}
            </Typography>

            {renderAttachments}
          </div>
        );
      }
      return null;
    });

  return (
    <Dialog open={opened} maxWidth="lg" onClose={closeCallback} onEscapeKeyDown={closeCallback}>
      <DialogTitle color="textPrimary" variant="default">
        Please choose attachments you want to include in NonConformity Report
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <div>
          <Typography color="textSecondary" variant="body2">
            Workflow
          </Typography>
          {renderWorkflowAttachments}
        </div>
        {renderSections}
      </DialogContent>

      <DialogActions>
        <Button onClick={closeCallback} className={classes.button} color="secondary">
          Close
        </Button>
        <Button onClick={submit} className="m-3 align-self-end" size="medium" variant="contained" color="primary">
          <InspectionStatusClosed className="icon-status large status--closed" style={{ fill: '#000' }} />
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WorkflowImageSelector.propTypes = {
  classes: PropTypes.shape({}),
  opened: PropTypes.bool,
  submitCallback: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
  workflowDetails: PropTypes.shape({}).isRequired,
};

WorkflowImageSelector.defaultProps = {
  classes: {},
  opened: false,
};

const Enchanted = withStyles(styles)(WorkflowImageSelector);

export default Enchanted;
