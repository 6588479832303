import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import classNames from 'classnames';
import { NavigationContextProvider, DrawerContextProvider } from 'context';
import FindingDetailsMobile from 'mobile/findings/findingDetailsMobile.component.tsx';
import NewFindingMobile from 'mobile/findings/newFindingMobile.component';
import InspectionDetailsMobile from 'mobile/inspections/inspectionDetailsMobile.component';
import InspectionsMobile from 'mobile/inspections/inspectionsMobile.component';
import NewInspectionMobile from 'mobile/inspections/newInspectionMobile.component';
import StatusReportsList from 'modules/admin/components/statusReports/statusReportsList.component';
import AdminTreeWeb from 'modules/admin/components/wbsesTree/wbsesTree.container';
import { getCookie, eraseCookie, isChromeBrowser, isSafariBrowser } from 'modules/app/helpers/utils';
import ChecklistsPage from 'modules/checklists/components/checklists.component';
import NewChecklist from 'modules/checklists/components/newChecklist.component';
import Breadcrumb from 'modules/common/components/breadcrumb/breadcrumb.container';
import HeaderMobile from 'modules/common/components/header/headerMobile.component';
import HeaderWeb from 'modules/common/components/header/headerWeb.component.tsx';
import MenuWeb from 'modules/common/components/menu/menuWeb.component.tsx';
import checkAccess from 'modules/common/hoc/checkAccess/checkAccess.hoc';
import Dashboard from 'modules/dashboard/components/dashboard/dashboard.container';
import FindingDetailsHeader from 'modules/findings/components/findingDetailsHeader/findingDetailsHeader.container';
import Findings from 'modules/findings/components/findings/findings.container';
import RelatedFindingWeb from 'modules/findings/components/relatedFinding/relatedFinding.component';
import InspectionDetailsWeb from 'modules/inspections/components/inspectionDetails/inspectionDetailsWeb.component';
import InspectionDetailsHeader from 'modules/inspections/components/inspectionDetailsHeader/inspectionDetailsHeader.container';
import InspectionsWeb from 'modules/inspections/components/inspections/inspectionsWeb.component';
import NewInspectionWeb from 'modules/inspections/components/newInspection/newInspectionWeb.component';
import UserDetails from 'modules/users/components/user/user.container';
import AdminUsersWeb from 'modules/users/components/users/users.container';
import NewWorkflow from 'modules/workflows/components/newWorkflow/newWorkflow.component';
import WorkflowDetails from 'modules/workflows/components/workflowDetails/workflowDetails.container';
import Workflows, {
  DefectNotificationsContainer,
  WorkflowsContainer,
} from 'modules/workflows/components/workflows/workflows.container';
import PropTypes from 'prop-types';
import { PRIVILEGE_KEYS } from 'services/acl';
import Notifications from 'services/notifications';

import { Box, Grid, useMediaQuery } from '@material-ui/core';

import { USER_ROLES } from '../../config/config';
import NoAccess from '../noAccess/noAccess.component';
import LoadingPage from './initalLoadingPage.component';

const InspectionsMobileWithAuth = checkAccess(InspectionsMobile, PRIVILEGE_KEYS.INSPECTIONS_LIST);
const NewInspectionMobileWithAuth = checkAccess(NewInspectionMobile, PRIVILEGE_KEYS.INSPECTIONS_CREATE);
const InspectionEditMobileWithAuth = checkAccess(NewInspectionMobile, PRIVILEGE_KEYS.INSPECTIONS_EDIT);
const InspectionDetailsMobileWithAuth = checkAccess(InspectionDetailsMobile, PRIVILEGE_KEYS.INSPECTIONS_DETAILS);
const NewFindingMobileWithAuth = checkAccess(NewFindingMobile, PRIVILEGE_KEYS.FINDINGS_CREATE);
const FindingEditMobileWithAuth = checkAccess(NewFindingMobile, PRIVILEGE_KEYS.FINDINGS_EDIT);
const FindingDetailsMobileWithAuth = checkAccess(FindingDetailsMobile, PRIVILEGE_KEYS.FINDINGS_DETAILS);

const DashboardWithAuth = checkAccess(Dashboard, PRIVILEGE_KEYS.DASHBOARDS_VIEW);

const InspectionsWebWithAuth = checkAccess(InspectionsWeb, PRIVILEGE_KEYS.INSPECTIONS_LIST);
const NewInspectionWebWithAuth = checkAccess(NewInspectionWeb, PRIVILEGE_KEYS.INSPECTIONS_CREATE);
const InspectionEditWebWithAuth = checkAccess(NewInspectionWeb, PRIVILEGE_KEYS.INSPECTIONS_EDIT);
const InspectionDetailsWebWithAuth = checkAccess(InspectionDetailsWeb, PRIVILEGE_KEYS.INSPECTIONS_DETAILS);
const NewWorkflowWithAuth = checkAccess(NewWorkflow, PRIVILEGE_KEYS.WORKFLOWS_CREATE);

const StatusReportsWithAuth = checkAccess(StatusReportsList, PRIVILEGE_KEYS.REPORTS_LIST);

const UsersWithAuth = checkAccess(AdminUsersWeb, PRIVILEGE_KEYS.USERS_LIST);
const UserDetailsWithAuth = checkAccess(UserDetails, PRIVILEGE_KEYS.USERS_DETAILS);
const WbsesWithAuth = checkAccess(AdminTreeWeb, PRIVILEGE_KEYS.WBSES_LIST);
const WorkflowsWithAuth = checkAccess(WorkflowsContainer, PRIVILEGE_KEYS.WORKFLOWS_LIST);
const DefectNotificationsWithAuth = checkAccess(DefectNotificationsContainer, PRIVILEGE_KEYS.WORKFLOWS_LIST);
const FindingsWithAuth = checkAccess(Findings, PRIVILEGE_KEYS.FINDINGS_LIST);

const WorkflowDetailsWithAuth = checkAccess(WorkflowDetails, PRIVILEGE_KEYS.WORKFLOWS_DETAILS);

const RelatedFindingWithAuth = checkAccess(RelatedFindingWeb, PRIVILEGE_KEYS.FINDINGS_RELATED);

const ChecklistsPageWithAuth = checkAccess(ChecklistsPage, PRIVILEGE_KEYS.CHECKLISTS_LIST);
const NewChecklistWithAuth = checkAccess(NewChecklist, PRIVILEGE_KEYS.CHECKLISTS_EDIT);

const Home = ({ checkMobile, init, initInProgress, isLogged, isMobile, showBanner, location, roles, history }) => {
  let redirectUrl = '/noAccess';
  const isChrome = isChromeBrowser();
  const isSafari = isSafariBrowser();

  const media = useMediaQuery('(min-width: 1152px)');

  const showWrongBrowserBanner = async () => {
    const BrowserBanner = await import('../../../common/components/browserBanner/browserBanner');
    Notifications.showBanner({ component: BrowserBanner.default });
  };

  const redirectToPageAfterLogin = () => {
    const redirectPage = getCookie('redirectToPathname');

    if (redirectPage) {
      history.push(redirectPage);
      eraseCookie('redirectToPathname');
    }
  };

  const checkRedirectUrl = () => {
    for (let i = 0; i < USER_ROLES.length; i += 1) {
      const role = USER_ROLES[i].key;

      if (roles.includes(role)) {
        redirectUrl = USER_ROLES[i].redirectUrl;
        break;
      }
    }
  };

  useEffect(() => {
    checkMobile();
  }, []);

  useEffect(() => {
    if (isLogged) {
      init();
      redirectToPageAfterLogin();
    }
    if (!isChrome && !isSafari) {
      showWrongBrowserBanner();
    }
  }, [isLogged]);

  if (initInProgress) {
    return <LoadingPage isMobile={isMobile} />;
  }

  if (!isLogged) {
    return <Redirect to="/login" />;
  }

  checkRedirectUrl();

  if (isMobile) {
    const classes = classNames('container-wrapper d-flex flex-column flex-fill', { 'with-banner': showBanner });

    return (
      <>
        {(location.pathname === '/inspections' || location.pathname === '/noAccess') && <HeaderMobile />}
        {location.pathname.includes('/inspectionDetails') && !location.pathname.includes('/findingDetails') && (
          <InspectionDetailsHeader />
        )}
        {location.pathname.includes('/findingDetails') && <FindingDetailsHeader />}
        <div className={classes}>
          <Switch>
            <Redirect exact from="/" to="/inspections" />
            <Route exact path="/inspections" component={InspectionsMobileWithAuth} />
            <Route exact path="/newInspection" component={NewInspectionMobileWithAuth} />
            <Route exact path="/newInspection/:inspectionId" component={NewInspectionMobileWithAuth} />
            <Route exact path="/editInspection/:editInspectionId" component={InspectionEditMobileWithAuth} />
            <Route exact path="/inspectionDetails/:id" component={InspectionDetailsMobileWithAuth} />
            <Route
              exact
              path="/inspectionDetails/:inspectionId/findingDetails/:id"
              component={FindingDetailsMobileWithAuth}
            />
            <Route exact path="/inspectionDetails/:inspectionId/newFinding" component={NewFindingMobileWithAuth} />
            <Route
              exact
              path="/inspectionDetails/:inspectionId/editFinding/:editFindingId"
              component={FindingEditMobileWithAuth}
            />
            <Route path="/relatedInspection/:inspectionId/relatedFinding/:id" component={RelatedFindingWithAuth} />

            <Route exact path="/noAccess" component={NoAccess} />
            <Redirect to="/" />
          </Switch>
        </div>
      </>
    );
  }

  return (
    <NavigationContextProvider>
      <Box className="container d-flex flex-column flex-fill">
        <HeaderWeb />
        <Grid className="flex-1" container>
          {!media && <MenuWeb />}
          {media && (
            <Grid item xs={3}>
              <MenuWeb />
            </Grid>
          )}
          <Grid item xs={media ? 9 : 12} className="flex-column pb-3">
            <Breadcrumb />
            <Switch>
              <Redirect exact from="/" to={redirectUrl} />
              <Route exact path="/dashboard" component={DashboardWithAuth} />
              <Route path="/inspections/:type" component={InspectionsWebWithAuth} />
              <Route
                exact
                path="/newInspection"
                render={(props) => <NewInspectionWebWithAuth key={Date.now()} {...props} />}
              />
              <Route exact path="/newInspection/:inspectionId" component={NewInspectionWebWithAuth} />
              <Route exact path="/editInspection/:editInspectionId" component={InspectionEditWebWithAuth} />
              <Route path="/inspectionDetails/:id" component={InspectionDetailsWebWithAuth} />
              <Route exact path="/admin/users" component={UsersWithAuth} />
              <Route path="/admin/wbses" component={WbsesWithAuth} />
              <Route exact path="/admin/users/:id" component={UserDetailsWithAuth} />
              <Route exact path="/findings/:type" component={FindingsWithAuth} />
              <Route exact path="/findings/:type/:selectedIds" component={FindingsWithAuth} />
              <Route exact path="/admin/reports" component={StatusReportsWithAuth} />
              <Route exact path="/workflow/:type" component={NewWorkflowWithAuth} />
              <Route exact path="/workflow/:type/copy/:workflowId" component={NewWorkflowWithAuth} />
              <Route exact path="/workflow/:type/:inspectionId/:findingId" component={NewWorkflowWithAuth} />
              <Route exact path="/editWorkflow/:editWorkflowId" component={NewWorkflowWithAuth} />
              <Route exact path="/workflowDetails/:workflowId" component={WorkflowDetailsWithAuth} />
              <Route exact path="/workflows/defect_notification" component={DefectNotificationsWithAuth} />
              <Route exact path="/workflows/:type" component={WorkflowsWithAuth} />

              <Route path="/relatedInspection/:inspectionId/relatedFinding/:id" component={RelatedFindingWithAuth} />

              <Route exact path="/admin/checklists" component={ChecklistsPageWithAuth} />

              <Route exact path="/admin/checklists/:id" component={NewChecklistWithAuth} />
              <Route exact path="/admin/newChecklist" component={NewChecklistWithAuth} />

              <Route exact path="/noAccess" component={NoAccess} />
              <Redirect to="/" />
            </Switch>
          </Grid>
        </Grid>
      </Box>
    </NavigationContextProvider>
  );
};

Home.defaultProps = {
  roles: [],
};

Home.propTypes = {
  checkMobile: PropTypes.func.isRequired,
  init: PropTypes.func.isRequired,
  initInProgress: PropTypes.bool.isRequired,
  isLogged: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  showBanner: PropTypes.bool.isRequired,
  location: PropTypes.objectOf(PropTypes.shape).isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  history: PropTypes.shape({}).isRequired,
};

export default Home;
