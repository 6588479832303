import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import FilteringList from '../filteringList/filteringList.component';
import InputModal from '../inputModal/inputModal.container';

class SelectList extends PureComponent {
  componentDidMount() {
    const { getData, extraParam } = this.props;

    getData(extraParam);
  }

  componentDidUpdate({ extraParam, value }) {
    const { extraParam: currentExtraParam, getData, data = [], autoSelect, value: currentValue } = this.props;

    if (currentExtraParam !== extraParam) {
      getData(currentExtraParam);
    }

    if (autoSelect && data.length === 1 && !value && !(currentValue && !value)) {
      this.onSelect(data[0]);
    }
  }

  onSearch = (text) => {
    const { getData, extraParam } = this.props;

    getData(extraParam, text);
  };

  onSelect = (item) => {
    const { onSelect, fieldName } = this.props;

    if (fieldName) {
      onSelect(fieldName, item);
    } else {
      onSelect(item);
    }
  };

  render() {
    const {
      className,
      data,
      value,
      id,
      placeholder,
      searchPlaceholder,
      renderItem,
      renderItemSecondary,
      error,
      errorText,
      rowHeight,
      onClick,
      fieldName,
      clearFieldValue,
      disabled,
      checkForActive,
      customLabel,
      customSearchPlaceholder,
      predefinePropName,
      predefineIfOne,
    } = this.props;

    const nodes = checkForActive ? data.filter((item) => item.active) : data;

    const predefineCondition = !value && predefineIfOne && nodes.length === 1;
    const valueToUse = predefineCondition ? nodes[0][predefinePropName] : value;

    return (
      <InputModal
        className={className}
        id={id}
        placeholder={customLabel || placeholder}
        value={valueToUse}
        error={error}
        errorText={errorText}
        onClick={onClick}
        fieldName={fieldName}
        clearFieldValue={clearFieldValue}
        disabled={disabled}
      >
        <FilteringList
          searchPlaceholder={customSearchPlaceholder || searchPlaceholder}
          data={nodes}
          renderItem={renderItem}
          renderItemSecondary={renderItemSecondary}
          onSearch={this.onSearch}
          onSelect={this.onSelect}
          rowHeight={rowHeight}
        />
      </InputModal>
    );
  }
}

SelectList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  getData: PropTypes.func.isRequired,
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  renderItemSecondary: PropTypes.func,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  rowHeight: PropTypes.number,
  className: PropTypes.string,
  extraParam: PropTypes.any,
  onClick: PropTypes.func,
  clearFieldValue: PropTypes.func.isRequired,
  autoSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  checkForActive: PropTypes.bool,
  customLabel: PropTypes.string,
  customSearchPlaceholder: PropTypes.string,
  predefinePropName: PropTypes.string,
  predefineIfOne: PropTypes.bool,
};

SelectList.defaultProps = {
  value: '',
  searchPlaceholder: '',
  error: false,
  errorText: '',
  renderItemSecondary: () => {},
  rowHeight: 50,
  className: 'w-100',
  extraParam: null,
  onClick: null,
  autoSelect: false,
  disabled: false,
  checkForActive: false,
  customLabel: '',
  customSearchPlaceholder: '',
  predefineIfOne: false,
};

export default SelectList;
