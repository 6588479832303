import { sortTree } from 'modules/app/helpers/utils';
import Api from 'services/api';
import Db from 'services/db';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'STATUS_REPORTS';
const FETCHED = `${namespace}_FETCH`;
const IS_LOADING = `${namespace}_IS_LOADING`;
const ADD = `${namespace}_ADD`;
const SELECTED = `${namespace}_SELECTED`;
const SELECTED_IS_LOADING = `${namespace}_SELECTED_IS_LOADING`;
const SET_WORKPACKAGES = `${namespace}_SET_WORKPACKAGES`;
const SET_SUPPLIERS = `${namespace}_SET_SUPPLIERS`;
const SUPPLIERS_IS_LOADING = `${namespace}_SUPPLIERS_IS_LOADING`;
const DELETE = `${namespace}_DELETE`;

const getWorkPackages = () => async (dispatch) => {
  try {
    const data = await Db.getAll('wbs_status_reports');
    const mappedData = data.rows.map((i) => i.doc);
    const payload = sortTree(mappedData, 'name', 'asc');
    dispatch({
      type: SET_WORKPACKAGES,
      payload,
    });
  } catch (e) {
    throw e;
  }
};

const getStatusReports = () => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });

    const payload = await Api.get('/api/status-reports');

    dispatch({
      type: FETCHED,
      payload,
    });
  } catch (e) {
    dispatch({
      type: IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const setSelectedReport = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SELECTED_IS_LOADING,
      payload: true,
    });

    const payload = await Api.get(`/api/status-reports/${id}`);

    dispatch({
      type: SELECTED,
      payload,
    });
  } catch (e) {
    dispatch({
      type: SELECTED_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const addNewReport =
  ({ wbs, title, supplier, comments, files }) =>
  async (dispatch, getState) => {
    try {
      const {
        statusReports: { items },
      } = getState();
      dispatch({
        type: IS_LOADING,
        payload: true,
      });

      const formData = new FormData();
      const request = {
        wbsId: wbs.id,
        title,
        comments,
        supplier: supplier.name,
      };

      formData.append('file', files[0]);
      formData.append('request', JSON.stringify(request));

      const response = await Api.post('/api/status-reports', formData);
      const newReport = {
        id: response.id,
        park: response.workPackage.rootDescription,
        title: response.title,
        workPackage: response.workPackage.description,
        createDate: response.createDate,
        supplier: response.supplier,
      };

      dispatch({
        type: ADD,
        payload: [...items, newReport],
      });
    } catch (e) {
      dispatch({
        type: IS_LOADING,
        payload: false,
      });
      throw e;
    }
  };

const fetchSuppliers = (wbsId) => async (dispatch) => {
  try {
    dispatch({
      type: SUPPLIERS_IS_LOADING,
      payload: true,
    });

    let payload = [];
    if (wbsId) {
      const data = await Api.get('/api/structures/site-facilities/filter', {
        wbsId,
      });
      payload = data.map((item) => ({
        active: item.active,
        id: item.supplier,
        name: item.supplier,
      }));
    }

    dispatch({
      type: SET_SUPPLIERS,
      payload,
    });
  } catch (e) {
    dispatch({
      type: SUPPLIERS_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const deleteStatusReport = (reportId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    const {
      statusReports: { items },
    } = getState();

    const payload = items.filter((item) => item.id !== reportId);
    await new Promise((resolve) => setTimeout(resolve, 1000));

    dispatch({
      type: DELETE,
      payload,
    });
  } catch (e) {
    dispatch({
      type: IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

export { getStatusReports, getWorkPackages, setSelectedReport, addNewReport, fetchSuppliers, deleteStatusReport };

export {
  IS_LOADING,
  FETCHED,
  SELECTED,
  ADD,
  SET_WORKPACKAGES,
  SET_SUPPLIERS,
  SUPPLIERS_IS_LOADING,
  DELETE,
  SELECTED_IS_LOADING,
};
