import React, { PureComponent } from 'react';

import { Chip } from '@material-ui/core';

class ChipWrapper extends PureComponent {
  handleOnDelete = () => {
    const { onDelete, item } = this.props;
    if (onDelete) {
      onDelete(item);
    }
  };

  handleOnClick = () => {
    const { onClick, item } = this.props;
    if (onClick) {
      onClick(item);
    }
  };

  render() {
    const { onDelete, onClick, ...rest } = this.props;
    return (
      <Chip
        className="chip"
        onDelete={onDelete ? this.handleOnDelete : undefined}
        onClick={this.handleOnClick}
        {...rest}
      />
    );
  }
}

export default ChipWrapper;
