import {
  SET_CO_INSPECTORS,
  CLEAN_DATA,
  SET_WORK_PACKAGE,
  SET_FACILITIES,
  SET_FUNCTIONAL_LOCATIONS,
  SET_IS_LOADING,
  SET_INSPECTORS,
} from './newInspection.actions';

const initialState = {
  inspectors: [],
  coInspectors: [],
  workPackage: [],
  facilities: [],
  functionalLocations: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CO_INSPECTORS: {
      return {
        ...state,
        coInspectors: payload,
      };
    }
    case CLEAN_DATA: {
      return { ...initialState };
    }
    case SET_WORK_PACKAGE: {
      return {
        ...state,
        workPackage: payload,
      };
    }
    case SET_FACILITIES: {
      return {
        ...state,
        facilities: payload,
      };
    }
    case SET_FUNCTIONAL_LOCATIONS: {
      return {
        ...state,
        functionalLocations: payload,
      };
    }
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case SET_INSPECTORS: {
      return {
        ...state,
        inspectors: payload,
      };
    }
    default:
      return state;
  }
};
