import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import PropTypes from 'prop-types';

import { getAttachment } from '../filesList/filesList.actions';
import { deleteAttachment, markAttachment } from './attachmentPreview.actions';

const initialState = {
  isLoading: false,
  data: {},
};

const mapDispatchToProps = {
  markAttachment,
};

const withAttachmentPreview = (PreviewComponent) => {
  class AttachmentPreview extends PureComponent {
    state = initialState;

    componentDidUpdate({ open, id: prevId }) {
      const { open: currentOpen, id, isMobile } = this.props;

      if ((!open && currentOpen) || (!isMobile && prevId !== id)) {
        this.handleGetAttachment();
      }
    }

    handleGetAttachment = async () => {
      try {
        const { id } = this.props;

        this.setIsLoading(true);

        const data = await getAttachment(id);

        this.setState({ data, isLoading: false });
      } catch (e) {
        genericErrorHandler(e);
        this.setIsLoading(false);
      }
    };

    setIsLoading = (isLoading) => {
      this.setState({ isLoading });
    };

    onClose = (reload) => {
      const { closeFn } = this.props;

      this.setState(initialState, () => closeFn(reload === true));
    };

    handleAttachmentDelete = async () => {
      try {
        const { id, registerSync, isMobile, callbackFn } = this.props;
        const { data } = this.state;
        this.setIsLoading(true);

        await deleteAttachment(data, id, isMobile);

        if (registerSync) {
          registerSync();
        }

        if (callbackFn) {
          await callbackFn();
        }

        this.onClose(true);
      } catch (err) {
        genericErrorHandler(err);
        this.setIsLoading(false);
      }
    };

    handleAttachmentEdit = () => {
      const { editFn, id } = this.props;

      this.onClose();
      editFn(id);
    };

    handleMark = async () => {
      try {
        const { isMobile, markAttachment, id } = this.props;
        const { data } = this.state;

        data.id = id;

        await markAttachment(data, isMobile);
        this.onClose(true);
      } catch (err) {
        genericErrorHandler(err);
      }
    };

    render() {
      const { isLoading, data } = this.state;

      return (
        <PreviewComponent
          isLoading={isLoading}
          data={data}
          handleMark={this.handleMark}
          handleAttachmentEdit={this.handleAttachmentEdit}
          handleAttachmentDelete={this.handleAttachmentDelete}
          onClose={this.onClose}
          {...this.props}
        />
      );
    }
  }

  AttachmentPreview.propTypes = {
    callbackFn: PropTypes.func,
    canEdit: PropTypes.bool.isRequired,
    closeFn: PropTypes.func.isRequired,
    editFn: PropTypes.func.isRequired,
    id: PropTypes.string,
    isMobile: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    photo: PropTypes.object.isRequired,
    registerSync: PropTypes.func,
  };

  AttachmentPreview.defaultProps = {
    callbackFn: null,
    id: null,
    registerSync: null,
  };

  return connect(null, mapDispatchToProps)(AttachmentPreview);
};

export default withAttachmentPreview;
