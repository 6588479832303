import React, { PureComponent, Fragment } from 'react';

import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Icon,
  Divider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const dialogClasses = {
  paper: 'm-3',
};

const styles = (theme) => ({
  action: {
    color: theme.palette.secondary.light,
    '&:focus,&:hover,&:visited,&:active': {
      color: theme.palette.secondary.light,
    },
  },
  text: {
    color: theme.palette.text.secondary,
  },
});

class InspectorInfo extends PureComponent {
  state = {
    open: false,
  };

  openDialog = () => {
    this.setState({ open: true });
  };

  closeDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const { children, data, classes } = this.props;

    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        onClick: this.openDialog,
      }),
    );

    return (
      <>
        {childrenWithProps}
        <Dialog fullWidth maxWidth={false} open={open} className="inspector-info-component" classes={dialogClasses}>
          <DialogTitle className="title" disableTypography>
            {data.fullName}
          </DialogTitle>
          <Divider />
          <div className="row no-gutters py-3">
            {data.phone && (
              <a
                href={`tel:${data.phone}`}
                className={`${classes.action} action-button d-flex flex-column flex-fill align-items-center justify-content-center`}
              >
                <Icon>call</Icon>
                Call
              </a>
            )}
            {data.email && (
              <a
                href={`mailto:${data.email}`}
                className={`${classes.action} action-button d-flex flex-column flex-fill align-items-center justify-content-center`}
              >
                <Icon>alternate_email</Icon>
                E-mail
              </a>
            )}
          </div>
          <Divider />
          <DialogContent className={`p-4 ${classes.text}`}>
            {data.phone && (
              <div className="row no-gutters align-items-center mb-3">
                <Icon className="mr-2">call</Icon>
                <Typography color="textSecondary" variant="body2">
                  {data.phone}
                </Typography>
              </div>
            )}
            {data.email && (
              <div className="row no-gutters align-items-center">
                <Icon className="mr-2">alternate_email</Icon>
                <Typography color="textSecondary" variant="body2">
                  {data.email}
                </Typography>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="secondary" className={classes.action}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

InspectorInfo.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(InspectorInfo);
