import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import FilteringTree from 'modules/common/components/filteringTree/filteringTree';
import InputModal from 'modules/common/components/inputModal/inputModal.container';
import PropTypes from 'prop-types';

import { Button, Icon, FormControl } from '@material-ui/core';

const DashboardFilters = ({ filters, setFilters }) => {
  const [filtersVisible, setFilterVisible] = useState(true);

  const clearFilters = () =>
    setFilters('all')(null, {
      park: {},
      siteFacility: {},
      supplier: {},
    });

  const stateFilters = useSelector((state) => state.dashboard.filters.data);

  const openFilters = () => setFilterVisible(!filtersVisible);
  const buttonVariant = filtersVisible ? 'outlined' : 'contained';

  const siteFacilities = filters.park.siteFacilities
    ? filters.park.siteFacilities.filter((item) => item.supplier === filters.supplier.supplier)
    : [];

  return (
    <div className="dashboard__filters mb-3">
      <div className="w-100">
        <Button className="outlined-fab" variant={buttonVariant} color="secondary" onClick={openFilters}>
          <Icon className="mr-1">filter_list</Icon>
          Filters
        </Button>
      </div>
      <div className={`dashboard__filters-filters ${filtersVisible ? 'open' : ''}`}>
        <FormControl className="col-md-3 pr-md-3 pb-1">
          <InputModal
            id="prakId"
            title="Park/Package"
            placeholder="Park/Package"
            fieldName="Park/Package"
            value={filters.park.description || filters.park.name || 'ALL'}
            blockRootClick
          >
            <FilteringTree
              nodes={stateFilters}
              onSelect={setFilters('park')}
              searchPlaceholderText="Search for Park"
              primaryTextField="description"
              alternativePrimaryTextField="name"
              secondaryTextField="number"
              sortProperty="name"
              canSelectParent
            />
          </InputModal>
        </FormControl>
        <FormControl className="col-md-3 pr-md-3 pb-1">
          <InputModal
            id="supplierId"
            title="Supplier"
            placeholder="Supplier"
            fieldName="park"
            value={filters.supplier.supplier || 'ALL'}
            disabled={!filters.park.id}
            blockRootClick
          >
            <FilteringTree
              nodes={filters.park.siteFacilities}
              onSelect={setFilters('supplier')}
              searchPlaceholderText="Search for Site / Facility"
              primaryTextField="supplier"
              secondaryTextField=""
              sortProperty="supplier"
            />
          </InputModal>
        </FormControl>
        <FormControl className="col-md-3 pr-md-3 pb-1">
          <InputModal
            id="siteFacilityId"
            title="Site Facility"
            placeholder="Site Facility"
            fieldName="park"
            value={filters.siteFacility.location || 'ALL'}
            blockRootClick
            disabled={!filters.supplier.id}
          >
            <FilteringTree
              nodes={siteFacilities}
              onSelect={setFilters('siteFacility')}
              searchPlaceholderText="Search for Site / Facility"
              primaryTextField="location"
              secondaryTextField="country"
              sortProperty="location"
              canSelectParent
            />
          </InputModal>
        </FormControl>
        <div className="d-flex align-items-end h-100 pb-1">
          <Button
            className="fab ml-2"
            variant="contained"
            color="primary"
            onClick={clearFilters}
            disabled={Object.keys(filters.park).length === 0}
          >
            <Icon className="mr-1">delete_forever</Icon>
            Clear Filters
          </Button>
        </div>
      </div>
    </div>
  );
};

DashboardFilters.propTypes = {
  filters: PropTypes.shape({
    workPackage: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    park: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    supplier: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    siteFacility: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default DashboardFilters;
