import React, { FC, useContext, useEffect } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FiltersContext } from '../../filters.context';
import { actions } from '../../filters.reducer';
import FilterTabs from '../filterTabs';

const useStyles = makeStyles((theme) => ({
  action: {
    color: theme.palette.secondary.light,
  },
  content: {
    padding: '0 0 50px 0',
  },
}));

interface IFiltersModalProps {
  open: boolean;
  closeDialog: () => void;
  onClearAll: () => void;
  additionalChips: { [key: string]: any };
}

const FiltersModal: FC<IFiltersModalProps> = ({ open, closeDialog, onClearAll, additionalChips }) => {
  const { dispatch, config } = useContext(FiltersContext);
  const classes = useStyles();
  const handleClearFilters = () => {
    const initialValues = {
      ...config.initialFilters,
    };

    Object.keys(config.filters).forEach((key) => {
      if (initialValues.filters?.[key]) {
        initialValues.filters[key] = [];
      }
    });

    onClearAll();
    dispatch(actions.clearFilters(initialValues)());
  };

  useEffect(() => {
    if (additionalChips) {
      Object.entries(additionalChips).forEach(([key, val]) => {
        dispatch(actions.setFilter(key, val));
      });
    }
  }, [additionalChips, dispatch]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={closeDialog}>
      <DialogTitle id="confirmation-dialog-title">Filters</DialogTitle>
      <DialogContent className={classes.content}>
        <FilterTabs />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} className={classes.action}>
          Close
        </Button>
        <Button onClick={handleClearFilters} className={classes.action}>
          Clear Filters
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FiltersModal;
