import React from 'react';
import { Provider } from 'react-redux';

import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import reducers from 'modules/app/config/reducers';
import reduxCatch from 'modules/app/helpers/reduxCatchMiddleware';
import PropTypes from 'prop-types';
import { applyMiddleware, createStore } from 'redux';
import reduxThunk from 'redux-thunk';

const createStoreWithMiddleware = applyMiddleware(reduxCatch(genericErrorHandler), reduxThunk)(createStore);

export const store = createStoreWithMiddleware(
  reducers,

  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    : undefined,
);

const ReduxWrapper = ({ children, withMockedStore }) => (
  <Provider store={withMockedStore || store}>{children}</Provider>
);

ReduxWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  withMockedStore: PropTypes.shape({}),
};

ReduxWrapper.defaultProps = {
  withMockedStore: null,
};

export default ReduxWrapper;
