import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Table, { TableUniqueKey } from '../../../common/components/table';
import ChangeParticipant from '../changeParticipant/changeParticipant.component';

const styles = (theme) => ({
  button: {
    color: theme.palette.secondary.light,
  },
});

class WorkflowParticipants extends PureComponent {
  tableConfig = {
    columns: [
      {
        caption: 'Role',
        dataField: 'role',
        align: 'left',
      },
      {
        caption: 'Assigned Users',
        dataField: 'user.fullName',
        align: 'center',
      },
      {
        caption: 'Actions',
        dataField: '',
        align: 'right',

        renderCell: (_, item) => {
          const { classes, ...rest } = this.props;

          return (
            <ChangeParticipant role={item.role} {...rest}>
              <Button color="secondary" className={classes.button}>
                Change
              </Button>
            </ChangeParticipant>
          );
        },
      },
    ],
    noItemsFoundText: 'No participants found',
    initialSort: {
      sortField: 'role',
      sortDirection: 'asc',
    },
  };

  render() {
    const { data } = this.props;

    return (
      <Table
        className="workflow-participants-component"
        config={this.tableConfig}
        items={data}
        idProperty="role"
        tableUniqueKey={TableUniqueKey.PARTICIPANTS}
      />
    );
  }
}

WorkflowParticipants.defaultProps = {
  data: [],
};

WorkflowParticipants.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  wbsId: PropTypes.string.isRequired,
  workflowId: PropTypes.string.isRequired,
  getWorkflow: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default withStyles(styles)(WorkflowParticipants);
