import { connect } from 'react-redux';

import { removeFinding } from '../findingDetails/findingDetails.actions';
import Header from './findingDetailsHeader.component';

const mapStateToProps = (state) => ({
  showHeader: state.app.showHeader,
  inspection: state.inspection.data,
});
const mapDispatchToProps = {
  removeFinding,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
