import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CONSTRUCTION_TYPE, INSPECTION_STATUSES } from 'modules/app/config/config';
import { charsLeft, isObjectEmpty } from 'modules/app/helpers/utils';
import FileUploadZone from 'modules/common/components/fileUpload/fileUpload.component';
import FilteringTree from 'modules/common/components/filteringTree/filteringTree';
import InputModal from 'modules/common/components/inputModal/inputModal.container';
import InputModalWithTabs from 'modules/common/components/inputModal/inputModalWithTabs.component';
import SaveHeaderWeb from 'modules/common/components/saveHeader/saveHeaderWeb.component';
import { getMainSystems, getSubSystems } from 'modules/inspections/components/newInspection/newInspection.actions';
import PropTypes from 'prop-types';

import { FormControl, TextField } from '@material-ui/core';

import { getFailureModes, getTopFailureModes } from './newFinding.actions';
import withNewFinding from './newFinding.hoc';
import { FINDING_TYPES } from './newFinding.model';

const NewFinding = ({
  deleteFile,
  errors,
  files,
  findings,
  getFinding,
  handleChange,
  handleSubmit,
  history,
  id,
  inspectionModel,
  inspection,
  isCreate,
  newFinding,
  renderFindingTypes,
  setFieldValue,
  setFiles,
  setMainSystemValue,
  setSelectValue,
}) => {
  const { inspectionId, editFindingId } = useParams();
  const [nextFindingDisabled, setNextFindingDisabled] = useState(false);
  const [prevFindingDisabled, setPrevFindingDisabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (inspectionId && editFindingId) {
      dispatch(getFinding(inspectionId, editFindingId));
    }
  }, [editFindingId]);

  const index = findings.findIndex((finding) => finding.id === id);

  const previousFinding = findings[index - 1];
  const nextFinding = findings[index + 1];

  useEffect(() => {
    if (previousFinding === undefined) {
      setPrevFindingDisabled(true);
    } else {
      setPrevFindingDisabled(false);
    }
  }, [previousFinding]);

  useEffect(() => {
    if (nextFinding === undefined) {
      setNextFindingDisabled(true);
    } else {
      setNextFindingDisabled(false);
    }
  }, [nextFinding]);

  const handlePreviousFinding = () => {
    if (previousFinding !== undefined) {
      history.replace(`/inspectionDetails/${inspectionId}/editFinding/${previousFinding.id}`);
    }
  };

  const handleNextFinding = () => {
    if (nextFinding !== undefined) {
      history.replace(`/inspectionDetails/${inspectionId}/editFinding/${nextFinding.id}`);
    }
  };

  const isDraft = inspectionModel.status === INSPECTION_STATUSES.draft;
  const isTodo = newFinding.findingType === FINDING_TYPES.TODO.value;
  const checklist = history.location.state?.checklist;

  const title = useMemo(() => {
    switch (true) {
      case isCreate && isTodo:
        return 'Register Todo';
      case !isCreate && isTodo && isDraft:
        return 'Edit Todo';
      case !isCreate && isTodo:
        return 'Register Finding based on Todo';
      case isCreate && !isObjectEmpty(checklist):
        return 'Register Finding based on checklist';
      case !isCreate && !isTodo:
        return 'Edit Finding';
      default:
        return 'Register Finding';
    }
  }, [newFinding.id]);

  const isConstruction = inspectionModel.type === CONSTRUCTION_TYPE;
  const hideForWorkInfo = newFinding.findingType === FINDING_TYPES.WORK_INFO.value && isConstruction;
  const hideOptionalPlaceholder = !isConstruction && inspectionModel.status === INSPECTION_STATUSES.finished;
  const shouldDisplayAttachmentUpload = isCreate && !isTodo;
  const fromChecklistWithSubSystem = !!checklist?.subSystem;

  const findingTypesList = renderFindingTypes(newFinding.findingType);

  return (
    <div className="mt-3 p-3 shadow rounded">
      <SaveHeaderWeb
        title={title}
        onSave={handleSubmit}
        history={history}
        isEdit={!isCreate}
        handlePreviousFinding={handlePreviousFinding}
        handleNextFinding={handleNextFinding}
        nextFindingDisabled={nextFindingDisabled}
        prevFindingDisabled={prevFindingDisabled}
        inspectionId={inspectionId}
      />
      <div id="new-finding:div" className="content-wrapper row no-gutters p-0">
        <InputModal
          className="col-md-4 pr-md-3"
          id="mainSystem"
          title="Main system"
          fieldName="mainSystem"
          placeholder={`Main system${isConstruction && !fromChecklistWithSubSystem ? ` (optional)` : ''}`}
          value={newFinding.mainSystem}
          clearFieldValue={setMainSystemValue}
          getNodes={getMainSystems}
          getNodesParams={inspectionModel}
          filterTreeId={inspectionModel.mainSystemDetails ? inspectionModel.mainSystemDetails.id : null}
          error={!!errors && !!errors.mainSystemId}
          errorText={errors && errors.mainSystemId}
          disabled={!!inspectionModel.mainSystem || !!inspectionModel.subSystem}
        >
          <FilteringTree
            onSelect={setMainSystemValue}
            searchPlaceholderText="Search for Main System"
            primaryTextField="description"
            secondaryTextField="mainSystemCode"
            checkForActive
          />
        </InputModal>

        <InputModal
          className="col-md-4 pr-md-3 mr-4"
          id="subSystem"
          title="Sub system"
          fieldName="subSystem"
          placeholder={`Sub system${hideOptionalPlaceholder ? '' : ` (optional)`}`}
          value={newFinding.subSystem}
          clearFieldValue={inspectionModel.subSystem ? null : setFieldValue}
          getNodes={getSubSystems}
          getNodesParams={newFinding}
          filterTreeId={inspectionModel.subSystemDetails ? inspectionModel.subSystemDetails.id : null}
          error={!!errors && !!errors.subSystemId}
          errorText={errors && errors.subSystemId}
        >
          <FilteringTree
            onSelect={setFieldValue}
            searchPlaceholderText="Search for Sub System"
            primaryTextField="subSystemCode"
            secondaryTextField="description"
            checkForActive
          />
        </InputModal>

        <FormControl className="col-md-4 mt-4 pr-md-3">
          <TextField
            label="Type"
            id="findingType"
            name="findingType"
            select
            value={newFinding.findingType || ''}
            onChange={setSelectValue('findingType')}
            disabled={findingTypesList.length === 1}
            error={errors && !!errors.findingType}
            helperText={errors && errors.findingType}
          >
            {findingTypesList}
          </TextField>
        </FormControl>

        {isConstruction && !hideForWorkInfo && (
          <FormControl className="col-md-4 mt-4 pr-md-3">
            <TextField
              label="Location of Finding"
              id="location"
              name="location"
              value={newFinding.location}
              onChange={handleChange}
              error={errors && !!errors.location}
              helperText={(errors && errors.location) || charsLeft(newFinding.location, 200)}
              multiline
            />
          </FormControl>
        )}

        {!hideForWorkInfo && (
          <InputModalWithTabs
            className="col-md-4 mt-4 pr-md-3"
            id="failureMode"
            title="Failure Mode"
            fieldName="failureMode"
            clearFieldValue={setFieldValue}
            placeholder="Failure Mode"
            value={newFinding.failureMode}
            getNodesParams={inspectionModel}
            error={errors && !!errors.failureModeId}
            errorText={errors && errors.failureModeId}
            tabs={[
              <FilteringTree
                getNodes={getFailureModes}
                onSelect={setFieldValue}
                searchPlaceholderText="Search for Failure Mode"
                primaryTextField="description"
                secondaryTextField="code"
                blockRootClick
                checkForActive
              />,
              <FilteringTree
                getNodes={getTopFailureModes}
                onSelect={setFieldValue}
                searchPlaceholderText="Search for Failure Mode"
                primaryTextField="description"
                secondaryTextField="code"
              />,
            ]}
            tabNames={['All', 'Most used']}
          />
        )}

        <FormControl className="col-md-8 mt-4 pr-md-3">
          <TextField
            label="Description"
            id="description"
            name="description"
            multiline
            value={newFinding.description}
            onChange={handleChange}
            error={errors && !!errors.description}
            helperText={(errors && errors.description) || charsLeft(newFinding.description, 2000)}
          />
        </FormControl>

        {isConstruction && (
          <>
            {!hideForWorkInfo && (
              <FormControl className="col-md-4 mt-4 pr-md-3">
                <TextField
                  label="Failure Mode details (optional)"
                  id="failureModeDetails"
                  name="failureModeDetails"
                  value={newFinding.failureModeDetails}
                  error={errors && !!errors.failureModeDetails}
                  helperText={(errors && errors.failureModeDetails) || charsLeft(newFinding.failureModeDetails, 2000)}
                  onChange={handleChange}
                  multiline
                />
              </FormControl>
            )}
            <FormControl className="col-md-4 mt-4 pr-md-3">
              <TextField
                label="Serial Number / ID Number"
                id="serialNumber"
                name="serialNumber"
                value={newFinding.serialNumber}
                onChange={handleChange}
                error={errors && !!errors.serialNumber}
                helperText={(errors && errors.serialNumber) || charsLeft(newFinding.serialNumber, 50)}
                multiline
              />
            </FormControl>

            <FormControl className="col-md-4 mt-4 pr-md-3">
              <TextField
                label="Reference Document"
                id="referenceDocument"
                name="referenceDocument"
                value={newFinding.referenceDocument}
                onChange={handleChange}
                error={errors && !!errors.referenceDocument}
                helperText={(errors && errors.referenceDocument) || charsLeft(newFinding.referenceDocument, 100)}
                multiline
              />
            </FormControl>
          </>
        )}

        {shouldDisplayAttachmentUpload && (
          <FileUploadZone setFiles={setFiles} files={files} handleDelete={deleteFile} isCreate={isCreate} />
        )}
      </div>
    </div>
  );
};

NewFinding.propTypes = {
  deleteFile: PropTypes.func,
  errors: PropTypes.object,
  files: PropTypes.arrayOf(PropTypes.object),
  findings: PropTypes.arrayOf(PropTypes.object),
  getFinding: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  id: PropTypes.string,
  inspectionModel: PropTypes.object,
  inspection: PropTypes.object,
  isCreate: PropTypes.bool,
  newFinding: PropTypes.object,
  renderFindingTypes: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFiles: PropTypes.func,
  setMainSystemValue: PropTypes.func,
  setSelectValue: PropTypes.func,
};

export default withNewFinding(NewFinding);
