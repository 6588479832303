import React, { PureComponent } from 'react';
import { Treebeard } from 'react-treebeard';

import PropTypes from 'prop-types';

class ExpandableTree extends PureComponent {
  onToggle = async (node, toggled) => {
    const { id, hasChildren } = node;
    const { getChildren } = this.props;

    Object.assign(node, { active: true });

    if (hasChildren) {
      const children = toggled ? await getChildren(id) : [];

      Object.assign(node, {
        toggled,
        children,
      });
    }

    this.forceUpdate();
  };

  render() {
    const { nodes, decorators } = this.props;

    return <Treebeard data={nodes} decorators={decorators} onToggle={this.onToggle} />;
  }
}

ExpandableTree.defaultProps = {};

ExpandableTree.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  getChildren: PropTypes.func.isRequired,
  decorators: PropTypes.objectOf(PropTypes.func).isRequired,
};

export default ExpandableTree;
