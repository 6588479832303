import React, { useState } from 'react';

import { Tabs, Tab, Divider, Icon } from '@material-ui/core';

export default (WrappedComponent) => {
  const withTabs = (props) => {
    const [tabIndex, setTabIndex] = useState((props.isMobile && props.defaultTabIndex) || 0);
    const onTabChange = (_, index) => {
      setTabIndex(index);
    };

    const renderTabs = (tabs) => (
      <>
        <Tabs value={tabIndex} onChange={onTabChange} indicatorColor="primary" scrollButtons="on" className="tabs">
          {tabs.map((tab) => (
            <Tab
              id={`${tab.id}-tab:button`}
              puppet-data={`${tab.id}:tab`}
              key={tab.id}
              label={tab.label}
              disabled={tab.disabled}
              style={{ display: tab.hideTab ? 'none' : 'block' }}
              icon={tab.tabIcon ? <Icon className="svg-medium">{tab.tabIcon}</Icon> : null}
            />
          ))}
        </Tabs>
        <Divider />
      </>
    );

    return <WrappedComponent {...props} renderTabs={renderTabs} tabIndex={tabIndex} />;
  };

  return withTabs;
};
