import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { GlobalActionEdit, GlobalActionMore, GlobalActionBack } from 'assets/icons/index';
import { generateFindingTitle } from 'modules/app/helpers/utils';
import ConfirmModal from 'modules/common/components/confirmModal/confirmModal';
import PropTypes from 'prop-types';

import { Typography, ListItemIcon, Icon, Menu, MenuItem, ListItemText, IconButton, Button } from '@material-ui/core';

import { FINDING_TYPES } from '../newFinding/newFinding.model';

class FindingDetailsFieldsWeb extends PureComponent {
  static defaultProps = {
    findings: [],
  };
  state = {
    anchorEl: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      nextFindingDisabled: false,
      prevFindingDisabled: false,
    };
  }

  componentDidMount() {
    this.updateFindingButtons();
  }

  componentDidUpdate(prevProps) {
    const { findings, id, location, match, getFinding } = this.props;
    if (prevProps.findings !== findings || prevProps.id !== id) {
      this.updateFindingButtons();
    }
    if (prevProps.location.pathname !== location.pathname) {
      const newId = match.params.id;
      getFinding(this.props.inspectionId, newId);
    }
  }

  updateFindingButtons() {
    const index = this.props.findings.findIndex((finding) => finding.id === this.props.id);

    let previousFinding = this.props.findings[index - 1];
    let nextFinding = this.props.findings[index + 1];

    this.setState({
      prevFindingDisabled: !previousFinding,
      nextFindingDisabled: !nextFinding,
    });
  }

  getFindingByDirection(direction) {
    const { findings, id } = this.props;
    const index = findings.findIndex((finding) => finding.id === id);
    return findings[direction === 'previous' ? index - 1 : index + 1];
  }

  handleFindingClick = (direction) => {
    const finding = this.getFindingByDirection(direction);
    if (finding) {
      const { history, inspectionId } = this.props;
      history.push(`/inspectionDetails/${inspectionId}/findingDetails/${finding.id}`);
    }
  };

  handlePreviousFindingClick = () => this.handleFindingClick('previous');
  handleNextFindingClick = () => this.handleFindingClick('next');

  handleMenuOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  getParams = () => {
    const { location } = this.props;
    return location.pathname.split('inspectionDetails/').pop().split('/findingDetails/');
  };

  handleEditFinding = () => {
    const { history } = this.props;
    const params = this.getParams();
    history.push(`/inspectionDetails/${params[0]}/editFinding/${params[1]}`);
  };

  handleRemoveFinding = async () => {
    const { history, removeFinding } = this.props;
    const params = this.getParams();

    await removeFinding(params[0], params[1]);
    history.replace(`/inspectionDetails/${params[0]}`);
  };

  goToPreviousSite = () => {
    const {
      history: {
        location: { state },
        replace: historyReplace,
        push: historyPush,
      },
    } = this.props;

    if (state && state.prevPath) {
      historyPush(state.prevPath);
      return;
    }

    const params = this.getParams();

    historyReplace(`/inspectionDetails/${params[0]}`);
  };

  renderMenu = () => {
    const { edit, remove } = this.props;
    const { anchorEl } = this.state;
    const isMenuOpen = !!anchorEl;

    return (
      <Menu
        id="inspection-details-menu:ul"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        {edit && (
          <MenuItem id="inspection-details-edit:li" onClick={this.handleEditFinding}>
            <ListItemIcon>
              <Icon className="svg-medium">
                <GlobalActionEdit className="icon-dark" />
              </Icon>
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {remove && (
          <ConfirmModal
            confirmFn={this.handleRemoveFinding}
            confirmTitle="Are you sure you want to delete this finding?"
            confirmIcon="delete"
          >
            <MenuItem id="inspection-details-edit:li">
              <ListItemIcon>
                <Icon>delete</Icon>
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </ConfirmModal>
        )}
      </Menu>
    );
  };

  render() {
    const { finding, edit, remove } = this.props;

    return (
      <>
        <div id="finding-details:div" className="flex-fill finding-details-web-component inspection-card">
          <div className="d-flex flex-row pt-4 justify-content-between">
            <div className="row no-gutters align-items-center">
              <IconButton
                id="menu-open:button"
                className="right-button"
                aria-label="Menu"
                onClick={this.goToPreviousSite}
              >
                <Icon className="svg-medium">
                  <GlobalActionBack className="icon-dark" />
                </Icon>
              </IconButton>
              <Typography className="title" color="textPrimary" variant="h5">
                {`${generateFindingTitle(
                  finding.mainSystem,
                  finding.subSystem,
                  finding.failureModeDetailsObj,
                  finding.failureModeDetails,
                )}`}
              </Typography>
            </div>
            {(edit || remove) && (
              <div className="row no-gutters align-items-center">
                <IconButton
                  id="menu-open:button"
                  className="right-button"
                  aria-label="Menu"
                  onClick={this.handleMenuOpen}
                >
                  <Icon className="svg-medium">
                    <GlobalActionMore className="icon-dark" />
                  </Icon>
                </IconButton>
              </div>
            )}
          </div>
          <Button disabled={this.state.prevFindingDisabled} onClick={this.handlePreviousFindingClick}>
            Previous
          </Button>

          <Button disabled={this.state.nextFindingDisabled} onClick={this.handleNextFindingClick}>
            Next
          </Button>
          <div className="row no-gutters pt-3 pb-3 border-bottom">
            {finding.mainSystem && (
              <div className="col-md-4 pb-3">
                <Typography color="textSecondary" variant="body2">
                  Main System
                </Typography>

                <Typography color="textPrimary" variant="body1">
                  {finding.mainSystem}
                </Typography>
              </div>
            )}
            {finding.subSystem && (
              <div className="col-md-4 pb-3">
                <Typography color="textSecondary" variant="body2">
                  Sub System
                </Typography>

                <Typography color="textPrimary" variant="body1">
                  {finding.subSystem}
                </Typography>
              </div>
            )}
            {finding.findingType && (
              <div className="col-md-4 pb-3">
                <Typography color="textSecondary" variant="body2">
                  Type
                </Typography>

                <Typography color="textPrimary" variant="body1">
                  {FINDING_TYPES[finding.findingType].label}
                </Typography>
              </div>
            )}
            {finding.location && (
              <div className="col-md-4 pb-3">
                <Typography color="textSecondary" variant="body2">
                  Location of Finding
                </Typography>

                <Typography color="textPrimary" variant="body1">
                  {finding.location}
                </Typography>
              </div>
            )}
            {finding.description && (
              <div className="col-md-4 mr-auto">
                <Typography color="textSecondary" variant="body2">
                  Description
                </Typography>

                <Typography color="textPrimary" variant="body1">
                  {finding.description}
                </Typography>
              </div>
            )}
            {finding.failureModeDetailsObj && finding.failureModeDetailsObj.description && (
              <div className="col-md-4 pt-3">
                <div className="pb-3">
                  <Typography color="textSecondary" variant="body2">
                    Failure Mode
                  </Typography>

                  <Typography color="textPrimary" variant="body1">
                    {finding.failureModeDetailsObj.description}
                  </Typography>
                </div>
                {finding.failureModeDetails && (
                  <div>
                    <Typography color="textSecondary" variant="body2">
                      Failure Mode Details
                    </Typography>

                    <Typography color="textPrimary" variant="body1">
                      {finding.failureModeDetails}
                    </Typography>
                  </div>
                )}
              </div>
            )}
            {(finding.referenceDocument || finding.serialNumber) && (
              <div className="col-md-4 mr-4">
                {finding.referenceDocument && (
                  <>
                    <Typography color="textSecondary" variant="body2" className="pt-3">
                      Reference Number
                    </Typography>

                    <Typography color="textPrimary" variant="body1">
                      {finding.referenceDocument}
                    </Typography>
                  </>
                )}

                {finding.serialNumber && (
                  <>
                    <Typography color="textSecondary" variant="body2" className="pt-3">
                      Serial Number / ID
                    </Typography>
                    <Typography color="textPrimary" variant="body1">
                      {finding.serialNumber}
                    </Typography>
                  </>
                )}
              </div>
            )}
            {finding.checkListElementDefinition?.description && (
              <div className="col-md-4">
                <Typography color="textSecondary" variant="body2" className="pt-3">
                  Checklist Element
                </Typography>
                <Typography color="textPrimary" variant="body1">
                  {finding.checkListElementDefinition?.description}
                </Typography>
              </div>
            )}
          </div>
        </div>
        {(edit || remove) && this.renderMenu()}
      </>
    );
  }
}

FindingDetailsFieldsWeb.propTypes = {
  finding: PropTypes.shape({}).isRequired,
  edit: PropTypes.bool.isRequired,
  remove: PropTypes.bool.isRequired,
};

export default withRouter(FindingDetailsFieldsWeb);
