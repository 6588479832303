import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { createCrumb } from 'modules/app/components/app/app.actions';
import { clearSelectedFindings } from 'modules/findings/components/findings/findings.actions';
import { getSelectedIds } from 'modules/findings/components/findings/findings.selectors';
import {
  getChecklistsDetails,
  clearData as clearChecklist,
} from 'modules/inspections/components/checklists/checklist.actions';
import Acl, { PRIVILEGE_KEYS } from 'services/acl';

import { INSPECTION_STATUSES } from '../../../app/config/config';
import Loader from '../../../common/components/loader/loader.component';
import { checkOutForMobile } from '../inspections/inspections.actions';
import NewInspection, { INSPECTION_STRUCTURE } from '../newInspection/newInspection.model';
import {
  getInspection,
  setInspectionStatus,
  clearData,
  removeInspection,
  selectInspectionFindings as selectFinding,
  selectAllFindings,
  setInspectionFindings,
} from './inspectionDetails.actions';
import { makeIsLoggedUserLeadInspector } from './inspectionDetails.selectors';

const isLoggedUserLeadInspector = makeIsLoggedUserLeadInspector();

const mapStateToProps = (state) => ({
  showSyncIndicator: state.app.showSyncIndicator,
  isMobile: state.app.isMobile,
  inspection: state.inspection.data,
  isLoading: state.inspection.isLoading,
  checklists: state.checklists.currentChecklistDetails.checklist,
  isLoggedUserLeadInspector: isLoggedUserLeadInspector(state),
  signMode: state.inspection.signMode,
  selectedIds: getSelectedIds(state),
  user: state.auth.user,
});

const mapDispatchToProps = {
  getInspection,
  clearData,
  setInspectionStatus,
  setInspectionFindings,
  removeInspection,
  createCrumb,
  selectFinding,
  selectAllFindings,
  clearSelectedFindings,
  getChecklistsDetails,
  clearChecklist,
  checkOutForMobile,
};

const withInspectionDetails = (InspectionDetailsComponent) => {
  class InspectionDetails extends PureComponent {
    canShowFindingDetails = Acl.hasPrivilege(PRIVILEGE_KEYS.FINDINGS_DETAILS);

    canCreateFinding = Acl.hasPrivilege(PRIVILEGE_KEYS.FINDINGS_CREATE);

    canCreateInspection = Acl.hasPrivilege(PRIVILEGE_KEYS.INSPECTIONS_CREATE);

    async componentDidMount() {
      const { getInspection, match } = this.props;

      const inspection = await getInspection(match.params.id);

      this.getChecklist(inspection);
    }

    async componentDidUpdate({ match: { params: prevParams } }) {
      const {
        getInspection,
        createCrumb,
        match: { params, path },
      } = this.props;

      // TODO: add getInspection after sync is finished. To show correct status in list of finding on mobile

      if (prevParams.id !== params.id) {
        const inspection = await getInspection(params.id);
        this.getChecklist(inspection);
      }

      createCrumb(path, params);
    }

    componentWillUnmount() {
      const { clearData, clearSelectedFindings, clearChecklist } = this.props;

      clearSelectedFindings();
      clearData();
      clearChecklist();
    }

    getChecklist(inspection) {
      const { getChecklistsDetails } = this.props;

      if (inspection) {
        getChecklistsDetails(inspection);
      }
    }

    goToInspectionDetails = () => {
      const { history, match } = this.props;

      history.push(`/inspectionDetails/${match.params.id}`);
    };

    goToMainInspection = () => {
      const {
        history,
        inspection: { mainInspectionId },
      } = this.props;

      history.push(`/inspectionDetails/${mainInspectionId}`);
    };

    goToFindingDetails = (e) => {
      if (!this.canShowFindingDetails) {
        return;
      }

      const {
        currentTarget: { id },
      } = e;
      const { history, match } = this.props;

      history.push(`/inspectionDetails/${match.params.id}/findingDetails/${id}`);
    };

    goToFindingAdd = (_, checklist = {}) => {
      const { history, match } = this.props;

      history.push(`/inspectionDetails/${match.params.id}/newFinding`, {
        checklist,
      });
    };

    handlePublish = () => {
      const { setInspectionStatus, isMobile } = this.props;

      setInspectionStatus(
        isMobile ? this.inspectionDetails.localId : this.inspectionDetails.id,
        INSPECTION_STATUSES.planned,
      );
    };

    handleAddSubInspection = () => {
      const { history, match } = this.props;

      history.push(`/newInspection/${match.params.id}`);
    };

    showAddFindingButton = (inspection) => {
      const { isLoggedUserLeadInspector, signMode } = this.props;

      return (
        this.canCreateFinding &&
        !signMode &&
        isLoggedUserLeadInspector &&
        inspection.structureType !== INSPECTION_STRUCTURE.main &&
        (inspection.status === INSPECTION_STATUSES.planned ||
          inspection.status === INSPECTION_STATUSES.draft ||
          inspection.status === INSPECTION_STATUSES.ongoing ||
          inspection.status === INSPECTION_STATUSES.finished)
      );
    };

    showSubInspectionButton = (inspection) => {
      const { isLoggedUserLeadInspector } = this.props;

      return (
        this.canCreateInspection &&
        isLoggedUserLeadInspector &&
        (inspection.status === INSPECTION_STATUSES.planned || inspection.status === INSPECTION_STATUSES.ongoing) &&
        inspection.structureType === INSPECTION_STRUCTURE.main
      );
    };

    render() {
      const {
        isLoading,
        inspection,
        match: { isExact },
        removeInspection,
        checklists,
        history,
      } = this.props;

      const isFromChecklist = Boolean(history?.location?.state?.checklist?.id);

      const {
        goToFindingDetails,
        goToFindingAdd,
        handlePublish,
        handleAddSubInspection,
        showAddFindingButton,
        showSubInspectionButton,
        goToInspectionDetails,
        goToMainInspection,
      } = this;

      const props = {
        defaultTabIndex: isFromChecklist ? 1 : 0,
        goToFindingDetails,
        goToFindingAdd,
        handlePublish,
        handleAddSubInspection,
        isExact,
        removeInspection,
        showAddFindingButton,
        showSubInspectionButton,
        goToInspectionDetails,
        goToMainInspection,
        checklistAssign: !!checklists?.length,
      };

      const inspectionDetails = new NewInspection(inspection).parseDetails();
      this.inspectionDetails = inspectionDetails;

      return (
        <>
          {(isLoading || !inspection) && <Loader mask />}
          <InspectionDetailsComponent inspectionDetails={inspectionDetails} {...this.props} {...props} />
        </>
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(InspectionDetails);
};

export default withInspectionDetails;
