import React from 'react';
import { withRouter } from 'react-router-dom';

import { FindingSeverityNonConformity, FindingSeverityObservation, RelatedItem } from 'assets/icons';
import { WORKFLOWS_TYPES, WORKFLOW_STATUS_ICON } from 'modules/app/config/config';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

const FindingPackageCell = ({ item, history }) => {
  const renderWorkflows = item.workflows?.map((workflow) => {
    const { id, type, title, mergedStatus } = workflow;
    const icon =
      type === WORKFLOWS_TYPES.nonconformity ? (
        <FindingSeverityNonConformity className="icon-min-width" />
      ) : (
        <FindingSeverityObservation className="icon-min-width" />
      );

    const goToWorkflowDetails = (e) => {
      const { currentTarget } = e;

      history.push(`/workflowDetails/${currentTarget.id}`);
    };

    return (
      <div key={id} className="row no-gutters align-items-center justify-content-between mt-3">
        <div className="col-md-6 d-flex align-items-center">
          <RelatedItem className="icon-dark icon-min-width" />
          {icon}
          <Typography
            color="textSecondary"
            variant="caption"
            className="pl-2 text-nowrap text-truncate cursor-pointer MoreActionMenu"
            onClick={goToWorkflowDetails}
            id={id}
          >
            {title}
          </Typography>
        </div>
        <div className="col-md-6 d-flex align-items-center MoreActionMenu">
          {WORKFLOW_STATUS_ICON[mergedStatus]}
          <Typography color="textSecondary" variant="caption" className="pl-2">
            {mergedStatus}
          </Typography>
        </div>
      </div>
    );
  });

  const {
    inspection: { wbs, parkName },
  } = item;

  return (
    <>
      <Typography color="textSecondary" variant="caption">
        {parkName ? `${parkName} - ${wbs}` : wbs}
      </Typography>
      {renderWorkflows}
    </>
  );
};

FindingPackageCell.propTypes = {
  item: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withRouter(FindingPackageCell);
