import React, { ReactNode, createContext, useEffect, useState } from 'react';

import { getCookie, setCookie } from 'modules/app/helpers/utils';

import { TableSettings } from './tableSettings.component';
import { ITableConfig } from './types';

export enum TableUniqueKey {
  FINDINGS = 'FINDING_TABLE_SETTINGS',
  WORKFLOWS = 'WORKFLOWS_TABLE_SETTINGS',
  REPORTS = 'REPORTS_TABLE_SETTINGS',
  ADD_FINDINGS_TO_WORKFLOW = 'ADD_FINDINGS_TO_WORKFLOW_TABLE_SETTINGS',
  USERS = 'USERS_TABLE_SETTINGS',
  USER_DETAILS = 'USER_DETAILS_TABLE_SETTINGS',
  PARTICIPANTS = 'PARTICIPANTS_TABLE_SETTINGS',
  INSPECTION_DETAILS = 'INSPECTION_DETAILS_TABLE_SETTINGS',
  DASHBOARD_WORK_PACKAGES = 'DASHBOARD_WORK_PACKAGES_TABLE_SETTINGS',
  DASHBOARD_SUPPLIERS = 'DASHBOARD_SUPPLIERS_TABLE_SETTINGS',
  DASHBOARD_OBSERVATIONS = 'DASHBOARD_OBSERVATIONS_TABLE_SETTINGS',
  DASHBOARD_NON_CONFORMITIES = 'DASHBOARD_NON_CONFORMITIES_TABLE_SETTINGS',
  DASHBOARD_FINDINGS = 'DASHBOARD_FINDINGS_TABLE_SETTINGS',
  RELATED_FINDINGS = 'RELATED_FINDINGS_TABLE_SETTINGS',
  CHECKLISTS = 'CHECKLISTS_TABLE_SETTINGS',
}

export interface IShowColumnConfig {
  dataField: string;
  showed: boolean;
}

interface ITableContext {
  columnsConfig: IShowColumnConfig[];
  setColumnsSettings: (columns: IShowColumnConfig[]) => void;
  resetColumnsSettings: () => void;
  handleOpenSettings: () => void;
}

export const TableContext = createContext<ITableContext>({
  columnsConfig: [],
  setColumnsSettings: () => {},
  resetColumnsSettings: () => {},
  handleOpenSettings: () => {},
});

interface ITableContextProviderProps {
  tableUniqueKey: TableUniqueKey;
  children: ReactNode;
  config: ITableConfig;
  skipCells: string[];
}

export const TableContextProvider = ({
  children,
  tableUniqueKey,
  config,
  skipCells = [],
}: ITableContextProviderProps) => {
  const [columnsConfig, setColumnsConfig] = useState<IShowColumnConfig[]>([]);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const handleOpenSettings = () => {
    setSettingsOpen(true);
  };

  const handleCloseSettings = () => {
    setSettingsOpen(false);
  };

  useEffect(() => {
    const initialValues: IShowColumnConfig[] = JSON.parse(getCookie(tableUniqueKey));

    if (initialValues) {
      setColumnsConfig(initialValues);
    }
    if (!initialValues) {
      const initConfig = config.columns.map((column) => ({
        dataField: column.dataField,
        showed: true,
      }));
      setColumnsConfig(initConfig);
      setCookie(tableUniqueKey, JSON.stringify(initConfig), 1);
    }
  }, [config.columns, tableUniqueKey]);

  const setColumnsSettings = (columns: IShowColumnConfig[]) => {
    setColumnsConfig(columns);
    setCookie(tableUniqueKey, JSON.stringify(columns), 1);
  };

  const resetColumnsSettings = () => {
    const initConfig = config.columns.map((column) => ({
      dataField: column.dataField,
      showed: true,
    }));
    setColumnsConfig(initConfig);
    setCookie(tableUniqueKey, JSON.stringify(initConfig), 1);
  };

  return (
    <TableContext.Provider value={{ columnsConfig, setColumnsSettings, resetColumnsSettings, handleOpenSettings }}>
      <TableSettings
        isOpen={settingsOpen}
        handleCloseSettings={handleCloseSettings}
        tableUniqueKey={tableUniqueKey}
        config={config}
        skipCells={skipCells}
        resetColumnsSettings={resetColumnsSettings}
        columnsConfig={columnsConfig}
        setColumnsSettings={setColumnsSettings}
      />
      {children}
    </TableContext.Provider>
  );
};
