import React from 'react';

const UserRoleOverride = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 3c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm0 14.2c-2.5 0-4.7-1.3-6-3.2 0-2 4-3.1 6-3.1s6 1.1 6 3.1c-1.3 1.9-3.5 3.2-6 3.2z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default UserRoleOverride;
