import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DashboardFilters from '../filters/filters.component';
import { getDashboardFilters, getDashboardChartData, getDashboardTableData } from './dashboard.actions';
import Dashboard from './dashboard.component';

const DashboardHOC = (Base) => (props) => {
  const dispatch = useDispatch();
  const [filtersState, setFilterState] = useState({
    park: {},
    siteFacility: {},
    supplier: {},
  });

  const stateFilters = useSelector((state) => state.dashboard.filters.data);

  useEffect(() => {
    dispatch(getDashboardFilters());
    dispatch(getDashboardChartData());
    dispatch(getDashboardTableData());
  }, []);

  useEffect(() => {
    if (filtersState.park.id && filtersState.park.id !== 0) {
      dispatch(
        getDashboardChartData({
          wbsId: filtersState.park.id,
          siteFacilityId: [filtersState.supplier.id, filtersState.siteFacility.id],
        }),
      );
      dispatch(
        getDashboardTableData({
          wbsId: filtersState.park.id,
          siteFacilityId: [filtersState.supplier.id, filtersState.siteFacility.id],
        }),
      );
    }
  }, [filtersState.park.id, filtersState.supplier.id, filtersState.siteFacility.id]);

  const setFilters = (filter) => (_, value) => {
    const newFilters = {
      ...filtersState,
    };
    if (filter === 'all') {
      setFilterState(value);
      dispatch(getDashboardFilters());
      dispatch(getDashboardChartData());
      dispatch(getDashboardTableData());
      return;
    }
    if (filter === 'park' && value.id !== filtersState.park.id) {
      newFilters.supplier = {};
      newFilters.siteFacility = {};
    }
    if (filter === 'supplier' && value.id !== filtersState.supplier.id) {
      newFilters.siteFacility = {};
    }
    newFilters[filter] = value;
    setFilterState(newFilters);
  };

  const parent = stateFilters.find((opt) => {
    if (filtersState.park.parents && filtersState.park.parents.length > 0) {
      return opt.id === filtersState.park.parents[0];
    }
    return false;
  });

  return (
    <div className="mt-3 dashboard">
      <div className="mb-3 dashboard__header">
        {Object.keys(filtersState.park).length === 0 && (
          <>
            <span>ALL </span>
            Parks
          </>
        )}
        {Object.keys(filtersState.park).length > 0 && parent && (
          <>
            <span>{parent.name || parent.description}</span>
            {' - '}
            {filtersState.park.description || filtersState.park.name}
          </>
        )}
        {Object.keys(filtersState.park).length > 0 && !parent && (
          <>
            <span>{filtersState.park.description || filtersState.park.name}</span>
          </>
        )}
      </div>
      <DashboardFilters setFilters={setFilters} filters={filtersState} />
      <Base {...props} filters={filtersState} />
    </div>
  );
};

export default DashboardHOC(Dashboard);
