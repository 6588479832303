import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Formik } from 'formik';
import { createCrumb } from 'modules/app/components/app/app.actions';
import { CHECKLIST_STATUSES } from 'modules/app/config/config';
import SaveHeaderWeb from 'modules/common/components/saveHeader/saveHeaderWeb.component';
import { getMainSystems } from 'modules/inspections/components/newInspection/newInspection.actions';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Icon, IconButton } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';

import ChecklistDataForm from './checklistDataForm.component';
import ChecklistDataViewer from './checklistDataViewer.component';
import {
  addChecklist,
  clearChecklistToEdit,
  editChecklist,
  getChecklistToEdit,
  toggleChecklistDuplicateModal,
} from './checklistEditor.actions';
import ChecklistEditor from './checklistEditor.component';
import ChecklistsDuplicateModal from './checklistsDuplicateModal.component';

const requiredText = 'This field is required';
const validationSchema = Yup.object().shape({
  description: Yup.string().min(2).max(100).required(requiredText),
  parkId: Yup.string().required(requiredText),
});

const DuplicateButton = ({ onClick }) => (
  <IconButton onClick={onClick} className="ml-2">
    <Icon className="svg-medium">
      <FileCopyOutlined className="icon-dark" />
    </Icon>
  </IconButton>
);

DuplicateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const NewChecklist = ({ match }) => {
  const { id: checklistId } = match.params;
  const { isLoading, currentChecklistDetails: currentChecklist } = useSelector((state) => state.checklistEditor);

  const dispatch = useDispatch();
  const history = useHistory();

  const presetParkId = history?.location?.state?.currentWbs || null;

  const initialFormValues = {
    park: currentChecklist?.park || {},
    parkId: currentChecklist?.park?.id || presetParkId || '',
    mainSystem: currentChecklist?.mainSystem || {},
    mainSystemId: currentChecklist?.mainSystem?.id || '',
    description: currentChecklist?.description || '',
  };

  useEffect(() => {
    if (checklistId) {
      dispatch(getChecklistToEdit(checklistId));
    } else {
      dispatch(clearChecklistToEdit());
    }
  }, [checklistId, dispatch]);

  useEffect(() => {
    dispatch(createCrumb(match.path, match.params));
  }, [currentChecklist, dispatch, match.params, match.path]);

  const onSubmit = async (values, { resetForm }) => {
    try {
      if (checklistId) {
        await dispatch(editChecklist(values, checklistId));
        history.replace(`/admin/checklists/${checklistId}`);
      } else {
        const newChecklist = await dispatch(addChecklist(values));
        history.replace(`/admin/checklists/${newChecklist.id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openDuplicateModal = () => {
    dispatch(toggleChecklistDuplicateModal());
  };

  const onClose = () => {
    dispatch(clearChecklistToEdit());
    history.goBack();
  };

  const isViewMode = Boolean(currentChecklist?.id && currentChecklist?.status !== CHECKLIST_STATUSES.draft);
  const title = checklistId ? 'Edit checklist' : 'Add checklist';
  const titleViewMode = isLoading || !currentChecklist?.description ? 'Checklist' : currentChecklist.description;

  const addIconButton = !isLoading ? <DuplicateButton onClick={openDuplicateModal} /> : null;

  return (
    <>
      <ChecklistsDuplicateModal />
      <div id="new-checklist" className="mt-3 mb-4 p-3 shadow rounded">
        {(isLoading || (!isLoading && isViewMode)) && (
          <>
            <SaveHeaderWeb
              title={titleViewMode}
              addIconButton={addIconButton}
              withSave={false}
              onClose={onClose}
              isLoading={isLoading}
            />
            <div className="content-wrapper row no-gutters p-0">
              <ChecklistDataViewer checklist={currentChecklist} isLoading={isLoading} />
            </div>
          </>
        )}
        {!isLoading && !isViewMode && (
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, dirty }) => (
              <>
                <SaveHeaderWeb
                  title={title}
                  onSave={handleSubmit}
                  disabled={!dirty}
                  withSave
                  onClose={onClose}
                  isLoading={isLoading}
                />
                <div className="content-wrapper row no-gutters p-0">
                  <ChecklistDataForm
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    isSubmitting={isSubmitting}
                    getMainSystems={getMainSystems}
                    isElements={Boolean(currentChecklist?.checkListGroupDefinitions?.length)}
                  />
                </div>
              </>
            )}
          </Formik>
        )}
        {!isLoading && checklistId && <ChecklistEditor viewMode={isViewMode} />}
      </div>
    </>
  );
};

NewChecklist.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default NewChecklist;
