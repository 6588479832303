import React, { FC, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { bufferToBlob } from 'modules//app/helpers/utils';
import { useOnScreen } from 'modules/app/helpers/hooks';
import Api from 'services/api';

import { Typography } from '@material-ui/core';

import { ROTATIONS } from '../attachmentPreview/usePrevNextPreview';
import Loader from '../loader/loader.component';

interface IImageProps {
  src: string | ArrayBuffer | Blob;
  alt?: string;
  mimeType: string;
  className?: string;
  refresh?: boolean;
  viewRotation?: number;
}

const Image: FC<IImageProps> = ({ src, mimeType, alt = '', className = '', refresh = false, viewRotation = 0 }) => {
  const controller = new AbortController();
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const [state, setState] = useState({
    src: '',
    isLoading: false,
    error: false,
  });

  const parseSrc = async () => {
    if (src instanceof ArrayBuffer) {
      const blob = bufferToBlob(src, mimeType);

      return URL.createObjectURL(blob);
    }

    if (src instanceof Blob) {
      return URL.createObjectURL(src);
    }

    if (src.includes('/api/attachments')) {
      if (!navigator.onLine) {
        return null;
      }

      const blob = await Api.get(src, null, 'blob', controller.signal);
      return URL.createObjectURL(blob);
    }

    return `data:${mimeType};base64,${src}`;
  };

  useEffect(() => {
    if (isVisible && !state.isLoading && (!state.src || refresh)) {
      setState({
        ...state,
        error: false,
        isLoading: true,
      });

      (async () => {
        try {
          const fetchSrc = await parseSrc();
          setState({
            ...state,
            error: false,
            isLoading: false,
            src: fetchSrc,
          });
        } catch (err) {
          setState({ ...state, error: true, isLoading: false });
        }
      })();
    }

    return () => {
      controller.abort();
    };
  }, [isVisible, refresh]);

  const handleError = () => {
    setState((state) => ({ ...state, src: '', error: true, isLoading: false }));
  };

  const classes = classNames(
    {
      'hidden-elem': state.isLoading || state.error,
      'rotate-90': viewRotation === ROTATIONS.right,
      'rotate-180': viewRotation === ROTATIONS.upside,
      'rotate-270': viewRotation === ROTATIONS.left,
    },
    className,
  );

  const { src: source, error, isLoading } = state;

  return (
    <div ref={ref}>
      {source && <img src={source} onError={handleError} alt="" className={classes} />}
      {isLoading && !error && (
        <div>
          <Loader />
        </div>
      )}
      {!isLoading && error && <Typography color="primary">No content available</Typography>}
    </div>
  );
};

export default Image;
