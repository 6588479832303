import React from 'react';

import { Tooltip } from '@material-ui/core';

const HelperTooltip = ({ children, icon, title }) => (
  <div className="d-flex flex-row align-items-center">
    {children}
    <Tooltip title={title} aria-label="show">
      <div className="pl-2">{icon}</div>
    </Tooltip>
  </div>
);

export default HelperTooltip;
