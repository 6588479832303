import React, { PureComponent } from 'react';

import { DATE_FORMAT } from 'modules/app/config/config';
import DatepickerWrapper from 'modules/common/components/datepickerWrapper/datepickerWrapper.component';
import PropTypes from 'prop-types';

import { FormControl, TextField } from '@material-ui/core';

const NOT_PAST_DATE = 'NOT_PAST_DATE';

class DynamicFormInput extends PureComponent {
  constructor({ value }) {
    super();

    this.state = { value };
  }

  componentDidUpdate({ reset: prevReset }) {
    const { reset, value } = this.props;

    if (reset !== prevReset) {
      this.setState({
        value,
      });
    }
  }

  onHandleChange = (ev) => {
    const {
      currentTarget: { name, value },
    } = ev;

    this.changeFieldValue(name, value);
  };

  onSetFieldValue = (name, value) => {
    this.changeFieldValue(name, value);
  };

  changeFieldValue = (name, value) => {
    const { changeFieldValue } = this.props;

    changeFieldValue(name, value.trim());
    this.setState({
      value,
    });
  };

  render() {
    const { config, errors } = this.props;
    const { value } = this.state;
    const { disabled = false, id, label, uiControlType, validationRules } = config;
    let item = null;

    switch (uiControlType) {
      case 'TEXTAREA':
        item = (
          <FormControl className="col-md-8 mt-4 pr-md-3">
            <TextField
              disabled={disabled}
              label={label}
              id={id}
              name={id}
              multiline
              value={value || ''}
              onChange={this.onHandleChange}
              error={errors && !!errors[id]}
              helperText={errors && errors[id]}
            />
          </FormControl>
        );
        break;

      case 'DATE': {
        const disablePast = validationRules.indexOf(NOT_PAST_DATE) > -1;
        item = (
          <FormControl className="col-md-4 mt-4 pr-md-3">
            <DatepickerWrapper
              disabled={disabled}
              label={label}
              format={DATE_FORMAT}
              value={value || null}
              onChange={this.onSetFieldValue}
              name={id}
              disablePast={disablePast}
              animateYearScrolling={false}
              error={errors && !!errors[id]}
              helperText={errors && errors[id]}
            />
          </FormControl>
        );
        break;
      }

      default:
        item = (
          <FormControl className="col-md-4 mt-4 pr-md-3">
            <TextField
              label={label}
              id={id}
              name={id}
              value={value || ''}
              onChange={this.onHandleChange}
              error={errors && !!errors[id]}
              helperText={errors && errors[id]}
            />
          </FormControl>
        );
    }

    return item;
  }
}

DynamicFormInput.propTypes = {
  config: PropTypes.objectOf(PropTypes.shape).isRequired,
  errors: PropTypes.objectOf(PropTypes.shape).isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  value: PropTypes.string,
  reset: PropTypes.string,
};

DynamicFormInput.defaultProps = {
  value: null,
  reset: null,
};

export default DynamicFormInput;
