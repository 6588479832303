import React, { forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import find from 'lodash/find';
import _get from 'lodash/get';
import { USER_ROLES } from 'modules/app/config/config';

import RoleRow from './roleRow.component';
import { addUser, deactivateUser, setMainUser } from './wbsDetails.actions';

export const ROLES = USER_ROLES.filter((role) => role.key !== 'CONTRACTOR').reduce(
  (acc, curr) => ({ ...acc, [curr.key]: curr.label }),
  {},
);

const PermissionsTabRows = forwardRef(({ confirmDelete, data, history }, ref) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.wbsDetails.isLoading);
  const roles = useSelector((state) => state.auth.rolesDetails);
  const loggedUser = useSelector((state) => state.auth.user);

  const handleSelect = (item, extraData) => {
    if (!isLoading) {
      dispatch(addUser(item, { userRole: extraData }));
    }
  };

  const handleSelectMain = (item, extraData) => {
    if (!isLoading) {
      dispatch(setMainUser(item, { userRole: extraData }));
    }
  };

  const onDelete = (item) => {
    confirmDelete(item, ROLES[item.role]);
  };

  useImperativeHandle(ref, () => ({
    handleDelete(clickedItem) {
      dispatch(deactivateUser(clickedItem, { userRole: clickedItem.role }));

      if (clickedItem.role === 'ADMINISTRATOR' && clickedItem.id === loggedUser.id) {
        history.push('/admin/wbses', { reload: true });
      }
    },
  }));

  return (
    <>
      {Object.keys(ROLES).map((role) => {
        const usersInRole = _get(data, `roles[${role}]`, []);
        const isMainRequired = !!(find(roles, { name: role }) || {}).mainUserRequired;
        return (
          <RoleRow
            key={role}
            role={role}
            data={usersInRole}
            title={ROLES[role]}
            confirmDelete={onDelete}
            handleSelect={handleSelect}
            handleSelectMain={handleSelectMain}
            isMainRequired={isMainRequired}
          />
        );
      })}
    </>
  );
});

export default PermissionsTabRows;
