import React, { memo } from 'react';

import classNames from 'classnames';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  link: {
    color: theme.palette.secondary.light,
  },
  disabledLink: {
    color: 'grey',
  },
});

const LinkComponent = memo(({ title, goToFunc, classes, className, disabled = false }) => (
  <Typography
    className={classNames(className, {
      'cursor-pointer': !disabled,
      [classes.link]: !disabled,
      [classes.disabledLink]: disabled,
    })}
    onClick={goToFunc}
  >
    {title}
  </Typography>
));

export default withStyles(styles)(LinkComponent);
