import React, { FC, MouseEvent, memo } from 'react';

import { TableCell } from '@material-ui/core';

import { useTableStyles } from './styles';

interface ITableBodyCellProps {
  children: React.ReactNode;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  padding?: 'default' | 'checkbox' | 'none';
  onClick?: (e: MouseEvent) => void;
}

const TableBodyCell: FC<ITableBodyCellProps> = memo(({ align = 'center', children, padding = 'default', onClick }) => {
  const classes = useTableStyles();
  return (
    <TableCell onClick={onClick} align={align} padding={padding} className={classes.cell}>
      {children}
    </TableCell>
  );
});

export default TableBodyCell;
