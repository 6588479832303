import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProps, useHistory, useRouteMatch } from 'react-router-dom';

import { AvatarOnshore, AvatarOffshore, GlobalActionClose } from 'assets/icons/index';
import { usePrevious } from 'hooks';
import { createCrumb } from 'modules/app/components/app/app.actions';
import { GUTTER_SIZE, INSPECTION_STATUSES } from 'modules/app/config/config';
import { divideWithFilter } from 'modules/app/helpers/utils';
import ChipWrapper from 'modules/common/components/chipWrapper/chipWrapper.component';
import ConfirmModal from 'modules/common/components/confirmModal/confirmModal';
import { clearData } from 'modules/common/components/filters/global.actions';
import FloatingElement from 'modules/common/components/floatingElement/floatingElement.container';
import Loader from 'modules/common/components/loader/loader.component';
import withTabs from 'modules/common/hoc/withTabs/withTabs.hoc';
import { clearSelectedFindings } from 'modules/findings/components/findings/findings.actions';
import { FINDING_TYPES } from 'modules/findings/components/newFinding/newFinding.model';
import {
  getChecklistsDetails,
  clearData as clearChecklist,
} from 'modules/inspections/components/checklists/checklist.actions';
import Checklist from 'modules/inspections/components/checklists/checklist.component';
import FindingsListMobile from 'modules/inspections/components/inspectionDetails/findingsListMobile.component';
import {
  getInspection,
  setInspectionStatus,
} from 'modules/inspections/components/inspectionDetails/inspectionDetails.actions';
import { makeIsLoggedUserLeadInspector } from 'modules/inspections/components/inspectionDetails/inspectionDetails.selectors';
import InspectionItem from 'modules/inspections/components/inspectionItem/inspectionItem.component';
import InspectorInfo from 'modules/inspections/components/inspectorInfo/inspectorInfo.component';
import NewInspection, { INSPECTION_STRUCTURE } from 'modules/inspections/components/newInspection/newInspection.model';
import Sign from 'modules/inspections/components/sign/sign.container';
import Acl, { PRIVILEGE_KEYS } from 'services/acl';

import {
  Avatar,
  Box,
  Button,
  Fab,
  Card,
  CardContent,
  Dialog,
  Icon,
  IconButton,
  Toolbar,
  Typography,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Loop } from '@material-ui/icons';

const styles = (theme) => ({
  publishButton: {
    bottom: GUTTER_SIZE,
    right: GUTTER_SIZE, // NOTE: change 'right' to 'left' to activate ADD TODO button
  },
  addButton: {
    backgroundColor: theme.palette.secondary.light,
    bottom: GUTTER_SIZE,
    right: GUTTER_SIZE,
  },
});

interface TodosHeaderMobileProps {
  onClose: (e: SyntheticEvent) => void;
  title: string;
}

const TodosHeaderMobile = ({ onClose, title }: TodosHeaderMobileProps) => (
  <Toolbar className="todo--overlay-close pr-0">
    <Typography variant="h6">{title}</Typography>
    <IconButton onClick={onClose}>
      <Icon>
        <GlobalActionClose className="icon-dark" />
      </Icon>
    </IconButton>
  </Toolbar>
);

interface MatchProps {
  params: { id: string };
  path: string;
}

interface InspectionDetailsProps {
  classes: any;
  renderTabs: () => void;
  tabIndex: number;
}

const isLoggedUserLeadInspectorFn = makeIsLoggedUserLeadInspector();

const InspectionDetails = ({ classes, renderTabs, tabIndex }: InspectionDetailsProps) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const historyLocationState: { checklist?: { id?: string } } = history.location.state || {};

  const { params, path }: MatchProps = useRouteMatch();
  const prevId = usePrevious(params.id);
  const currId = params.id;

  const canShowFindingDetails = Acl.hasPrivilege(PRIVILEGE_KEYS.FINDINGS_DETAILS);
  const canCreateFinding = Acl.hasPrivilege(PRIVILEGE_KEYS.FINDINGS_CREATE);

  const [open, setOpen] = useState(false);

  const state = useSelector((state: IStore) => state);
  const { data: inspection, isLoading, signMode } = state.inspection;
  const { isMobile, showSyncIndicator } = state.app;
  const checklists = state.checklists.currentChecklistDetails.checklist;

  const isLoggedUserLeadInspector = isLoggedUserLeadInspectorFn(state);
  const inspectionDetails = new NewInspection(inspection).parseDetails();

  const getInspectionById = async (id) => {
    const inspection = await dispatch(getInspection(id));
    if (!inspection) return;

    dispatch(getChecklistsDetails(inspection));
  };

  const handlePublish = () => {
    dispatch(
      setInspectionStatus(isMobile ? inspectionDetails.localId : inspectionDetails.id, INSPECTION_STATUSES.planned),
    );
  };

  const showAddFindingButton = (inspection) =>
    canCreateFinding &&
    !signMode &&
    isLoggedUserLeadInspector &&
    inspection.structureType !== INSPECTION_STRUCTURE.main &&
    (inspection.status === INSPECTION_STATUSES.planned ||
      inspection.status === INSPECTION_STATUSES.draft ||
      inspection.status === INSPECTION_STATUSES.ongoing ||
      inspection.status === INSPECTION_STATUSES.finished);

  useEffect(() => {
    if (prevId !== currId) {
      getInspectionById(currId);
    }

    createCrumb(path, params);

    return () => {
      dispatch(clearSelectedFindings());
      dispatch(clearData());
      dispatch(clearChecklist());
    };
  }, [prevId, currId]);

  useEffect(() => {
    if (inspectionDetails?.checkListDefinitionId) getChecklistsDetails(inspectionDetails);
  }, [inspectionDetails]);

  useEffect(() => {
    if (inspectionDetails.localId && !inspectionDetails.checkedOutForMobile) {
      history.push('/');
    }
  }, [inspectionDetails.checkedOutForMobile, inspectionDetails.localId, history]);

  useEffect(() => {
    if ([INSPECTION_STATUSES.finished].includes(inspectionDetails.status)) {
      history.push('/');
    }
  }, [history, inspectionDetails.status]);

  // for TABS
  const [findings, todos] = divideWithFilter(
    inspectionDetails.findings,
    (f) => f.findingType !== FINDING_TYPES.TODO.value,
  );

  const inspectionIsDraft = inspectionDetails.status === INSPECTION_STATUSES.draft;
  const canCreateFindingMobile = showAddFindingButton(inspectionDetails) && !inspectionIsDraft;

  const goToFindingDetails = (e) => {
    if (!canShowFindingDetails) {
      return;
    }

    const {
      currentTarget: { id },
    } = e;

    history.push(`/inspectionDetails/${params.id}/findingDetails/${id}`);
  };

  const goToFindingAdd = (_, checklist = {}) => {
    history.push(`/inspectionDetails/${params.id}/newFinding`, {
      checklist,
    });
  };

  const TABS = [
    {
      id: 'findings',
      label: 'Findings',
      renderComponent: (): ReactElement => (
        <FindingsListMobile findings={findings} goToFindingDetails={goToFindingDetails} />
      ),
    },
    {
      id: 'checklist',
      label: 'Checklist',
      disabled: !checklists?.length,
      renderComponent: (): ReactElement => (
        <Checklist
          canModify={canCreateFindingMobile}
          goToFindingAdd={goToFindingAdd}
          scrolledId={historyLocationState?.checklist?.id}
        />
      ),
    },
  ];

  // for main return
  const toggleOpenTodos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
  };

  const isMainInspection = inspectionDetails.structureType === INSPECTION_STRUCTURE.main;

  if (isLoading || !inspection) return <Loader mask />;

  return (
    <Grid container className="inspection-details-component">
      <Grid container direction="column">
        <Card id="finding-details:div" className="inspection-card">
          {showSyncIndicator && <Loop className="sync-indicator" />}
          <CardContent className="row no-gutters flex-nowrap">
            <Avatar className="avatar">
              <Icon className="svg-extra-large">
                {inspectionDetails.parkType === 'ONSHORE' && <AvatarOnshore />}
                {inspectionDetails.parkType !== 'ONSHORE' && <AvatarOffshore />}
              </Icon>
            </Avatar>
            <Box className="min-width-0">
              <Typography className="title break-word" color="textPrimary">
                {inspectionDetails.title}
              </Typography>
              <Typography color="textSecondary" className="break-word">
                {inspectionDetails.wbs}
              </Typography>
            </Box>
          </CardContent>
          <CardContent className="row no-gutters align-items-center properties py-0">
            {inspectionDetails.mainSystem && (
              <Box>
                <Typography className="label" color="textSecondary" variant="body1">
                  Main System
                </Typography>
                <Typography className="value" color="textPrimary">
                  {inspectionDetails.mainSystem}
                </Typography>
              </Box>
            )}

            {inspectionDetails.subSystem && (
              <Box>
                <Typography className="label" color="textSecondary" variant="body1">
                  Sub System
                </Typography>
                <Typography className="value" color="textPrimary">
                  {inspectionDetails.subSystem}
                </Typography>
              </Box>
            )}

            {inspectionDetails.siteFacility && (
              <Box>
                <Typography className="label" color="textSecondary" variant="body1">
                  Site/Facility
                </Typography>
                <Typography className="value" color="textPrimary">
                  {inspectionDetails.siteFacility}
                </Typography>
              </Box>
            )}
          </CardContent>
          {!signMode && (
            <CardContent className="row no-gutters align-items-center inspectors">
              {inspectionDetails.inspectors?.map((inspector) => (
                <InspectorInfo key={inspector.id} data={inspector}>
                  <ChipWrapper label={inspector.fullName} className="default-chip" item={inspector} />
                </InspectorInfo>
              ))}
            </CardContent>
          )}
          <CardContent>
            <Grid container className="justify-content-start align-items-center">
              <Typography color="textSecondary">{inspectionDetails.identifier}</Typography>
            </Grid>
          </CardContent>
        </Card>

        {Boolean(todos.length) && (
          <>
            <Box className="todo--overlay-floating-toggler row mr-3">
              <Button variant="contained" className="todo--button" onClick={toggleOpenTodos}>
                Todos ({todos.length})
              </Button>
            </Box>
            <Dialog open={open} onClose={toggleOpenTodos}>
              <TodosHeaderMobile title="Todo list" onClose={toggleOpenTodos} />
              <FindingsListMobile className="mt-5" findings={todos} goToFindingDetails={goToFindingDetails} todos />
            </Dialog>
          </>
        )}

        {!(signMode || isMainInspection) && (
          <>
            {/*  @ts-ignore */}
            {renderTabs(TABS)}
            {TABS[tabIndex].renderComponent()}
          </>
        )}
        {signMode && <Sign inspection={inspectionDetails} inspectors={inspectionDetails.inspectors} />}
        {inspectionDetails.partOfPlanInspections.length > 0 && (
          <Grid container className="flex-column flex-wrap justify-content-between">
            <Typography color="textSecondary" variant="body2">
              Sub Inspections
            </Typography>
            <Grid container className="flex-column flex-wrap justify-content-between ">
              {inspectionDetails.partOfPlanInspections?.map((item, index) => {
                const inspectionModel = new NewInspection(item).parseDetails();
                return (
                  <InspectionItem
                    className={`w-100 ${index === 0 ? 'mt-1' : 'mt-2'}`}
                    key={inspectionModel.id}
                    inspection={inspectionModel}
                    index={index}
                    idKey="id"
                  />
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
      {showAddFindingButton(inspectionDetails) &&
        inspectionDetails.status !== INSPECTION_STATUSES.draft && // NOTE: remove the line to activate ADD TODO button
        tabIndex === 0 && (
          <FloatingElement
            render={(floatingClasses) => (
              <Fab
                id="add-finding:button"
                size="medium"
                variant="extended"
                color="secondary"
                puppet-data="add-finding:mobile"
                className={`position-fixed ${classes.addButton} ${floatingClasses}`}
                onClick={goToFindingAdd}
              >
                <Icon className="svg-medium">add</Icon>
                {inspectionDetails.status === INSPECTION_STATUSES.draft ? 'TODO' : 'Finding'}
              </Fab>
            )}
          />
        )}
      {inspectionDetails.status === INSPECTION_STATUSES.draft && (
        <FloatingElement
          render={(floatingClasses) => (
            <ConfirmModal confirmFn={handlePublish} confirmTitle="Are you sure you want to publish inspection?">
              <Fab
                id="publish-inspection:button"
                size="medium"
                variant="extended"
                puppet-data="publish-inspection:mobile"
                color="primary"
                className={`position-fixed ${floatingClasses} ${classes.publishButton}`}
              >
                Publish Inspection
              </Fab>
            </ConfirmModal>
          )}
        />
      )}
    </Grid>
  );
};

export default withTabs(withStyles(styles)(InspectionDetails));
