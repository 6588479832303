import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { debounce } from 'lodash';
import { DEFAULT_PAGINATION_PAGE_SIZE, FILTER_COOKIE_NAMES, INSPECTION_STATUSES } from 'modules/app/config/config';
import {
  compareObjectStructure,
  decodeParam,
  encodeParam,
  eraseCookie,
  getCookie,
  setCookie,
} from 'modules/app/helpers/utils';
import Filters from 'modules/common/components/filters';
import { setSimilarFiltersFrom } from 'modules/common/components/filters/global.actions';
import Table from 'modules/common/components/table';
import { TableUniqueKey } from 'modules/common/components/table/table.context';
import CreateWorkflowFromSelected from 'modules/findings/components/findings/createWorkflowFromSelected.component';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

import { TABLE_CONFIG } from './findings.config';

const Findings = ({
  match: { params, path },
  history,
  setSelectedFindings,
  clearSelectedFindings,
  findings,
  isLoading,
  totalCount,
  getFindings,
  selectFinding,
  createCrumb,
  selectedIds,
}) => {
  const dispatch = useDispatch();
  const filterSetFrom = useSelector((state) => state.filtersConfig.similarFiltersFrom);

  const [state, setState] = useState({
    selectedFindings: {},
  });

  const [additionalFilters, setAdditionalFilters] = useState({});

  const emptyFilters = {
    search: '',
    filters: {
      mainSystem: [],
      assigned: [],
      failureMode: [],
      inspector: [],
      status: [],
      subSystem: [],
      wbs: [],
    },
    sort: { sortBy: 'lastActivityDate', sortDirection: 'desc' },
  };

  const initialValues = JSON.parse(getCookie(FILTER_COOKIE_NAMES.FINDINGS));

  const filterStructureCheck = compareObjectStructure(emptyFilters, initialValues);

  const [filterState, setFilterState] = useState({
    filters: filterStructureCheck ? initialValues : emptyFilters,
    size: DEFAULT_PAGINATION_PAGE_SIZE,
    page: 0,
  });

  useEffect(() => {
    createCrumb(path, params);

    if (params.selectedIds) {
      const ids = decodeParam(params.selectedIds);
      setSelectedFindings(ids);
    }

    return () => {
      clearSelectedFindings();
      dispatch(setSimilarFiltersFrom({}));
    };
  }, []);

  useEffect(() => {
    if (filterSetFrom.item && filterSetFrom.item.id) {
      const filters = {};

      if (filterSetFrom.item.failureMode) {
        filters.failureMode = [filterSetFrom.item.failureMode.id];
      }
      if (filterSetFrom.item.subSystem) {
        filters.subSystem = [filterSetFrom.item.subSystem.id];
      }

      setFilterState({
        size: DEFAULT_PAGINATION_PAGE_SIZE,
        page: 0,
        filters: {
          filters,
          sort: { sortBy: 'lastActivityDate', sortDirection: 'desc' },
        },
      });
      setAdditionalFilters(filters);
    }
  }, [filterSetFrom]);

  useEffect(() => {
    const { page, size, filters } = filterState;
    getFindings(
      {
        sort: `${filters.sort.sortBy},${filters.sort.sortDirection.toUpperCase()}`,
        page,
        size,
      },
      filters,
    );
  }, [filterState]);

  const onRowClick = (e, item) => {
    const { type } = params;
    const {
      target: { tagName },
    } = e;

    if (tagName === 'SPAN') {
      history.push(`/inspectionDetails/${item.inspection.id}/findingDetails/${item.id}`, {
        prevPath: `/findings/${type}/${encodeParam(selectedIds)}`,
      });
    }
  };

  const selectRelatedFindings = (findingId) => {
    if (state.selectedFindings[findingId]) {
      const newState = { ...state };
      delete newState.selectedFindings[findingId];
      setState(newState);
    } else {
      const finding = findings.find((item) => item.id === findingId);
      setState({
        ...state,
        selectedFindings: {
          ...state.selectedFindings,
          [findingId]: finding,
        },
      });
    }

    selectFinding(findingId);
  };

  const handleClearSelected = () => {
    setState({
      selectedFindings: {},
    });
    clearSelectedFindings();
  };

  const getFindingsForWorkflows = () => {
    const findingsForWorkflows = Object.values(state.selectedFindings);
    if (Array.isArray(selectedIds) && !findingsForWorkflows.length) {
      const selectedFinding = findings.find((item) => selectedIds?.includes?.(item.id));

      return selectedFinding ? [selectedFinding] : [];
    }
    return findingsForWorkflows;
  };

  const findingsForWorkflows = getFindingsForWorkflows();

  const setPagination = (page, size) => {
    setFilterState({
      ...filterState,
      page,
      size,
    });
  };

  const handleFiltersChange = (newFilterState, sortOnly) => {
    const filtersHaveChanged = JSON.stringify(filterState.filters) === JSON.stringify(newFilterState);

    if (!filtersHaveChanged) {
      setCookie(FILTER_COOKIE_NAMES.FINDINGS, JSON.stringify(newFilterState), 1);

      const newState = {
        ...filterState,
        filters: newFilterState,
      };

      if (!sortOnly) {
        newState.page = 0;
      }

      setFilterState(newState);
    }
  };

  const debounced = debounce(handleFiltersChange, 250, {
    maxWait: 1300,
  });

  const onClearAll = () => {
    if (filterSetFrom && filterSetFrom.item.id) {
      dispatch(setSimilarFiltersFrom({}));
    }
    eraseCookie(FILTER_COOKIE_NAMES.FINDINGS);
    setFilterState({
      filters: { ...emptyFilters, search: filterState.filters.search },
      size: filterState.size,
      page: filterState.page,
    });
  };

  const filtersConfig = {
    name: FILTER_COOKIE_NAMES.FINDINGS,
    initialFilters: filterState.filters,
    filters: {
      wbs: {
        caption: 'WBS',
        primaryText: 'description',
        secondaryText: 'name',
        onChangeClear: ['mainSystem'],
        singleChoice: true,
      },
      mainSystem: {
        caption: 'Main System',
        primaryText: 'description',
        secondaryText: 'mainSystemCode',
        dependsOn: 'wbs',
        disabledTooltip: 'Select WBS first',
        dependentFilter: 'parkCodes',
        dependentFilterKey: 'parkCode',
      },
      failureMode: {
        caption: 'Failure Mode',
        primaryText: 'description',
        secondaryText: 'code',
      },
      subSystem: {
        caption: 'Sub System',
        primaryText: 'description',
        secondaryText: 'subSystemCode',
      },
      inspector: {
        caption: 'Inspector',
        primaryText: 'fullName',
        secondaryText: 'email',
      },
      status: {
        caption: 'Status',
        primaryText: 'id',
        secondaryText: '',
      },
      assigned: {
        caption: 'Assigned',
        primaryText: 'label',
        secondaryText: '',
      },
    },
    sort: {
      status: 'inspection.status',
      mainSystem: 'mainSystem.description',
      subSystem: 'subSystem.description',
      inspector: 'inspection.leadInspector',
      lastActivityDate: 'lastActivityDate',
      description: 'description',
      failureMode: 'failureMode.description',
      wbs: 'inspection.wbs',
    },
    sortLabels: {
      status: 'Status',
      mainSystem: 'Main System',
      subSystem: 'Sub System',
      inspector: 'Inspector',
      lastActivityDate: 'Date and Time',
      description: 'Description',
      failureMode: 'Failure Mode',
      wbs: 'Park/Package',
    },
    filtersOrderIndex: {
      wbs: 1,
      mainSystem: 2,
      failureMode: 3,
      subSystem: 4,
      inspector: 5,
      status: 6,
      assigned: 7,
    },
    chipLabels: {
      wbs: 'Park/Package',
      mainSystem: 'Main System',
      failureMode: 'Failure Mode',
      subSystem: 'Sub System',
      similarFindings: 'Similar Findings',
    },
    additionalFilters: {
      status: Object.values(INSPECTION_STATUSES)
        .filter((item) => !['INVALIDATED', 'DRAFT'].includes(item))
        .map((item) => ({ id: item })),
      assigned: [
        { label: 'YES', id: true },
        { label: 'NO', id: false },
      ],
    },
  };

  const onClearFuncs = {
    failureMode: () => {
      const newAdditionalFilters = {
        ...additionalFilters,
      };
      delete newAdditionalFilters.failureMode;
      dispatch(setSimilarFiltersFrom({}));
      setAdditionalFilters(newAdditionalFilters);
    },
    subSystem: () => {
      const newAdditionalFilters = {
        ...additionalFilters,
      };
      delete newAdditionalFilters.subSystem;
      dispatch(setSimilarFiltersFrom({}));

      setAdditionalFilters(newAdditionalFilters);
    },
  };

  const handleSelectAllVisibleFindings = async (checkedAll, items) => {
    if (checkedAll) {
      setSelectedFindings(Array.from(new Set([...selectedIds, ...items])));
    }

    if (!checkedAll) {
      setSelectedFindings(Array.from(new Set(selectedIds.filter((id) => !items.includes(id)))));
    }

    return true;
  };

  const rowClasses = classNames('col-md-12 pt-3 row align-items-center', 'justify-content-end');

  return (
    <>
      <div className="row no-gutters align-items-start justify-content-end pr-5 pl-5 pt-2 pb-2">
        <Filters
          withTextSearch
          withSort
          searchLabel="description"
          callback={debounced}
          config={filtersConfig}
          onClearAll={onClearAll}
          onClearFuncs={onClearFuncs}
          additionalChips={additionalFilters}
          preserveAdditionalFilters
        />
      </div>
      <div className="mt-3">
        <CreateWorkflowFromSelected
          selectedIds={selectedIds}
          findings={findingsForWorkflows}
          withSelectedCounter
          clearAll={handleClearSelected}
        />
        {filterState.filters.filters.wbs?.length === 0 && (
          <div className={rowClasses}>
            <Typography color="textSecondary" variant="subtitle2" className="text-right">
              To enable select all on page, please select work package in filters.
            </Typography>
          </div>
        )}
        <Table
          config={TABLE_CONFIG}
          handleRowCheck={selectRelatedFindings}
          selectAllCheckboxes={handleSelectAllVisibleFindings}
          isLoading={isLoading}
          items={findings}
          onRowClick={onRowClick}
          pagination
          paginationCallback={setPagination}
          params={params}
          selectedIds={selectedIds}
          hideSelectAll={filterState.filters.filters.wbs?.length === 0}
          similarFiltersFrom={filterSetFrom.item}
          sort={filterState.filters.sort}
          totalCount={totalCount}
          tableUniqueKey={TableUniqueKey.FINDINGS}
          withCheckboxes
          withMarkedRows
        />
      </div>
    </>
  );
};

Findings.propTypes = {
  getFindings: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  findings: PropTypes.arrayOf(PropTypes.shape),
  match: PropTypes.objectOf(PropTypes.shape).isRequired,
  createCrumb: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  history: PropTypes.objectOf(PropTypes.shape).isRequired,
  selectFinding: PropTypes.func.isRequired,
  clearSelectedFindings: PropTypes.func.isRequired,
  setSelectedFindings: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
};

Findings.defaultProps = {
  findings: [],
  selectedIds: [],
};

export default Findings;
