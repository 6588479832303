import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import { Button, IconButton } from '@material-ui/core';
import { Edit, ExpandLess } from '@material-ui/icons';

import { reorderElements } from './checklistEditor.actions';
import ChecklistElement from './checklistElement.component';
import { ItemTypes } from './checklists.config';
import NewElement from './newElement.component';
import NewGroup from './newGroup.component';
import { useDnD, useDnDMove } from './useDnD';

interface IChecklistGroupProps {
  changeOrder: (id: string, index: number) => void;
  id: string;
  index: number;
  moveGroup: (dragIndex: number, hoverIndex: number) => void;
  scrolledId?: string;
  viewMode?: boolean;
}

const ChecklistGroup: FC<IChecklistGroupProps> = ({
  changeOrder: changeGroupsOrder,
  id,
  index,
  moveGroup,
  scrolledId: scrolledGroupId,
  viewMode,
}) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [scrolledId, setScrolledId] = useState('');

  const { isMobile } = useSelector((state: IStore) => state.app);
  const checklistGroup = useSelector((state: IStore) =>
    state.checklistEditor.currentChecklistDetails.checkListGroupDefinitions.find((group) => group.id === id),
  );

  const checklistElements = checklistGroup?.checkListElementDefinitions || [];

  const [elements, moveElement] = useDnDMove(checklistElements);

  const { drag, drop, isDragging, handlerId } = useDnD(ref, id, index, moveGroup, changeGroupsOrder, ItemTypes.GROUP);

  const [expanded, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openElementEditor, setOpenElementEditor] = useState(false);

  useEffect(() => {
    let timeoutID;
    if (scrolledGroupId) {
      timeoutID = setTimeout(() => {
        const el = document.getElementById(`checklistGroup-${scrolledGroupId}`);

        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 100);
    }
    return () => {
      clearTimeout(timeoutID);
    };
  }, [scrolledGroupId]);

  const changeElementsOrder = () => {
    dispatch(
      reorderElements({
        checkListGroupDefinitionId: id,
        elementsOrder: Object.fromEntries(elements.map((item, index) => [item?.id, index + 1])),
      }),
    );
  };

  const renderElements = elements.map((element, elementIndex) => (
    <ChecklistElement
      key={element.id}
      index={elementIndex}
      groupId={id}
      element={element}
      scrolledId={scrolledId}
      isMobile={isMobile}
      moveElement={moveElement}
      changeOrder={changeElementsOrder}
      viewMode={viewMode}
    />
  ));

  const handleCloseElementEditor = (elementId) => {
    setExpanded(true);
    setOpenElementEditor(false);
    setScrolledId(elementId);
  };

  const handleOpenElementEditor = (e) => {
    e.stopPropagation();
    setOpenElementEditor(!openElementEditor);
  };

  const toggleExpand = () => checklistElements.length && setExpanded(!expanded);
  const toggleEditMode = () => setEditMode(!editMode);

  const opacity = isDragging ? 0 : 1;

  if (!viewMode) {
    drag(drop(ref));
  }

  if (editMode) {
    return (
      <div className="checklist__group">
        <NewGroup group={checklistGroup} onClose={toggleEditMode} edit />
      </div>
    );
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleExpand();
    }
  };

  const openIconClasses = classNames('expand-icon', 'icon-dark', { open: openElementEditor || expanded });

  return (
    <div className="checklist__group" style={{ opacity, backgroundColor: 'white' }}>
      <div
        ref={ref}
        id={`checklistGroup-${id}`}
        className={classNames('checklist__title', { viewMode, show: Boolean(!elements.length) })}
        onClick={toggleExpand}
        onKeyDown={handleKeyDown}
      >
        {checklistGroup?.description}
        <IconButton onClick={toggleEditMode} className="ml-2">
          <Edit />
        </IconButton>
        <div className="grow" />
        <Button onClick={handleOpenElementEditor} className="add noTextTransform">
          Add element
        </Button>
        {Boolean(checklistElements.length > 0) && <ExpandLess className={openIconClasses} />}
      </div>
      {openElementEditor && <NewElement groupId={checklistGroup.id} onClose={handleCloseElementEditor} />}
      <div
        className={classNames('checklist__items', {
          open: openElementEditor || expanded,
          'checklist__items--isDrawer': !isMobile,
        })}
      >
        {Boolean(elements.length) && renderElements}
      </div>
    </div>
  );
};

export default ChecklistGroup;
