import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import FilteringTree from 'modules/common/components/filteringTree/filteringTree';
import Loader from 'modules/common/components/loader/loader.component';
import { getMainSystems } from 'modules/inspections/components/newInspection/newInspection.actions';
import notifications from 'services/notifications';

import { Button, Dialog, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { addChecklist, toggleChecklistDuplicateModal } from './checklistEditor.actions';

const useStyles = makeStyles((theme) => ({
  action: {
    color: theme.palette.secondary.light,
  },
}));

const ChecklistsDuplicateModal: FC = () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isMobile = useSelector((state: IStore) => state.app.isMobile);
  const { isDuplicateModalOpen: open, currentChecklistDetails: currentChecklist } = useSelector(
    (state: IStore) => state.checklistEditor,
  );
  const history = useHistory();

  const dispatch = useDispatch();
  const close = () => dispatch(toggleChecklistDuplicateModal());

  const onMainSystemSelect = async (_, mainSystem) => {
    dispatch(toggleChecklistDuplicateModal());
    setIsLoading(true);
    try {
      const checklistCopy: any = await dispatch(
        addChecklist({
          description: `${currentChecklist.description} copy`,
          parkId: currentChecklist.park?.id || '',
          mainSystemId: mainSystem?.id || '',
          sourceCheckListDefinitionId: currentChecklist.id,
        }),
      );

      setIsLoading(false);
      notifications.showSuccess(`Checklist '${checklistCopy.description} copy' is created`);
      history.push(`/admin/checklists/${checklistCopy.id}`);
    } catch (err) {
      setIsLoading(false);
      genericErrorHandler(err);
      throw err;
    }
  };

  if (isLoading) return <Loader fixed mask />;

  if (!open) return null;

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      fullWidth={!isMobile}
      maxWidth={!isMobile ? 'md' : undefined}
      onClose={!isMobile ? close : undefined}
    >
      <FilteringTree
        fieldName="mainSystem"
        clearFieldValue={onMainSystemSelect}
        onSelect={onMainSystemSelect}
        filterTreeId={null}
        getNodes={getMainSystems}
        getNodesParams={{ wbsDetails: currentChecklist?.park }}
        searchPlaceholderText="Find Main System for the checklist copy"
        primaryTextField="description"
        secondaryTextField="mainSystemCode"
        checkForActive
        withCheck={false}
      />
      {!isMobile && (
        <DialogActions>
          <Button onClick={close} className={classes.action}>
            Cancel
          </Button>
          <Button onClick={(e) => onMainSystemSelect(e, null)} className={classes.action}>
            Duplicate without Main system
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ChecklistsDuplicateModal;
