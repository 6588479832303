import { connect } from 'react-redux';

import { setShowBanner } from '../../../app/components/app/app.actions';
import Banner from './banner.component';

const mapStateToProps = (state) => ({
  isLogged: state.auth.isLogged,
});

const mapDispatchToProps = {
  setShowBanner,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Banner);
