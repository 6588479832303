import React, { createRef } from 'react';
import { createPortal } from 'react-dom';

import { GUTTER_SIZE } from 'modules/app/config/config';
import FloatingElement from 'modules/common/components/floatingElement/floatingElement.container';
import PropTypes from 'prop-types';

import { Fab, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  addButton: {
    position: 'fixed',
    backgroundColor: theme.palette.secondary.light,
    bottom: GUTTER_SIZE,
    right: GUTTER_SIZE,
  },
  quickPhotoButton: {
    position: 'fixed',
    backgroundColor: theme.palette.secondary.light,
    bottom: GUTTER_SIZE * 5,
    right: GUTTER_SIZE,
  },
});
const portalRoot = document.getElementById('body');

const FloatingIconWrapper = ({ classes, handleAddFile, icon, isLoadingSave, isQuickPhoto, tab }) => {
  const inputRef = createRef();

  const handleAdd = () => {
    if (inputRef) {
      inputRef.current.click();
    }
  };

  const onChange = (e) => {
    handleAddFile(e);

    if (inputRef?.current) {
      inputRef.current.value = null;
    }
  };

  const classNames = isQuickPhoto ? classes.quickPhotoButton : classes.addButton;

  return createPortal(
    <>
      <input
        className="hidden-elem"
        type="file"
        onChange={onChange}
        ref={inputRef}
        disabled={isLoadingSave}
        capture={isQuickPhoto ? 'environment' : undefined}
        {...tab.attachmentOptions.inputProps}
      />
      <FloatingElement
        render={(floatingClasses) => (
          <>
            <Fab color="secondary" className={`${classNames} ${floatingClasses}`} onClick={handleAdd}>
              <Icon className="svg-medium">{icon}</Icon>
            </Fab>
          </>
        )}
      />
    </>,
    portalRoot,
  );
};

FloatingIconWrapper.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  handleAddFile: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  isLoadingSave: PropTypes.bool,
  isQuickPhoto: PropTypes.bool,
  tab: PropTypes.shape({}).isRequired,
};

FloatingIconWrapper.defaultProps = {
  isLoadingSave: false,
  isQuickPhoto: false,
};

export default withStyles(styles)(FloatingIconWrapper);
