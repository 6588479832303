import { SET_INSPECTIONS, SET_IS_LOADING } from './inspections.actions';

const initialState = {
  items: [],
  itemsTotal: 0,
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_INSPECTIONS:
      return {
        ...state,
        items: payload.content,
        itemsTotal: payload.itemsTotal,
        isLoading: false,
      };

    default:
      return state;
  }
};
