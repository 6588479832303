import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { usePrevious } from 'hooks';
import { createCrumb, setShowHeader } from 'modules/app/components/app/app.actions';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { CONSTRUCTION_TYPE, INSPECTION_STATUSES } from 'modules/app/config/config';
import { charsLeft, isObjectEmpty } from 'modules/app/helpers/utils';
import FilteringTree from 'modules/common/components/filteringTree/filteringTree';
import InputModal from 'modules/common/components/inputModal/inputModal.container';
import InputModalWithTabs from 'modules/common/components/inputModal/inputModalWithTabs.component';
import Loader from 'modules/common/components/loader/loader.component';
import SaveHeader from 'modules/common/components/saveHeader/saveHeaderMobile.component';
import UnsavedChanges from 'modules/common/components/unsavedChanges/unsavedChanges.hoc';
import {
  clearData as clearFindingDetails,
  getFinding,
} from 'modules/findings/components/findingDetails/findingDetails.actions';
import { clearData as clearFindings } from 'modules/findings/components/findings/findings.actions';
import NewFindingModel, { FINDING_TYPES } from 'modules/findings/components/newFinding/newFinding.model';
import { getInspection } from 'modules/inspections/components/inspectionDetails/inspectionDetails.actions';
import { getMainSystems, getSubSystems } from 'modules/inspections/components/newInspection/newInspection.actions';
import NewInspectionModel from 'modules/inspections/components/newInspection/newInspection.model';

import { FormControl, MenuItem, TextField } from '@material-ui/core';

import {
  getFailureModes,
  getTopFailureModes,
  saveFinding,
  setIsLoading,
  updateFinding,
} from '../../modules/findings/components/newFinding/newFinding.actions';

interface IHistoryLocationState {
  checklist?: object;
  checklists?: any;
  fromNewFinding?: boolean;
}

interface MatchProps {
  params: {
    inspectionId: string;
    editFindingId: string;
  };
  path: string;
}

interface INewFindingErrors {
  findingType?: any;
  location?: any;
  serialNumber?: any;
  referenceDocument?: any;
  description?: any;
  mainSystemId?: any;
  subSystemId?: any;
  failureModeId?: any;
}

interface NewFindingProps {
  setDirty: (value: boolean) => boolean;
}

const NewFinding: FC<NewFindingProps> = ({ setDirty }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const historyLocationState: IHistoryLocationState = history.location.state;

  const { params, path }: MatchProps = useRouteMatch();
  const { inspectionId, editFindingId } = params;

  const inspectionStoreData = useSelector((state: IStore) => state.inspection.data);
  const { isLoading: isLoadingEdit, data: findingStoreData } = useSelector((state: IStore) => state.finding);
  const { isLoading: isLoadingCreate } = useSelector((state: IStore) => state.newFinding);

  const prevEditFindingId = usePrevious(editFindingId);
  const prevFinding = usePrevious(findingStoreData);

  const [findingState, setFindingState] = useState<Partial<INewFindingData>>({});
  const [errors, setErrors] = useState<INewFindingErrors>({});

  useEffect(() => {
    dispatch(setShowHeader(false));
    dispatch(getInspection(inspectionId));

    if (inspectionId && editFindingId) {
      dispatch(getFinding(inspectionId, editFindingId));
    }

    return () => {
      if (findingStoreData.identifier) {
        dispatch(clearFindings());
        dispatch(clearFindingDetails());
      }

      dispatch(setShowHeader(true));
    };
  }, []);

  useEffect(() => {
    if (prevEditFindingId !== editFindingId) {
      dispatch(getFinding(inspectionId, editFindingId));
    }

    if (prevFinding !== findingStoreData) {
      setFindingState(findingStoreData);
    }

    dispatch(createCrumb(path, params));
  }, [editFindingId, findingStoreData, inspectionId, params, path]);

  useEffect(() => {
    const isEditFindingIdPresent = !!editFindingId && (!!findingState.id || !!findingState._id);

    if (!(isEditFindingIdPresent || !editFindingId)) {
      setFindingState(findingStoreData);
    }

    if (inspectionStoreData?.status === INSPECTION_STATUSES.finished) {
      const inspectionModel = new NewInspectionModel(inspectionStoreData).parseDetails();
      const newErrors = new NewFindingModel(findingState, inspectionModel).validateSave();
      setErrors(newErrors || {});
    }
  }, [editFindingId, findingStoreData, inspectionStoreData, findingState]);

  const handleSubmit = async () => {
    setDirty(false);
    const checklist = historyLocationState?.checklist;

    const inspectionModel = new NewInspectionModel(inspectionStoreData).parseDetails();
    const newFindingModel: NewFindingModel & { id?: string } = new NewFindingModel(
      findingState,
      inspectionModel,
      checklist,
    );

    const newErrors = newFindingModel.validateSave();

    if (newErrors) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);

    try {
      const id = newFindingModel.id
        ? await dispatch(updateFinding(inspectionId, newFindingModel))
        : await dispatch(saveFinding(inspectionId, newFindingModel));

      await dispatch(getInspection(inspectionId));

      const locationState: IHistoryLocationState = {
        checklist: historyLocationState?.checklist,
        fromNewFinding: !findingState.id,
      };

      setDirty(false);
      setIsLoading(false);

      if (!id) return;

      if (inspectionId && editFindingId) {
        clearFindings();
        clearFindingDetails();
      }

      history.replace(`/inspectionDetails/${inspectionId}/findingDetails/${id}`, locationState);
    } catch (err) {
      setIsLoading(false);
      genericErrorHandler(err);
    }
  };

  const changeFieldsValue = (values) => {
    const findingStateCopy = { ...findingState };
    values.forEach((item) => {
      findingStateCopy[item.name] = item.value;
    });

    const inspectionModel = new NewInspectionModel(inspectionStoreData).parseDetails();
    const newFindingState = new NewFindingModel(findingStateCopy, inspectionModel);

    if (Object.keys(errors).length > 0) {
      const newErrors = newFindingState.validateSave();
      setErrors(newErrors || {});
    }

    setFindingState(newFindingState);

    setDirty(true);
  };

  const handleChange = (e) => {
    const {
      currentTarget: { name, value },
    } = e;

    changeFieldsValue([{ name, value }]);
  };

  const setFieldValue = (name, value) => {
    changeFieldsValue([{ name, value }]);
  };

  const setSelectValue = (name) => (e) => {
    setFieldValue(name, e.target.value);
  };

  const onRegisterFindingClose = () => {
    const goBackUrl = `/inspectionDetails/${inspectionId}`;

    const isFromChecklist = Object.keys(historyLocationState?.checklist || {}).length > 0;

    if (isFromChecklist) {
      history.push(goBackUrl, {
        checklist: historyLocationState.checklists,
      });
    } else {
      history.push(goBackUrl);
    }
  };

  const setMainSystemValue = async (name, value) => {
    changeFieldsValue([
      { name, value },
      { name: 'subSystem', value: '' },
    ]);
  };

  const renderFindingTypes = (findingType) => {
    let types = [];
    if (findingType === FINDING_TYPES.TODO.value && inspectionStoreData.status !== INSPECTION_STATUSES.draft) {
      types = Object.entries(FINDING_TYPES).map(([, val]) => (
        <MenuItem
          key={val.value}
          value={val.value}
          className={`${val.value === FINDING_TYPES.TODO.value ? 'disabled' : ''}`}
        >
          {val.label}
        </MenuItem>
      ));
    } else {
      types = Object.entries(FINDING_TYPES)
        .filter(([, val]) => val.inspectionDraftOnly === (inspectionStoreData.status === INSPECTION_STATUSES.draft))
        .map(([, val]) => (
          <MenuItem key={val.value} value={val.value}>
            {val.label}
          </MenuItem>
        ));
    }
    return types;
  };

  const checklist = historyLocationState?.checklist;

  const inspectionModel = new NewInspectionModel(inspectionStoreData).parseDetails();
  const newFinding = new NewFindingModel(findingState, inspectionModel, checklist).parseDetails();

  const isCreate = !editFindingId;
  const isDraft = inspectionModel.status === INSPECTION_STATUSES.draft;
  const isTodo = newFinding.findingType === FINDING_TYPES.TODO.value;

  const title = useMemo(() => {
    switch (true) {
      case isCreate && isTodo:
        return 'Register Todo';
      case !isCreate && isTodo && isDraft:
        return 'Edit Todo';
      case !isCreate && isTodo:
        return 'Todo → Finding';
      case isCreate && !isObjectEmpty(checklist):
        return 'Checklist → Finding';
      case !isCreate && !isTodo:
        return 'Edit Finding';
      default:
        return 'Register Finding';
    }
  }, [newFinding.id]);

  if (!isCreate && (isLoadingCreate || isLoadingEdit)) {
    return <Loader />;
  }

  if (isCreate && (isLoadingCreate || !inspectionStoreData || !findingState)) {
    return <Loader />;
  }

  const isConstruction = inspectionModel.type === CONSTRUCTION_TYPE;
  const hideForWorkInfo = newFinding.findingType === FINDING_TYPES.WORK_INFO.value && isConstruction;
  const hideOptionalPlaceholder = !isConstruction && inspectionModel.status === INSPECTION_STATUSES.finished;

  return (
    <>
      <SaveHeader title={title} onSave={handleSubmit} history={history} onClose={onRegisterFindingClose} />
      <div id="new-finding:div" className="content-wrapper">
        <InputModal
          className="w-100 mt-4"
          id="mainSystem"
          title="Main system"
          fieldName="mainSystem"
          placeholder={`Main system${isConstruction ? ` (optional)` : ''}`}
          value={newFinding.mainSystem}
          clearFieldValue={setMainSystemValue}
          getNodes={getMainSystems}
          getNodesParams={inspectionModel}
          filterTreeId={inspectionModel.mainSystemDetails ? inspectionModel.mainSystemDetails.id : null}
          error={!!errors && !!errors.mainSystemId}
          errorText={errors && errors.mainSystemId}
          disabled={!!inspectionModel.mainSystem || !!inspectionModel.subSystem}
        >
          <FilteringTree
            onSelect={setMainSystemValue}
            searchPlaceholderText="Search for Main System"
            primaryTextField="description"
            secondaryTextField="mainSystemCode"
            checkForActive
            checkFunction={undefined}
            fieldName={undefined}
            searchPosition={undefined}
            withCheck={undefined}
          />
        </InputModal>

        <InputModal
          className="w-100 mt-4"
          id="subSystem"
          title="Sub system"
          fieldName="subSystem"
          placeholder={`Sub system${hideOptionalPlaceholder ? '' : ` (optional)`}`}
          value={newFinding.subSystem}
          clearFieldValue={inspectionModel.subSystem ? null : setFieldValue}
          getNodes={getSubSystems}
          getNodesParams={newFinding}
          filterTreeId={inspectionModel.subSystemDetails ? inspectionModel.subSystemDetails.id : null}
          error={!!errors && !!errors.subSystemId}
          errorText={errors && errors.subSystemId}
        >
          <FilteringTree
            onSelect={setFieldValue}
            searchPlaceholderText="Search for Sub System"
            primaryTextField="subSystemCode"
            secondaryTextField="description"
            checkForActive
            fieldName={undefined}
            checkFunction={undefined}
            searchPosition={undefined}
            withCheck={undefined}
          />
        </InputModal>

        {!hideForWorkInfo && (
          <InputModalWithTabs
            id="failureMode"
            title="Failure Mode"
            fieldName="failureMode"
            placeholder="Failure Mode"
            value={newFinding.failureMode}
            error={errors && !!errors.failureModeId}
            errorText={errors && errors.failureModeId}
            clearFieldValue={setFieldValue}
            getNodesParams={inspectionModel}
            tabs={[
              <FilteringTree
                getNodes={getTopFailureModes}
                onSelect={setFieldValue}
                searchPlaceholderText="Search for Failure Mode"
                primaryTextField="description"
                secondaryTextField="code"
                fieldName={undefined}
                checkFunction={undefined}
                searchPosition={undefined}
                withCheck={undefined}
              />,
              <FilteringTree
                getNodes={getFailureModes}
                onSelect={setFieldValue}
                searchPlaceholderText="Search for Failure Mode"
                primaryTextField="description"
                secondaryTextField="code"
                blockRootClick
                checkForActive
                fieldName={undefined}
                checkFunction={undefined}
                searchPosition={undefined}
                withCheck={undefined}
              />,
            ]}
            tabNames={['Most used', 'All']}
          />
        )}

        <FormControl className="w-100 mt-4">
          <TextField
            label="Type"
            select
            id="findingType"
            name="findingType"
            value={newFinding.findingType}
            onChange={setSelectValue('findingType')}
            error={errors && !!errors.findingType}
            helperText={errors && errors.findingType}
          >
            {renderFindingTypes(newFinding.findingType)}
          </TextField>
        </FormControl>

        {isConstruction && (
          <>
            {!hideForWorkInfo && (
              <>
                <FormControl className="w-100 mt-4">
                  <TextField
                    label="Failure Mode details (optional)"
                    id="failureModeDetails"
                    name="failureModeDetails"
                    value={newFinding.failureModeDetails}
                    onChange={handleChange}
                    helperText={charsLeft(newFinding.failureModeDetails, 2000)}
                    multiline
                  />
                </FormControl>

                <FormControl className="w-100 mt-4">
                  <TextField
                    label="Location of Finding"
                    id="location"
                    name="location"
                    value={newFinding.location}
                    onChange={handleChange}
                    error={errors && !!errors.location}
                    helperText={charsLeft(newFinding.location, 200)}
                    multiline
                  />
                </FormControl>
              </>
            )}

            <FormControl className="w-100 mt-4">
              <TextField
                label="Serial Number / ID Number"
                id="serialNumber"
                name="serialNumber"
                value={newFinding.serialNumber}
                onChange={handleChange}
                error={errors && !!errors.serialNumber}
                helperText={charsLeft(newFinding.serialNumber, 50)}
                multiline
              />
            </FormControl>

            <FormControl className="w-100 mt-4">
              <TextField
                label="Reference Document"
                id="referenceDocument"
                name="referenceDocument"
                value={newFinding.referenceDocument}
                onChange={handleChange}
                error={errors && !!errors.referenceDocument}
                helperText={charsLeft(newFinding.referenceDocument, 100)}
                multiline
              />
            </FormControl>
          </>
        )}

        <FormControl className="w-100 mt-4">
          <TextField
            label="Description"
            id="description"
            name="description"
            value={newFinding.description}
            onChange={handleChange}
            error={errors && !!errors.description}
            helperText={charsLeft(newFinding.description, 2000)}
            multiline
          />
        </FormControl>
      </div>
    </>
  );
};

export default UnsavedChanges(NewFinding);
