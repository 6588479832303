import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  title: state.app.title,
  showHeader: state.app.showHeader,
  ...state.auth.user,
});

const withHeader = (HeaderComponent) => {
  class Header extends PureComponent {
    render() {
      return <HeaderComponent {...this.props} />;
    }
  }

  return connect(mapStateToProps, {})(Header);
};

export default withHeader;
