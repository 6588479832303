import { connect } from 'react-redux';

import { init, checkMobile } from 'modules/app/components/app/app.actions';

import Home from './home.component';

const mapStateToProps = (state) => ({
  isLogged: state.auth.isLogged,
  roles: state.auth.user.roles,
  initInProgress: state.app.initInProgress,
  isMobile: state.app.isMobile,
  showBanner: state.app.showBanner,
});

const mapDispatchToProps = {
  init,
  checkMobile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
