import React from 'react';

const NonconformityStatusMitigationPlanIssued = (props) => (
  <svg width={24} height={24} {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M11 7h6v2h-6zm0 4h6v2h-6zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7zM20.1 3H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM19 19H5V5h14v14z" />
  </svg>
);

export default NonconformityStatusMitigationPlanIssued;
