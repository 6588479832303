import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';

import Api from '../../../../services/api';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'ADMIN';
const CLEAR_DATA = `${namespace}_CLEAR_DATA`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const SET_USER_IS_ADDING = `${namespace}_SET_USER_IS_ADDING`;
const SET_USERS = `${namespace}_SET_USERS`;

const getUsers = (params) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const { content, totalElements } = await Api.get('/api/users', params);

    dispatch({
      type: SET_USERS,
      payload: {
        content,
        totalElements,
      },
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const addGuestUser =
  ({ values, filterParams }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_USER_IS_ADDING,
        payload: true,
      });
      await Api.post('/api/users/guests', values);
      dispatch({
        type: SET_USER_IS_ADDING,
        payload: false,
      });

      const { content, totalElements } = await Api.get('/api/users', filterParams);
      dispatch({
        type: SET_USERS,
        payload: {
          content,
          totalElements,
        },
      });
      Notifications.showSuccess('User was successfully added');
    } catch (error) {
      dispatch({
        type: SET_USER_IS_ADDING,
        payload: false,
      });
      genericErrorHandler(error);
    }
  };

const clearData = () => ({ type: CLEAR_DATA });

export { addGuestUser, getUsers, clearData };

export { CLEAR_DATA, SET_IS_LOADING, SET_USER_IS_ADDING, SET_USERS };
