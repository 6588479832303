import Api from 'services/api';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'DASHBOARD';
const CLEAR_DATA = `${namespace}_CLEAR_DATA`;

const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const SET_IS_LOADING_FILTERS = `${namespace}_SET_IS_LOADING_FILTERS`;
const SET_IS_LOADING_CHARTS = `${namespace}_SET_IS_LOADING_CHARTS`;
const SET_DASHBOARD_FILTERS = `${namespace}_SET_DASHBOARD_FILTERS`;
const SET_DASHBOARD_CHARTS = `${namespace}_SET_DASHBOARD_CHARTS`;
const SET_IS_LOADING_TABLES = `${namespace}_SET_IS_LOADING_TABLES`;
const SET_DASHBOARD_TABLES = `${namespace}_SET_DASHBOARD_TABLE`;

const setIsLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  payload: isLoading,
});

const getDashboardFilters = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING_FILTERS,
      payload: true,
    });

    const data = await Api.get(`/api/dashboard/filter`);

    dispatch({
      type: SET_DASHBOARD_FILTERS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING_FILTERS,
      payload: false,
    });
    throw e;
  }
};

const getDashboardChartData = (params) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING_CHARTS,
      payload: true,
    });

    const data = await Api.get(`/api/dashboard/charts`, params);

    dispatch({
      type: SET_DASHBOARD_CHARTS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING_CHARTS,
      payload: false,
    });
    throw e;
  }
};

const getDashboardTableData = (params) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING_TABLES,
      payload: true,
    });

    const data = await Api.get(`/api/dashboard/tables`, params);

    dispatch({
      type: SET_DASHBOARD_TABLES,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING_TABLES,
      payload: false,
    });
    throw e;
  }
};

const clearData = () => ({
  type: CLEAR_DATA,
});

export { clearData, setIsLoading, getDashboardFilters, getDashboardChartData, getDashboardTableData };

export {
  CLEAR_DATA,
  SET_IS_LOADING,
  SET_IS_LOADING_FILTERS,
  SET_DASHBOARD_FILTERS,
  SET_IS_LOADING_CHARTS,
  SET_DASHBOARD_CHARTS,
  SET_IS_LOADING_TABLES,
  SET_DASHBOARD_TABLES,
};
