import _get from 'lodash/get';

import { ISelectionConfig } from './types';

const validateTableItem = (config: ISelectionConfig[], item) =>
  !!config.filter((val) => {
    if (typeof val.compareFn === 'function') {
      return _get(item, val.dataField) && val.compareFn(item);
    }
    return _get(item, val.dataField);
  }).length;

export { validateTableItem };
