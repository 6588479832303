import { SET_IS_LOADING, SET_FINDING, CLEAR_DATA } from './findingDetails.actions';

const initialState = {
  data: {},
  isLoading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_FINDING:
      return {
        ...state,
        data: payload.data,
        inspectionId: payload.inspectionId,
        findingId: payload.findingId,
        isLoading: false,
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
