import React from 'react';

const Logo = (props) => (
  <svg viewBox="0 0 180 27" {...props}>
    <g fill="none" fillRule="evenodd">
      <text
        transform="translate(48, 5)"
        fill="#000"
        fillOpacity={0.87}
        fontFamily="Roboto-Medium, Roboto"
        fontSize={14}
        fontWeight={400}
        letterSpacing={0.1}
      >
        <tspan x={0} y={15}>
          Wind Inspection Tool
        </tspan>
      </text>
      <path
        d="M25.708 26.667c-3.125 0-5.708-2.584-5.708-5.709 0-.541.417-.958.958-.958.542 0 .959.417.959.958a3.806 3.806 0 0 0 3.791 3.792 3.806 3.806 0 0 0 3.792-3.792 3.806 3.806 0 0 0-3.792-3.791h-20a.943.943 0 0 1-.958-.959c0-.541.417-.958.958-.958h20c3.125 0 5.709 2.583 5.709 5.708s-2.542 5.709-5.709 5.709z"
        fill="#1162A5"
      />
      <path
        d="M33.333 13.333H.958A.943.943 0 0 1 0 12.375c0-.542.417-.958.958-.958h32.375a4.748 4.748 0 0 0 4.75-4.75 4.748 4.748 0 0 0-4.75-4.75 4.748 4.748 0 0 0-4.75 4.75.943.943 0 0 1-.958.958.943.943 0 0 1-.958-.958C26.667 3 29.667 0 33.333 0 37 0 40 3 40 6.667c0 3.666-3 6.666-6.667 6.666z"
        fill="#29A2E5"
      />
      <path
        d="M19.042 9.542h-9.5a.943.943 0 0 1-.959-.959c0-.541.417-.958.959-.958h9.541a2.88 2.88 0 0 0 2.875-2.875 2.88 2.88 0 0 0-2.875-2.875 2.88 2.88 0 0 0-2.875 2.875.943.943 0 0 1-.958.958.943.943 0 0 1-.958-.958A4.748 4.748 0 0 1 19.042 0a4.748 4.748 0 0 1 4.75 4.75c0 2.625-2.125 4.792-4.75 4.792z"
        fill="#158AD1"
      />
    </g>
  </svg>
);

export default Logo;
