import {
  CLEAR_SYNC_ERRORS,
  INCREMENT_SYNC_PROGRESS,
  SET_CRUMB,
  SET_INIT_IN_PROGRESS,
  SET_IS_TOAST_VISIBLE,
  SET_MOBILE,
  SET_OFFLINE,
  SET_ONLINE,
  SET_SHOW_BANNER,
  SET_SHOW_HEADER,
  SET_SYNC_ERRORS,
  SET_SYNC_INDICATOR,
  SET_SYNC_PROGRESS,
  SET_SYNC_STARTED,
  SET_SYNC_STATUS,
  SET_TITLE,
} from './app.actions';

const initialState = {
  title: '',
  showHeader: true,
  initInProgress: true,
  isMobile: false,
  showBanner: false,
  syncErrors: [],
  breadcrumbs: [],
  isToastVisible: false,
  syncInProgress: false,
  syncStarted: false,
  showSyncIndicator: false,
  isOnline: navigator.online,
  syncProgress: {
    attachments: 0,
    attachmentsTotal: 0,
    findings: 0,
    findingsTotal: 0,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TITLE: {
      return {
        ...state,
        title: payload,
      };
    }
    case SET_SHOW_HEADER: {
      return {
        ...state,
        showHeader: payload,
      };
    }
    case SET_INIT_IN_PROGRESS: {
      return {
        ...state,
        initInProgress: payload,
      };
    }
    case SET_SYNC_STATUS:
      return {
        ...state,
        syncInProgress: payload,
      };

    case SET_SYNC_PROGRESS:
      return {
        ...state,
        syncProgress: {
          ...state.syncProgress,
          ...payload,
        },
      };

    case INCREMENT_SYNC_PROGRESS:
      return {
        ...state,
        syncProgress: {
          ...state.syncProgress,
          [payload]: state.syncProgress[payload] + 1,
        },
      };

    case SET_SYNC_STARTED:
      return {
        ...state,
        syncStarted: payload,
      };

    case SET_SYNC_INDICATOR:
      return {
        ...state,
        showSyncIndicator: payload,
      };

    case SET_OFFLINE:
      return {
        ...state,
        isOnline: false,
      };

    case SET_ONLINE:
      return {
        ...state,
        isOnline: true,
      };

    case SET_MOBILE: {
      return {
        ...state,
        isMobile: payload,
      };
    }
    case SET_SHOW_BANNER: {
      return {
        ...state,
        showBanner: payload,
      };
    }
    case SET_SYNC_ERRORS: {
      return {
        ...state,
        syncErrors: payload,
      };
    }
    case CLEAR_SYNC_ERRORS: {
      return {
        ...state,
        syncErrors: [],
      };
    }
    case SET_CRUMB: {
      return {
        ...state,
        breadcrumbs: payload,
      };
    }
    case SET_IS_TOAST_VISIBLE: {
      return {
        ...state,
        isToastVisible: payload,
      };
    }
    default:
      return state;
  }
};
