import React, { useState } from 'react';

import { shape, string, node, bool, func, arrayOf } from 'prop-types';

import { Button, Dialog, DialogActions, DialogTitle, FormHelperText, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  action: {
    color: theme.palette.secondary.light,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContainer: {
    width: '500px',
    padding: '1rem',
  },
});

const SimpleInputModal = ({
  children,
  classes,
  defaultInitialState,
  defaultInitialText,
  label,
  name,
  placeholderText,
  submitFn,
  title,
  validationRules,
}) => {
  const [isOpen, setIsOpen] = useState(defaultInitialState);
  const [text, setText] = useState(defaultInitialText);
  const [error, setError] = useState('');
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const onChange = async (e) => {
    const { value } = e.target;

    if (validationRules.length) {
      setError('');
      validationRules.forEach((rule) => {
        if (rule?.regex && !rule.regex.test(value)) {
          setError(rule.msg);
        }
        if (rule?.maxLength && value.length > rule.maxLength) {
          setError(rule.msg);
        }
      });
    }

    setText(value);
  };

  const onApply = () => {
    setText('');
    submitFn(text);
    closeDialog();
  };

  const onCancel = () => {
    setText('');
    setError('');
    closeDialog();
  };

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      onClick: openDialog,
    }),
  );

  return (
    <>
      {childrenWithProps}
      <Dialog open={isOpen} maxWidth="md" onClose={closeDialog} onEscapeKeyDown={closeDialog}>
        <div className={classes.dialogContainer}>
          <DialogTitle className="modal-title--center">{title}</DialogTitle>
          <TextField
            fullWidth
            label={label}
            name={name}
            onChange={onChange}
            placeholder={placeholderText}
            value={text}
            variant="outlined"
            error={!!error}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
          <DialogActions classes={{ root: classes.buttons }}>
            <Button onClick={onCancel} color="secondary" className={classes.action}>
              Close
            </Button>
            <Button onClick={onApply} color="primary" variant="contained" disabled={!text || !!error}>
              Add
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

SimpleInputModal.propTypes = {
  children: node.isRequired,
  classes: shape({}).isRequired,
  defaultInitialState: bool,
  defaultInitialText: string,
  label: string,
  name: string.isRequired,
  placeholderText: string,
  submitFn: func.isRequired,
  title: string.isRequired,
  validationRules: arrayOf(shape({})),
};

SimpleInputModal.defaultProps = {
  defaultInitialState: false,
  defaultInitialText: '',
  label: '',
  placeholderText: '',
  validationRules: [],
};

export default withStyles(styles)(SimpleInputModal);
