import { registerSync } from 'modules/app/components/app/app.actions';
import { CHECKLIST_STATUSES } from 'modules/app/config/config';
import Api from 'services/api';

const namespace = 'CHECKLIST_EDITOR';
const ADD_CHECKLIST = `${namespace}_ADD_CHECKLIST`;
const ADD_CHECKLIST_ELEMENT = `${namespace}_ADD_CHECKLIST_ELEMENT`;
const ADD_CHECKLIST_GROUP = `${namespace}_ADD_CHECKLIST_GROUP`;
const CHANGE_STATUS = `${namespace}_CHANGE_STATUS`;
const CLEAR_ACTIVE_CHECKLISTS = `${namespace}_CLEAR_ACTIVE_CHECKLISTS`;
const CLEAR_CHECKLIST_DETAILS = `${namespace}_CLEAR_CHECKLIST_DETAILS`;
const CLEAR_DATA = `${namespace}_CLEAR_DATA`;
const DELETE_CHECKLIST_ELEMENT = `${namespace}_DELETE_CHECKLIST_ELEMENT`;
const DELETE_CHECKLIST_GROUP = `${namespace}_DELETE_CHECKLIST_GROUP`;
const EDIT_CHECKLIST = `${namespace}_EDIT_CHECKLIST`;
const EDIT_CHECKLIST_ELEMENT = `${namespace}_EDIT_CHECKLIST_ELEMENT`;
const EDIT_CHECKLIST_GROUP = `${namespace}_EDIT_CHECKLIST_GROUP`;
const REORDER_CHECKLIST_ELEMENTS = `${namespace}_REORDER_CHECKLIST_ELEMENTS`;
const REORDER_CHECKLIST_GROUPS = `${namespace}_REORDER_CHECKLIST_GROUPS`;
const SET_ACTIVE_CHECKLISTS = `${namespace}_SET_ACTIVE_CHECKLISTS`;
const SET_CHECKLIST_DETAILS = `${namespace}_SET_CHECKLIST_DETAILS`;
const SET_CHECKLISTS = `${namespace}_SET_CHECKLISTS`;
const SET_CURRENT_WBS = `${namespace}_SET_CURRENT_WBS`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const SET_IS_LOADING_BUILDER = `${namespace}_SET_IS_LOADING_BUILDER`;
const SET_IS_LOADING_GROUPS = `${namespace}_SET_IS_LOADING_GROUPS`;
const SET_WBSES = `${namespace}_SET_WBSES`;
const SET_WBSES_FOR_ADMIN = `${namespace}_SET_WBSES_FOR_ADMIN`;
const TOGGLE_DUPLICATE_MODAL = `${namespace}_TOGGLE_DUPLICATE_MODAL`;

const getChecklists = (currentWbs) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const url = currentWbs ? `/api/checklists?parkId=${currentWbs}&adminView=true` : `/api/checklists?adminView=true`;

    const payload = await Api.get(url);

    dispatch({
      type: SET_CHECKLISTS,
      payload,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const getWorkPackagesForAdmin = () => async (dispatch) => {
  try {
    const payload = await Api.get('/api/structures/wbses?role=ADMINISTRATOR');

    dispatch({
      type: SET_WBSES_FOR_ADMIN,
      payload,
    });
  } catch (e) {
    throw e;
  }
};

const getWorkPackages = () => async (dispatch) => {
  try {
    const payload = await Api.get('/api/structures/wbses');

    dispatch({
      type: SET_WBSES,
      payload,
    });
  } catch (e) {
    throw e;
  }
};

const getChecklistToEdit = (checklistId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const payload = await Api.get(`/api/checklists/${checklistId}`);

    dispatch({
      type: SET_CHECKLIST_DETAILS,
      payload,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const setChecklist = (checklist) => async (dispatch) => {
  if (checklist.id) {
    dispatch({
      type: SET_CHECKLIST_DETAILS,
      payload: checklist,
    });
  }
};

const getChecklistToBuild = (checklistId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING_BUILDER,
      payload: true,
    });

    const payload = await Api.get(`/api/checklists/${checklistId}`);

    dispatch({
      type: SET_CHECKLIST_DETAILS,
      payload,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING_BUILDER,
      payload: false,
    });
    throw e;
  }
};

const clearChecklistToEdit = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CHECKLIST_DETAILS,
  });
};

const addChecklist = (values) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const payload = await Api.post(`/api/checklists`, values);

    dispatch({
      type: ADD_CHECKLIST,
      payload,
    });

    dispatch(getChecklistToEdit(payload.id));

    return payload;
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const editChecklist = (values, id) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const payload = await Api.put(`/api/checklists/${id}`, values);

    dispatch({
      type: EDIT_CHECKLIST,
      payload,
    });

    dispatch(getChecklistToEdit(payload.id));
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });

    throw e;
  }
};

const isEmptyGroupPresent = (checklistDataGroups) => {
  for (const elements of checklistDataGroups) {
    if (!elements.checkListElementDefinitions || elements.checkListElementDefinitions.length === 0) {
      return true;
    }
  }
  return false;
};

const toggleChecklistStatus = (checklistId, status) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    if (status.status === CHECKLIST_STATUSES.active) {
      const checklistData = await Api.get(`/api/checklists/${checklistId}`);
      const checklistDataGroups = checklistData.checkListGroupDefinitions;

      if (!Array.isArray(checklistDataGroups) || checklistDataGroups.length === 0) {
        throw new Error(`Unable to activate an empty checklist.`);
      }
      if (isEmptyGroupPresent(checklistDataGroups)) {
        throw new Error(`Unable to activate when one of the groups has no elements.`);
      }
    }

    const payload = await Api.post(`/api/checklists/${checklistId}/change-status`, status);
    dispatch({ type: CHANGE_STATUS, payload });
    dispatch(registerSync());
  } catch (e) {
    dispatch({ type: SET_IS_LOADING, payload: false });
    throw e;
  }
};

const getActiveChecklistsForPark = (parkId, mainSystemId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const mainSystemAddon = mainSystemId ? `&mainSystemId=${mainSystemId}` : '';
    const payload = await Api.get(`/api/checklists?status=ACTIVE&parkId=${parkId}${mainSystemAddon}`);

    dispatch({
      type: SET_ACTIVE_CHECKLISTS,
      payload,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const clearActiveChecklists = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ACTIVE_CHECKLISTS,
  });
};

//
//
// GROUPS

const reorderGroups = (values) => async (dispatch) => {
  try {
    await Api.patch(`/api/checklists/groups`, values);

    dispatch({
      type: REORDER_CHECKLIST_GROUPS,
      payload: values,
    });
  } catch (e) {
    throw e;
  }
};

const getGroupById = (groupId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING_GROUPS,
      payload: true,
    });

    const payload = await Api.get(`/api/checklists/groups/${groupId}`);

    dispatch({
      type: EDIT_CHECKLIST_GROUP,
      payload,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING_GROUPS,
      payload: false,
    });
    throw e;
  }
};

const addGroup = (values) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING_BUILDER,
      payload: true,
    });

    const payload = await Api.post('/api/checklists/groups', values);

    dispatch({
      type: ADD_CHECKLIST_GROUP,
      payload,
    });

    dispatch(getChecklistToBuild(values.checkListDefinitionId));

    return payload.id;
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING_BUILDER,
      payload: false,
    });
    throw e;
  }
};

const editGroup = (groupId, values) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING_GROUPS,
      payload: true,
    });

    const payload = await Api.put(`/api/checklists/groups/${groupId}`, values);

    dispatch({
      type: EDIT_CHECKLIST_GROUP,
      payload,
    });

    dispatch(getGroupById(groupId));
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING_GROUPS,
      payload: false,
    });
    throw e;
  }
};

const deleteGroup = (groupId, checklistId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING_BUILDER,
      payload: true,
    });

    await Api.delete(`/api/checklists/groups/${groupId}`);

    dispatch({
      type: DELETE_CHECKLIST_GROUP,
      payload: groupId,
    });

    dispatch(getChecklistToBuild(checklistId));
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING_BUILDER,
      payload: false,
    });
    throw e;
  }
};

//
//
// ELEMENTS

const reorderElements = (values) => async (dispatch) => {
  try {
    await Api.patch(`/api/checklists/elements`, values);

    dispatch({
      type: REORDER_CHECKLIST_ELEMENTS,
      payload: values,
    });

    dispatch(getGroupById(values.checkListGroupDefinitionId));
  } catch (e) {
    throw e;
  }
};

const addElement = (values) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING_GROUPS,
      payload: true,
    });

    const payload = await Api.post('/api/checklists/elements', values);

    dispatch({
      type: ADD_CHECKLIST_ELEMENT,
      payload,
      parentId: values.checkListGroupDefinitionId,
    });

    dispatch(getGroupById(values.checkListGroupDefinitionId));

    return payload.id;
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING_GROUPS,
      payload: false,
    });
    throw e;
  }
};

const editElement = (elementId, values) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING_GROUPS,
      payload: true,
    });

    const payload = await Api.put(`/api/checklists/elements/${elementId}`, values);

    dispatch({
      type: EDIT_CHECKLIST_ELEMENT,
      payload,
      parentId: values.checkListGroupDefinitionId,
    });

    dispatch(getGroupById(values.checkListGroupDefinitionId));
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING_GROUPS,
      payload: false,
    });
    throw e;
  }
};

const deleteElement = (elementId, groupId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING_GROUPS,
      payload: true,
    });

    const payload = await Api.delete(`/api/checklists/elements/${elementId}`);

    dispatch({
      type: SET_IS_LOADING_GROUPS,
      payload: false,
    });

    dispatch(getGroupById(groupId));
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING_GROUPS,
      payload: false,
    });
    throw e;
  }
};

const toggleChecklistDuplicateModal = () => (dispatch) => {
  dispatch({
    type: TOGGLE_DUPLICATE_MODAL,
  });
};

const setCurrentWbsInStore = (currentWbs) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_WBS,
    payload: currentWbs,
  });
};

export {
  addChecklist,
  addGroup,
  addElement,
  clearActiveChecklists,
  clearChecklistToEdit,
  deleteElement,
  deleteGroup,
  editChecklist,
  editElement,
  editGroup,
  getActiveChecklistsForPark,
  getWorkPackages,
  getWorkPackagesForAdmin,
  getChecklists,
  getChecklistToEdit,
  getGroupById,
  setChecklist,
  setCurrentWbsInStore,
  reorderElements,
  reorderGroups,
  toggleChecklistStatus,
  toggleChecklistDuplicateModal,
};

export {
  ADD_CHECKLIST_ELEMENT,
  ADD_CHECKLIST_GROUP,
  ADD_CHECKLIST,
  CHANGE_STATUS,
  CLEAR_ACTIVE_CHECKLISTS,
  CLEAR_CHECKLIST_DETAILS,
  CLEAR_DATA,
  DELETE_CHECKLIST_ELEMENT,
  DELETE_CHECKLIST_GROUP,
  EDIT_CHECKLIST_ELEMENT,
  EDIT_CHECKLIST_GROUP,
  EDIT_CHECKLIST,
  REORDER_CHECKLIST_ELEMENTS,
  REORDER_CHECKLIST_GROUPS,
  SET_ACTIVE_CHECKLISTS,
  SET_CHECKLIST_DETAILS,
  SET_CHECKLISTS,
  SET_CURRENT_WBS,
  SET_IS_LOADING_BUILDER,
  SET_IS_LOADING_GROUPS,
  SET_IS_LOADING,
  SET_WBSES,
  SET_WBSES_FOR_ADMIN,
  TOGGLE_DUPLICATE_MODAL,
};
