import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { DEFAULT_PAGINATION_PAGE_SIZE } from 'modules/app/config/config';
import { TableUniqueKey } from 'modules/common/components/table/table.context';
import PropTypes from 'prop-types';

import { Button, Typography } from '@material-ui/core';

import { UserRoleOverride } from '../../../../assets/icons/index';
import Table from '../../../common/components/table';
import UserDetailsModal from './usersDetailsModal';

const TABLE_CONFIG = {
  columns: [
    {
      caption: 'Name',
      dataField: 'lastName',
      sort: true,
      renderCell: (_, item) => (
        <div className="row no-gutters align-items-center cursor-pointer">
          <UserRoleOverride className={item.active ? 'person-icon pr-2' : 'invisible'} />
          <Typography color={item.active ? 'textPrimary' : 'textSecondary'} variant="body2">
            {item.fullName}
          </Typography>
        </div>
      ),
      align: 'left',
    },
    {
      caption: 'E-mail',
      dataField: 'email',
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Job Title',
      dataField: 'jobTitle',
      hidable: true,
      align: 'right',
    },
  ],
  noItemsFoundText: 'No users found',
  initialSort: {
    sortField: 'lastName',
    sortDirection: 'asc',
  },
};

class AdminUsersWeb extends PureComponent {
  state = {
    size: DEFAULT_PAGINATION_PAGE_SIZE,
    page: 0,
    isModalOpen: false,
  };

  componentDidMount() {
    const {
      createCrumb,
      getUsers,
      match: { path },
    } = this.props;

    createCrumb(path);
    getUsers(this.state);
  }

  componentDidUpdate(_, prevState) {
    const { getUsers } = this.props;
    if (prevState && prevState !== this.state) {
      getUsers(this.state);
    }
  }

  componentWillUnmount() {
    const { clearData } = this.props;

    clearData();
  }

  onRowClick = (_, item) => {
    const { history } = this.props;

    history.push(`/admin/users/${item.id}`);
  };

  setSort = (page, size) => {
    this.setState({
      size,
      page,
    });
  };

  handleModalOpen = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    const { isGlobalAdmin, isLoading, isUserAdding, totalCount, users } = this.props;
    const { isModalOpen } = this.state;

    return (
      <div className="mt-3">
        <UserDetailsModal open={isModalOpen} handleClose={this.handleCloseModal} filterParams={this.state} />
        {isGlobalAdmin && (
          <div className="d-flex justify-content-end">
            <Button color="info" variant="outlined" onClick={this.handleModalOpen} disabled={isUserAdding}>
              Add user
            </Button>
          </div>
        )}
        <Table
          config={TABLE_CONFIG}
          items={users}
          totalCount={totalCount}
          onRowClick={this.onRowClick}
          isLoading={isLoading}
          pagination
          paginationCallback={this.setSort}
          tableUniqueKey={TableUniqueKey.USERS}
        />
      </div>
    );
  }
}

AdminUsersWeb.propTypes = {
  clearData: PropTypes.func.isRequired,
  createCrumb: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.shape).isRequired,
  isGlobalAdmin: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.objectOf(PropTypes.shape).isRequired,
  totalCount: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
};

AdminUsersWeb.defaultProps = {
  isGlobalAdmin: false,
  users: [],
};

export default withRouter(AdminUsersWeb);
