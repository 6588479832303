import React, { FC, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Modal,
  Typography,
} from '@material-ui/core';

import { useTableStyles } from './styles';
import { IShowColumnConfig, TableUniqueKey } from './table.context';
import { ITableConfig } from './types';

interface ITableSettings {
  isOpen: boolean;
  handleCloseSettings: () => void;
  resetColumnsSettings: () => void;
  setColumnsSettings: (columns: IShowColumnConfig[]) => void;
  columnsConfig: IShowColumnConfig[];
  tableUniqueKey: TableUniqueKey;
  config: ITableConfig;
  skipCells: string[];
}

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80vw',
  background: '#FFF',
  boxShadow: 24,
  p: 0,
};

const TableSettings: FC<ITableSettings> = ({
  isOpen,
  handleCloseSettings,
  resetColumnsSettings,
  setColumnsSettings,
  columnsConfig,
  tableUniqueKey,
  config,
  skipCells = [],
}) => {
  const classes = useTableStyles();

  const visibleColumns = config.columns
    .filter((conf) => !skipCells.includes(conf.dataField))
    .filter((conf) => conf.hidable)
    .filter((conf) => conf.dataField)
    .reduce<Record<string, boolean>>((acc, column) => {
      acc[column.dataField] = Boolean(columnsConfig?.find((conf) => conf.dataField === column.dataField)?.showed);

      return acc;
    }, {});

  const [settingsState, setSettingsState] = useState({
    visible: visibleColumns,
  });

  useEffect(() => {
    if (isOpen) {
      setSettingsState({
        visible: visibleColumns,
      });
    }
  }, [isOpen]);

  const dirty = Object.entries(settingsState.visible).some(([key, value]) => {
    const column = columnsConfig?.find((conf) => conf.dataField === key);
    return column?.showed !== value;
  });

  const TABLE_LABELS = {
    [TableUniqueKey.FINDINGS]: 'Findings',
    [TableUniqueKey.WORKFLOWS]: 'Workflows',
    [TableUniqueKey.REPORTS]: 'Reports',
    [TableUniqueKey.USERS]: 'Users',
    [TableUniqueKey.ADD_FINDINGS_TO_WORKFLOW]: 'Add Findings to Workflow',
  };

  const renderConfigSettings = useMemo(
    () =>
      Object.entries(settingsState.visible).map(([key, value]) => {
        const handleChangeVisibleSettings = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
          setSettingsState((state) => ({
            ...state,
            visible: {
              ...state.visible,
              [key]: checked,
            },
          }));
        };

        const label = config.columns.find((column) => column.dataField === key)?.caption;

        return (
          <FormControlLabel
            key={label}
            control={<Checkbox checked={value} onChange={handleChangeVisibleSettings} name={key} />}
            label={label}
          />
        );
      }),
    [settingsState.visible, config.columns],
  );

  const handleSubmit = () => {
    const newColumnsConfig = columnsConfig.map((column) => ({
      ...column,
      showed: settingsState.visible[column.dataField],
    }));

    setColumnsSettings(newColumnsConfig);
    handleCloseSettings();
  };

  const handleResetSettings = () => {
    resetColumnsSettings();
    handleCloseSettings();
  };

  return (
    <Modal open={isOpen} onClose={handleCloseSettings}>
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '80vh',
            width: '60vw',
            padding: '32px',
            boxSizing: 'border-box',
          }}
        >
          <Typography variant="h6"> {TABLE_LABELS[tableUniqueKey]} Table Settings</Typography>

          <Box sx={{ marginTop: '20px' }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select columns to be visible</FormLabel>
              <FormGroup>{renderConfigSettings}</FormGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '16px',
              boxSizing: 'border-box',
            }}
          >
            <Button onClick={handleCloseSettings} variant="text">
              Close
            </Button>
            <Button onClick={handleResetSettings} variant="text">
              Reset Settings
            </Button>
            <Button onClick={handleSubmit} className={classes.action} disabled={!dirty}>
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { TableSettings };
