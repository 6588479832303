import React from 'react';

import { DATETIME_FORMAT } from 'modules/app/config/config';
import moment from 'moment';

import { Typography } from '@material-ui/core';

const TABLE_CONFIG = {
  columns: [
    {
      caption: 'Title',
      dataField: 'title',
      sort: true,
      align: 'left',
    },
    {
      caption: 'Park',
      dataField: 'park',
      sort: true,
      align: 'center',
    },
    {
      caption: 'Work package',
      dataField: 'workPackage',
      sort: true,
      align: 'left',
    },
    {
      caption: 'Date',
      dataField: 'createDate',
      sort: true,
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
          {moment(item.createDate).format(DATETIME_FORMAT)}
        </Typography>
      ),
      align: 'left',
    },
    {
      caption: 'Supplier',
      dataField: 'supplier',
      sort: true,
      align: 'right',
    },
  ],
  noItemsFoundText: 'No reports found',
  initialSort: {
    sortField: 'date',
    sortDirection: 'desc',
  },
};

export { TABLE_CONFIG as default };
