import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { GlobalActionAdd, InspectionProjectStageConstruction, InspectionProjectStageOm } from 'assets/icons/index';
import { createCrumb, setShowHeader, setTitle } from 'modules/app/components/app/app.actions';
import { GUTTER_SIZE, CONSTRUCTION_TYPE, OM_TYPE } from 'modules/app/config/config';
import FloatingElement from 'modules/common/components/floatingElement/floatingElement.container';
import Loader from 'modules/common/components/loader/loader.component';
import InspectionItem from 'modules/inspections/components/inspectionItem/inspectionItem.component';
import { getInspections } from 'modules/inspections/components/inspections/inspections.actions';
import Acl, { PRIVILEGE_KEYS } from 'services/acl';

import { Box, Fab, Icon, Typography, Drawer, Grid } from '@material-ui/core';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    addButton: {
      position: 'fixed',
      backgroundColor: theme.palette.secondary.light,
      bottom: GUTTER_SIZE,
      right: GUTTER_SIZE,
    },
    addIcon: {
      marginLeft: -6,
      marginRight: 6,
    },
    drawerIcon: {
      color: theme.palette.text.secondary,
      fontSize: 45,
      marginBottom: 8,
    },
  });

const Inspections = ({ classes }: WithStyles<typeof styles>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { params, path } = useRouteMatch();
  const [addDrawer, setAddDrawer] = useState(false);
  const { isLoading, items: inspections } = useSelector((state: IStore) => state.inspections);

  const canCreateInspection = Acl.hasPrivilege(PRIVILEGE_KEYS.INSPECTIONS_CREATE);

  const title = `My inspections${
    ['development', 'test'].includes(process.env.NODE_ENV) ? ` - ${process.env.NODE_ENV}` : ''
  } `;

  useEffect(() => {
    dispatch(setShowHeader(true));
    dispatch(setTitle(title));
  }, [title]);

  useEffect(() => {
    dispatch(createCrumb(path, params));

    dispatch(getInspections());
  }, []);

  const handleConstructionClick = () => {
    history.push('/newInspection', { type: CONSTRUCTION_TYPE });
  };

  const handleOMClick = () => {
    history.push('/newInspection', { type: OM_TYPE });
  };

  const toggleAddDrawer = () => {
    setAddDrawer(!addDrawer);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box id="inspection-list" className="inspections-list-component flex-fill">
      {inspections.map((inspection, index: number) => (
        <InspectionItem className="mt-3 ml-3 mr-3" key={inspection.id} inspection={inspection} index={index} />
      ))}
      {canCreateInspection && (
        <FloatingElement
          render={(floatingClasses) => (
            <Fab
              size="medium"
              variant="extended"
              color="secondary"
              puppet-data="add-inspection-mobile"
              className={`${floatingClasses} ${classes.addButton}`}
              onClick={toggleAddDrawer}
              id="add-inspection:button"
            >
              <Icon className={`${classes.addIcon} svg-medium`}>
                <GlobalActionAdd className="icon-light" />
              </Icon>
              INSPECTION
            </Fab>
          )}
        />
      )}
      <Drawer anchor="bottom" open={addDrawer} onClose={toggleAddDrawer}>
        <Box className="add-drawer-container">
          <Grid container>
            <Typography>New Inspection</Typography>
          </Grid>
          <Grid container>
            <Box
              className="d-flex flex-column flex-fill align-items-center py-4"
              onClick={handleConstructionClick}
              puppet-data="add-inspection:construction"
              onKeyPress={handleConstructionClick}
              role="button"
              id="new-construction-inspection:div"
              tabIndex={0}
            >
              <Icon className={`${classes.drawerIcon} svg-extra-large`}>
                <InspectionProjectStageConstruction className="icon-dark" />
              </Icon>
              <Typography variant="caption">Construction</Typography>
            </Box>
            <Box
              className="d-flex flex-column flex-fill align-items-center py-4"
              onClick={handleOMClick}
              puppet-data="add-inspection:om"
              onKeyPress={handleOMClick}
              role="button"
              id="new-construction-inspection:div"
              tabIndex={0}
            >
              <Icon className={`${classes.drawerIcon} svg-extra-large`}>
                <InspectionProjectStageOm className="icon-dark" />
              </Icon>
              <Typography variant="caption">O&M</Typography>
            </Box>
          </Grid>
        </Box>
      </Drawer>
    </Box>
  );
};

export default withStyles(styles)(Inspections);
