import { connect } from 'react-redux';

import { createCrumb } from 'modules/app/components/app/app.actions';

import { getUsers, clearData } from './users.actions';
import AdminUsersWeb from './users.component';

const mapStateToProps = (state) => ({
  isGlobalAdmin: state.auth.user.isGlobalAdmin,
  isLoading: state.users.isLoading,
  isUserAdding: state.users.isUserAdding,
  totalCount: state.users.totalCount,
  userRoles: state.auth.user.roles,
  users: state.users.items,
});

const mapDispatchToProps = {
  getUsers,
  clearData,
  createCrumb,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersWeb);
