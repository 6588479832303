import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createCrumb } from 'modules/app/components/app/app.actions';
import Table, { TableUniqueKey } from 'modules/common/components/table';
import { shape } from 'prop-types';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  clearChecklistToEdit,
  getChecklists,
  getWorkPackages,
  getWorkPackagesForAdmin,
  setCurrentWbsInStore,
} from './checklistEditor.actions';
import { TABLE_CONFIG } from './checklists.config';
import ChecklistsDuplicateModal from './checklistsDuplicateModal.component';

const ChecklistsPage = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentWbs, setCurrentWbs] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const { isLoading, checklists, wbsesForAdmin } = useSelector((state) => state.checklistEditor);
  const isGlobalAdmin = useSelector((state) => state.auth.user.isGlobalAdmin);

  useEffect(() => {
    dispatch(createCrumb(match.path, match.params));
    if ((wbsesForAdmin?.length === 0 && !isGlobalAdmin) || (!wbsesForAdmin && !isGlobalAdmin)) {
      dispatch(getWorkPackagesForAdmin());
    }

    if (isGlobalAdmin) {
      dispatch(getWorkPackages());
    }

    dispatch(getChecklists());
  }, [dispatch, match.params, match.path, isGlobalAdmin, wbsesForAdmin]);

  useEffect(() => {
    if (currentWbs) {
      dispatch(getChecklists(currentWbs));
    }
    if (currentWbs === null) {
      dispatch(getChecklists());
    }
  }, [currentWbs, dispatch]);

  const handleChange = (event) => {
    setCurrentWbs(event.target.value);
    dispatch(setCurrentWbsInStore(event.target.value));
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const onRowClick = (e, item) => {
    history.push(`/admin/checklists/${item.id}`, { item });
  };

  const handleAddChecklist = () => {
    dispatch(clearChecklistToEdit());
    history.push('/admin/newChecklist', { currentWbs });
  };

  const renderWorkPackages = wbsesForAdmin?.map((wp) => {
    return (
      <MenuItem key={wp.id} value={wp.id}>
        {wp.description} - {wp.name}
      </MenuItem>
    );
  });

  const statusOptions = ['Active', 'Draft', 'Deactivated'];

  const renderStatus = statusOptions.map((status) => {
    return (
      <MenuItem key={status} value={status}>
        {status}
      </MenuItem>
    );
  });

  const renderAccordion = wbsesForAdmin
    ?.filter((park) => !currentWbs || park.id === currentWbs)
    ?.map((park) => {
      let checklistsForPark = checklists?.filter((checklist) => checklist.park.id === park.id);

      if (statusFilter) {
        checklistsForPark = checklistsForPark.filter(
          (checklist) => checklist.status.toLowerCase() === statusFilter.toLowerCase(),
        );
      }

      return (
        <Accordion key={park.id}>
          <AccordionSummary aria-controls="panel2a-content" id="panel2a-header" expandIcon={<ExpandMoreIcon />}>
            <Typography>{park.description}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table
              isChecklistTable
              isLoading={isLoading}
              items={checklistsForPark || []}
              config={TABLE_CONFIG}
              onRowClick={onRowClick}
              similarFiltersFrom={{ id: 1 }}
              tableUniqueKey={TableUniqueKey.CHECKLISTS}
            />
          </AccordionDetails>
        </Accordion>
      );
    });

  return (
    <>
      <ChecklistsDuplicateModal />
      <div className="row no-gutters align-items-start pr-5 pt-2 pb-2 justify-content-between">
        <Fab
          onClick={handleAddChecklist}
          size="medium"
          variant="extended"
          color="primary"
          className="position-relative my-3 mr-3"
        >
          Add checklist {currentWbs && `to ${wbsesForAdmin.find((p) => p.id === currentWbs).description}`}
        </Fab>
        <div className="d-flex flex-wrap justify-content-end mt-2">
          <div className="ml-2 mb-2">
            <FormControl>
              <InputLabel>Filter by park</InputLabel>
              <Select className="park-filter-select" onChange={handleChange} value={currentWbs || ''}>
                <MenuItem value={null}>All</MenuItem>
                {renderWorkPackages}
              </Select>
            </FormControl>
          </div>
          <div className="ml-2 mb-2">
            <FormControl>
              <InputLabel>Status</InputLabel>
              <Select className="status-filter-select" onChange={handleStatusChange} value={statusFilter || ''}>
                <MenuItem value={null}>All</MenuItem>
                {renderStatus}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="mt-3">{renderAccordion}</div>
    </>
  );
};

ChecklistsPage.propTypes = {
  match: shape({}).isRequired,
};

export default ChecklistsPage;
