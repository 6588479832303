import React from 'react';

const LogoHorisontal = (props) => (
  <svg viewBox="0 0 160 24.5" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M147.8 0c-6.8 0-12.2 5.5-12.2 12.2h24.5C160 5.5 154.5 0 147.8 0" fill="#ffda00" />
      <path d="M147.8 24.5c-6.8 0-12.2-5.5-12.2-12.2h24.5c-.1 6.7-5.6 12.2-12.3 12.2" fill="#2071b5" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#4e4b48"
      d="M118.4 19.6h10.1v-3.1h-6.4V5.6h-3.7zm-11.7 0h10.1v-3.1h-6.4V5.6h-3.7zM96 14.3l1.9-4.8 1.9 4.8H96zm6 5.3h3.5l-5.8-14h-3.5l-5.8 14h3.5l1-2.5h6.2l.9 2.5zm-21.2 0h3.7v-5.7h5.9V11h-5.9V8.6h7v-3H80.8zm-2.8 0v-14h-3.4V14l-6.3-8.4h-3.5v14h3.5v-8.3l6.3 8.3zm-26 0h10.3v-3h-6.7v-2.7h5.7v-3h-5.7V8.6h6.7v-3H52zm-13.2-14v3h3.7v11h3.6v-11h4.1v-3zm-1.3 0H26.2v3h4v11h3.7v-11h3.6zm-19.1 8.7l1.9-4.8 1.9 4.8h-3.8zm6 5.3H28l-5.8-14h-3.5l-5.8 14h3.5l1-2.5h6.2l.8 2.5zm-12.9-14l-3.9 9.9h-.1l-4-9.9H0l5.7 14h3.6l5.8-14z"
    />
  </svg>
);

export default LogoHorisontal;
