import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  checkIcon: {
    fill: theme.palette.secondary.light,
  },
  button: {
    color: theme.palette.secondary.light,
  },
}));

interface SaveHeaderWebProps {
  addIconButton?: React.ReactNode;
  handleNextFinding?: () => void;
  handlePreviousFinding?: () => void;
  isEdit?: boolean;
  isLoading?: boolean;
  nextFindingDisabled?: boolean;
  onClose?: () => void;
  onSave?: (...args: any[]) => void;
  prevFindingDisabled?: boolean;
  disabled?: boolean;
  title: string;
  withSave?: boolean;
  inspectionId?: string;
}

const SaveHeaderWeb: FC<SaveHeaderWebProps> = ({
  addIconButton,
  handleNextFinding,
  handlePreviousFinding,
  isEdit,
  isLoading,
  nextFindingDisabled,
  onClose,
  onSave,
  prevFindingDisabled,
  disabled = false,
  title = '',
  withSave = true,
  inspectionId,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleCloseClick = () => {
    if (inspectionId) {
      history.replace(`/inspectionDetails/${inspectionId}`);
    }

    if (onClose) {
      onClose();
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <Toolbar className="save-header-web-component p-0 grow">
        <Typography variant="h5" color="textPrimary" className="title" noWrap>
          {title}
        </Typography>

        {addIconButton}

        <div className="grow" />
        <Button
          id="close:button"
          className="left-button"
          puppet-data="close:button"
          aria-label="Close"
          onClick={handleCloseClick}
          disabled={isLoading}
        >
          {withSave ? 'Cancel' : 'Close'}
        </Button>
        {withSave && (
          <Button
            id="save:button"
            puppet-data="save:button"
            className={`right-button ${classes.button}`}
            aria-label="Save"
            onClick={onSave}
            disabled={isLoading || disabled}
          >
            Save
          </Button>
        )}
      </Toolbar>
      {isEdit && (
        <>
          <Button disabled={prevFindingDisabled} onClick={handlePreviousFinding}>
            Previous
          </Button>

          <Button disabled={nextFindingDisabled} onClick={handleNextFinding}>
            Next
          </Button>
        </>
      )}
    </>
  );
};

export default SaveHeaderWeb;
