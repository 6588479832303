import React, { memo } from 'react';

import { WORKFLOWS_TYPES } from 'modules/app/config/config';

import { Typography } from '@material-ui/core';

import { AREA_OPTIONS, SERIAL_DEFECTS, SEVERITY_OPTIONS } from '../newWorkflow/newWorkflow.model';

const workflowDetailsFields = memo(({ workflow }) => (
  <div className="row pt-3 pb-3">
    {workflow.siteFacility && (
      <div className="col-md-4 pb-3">
        <Typography color="textSecondary" variant="body2">
          {workflow.type === WORKFLOWS_TYPES.defect_notification ? 'Contractor' : 'Site / Facility'}
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {workflow.siteFacility}
        </Typography>
      </div>
    )}

    {workflow.wbs && (
      <div className="col-md-4 pb-3">
        <Typography color="textSecondary" variant="body2">
          Work Package
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {workflow.wbs}
        </Typography>
      </div>
    )}

    {workflow.mainSystem && (
      <div className="col-md-4 pb-3">
        <Typography color="textSecondary" variant="body2">
          Main System
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {workflow.mainSystem}
        </Typography>
      </div>
    )}

    {workflow.mainSystems && workflow.mainSystems.length > 0 && (
      <div className="col-md-4 pb-3">
        <Typography color="textSecondary" variant="body2">
          Related Main Systems
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {workflow.mainSystems.map((ms) => ms.description).join(', ')}
        </Typography>
      </div>
    )}

    {workflow.subSystem && (
      <div className="col-md-4 pb-3">
        <Typography color="textSecondary" variant="body2">
          Sub System
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {workflow.subSystem}
        </Typography>
      </div>
    )}

    {workflow.reference && (
      <div className="col-md-4 pb-3">
        <Typography color="textSecondary" variant="body2">
          Reference
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {workflow.reference}
        </Typography>
      </div>
    )}

    {workflow.legalEntity && (
      <div className="col-md-4 pb-3">
        <Typography color="textSecondary" variant="body2">
          Legal Entity
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {workflow.legalEntity}
        </Typography>
      </div>
    )}

    {workflow.type === WORKFLOWS_TYPES.defect_notification && (
      <div className="col-md-4 pb-3">
        <Typography color="textSecondary" variant="body2">
          Serial defect
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {workflow.serialDefect ? SERIAL_DEFECTS[0].label : SERIAL_DEFECTS[1].label}
        </Typography>
      </div>
    )}

    {workflow.defectLiabilityFrom && workflow.defectLiabilityTo && (
      <div className="col-md-4 pb-3">
        <Typography color="textSecondary" variant="body2">
          Defect liability period (from oldest to latest*)
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {`from ${workflow.defectLiabilityFrom} to ${workflow.defectLiabilityTo}`}
        </Typography>
      </div>
    )}

    {workflow.createdBy && (
      <div className="col-md-4 pb-3">
        <Typography color="textSecondary" variant="body2">
          Reported by
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {workflow.createdBy}
        </Typography>
      </div>
    )}

    {workflow.location && (
      <div className="col-md-4 pb-3 break-word">
        <Typography color="textSecondary" variant="body2">
          Location of Finding
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {workflow.location}
        </Typography>
      </div>
    )}

    {workflow.failureModeDetailsObj && workflow.failureModeDetailsObj.description && (
      <div className="col-md-4 pb-3">
        <Typography color="textSecondary" variant="body2">
          Failure Mode
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {workflow.failureModeDetailsObj.description}
        </Typography>
      </div>
    )}

    {workflow.type === WORKFLOWS_TYPES.defect_notification && (
      <div className="col-md-4 pb-3">
        <Typography color="textSecondary" variant="body2">
          Area
        </Typography>
        <Typography color="textPrimary" variant="body1">
          {`${workflow.area ? `${AREA_OPTIONS.get(workflow.area)?.value} -` : ''} ${
            AREA_OPTIONS.get(workflow.area)?.label
          }`}
        </Typography>
      </div>
    )}

    {workflow.description && (
      <div className="col-md-8 pb-3 break-word">
        <Typography color="textSecondary" variant="body2">
          Description
        </Typography>

        <Typography color="textPrimary" variant="body1">
          {workflow.description}
        </Typography>
      </div>
    )}

    {workflow.type === WORKFLOWS_TYPES.defect_notification && (
      <div className="col-md-4 pb-3">
        <Typography color="textSecondary" variant="body2">
          Severity
        </Typography>
        <Typography color="textPrimary" variant="body1">
          {SEVERITY_OPTIONS.get(workflow.severity)?.label}
        </Typography>
      </div>
    )}

    {(workflow.referenceDocument || workflow.serialNumber) && (
      <div className="col-md-4 break-word">
        {workflow.referenceDocument && (
          <>
            <Typography color="textSecondary" variant="body2" className="pt-3">
              Reference Number
            </Typography>

            <Typography color="textPrimary" variant="body1">
              {workflow.referenceDocument}
            </Typography>
          </>
        )}

        {workflow.serialNumber && (
          <>
            <Typography color="textSecondary" variant="body2" className="pt-3">
              Serial Number / ID
            </Typography>
            <Typography color="textPrimary" variant="body1">
              {workflow.serialNumber}
            </Typography>
          </>
        )}
      </div>
    )}
  </div>
));

export default workflowDetailsFields;
