import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import _orderBy from 'lodash/orderBy';
import _set from 'lodash/set';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import {
  FILE_PARENTS,
  WORKFLOW_ACTION_TYPES,
  WORKFLOWS_DIALOG_TYPES,
  WORKFLOWS_TYPES,
} from 'modules/app/config/config';
import DropdownMenu from 'modules/common/components/dropdownMenu/dropdownMenu.component';
import FileUploadZone from 'modules/common/components/fileUpload/fileUpload.component';
import FileListNames from 'modules/common/components/filesList/FileListNames';
import { removeAttachmentsWeb, saveAttachmentsWeb } from 'modules/common/components/filesList/filesList.actions';
import SaveHeaderWorkflow from 'modules/common/components/saveHeader/saveHeaderWorkflowWeb.component';
import ChangeDueDate from 'modules/workflows/components/changeDueDate/changeDueDate.component';
import ReassignUser from 'modules/workflows/components/reassignUser/reassignUser.component';
import WorkflowImageSelector from 'modules/workflows/components/workflowImageSelector/workflowImageSelector.component';
import moment from 'moment';
import { arrayOf, func, shape, string } from 'prop-types';
import Notifications from 'services/notifications';

import { Fab, Tooltip, Typography } from '@material-ui/core';

import DynamicFormDetails from '../dynamicFormDetails/dynamicFormDetails.component';
import { createPart, makeTransition, rollbackWorkflow } from './dynamicForm.actions';
import DynamicFormModel from './dynamicForm.model';
import DynamicFormInput from './dynamicFormItem.component';

export const assigneeData = (assignee) => {
  if (assignee) {
    const toAssingee =
      assignee.firstName && assignee.lastName
        ? ` ${assignee.firstName} ${assignee.lastName} (${assignee.userRole})`
        : assignee.userRole;
    return `To: ${toAssingee}`;
  }
  return '';
};

const WorkflowActionButtons = ({
  currentActionTakerRole,
  workflowType,
  name,
  workflowDetails,
  getWorkflow,
  setIsLoading,
  actions,
  onTransitionClick,
}) => {
  const isContractor = currentActionTakerRole === 'CONTRACTOR';
  const allowToChangeDue = workflowDetails.type === WORKFLOWS_TYPES.observation && !isContractor;
  const showDropdownOptions = workflowType === WORKFLOWS_TYPES.nonconformity && !isContractor;
  const actionButtons = actions.map((action) => (
    <Tooltip key={action.targetPart} title={assigneeData(action.assignee)}>
      <Fab
        id="start-workflow:button"
        className="m-3 align-self-end"
        size="medium"
        variant="extended"
        color="primary"
        onClick={onTransitionClick}
        data-action={action.targetPart}
      >
        {action.label}
      </Fab>
    </Tooltip>
  ));

  if (!actionButtons.length) {
    return null;
  }

  return (
    <>
      <div className="row no-gutters justify-content-between py-3">
        <div className="col-md-8">
          <Typography className="title" color="textPrimary" variant="body1">
            {name}
          </Typography>
        </div>
        <div className="col-md-4">
          <div className="row no-gutters align-items-center justify-content-end">
            {showDropdownOptions && (
              <DropdownMenu>
                {allowToChangeDue && <ChangeDueDate workflowId={workflowDetails.id} />}
                {!isContractor && (
                  <ReassignUser
                    workflowDetails={workflowDetails}
                    getWorkflow={getWorkflow}
                    setIsLoading={setIsLoading}
                  />
                )}
              </DropdownMenu>
            )}
          </div>
        </div>
      </div>
      <div className="row no-gutters justify-content-end">{actionButtons}</div>
    </>
  );
};

WorkflowActionButtons.propTypes = {
  currentActionTakerRole: string.isRequired,
  workflowType: string.isRequired,
  name: string.isRequired,
  workflowDetails: shape({}).isRequired,
  getWorkflow: func.isRequired,
  setIsLoading: func.isRequired,
  actions: arrayOf(shape({})),
  onTransitionClick: func.isRequired,
};

WorkflowActionButtons.defaultProps = {
  actions: [],
};

class DynamicForm extends PureComponent {
  fileListNames = new FileListNames();

  constructor(props) {
    super(props);

    const {
      workflowDetails: {
        currentPart: { attachments, fields, saved },
      },
    } = props;
    this.createDataModel(fields);

    this.state = {
      errors: {},
      open: !saved,
      reset: '',
      oldFiles: attachments,
      files: attachments,
      filesToRemove: [],
      modalOnSend: '',
    };
  }

  componentDidUpdate({
    workflowDetails: {
      currentPart: { id: prevId, saved: prevSaved },
    },
  }) {
    const {
      workflowDetails: {
        currentPart: { id: currId, saved: currSaved, fields },
      },
    } = this.props;

    if (prevSaved !== currSaved) {
      this.setState({
        open: !currSaved,
      });
    }

    if (prevId !== currId) {
      this.createDataModel(fields);
    }
  }

  createDataModel = (fields) => {
    this.formData = fields.reduce((reduced, { id, value, disabled }) => {
      if (disabled) {
        return reduced;
      }

      return _set(reduced, id, value);
    }, {});
    this.model = new DynamicFormModel(fields);
  };

  onTransitionClick = async (ev) => {
    const { currentTarget } = ev;
    const {
      workflowDetails: {
        id,
        currentPart: { actions },
      },
      setIsLoading,
      history,
    } = this.props;
    const targetPart = currentTarget.getAttribute('data-action');
    const action = actions.find((actionItem) => actionItem.targetPart === targetPart);

    if (action.isTransitiveAction) {
      setIsLoading(true);
      await this.onMakeTransition(action);
      this.goToBottom();
      setIsLoading(false);
    } else {
      // TODO as for now we have only one such action hardcoded
      history.push(`/workflow/nonconformity/copy/${id}`);
    }
  };

  onSendClick = async () => {
    const {
      generateNCReport,
      workflowDetails: {
        id,
        currentPart: { actions },
        workflowHistory,
        attachments,
      },
      setIsLoading,
    } = this.props;
    const { SHOW_IMAGE_SELECTOR_POPUP } = WORKFLOWS_DIALOG_TYPES;
    const actionDetails = actions.map((action) => ({
      actionType: action.action,
      actionEndpoint: action.actionEndpoint,
    }));
    const isSelectorPopUP = actionDetails.find((item) => item.actionType === SHOW_IMAGE_SELECTOR_POPUP);

    const actionTypes = actions.map((action) => action.action);
    const attachmentsAvailableInHistory = workflowHistory.filter((item) => item.attachments.length > 0).length;

    const errors = this.model.validateSchema(this.formData);

    if (
      actionTypes.includes(SHOW_IMAGE_SELECTOR_POPUP) &&
      (!!attachmentsAvailableInHistory || !!attachments.length) &&
      !errors
    ) {
      this.setState({
        modalOnSend: SHOW_IMAGE_SELECTOR_POPUP,
      });
      return;
    }

    try {
      if (actionTypes.includes(SHOW_IMAGE_SELECTOR_POPUP) && !errors) {
        await generateNCReport(id, attachments, isSelectorPopUP.actionEndpoint);
      }
      const result = await this.handleSubmit(false);

      if (result && actions[0].isTransitiveAction) {
        await this.onMakeTransition(actions[0]);
      }
    } catch (err) {
      genericErrorHandler(err);
    }
    setIsLoading(false);
  };

  onRollbackClick = async () => {
    const { setIsLoading } = this.props;

    try {
      this.rollbackWorkflow();
    } catch (err) {
      setIsLoading(false);
      genericErrorHandler(err);
    }
  };

  onFormRollbackClick = async () => {
    const { setIsLoading } = this.props;

    try {
      const {
        workflowDetails: {
          currentPart: { saved, rollbackAllowed },
        },
      } = this.props;

      if (!saved || (saved && rollbackAllowed)) {
        await this.rollbackWorkflow();
      }

      if (saved && !rollbackAllowed) {
        await this.reloadWorkflow();
      }
    } catch (err) {
      setIsLoading(false);
      genericErrorHandler(err);
    }
  };

  rollbackWorkflow = async () => {
    const {
      match: { params },
      getWorkflow,
      setIsLoading,
    } = this.props;

    setIsLoading(true);
    await rollbackWorkflow(params.workflowId);
    await getWorkflow(params.workflowId);
    setIsLoading(false);
  };

  reloadWorkflow = async () => {
    const {
      match: { params },
      getWorkflow,
    } = this.props;

    const workflow = await getWorkflow(params.workflowId);
    const {
      currentPart: { fields },
    } = workflow;

    this.createDataModel(fields);

    this.setState({
      reset: moment().format('x'),
    });
  };

  onMakeTransition = async (action) => {
    try {
      const {
        match: { params },
        getWorkflow,
      } = this.props;

      await makeTransition({
        targetPart: action.targetPart,
        workflowId: params.workflowId,
      });
      await getWorkflow(params.workflowId);
    } catch (err) {
      genericErrorHandler(err);
    }
  };

  openEdit = () => {
    this.setState({
      open: true,
    });
  };

  closeEdit = () => {
    this.setState({
      open: false,
      errors: {},
    });
  };

  changeFieldValue = (name, value) => {
    this.formData[name] = value;
  };

  handleSubmitWithModal = async (attachments = []) => {
    const {
      workflowDetails: {
        id,
        currentPart: { actions },
      },
      setIsLoading,
      generateNCReport,
    } = this.props;

    const { SHOW_IMAGE_SELECTOR_POPUP } = WORKFLOWS_DIALOG_TYPES;
    const actionTypes = actions.map((action) => ({
      actionType: action.action,
      actionEndpoint: action.actionEndpoint,
    }));
    const isSelectorPopUP = actionTypes.find((item) => item.actionType === SHOW_IMAGE_SELECTOR_POPUP);

    if (isSelectorPopUP) {
      try {
        // TODO: ADD DISPATCH WITH ATTACHMENTS;
        await generateNCReport(id, attachments, isSelectorPopUP.actionEndpoint);
      } catch (e) {
        console.log('err -', e);
      }
    }
    this.closeModal();
    try {
      const result = await this.handleSubmit(false);

      if (result && actions[0].isTransitiveAction) {
        await this.onMakeTransition(actions[0]);
      }
    } catch (err) {
      genericErrorHandler(err);
    }
    setIsLoading(false);
  };

  handleSubmit = async (getWorkflowDetails = true) => {
    try {
      const {
        getWorkflow,
        workflowDetails: {
          currentPart: { id: currentPartId },
          id,
        },
        setIsLoading,
      } = this.props;
      const { files, filesToRemove } = this.state;

      const errors = this.model.validateSchema(this.formData);

      if (errors || this.deadlineError) {
        Notifications.showError({ code: 999 });

        return this.setState({
          errors,
          open: true,
        });
      }

      setIsLoading(true);

      await createPart(currentPartId, this.formData);

      // upload / delete files based on previous files
      const filesToUpload = files.filter((file) => !file.workflowPartId);

      if (filesToUpload.length > 0) {
        try {
          await saveAttachmentsWeb(filesToUpload, currentPartId, FILE_PARENTS.workflowPart, this.fileListNames);
        } catch (e) {
          genericErrorHandler(e);
        }
      }

      if (filesToRemove.length > 0) {
        try {
          await removeAttachmentsWeb(filesToRemove, currentPartId, FILE_PARENTS.workflowPart);
        } catch (e) {
          genericErrorHandler(e);
        }
      }

      if (getWorkflowDetails) {
        await getWorkflow(id);
        this.goToBottom();
      }

      this.closeEdit();

      return true;
    } catch (err) {
      genericErrorHandler(err);
      return false;
    }
  };

  deleteFile = (id) => {
    const { files, filesToRemove, oldFiles } = this.state;

    const newFiles = files.filter((file) => file.id !== id);
    const file = oldFiles.find((oldFile) => oldFile.id === id);

    if (file && file.workflowPartId) {
      filesToRemove.push(id);
    }

    this.setState({
      files: newFiles,
      filesToRemove,
    });
  };

  setFiles = (files) => {
    this.setState({ files });
  };

  closeModal = () => {
    this.setState({
      modalOnSend: false,
    });
  };

  goToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  render() {
    const { open, errors, reset, files, modalOnSend } = this.state;

    const {
      workflowDetails: {
        currentPart: { actions, fields, name, type, rollbackAllowed, saved },
        currentActionTakerRole,
        id,
        type: workflowType,
      },
      workflowDetails,
      history,

      getWorkflow,
      setIsLoading,
      callbackFn,
    } = this.props;

    const { SHOW_IMAGE_SELECTOR_POPUP } = WORKFLOWS_DIALOG_TYPES;
    const renderFormFields = () =>
      _orderBy(fields, 'orderPosition').map((formItem) => (
        <DynamicFormInput
          key={formItem.id}
          config={formItem}
          value={this.formData[formItem.id] || formItem.value || null}
          changeFieldValue={this.changeFieldValue}
          errors={errors}
          reset={reset}
        />
      ));

    const informationIsOpen = open && fields.length;
    const informationIsClosed = !open && workflowDetails.id;

    return (
      <>
        {type === WORKFLOW_ACTION_TYPES.information && informationIsOpen && (
          <div className="my-3 p-3 shadow rounded">
            <div id="dynamic-form:div" className="content-wrapper row no-gutters p-0 flex-column">
              <SaveHeaderWorkflow
                title={name}
                onSave={this.handleSubmit}
                onClose={!rollbackAllowed && !saved ? null : this.onFormRollbackClick}
                onSend={this.onSendClick}
                history={history}
                saved={saved}
                rollbackAllowed={rollbackAllowed}
                assignee={actions.length && actions[0].assignee}
                nextActionLabel={actions.length && actions[0].label}
                customConfirmation={actions.length && actions[0].confirmationText}
              />
              <div className="row no-gutters">{renderFormFields()}</div>
              <FileUploadZone setFiles={this.setFiles} files={files} handleDelete={this.deleteFile} />
            </div>
          </div>
        )}

        {type === WORKFLOW_ACTION_TYPES.information && informationIsClosed && (
          <DynamicFormDetails
            data={[
              {
                ...workflowDetails.currentPart,
                actionTakerRole: workflowDetails.currentPart.actionTakerRole
                  ? workflowDetails.currentPart.actionTakerRole
                  : currentActionTakerRole,
              },
            ]}
            workflowId={id}
            onEdit={this.openEdit}
            onSend={this.onSendClick}
            onRollback={rollbackAllowed ? this.rollbackWorkflow : null}
            saved={saved}
            workflowDetails={workflowDetails}
            getWorkflow={getWorkflow}
            setIsLoading={setIsLoading}
            callbackFn={callbackFn}
            canEdit
          />
        )}
        {type === WORKFLOW_ACTION_TYPES.action && (
          <WorkflowActionButtons
            currentActionTakerRole={currentActionTakerRole}
            workflowType={workflowType}
            name={name}
            workflowDetails={workflowDetails}
            getWorkflow={getWorkflow}
            setIsLoading={setIsLoading}
            actions={actions}
            onTransitionClick={this.onTransitionClick}
          />
        )}
        {workflowType === WORKFLOWS_TYPES.nonconformity && (
          <WorkflowImageSelector
            opened={modalOnSend === SHOW_IMAGE_SELECTOR_POPUP}
            closeCallback={this.closeModal}
            workflowDetails={workflowDetails}
            submitCallback={this.handleSubmitWithModal}
          />
        )}
      </>
    );
  }
}

DynamicForm.propTypes = {
  workflowDetails: shape({}).isRequired,
  getWorkflow: func.isRequired,
  history: shape({}).isRequired,
  setIsLoading: func.isRequired,
  callbackFn: func,
};

DynamicForm.defaultProps = {
  callbackFn: null,
};

export default withRouter(DynamicForm);
