import { connect } from 'react-redux';

import { createCrumb, registerSync } from 'modules/app/components/app/app.actions';
import { getInspection } from 'modules/inspections/components/inspectionDetails/inspectionDetails.actions';

import { getFinding, clearData, removeFinding } from './findingDetails.actions';

const mapStateToProps = (state) => ({
  finding: state.finding.data,
  inspection: state.inspection.data,
  isLoading: state.finding.isLoading,
});

const mapDispatchToProps = {
  getFinding,
  removeFinding,
  clearData,
  registerSync,
  createCrumb,
  getInspection,
};

const withFindingDetails = (FindingDetailsComponent) =>
  connect(mapStateToProps, mapDispatchToProps)(FindingDetailsComponent);

export default withFindingDetails;
