import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import SearchUsersModal from 'modules/common/components/usersSearchModal/usersSearchModal.component';
import { searchUser, reassignUser } from 'modules/workflows/components/changeParticipant/changeParticipant.actions';
import PropTypes from 'prop-types';

import { MenuItem, Icon } from '@material-ui/core';

class ReassignUser extends PureComponent {
  searchUsers = () => {
    try {
      const {
        workflowDetails: {
          currentActionTakerRole,
          wbsDetails: { id },
          siteFacilityDetails: { id: siteFacilityId },
        },
      } = this.props;

      const additionalQueryParams = {
        role: currentActionTakerRole,
        excludeLoggedUser: true,
        ...(currentActionTakerRole === 'CONTRACTOR' && { siteFacilityId }),
      };

      return searchUser(id, additionalQueryParams);
    } catch (err) {
      genericErrorHandler(err);
    }
  };

  confirmContent = (data) => {
    const {
      workflowDetails: { identifier },
    } = this.props;

    return `You are about to assign ${identifier} to ${data.firstName}, ${data.lastName} (${data.email})`;
  };

  onReassignClick = async (e, data) => {
    const {
      match: { params },
      workflowDetails: {
        currentActionTakerRole,
        siteFacilityDetails: { id: siteFacilityId },
        currentPart: { id },
      },
      getWorkflow,
      setIsLoading,
    } = this.props;

    try {
      setIsLoading(true);

      const additionalQueryParams = {
        id,
        userId: data.id,
        ...(currentActionTakerRole === 'CONTRACTOR' && { siteFacilityId }),
      };

      await reassignUser(additionalQueryParams);
      await getWorkflow(params.workflowId);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      genericErrorHandler(err);
    }
  };

  render() {
    return (
      <SearchUsersModal
        confirmFn={this.onReassignClick}
        confirmIcon="swap_horiz"
        confirmLabel="Reassign"
        confirmTitle="Confirm reassignment of workflow"
        confirmContent={this.confirmContent}
        getUsers={this.searchUsers}
        searchOnOpen
        local
      >
        <MenuItem>
          <Icon className="mr-2">swap_horiz</Icon>
          Reassign User
        </MenuItem>
      </SearchUsersModal>
    );
  }
}

ReassignUser.propTypes = {
  getWorkflow: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  workflowDetails: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default withRouter(ReassignUser);
