import React, { useState } from 'react';

import { saveAs } from 'file-saver';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { INTERNAL_ERROR_CODE } from 'modules/app/config/config';
import Loader from 'modules/common/components/loader/loader.component';
import PropTypes from 'prop-types';

import { FormHelperText } from '@material-ui/core';

import FileListNames from '../filesList/FileListNames';
import FileDropzone from '../filesList/fileDropzone.component.tsx';
import FileRow from '../filesList/fileRow.component';
import { addAttachmentsToSave } from '../filesList/filesList.actions';

const OneFileUploader = ({ files, setFiles, handleDelete, error }) => {
  const [isLoading, setIsLoading] = useState(false);

  const fileListNames = new FileListNames();

  const onDrop = async (dropedFiles) => {
    try {
      setIsLoading(true);
      try {
        const newFiles = await addAttachmentsToSave([dropedFiles[0]], fileListNames);
        setFiles([...newFiles]);
        setIsLoading(false);
      } catch (e) {
        if (Array.isArray(e)) {
          const internalErrors = e.filter((i) => i.code === INTERNAL_ERROR_CODE);

          if (internalErrors.length && files.length !== internalErrors.length) {
            genericErrorHandler(e);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            throw e;
          }
        } else {
          setIsLoading(false);
          throw e;
        }
      }
    } catch (err) {
      genericErrorHandler(err);
      setIsLoading(false);
    }
  };

  const handleFileDelete = (_, params) => {
    const { id } = params;
    handleDelete(id);
  };

  const handleDownload = (e) => {
    try {
      const { currentTarget } = e;
      const title = currentTarget.getAttribute('data-title');
      const id = currentTarget.getAttribute('data-path');
      const fileToDownload = files.filter((file) => file.id === id)[0];

      saveAs(fileToDownload, title);
    } catch (err) {
      genericErrorHandler(err);
    }
  };

  return (
    <div className="row col-md-12 no-gutters py-3 attachments-component-web">
      <div className="col-md-12">
        <FileDropzone onDrop={onDrop} />
        {error && (
          <div className="d-flex justify-content-center">
            <FormHelperText error variant="filled">
              {error}
            </FormHelperText>
          </div>
        )}
      </div>

      {files.length > 0 && (
        <div className="col-md-12">
          {files.map((attachment) => (
            <FileRow
              key={attachment.id}
              attachment={attachment}
              canEdit
              handleDownload={handleDownload}
              onRemoveFile={handleFileDelete}
            />
          ))}
        </div>
      )}

      {isLoading && <Loader inline mask />}
    </div>
  );
};

OneFileUploader.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape).isRequired,
  setFiles: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  error: PropTypes.string,
};

OneFileUploader.defaultProps = {
  error: '',
};

export default OneFileUploader;
