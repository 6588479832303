import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import _get from 'lodash/get';
import { setShowHeader, createCrumb } from 'modules/app/components/app/app.actions';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { OM_TYPE, FILE_PARENTS, INSPECTION_STATUSES } from 'modules/app/config/config';
import { parseFileSize } from 'modules/app/helpers/utils';
import FileListNames from 'modules/common/components/filesList/FileListNames';
import { saveAttachmentsWeb, validateFiles } from 'modules/common/components/filesList/filesList.actions';
import Loader from 'modules/common/components/loader/loader.component';
import UnsavedChanges from 'modules/common/components/unsavedChanges/unsavedChanges.hoc';
import { handleElementChange } from 'modules/inspections/components/checklists/checklist.actions';
import {
  getInspection,
  validateFinding,
} from 'modules/inspections/components/inspectionDetails/inspectionDetails.actions';
import NewInspectionModel from 'modules/inspections/components/newInspection/newInspection.model';

import { MenuItem } from '@material-ui/core';

import { getFinding, clearData as clearFindingData } from '../findingDetails/findingDetails.actions';
import { saveFinding, updateFinding, setIsLoading } from './newFinding.actions';
import NewFindingModel, { FINDING_TYPES } from './newFinding.model';

const mapStateToProps = (state) => ({
  isLoadingEdit: state.finding.isLoading,
  isLoadingCreate: state.newFinding.isLoading,
  failureModes: state.newFinding.failureModes,
  severity: state.newFinding.severity,
  finding: state.finding.data,
  findings: state.inspection.inspectionFindings,
  inspection: state.inspection.data,
  functionalLocations: state.newInspection.functionalLocations,
  isMobile: state.app.isMobile,
});

const mapDispatchToProps = {
  setShowHeader,
  clearFindingData,
  saveFinding,
  updateFinding,
  getFinding,
  getInspection,
  createCrumb,
  setIsLoading,
  handleElementChange,
};

const withNewFinding = (NewFindingComponent) => {
  class NewFinding extends PureComponent {
    state = {
      newFinding: {},
      files: [],
      id: '',
    };

    fileListNames = new FileListNames();

    async componentDidMount() {
      const {
        setShowHeader,
        getFinding,
        match: { params },
        getInspection,
        isMobile,
      } = this.props;

      setShowHeader(false);
      if (isMobile) {
        await getInspection(params.inspectionId);
      }

      if (params.inspectionId && params.editFindingId) {
        await getFinding(params.inspectionId, params.editFindingId);
      }
    }

    componentDidUpdate(prevProps, prevState) {
      const {
        match: { path, params },
        createCrumb,
      } = this.props;

      if (prevProps.match.params.editFindingId !== params.editFindingId) {
        getFinding(params.inspectionId, params.editFindingId);
      }

      if (prevProps.finding !== this.props.finding) {
        this.setState({ newFinding: this.props.finding });
      }

      createCrumb(path, params);
    }

    componentWillUnmount() {
      const {
        setShowHeader,
        clearFindingData,
        finding: { identifier },
      } = this.props;

      if (identifier) {
        clearFindingData();
      }

      setShowHeader(true);
    }

    static getDerivedStateFromProps(props, state) {
      const {
        finding,
        inspection,
        match: { params },
      } = props;

      if ((!!params.editFindingId && (state.newFinding.id || state.newFinding._id)) || !params.editFindingId) {
        if (inspection && inspection.status === INSPECTION_STATUSES.finished) {
          const inspectionModel = new NewInspectionModel(inspection).parseDetails();
          const errors = new NewFindingModel(state.newFinding, inspectionModel).validateSave();

          return {
            errors: errors || {},
          };
        }

        return null;
      }

      return {
        newFinding: finding,
      };
    }

    setFiles = (files) => {
      this.setState({ files });
    };

    deleteFile = (id) => {
      this.setState((prevState) => ({
        files: prevState.files.filter((file) => file.id !== id),
      }));
    };

    isOM = () => {
      const { inspection } = this.props;
      return _get(inspection, 'type') === OM_TYPE;
    };

    // eslint-disable-next-line class-methods-use-this
    filterIncompleteFindings = (findings) => {
      const incompleteFindings = [];

      findings.forEach((finding, index) => {
        if (!validateFinding(finding)) {
          incompleteFindings.push({ id: finding.id, index });
        }
      });

      return incompleteFindings;
    };

    findNextIncompleteFindingId = (findingId, findings) => {
      const incomplete = this.filterIncompleteFindings(findings);

      if (!incomplete.length) {
        return null;
      }
      const index = findings.findIndex((finding) => finding.id === findingId);
      const nextFinding = incomplete.find((uf) => uf.index > index) || incomplete[0];

      return nextFinding.id;
    };

    handleSubmit = async () => {
      const {
        history,
        findings,
        location,
        saveFinding,
        updateFinding,
        match: { params },
        inspection,
        setDirty,
        setIsLoading,
        getInspection,
      } = this.props;
      setDirty(false);

      const { newFinding, files } = this.state;
      const { inspectionId } = params;

      const checklist = location.state && location.state.checklist;

      const inspectionModel = new NewInspectionModel(inspection).parseDetails();
      const newFindingModel = new NewFindingModel(newFinding, inspectionModel, checklist);
      const newErrors = newFindingModel.validateSave();
      const filesErrors = validateFiles(files);

      if (filesErrors.length) {
        genericErrorHandler(filesErrors);
        return;
      }

      if (newErrors) {
        this.setState({
          errors: newErrors,
        });
        return;
      }

      setIsLoading(true);

      try {
        const id = newFindingModel.id
          ? await updateFinding(inspectionId, newFindingModel)
          : await saveFinding(inspectionId, newFindingModel);

        // check if files are in state and add them to finding
        if (files.length > 0) {
          try {
            await saveAttachmentsWeb(files, id, FILE_PARENTS.finding, this.fileListNames);
          } catch (e) {
            genericErrorHandler(e);
          }
        }

        await getInspection(inspectionId);

        const locationState = {
          checklist: location?.state?.checklist,
          fromNewFinding: !newFinding.id,
        };

        setDirty(false);
        setIsLoading(false);

        if (!id) return;

        if (inspectionId && params.editFindingId) {
          clearFindingData();
        }

        if (inspection.status === INSPECTION_STATUSES.finished) {
          const nextFindingId = this.findNextIncompleteFindingId(id, findings);

          if (nextFindingId !== null && nextFindingId !== id) {
            history.replace(`/inspectionDetails/${inspectionId}/editFinding/${nextFindingId}`, locationState);
            return;
          }
        }

        history.replace(`/inspectionDetails/${inspectionId}/findingDetails/${id}`, locationState);
      } catch (err) {
        setIsLoading(false);
        genericErrorHandler(err);
      }
    };

    handleChange = (e) => {
      const {
        currentTarget: { name, value },
      } = e;

      this.changeFieldsValue([{ name, value }]);
    };

    handleChangeCheckbox = (e) => {
      const {
        currentTarget: { name, checked },
      } = e;
      this.changeFieldsValue([{ name, checked }]);
    };

    setFieldValue = (name, value) => {
      this.changeFieldsValue([{ name, value }]);
    };

    setSelectValue = (name) => (e) => {
      this.setFieldValue(name, e.target.value);
    };

    renderFindingTypes = (findingType) => {
      const { inspection } = this.props;

      let types = [];
      if (findingType === FINDING_TYPES.TODO.value && inspection.status !== INSPECTION_STATUSES.draft) {
        types = Object.entries(FINDING_TYPES).map(([, val]) => (
          <MenuItem
            key={val.value}
            value={val.value}
            className={`${val.value === FINDING_TYPES.TODO.value ? 'disabled' : ''}`}
          >
            {val.label}
          </MenuItem>
        ));
      } else {
        types = Object.entries(FINDING_TYPES)
          .filter(([, val]) => val.inspectionDraftOnly === (inspection.status === INSPECTION_STATUSES.draft))
          .map(([, val]) => (
            <MenuItem key={val.value} value={val.value}>
              {val.label}
            </MenuItem>
          ));
      }

      return types;
    };

    setMainSystemValue = async (name, value) => {
      this.changeFieldsValue([
        { name, value },
        { name: 'subSystem', value: '' },
      ]);
    };

    changeFieldsValue(values) {
      const { newFinding } = this.state;
      const { inspection, setDirty } = this.props;

      values.forEach((item) => {
        newFinding[item.name] = item.value;
      });

      const inspectionModel = new NewInspectionModel(inspection).parseDetails();
      const newFindingModel = new NewFindingModel(newFinding, inspectionModel);

      const stateUpdate = {
        newFinding: newFindingModel,
      };

      if (values.find((item) => item.name === 'findingType')) {
        const newErrors = newFindingModel.validateSave();
        stateUpdate.errors = newErrors;
      }

      this.setState(stateUpdate);

      setDirty();
    }

    onRegisterFindingClose = () => {
      const { location, history, match, isMobile } = this.props;
      const goBackUrl = `/inspectionDetails/${match.params.inspectionId}`;

      const isFromChecklist = Object.keys(location?.state?.checklist || {}).length > 0;

      if (isFromChecklist && isMobile) {
        history.push(goBackUrl, {
          checklist: location.state.checklists,
        });
      } else {
        history.push(goBackUrl);
      }
    };

    render() {
      const {
        inspection,
        match: { params },
        history: { location },
        isLoadingEdit,
        isLoadingCreate,
      } = this.props;

      let { newFinding } = this.state;
      const { errors, files } = this.state;
      const isCreate = !params.editFindingId;

      const id = params.editFindingId;

      if (!isCreate && (isLoadingCreate || isLoadingEdit)) {
        return <Loader />;
      }

      if (isCreate && (isLoadingCreate || !inspection || !newFinding)) {
        return <Loader />;
      }

      const checklist = location.state && location.state.checklist;

      const inspectionModel = new NewInspectionModel(inspection).parseDetails();
      newFinding = new NewFindingModel(newFinding, inspectionModel, checklist).parseDetails();

      const {
        deadlineError,
        deleteFile,
        handleChange,
        handleChangeCheckbox,
        handleSubmit,
        isConstruction,
        isOM,
        onRegisterFindingClose,
        renderFindingTypes,
        setDeadlineError,
        setFieldValue,
        setFiles,
        setMainSystemValue,
        setSelectValue,
      } = this;

      const props = {
        deadlineError,
        deleteFile,
        errors,
        files,
        getFinding,
        handleChange,
        handleChangeCheckbox,
        handleSubmit,
        id,
        inspection,
        inspectionModel,
        isConstruction,
        isCreate,
        isOM,
        newFinding,
        onRegisterFindingClose,
        parseFileSize,
        renderFindingTypes,
        setDeadlineError,
        setFieldValue,
        setFiles,
        setMainSystemValue,
        setSelectValue,
      };

      return <NewFindingComponent {...this.props} {...props} />;
    }
  }

  return UnsavedChanges(connect(mapStateToProps, mapDispatchToProps)(NewFinding));
};

export default withNewFinding;
