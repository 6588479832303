import _get from 'lodash/get';
import { registerSync } from 'modules/app/components/app/app.actions';
import { INSPECTION_STATUSES } from 'modules/app/config/config';
import { generateUId, sortTree } from 'modules/app/helpers/utils';
import Api from 'services/api';
import Db from 'services/db';

import NewInspectionModel, { INSPECTION_STRUCTURE } from './newInspection.model';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'NEW_INSPECTION';
const SET_CO_INSPECTORS = `${namespace}_SET_CO_INSPECTORS`;
const CLEAN_DATA = `${namespace}_CLEAN_DATA`;
const SET_WORK_PACKAGE = `${namespace}_SET_WORK_PACKAGE`;
const SET_FACILITIES = `${namespace}_SET_FACILITIES`;
const SET_FUNCTIONAL_LOCATIONS = `${namespace}_SET_FUNCTIONAL_LOCATIONS`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const SET_INSPECTORS = `${namespace}_SET_INSPECTORS`;

/*
 * REDUX ACTIONS
 */
const removeItem = (arr, item) => arr.filter((i) => i.id !== item.id);

const addItem = (arr, newItem) => [...arr, newItem];

const updateItem = (arr, newItem) =>
  arr.map((item) => {
    if (item.id === newItem.id) {
      return {
        ...item,
        ...newItem,
      };
    }

    return item;
  });

const saveCoInspector = (item) => (dispatch, getState) => {
  const {
    newInspection: { coInspectors },
  } = getState();
  const newItem = { ...item };
  let newCoInspectors = [];

  newItem.label = `${newItem.lastName}, ${newItem.firstName}`;

  if (!newItem.id) {
    newItem.id = generateUId();
    newCoInspectors = addItem(coInspectors, newItem);
  } else {
    newCoInspectors = updateItem(coInspectors, newItem);
  }

  dispatch({
    type: SET_CO_INSPECTORS,
    payload: newCoInspectors,
  });
};

const deleteCoInspector = (item) => (dispatch, getState) => {
  const {
    newInspection: { coInspectors },
  } = getState();
  const newCoInspectors = removeItem(coInspectors, item);

  dispatch({
    type: SET_CO_INSPECTORS,
    payload: newCoInspectors,
  });
};

const setCoInspectors =
  (items = []) =>
  (dispatch) => {
    const coInspectors = items.map((item) => {
      Object.assign(item, {
        label: `${item.lastName}, ${item.firstName}`,
      });

      return item;
    });

    dispatch({
      type: SET_CO_INSPECTORS,
      payload: coInspectors,
    });
  };

const getWorkPackage = () => async (dispatch) => {
  try {
    const data = await Db.getAll('wbs_inpsection');

    const mappedData = data.rows.map((i) => i.doc);
    const sortedData = sortTree(mappedData, 'name', 'asc');

    dispatch({
      type: SET_WORK_PACKAGE,
      payload: sortedData,
    });
  } catch (e) {
    throw e;
  }
};

const getFacilities = (param, query) => async (dispatch) => {
  try {
    let payload = [];

    if (!param.wbs) {
      return payload;
    }

    const selector = {
      $and: [{ supplier: { $gte: null } }, { wbses: { $elemMatch: { id: { $eq: param.wbsDetails.id } } } }],
    };

    if (query) {
      const regexp = new RegExp(query, 'i');

      selector.$and.push({
        $or: [{ supplier: { $regex: regexp } }, { location: { $regex: regexp } }, { country: { $regex: regexp } }],
      });
    }

    const { docs } = await Db.find('facilities', {
      selector,
      sort: [{ supplier: 'asc' }],
    });

    payload = docs;

    dispatch({
      type: SET_FACILITIES,
      payload,
    });
  } catch (e) {
    throw e;
  }
};

const sortMainSystem = (item) => {
  // Sorts generic main systems codes first
  const re = /x+/i;
  if (re.test(item.mainSystemCode)) {
    return 1;
  }
  return 0;
};

const getMainSystems = async ({ wbsDetails }) => {
  try {
    if (!wbsDetails || !wbsDetails.parkCodes) {
      return [];
    }

    const selector = wbsDetails.parkCodes.map((item) => ({
      parkCode: { $eq: item },
    }));

    const data = await Db.find('main_systems', {
      selector: { $or: selector },
    });

    const activeData = data.docs.filter((item) => item.active);

    return sortTree(activeData, [sortMainSystem, 'mainSystemCode'], ['desc', 'asc']);
  } catch (e) {
    throw e;
  }
};

const getAllSubSystems = async ({ parkId }) => {
  try {
    const data = await Api.get(`/api/structures/sub-systems?parkId=${parkId}`);

    return sortTree(data, 'subSystemCode', 'asc');
  } catch (e) {
    throw e;
  }
};

const getSubSystems = async ({ mainSystemDetails }) => {
  try {
    if (!mainSystemDetails || !mainSystemDetails.mainSystemType) {
      return [];
    }

    const data = await Db.find('sub_systems', {
      selector: { mainSystemType: { $eq: mainSystemDetails.mainSystemType } },
    });

    return sortTree(data.docs, 'subSystemCode', 'asc');
  } catch (e) {
    throw e;
  }
};

/*
 * saveInspection
 */
const saveInspectionWeb = async (inspection) => {
  try {
    const newInspection = { ...inspection, localId: generateUId() };

    const parsedData = await new NewInspectionModel(newInspection).parseDetailsForSynchronization(false, true);

    const { id } = await Api.post('/api/inspections', parsedData);
    return id;
  } catch (e) {
    throw e;
  }
};

const saveInspectionMobile = (inspection) => async (dispatch) => {
  try {
    const { id } = await Db.insert('inspections', inspection);

    await Db.insert('sync_inspections', {
      ...inspection,
      localId: id,
      createdAt: new Date().toISOString(),
    });

    dispatch(registerSync());

    return id;
  } catch (e) {
    throw e;
  }
};

const saveInspection = (data) =>
  async function save(dispatch, getState) {
    try {
      dispatch({
        type: SET_IS_LOADING,
        payload: true,
      });

      const {
        app: { isMobile },
        newInspection: { coInspectors },
      } = getState();

      const inspection = {
        ...data,
        checkedOutForMobile: isMobile,
        coInspectors,
        partOfPlanInspections: [],
        findings: [],
        status: INSPECTION_STATUSES.draft,
        structureType: data.structureType || INSPECTION_STRUCTURE.standalone,
      };

      const id = isMobile ? await dispatch(saveInspectionMobile(inspection)) : await saveInspectionWeb(inspection);

      dispatch({
        type: SET_IS_LOADING,
        payload: false,
      });

      return id;
    } catch (e) {
      dispatch({
        type: SET_IS_LOADING,
        payload: false,
      });
      throw e;
    }
  };

/*
 * updateInspection
 */
const updateInspectionMobile = (inspection) => async (dispatch) => {
  const { mainInspectionId, ...dataToUpdate } = inspection;
  const { id } = await Db.retryUntilWritten('inspections', inspection._id, (obj) => ({ ...obj, ...dataToUpdate }));
  const local = await Db.findOne('sync_inspections', { selector: { localId: inspection._id } }, false);

  if (local) {
    await Db.retryUntilWritten('sync_inspections', local._id, (obj) => ({
      ...obj,
      ...inspection,
      localId: id,
      _id: obj._id,
    }));
  } else {
    const currentInspection = await Db.findOne('inspections', id, false); // get current inspection data, they may be different from passed inspection due to background sync
    await Db.retryUntilWritten('sync_inspections_edit', currentInspection._id, (obj = {}) => ({
      ...obj,
      ...currentInspection,
    }));
  }

  dispatch(registerSync());

  return inspection.frontendId || inspection._id;
};

const updateInspectionWeb = async (inspection) => {
  const parsedData = await new NewInspectionModel(inspection).parseDetailsForSynchronization(true, true);
  const { id } = await Api.put('/api/inspections', parsedData);
  return id;
};

const updateInspection = (data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const {
      app: { isMobile },
      newInspection: { coInspectors },
    } = getState();
    const inspection = {
      ...data,
      coInspectors,
      updatedAt: new Date().toISOString(),
    };

    const id = isMobile ? await dispatch(updateInspectionMobile(inspection)) : await updateInspectionWeb(inspection);

    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });

    return id;
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

/*
 * getInpsectors
 */
const getInspectors = (wbs, query) => async (dispatch) => {
  try {
    let payload = [];

    if (wbs) {
      const selector = {
        wbses: { $elemMatch: { $eq: wbs } },
      };

      if (query) {
        selector.fullName = { $regex: new RegExp(query, 'i') };
      }

      const { docs } = await Db.find('inspectors_wbses', {
        selector,
      });

      payload = docs;
    }

    dispatch({
      type: SET_INSPECTORS,
      payload,
    });
  } catch (e) {
    throw e;
  }
};

const cleanData = () => ({
  type: CLEAN_DATA,
});

const isMainOrSubInspection = ({ mainInspectionId, structureType }) =>
  !!mainInspectionId || structureType === INSPECTION_STRUCTURE.main;

const isMainInspector = (user, { mainInspectionInspector }) => {
  const userId = _get(user, 'id', false);
  const mainInspectorId = _get(mainInspectionInspector, 'id', false);

  return userId && mainInspectorId && userId === mainInspectorId;
};

const isVFManager = (user) => {
  const userRoles = _get(user, 'roles', false);
  return userRoles && userRoles.includes('VF_MANAGER');
};

const isInspector = (user) => {
  const userRoles = _get(user, 'roles', false);
  return userRoles && userRoles.includes('INSPECTOR');
};

const isOwnerOfMainInspection = (user, inspection) => {
  const isOwner = _get(inspection, 'leadInspector.id') === _get(user, 'id');
  return isOwner && inspection.structureType === INSPECTION_STRUCTURE.main;
};

const disableIfUserNotInProperRole = (user, inspection, isMobile) =>
  !(
    isMainInspector(user, inspection) ||
    isInspector(user) ||
    (isVFManager(user) && !isMobile) ||
    isOwnerOfMainInspection(user, inspection)
  );

const disabledIfStatusIsNotDraft = (inspection) => {
  const statusesThatAllowEdit = Object.values(INSPECTION_STATUSES).filter(
    (status) => ![INSPECTION_STATUSES.draft].includes(status),
  );

  return statusesThatAllowEdit.includes(inspection.status);
};

const disabledIfStatusNotInDraftOrPlanned = (inspection) => {
  const statusDraftOrPlanned = Object.values(INSPECTION_STATUSES).filter(
    (status) => ![INSPECTION_STATUSES.planned, INSPECTION_STATUSES.draft].includes(status),
  );

  return statusDraftOrPlanned.includes(inspection.status);
};

export {
  cleanData,
  deleteCoInspector,
  disableIfUserNotInProperRole,
  disabledIfStatusIsNotDraft,
  disabledIfStatusNotInDraftOrPlanned,
  getFacilities,
  getInspectors,
  getMainSystems,
  getAllSubSystems,
  getSubSystems,
  getWorkPackage,
  isMainInspector,
  isMainOrSubInspection,
  isOwnerOfMainInspection,
  isVFManager,
  saveCoInspector,
  saveInspection,
  setCoInspectors,
  updateInspection,
};

export {
  SET_CO_INSPECTORS,
  SET_WORK_PACKAGE,
  SET_FACILITIES,
  SET_FUNCTIONAL_LOCATIONS,
  CLEAN_DATA,
  SET_IS_LOADING,
  SET_INSPECTORS,
};
