import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSimilarFiltersFrom } from 'modules/common/components/filters/global.actions';
import PropTypes from 'prop-types';

import { Icon, IconButton, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { styles } from './styles';

// TODO: Cleanup

const FindingsActionMenu = ({ menuList, item, classes, withMarkedRows }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const filterSetFrom = useSelector((state) => state.filtersConfig.similarFiltersFrom);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setMarkedRow = (obj) => {
    if (withMarkedRows) {
      if (filterSetFrom.id === obj.id) {
        dispatch(setSimilarFiltersFrom({}));
      }
      dispatch(setSimilarFiltersFrom(obj));
    }
  };

  const handleItemClick = (e, value) => {
    setMarkedRow(item, value);
  };

  const removeFilter = () => {
    setMarkedRow(item);
  };

  const renderMenu = Object.entries(menuList).map(([key, value]) => {
    const selected = filterSetFrom && filterSetFrom.item && item.id === filterSetFrom.item.id;

    const handleMenuItemClick = (e) => {
      if (selected) {
        removeFilter(e);
        return;
      }
      handleItemClick(e, value);
    };

    return (
      <MenuItem key={key} param={key} itemID={item.id} selected={selected} onClick={handleMenuItemClick}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {selected && <Icon className={classes.icon}>check</Icon>}
          by {value}
        </div>
      </MenuItem>
    );
  });

  if (!item.failureMode) {
    return null;
  }

  return (
    <div>
      <IconButton aria-label="More" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <Icon className="MoreActionMenu">more_vert</Icon>
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: 200,
          },
        }}
      >
        {renderMenu}
      </Menu>
    </div>
  );
};

FindingsActionMenu.propTypes = {
  menuList: PropTypes.shape({}),
  item: PropTypes.shape({}),
  withMarkedRows: PropTypes.bool,
  classes: PropTypes.shape({}).isRequired,
};

FindingsActionMenu.defaultProps = {
  menuList: {},
  item: {},
  withMarkedRows: false,
};

export default withStyles(styles)(FindingsActionMenu);
