import { connect } from 'react-redux';

import { createCrumb } from 'modules/app/components/app/app.actions';

import { setTitle } from '../../../app/components/app/app.actions';
import {
  getFindings,
  selectListFindings as selectFinding,
  selectAllFromList as selectAll,
  clearSelectedFindings,
  setSelectedFindings,
  getSimilarFindings,
} from './findings.actions';
import Findings from './findings.component';
import { getSelectedIds } from './findings.selectors';

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.findings.isLoading,
  findings: state.findings.items,
  totalCount: state.findings.totalCount,
  selectedIds: getSelectedIds(state, ownProps),
});

const mapDispatchToProps = {
  getFindings,
  selectFinding,
  clearSelectedFindings,
  getSimilarFindings,
  setTitle,
  createCrumb,
  setSelectedFindings,
  selectAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(Findings);
