import React, { useEffect, useState } from 'react';

import {
  DEFAULT_PAGINATION_PAGE_SIZE,
  FILTER_COOKIE_NAMES,
  WORKFLOWS_TYPES,
  WORKFLOW_STATUSES,
} from 'modules/app/config/config';
import { eraseCookie, getCookie, setCookie, compareObjectStructure } from 'modules/app/helpers/utils';
import Filters from 'modules/common/components/filters';
import Table from 'modules/common/components/table';
import { TableUniqueKey } from 'modules/common/components/table/table.context';
import PropTypes from 'prop-types';

import { Box, Button } from '@material-ui/core';
import { CloudDownload, CloudDownloadOutlined } from '@material-ui/icons';

import { AREA_OPTIONS, SEVERITY_OPTIONS, SERIAL_DEFECTS } from '../newWorkflow/newWorkflow.model';
import { ExportDefectNotificationButton } from './defectNotificationExport';
import TABLE_CONFIG from './workflows.config';

const WORKFLOW_STATUS_DELETED = 'DELETED';

const FILTERS_COOKE_NAME_MAP = new Map(Object.keys(FILTER_COOKIE_NAMES).map((key) => [key, FILTER_COOKIE_NAMES[key]]));

const DefectNotifications = ({
  createCrumb,
  getWorkflows,
  history,
  isLoading,
  totalCount,
  workflows,
  match: { params, path },
}) => {
  const cookie = FILTERS_COOKE_NAME_MAP.get(FILTER_COOKIE_NAMES.DEFECT_NOTIFICATION);

  const initialValues = JSON.parse(getCookie(cookie));

  const emptyFilters = {
    search: '',
    filters: {
      wbs: [],
      status: [],
      siteFacility: [],
      failureMode: [],
      area: [],
      severity: [],
      serialDefect: [],
    },
    sort: { sortBy: 'lastActivityDate', sortDirection: 'desc' },
  };

  const filterStructureCheck = compareObjectStructure(emptyFilters, initialValues);

  const [state, setState] = useState({
    filters: filterStructureCheck ? initialValues : emptyFilters,
    size: DEFAULT_PAGINATION_PAGE_SIZE,
    page: 0,
  });

  useEffect(() => {
    const { page, size, filters } = state;
    getWorkflows(
      {
        sort: `${filters.sort.sortBy},${filters.sort.sortDirection.toUpperCase()}`,
        types: WORKFLOWS_TYPES.defect_notification,
        page,
        size,
      },
      filters,
    );
  }, [state]);

  const onRowClick = (_, item) => {
    if (item.status !== WORKFLOW_STATUS_DELETED) {
      history.push(`/workflowDetails/${item.id}`);
    }
  };

  const filtersConfig = {
    name: cookie,
    initialFilters: state.filters,
    filters: {
      wbs: {
        caption: 'WBS',
        primaryText: 'description',
        secondaryText: 'name',
      },
      status: {
        caption: 'Status',
        primaryText: 'id',
        secondaryText: '',
      },
      siteFacility: {
        caption: 'Site Facility',
        primaryText: 'country',
        secondaryText: 'supplier',
      },
      failureMode: {
        caption: 'Failure Mode',
        primaryText: 'description',
        secondaryText: 'code',
      },

      severity: {
        caption: 'Severity',
        primaryText: 'label',
        secondaryText: '',
      },
      area: {
        caption: 'Area',
        primaryText: 'id',
        secondaryText: 'label',
      },
      serialDefect: { caption: 'Serial  Defect', primaryText: 'id', secondaryText: 'label' },
    },
    filtersOrderIndex: {
      wbs: 1,
      failureMode: 2,
      siteFacility: 3,
      status: 4,
      severity: 5,
      area: 6,
      serialDefect: 7,
    },
    sort: {
      status: 'status',
      lastActivityDate: 'lastActivityDate',
      currentStepDueDate: 'currentStepDueDate',
      siteFacility: 'siteFacility',
      title: 'title',
      failureMode: 'failureMode.description',
      wbs: 'wbs',
      severity: 'severity',
      area: 'area',
      reference: 'reference',
      workflowNumber: 'workflowNumber',
    },
    sortLabels: {
      status: 'Status',
      lastActivityDate: 'Last update',
      currentStepDueDate: 'Deadline',
      siteFacility: 'Site facility',
      title: 'Title',
      failureMode: 'Failure mode',
      wbs: 'Park/Package',
      severity: 'Severity',
      area: 'Area',
      reference: 'Reference',
      workflowNumber: 'Workflow Number',
    },
    chipLabels: {
      wbs: 'Park/Package',
      siteFacility: 'Site Facility',
      failureMode: 'Failure Mode',
      severity: 'Severity',
      area: 'Area',
      serialDefect: 'Serial Defect',
    },
    additionalFilters: {
      status: Object.entries(WORKFLOW_STATUSES).map(([, value]) => ({
        id: value,
      })),

      severity: [...SEVERITY_OPTIONS.values()].map(({ value, label }) => ({
        id: value,
        label: label,
      })),
      area: [...AREA_OPTIONS.values()].map(({ value, label }) => ({
        id: value,
        label: label,
      })),
      serialDefect: SERIAL_DEFECTS.map((item) => ({ id: item.value, label: item.label })),
    },
  };

  const setSort = (page, size) => {
    setState({
      ...state,
      page,
      size,
    });
  };

  const handleFiltersChange = (filterState, sortOnly) => {
    if (filterState.filters.serialDefect?.length > 1) {
      filterState.filters.serialDefect = [filterState.filters.serialDefect.pop()];
    }

    setCookie(cookie, JSON.stringify(filterState), 1);

    const newState = {
      ...state,
      filters: filterState,
    };
    if (!sortOnly) {
      newState.page = 0;
    }
    setState(newState);
  };

  const onClearAll = () => {
    eraseCookie(cookie);
  };

  const skipCells = ['failureMode'];

  return (
    <>
      <Box className="row no-gutters align-items-start justify-content-end pr-5 pl-5 pt-2 pb-2">
        <Filters
          withTextSearch
          withSort
          callback={handleFiltersChange}
          config={filtersConfig}
          onClearAll={onClearAll}
          preserveAdditionalFilters
        />
      </Box>
      <Box className="mt-3">
        <Table
          config={TABLE_CONFIG}
          isLoading={isLoading}
          items={workflows}
          onRowClick={onRowClick}
          pagination
          paginationCallback={setSort}
          params={params}
          sort={state.filters.sort}
          totalCount={totalCount}
          skipCells={skipCells}
          tableUniqueKey={TableUniqueKey.WORKFLOWS}
          excelExportParams={{
            filters: state.filters.filters,
            search: state.filters.search,
            url: `/api/workflows/export/xlsx`,
            params: {
              types: [WORKFLOWS_TYPES.defect_notification],
              sort: `${state.filters.sort.sortBy},${state.filters.sort.sortDirection.toUpperCase()}`,
              size: state.size,
            },
          }}
          AdditionalSettingsComponent={
            <ExportDefectNotificationButton
              filters={state.filters.filters}
              search={state.filters.search}
              params={{
                types: [WORKFLOWS_TYPES.defect_notification],
                sort: `${state.filters.sort.sortBy},${state.filters.sort.sortDirection.toUpperCase()}`,
                size: state.size,
              }}
            />
          }
        />
      </Box>
    </>
  );
};

DefectNotifications.propTypes = {
  getWorkflows: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  workflows: PropTypes.arrayOf(PropTypes.shape).isRequired,
  match: PropTypes.objectOf(PropTypes.shape).isRequired,
  createCrumb: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default DefectNotifications;
