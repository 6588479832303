import { connect } from 'react-redux';

import { setInspectionStatus, setSignMode } from '../inspectionDetails/inspectionDetails.actions';
import Sign from './sign.component';

const mapDispatchToProps = {
  setInspectionStatus,
  setSignMode,
};

export default connect(null, mapDispatchToProps)(Sign);
