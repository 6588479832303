import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';

import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { bufferToBlob } from 'modules/app/helpers/utils';
import { bool, func, instanceOf, oneOfType, shape, string } from 'prop-types';
import Api from 'services/api';

import { Button, Slider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Loader from '../loader/loader.component';
import { getCroppedImg } from './canvasUtils';
import ImgDialog from './imgDialog';

export const styles = (theme) => ({
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
});

const ImageCrop = ({ blockApplying, classes, fetchCroppedImage, mimeType, src }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showResult, setShowResult] = useState(false);

  const parseSrc = async () => {
    if (src instanceof ArrayBuffer) {
      const blob = bufferToBlob(src, mimeType);
      return URL.createObjectURL(blob);
    }

    if (src instanceof Blob) {
      return URL.createObjectURL(src);
    }

    if (src.includes('/api/attachments')) {
      if (!navigator.onLine) {
        return null;
      }

      const blob = await Api.get(src, null, 'blob');
      return URL.createObjectURL(blob);
    }

    return `data:${mimeType};base64,${src}`;
  };

  useEffect(() => {
    const fetchSrc = async () => {
      try {
        setIsLoading(true);
        const result = await parseSrc();
        setImageSrc(result);
        setIsLoading(false);
      } catch (err) {
        genericErrorHandler(err);
        setIsLoading(false);
      }
    };
    fetchSrc();
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const closeResult = () => {
    setShowResult(false);
  };

  const openResult = async () => {
    const croppedImage = await showCroppedImage();
    setCroppedImage(croppedImage);
    setShowResult(true);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);

      return croppedImage;
    } catch (e) {
      genericErrorHandler(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  const applyChanges = async () => {
    if (!blockApplying) {
      const croppedImage = await showCroppedImage();

      setCroppedImage(croppedImage);
      fetchCroppedImage(croppedImage);
    }
  };

  return (
    <>
      <div className={classes.cropContainer}>
        {isLoading && <Loader />}
        {!isLoading && (
          <Cropper
            image={imageSrc}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        )}
      </div>
      <div className={classes.controls}>
        <div className={classes.sliderContainer}>
          <Typography variant="overline" classes={{ root: classes.sliderLabel }}>
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ root: classes.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        <div className={classes.sliderContainer}>
          <Typography variant="overline" classes={{ root: classes.sliderLabel }}>
            Rotation
          </Typography>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            classes={{ root: classes.slider }}
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </div>
        <Button onClick={openResult} variant="contained" color="primary" classes={{ root: classes.cropButton }}>
          Show Result
        </Button>
        <Button
          onClick={applyChanges}
          variant="contained"
          color="primary"
          classes={{ root: classes.cropButton }}
          disabled={blockApplying || isLoading}
        >
          Apply Changes
        </Button>
      </div>
      <ImgDialog img={croppedImage} open={showResult} onClose={closeResult} />
    </>
  );
};

ImageCrop.propTypes = {
  blockApplying: bool,
  classes: shape({}),
  mimeType: string.isRequired,
  fetchCroppedImage: func.isRequired,
  src: oneOfType([instanceOf(Blob), instanceOf(ArrayBuffer), string]).isRequired,
};

ImageCrop.defaultProps = {
  blockApplying: false,
  classes: {},
};

export default withStyles(styles)(ImageCrop);
