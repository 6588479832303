import React from 'react';

import { FindingsMenu } from 'assets/icons';
import { DATETIME_FORMAT } from 'modules/app/config/config';
import FindingAttachmentsCounter from 'modules/findings/components/findigsAttachmentsCounter/findingsAttachmentsCounter.component';
import { SELECTING_CONFIG } from 'modules/findings/components/findings/findings.config';
import { FINDING_TYPES } from 'modules/findings/components/newFinding/newFinding.model';
import moment from 'moment';

import { ListItemText, Typography, Tooltip } from '@material-ui/core';
import { Flag, WorkOutline } from '@material-ui/icons';

import FindingActions from './findingActions.component';
import FindingTitleCell from './findingTitleCell.component';

const FINDINGS_CONFIG = {
  columns: [
    {
      caption: 'Type',
      sort: false,
      dataField: 'findingType',
      renderCell: (_, { findingType }) => (
        <>
          {findingType === FINDING_TYPES.WORK_INFO.value && <WorkOutline style={{ fill: '#2071B5' }} />}

          {findingType === FINDING_TYPES.ACTIONABLE.value && (
            <FindingsMenu className="mr-2 text-secondary menu-icon--findings" />
          )}
          {findingType === FINDING_TYPES.TODO.value && <Flag className="mr-2 text-secondary menu-icon--findings" />}
        </>
      ),
      align: 'left',
    },
    {
      caption: 'Name',
      dataField: 'title',
      sort: true,
      renderCell: (_, item) => <FindingTitleCell item={item} />,
      align: 'left',
    },
    {
      caption: 'Location',
      sort: true,
      dataField: 'location',
      align: 'center',
      renderCell: (_, item) => (
        <ListItemText
          className="text-nowrap"
          style={{ width: '100%', textAlign: 'inherit' }}
          secondary={item.location}
        />
      ),
    },
    {
      caption: 'Description',
      sort: true,
      dataField: 'description',
      align: 'center',
      renderCell: (_, item) => (
        <Tooltip title={item.description || ''} aria-label={item.description} placement="top">
          <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
            {item.description}
          </Typography>
        </Tooltip>
      ),
    },
    {
      caption: 'Failure Mode',
      sort: true,
      dataField: 'failureMode.description',
      align: 'center',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
          {item.failureMode !== null ? item.failureMode.description : ''}
        </Typography>
      ),
    },
    {
      caption: 'Sub System',
      sort: true,
      dataField: 'subSystem.subSystemCode',
      align: 'center',
      renderCell: (_, item) => (
        <ListItemText
          className="text-nowrap"
          style={{ textAlign: 'inherit' }}
          secondary={item.subSystem !== null ? item.subSystem.subSystemCode : '(Not Provided)'}
        />
      ),
    },
    {
      caption: 'Date and Time',
      sort: true,
      dataField: 'createDate',
      align: 'right',
      renderCell: (_, item) => (
        <ListItemText
          className="text-nowrap"
          style={{ width: '100%', textAlign: 'inherit' }}
          secondary={moment(item.createDate).format(DATETIME_FORMAT)}
        />
      ),
    },
    {
      caption: '',
      dataField: 'numberOfAttachments',
      align: 'right',
      padding: 'none',
      renderCell: (_, item) => {
        const { numberOfImages, numberOfDocuments, numberOfAudios } = item;
        return <FindingAttachmentsCounter {...{ numberOfImages, numberOfDocuments, numberOfAudios }} />;
      },
    },
    {
      caption: '',
      dataField: 'id',
      align: 'right',
      padding: 'none',
      renderCell: (_, item) => (item.id && item.inspection ? <FindingActions finding={item} /> : null),
    },
  ],
  noItemsFoundText: 'No findings found',
  initialSort: {
    sortField: 'title',
    sortDirection: 'asc',
  },
  disableFromRowSelecting: SELECTING_CONFIG,
};

export { FINDINGS_CONFIG };
