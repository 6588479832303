import React, { PureComponent } from 'react';

import { ListItem } from '@material-ui/core';

class ListItemWrapper extends PureComponent {
  handleOnClick = () => {
    const { onClick, item } = this.props;
    if (onClick) {
      onClick(item);
    }
  };

  render() {
    const { onClick, ...rest } = this.props;
    return <ListItem onClick={this.handleOnClick} {...rest} />;
  }
}

export default ListItemWrapper;
