import React from 'react';

const AvatarOnshore = (props) => (
  <svg xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40" {...props}>
    <defs>
      <circle id="a" cx={20} cy={20} r={20} />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <circle clipPath="url(#b)" fill="#C8E9F2" cx={20} cy={20} r={20} />
    <path
      clipPath="url(#b)"
      fill="#FFF"
      d="M40 19.3c-1.6-.2-3.3-.3-5-.3-3.3 0-6.7.3-10 1-4.4-3.3-9.5-5-15-5-3.4 0-6.6.7-9.8 2-.1 1-.2 2-.2 3 0 11 9 20 20 20s20-9 20-20v-.7z"
    />
    <g clipPath="url(#b)">
      <defs>
        <path
          id="c"
          d="M35 20c-3.5 0-6.9.4-10.2 1.1C20.7 17.9 15.6 16 10 16c-3.5 0-6.9.8-9.9 2.2-.1.6-.1 1.2-.1 1.8 0 11 9 20 20 20s19.8-8.8 20-19.7c-1.7-.2-3.3-.3-5-.3z"
        />
      </defs>
      <clipPath id="d">
        <use xlinkHref="#c" overflow="visible" />
      </clipPath>
      <circle clipPath="url(#d)" fill="#108043" cx={10} cy={40} r={24} />
      <circle clipPath="url(#d)" fill="#149A4C" cx={35} cy={68} r={48} />
      <circle clipPath="url(#d)" fill="#17AF50" cx={8} cy={122} r={96} />
    </g>
  </svg>
);

export default AvatarOnshore;
