import { connect } from 'react-redux';

import { setInspectionStatus, removeInspection, setSignMode } from '../inspectionDetails/inspectionDetails.actions';
import { makeIsLoggedUserLeadInspector } from '../inspectionDetails/inspectionDetails.selectors';
import { checkOutForMobile } from '../inspections/inspections.actions';
import Header from './inspectionDetailsHeader.component';

const isLoggedUserLeadInspector = makeIsLoggedUserLeadInspector();

const mapStateToProps = (state) => ({
  isMobile: state.app.isMobile,
  checklist: state.checklists.currentChecklistDetails.checklist,
  inspection: state.inspection.data,
  showHeader: state.app.showHeader,
  isLoggedUserLeadInspector: isLoggedUserLeadInspector(state),
  signMode: state.inspection.signMode,
  user: state.auth.user,
});

const mapDispatchToProps = {
  setInspectionStatus,
  removeInspection,
  setSignMode,
  checkOutForMobile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
