import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createCrumb } from 'modules/app/components/app/app.actions';
import { TableUniqueKey } from 'modules/common/components/table/table.context';
import PropTypes from 'prop-types';

import Table from '../../../common/components/table';
import NewReport from './newStatusReport.component';
import { getStatusReports, setSelectedReport } from './statusReports.actions';
import TABLE_CONFIG from './statusReports.config';
import StatusReportsDetails from './statusReportsDetails.component';

const StatusReportsList = ({ match: { path, params } }) => {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const reports = useSelector((state) => state.statusReports.items);
  const isLoading = useSelector((state) => state.statusReports.isLoading);

  useEffect(() => {
    dispatch(getStatusReports());
    dispatch(createCrumb(path, params));
  }, [path, params]);

  const openModal = () => setShowDetails(true);
  const closeModal = () => setShowDetails(false);
  const onRowClick = (_, item) => {
    dispatch(setSelectedReport(item.id));
    openModal();
  };

  return (
    <>
      <NewReport />
      <StatusReportsDetails open={showDetails} closeModal={closeModal} />
      <div className="row">
        <div className="col-md-12">
          <Table
            puppet-data="statusReports:table"
            config={TABLE_CONFIG}
            items={reports}
            onRowClick={onRowClick}
            isLoading={isLoading}
            tableUniqueKey={TableUniqueKey.REPORTS}
          />
        </div>
      </div>
    </>
  );
};

StatusReportsList.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default withRouter(StatusReportsList);
