import { SET_CHECKLIST_DETAILS, SET_IS_LOADING, CLEAR_DATA } from './checklist.actions';

const initialState = {
  currentChecklistDetails: {
    checklist: [],
    inspectionId: '',
    checklistId: '',
  },
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_CHECKLIST_DETAILS:
      return {
        ...state,
        currentChecklistDetails: {
          ...state.currentChecklistDetails,
          checklist: payload.checklist,
          inspectionId: payload.inspectionId,
          checklistId: payload.checklistId,
        },
        isLoading: false,
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
