import React, { FC, MouseEvent } from 'react';

import { parseFileSize } from 'modules/app/helpers/utils';
import ConfirmModal from 'modules/common/components/confirmModal/confirmModal';
import DropdownMenu from 'modules/common/components/dropdownMenu/dropdownMenu.component';

import { Avatar, Icon, ListItemIcon, ListItemText, MenuItem, Typography } from '@material-ui/core';

import { mimetypeToFileIcon } from './filesList.actions';

interface IParams {
  [key: string]: string;
}

interface IAttachment extends File {
  path?: string;
  id: string;
  title?: string;
  name: string;
  mimeType: string;
}

interface IAttachmentMenuProps {
  attachment: IAttachment;
  handleDownload: (e: MouseEvent<HTMLLIElement>) => void;
  onRemoveFile: (e: MouseEvent<HTMLButtonElement>, params: IParams) => void;

  canEdit?: boolean;
}

const AttachmentMenu: FC<IAttachmentMenuProps> = ({ attachment, handleDownload, onRemoveFile, canEdit = false }) => (
  <DropdownMenu>
    {handleDownload && (
      <MenuItem
        data-path={attachment.path || attachment.id}
        data-title={attachment.title || attachment.name}
        onClick={handleDownload}
      >
        <ListItemIcon>
          <Icon>save_alt</Icon>
        </ListItemIcon>
        <ListItemText>Download</ListItemText>
      </MenuItem>
    )}

    {canEdit && (
      <ConfirmModal params={{ id: attachment.id }} confirmFn={onRemoveFile}>
        <MenuItem>
          <ListItemIcon id="delete:button">
            <Icon>delete</Icon>
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </ConfirmModal>
    )}
  </DropdownMenu>
);

interface IAttachmentIconProps {
  mimeType: string;
}

const AttachmentIcon: FC<IAttachmentIconProps> = ({ mimeType = '' }) => {
  const FileIcon = mimetypeToFileIcon(mimeType);
  return <FileIcon className="icon-dark" />;
};

interface IFileRowProps {
  attachment: IAttachment;
  canEdit?: boolean;
  handleDownload: (e: MouseEvent<HTMLLIElement>) => void;
  onRemoveFile: (e: MouseEvent<HTMLButtonElement>, params: IParams) => void;
}

const FileRow: FC<IFileRowProps> = ({ attachment, canEdit = false, handleDownload, onRemoveFile }) => (
  <div className="row align-items-center justify-content-between cursor-pointer file-row">
    <Avatar className="file-icon">
      <AttachmentIcon mimeType={attachment.mimeType || attachment.type} />
    </Avatar>
    <div className="d-flex flex-column">
      <Typography align="left" color="textPrimary" variant="body2" noWrap className="file-name">
        {attachment.title || attachment.name}
      </Typography>

      <Typography align="right" color="textSecondary" variant="caption">
        {parseFileSize(attachment.size)}
      </Typography>
    </div>
    <AttachmentMenu
      attachment={attachment}
      handleDownload={handleDownload}
      onRemoveFile={onRemoveFile}
      canEdit={canEdit}
    />
  </div>
);

export default FileRow;
