import { connect } from 'react-redux';

import { registerSync } from '../../../app/components/app/app.actions';
import { logout } from '../../../auth/components/login/login.actions';
import { getNotSynchronizedAttachments } from '../filesList/filesList.actions';
import Menu from './menu.component';

const mapStateToProps = (state) => ({
  ...state.auth.user,
  isOnline: state.app.isOnline,
  isMobile: state.app.isMobile,
  syncInProgress: state.app.syncInProgress,
  syncProgress: state.app.syncProgress,
});

const mapDispatchToProps = {
  logout,
  getNotSynchronizedAttachments,
  registerSync,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
