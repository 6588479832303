import React, { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { usePrevious } from 'hooks';
import _get from 'lodash/get';
import { createCrumb, getData, setShowHeader } from 'modules/app/components/app/app.actions';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { CONSTRUCTION_TYPE, DATE_FORMAT, OM_TYPE, dataToStoreOffline } from 'modules/app/config/config';
import { isOnline } from 'modules/app/helpers/hooks';
import { charsLeft } from 'modules/app/helpers/utils';
import DatepickerWrapper from 'modules/common/components/datepickerWrapper/datepickerWrapper.component';
import FilteringTree from 'modules/common/components/filteringTree/filteringTree';
import InputModal from 'modules/common/components/inputModal/inputModal.container';
import Loader from 'modules/common/components/loader/loader.component';
import SaveHeader from 'modules/common/components/saveHeader/saveHeaderMobile.component';
import UnsavedChanges from 'modules/common/components/unsavedChanges/unsavedChanges.hoc';
import { getChecklistsByParkId } from 'modules/inspections/components/checklists/checklist.actions';
import CoInspectors from 'modules/inspections/components/coInspectors/coInspectors.container';
import Facilities from 'modules/inspections/components/facilities/facilities.container';
import {
  clearData as clearInspectionData,
  getInspection,
  getInspectionDetails,
} from 'modules/inspections/components/inspectionDetails/inspectionDetails.actions';
import LeadInspector from 'modules/inspections/components/leadInspector/leadInspector.container';
import {
  cleanData,
  disableIfUserNotInProperRole,
  disabledIfStatusIsNotDraft,
  disabledIfStatusNotInDraftOrPlanned,
  getMainSystems,
  getSubSystems,
  getWorkPackage,
  saveInspection,
  setCoInspectors,
  updateInspection,
} from 'modules/inspections/components/newInspection/newInspection.actions';
import NewInspectionModel, {
  INSPECTION_STRUCTURE,
} from 'modules/inspections/components/newInspection/newInspection.model';
import Notifications from 'services/notifications';

import { Box, FormControl, Icon, IconButton, InputAdornment, MenuItem, TextField } from '@material-ui/core';

interface MatchProps {
  params: { inspectionId: string; editInspectionId: string };
  path: string;
}

interface IStoreOffline {
  key: string;
  url: string;
  syncDate?: string;
  syncDateField?: string;
  dbName: string;
  index?: string[];
  mapData?: (data: any) => any;
  data?: any;
  mobileOnly?: boolean;
}

interface INewInspectionErrors {
  wbs?: any;
  mainSystem?: any;
  subSystem?: any;
  title?: string;
  siteFacility?: any;
  deadline?: any;
  checkListDefinitionId?: any;
  leadInspector?: any;
  coInspectors?: any;
}

interface NewInspectionProps {
  setDirty: (isDirty?: boolean) => void;
}

interface ILocationState {
  type: string;
}

const renderItemFacilities = ({ supplier, location, country }) => `${supplier}, ${location}, ${country}`;
const renderItemLeadInspector = ({ fullName }) => fullName;
const datepickerInputProps = {
  endAdornment: (
    <InputAdornment position="end">
      <IconButton>
        <Icon>today</Icon>
      </IconButton>
    </InputAdornment>
  ),
};

function hasRoleInTree(role) {
  return (node) => {
    if (node.roles.includes(role)) {
      return true;
    }
    for (const child of node.children || []) {
      if (hasRoleInTree(role)(child)) {
        return true;
      }
    }
    return false;
  };
}

const NewInspection = ({ setDirty }: NewInspectionProps) => {
  const history = useHistory();
  const { state }: { state: ILocationState } = useLocation();

  const dispatch = useDispatch();
  const { params, path }: MatchProps = useRouteMatch();

  const { isLoading, workPackage } = useSelector((state: IStore) => state.newInspection);
  const { isLoading: isLoadingDetails, data: inspectionStoreData } = useSelector((state: IStore) => state.inspection);
  const { user: loggedUser } = useSelector((state: IStore) => state.auth);

  const [inspectionState, setInspectionState] = useState<any>({});
  const [mainInspection, setMainInspection] = useState<any>({});
  const [checklistsForMobile, setChecklistsForMobile] = useState<{ docs: any[] }>({ docs: [] });
  const [deadlineError, setDeadlineErrorState] = useState(false);
  const [errors, setErrors] = useState<INewInspectionErrors>({});

  const workPackageInspectorsFiltered = workPackage?.filter(hasRoleInTree('INSPECTOR'));

  const prevInspectionId = usePrevious(inspectionStoreData.id);

  const { inspectionId: newInspectionId, editInspectionId } = params;

  const getMainInspection = async (id) => {
    const newMainInspection = await dispatch(getInspectionDetails(id));
    setMainInspection(newMainInspection);
  };

  useEffect(() => {
    if (!editInspectionId) {
      inspectionState.leadInspector = loggedUser;
    }

    setInspectionState({ ...inspectionState, type: state?.type || mainInspection.type });
  }, []);

  useEffect(() => {
    dispatch(setShowHeader(false));
    dispatch(getWorkPackage());

    (async () => {
      let editedInspection;

      if (path !== '/newInspection') {
        if (newInspectionId || inspectionStoreData.mainInspectionId) {
          getMainInspection(newInspectionId || inspectionStoreData.mainInspectionId);
        }

        if (editInspectionId) {
          editedInspection = await getInspection(editInspectionId);
        }
      }

      const parkId = inspectionStoreData?.park?.id || editedInspection?.park?.id;

      if (parkId) {
        const checklist = await getChecklistsByParkId(parkId);
        setChecklistsForMobile(checklist);
      }
    })();

    return () => {
      dispatch(clearInspectionData());
      dispatch(setShowHeader(true));
      dispatch(cleanData());
    };
  }, []);

  useEffect(() => {
    if (path === '/newInspection') {
      dispatch(clearInspectionData());
    }
  }, [dispatch, path]);

  useEffect(() => {
    if (inspectionStoreData?.id !== prevInspectionId && inspectionStoreData?.mainInspectionId) {
      getMainInspection(inspectionStoreData.mainInspectionId);
    }

    dispatch(createCrumb(path, params));
  }, [inspectionStoreData.id, path, params]);

  useEffect(() => {
    if ((!!editInspectionId && (inspectionState.id || inspectionState._id)) || !editInspectionId) {
      return;
    }

    dispatch(setCoInspectors(inspectionStoreData.coInspectors));

    setInspectionState(inspectionStoreData);
  }, [inspectionStoreData.coInspectors, editInspectionId]);

  const isOM = inspectionStoreData?.type === OM_TYPE || _get(history, 'location.state.type') === OM_TYPE;

  let title = editInspectionId ? 'Edit Inspection' : `Plan ${isOM ? 'O&M' : 'Construction'} Inspection`;
  if (newInspectionId) {
    title = 'Plan Subinspection';
  }

  const newInspection = useMemo(
    () => new NewInspectionModel(inspectionState, mainInspection).parseDetails(),
    [inspectionState, mainInspection],
  );

  const isUserWithoutProperRole = disableIfUserNotInProperRole(loggedUser, inspectionStoreData, true);
  const isDisabledForEditionOtherThanDraft = disabledIfStatusIsNotDraft(inspectionStoreData);
  const isDisabledForEditionOtherThanDraftOrPlanned = disabledIfStatusNotInDraftOrPlanned(inspectionStoreData);

  const isConstruction = newInspection.type === CONSTRUCTION_TYPE;
  const isMainInspection = newInspection.structureType === INSPECTION_STRUCTURE.main;

  const mainSystemId = newInspection?.mainSystemDetails?.id;

  const filteredChecklists = mainSystemId
    ? checklistsForMobile?.docs?.filter((c) => c.mainSystem === null || c.mainSystem.id === mainSystemId)
    : checklistsForMobile?.docs;

  const isChecklistFieldDisabled =
    isUserWithoutProperRole ||
    isDisabledForEditionOtherThanDraft ||
    isMainInspection ||
    !filteredChecklists ||
    filteredChecklists.length === 0;

  if (isLoading || isLoadingDetails) {
    return <Loader />;
  }

  if (!inspectionStoreData) {
    return null;
  }

  const handleOnSubmit = async () => {
    setDirty(false);

    const type = !Object.keys(mainInspection).length
      ? inspectionState.type || _get(history, 'location.state.type', CONSTRUCTION_TYPE)
      : mainInspection.type;

    const newInspectionModel: NewInspectionModel & { _id?: string } = new NewInspectionModel(
      { ...inspectionState, type },
      mainInspection,
    );

    const newErrors = newInspectionModel.validateSave();

    if (newErrors || deadlineError) {
      setErrors(newErrors);
      return;
    }

    if (!newInspectionModel._id && newInspectionId) {
      Object.assign(newInspectionModel, { mainInspectionId: newInspectionId });
    }

    try {
      let inspectionId;

      if (editInspectionId) {
        inspectionId = await dispatch(updateInspection(newInspectionModel));
      } else {
        inspectionId = await dispatch(saveInspection(newInspectionModel));
      }
      setDirty(false);

      if (inspectionId) {
        history.replace(`/inspectionDetails/${inspectionId}`);
      }
    } catch (err) {
      genericErrorHandler(err);
    }
  };

  const setDeadlineError = (err) => {
    setDeadlineErrorState(err);
  };

  const changeFieldsValue = (values = []) => {
    const inspectionStateCopy = { ...inspectionState };

    values.forEach((item) => {
      inspectionStateCopy[item.name] = item.value;
    });

    const newInspectionState = new NewInspectionModel(inspectionStateCopy, mainInspection);

    if (Object.keys(errors).length > 0) {
      const newErrors = newInspectionState.validateSave();
      setErrors(newErrors);
    }

    setInspectionState(newInspectionState);

    setDirty();
  };

  const setFieldValue = (name, value = '') => {
    changeFieldsValue([{ name, value }]);
  };

  const handleChange = (e) => {
    const {
      currentTarget: { name, value },
    } = e;

    setFieldValue(name, value);
  };

  const setWbsValue = async (name: string, value: IWBS) => {
    if (isOnline()) {
      try {
        const checklistsDataGetter: IStoreOffline = dataToStoreOffline.find((d) => d.key === 'checklists');
        getData(checklistsDataGetter as Required<IStoreOffline>);
      } catch (err) {
        genericErrorHandler(err);
      }
    }

    let checklists = null;
    if (value?.parents[0]) {
      checklists = await getChecklistsByParkId(value.parents[0]);
    }

    setChecklistsForMobile(checklists);

    const fieldsToChange: { name: string; value: any }[] = [
      { name, value },
      { name: 'mainSystem', value: '' },
      { name: 'subSystem', value: '' },
      { name: 'siteFacility', value: '' },
      { name: 'checkListDefinition', value: {} },
      { name: 'checkListDefinitionId', value: '' },
    ];

    if (_get(inspectionState, 'leadInspector.id') !== loggedUser.id) {
      fieldsToChange.push({ name: 'leadInspector', value: '' });
    }

    if (value && value.parents && value.parents.length) {
      fieldsToChange.push({ name: 'park', value: { id: value.parents[0] } });
    }

    changeFieldsValue(fieldsToChange);
  };

  const setMainSystemValue = async (name: string, value: any) => {
    const fieldsToChange: { name: string; value: any }[] = [
      { name, value },
      { name: 'subSystem', value: '' },
    ];

    const checklist: IChecklist = checklistsForMobile.docs?.find((c) => c.id === newInspection?.checkListDefinitionId);

    // check if checklist is not valid for selected main system
    if (!(checklist?.mainSystem === null || checklist?.mainSystem?.id === value?.id)) {
      fieldsToChange.push({ name: 'checkListDefinition', value: {} }, { name: 'checkListDefinitionId', value: '' });
    }

    changeFieldsValue(fieldsToChange);
  };

  const setChecklistValue: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = async (value) => {
    const newCheckListDefinitionId = value.target.value;
    const newCheckListDefinition = filteredChecklists.find((c) => c.id === newCheckListDefinitionId);

    changeFieldsValue([
      { name: 'checkListDefinitionId', value: newCheckListDefinitionId },
      { name: 'checkListDefinition', value: newCheckListDefinition },
    ]);
  };

  const preLeadInspectorClick = (cb: () => void) => {
    if (!_get(newInspection, 'wbsDetails.id')) {
      Notifications.showError('First select Work Package');
      return;
    }

    cb();
  };

  const renderChecklistItems = filteredChecklists?.map((checklist) => {
    return (
      <MenuItem key={checklist.id} value={checklist.id}>
        {checklist.description}
      </MenuItem>
    );
  }) || <MenuItem />;

  if (newInspection.checkListDefinitionId && Array.isArray(renderChecklistItems)) {
    renderChecklistItems.unshift(
      <MenuItem key={0} value="">
        Clear current selection
      </MenuItem>,
    );
  }

  return (
    <>
      <SaveHeader title={title} onSave={handleOnSubmit} history={history} />
      <Box className="content-wrapper" id="new-inspection">
        <InputModal
          className="w-100 mt-4"
          id="add-inspection:wbsId"
          title="Work package"
          fieldName="wbs"
          placeholder="Work Package"
          value={newInspection.wbs}
          clearFieldValue={setWbsValue}
          filterTreeId={mainInspection?.wbsDetails?.id || null}
          error={!!errors && !!errors.wbs}
          errorText={errors && errors.wbs}
          disabled={!!mainInspection?.mainSystem || isUserWithoutProperRole || isDisabledForEditionOtherThanDraft}
          blockRootClick
        >
          <FilteringTree
            onSelect={setWbsValue}
            nodes={workPackageInspectorsFiltered}
            searchPlaceholderText="Search for Work Package"
            primaryTextField="description"
            secondaryTextField="name"
            alternativePrimaryTextField="name"
            checkForActive
            fieldName={undefined}
            checkFunction={undefined}
            searchPosition={undefined}
            withCheck={undefined}
          />
        </InputModal>
        <InputModal
          className="w-100 mt-4"
          id="add-inspection:mainSystem"
          title="Main system"
          placeholder={`Main system${isConstruction || isMainInspection ? ` (optional)` : ''}`}
          fieldName="mainSystem"
          value={newInspection.mainSystem}
          clearFieldValue={setMainSystemValue}
          getNodes={getMainSystems}
          getNodesParams={newInspection}
          filterTreeId={mainInspection.mainSystemDetails ? mainInspection.mainSystemDetails.id : null}
          error={!!errors && !!errors.mainSystem}
          errorText={errors && errors.mainSystem}
          disabled={!!mainInspection?.mainSystem || isUserWithoutProperRole || isDisabledForEditionOtherThanDraft}
        >
          <FilteringTree
            onSelect={setMainSystemValue}
            searchPlaceholderText="Search for Main System"
            primaryTextField="description"
            secondaryTextField="mainSystemCode"
            checkForActive
            fieldName={undefined}
            checkFunction={undefined}
            searchPosition={undefined}
            withCheck={undefined}
          />
        </InputModal>
        <InputModal
          className="w-100 mt-4"
          id="add-inspection:subSystem"
          title="Sub system"
          placeholder="Sub system (optional)"
          fieldName="subSystem"
          value={newInspection.subSystem}
          clearFieldValue={setFieldValue}
          getNodes={getSubSystems}
          getNodesParams={newInspection}
          filterTreeId={mainInspection.subSystemDetails ? mainInspection.subSystemDetails.id : null}
          error={!!errors && !!errors.subSystem}
          errorText={errors && errors.subSystem}
          disabled={isUserWithoutProperRole || isDisabledForEditionOtherThanDraft}
        >
          <FilteringTree
            onSelect={setFieldValue}
            searchPlaceholderText="Search for Sub System"
            primaryTextField="description"
            secondaryTextField="subSystemCode"
            checkForActive
            fieldName={undefined}
            checkFunction={undefined}
            searchPosition={undefined}
            withCheck={undefined}
          />
        </InputModal>
        <TextField
          label="Title"
          className="w-100 mt-4"
          id="title"
          name="title"
          value={newInspection.title}
          onChange={handleChange}
          error={!!errors && !!errors.title}
          helperText={(errors && errors.title) || charsLeft(newInspection.title, 200)}
          disabled={isUserWithoutProperRole || isDisabledForEditionOtherThanDraft}
          multiline
        />
        <Facilities
          fieldName="siteFacility"
          onSelect={setFieldValue}
          clearFieldValue={setFieldValue}
          value={newInspection.siteFacility}
          error={!!errors && !!errors.siteFacility}
          errorText={errors && errors.siteFacility}
          renderItem={renderItemFacilities}
          rowHeight={65}
          extraParam={newInspection}
          disabled={isUserWithoutProperRole || isDisabledForEditionOtherThanDraft}
          autoSelect
          placeholder="Site/Facility"
          checkForActive
          predefinePropName="location"
          predefineIfOne={!isOM}
        />
        <FormControl className="w-100 mt-4">
          <DatepickerWrapper
            InputProps={datepickerInputProps}
            animateYearScrolling={false}
            clearable
            disabled={isUserWithoutProperRole || isDisabledForEditionOtherThanDraftOrPlanned}
            disablePast
            format={DATE_FORMAT}
            label="Deadline (optional)"
            maxDate={mainInspection?.deadline || undefined}
            name="deadline"
            onChange={setFieldValue}
            setError={setDeadlineError}
            value={newInspection.deadline}
          />
        </FormControl>
        <TextField
          label="Checklist"
          select
          className="w-100 mt-4"
          id="checkListDefinitionId"
          name="checkListDefinitionId"
          value={newInspection.checkListDefinitionId || ''}
          onChange={setChecklistValue}
          disabled={isChecklistFieldDisabled}
        >
          {renderChecklistItems}
        </TextField>
        <LeadInspector
          clearFieldValue={setFieldValue}
          disabled={isUserWithoutProperRole || isDisabledForEditionOtherThanDraftOrPlanned}
          error={!!errors && !!errors.leadInspector}
          errorText={errors && errors.leadInspector}
          extraParam={_get(newInspection, 'wbsDetails.id')}
          fieldName="leadInspector"
          onClick={preLeadInspectorClick}
          onSelect={setFieldValue}
          renderItem={renderItemLeadInspector}
          value={newInspection.leadInspector}
        />
        {!isConstruction && (
          <CoInspectors
            className="w-100 mt-4"
            disabled={isUserWithoutProperRole || isDisabledForEditionOtherThanDraftOrPlanned}
            id="new-inspection-co-inspectors"
            setDirty={setDirty}
          />
        )}
      </Box>
    </>
  );
};

export default UnsavedChanges(NewInspection);
