import {
  ADD,
  DELETE,
  FETCHED,
  IS_LOADING,
  SELECTED,
  SELECTED_IS_LOADING,
  SET_SUPPLIERS,
  SET_WORKPACKAGES,
  SUPPLIERS_IS_LOADING,
} from './statusReports.actions';

const initialState = {
  isLoading: false,
  items: [],
  selected: {},
  selectedIsLoading: false,
  workPackages: [],
  suppliers: [],
  suppliersIsLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case FETCHED:
      return {
        ...state,
        items: payload,
        isLoading: false,
      };
    case DELETE:
      return {
        ...state,
        items: payload,
        isLoading: false,
      };
    case SELECTED:
      return {
        ...state,
        selected: payload,
        selectedIsLoading: false,
      };
    case SELECTED_IS_LOADING:
      return {
        ...state,
        selectedIsLoading: true,
      };
    case ADD:
      return {
        ...state,
        items: payload,
        isLoading: false,
      };
    case SET_WORKPACKAGES:
      return {
        ...state,
        workPackages: payload,
      };
    case SET_SUPPLIERS:
      return {
        ...state,
        suppliers: payload.filter((supplier) => supplier.active === true),
        suppliersIsLoading: false,
      };
    case SUPPLIERS_IS_LOADING:
      return {
        ...state,
        suppliersIsLoading: payload,
      };
    default:
      return state;
  }
};
