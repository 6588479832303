import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FilterModal from './components/modal';
import FilterUtils from './components/utils';
import FiltersContextProvider from './filters.context';
import { getFilters } from './global.actions';
import { FiltersProps } from './types';

const Filters: FC<FiltersProps> = ({
  additionalChips,
  callback,
  config,
  extendedParameters = {},
  onClearAll = () => {},
  onClearFuncs = {},
  preserveAdditionalFilters,
  searchLabel,
  withSort,
  withTextSearch,
  resetOn,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => (state as any).auth);
  const [filtersOpened, setFiltersOpened] = useState(false);
  const additionalFilters = JSON.stringify(config.additionalFilters);

  useEffect(() => {
    dispatch(getFilters(user, config));
  }, [additionalFilters]);

  const openModal = () => setFiltersOpened(true);
  const closeModal = () => setFiltersOpened(false);

  const initialFilters = {
    ...config.initialFilters,
  };

  return (
    <FiltersContextProvider initialValues={initialFilters} config={config} resetOn={resetOn}>
      <FilterModal
        open={filtersOpened}
        closeDialog={closeModal}
        onClearAll={onClearAll}
        additionalChips={additionalChips}
      />
      <FilterUtils
        callback={callback}
        onClearFuncs={onClearFuncs}
        openModalCallback={openModal}
        preserveAdditionalFilters={preserveAdditionalFilters}
        extendedParameters={extendedParameters}
        searchLabel={searchLabel}
        withSort={withSort}
        withTextSearch={withTextSearch}
      />
    </FiltersContextProvider>
  );
};

export { Filters as default };
