import { connect } from 'react-redux';

import SelectList from '../../../common/components/selectList/selectList.component';
import { getInspectors as getData } from '../newInspection/newInspection.actions';

const mapStateToProps = (state) => ({
  data: state.newInspection.inspectors,
  id: 'leadInspector',
  placeholder: 'Inspector',
  searchPlaceholder: 'Search for Inspector',
});

const mapDispatchToProps = {
  getData,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectList);
