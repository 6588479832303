import jwtDecode from 'jwt-decode';
import { B2BConfig, scopeUrl } from 'modules/app/config/config';

import { AuthenticationResult, EventType, PublicClientApplication } from '@azure/msal-browser';

import Db from './db';

const loginRequest = {
  scopes: [scopeUrl, 'User.Read', 'openid'],
};

const silentRequest = {
  ...loginRequest,
};

const saveToken = (data: string) => {
  const decoded = jwtDecode(data);

  Db.retryUntilWritten('token', 'token', (org = { _id: 'token' }) => ({
    ...org,
    data,
    decoded,
  }));
};

const createMsalInstance = () => {
  const msalInstance = new PublicClientApplication(B2BConfig);

  msalInstance.addEventCallback((event) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    const account = (event.payload as AuthenticationResult)?.account;

    if (event.eventType === EventType.LOGIN_SUCCESS && account) {
      msalInstance.setActiveAccount(account);
    }
  });

  msalInstance
    .handleRedirectPromise()
    .then(async (tokenResponse) => {
      console.log('tokenResponse -', tokenResponse);
      // Check if user signed in
      if (!tokenResponse) {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0 && navigator.onLine) {
          // No user signed in

          msalInstance.loginRedirect();
        }
      } else {
        // Do something with the tokenResponse

        saveToken(tokenResponse.accessToken);
      }
    })
    .catch((err) => {
      // TODO: Handle errors
      console.log(err);
    });

  return msalInstance;
};

const loginAzure = async (msalInstance: PublicClientApplication) => {
  if (navigator.onLine) {
    try {
      msalInstance.loginRedirect(loginRequest);
    } catch (err) {
      console.log(err);
    }
  }
};

const logoutAzure = async (msalInstance: PublicClientApplication) => {
  if (navigator.onLine) {
    try {
      await msalInstance.logoutPopup();
    } catch (err) {
      console.log(err);
    }
  }
};

const getToken = async (msalInstance: PublicClientApplication) => {
  try {
    const account = msalInstance.getActiveAccount();
    if (!account && navigator.onLine) {
      msalInstance.loginRedirect(loginRequest);
    }

    const authResult = await msalInstance.acquireTokenSilent({ ...loginRequest, account });

    saveToken(authResult.accessToken);

    return { data: authResult.accessToken, decoded: jwtDecode(authResult.accessToken), ...authResult };
  } catch (err) {
    console.log(err);
    if (navigator.onLine) {
      loginAzure(msalInstance);
    }
  }
};

const acquireAccessToken = async (msalInstance) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    await loginAzure(msalInstance);
  }

  if (navigator.onLine) {
    return getToken(msalInstance);
  }

  const accessToken = await Db.get('token', 'token');

  return accessToken;
};

export { acquireAccessToken, createMsalInstance, getToken, loginAzure, loginRequest, logoutAzure, silentRequest };
