import React from 'react';

import { DATETIME_FORMAT, INSPECTION_STATUSES } from 'modules/app/config/config';
import FindingPackageCell from 'modules/inspections/components/inspectionDetails/findingPackageCell';
import moment from 'moment';

import { Tooltip, Typography } from '@material-ui/core';

import { FINDING_TYPES } from '../newFinding/newFinding.model';
import FindingsActionMenu from './findingsActionMenu.component';

const shortenTo = (text = '', shortenBy = 10) => {
  if (text) {
    return `${text.slice(0, shortenBy)}${text.length > shortenBy ? '...' : ''}`;
  }
  return '';
};

const actionMenuList = {
  FAILURE_MODE: 'Failure Mode',
};

const SELECTING_CONFIG = [
  {
    dataField: 'workflows',
    compareFn: ({ workflows }) => workflows.length > 0,
  },
  {
    dataField: 'inspection.status',
    compareFn: ({ inspection: { status } }) => status !== INSPECTION_STATUSES.completed,
  },
  {
    dataField: 'findingType',
    compareFn: (finding) => finding.findingType === FINDING_TYPES.WORK_INFO.value,
  },
];

const TABLE_CONFIG = {
  columns: [
    {
      caption: 'Park/Package',
      dataField: 'inspection.wbs',
      sortField: 'inspection.wbs',
      renderCell: (_, item) => <FindingPackageCell item={item} />,
      hidable: true,
      align: 'left',
    },
    {
      caption: 'Failure Mode',
      dataField: 'failureMode.description',
      sortField: 'failureMode.description',
      renderCell: (_, item) => (
        <Tooltip
          title={item.failureMode ? item.failureMode.description : ''}
          aria-label={item.failureMode}
          placement="top"
        >
          <Typography color="textSecondary" variant="caption">
            {item.failureMode !== null ? shortenTo(item.failureMode.description, 20) : '(Not Provided)'}
          </Typography>
        </Tooltip>
      ),
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Find Similar',
      dataField: '',
      sortField: '',
      renderCell: (_, item, withMarkedRows) => (
        <FindingsActionMenu menuList={actionMenuList} item={item} withMarkedRows={withMarkedRows} />
      ),
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Description',
      dataField: 'description',
      sortField: 'description',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
          {shortenTo(item.description, 100)}
        </Typography>
      ),
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Date and Time',
      dataField: 'lastActivityDate',
      sortField: 'lastActivityDate',
      style: { whiteSpace: 'nowrap' },
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
          {moment(item.lastActivityDate).format(DATETIME_FORMAT)}
        </Typography>
      ),
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Main System',
      dataField: 'mainSystem',
      sortField: 'mainSystem',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
          {item.mainSystem !== null ? item.mainSystem.description : '(Not Provided)'}
        </Typography>
      ),
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Sub System',
      dataField: 'subSystem.description',
      sortField: 'subSystem.description',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
          {item.subSystem ? item.subSystem.description || `<${item.subSystem.subSystemCode}>` : '(Not Provided)'}
        </Typography>
      ),
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Inspector',
      dataField: 'inspection.leadInspector',
      sortField: 'inspection.leadInspector',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
          {item.inspection.leadInspector}
        </Typography>
      ),
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Status',
      dataField: 'inspection.status',
      sortField: 'inspection.status',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
          {item.inspection.status}
        </Typography>
      ),
      hidable: true,
      align: 'right',
    },
  ],
  noItemsFoundText: 'No findings found',
  disableFromRowSelecting: SELECTING_CONFIG,
};

export { TABLE_CONFIG, SELECTING_CONFIG };
