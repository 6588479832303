import React, { PureComponent, Fragment } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Typography, ListItemIcon } from '@material-ui/core';

import { FindingPhoto, FindingAttachment, FindingVoiceRecording } from '../../../../assets/icons/index';

class FindingsAttachmentsCounter extends PureComponent {
  render() {
    const { numberOfImages, numberOfDocuments, numberOfAudios, minWidth } = this.props;
    const containerClasses = classNames('d-inline-flex', {
      'mw-10r': minWidth,
    });

    return (
      <div className={containerClasses}>
        {numberOfImages > 0 && (
          <>
            <ListItemIcon className="svg-small no-min-width">
              <FindingPhoto className="icon-dark" />
            </ListItemIcon>
            <Typography color="textSecondary" className="ml-1 mr-3">
              {numberOfImages}
            </Typography>
          </>
        )}
        {numberOfDocuments > 0 && (
          <>
            <ListItemIcon className="svg-small no-min-width">
              <FindingAttachment className="icon-dark" />
            </ListItemIcon>
            <Typography color="textSecondary" className="ml-1 mr-3">
              {numberOfDocuments}
            </Typography>
          </>
        )}
        {numberOfAudios > 0 && (
          <>
            <ListItemIcon className="svg-small no-min-width">
              <FindingVoiceRecording className="icon-dark" />
            </ListItemIcon>
            <Typography color="textSecondary" className="ml-1 mr-3">
              {numberOfAudios}
            </Typography>
          </>
        )}
      </div>
    );
  }
}

FindingsAttachmentsCounter.propTypes = {
  numberOfImages: PropTypes.number,
  numberOfDocuments: PropTypes.number,
  numberOfAudios: PropTypes.number,
  minWidth: PropTypes.bool,
};

FindingsAttachmentsCounter.defaultProps = {
  numberOfImages: 0,
  numberOfDocuments: 0,
  numberOfAudios: 0,
  minWidth: false,
};

export default FindingsAttachmentsCounter;
