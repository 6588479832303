import _get from 'lodash/get';
import { CONSTRUCTION_TYPE, INSPECTION_STATUSES } from 'modules/app/config/config';
import * as yup from 'yup';

export const FINDING_TYPES = {
  ACTIONABLE: {
    label: 'Finding',
    value: 'ACTIONABLE',
    inspectionDraftOnly: false,
  },
  WORK_INFO: {
    label: 'Work Info',
    value: 'WORK_INFO',
    inspectionDraftOnly: false,
  },
  TODO: {
    label: 'To Do',
    value: 'TODO',
    inspectionDraftOnly: true,
  },
};

const requiredText = 'This field is required';

const schemaToSave = {
  serialNumber: yup.string().nullable().max(50),
  location: yup.string().nullable().max(200),
  referenceDocument: yup.string().nullable().max(100),
  failureModeDetails: yup.string().nullable().max(2000),
  description: yup.string().nullable().max(2000),
};

const serialNumber = yup.string().nullable().max(50).required(requiredText).trim();
const location = yup.string().nullable().max(200).required(requiredText).trim();
const referenceDocument = yup.string().nullable().required(requiredText).trim();
const description = yup.string().nullable().max(2000).required(requiredText).trim();
const failureModeId = yup.string().nullable().required(requiredText).trim();
const mainSystemId = yup.string().nullable().required(requiredText).trim();
const subSystemId = yup.string().nullable().required(requiredText).trim();
const findingType = yup
  .string()
  .matches(/^(?!TODO$).+$/, 'To be changed from Todo')
  .required(requiredText)
  .trim();

const todoSchemaToSave = {
  ...schemaToSave,
  description,
};

const ongoingSchemaToSave = {
  ...schemaToSave,
  findingType,
};

const schemaToCloseInspectionConstructionWorkInfo = {
  serialNumber,
  referenceDocument,
  description,
};

const schemaToCloseInspectionConstructionFinding = {
  serialNumber,
  location,
  referenceDocument,
  description,
  failureModeId,
};

const schemaToCloseInspectionOM = {
  description,
  failureModeId,
  subSystemId,
  mainSystemId,
};

class NewFinding {
  static parseErrors(err) {
    const error = {};
    err.inner.forEach((item) => {
      error[item.path] = item.message;
    });
    return error;
  }

  static addInspection(inspection) {
    if (inspection.findings) {
      const inspectionToSave = { ...inspection };
      delete inspectionToSave.findings;

      return inspectionToSave;
    }
    return inspection;
  }

  constructor(finding, inspection = {}, checklist = {}) {
    Object.keys(finding || {}).forEach((key) => {
      this[key] = finding[key];
    });

    if (!this.mainSystem && inspection.mainSystemDetails) {
      this.mainSystem = inspection.mainSystemDetails;
    }

    if (!this.subSystem && checklist.subSystem) {
      this.subSystem = checklist.subSystem;
    }

    if (!this.findingType) {
      this.findingType =
        inspection.status === INSPECTION_STATUSES.draft ? FINDING_TYPES.TODO.value : FINDING_TYPES.ACTIONABLE.value;
    }

    if (!this.subSystem && inspection.subSystemDetails && !checklist.subSystem) {
      this.subSystem = inspection.subSystemDetails;
    }

    if (!this.wbs && inspection.wbsDetails) {
      this.wbs = inspection.wbsDetails;
    }

    if (checklist.id) {
      this.checkListElementDefinitionId = checklist.id;
    }

    if (finding.checkListElementDefinition?.id) {
      this.checkListElementDefinition = finding.checkListElementDefinition;
    }

    this.inspection = NewFinding.addInspection(inspection);
  }

  parseDetails() {
    const findingModel = {
      _id: this._id,
      id: this.id,
      localId: this.frontendId || this._id,
      description: this.description || '',
      serialNumber: this.serialNumber || '',
      location: this.location || '',
      referenceDocument: this.referenceDocument || '',
      findingType: this.findingType || '',
      failureMode: this.failureMode ? this.failureMode.description : '',
      failureModeDetailsObj: (this.failureModeDetailsObj ? this.failureModeDetailsObj : this.failureMode) || {},
      failureModeDetails: this.failureModeDetails || '',
      healthAndSecurity: this.healthAndSecurity || false,
      identifier: this.identifier || '',
      inspectionFunctionalLocation: this.inspectionFunctionalLocation || {},
      mainSystem: this.mainSystem ? this.mainSystem.description : '',
      mainSystemDetails: this.mainSystemDetails ? this.mainSystemDetails : this.mainSystem,
      subSystem: this.subSystem ? this.subSystem.description || `<${this.subSystem.subSystemCode}>` : '',
      subSystemDetails: this.subSystemDetails ? this.subSystemDetails : this.subSystem,
      syncCompletionDate: this.syncCompletionDate,
      wbs: this.wbs,
      wbsDetails: this.wbsDetails ? this.wbsDetails : this.wbs,
      checkListElementDefinitionId: this.checkListElementDefinitionId,
      checkListElementDefinition: this.checkListElementDefinition,
    };

    return findingModel;
  }

  parseDetailsForSynchronization(edit) {
    return {
      id: edit ? this.id : undefined,
      syncId: this.id,
      frontendId: !edit ? this._id : undefined,
      inspectionId: this.inspectionId,
      description: this.description,
      serialNumber: this.serialNumber,
      findingType: this.findingType,
      location: this.location,
      referenceDocument: this.referenceDocument,
      failureModeId: _get(this, 'failureMode.id'),
      failureModeDetails: this.failureModeDetails,
      healthAndSafety: this.healthAndSecurity || false,
      createDate: this.createdAt || this.createDate || new Date().toISOString(),
      modifyDate: edit ? this.updatedAt : this.createdAt,
      mainSystemId: _get(this, 'mainSystem.id'),
      subSystemId: _get(this, 'subSystem.id'),
      checkListElementDefinitionId: this.checkListElementDefinitionId,
    };
  }

  getCloseSchema(inspectionType) {
    if (inspectionType === CONSTRUCTION_TYPE) {
      // for construction
      if (this.findingType === FINDING_TYPES.ACTIONABLE.value) {
        return schemaToCloseInspectionConstructionFinding;
      }
      return schemaToCloseInspectionConstructionWorkInfo;
    }
    // for OM
    return schemaToCloseInspectionOM;
  }

  validateSave() {
    try {
      const closeSchema = this.getCloseSchema(this.inspection.type);

      const inspectionStatus = this.inspection.status;

      let selectedSchema;
      switch (true) {
        case inspectionStatus === INSPECTION_STATUSES.finished:
          selectedSchema = closeSchema;
          break;
        case inspectionStatus === INSPECTION_STATUSES.ongoing || inspectionStatus === INSPECTION_STATUSES.planned:
          selectedSchema = ongoingSchemaToSave;
          break;
        case this.findingType === FINDING_TYPES.TODO.value:
          selectedSchema = todoSchemaToSave;
          break;
        default:
          selectedSchema = schemaToSave;
          break;
      }

      // for construction without main system in case of finding created from checklist item with sub system
      if (this.subSystem) {
        selectedSchema = { ...selectedSchema, mainSystemId };
      }

      const schema = yup.object().shape(selectedSchema);

      schema.validateSync(this.parseDetailsForSynchronization(), {
        abortEarly: false,
      });

      return false;
    } catch (err) {
      return NewFinding.parseErrors(err);
    }
  }

  validateCloseInspection(model = this, inspection) {
    try {
      const schemaToClose = this.getCloseSchema(_get(inspection, 'type'));
      const schema = yup.object().shape(schemaToClose);
      schema.validateSync(model, {
        abortEarly: false,
      });

      return false;
    } catch (err) {
      return NewFinding.parseErrors(err);
    }
  }
}

export default NewFinding;
