import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createCrumb } from 'modules/app/components/app/app.actions';
import Loader from 'modules/common/components/loader/loader.component';
import Table, { TableUniqueKey } from 'modules/common/components/table';
import PropTypes from 'prop-types';

import PieChart from '../charts/pieChart.component';
import {
  WORK_PACKAGES_TABLE_CONFIG,
  SUPPLIERS_TABLE_CONFIG,
  UNASSIGNED_FINDINGS_TABLE_CONFIG,
  WORKFLOWS_TABLE_CONFIG,
} from './tables.config';

const WORKFLOW_STATUS_DELETED = 'DELETED';

const Dashboard = ({ match: { path }, filters, history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createCrumb(path));
  }, []);

  const charts = useSelector((state) => state.dashboard.charts);
  const {
    closedNonConformities,
    openNonConformities,
    closedObservations,
    openObservations,
    findingDashboard,
    supplierDashboard,
    children,
  } = charts.data;

  const tables = useSelector((state) => state.dashboard.tables);
  const { observations, nonConformities, findings } = tables.data;

  const WorkflowChart = {
    colors: ['#2071B5', '#FFDA00'],
    data: [
      {
        name: 'NonConformities',
        value: closedNonConformities + openNonConformities,
      },
      {
        name: 'Observations',
        value: closedObservations + openObservations,
      },
    ],
  };

  const FindingsChart = {
    colors: ['#2071B5', '#FFDA00'],
    data: [],
  };

  if (findingDashboard) {
    FindingsChart.data = [
      { name: 'Unassigned', value: findingDashboard.numberOfUnassigned },
      { name: 'Assigned', value: findingDashboard.numberOfAssigned },
    ];
  }

  const isMoreThanZero = (element) => {
    if (element) return element.value > 0;
    return false;
  };

  const onWorkflowRowClick = (_, item) => {
    if (item.status !== WORKFLOW_STATUS_DELETED) {
      history.push(`/workflowDetails/${item.id}`);
    }
  };

  const onFindingRowClick = (e, item) => {
    const {
      target: { className, tagName },
    } = e;

    if (!className.includes('MoreActionMenu') && tagName === 'SPAN') {
      history.push(`/inspectionDetails/${item.inspection.id}/findingDetails/${item.id}`);
    }
  };

  return (
    <>
      <div className="mt-3 d-flex flex-wrap flex-row justify-content-start align-items-start">
        {charts.isLoading && <Loader />}
        {!charts.isLoading && (
          <>
            <div className="w-50 p-2 mb-2">
              <div className="dashboard__header header--small">
                <span>Workflows</span>
              </div>
              {WorkflowChart.data.some(isMoreThanZero) && (
                <PieChart data={WorkflowChart.data} colors={WorkflowChart.colors} />
              )}
            </div>
            {children && children.length > 0 && (
              <div className="w-50 card p-2 dashboard__section mb-2">
                <div className="dashboard__header header--small mb-2">
                  <span>
                    Work Packages Workflows
                    {' ('}
                    <span className="text-warning">OPEN</span>
                    {' / '}
                    <span className="text-info">CLOSED</span>)
                  </span>
                </div>
                <div className="dashboard__data-wrapper card-body">
                  <Table
                    className="mt-3"
                    config={WORK_PACKAGES_TABLE_CONFIG}
                    items={children}
                    tableUniqueKey={TableUniqueKey.DASHBOARD_WORK_PACKAGES}
                  />
                </div>
              </div>
            )}
          </>
        )}
        {filters.park.id && !charts.isLoading && (
          <>
            <div className="w-50 p-2">
              <div className="dashboard__header header--small">
                <span>Findings</span>
              </div>
              {FindingsChart.data.some(isMoreThanZero) && (
                <PieChart data={FindingsChart.data} colors={FindingsChart.colors} />
              )}
            </div>
            {supplierDashboard && supplierDashboard.length > 0 && (
              <div className="w-50 p-2 card dashboard__section">
                <div className="dashboard__header header--small mb-2">
                  <span>
                    Suppliers Workflows
                    {' ('}
                    <span className="text-warning">OPEN</span>
                    {' / '}
                    <span className="text-info">CLOSED</span>)
                  </span>
                </div>
                <div className="dashboard__data-wrapper card-body">
                  <Table
                    className="mt-3"
                    config={SUPPLIERS_TABLE_CONFIG}
                    items={supplierDashboard}
                    tableUniqueKey={TableUniqueKey.DASHBOARD_SUPPLIERS}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {tables.isLoading && <Loader />}
      {!tables.isLoading && (
        <>
          <div className="mt-3 d-flex flex-column justify-content-start align-items-start p-2 card">
            <div className="w-100">
              <div className="dashboard__header header--small">
                <span>Observations</span>
              </div>
            </div>
            <div className="dashboard__data-wrapper w-100 card-body">
              {observations && observations.length > 0 && (
                <Table
                  className="mt-3"
                  config={WORKFLOWS_TABLE_CONFIG}
                  onRowClick={onWorkflowRowClick}
                  items={observations}
                  tableUniqueKey={TableUniqueKey.DASHBOARD_OBSERVATIONS}
                />
              )}
            </div>
          </div>
          <div className="mt-3 d-flex flex-column justify-content-start align-items-start p-2 card">
            <div className="w-100">
              <div className="dashboard__header header--small">
                <span>NonConformities</span>
              </div>
            </div>
            <div className="dashboard__data-wrapper w-100 card-body">
              {nonConformities && nonConformities.length > 0 && (
                <Table
                  className="mt-3"
                  config={WORKFLOWS_TABLE_CONFIG}
                  items={nonConformities}
                  onRowClick={onWorkflowRowClick}
                  tableUniqueKey={TableUniqueKey.DASHBOARD_NON_CONFORMITIES}
                />
              )}
            </div>
          </div>
          <div className="mt-3 d-flex flex-column justify-content-start align-items-start mb-3  p-2 card">
            <div className="w-100">
              <div className="dashboard__header header--small mb-2">
                <span>Unassigned findings</span>
              </div>
            </div>
            <div className="dashboard__data-wrapper w-100 card-body">
              {findings && findings.length > 0 && (
                <Table
                  className="mt-3"
                  config={UNASSIGNED_FINDINGS_TABLE_CONFIG}
                  items={findings}
                  onRowClick={onFindingRowClick}
                  tableUniqueKey={TableUniqueKey.DASHBOARD_FINDINGS}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

Dashboard.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  filters: PropTypes.shape({
    workPackage: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    park: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    supplier: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    siteFacility: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withRouter(Dashboard);
