import React, { Fragment, PureComponent } from 'react';

import PropTypes from 'prop-types';

import Chips from '../../../common/components/chips/chips.component';
import CoInspectorDialog from './coInspectorDialog.component';

class CoInspectors extends PureComponent {
  state = {
    open: false,
  };

  openDialog = () => {
    this.setState({ open: true });
  };

  closeDialog = () => {
    this.selectedItem = undefined;
    this.setState({ open: false });
  };

  handleSaveCoInspector = (item) => {
    const { saveCoInspector, setDirty } = this.props;
    saveCoInspector(item);
    this.closeDialog();
    setDirty();
  };

  handleItemClick = (item) => {
    this.selectedItem = item;
    this.openDialog();
  };

  handleItemDelete = (item) => {
    const { deleteCoInspector, setDirty } = this.props;
    deleteCoInspector(item);
    setDirty();
  };

  render() {
    const { open } = this.state;
    const { coInspectors, className, id, isMobile, disabled } = this.props;

    return (
      <>
        <Chips
          title="Co-Inspectors (optional)"
          icon="person_add_outline"
          className={className}
          onIconClick={this.openDialog}
          onItemClick={this.handleItemClick}
          onItemDelete={this.handleItemDelete}
          items={coInspectors}
          disabled={disabled}
          id={id}
        />
        {open && !disabled && (
          <CoInspectorDialog
            open={open}
            onSave={this.handleSaveCoInspector}
            onClose={this.closeDialog}
            item={this.selectedItem}
            isMobile={isMobile}
          />
        )}
      </>
    );
  }
}

CoInspectors.propTypes = {
  coInspectors: PropTypes.arrayOf(PropTypes.any).isRequired,
  saveCoInspector: PropTypes.func.isRequired,
  deleteCoInspector: PropTypes.func.isRequired,
  className: PropTypes.string,
  setDirty: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CoInspectors.defaultProps = {
  className: '',
  disabled: false,
};

export default CoInspectors;
