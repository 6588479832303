import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import _get from 'lodash/get';
import { FINDING_TYPES } from 'modules/findings/components/newFinding/newFinding.model';
import PropTypes from 'prop-types';
import Acl, { PRIVILEGE_KEYS } from 'services/acl';

import {
  AppBar,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';

import {
  GlobalActionBack,
  GlobalActionEdit,
  GlobalActionMore,
  InspectionStatusOngoing,
  InspectionStatusPlanned,
} from '../../../../assets/icons/index';
import Notifications from '../../../../services/notifications';
import { INSPECTION_STATUSES } from '../../../app/config/config';
import ConfirmModal from '../../../common/components/confirmModal/confirmModal';
import { setSignMode } from '../inspectionDetails/inspectionDetails.actions';
import { INSPECTION_STRUCTURE } from '../newInspection/newInspection.model';

const menuOptions = { vertical: 'top', horizontal: 'right' };

class InspectionDetailsHeader extends PureComponent {
  state = {
    anchorEl: null,
  };

  canBlockForMobile = () => {
    const { inspection } = this.props;

    return (
      this.isLeadInspector() &&
      ![INSPECTION_STATUSES.completed, INSPECTION_STATUSES.finished].includes(inspection.status)
    );
  };

  canEditInspection = (wbs) => Acl.hasPrivilege(PRIVILEGE_KEYS.INSPECTIONS_EDIT, wbs);

  canExecuteInspection = (wbs) => Acl.hasPrivilege(PRIVILEGE_KEYS.INSPECTIONS_EXECUTE, wbs);

  canFinishInspection = (wbs) => Acl.hasPrivilege(PRIVILEGE_KEYS.INSPECTIONS_FINISH, wbs);

  canCloseInspection = (wbs) => Acl.hasPrivilege(PRIVILEGE_KEYS.INSPECTIONS_CLOSE, wbs);

  canDeleteInspection = (wbs) =>
    Acl.hasPrivilege(PRIVILEGE_KEYS.INSPECTIONS_DELETE, wbs) && (this.isCreator() || this.isLeadInspector());

  editBasedOnInspectionStatus = (inspection) => {
    const statusesThatAllowEdit = Object.values(INSPECTION_STATUSES).filter(
      (status) => status !== INSPECTION_STATUSES.completed,
    );

    return statusesThatAllowEdit.includes(inspection.status);
  };

  isCreator = () => {
    const { inspection, user } = this.props;

    if (inspection.status === INSPECTION_STATUSES.draft) {
      return _get(inspection, 'createdBy.id') === _get(user, 'id');
    }

    return true;
  };

  isLeadInspector = () => {
    const { inspection, user } = this.props;

    if (inspection.status !== INSPECTION_STATUSES.draft) {
      return _get(inspection, 'leadInspector.id') === _get(user, 'id');
    }

    return true;
  };

  handleCloseClick = () => {
    const { setSignMode } = this.props;
    setSignMode(false);
    this.props.history.push('/inspections');
  };

  handleMenuOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleExecute = () => {
    this.handleStatusChange(INSPECTION_STATUSES.ongoing);
  };

  shouldValidateChecklist = () => {
    const { isMobile, checklist } = this.props;

    return isMobile && checklist.length;
  };

  isChecklistFilled = () => {
    const { checklist } = this.props;

    return !!checklist.find((item) => !item.isFilled);
  };

  isEditAllowedBasedOnMobileAndStatus = (inspection) =>
    !(inspection.checkedOutForMobile && inspection.status === INSPECTION_STATUSES.ongoing);

  checkMainInspectionForDelete = (inspection) => {
    if (inspection.structureType === INSPECTION_STRUCTURE.main && inspection?.partOfPlanInspections?.length > 0) {
      return inspection.partOfPlanInspections.every((subinspsection) => subinspsection.checkedOutForMobile === false);
    }
    return true;
  };

  handleFinish = () => {
    const { setSignMode, isMobile, inspection, setInspectionStatus } = this.props;

    isMobile
      ? setSignMode(true)
      : setInspectionStatus(inspection.localId || inspection.id, INSPECTION_STATUSES.finished);
    this.handleMenuClose();
  };

  navigateToList = () => {
    const { history } = this.props;
    history.replace('/inspections');
  };

  handleCloseCallBack = () => {};

  handleClose = (e, handleCloseCallBack, closingDate) => {
    const { inspection } = this.props;

    if (inspection.finishReportUrl === null) {
      Notifications.showError('Please wait until report will be ready.');
      return;
    } else {
      this.handleStatusChange(INSPECTION_STATUSES.completed, handleCloseCallBack, closingDate);
    }
  };

  handleStatusChange = (status, cb, closingDate) => {
    const { inspection, setInspectionStatus, isMobile } = this.props;

    this.handleMenuClose();
    setInspectionStatus(isMobile ? inspection.frontendId || inspection._id : inspection.id, status, cb, closingDate);
  };

  getParamId = () => {
    const { location } = this.props;
    return location.pathname.split('inspectionDetails/')[1];
  };

  handleRemoveInspection = async () => {
    const { removeInspection } = this.props;
    this.handleMenuClose();

    await removeInspection(this.getParamId());
    this.navigateToList();
  };

  handleEditInspection = () => {
    const { history, inspection } = this.props;
    if (inspection.status === INSPECTION_STATUSES.finished && inspection.finishReportUrl === null) {
      Notifications.showError('Please wait until report will be ready.');
      return;
    } else {
      history.push(`/editInspection/${this.getParamId()}`);
    }
  };

  render() {
    const { anchorEl } = this.state;
    const { title, inspection, showHeader, isLoggedUserLeadInspector, isMobile, signMode } = this.props;

    if (!showHeader && isMobile) {
      return null;
    }

    const isMenuOpen = !!anchorEl;
    const wbs = _get(inspection, 'wbs.id', null);
    const todos = inspection.findings?.filter((f) => f.findingType === FINDING_TYPES.TODO.value) || [];
    const menu = [];

    if (
      this.editBasedOnInspectionStatus(inspection) &&
      this.isEditAllowedBasedOnMobileAndStatus(inspection) &&
      this.canEditInspection(wbs) &&
      (inspection.structureType !== 'MAIN' || inspection.status !== INSPECTION_STATUSES.completed)
    ) {
      menu.push(
        <MenuItem
          id="inspection-details-edit:li"
          key="inspection-details-edit:li"
          puppet-data="inspection-details-edit"
          onClick={this.handleEditInspection}
        >
          <ListItemIcon>
            <Icon className="svg-medium">
              <GlobalActionEdit className="icon-dark" />
            </Icon>
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>,
      );
    }

    if (inspection.status === INSPECTION_STATUSES.planned && this.canExecuteInspection(wbs)) {
      menu.push(
        <ConfirmModal
          onClose={this.handleMenuClose}
          confirmFn={this.handleExecute}
          confirmTitle="Are you sure you want to execute this inspection?"
          key="inspection-details-execute:li"
        >
          <MenuItem id="inspection-details-execute:li" puppet-data="inspection-details-execute">
            <ListItemIcon>
              <Icon className="svg-medium">
                <InspectionStatusPlanned className="icon-dark" />
              </Icon>
            </ListItemIcon>
            <ListItemText>Execute</ListItemText>
          </MenuItem>
        </ConfirmModal>,
      );
    }

    if (
      inspection.status === INSPECTION_STATUSES.ongoing &&
      inspection.structureType !== INSPECTION_STRUCTURE.main &&
      this.canFinishInspection(wbs) &&
      !signMode
    ) {
      const isChecklistFilled = this.shouldValidateChecklist() && this.isChecklistFilled();
      const isTodosDone = todos.length > 0;

      let confirmationAddon = '';
      switch (true) {
        case isTodosDone && !isChecklistFilled:
          confirmationAddon = `Address incomplete todos. `;
          break;
        case isChecklistFilled && !isTodosDone:
          confirmationAddon = `Address unchecked checklist items. `;
          break;
        case isChecklistFilled && isTodosDone:
          confirmationAddon = `Address unchecked checklist item and incomplete todos. `;
          break;
      }

      menu.push(
        <ConfirmModal
          onClose={this.handleMenuClose}
          confirmFn={this.handleFinish}
          confirmTitle={`${confirmationAddon}Are you sure you want to finish this inspection?`}
          key="inspection-details-execute:li"
        >
          <MenuItem
            id="inspection-details-finish:li"
            key="inspection-details-finish:li"
            puppet-data="inspection-details-finish"
          >
            <ListItemIcon>
              <Icon className="svg-medium">
                <InspectionStatusOngoing className="icon-dark" />
              </Icon>
            </ListItemIcon>
            <ListItemText>Finish</ListItemText>
          </MenuItem>
        </ConfirmModal>,
      );
    }

    if (
      (inspection.status === INSPECTION_STATUSES.finished ||
        (inspection.structureType === INSPECTION_STRUCTURE.main &&
          inspection.status === INSPECTION_STATUSES.ongoing)) &&
      this.canCloseInspection(wbs)
    ) {
      menu.push(
        <ConfirmModal
          onClose={this.handleMenuClose}
          confirmFn={this.handleClose}
          confirmTitle="Are you sure you want to close this inspection?"
          key="inspection-details-close:li"
          withDate
        >
          <MenuItem id="inspection-details-close:li" puppet-data="inspection-details-close">
            <ListItemIcon>
              <Icon className="svg-medium">done_all</Icon>
            </ListItemIcon>
            <ListItemText>Close</ListItemText>
          </MenuItem>
        </ConfirmModal>,
      );
    }

    if (
      this.canDeleteInspection(wbs) &&
      inspection !== INSPECTION_STATUSES.completed &&
      this.checkMainInspectionForDelete(inspection)
    ) {
      menu.push(
        <ConfirmModal
          onClose={this.handleMenuClose}
          confirmFn={this.handleRemoveInspection}
          confirmTitle={`Are you sure you want to ${
            inspection.status === INSPECTION_STATUSES.draft ? 'delete' : 'invalidate'
          } this inspection?`}
          key="inspection-details-delete:li"
        >
          <MenuItem id="inspection-details-delete:li" puppet-data="inspection-details-delete">
            <ListItemIcon>
              <Icon>delete</Icon>
            </ListItemIcon>
            <ListItemText>{inspection.status === INSPECTION_STATUSES.draft ? 'Delete' : 'Invalidate'}</ListItemText>
          </MenuItem>
        </ConfirmModal>,
      );
    }

    const renderMenu = (
      <Menu
        id="inspection-details-menu:ul"
        anchorEl={anchorEl}
        anchorOrigin={menuOptions}
        transformOrigin={menuOptions}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        {menu}
      </Menu>
    );

    if (isMobile) {
      return (
        <>
          <AppBar position="fixed" color="primary" className="header-component">
            <Toolbar>
              <IconButton
                id="go-back:button"
                className="left-button"
                aria-label="Go back"
                onClick={this.handleCloseClick}
              >
                <Icon className="svg-medium">
                  <GlobalActionBack className="icon-dark" />
                </Icon>
              </IconButton>
              <Typography variant="h6" noWrap>
                {title}
              </Typography>
              <div className="grow" />
              {(!isLoggedUserLeadInspector && inspection.status === INSPECTION_STATUSES.planned) ||
              !menu.length ? null : (
                <IconButton
                  id="menu-open:button-mobile"
                  puppet-data="menu-open:button-mobile"
                  className="right-button"
                  aria-label="Menu"
                  onClick={this.handleMenuOpen}
                >
                  <Icon className="svg-medium">
                    <GlobalActionMore className="icon-dark" />
                  </Icon>
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          {renderMenu}
        </>
      );
    }

    if (menu.length > 0) {
      return (
        <>
          <IconButton
            id="menu-open:button"
            puppet-data="menu-open:button"
            className="right-button"
            aria-label="Menu"
            onClick={this.handleMenuOpen}
          >
            <Icon className="svg-medium">
              <GlobalActionMore className="icon-dark" />
            </Icon>
          </IconButton>
          {renderMenu}
        </>
      );
    }

    return null;
  }
}

InspectionDetailsHeader.propTypes = {
  title: PropTypes.string,
  inspection: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  setInspectionStatus: PropTypes.func.isRequired,
  showHeader: PropTypes.bool.isRequired,
  setSignMode: PropTypes.func.isRequired,
  signMode: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  checklist: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

InspectionDetailsHeader.defaultProps = {
  title: 'Inspection',
};

export default withRouter(InspectionDetailsHeader);
