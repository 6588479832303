import React from 'react';

import { string, instanceOf, oneOfType, shape, func, bool } from 'prop-types';

import { AppBar, Dialog, IconButton, Slide, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
  appBar: {
    position: 'relative',
    backgroundColor: '#fff',
  },
  flex: {
    flex: 1,
  },
  imgContainer: {
    position: 'relative',
    flex: 1,
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const ImgDialog = ({ classes, img, onClose, open }) => (
  <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar className="save-header-web-component p-0">
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="title" color="inherit" className={classes.flex}>
            Edited picture
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.imgContainer}>
        <img src={img} alt="Cropped" className={classes.img} />
      </div>
    </div>
  </Dialog>
);

ImgDialog.propTypes = {
  classes: shape({}).isRequired,
  img: oneOfType([instanceOf(Blob), instanceOf(ArrayBuffer), string]).isRequired,
  onClose: func.isRequired,
  open: bool,
};

ImgDialog.defaultProps = {
  open: false,
};

export default withStyles(styles)(ImgDialog);
