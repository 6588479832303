import { markAttachmentAsMain } from 'modules/inspections/components/inspectionDetails/inspectionDetails.actions';
import Api from 'services/api';
import Db from 'services/db';
import Notifications from 'services/notifications';

const deleteAttachmentMobile = async (data) => {
  try {
    if (data.path) {
      return;
    }

    const attachment = await Db.get('sync_attachments', data._id);
    await Db.delete('sync_attachments', attachment._id, attachment._rev);

    const file = await Db.get('sync_attachments_files', data._id);
    await Db.delete('sync_attachments_files', file._id, file._rev);

    if (attachment.sync) {
      const { thumbnail, ...toSyncDoc } = attachment;
      await Db.insert('sync_attachments_delete', toSyncDoc);
    }

    Notifications.showSuccess('Attachment has been deleted');
  } catch (e) {
    console.log('deleteAttachmentMobile', e);
    throw e;
  }
};

const deleteAttachmentWeb = async (id, showNotification) => {
  try {
    await Api.delete(`/api/attachments/${id}`);
    if (showNotification) {
      Notifications.showSuccess('Attachment has been deleted');
    }
  } catch (e) {
    console.log('deleteAttachmentWeb', e);
    throw e;
  }
};

const deleteAttachment = (data, id, isMobile, showNotification = true) => {
  if (isMobile) {
    return deleteAttachmentMobile(data);
  }

  return deleteAttachmentWeb(id, showNotification);
};

const markAttachmentMobile = async (data) => {
  try {
    if (data.file) {
      const current = await Db.findOne(
        'sync_attachments',
        {
          selector: {
            itemId: { $eq: data.itemId },
            marked: { $eq: true },
          },
        },
        false,
      );

      if (current) {
        await Db.retryUntilWritten('sync_attachments', current._id, (org) => ({
          ...org,
          marked: false,
        }));
      }
      await Db.retryUntilWritten('sync_attachments', data._id, (org) => ({
        ...org,
        marked: true,
      }));

      if (data.sync) {
        const updatedDoc = {
          ...data,
          marked: true,
          updatedAt: new Date().toISOString(),
        };
        await Db.retryUntilWritten('sync_attachments_edit', data._id, (o = {}) => ({ ...o, ...updatedDoc }));
      }
    } else {
      await Api.put('/api/attachments', { ...data, main: true });
    }
  } catch (e) {
    console.log('markAttachment', e);
    throw e;
  }
};

const markAttachmentWeb = (data) => async (dispatch) => {
  try {
    const markedAsMain = await Api.put('/api/attachments', {
      ...data,
      main: true,
    });

    dispatch(markAttachmentAsMain(markedAsMain));

    return markedAsMain;
  } catch (e) {
    throw e;
  }
};

const markAttachment = (data, isMobile) => async (dispatch) => {
  if (isMobile) {
    return markAttachmentMobile(data);
  }

  return dispatch(markAttachmentWeb(data));
};

const downloadAttachment = async (path) => Api.get(path, null, 'blob');

export { deleteAttachment, deleteAttachmentMobile, markAttachment, markAttachmentMobile, downloadAttachment };
