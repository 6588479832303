import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

export const menuOptions = { vertical: 'top', horizontal: 'right' };

const withAttachmentPreviewHeader = (HeaderComponent) => {
  class AttachmentPreviewHeader extends PureComponent {
    state = {
      anchorEl: null,
    };

    handleMenuOpen = (event) => {
      this.setState({
        anchorEl: event.currentTarget,
      });
    };

    handleMenuClose = () => {
      this.setState({
        anchorEl: null,
      });
    };

    handleMark = () => {
      const { onMark } = this.props;
      onMark();
      this.handleMenuClose();
    };

    handleConfirmDelete = () => {
      const { onDelete } = this.props;
      onDelete();
      this.handleMenuClose();
    };

    handleEditClick = () => {
      const { onEdit } = this.props;
      onEdit();
      this.handleMenuClose();
    };

    render() {
      const { anchorEl } = this.state;

      return (
        <HeaderComponent
          anchorEl={anchorEl}
          handleMenuOpen={this.handleMenuOpen}
          handleMenuClose={this.handleMenuClose}
          handleMark={this.handleMark}
          handleConfirmDelete={this.handleConfirmDelete}
          handleEditClick={this.handleEditClick}
          {...this.props}
        />
      );
    }
  }
  AttachmentPreviewHeader.propTypes = {
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onMark: PropTypes.func.isRequired,
    canEdit: PropTypes.bool.isRequired,
  };
  return AttachmentPreviewHeader;
};

export default withAttachmentPreviewHeader;
