class NewWebInspection {
  constructor(inspection) {
    Object.keys(inspection || {}).forEach((key) => {
      this[key] = inspection[key];
    });
  }

  parseDetails() {
    return {
      _id: this._id,
      id: this.id,
      checkedOutForMobile: this.checkedOutForMobile,
      closeReportAttachmentId: this.closeReportAttachmentId,
      finishReportAttachmentId: this.finishReportAttachmentId,
      closeReportUrl: this.closeReportUrl,
      findings: Array(this.numberOfFindings) || [],
      finishReportUrl: this.finishReportUrl,
      frontendId: this.frontendId,
      functionalLocation: this.functionalLocationCode || '',
      lastActivityDate: this.lastActivityDate || '',
      leadInspector: this.leadInspector || '',
      localId: this.frontendId || this._id,
      mainInspectionId: this.mainInspectionId,
      numberOfFindings: this.numberOfFindings,
      numberOfSynchronizedFindings: this.numberOfSynchronizedFindings,
      partOfPlanInspections: this.partOfPlanInspections || [],
      siteFacility: this.siteFacility || '',
      status: this.status || '',
      structureType: this.structureType,
      title: this.title || '',
      type: this.type || '',
      wbs: this.wbs || '',
    };
  }
}

export default NewWebInspection;
