import { saveAs } from 'file-saver';

import Api from '../../../../services/api';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'WORKFLOW_DETAILS';
const CLEAR_DATA = `${namespace}_CLEAR_DATA`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const SET_WORKFLOW = `${namespace}_SET_WORKFLOW`;
const SET_PARTICIPANTS = `${namespace}_SET_PARTICIPANTS`;
const GET_NC_REPORT = `${namespace}_GET_NC_REPORT`;

/*
 * REDUX ACTIONS
 */

const getNcReport = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });
    const response = await Api.get(`/api/attachments/${id}/download`, null, 'blob');
    const jsonBlob = new Blob([response], { type: 'charset=utf-8' });
    saveAs(jsonBlob, 'NonConformityReport.pdf');
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const generateNCReport = (id, attachments, action) => async (dispatch) => {
  const data = {
    workflowId: id,
    attachmentIds: attachments,
  };
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });
    const NCReport = await Api.put(`/api/workflows/${action}`, data);
    await dispatch(getNcReport(NCReport.reportAttachmentId));

    dispatch({
      type: GET_NC_REPORT,
      payload: NCReport,
    });
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const getParticipants = (id) => async (dispatch) => {
  const { workflowPartUserRoleDetails } = await Api.get(`/api/user-management/workflow-role-users/${id}`);

  const participants = Object.keys(workflowPartUserRoleDetails).reduce((acc, role) => {
    acc.push({
      role,
      ...workflowPartUserRoleDetails[role],
    });
    return acc;
  }, []);

  dispatch({
    type: SET_PARTICIPANTS,
    payload: participants,
  });
};

const getWorkflow =
  (id, loadParticipants = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_IS_LOADING,
        payload: true,
      });

      const workflow = await Api.get(`/api/workflows/${id}`);

      if (loadParticipants) {
        await dispatch(getParticipants(id));
      }

      dispatch({
        type: SET_WORKFLOW,
        payload: workflow,
      });

      return workflow;
    } catch (e) {
      dispatch({
        type: SET_IS_LOADING,
        payload: false,
      });

      throw e;
    }
  };

const removeWorkflow = (id) =>
  async function remove() {
    try {
      await Api.delete(`/api/workflows/${id}`);
    } catch (e) {
      throw e;
    }
  };

const invalidateWorkflow = async (params) => {
  try {
    await Api.put(`/api/workflows/invalidate`, params);
  } catch (e) {
    throw e;
  }
};

const startWorkflow = (id) =>
  async function start() {
    try {
      await Api.post(`/api/workflows/${id}/start`);
    } catch (e) {
      throw e;
    }
  };

const changeDueDate = (workflowId, dueDate) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const workflow = await Api.put('/api/workflows/due-date', {
      workflowId,
      dueDate,
    });

    dispatch({
      type: SET_WORKFLOW,
      payload: workflow,
    });

    return workflow;
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const createNc = (workflowId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const workflow = await Api.post(`/api/workflows/observation/${workflowId}/to-nonconformity`);
    return workflow.id;
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const clearData = () => ({
  type: CLEAR_DATA,
});

const setIsLoading = (payload) => ({
  type: SET_IS_LOADING,
  payload,
});

export {
  clearData,
  getWorkflow,
  removeWorkflow,
  invalidateWorkflow,
  startWorkflow,
  setIsLoading,
  changeDueDate,
  createNc,
  generateNCReport,
  getNcReport,
};

export { CLEAR_DATA, SET_IS_LOADING, SET_WORKFLOW, SET_PARTICIPANTS, GET_NC_REPORT };
