import { connect } from 'react-redux';

import { setIsToastVisible } from 'modules/app/components/app/app.actions';

import Toast from './toast.component';

const mapDispatchToProps = {
  setIsToastVisible,
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(Toast);
