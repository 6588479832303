import { Dispatch } from 'react';

import { AREA_OPTIONS, SEVERITY_OPTIONS } from 'modules/workflows/components/newWorkflow/newWorkflow.model';
import Api from 'services/api';
import Db from 'services/db';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'FILTERS';
const SET_ITEMS = `${namespace}_SET_ITEMS`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const CLEAR_DATA = `${namespace}_CLEAR_DATA`;
const SET_SIMILAR_FILTERS_FROM = `${namespace}_SET_SIMILAR_FILTERS_FROM`;

const descriptionComparator = (obj1, obj2) =>
  (obj1.description.toLowerCase() > obj2.description.toLowerCase() && 1) || -1;
const packageComparator = (obj1, obj2) => (obj1.name.toLowerCase() > obj2.name.toLowerCase() && 1) || -1;
const lastNameComparator = (obj1, obj2) => (obj1.lastName.toLowerCase() > obj2.lastName.toLowerCase() && 1) || -1;

export const filterAPIConfig = {
  failureMode: {
    fetch: false,
    getFromDB: 'failure_modes',
    sortingFunction: (failureMode1, failureMode2) => descriptionComparator(failureMode1, failureMode2),
    subSortingFunction: (failureMode1, failureMode2) => descriptionComparator(failureMode1, failureMode2),
  },
  mainSystem: {
    fetch: false,
    getFromDB: 'main_systems',
    sortingFunction: (mainSystem1, mainSystem2) => descriptionComparator(mainSystem1, mainSystem2),
  },
  subSystem: {
    fetch: false,
    getFromDB: 'sub_systems',
    sortingFunction: (subSystem1, subSystem2) => descriptionComparator(subSystem1, subSystem2),
    subSortingFunction: (subSystem1, subSystem2) => descriptionComparator(subSystem1, subSystem2),
    filterData: (data) => data.active,
  },
  wbs: {
    fetch: false,
    getFromDB: 'wbs_all',
    sortingFunction: (wbs1, wbs2) => descriptionComparator(wbs1, wbs2),
    subSortingFunction: (failureMode1, failureMode2) => packageComparator(failureMode1, failureMode2),
  },
  inspector: {
    fetch: true,
    getUrl: () => `/api/user-management/roles/INSPECTOR`,
    sortingFunction: (inspector1, inspector2) => lastNameComparator(inspector1, inspector2),
  },
  actionee: {
    fetch: true,
    getUrl: (user) => `/api/user-management/roles/${user.roles[user.roles.length - 1]}`,
  },
  siteFacility: {
    fetch: false,
    getFromDB: 'facilities',
  },
  severity: {
    data: [...SEVERITY_OPTIONS.values()].map(({ value, label }) => ({
      id: value,
      label: label,
    })),
  },
  area: {
    data: [...AREA_OPTIONS.values()].map(({ value, label }) => ({
      id: value,
      label: label,
    })),
  },
};

type FilterApiConfig = typeof filterAPIConfig;
export type FilterApiConfigKeys = keyof FilterApiConfig;
export type FilterApiConfigItem = FilterApiConfig[FilterApiConfigKeys];

const getSortingForItem = (filterApiSingleConfiguration) => {
  if (filterApiSingleConfiguration.sortingFunction) {
    return filterApiSingleConfiguration.sortingFunction;
  }
  return null;
};

const getSubSortingForItem = (filterApiSingleConfiguration) => {
  if (filterApiSingleConfiguration.subSortingFunction) {
    return filterApiSingleConfiguration.subSortingFunction;
  }
  return null;
};

/*
 * REDUX ACTIONS
 */

interface GenericAction {
  type: string;
  payload?: any;
}

interface GetFiltersAction extends GenericAction {
  type: typeof SET_ITEMS;
  payload: any;
}

interface SetItemsAction extends GenericAction {
  type: typeof SET_ITEMS;
  payload: any;
}

interface SetIsLoadingAction extends GenericAction {
  type: typeof SET_IS_LOADING;
  payload: boolean;
}

interface ClearDataAction extends GenericAction {
  type: typeof CLEAR_DATA;
}

interface SetSimilarFiltersFromAction extends GenericAction {
  type: typeof SET_SIMILAR_FILTERS_FROM;
  payload: any;
}

export type GlobalFilterActionsTypes =
  | GetFiltersAction
  | ClearDataAction
  | SetSimilarFiltersFromAction
  | SetIsLoadingAction
  | SetItemsAction
  | GenericAction;

const getFilters = (user, config) => async (dispatch: Dispatch<GlobalFilterActionsTypes>) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const content = {};

    if (config?.initialFilters?.filters) {
      for (const item of Object.keys(config?.initialFilters?.filters)) {
        if (filterAPIConfig[item]) {
          if (filterAPIConfig[item].fetch) {
            content[item] = await Api.get(filterAPIConfig[item].getUrl(user));
          }
          if (filterAPIConfig[item].getFromDB) {
            const data = await Db.getAll(filterAPIConfig[item].getFromDB);
            content[item] = Db.mapData(data);
          }

          if (filterAPIConfig[item].data) {
            content[item] = filterAPIConfig[item].data;
          }

          if (filterAPIConfig[item].filterData) {
            content[item] = content[item].filter(filterAPIConfig[item].filterData);
          }
        }
      }
    }

    if (config?.additionalFilters) {
      Object.entries(config.additionalFilters).forEach(([key, val]) => {
        content[key] = val;
      });
    }

    dispatch({
      type: SET_ITEMS,
      payload: content,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const clearData = (): GlobalFilterActionsTypes => ({ type: CLEAR_DATA });

const setSimilarFiltersFrom = (item): GlobalFilterActionsTypes => ({
  type: SET_SIMILAR_FILTERS_FROM,
  payload: { item },
});

export { clearData, getFilters, setSimilarFiltersFrom };

export { CLEAR_DATA, SET_IS_LOADING, SET_ITEMS, SET_SIMILAR_FILTERS_FROM };
