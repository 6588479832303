import React, { PureComponent, Fragment } from 'react';

import { GlobalActionBack, GlobalActionMore } from 'assets/icons/index';
import PropTypes from 'prop-types';

import { AppBar, Toolbar, IconButton, Icon, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

import ConfirmModal from '../confirmModal/confirmModal';
import withAttachmentPreviewHeader, { menuOptions } from './attachmentPreviewHeader.hoc';

class AttachmentPreviewHeaderMobile extends PureComponent {
  renderMenu = () => {
    const { isImage, anchorEl, handleConfirmDelete, handleEditClick, handleMark, handleMenuClose } = this.props;

    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={menuOptions}
        transformOrigin={menuOptions}
        open={!!anchorEl}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon>
            <Icon>edit</Icon>
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <ConfirmModal confirmFn={handleConfirmDelete} confirmTitle="Are you sure you want to delete this attachment?">
          <MenuItem>
            <ListItemIcon>
              <Icon>delete</Icon>
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </ConfirmModal>
        {isImage && (
          <MenuItem onClick={handleMark}>
            <ListItemIcon>
              <Icon>star</Icon>
            </ListItemIcon>
            <ListItemText>Mark as main</ListItemText>
          </MenuItem>
        )}
      </Menu>
    );
  };

  render() {
    const { onClose, canEdit, handleMenuOpen } = this.props;

    return (
      <>
        <AppBar position="fixed" color="primary" className="header-component">
          <Toolbar>
            <IconButton className="left-button" aria-label="Go back" onClick={onClose}>
              <Icon className="svg-medium">
                <GlobalActionBack className="icon-dark" />
              </Icon>
            </IconButton>
            <div className="grow" />
            {canEdit && (
              <IconButton className="right-button" aria-label="Menu" onClick={handleMenuOpen}>
                <Icon className="svg-medium">
                  <GlobalActionMore className="icon-dark" />
                </Icon>
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        {canEdit && this.renderMenu()}
      </>
    );
  }
}

AttachmentPreviewHeaderMobile.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleConfirmDelete: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  handleMark: PropTypes.func.isRequired,
  handleMenuOpen: PropTypes.func.isRequired,
  isImage: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  anchorEl: PropTypes.shape({}),
};

AttachmentPreviewHeaderMobile.defaultProps = {
  anchorEl: null,
};

export default withAttachmentPreviewHeader(AttachmentPreviewHeaderMobile);
