/* eslint-disable no-await-in-loop */

/* eslint-disable no-restricted-syntax */
import findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import {
  incrementProgress,
  setSyncIndicator,
  setSyncProgress,
  setSyncStarted,
  setSyncStatus,
} from 'modules/app/components/app/app.actions';
import { updateFindingAfterSync } from 'modules/inspections/components/inspectionDetails/inspectionDetails.actions';
import NoSleep from 'nosleep.js';

import {
  CONNECTION_STATUS_ID,
  FILE_PARENTS,
  INSPECTION_STATUSES,
  STATUS_TO_ENDPOINT,
  SYNC_ACTIONS_TYPES,
  SYNC_ERRORS_TO_SKIP,
} from '../modules/app/config/config';
import { bufferToBlob, generateUId } from '../modules/app/helpers/utils';
import NewFindingModel, { FINDING_TYPES } from '../modules/findings/components/newFinding/newFinding.model';
import NewInspectionModel, {
  INSPECTION_STRUCTURE,
} from '../modules/inspections/components/newInspection/newInspection.model';
import Api from './api';
import Db from './db';
import appInsights from './telemetry';

class Sync {
  q = [];

  async deleteFile(id) {
    const file = await Db.get('sync_attachments_files', id);
    await Db.delete('sync_attachments_files', file._id, file._rev);
  }

  setStore(storeMiddleware) {
    this.dispatch = storeMiddleware.dispatch;
    this.getState = storeMiddleware.getState;
  }

  async parseAttachment(data, finding) {
    const formData = new FormData();

    let file = await Db.getAttachment('sync_attachments_files', data._id, data._id);

    if (typeof file === 'string') {
      file = await (await fetch(`data:${data.mimeType};base64,${file}`)).blob();
    } else if (file instanceof ArrayBuffer) {
      file = bufferToBlob(file, data.mimeType);
    }

    formData.append('file', file);
    formData.append(
      'attachment',
      JSON.stringify({
        parentId: finding.id,
        parentType: FILE_PARENTS.finding,
        title: data.title,
        description: data.description,
        frontendId: data._id,
        createDate: data.createdAt,
        modifyDate: data.createdAt,
        main: data.marked,
      }),
    );
    return formData;
  }

  async syncAttachment(data, doc, errors) {
    console.log(`sync attachment: `, data);
    try {
      const inspection = await Db.findOne('inspections', data.inspectionId, false);

      if (!doc) {
        throw new Error('No finding for given attachment');
      }

      const parsedData = await this.parseAttachment(data, doc);
      const { id } = await Api.post('/api/attachments', parsedData);

      const { id: newId, rev: newRev } = await Db.retryUntilWritten('sync_attachments', data._id, (o) => ({
        ...o,
        id,
        sync: true,
      }));
      await Db.retryUntilWritten('sync_attachments_files', data._id, (o) => ({
        ...o,
        sync: true,
      }));

      if (inspection.status === INSPECTION_STATUSES.completed) {
        await Db.delete('sync_attachments', newId, newRev);
        await this.deleteFile(data._id);
      }
    } catch (e) {
      this.addError(errors, SYNC_ACTIONS_TYPES.ATTACHMENT_ADD, data, e, 'sync_attachments');
    }
  }

  async syncAttachments(findingId, doc, errors) {
    const { docs } = await Db.find('sync_attachments', {
      selector: {
        sync: { $eq: false },
        itemId: { $eq: findingId },
      },
    });

    appInsights.trackEvent({ name: 'SYNC_ATTACHEMENTS' }, { docs });

    if (docs.length > 0) {
      this.dispatch(
        setSyncProgress({
          attachmentsTotal: docs.length,
          attachments: 0,
        }),
      );
    }
    for (const attachment of docs) {
      await this.syncAttachment(attachment, doc, errors);
      this.dispatch(incrementProgress('attachments'));
    }
  }

  async syncAttachmentDelete(data, errors) {
    try {
      if (!data.id) {
        throw new Error('no attachment id');
      }

      await Api.delete(`/api/attachments/${data.id}`);
      await Db.delete('sync_attachments_delete', data._id, data._rev);
    } catch (e) {
      this.addError(errors, SYNC_ACTIONS_TYPES.ATTACHMENT_DELETE, data, e, 'sync_attachments_delete');
    }
  }

  async syncAttachmentsDelete(findingId, errors) {
    const { docs } = await Db.find('sync_attachments_delete', {
      selector: {
        itemId: { $eq: findingId },
      },
    });
    appInsights.trackEvent({ name: 'SYNC_ATTACHEMENTS' }, { action: 'DELETE', docs });
    console.log('sync attachments delete', docs);

    if (docs.length > 0) {
      this.dispatch(
        setSyncProgress({
          attachmentsTotal: docs.length,
          attachments: 0,
        }),
      );
    }
    await Promise.all(
      docs.map(async (i) => {
        await this.syncAttachmentDelete(i, errors);
        this.dispatch(incrementProgress('attachments'));
      }),
    );
  }

  parseAttachmentEdit(data, finding) {
    return {
      id: data.id,
      findingId: finding.id,
      description: data.description,
      createDate: data.createdAt || data.createDate,
      modifyDate: data.updatedAt,
      main: data.marked,
      title: data.title,
    };
  }

  async syncAttachmentEdit(data, doc, errors) {
    try {
      if (!data.id) {
        throw new Error('no attachment id');
      }

      if (!doc) {
        throw new Error('No finding for given attachment');
      }

      const parsedData = this.parseAttachmentEdit(data, doc);
      await Api.put('/api/attachments', parsedData);

      await Db.delete('sync_attachments_edit', data._id, data._rev);
    } catch (e) {
      this.addError(errors, SYNC_ACTIONS_TYPES.ATTACHMENT_EDIT, data, e, 'sync_attachments_edit');
    }
  }

  async syncAttachmentsEdit(findingId, doc, errors) {
    const { docs } = await Db.find('sync_attachments_edit', {
      selector: {
        sync: { $eq: false },
        itemId: { $eq: findingId },
      },
    });

    console.log('sync attachments edit', docs);
    appInsights.trackEvent({ name: 'SYNC_ATTACHEMENTS' }, { action: 'EDIT', docs });

    if (docs.length > 0) {
      this.dispatch(
        setSyncProgress({
          attachmentsTotal: docs.length,
          attachments: 0,
        }),
      );
    }
    await Promise.all(
      docs.map(async (i) => {
        await this.syncAttachmentEdit(i, doc, errors);
        this.dispatch(incrementProgress('attachments'));
      }),
    );
  }

  async syncFinding(finding, method = 'post', errors, appInit, errorCb) {
    let inspection;

    try {
      appInsights.trackEvent({ name: 'SYNC_FINDING' }, { action: method, finding });
      console.log(`sync finding ${method}`, finding._id);
      inspection = await Db.findOne('inspections', finding.inspectionLocalId, false);
      if (inspection && inspection.id) {
        const parsedData = new NewFindingModel(
          { ...finding, inspectionId: inspection.id },
          inspection,
        ).parseDetailsForSynchronization(method !== 'post');
        const doc = await Api[method]('/api/findings', parsedData);

        if (appInit) {
          this.syncAttachments(finding._id, doc, errors)
            .then(() => this.syncAttachmentsEdit(finding._id, doc, errors))
            .then(() => this.syncAttachmentsDelete(finding._id, errors))
            .then(() => {
              if (errors.length) {
                throw new Error('sync error');
              }
            })
            .catch(() => {
              errorCb(errors);
            });
        } else {
          await this.syncAttachments(finding._id, doc, errors);
          await this.syncAttachmentsEdit(finding._id, doc, errors);
          await this.syncAttachmentsDelete(finding._id, errors);
        }

        await Api.put(`/api/findings/${doc.id}/sync-completed`);
        await Db.retryUntilWritten('inspections', inspection._id, (o) => {
          const newDoc = { ...o };
          const findingIndex = findIndex(newDoc.findings, {
            id: method === 'post' ? finding._id : finding.id,
          });

          if (findingIndex > -1) {
            newDoc.findings[findingIndex] = {
              ...doc,
              syncCompletionDate: new Date().toISOString(),
            };
            return newDoc;
          }
          return null;
        });

        this.dispatch(updateFindingAfterSync(doc));
      }

      await Db.delete(method === 'post' ? 'sync_findings' : 'sync_findings_edit', finding._id, finding._rev);
    } catch (e) {
      this.addError(
        errors,
        method === 'put' ? SYNC_ACTIONS_TYPES.FINDING_EDIT : SYNC_ACTIONS_TYPES.FINDING_ADD,
        {
          finding,
          inspection,
          _id: finding._id,
          _rev: finding._rev,
        },
        e,
        method === 'post' ? 'sync_findings' : 'sync_findings_edit',
      );
    }
  }

  async syncFindings(edit = false, errors, appInit, errorCb) {
    const data = await Db.getAll(edit ? 'sync_findings_edit' : 'sync_findings');
    const findingsAndTodos = Db.mapData(data);
    const findings = findingsAndTodos.filter((items) => items.findingType !== FINDING_TYPES.TODO.value);

    appInsights.trackEvent({ name: 'SYNC_FINDINGS' }, { action: edit, findings });

    this.dispatch(
      setSyncProgress({
        findingsTotal: findings.length,
        findings: 0,
        attachmentsTotal: 0,
        attachments: 0,
      }),
    );
    for (const finding of findings) {
      await this.syncFinding(finding, edit ? 'put' : 'post', errors, appInit, errorCb);
      this.dispatch(incrementProgress('findings'));
    }
  }

  async syncFindingDelete(data, errors) {
    try {
      if (!data.id) {
        throw new Error('no inspection id');
      }

      await Api.delete(`/api/findings/${data.id}`);
      await Db.delete('sync_findings_delete', data._id, data._rev);
    } catch (e) {
      this.addError(errors, SYNC_ACTIONS_TYPES.FINDING_DELETE, data, e, 'sync_findings_delete');
    }
  }

  async syncFindingsDelete(errors) {
    const data = await Db.getAll('sync_findings_delete');
    const findings = Db.mapData(data);

    appInsights.trackEvent({ name: 'SYNC_FINDINGS' }, { action: 'DELETE', findings });
    this.dispatch(
      setSyncProgress({
        findingsTotal: findings.length,
        findings: 0,
      }),
    );
    console.log('sync findings delete', findings);
    await Promise.all(
      findings.map(async (i) => {
        await this.syncFindingDelete(i, errors);
        this.dispatch(incrementProgress('findings'));
      }),
    );
  }

  async syncStatus(data, status, errors) {
    let inspection;
    try {
      inspection = await Db.findOne('inspections', data.inspectionId, false);

      if (inspection && inspection.id) {
        let params = {
          changeDate: data.changeDate,
          inspectionId: inspection.id,
        };

        if (status === INSPECTION_STATUSES.finished) {
          const finishData = (data.extraData || []).reduce((acc, curr) => {
            const { leadInspector, ...rest } = curr;
            const parsedRest = {
              ...rest,
              imageBytes: rest.imageBytes && rest.imageBytes.split(',')[1],
            };

            if (leadInspector) {
              acc.inspectorSignature = parsedRest;
            } else {
              if (!acc.coInspectorSignatures) {
                acc.coInspectorSignatures = [];
              }

              acc.coInspectorSignatures.push(parsedRest);
            }

            return acc;
          }, {});
          params = { ...params, ...finishData };
        }

        await Api.post(`/api/inspections/${STATUS_TO_ENDPOINT[status]}`, params);

        if (status === INSPECTION_STATUSES.completed) {
          await Db.deleteAll('sync_attachments', {
            selector: {
              inspectionId: { $eq: inspection.frontendId },
              sync: { $eq: true },
            },
          });

          await Db.deleteAll('sync_attachments_files', {
            selector: {
              inspectionId: { $eq: inspection.frontendId },
              sync: { $eq: true },
            },
          });
        }

        if ([INSPECTION_STATUSES.finished].includes(status)) {
          await Api.delete(`/api/inspections/${inspection.id}/checkout-for-mobile`);
        }
      }

      await Db.delete(`sync_inspections_statuses_${status}`, data._id, data._rev);
    } catch (e) {
      if (SYNC_ERRORS_TO_SKIP.includes(_get(e, 'code'))) {
        try {
          await Db.delete(`sync_inspections_statuses_${status}`, data._id, data._rev);
        } catch (err) {
          this.addError(
            errors,
            SYNC_ACTIONS_TYPES.INSPECTION_STATUS,
            { ...data, inspection },
            err,
            `sync_inspections_statuses_${status}`,
          );
        }
      } else {
        this.addError(
          errors,
          SYNC_ACTIONS_TYPES.INSPECTION_STATUS,
          { ...data, inspection },
          e,
          `sync_inspections_statuses_${status}`,
        );
      }
    }
  }

  async syncStatuses(status, main, errors) {
    const { docs } = await Db.find(`sync_inspections_statuses_${status}`, {
      selector: {
        main: { $eq: main },
      },
    });

    appInsights.trackEvent({ name: 'SYNC_STATUSES' }, { type: main, docs, status });

    console.log(`sync statuses ${main ? 'main' : ''} ${status}`, docs);
    await Promise.all(docs.map((i) => this.syncStatus(i, status, errors)));
  }

  async parseInspection(data, edit = false) {
    return new NewInspectionModel(data).parseDetailsForSynchronization(edit);
  }

  async syncInspection(data, errors) {
    try {
      const parsedData = await this.parseInspection(data);
      const doc = await Api.post('/api/inspections', parsedData);
      const { findings, status, ...rest } = doc; // overwrite everything except findings and status

      await Db.retryUntilWritten('inspections', data.localId, (o) => ({
        ...o,
        ...rest,
      }));

      await Db.delete('sync_inspections', data._id, data._rev);
    } catch (e) {
      this.addError(errors, SYNC_ACTIONS_TYPES.INSPECTION_ADD, data, e, 'sync_inspections');
    }
  }

  async syncInspections(main, errors) {
    const { docs } = await Db.find('sync_inspections', {
      selector: {
        structureType: {
          $eq: main ? INSPECTION_STRUCTURE.main : INSPECTION_STRUCTURE.standalone,
        },
      },
    });

    appInsights.trackEvent({ name: 'SYNC_INSPECTIONS' }, { type: main, docs });
    console.log(`sync inspections ${main ? 'main' : 'sub'}`, docs);

    await Promise.all(docs.map(async (i) => this.syncInspection(i, errors)));
  }

  async syncInspectionEdit(data, errors) {
    try {
      if (!data.id) {
        throw new Error('no inspection id');
      }

      const parsedData = await this.parseInspection(data, true);

      await Api.put('/api/inspections', parsedData);
      await Db.delete('sync_inspections_edit', data._id, data._rev);
    } catch (e) {
      this.addError(errors, SYNC_ACTIONS_TYPES.INSPECTION_EDIT, data, e, 'sync_inspections_edit');
    }
  }

  async syncInspectionsEdit(main, errors) {
    const { docs } = await Db.find('sync_inspections_edit', {
      selector: {
        structureType: {
          $eq: main ? INSPECTION_STRUCTURE.main : INSPECTION_STRUCTURE.standalone,
        },
      },
    });

    appInsights.trackEvent(
      { name: 'SYNC_INSPECTIONS' },
      {
        action: 'EDIT',
        type: main,
        docs,
      },
    );
    console.log(`sync inspections edit ${main ? 'main' : 'sub'}`, docs);
    await Promise.all(docs.map((i) => this.syncInspectionEdit(i, errors)));
  }

  async syncInspectionDelete(data, errors) {
    try {
      if (!data.id) {
        throw new Error('no inspection id');
      }

      await Api.delete(`/api/inspections/${data.id}`);
      await Db.delete('sync_inspections_delete', data._id, data._rev);
    } catch (e) {
      this.addError(errors, SYNC_ACTIONS_TYPES.INSPECTION_DELETE, data, e, 'sync_inspections_delete');
    }
  }

  async syncChecklist(checklist, errors) {
    let inspection;
    try {
      if (!checklist?.inspectionId) {
        return;
      }

      inspection = await Db.findOne('inspections', checklist.inspectionId, false);

      if (inspection && inspection.id) {
        if (inspection.status === INSPECTION_STATUSES.completed) {
          appInsights.trackEvent({ name: 'DELETE_CHECKLIST' }, { checklist });
          console.log('delete checklist', checklist);
          await Db.delete('sync_inspections_checklist', checklist._id, checklist._rev);
        } else {
          appInsights.trackEvent({ name: 'SYNC_CHECKLIST' }, { checklist });
          console.log('sync checklist', checklist);
          await Api.post(`/api/checklists/inspections/${inspection.id}`, checklist.elements);
        }
      }
    } catch (e) {
      this.addError(
        errors,
        SYNC_ACTIONS_TYPES.CHECKLIST_SYNC,
        {
          checklist,
          inspection,
          _id: checklist._id,
          _rev: checklist._rev,
        },
        e,
        'sync_inspections_checklist',
      );
    }
  }

  async syncChecklists(errors) {
    const data = await Db.getAll('sync_inspections_checklist');
    const checklists = Db.mapData(data).filter((i) => 'elements' in i);
    appInsights.trackEvent({ name: 'SYNC_CHECKLISTS' }, { docs: checklists });
    console.log('sync checklists', checklists);

    if (checklists.length) {
      await this.syncChecklist(checklists[checklists.length - 1], errors);
    }
  }

  async syncInspectionsDelete(errors) {
    const data = await Db.getAll('sync_inspections_delete');
    const inspections = Db.mapData(data);
    appInsights.trackEvent(
      { name: 'SYNC_INSPECTIONS' },
      {
        action: 'DELETE',
        docs: inspections,
      },
    );
    console.log('sync inspections delete', inspections);
    await Promise.all(inspections.map((i) => this.syncInspectionDelete(i, errors)));
  }

  async syncMissingAttachments(errors) {
    const { docs } = await Db.find('sync_attachments', {
      selector: {
        sync: { $eq: false },
      },
    });
    const { rows } = await Db.getAll('inspections');
    const findings = rows.reduce((acc, curr) => {
      if (curr.doc.findings) acc.push(...curr.doc.findings);
      return acc;
    }, []);
    await Promise.all(
      docs.map(async (doc) => {
        const finding = findings.find((i) => i.frontendId === doc.itemId);
        if (finding) await this.syncAttachment(doc, finding, errors);
      }),
    );
  }

  registerSync = async (errorCb) => {
    try {
      if (this.inProgress) {
        this.q.push(Date.now());
        return;
      }

      this.inProgress = true;
      await this.syncAll();
      this.inProgress = false;
      const firstFromQ = this.q.shift();

      if (firstFromQ) {
        this.registerSync(errorCb);
      }
    } catch (e) {
      this.inProgress = false;
      errorCb(e);
    }
  };

  async syncAll(appInit = false, errorCb) {
    let wakeLock;
    try {
      wakeLock = new NoSleep();
      await wakeLock.enable();
    } catch (err) {
      console.log('No Sleep error, proceeding with sync anyways');
    }

    const errors = [];

    try {
      if (!appInit) {
        await this.checkToken();
      }

      const connectionStatusDoc = await Db.findOne('connection_status', CONNECTION_STATUS_ID, false);

      if ((!connectionStatusDoc || _get(connectionStatusDoc, 'online') === true) && navigator.onLine) {
        this.dispatch(setSyncIndicator(true));
        this.dispatch(setSyncStatus(true));
        this.dispatch(setSyncStarted(true));
        await this.syncInspections(true, errors);
        await this.syncInspectionsEdit(true, errors);
        await this.syncStatuses(INSPECTION_STATUSES.planned, true, errors);
        await this.syncStatuses(INSPECTION_STATUSES.ongoing, true, errors);

        await this.syncInspections(false, errors);
        await this.syncInspectionsEdit(false, errors);
        await this.syncStatuses(INSPECTION_STATUSES.planned, false, errors);
        await this.syncStatuses(INSPECTION_STATUSES.ongoing, false, errors);

        await this.syncFindings(false, errors, appInit, errorCb);
        await this.syncFindings(true, errors, appInit, errorCb);

        await this.syncStatuses(INSPECTION_STATUSES.finished, false, errors);
        await this.syncStatuses(INSPECTION_STATUSES.finished, true, errors);

        await this.syncChecklists(errors);

        await this.syncFindingsDelete(errors);
        await this.syncInspectionsDelete(errors);

        await this.syncStatuses(INSPECTION_STATUSES.completed, false, errors);
        await this.syncStatuses(INSPECTION_STATUSES.completed, true, errors);

        await this.syncMissingAttachments(errors);

        this.dispatch(setSyncStatus(false));
        this.dispatch(setSyncIndicator(false));

        if (errors.length) {
          throw new Error('sync error');
        }
      }
    } catch (e) {
      console.log('sync errors', errors);

      throw errors;
    } finally {
      wakeLock.disable();
    }
  }

  addError(errors, type, data, error, db) {
    appInsights.trackException(error);

    errors.push({
      key: generateUId(),
      type,
      data,
      error,
      db,
    });
  }

  async checkToken() {
    const data = await Db.get('token', 'token');
    Db.setUserId(_get(data, 'decoded.oid'));
  }

  getContext() {
    try {
      return window;
    } catch (e) {
      return self; // eslint-disable-line
    }
  }
}

export default new Sync();
