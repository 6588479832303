import React, { PureComponent, Fragment, memo } from 'react';

import { PropTypes } from 'prop-types';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { USER_ROLES } from '../../../app/config/config';
import Loader from '../../../common/components/loader/loader.component';
import Table, { TableUniqueKey } from '../../../common/components/table';

const styles = (theme) => ({
  link: {
    color: theme.palette.secondary.light,
  },
});

const PrimaryLink = memo(
  withStyles(styles)(({ className, classes, href, value }) => (
    <a href={href} className={`${className || ''} ${classes.link}`}>
      {value}
    </a>
  )),
);

const TABLE_CONFIG = {
  columns: [
    {
      caption: 'Park',
      dataField: 'wbs.rootDescription',
      renderCell: (_, item) => {
        const { rootDescription, description } = item.wbs;
        return (
          <Typography color="textSecondary" variant="caption" style={{ width: '100%' }}>
            {rootDescription || description}
          </Typography>
        );
      },
      isHidable: true,
      align: 'left',
    },
    {
      caption: 'Work package',
      dataField: 'wbs',
      renderCell: (_, item) => {
        const { id, description, rootDescription } = item.wbs;

        return (
          <u>
            <PrimaryLink href={`/admin/wbses/${id}`} value={rootDescription ? description : ''} />
          </u>
        );
      },
      isHidable: true,
      align: 'center',
    },
    {
      caption: 'Role',
      dataField: 'userRole',
      renderCell: (dataValue) => {
        const role = USER_ROLES?.find((roleItem) => roleItem.key === dataValue);
        return (
          <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
            {role?.label || '-'}
          </Typography>
        );
      },
      isHidable: true,
      align: 'right',
    },
  ],
  noItemsFoundText: 'No user roles found',
  initialSort: {
    sortField: 'wbs.description',
    sortDirection: 'asc',
  },
};

class UserDetailsWeb extends PureComponent {
  async componentDidMount() {
    const {
      getUser,
      match: { path, params },
      createCrumb,
    } = this.props;
    await getUser(params.id);

    createCrumb(path, params);
  }

  componentWillUnmount() {
    const { clearData } = this.props;
    clearData();
  }

  render() {
    const { user, roles, isLoading } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        <div className="row no-gutters align-item-center pt-4 pb-4">
          <div>
            <Typography color="textPrimary" variant="h6">
              {user.fullName}
            </Typography>
            {user.company && (
              <Typography className="pt-2" color="textSecondary" variant="body1">
                {user.company}
              </Typography>
            )}
            {user.email && (
              <PrimaryLink href={`mailto:${user.email}`} value={user.email} className="pt-3" variant="body1" />
            )}
            {user.phone && (
              <Typography className="pt-2" color="textSecondary" variant="body1">
                {user.phone}
              </Typography>
            )}
          </div>
        </div>

        <Typography color="textPrimary" variant="body1">
          User Roles
        </Typography>

        <Table className="mt-3" config={TABLE_CONFIG} items={roles} tableUniqueKey={TableUniqueKey.USER_DETAILS} />
      </>
    );
  }
}

UserDetailsWeb.propTypes = {
  user: PropTypes.objectOf(PropTypes.shape).isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape).isRequired,
  isLoading: PropTypes.bool.isRequired,
  clearData: PropTypes.func.isRequired,
};

export default UserDetailsWeb;
