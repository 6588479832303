import React from 'react';

import { DATE_FORMAT } from 'modules/app/config/config';
import { charsLeft, renderItemFacilities } from 'modules/app/helpers/utils';
import DatepickerWrapper from 'modules/common/components/datepickerWrapper/datepickerWrapper.component';
import FileUploadZone from 'modules/common/components/fileUpload/fileUpload.component';
import FilteringTree from 'modules/common/components/filteringTree/filteringTree';
import InputModal from 'modules/common/components/inputModal/inputModal.container';
import { InputLoader } from 'modules/common/components/loader/loader.component';
import SaveHeaderWeb from 'modules/common/components/saveHeader/saveHeaderWeb.component';
import Facilities from 'modules/inspections/components/facilities/facilities.container';
import { getMainSystems } from 'modules/inspections/components/newInspection/newInspection.actions';

import {
  FormControl,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  Tooltip,
  Typography,
  Badge,
  Icon,
} from '@material-ui/core';

import { getSubSystemsFromMultipleMainSystems } from './newWorkflow.actions';
import { AREA_OPTIONS, SERIAL_DEFECTS, SEVERITY_OPTIONS } from './newWorkflow.model';

const CustomTooltipContent = () => (
  <>
    <Typography style={{ marginBottom: '16px' }}>
      High: Remedy is needed immediately to keep safe operations and to ensure integrity throughout the design lifetime.
      And/Or, the amount of similar findings across all assets indicates a serial defect that needs a remedy campaign or
      a retrofit to ensure efficient operation and maintenance throughout the remaining design lifetime.
    </Typography>
    <Typography style={{ marginBottom: '16px' }}>
      Moderate: Remedy or additional monitoring is needed during the next service campaign. And/Or the amount of similar
      findings across all assets indicates a serial defect which is adding significant maintenance demand if not
      remedied.
    </Typography>
    <Typography style={{ marginBottom: '16px' }}>
      Low: Not affecting the integrity of the asset if not remedied within the next year. And/Or the finding is on a
      wear-part, which is expected to fail at some point in time, and the maintenance/repair is within the expected
      scope of maintenance throughout the lifetime.
    </Typography>
    <Typography>Observatic: Leave as is/No discussion needed to be solved during next maintenance.</Typography>
  </>
);

const DefectNotification = ({
  deleteFile,
  errors,
  files,
  formDataIsLoading,
  handleChange,
  handleChangeRole,
  handleSelectChange,
  handleSubmit,
  history,
  isCreate,
  mainSystemIdsCheckFunction,
  newWorkflow,
  roles,
  setFieldValue,
  setFiles,
  setMainSystemIds,
  title,
  workPackage,
  withRelatedFindings,
}) => (
  <div className="mt-3 p-3 shadow rounded">
    <SaveHeaderWeb title={title} onSave={handleSubmit} history={history} />

    <div id="new-finding:div" className="content-wrapper row no-gutters p-0" puppet-data="new-workflow">
      {!withRelatedFindings && (
        <>
          <FormControl className="col-md-4 pr-md-3">
            <InputModal
              id="wbsId"
              title="Work package"
              placeholder="Work Package"
              fieldName="wbs"
              value={newWorkflow.wbs}
              clearFieldValue={handleChangeRole}
              error={!!errors && !!errors.wbs}
              errorText={errors && errors.wbs}
              blockRootClick
              isLoadingIndicator={formDataIsLoading}
              workPackage
            >
              <FilteringTree
                onSelect={handleChangeRole}
                nodes={workPackage}
                searchPlaceholderText="Search for Work Package"
                primaryTextField="description"
                secondaryTextField="name"
                alternativePrimaryTextField="name"
                sortProperty="name"
                checkForActive
              />
            </InputModal>
          </FormControl>

          <InputModal
            className="col-md-4 pr-md-3"
            id="mainSystems"
            title="Main systems"
            placeholder="Main systems"
            fieldName="mainSystems"
            value={(newWorkflow.mainSystems || []).map((ms) => ms.description)}
            clearFieldValue={setMainSystemIds}
            getNodes={getMainSystems}
            getNodesParams={newWorkflow}
            error={!!errors && !!errors.mainSystems}
            errorText={errors && errors.mainSystems}
            isLoadingIndicator={formDataIsLoading}
          >
            <FilteringTree
              checkFunction={mainSystemIdsCheckFunction}
              searchPlaceholderText="Search for Main Systems"
              primaryTextField="description"
              secondaryTextField="mainSystemCode"
              checkForActive
              onSelect={setMainSystemIds}
              withCheck
              dontClose
            />
          </InputModal>

          <InputModal
            className="col-md-4 pr-md-3"
            id="subSystem"
            title="Sub system"
            placeholder="Sub system (optional)"
            fieldName="subSystem"
            value={newWorkflow.subSystem}
            clearFieldValue={setFieldValue}
            getNodes={getSubSystemsFromMultipleMainSystems}
            getNodesParams={newWorkflow}
            error={!!errors && !!errors.subSystem}
            errorText={errors && errors.subSystem}
            isLoadingIndicator={formDataIsLoading}
          >
            <FilteringTree
              onSelect={setFieldValue}
              searchPlaceholderText="Search for Sub System"
              primaryTextField="description"
              secondaryTextField="subSystemCode"
              checkForActive
            />
          </InputModal>
        </>
      )}
      <FormControl className="col-md-4 mt-4 pr-md-3" error={!!(errors && errors.creatorRole)}>
        <InputLabel htmlFor="creatorRole">Creator`s role</InputLabel>
        <Select
          value={newWorkflow.creatorRole}
          onChange={handleSelectChange}
          className="select"
          puppet-data="creatorsRole"
          inputProps={{
            id: 'creatorRole',
            name: 'creatorRole',
          }}
          error={!!errors && !!errors.creatorRole}
          IconComponent={formDataIsLoading ? InputLoader : undefined}
        >
          {roles.map((role) => (
            <MenuItem key={role} value={role} puppet-data={`${role}-select`}>
              {role}
            </MenuItem>
          ))}
        </Select>
        {errors && errors.creatorRole && <FormHelperText>{errors.creatorRole}</FormHelperText>}
      </FormControl>

      <FormControl className="col-md-8 mt-4 pr-md-3">
        <TextField
          label="Title"
          id="title"
          name="title"
          value={newWorkflow.title}
          onChange={handleChange}
          error={errors && !!errors.title}
          helperText={(errors && errors.title) || charsLeft(newWorkflow.title, 200)}
          multiline
        />
      </FormControl>

      <FormControl className="col-md-4 mt-4 pr-md-3">
        <InputLabel htmlFor="severity">Area (optional)</InputLabel>

        <Select
          name="area"
          id="area"
          onChange={handleSelectChange}
          autoWidth
          value={newWorkflow.area}
          defaultValue={null}
        >
          {[...AREA_OPTIONS.values()].map((area) => (
            <MenuItem key={`area-option-${area.value}`} value={area.value}>
              {!area.value && <>{area.label}</>}
              {area.value && `${area.value} (${area.label})`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Facilities
        className="col-md-4 pr-md-3"
        fieldName="siteFacility"
        clearFieldValue={setFieldValue}
        onSelect={setFieldValue}
        value={newWorkflow.siteFacility}
        error={!!errors && !!errors.siteFacility}
        errorText={errors && errors.siteFacility}
        renderItem={renderItemFacilities}
        rowHeight={65}
        extraParam={newWorkflow}
        checkForActive
        customLabel="Contractor"
        customSearchPlaceholder="Search for contractor"
      />

      <FormControl className="col-md-4 mt-4 pr-md-3">
        <TextField
          label="Reference"
          id="reference"
          name="reference"
          value={newWorkflow.reference}
          onChange={handleChange}
          error={errors && !!errors.reference}
          helperText={(errors && errors.reference) || charsLeft(newWorkflow.reference, 255)}
          multiline
        />
      </FormControl>

      <FormControl className="col-md-4 mt-4 pr-md-3">
        <TextField
          label="Legal Entity"
          id="legalEntity"
          name="legalEntity"
          value={newWorkflow.legalEntity}
          onChange={handleChange}
          error={errors && !!errors.legalEntity}
          helperText={(errors && errors.legalEntity) || charsLeft(newWorkflow.legalEntity, 255)}
          multiline
        />
      </FormControl>

      <FormControl className="col-md-4 mt-4 pr-md-3">
        <InputLabel htmlFor="serialDefect">Serial defect</InputLabel>
        <Select
          name="serialDefect"
          id="serialDefect"
          onChange={handleSelectChange}
          defaultValue={SERIAL_DEFECTS[1].value}
          autoWidth
          value={newWorkflow.serialDefect}
        >
          {SERIAL_DEFECTS.map((serial) => (
            <MenuItem key={`serial-defect-option-${serial.value}`} value={serial.value}>
              {serial.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className="col-md-4 mt-4 pr-md-3">
        <DatepickerWrapper
          label="Liability period (from)"
          format={DATE_FORMAT}
          value={newWorkflow.defectLiabilityFrom}
          onChange={setFieldValue}
          name="defectLiabilityFrom"
          clearable
          animateYearScrolling={false}
          error={errors && !!errors.defectLiabilityFrom}
        />
      </FormControl>

      <FormControl className="col-md-4 mt-4 pr-md-3">
        <DatepickerWrapper
          label="Liability period (to)"
          format={DATE_FORMAT}
          value={newWorkflow.defectLiabilityTo}
          onChange={setFieldValue}
          name="defectLiabilityTo"
          clearable
          animateYearScrolling={false}
          error={errors && !!errors.defectLiabilityTo}
        />
      </FormControl>

      <FormControl className="col-md-8 mt-4 pr-md-3">
        <TextField
          label="Description"
          id="description"
          name="description"
          multiline
          value={newWorkflow.description}
          onChange={handleChange}
          error={!!errors && !!errors.description}
          helperText={(errors && errors.description) || charsLeft(newWorkflow.description, 2000)}
        />
      </FormControl>

      <FormControl className="col-md-4 mt-4 pr-md-3">
        <InputLabel htmlFor="severity">
          <Badge
            badgeContent={
              <Tooltip title={<CustomTooltipContent />} placement="right" arrow>
                <Icon style={{ marginLeft: '25px', color: '#8a8a8a' }} fontSize="small">
                  info
                </Icon>
              </Tooltip>
            }
          >
            Severity (optional)
          </Badge>
        </InputLabel>

        <Select
          name="severity"
          id="severity"
          onChange={handleSelectChange}
          autoWidth
          value={newWorkflow.severity}
          defaultValue={null}
        >
          {[...SEVERITY_OPTIONS.values()].map((severity) => (
            <MenuItem key={`severity-option-${severity.value}`} value={severity.value}>
              {severity.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {isCreate && <FileUploadZone setFiles={setFiles} files={files} handleDelete={deleteFile} />}
    </div>
  </div>
);

export default DefectNotification;
