import { useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import update from 'immutability-helper';

export const useDnD = (ref, id, index, move, changeOrder, type) => {
  const [{ handlerId }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (drag < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (drag > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      move(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: () => ({ id, index }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: () => changeOrder(),
  });

  return { drag, drop, isDragging, handlerId };
};

export const useDnDMove = (initialState) => {
  const [items, setItems] = useState(initialState);

  useEffect(() => {
    if (initialState.length !== items.length) {
      setItems(initialState);
    } else {
      setItems(initialState.sort((a, b) => a.orderNumber - b.orderNumber));
    }
  }, [initialState]);

  const moveItem = (dragIndex, hoverIndex) => {
    setItems((prevCards) => {
      const state = prevCards.length > 0 ? prevCards : initialState;
      return update(state, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, state[dragIndex]],
        ],
      });
    });
  };

  return [items, moveItem];
};
