export default class Checklist {
  constructor(template, elements, inspectionId) {
    this.template = template;
    this.elements = elements;
    this.inspectionId = inspectionId;
    this.checklistId = template.id;
    this.subGroupsToBeFiltered = [];
    this.isFilled = true;
  }

  parseSubGroups(group) {
    return group.childrenCheckListGroupDefinitions?.map((subGroup) => {
      if (subGroup.childrenCheckListGroupDefinitions > 0) {
        this.parseSubGroups(subGroup);
      }
      return this.template.checkListGroupDefinitions.find((item) => {
        this.subGroupsToBeFiltered.push(item.id);
        return item.id === subGroup.id;
      });
    });
  }

  parseElements(group) {
    const elements = group.checkListElementDefinitions?.map((element) => {
      const elementsDetails = this.elements.find((item) => item.checkListElementDefinitionId === element.id);
      if (elementsDetails) {
        return {
          ...element,
          ...elementsDetails,
        };
      }
      return {
        ...element,
        isChecked: false,
        findings: [],
      };
    });

    elements.forEach((item) => {
      if (!item.isChecked) {
        this.isFilled = false;
      }
    });

    return elements;
  }

  createChecklist() {
    if (Object.keys(this.template).length === 0) {
      return {
        checklist: [],
        inspectionId: this.inspectionId,
        checklistId: this.checklistId,
      };
    }

    this.checklist = this.template.checkListGroupDefinitions.map((group) => ({
      ...group,
      childrenCheckListGroupDefinitions: this.parseSubGroups(group),
      checkListElementDefinitions: this.parseElements(group),
      isFilled: this.isFilled,
    }));

    return {
      checklist: this.checklist?.filter((group) => !this.subGroupsToBeFiltered.includes(group.id)),
      inspectionId: this.inspectionId,
      checklistId: this.checklistId,
    };
  }
}
