import { connect } from 'react-redux';

import SelectList from '../../../common/components/selectList/selectList.component';
import { getFacilities as getData } from '../newInspection/newInspection.actions';

const mapStateToProps = (state) => ({
  data: state.newInspection.facilities,
  id: 'facilities',
  searchPlaceholder: 'Search for Site/Facility',
});

const mapDispatchToProps = {
  getData,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectList);
