import _get from 'lodash/get';

import Api from '../../../../services/api';
import Db from '../../../../services/db';
import Notifications from '../../../../services/notifications';
import { registerSync } from '../../../app/components/app/app.actions';
import {
  getInspection,
  SET_INSPECTION,
} from '../../../inspections/components/inspectionDetails/inspectionDetails.actions';
import NewInspection from '../../../inspections/components/newInspection/newInspection.model';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'FINDING';
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const SET_FINDING = `${namespace}_SET_FINDING`;
const CLEAR_DATA = `${namespace}_CLEAR_DATA`;

/*
 * REDUX ACTIONS
 */

const getFindingWeb = (inspectionId, findingId) => async (dispatch) => {
  try {
    const data = await Api.get(`/api/findings/${findingId}`);

    if (inspectionId !== _get(data, 'inspection.id')) {
      throw new Error('Finding not found');
    }

    dispatch({
      type: SET_FINDING,
      payload: {
        data,
        inspectionId,
        findingId,
      },
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const getFindingMobile = (inspectionId, findingId) => async (dispatch) => {
  try {
    const data = await Db.findOne('inspections', inspectionId);
    const inspectionDetails = new NewInspection(data).parseDetails();

    const findingDetails = (inspectionDetails.findings || []).find(
      (finding) => finding.frontendId === findingId || finding.localId === findingId || finding.id === findingId,
    );

    if (!findingDetails) {
      throw new Error('Finding not found');
    }

    dispatch({
      type: SET_INSPECTION,
      payload: inspectionDetails,
    });

    dispatch({
      type: SET_FINDING,
      payload: {
        data: findingDetails,
        inspectionId,
        findingId,
      },
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const getFinding = (inspectionId, findingId) => async (dispatch, getState) => {
  const {
    app: { isMobile },
  } = getState();

  try {
    isMobile
      ? await dispatch(getFindingMobile(inspectionId, findingId))
      : await dispatch(getFindingWeb(inspectionId, findingId));
  } catch (e) {
    throw e;
  }
};

const clearData = () => ({ type: CLEAR_DATA });

const removeFindingMobile = (inspectionId, findingId) => async (dispatch) => {
  try {
    const inspection = await Db.findOne('inspections', inspectionId);

    const findingIndex = (inspection.findings || []).findIndex(
      (finding) => finding.frontendId === findingId || finding.id === findingId,
    );
    const oldFinding = inspection.findings[findingIndex];

    inspection.findings = [
      ...inspection.findings.slice(0, findingIndex),
      ...inspection.findings.slice(findingIndex + 1),
    ];

    await Db.update('inspections', inspection);

    const findingToSync = await Db.findOne('sync_findings', oldFinding.id, false);
    if (findingToSync) {
      await Db.delete('sync_findings', findingToSync._id, findingToSync._rev);
    } else {
      await Db.insert('sync_findings_delete', oldFinding);
    }

    await Db.deleteAll('sync_attachments', {
      selector: {
        findingId: { $eq: findingId },
      },
    });

    dispatch(registerSync());
  } catch (e) {
    throw e;
  }
};

const removeFindingWeb = (findingId) => Api.delete(`/api/findings/${findingId}`);

const removeFinding = (inspectionId, findingId) => async (dispatch, getState) => {
  const {
    app: { isMobile },
  } = getState();

  dispatch({
    type: SET_IS_LOADING,
    payload: true,
  });

  try {
    isMobile ? await dispatch(removeFindingMobile(inspectionId, findingId)) : await removeFindingWeb(findingId);
    dispatch(getInspection(inspectionId));

    Notifications.showSuccess('Finding has been deleted');
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

export { getFinding, clearData, removeFinding };

export { SET_IS_LOADING, SET_FINDING, CLEAR_DATA };
