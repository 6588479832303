import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import _get from 'lodash/get';
import { createCrumb, getData, setShowHeader } from 'modules/app/components/app/app.actions';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { isMobile, isOnline } from 'modules/app/helpers/hooks';
import {
  clearActiveChecklists,
  getActiveChecklistsForPark,
} from 'modules/checklists/components/checklistEditor.actions';
import UnsavedChanges from 'modules/common/components/unsavedChanges/unsavedChanges.hoc';
import {
  disabledIfStatusIsNotDraft,
  disabledIfStatusNotInDraftOrPlanned,
  disableIfUserNotInProperRole,
} from 'modules/inspections/components/newInspection/newInspection.actions';
import PropTypes from 'prop-types';
import Notifications from 'services/notifications';

import { CONSTRUCTION_TYPE, dataToStoreOffline, OM_TYPE } from '../../../app/config/config';
import { getChecklistsByParkId } from '../checklists/checklist.actions';
import {
  clearData as clearInspectionData,
  getInspection,
  getInspectionDetails,
} from '../inspectionDetails/inspectionDetails.actions';
import { cleanData, getWorkPackage, saveInspection, setCoInspectors, updateInspection } from './newInspection.actions';
import NewInspectionModel, { INSPECTION_STRUCTURE } from './newInspection.model';

const mapStateToProps = (state) => ({
  activeChecklists: state.checklistEditor.activeChecklists,
  inspection: state.inspection.data,
  isLoading: state.newInspection.isLoading,
  isLoadingDetails: state.inspection.isLoading,
  isLoadingChecklists: state.checklistEditor.isLoading,
  loggedUser: state.auth.user,
  workPackage: state.newInspection.workPackage,
});

const mapDispatchToProps = {
  clearActiveChecklists,
  cleanData,
  clearInspectionData,
  createCrumb,
  getActiveChecklistsForPark,
  getInspection,
  getInspectionDetails,
  getWorkPackage,
  saveInspection,
  setCoInspectors,
  setShowHeader,
  updateInspection,
};

const withNewInspection = (NewInspectionComponent) => {
  class NewInspection extends PureComponent {
    constructor(props) {
      super();
      const {
        loggedUser,
        match: {
          params: { editInspectionId },
        },
      } = props;

      const { state } = props?.history?.location;

      const inspection = {};
      if (!editInspectionId) {
        inspection.leadInspector = loggedUser;
        inspection.type = state?.type;
      }

      this.state = {
        inspection,
        mainInspection: {},
        checklistsForMobile: [],
      };
    }

    isMob = isMobile();

    async componentDidMount() {
      const {
        setShowHeader,
        getWorkPackage,
        getActiveChecklistsForPark,
        getInspectionDetails,
        getInspection,
        inspection,
        match: {
          params: { inspectionId, editInspectionId },
          path,
        },
      } = this.props;

      setShowHeader(false);
      await getWorkPackage();

      let editedInspection;
      if (path !== '/newInspection') {
        if (inspectionId || _get(inspection, 'mainInspectionId')) {
          const mainInspection = await getInspectionDetails(inspectionId || inspection.mainInspectionId);
          this.setState({
            mainInspection,
          });
        }

        if (editInspectionId) {
          editedInspection = await getInspection(editInspectionId);
        }
      }

      const parkId = inspection?.park?.id || editedInspection?.park?.id;

      if (!!parkId && !this.isMob) await getActiveChecklistsForPark(parkId);

      if (!!parkId && this.isMob) {
        this.setState({
          ...this.state,
          checklistsForMobile: await getChecklistsByParkId(parkId),
        });
      }
    }

    async componentDidUpdate(prevProps) {
      const {
        createCrumb,
        inspection,
        getInspectionDetails,
        match: { path, params },
      } = this.props;

      if (_get(inspection, 'id') !== _get(prevProps, 'inspection.id') && _get(inspection, 'mainInspectionId')) {
        const mainInspection = await getInspectionDetails(inspection.mainInspectionId);

        this.setState({
          mainInspection,
        });
      }

      createCrumb(path, params);
    }

    componentWillUnmount() {
      const { setShowHeader, cleanData, clearInspectionData } = this.props;

      clearInspectionData();
      setShowHeader(true);
      cleanData();
    }

    static getDerivedStateFromProps(props, state) {
      const {
        inspection,
        setCoInspectors,
        match: {
          params: { editInspectionId },
        },
      } = props;

      if ((!!editInspectionId && (state.inspection.id || state.inspection._id)) || !editInspectionId) {
        return null;
      }

      setCoInspectors(inspection.coInspectors);

      return { inspection };
    }

    createNewInspection = () => {
      const { history, inspection, mainInspection } = this.state;

      const type = !Object.keys(mainInspection).length
        ? inspection.type || _get(history, 'location.state.type', CONSTRUCTION_TYPE)
        : mainInspection.type;

      return new NewInspectionModel({ ...inspection, type }, mainInspection);
    };

    validate = (newInspection) => {
      const newErrors = newInspection.validateSave();

      if (newErrors || this.deadlineError) {
        this.setState({
          errors: newErrors,
        });
      }
    };

    handleOnSubmit = async () => {
      const {
        history,
        saveInspection,
        updateInspection,
        match: { params },
        setDirty,
      } = this.props;
      setDirty(false);

      const { inspection, mainInspection } = this.state;

      const type = !Object.keys(mainInspection).length
        ? inspection.type || _get(history, 'location.state.type', CONSTRUCTION_TYPE)
        : mainInspection.type;

      const newInspection = new NewInspectionModel({ ...inspection, type }, mainInspection);

      const newErrors = newInspection.validateSave();

      if (newErrors || this.deadlineError) {
        this.setState({
          errors: newErrors,
        });
        return;
      }

      if (!newInspection._id && params.inspectionId) {
        Object.assign(newInspection, { mainInspectionId: params.inspectionId });
      }

      try {
        let inspectionId;

        if (params.editInspectionId) {
          inspectionId = await updateInspection(newInspection);
        } else {
          inspectionId = await saveInspection(newInspection);
        }
        setDirty(false);

        if (inspectionId) {
          history.replace(`/inspectionDetails/${inspectionId}`);
        }
      } catch (err) {
        genericErrorHandler(err);
      }
    };

    isOM = () => {
      const { history, inspection } = this.props;
      return _get(inspection, 'type') === OM_TYPE || _get(history, 'location.state.type') === OM_TYPE;
    };

    setDeadlineError = (error) => {
      this.deadlineError = error;
    };

    handleChange = (e) => {
      const {
        currentTarget: { name, value },
      } = e;

      this.changeFieldsValue([{ name, value }]);
    };

    handleChangeCheckbox = (e) => {
      const {
        currentTarget: { name, checked, value },
      } = e;

      this.changeFieldsValue([{ name, value: checked ? value : INSPECTION_STRUCTURE.standalone }]);
      if (checked) this.changeFieldsValue([{ name: 'checkListDefinitionId', value: '' }]);

      const newInspection = this.createNewInspection();
      this.validate(newInspection);
    };

    setWbsValue = async (name, value) => {
      const { inspection } = this.state;
      const { loggedUser, getActiveChecklistsForPark } = this.props;

      if (this.isMob) {
        if (isOnline()) {
          try {
            const checklistsDataGetter = dataToStoreOffline.find((d) => d.key === 'checklists');
            getData(checklistsDataGetter);
          } catch (err) {
            genericErrorHandler(err);
          }
        }

        clearActiveChecklists();

        this.setState({
          ...this.state,
          checklistsForMobile: await getChecklistsByParkId(value.parents[0]),
        });
      }

      if (!this.isMob) {
        clearActiveChecklists();

        const parkId = value.parents[0] || this.state.mainInspection.park.id;
        // parkId = for regular inspections || for sub-inspections

        getActiveChecklistsForPark(parkId);
      }

      if (_get(inspection, 'leadInspector.id') !== _get(loggedUser, 'id')) {
        this.changeFieldsValue([{ name: 'leadInspector', value: '' }]);
      }

      if (value && value.parents && value.parents.length) {
        this.changeFieldsValue([{ name: 'park', value: { id: value.parents[0] } }]);
      }

      this.changeFieldsValue([
        { name, value },
        { name: 'mainSystem', value: '' },
        { name: 'subSystem', value: '' },
        { name: 'siteFacility', value: '' },
      ]);
    };

    setChecklistValue = async (value) => {
      const newCheckListDefinitionId = value.target.value;
      const newCheckListDefinition = this.props.activeChecklists.find((c) => c.id === newCheckListDefinitionId);

      this.changeFieldsValue([
        { name: 'checkListDefinitionId', value: newCheckListDefinitionId },
        { name: 'checkListDefinition', value: newCheckListDefinition },
      ]);
    };

    setMainSystemValue = (name, value) => {
      const { getActiveChecklistsForPark } = this.props;

      if (!this.isMob && value?.id) {
        clearActiveChecklists();

        const { inspection, mainInspection } = this.state;

        const parkId = inspection.park?.id || mainInspection?.park.id;
        getActiveChecklistsForPark(parkId, value.id);
      }

      this.changeFieldsValue([
        { name, value },
        { name: 'subSystem', value: '' },
      ]);
    };

    preLeadInspectorClick = (cb) => {
      if (!_get(this.newInspection, 'wbsDetails.id')) {
        Notifications.showError('First select Work Package');
        return;
      }

      cb();
    };

    setFieldValue = (name, value = '') => {
      this.changeFieldsValue([{ name, value }]);
    };

    changeFieldsValue(values = []) {
      const { inspection, mainInspection } = this.state;
      const { setDirty } = this.props;

      values.forEach((item) => {
        inspection[item.name] = item.value;
      });
      const newInspection = new NewInspectionModel(inspection, mainInspection);

      this.setState({
        inspection: newInspection,
      });
      setDirty();
    }

    render() {
      const { match, isLoading, isLoadingDetails, isLoadingChecklists, loggedUser } = this.props;
      const { inspection, mainInspection, errors, checklistsForMobile } = this.state;

      if (!inspection) {
        return null;
      }

      this.newInspection = new NewInspectionModel(inspection, mainInspection).parseDetails();

      let title = match.params.editInspectionId
        ? 'Edit Inspection'
        : `Plan ${this.isOM() ? 'O&M' : 'Construction'} Inspection`;
      if (match.params.inspectionId) {
        title = 'Plan Subinspection';
      }

      const isUserWithoutProperRole = disableIfUserNotInProperRole(loggedUser, inspection, this.isMob);
      const isDisabledForEditionOtherThanDraft = disabledIfStatusIsNotDraft(inspection);
      const isDisabledForEditionOtherThanDraftOrPlanned = disabledIfStatusNotInDraftOrPlanned(inspection);

      const {
        deadlineError,
        handleChange,
        handleChangeCheckbox,
        handleOnSubmit,
        isOM,
        newInspection,
        preLeadInspectorClick,
        setChecklistValue,
        setDeadlineError,
        setFieldValue,
        setMainSystemValue,
        setWbsValue,
      } = this;

      const props = {
        checklistsForMobile,
        deadlineError,
        errors,
        handleChange,
        handleChangeCheckbox,
        handleOnSubmit,
        isDisabledForEditionOtherThanDraft,
        isDisabledForEditionOtherThanDraftOrPlanned,
        isLoading,
        isLoadingDetails,
        isLoadingChecklists,
        isOM,
        mainInspection,
        newInspection,
        isUserWithoutProperRole,
        preLeadInspectorClick,
        setChecklistValue,
        setDeadlineError,
        setFieldValue,
        setMainSystemValue,
        setWbsValue,
        title,
      };

      return <NewInspectionComponent {...this.props} {...props} />;
    }
  }

  NewInspection.defaultProps = {
    errors: {},
  };

  NewInspection.propTypes = {
    setShowHeader: PropTypes.func.isRequired,
    cleanData: PropTypes.func.isRequired,
    clearInspectionData: PropTypes.func.isRequired,
    workPackage: PropTypes.arrayOf(PropTypes.any).isRequired,
    errors: PropTypes.objectOf(PropTypes.any),
  };

  return UnsavedChanges(connect(mapStateToProps, mapDispatchToProps)(NewInspection));
};

const defaultProps = {
  errors: {},
};

const propTypes = {
  workPackage: PropTypes.arrayOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  inspection: PropTypes.objectOf(PropTypes.any).isRequired,
  mainInspection: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
  isOM: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeCheckbox: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
};

export { withNewInspection as default, defaultProps, propTypes };
