import React, { memo } from 'react';

import { FILE_SIZE_LIMIT } from 'modules/app/config/config';

import { Typography } from '@material-ui/core';

interface IFileAllowedProps {
  txt?: string;
}
const FileAllowed = memo(({ txt = 'Only' }: IFileAllowedProps) => (
  <Typography className="text-center" color="textSecondary" variant="caption">
    {`${txt} files with maximum size of ${FILE_SIZE_LIMIT}MB can be uploaded`}
  </Typography>
));

export default FileAllowed;
