import React, { ChangeEvent, FC, MouseEvent, ReactNode, useEffect, useState } from 'react';

import moment from 'moment';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, TextField } from '@material-ui/core';

interface ICallback {
  (): void;
}

interface IParams {
  [key: string]: string;
}

interface IConfirmModalProps {
  cancelLabel?: string;
  children: ReactNode;
  confirmContent?: (params: IParams) => ReactNode;
  confirmFn: (e: MouseEvent<HTMLButtonElement>, ...args: any[]) => void;
  confirmIcon?: string;
  confirmLabel?: string;
  confirmTitle?: string;
  fullScreen?: boolean;
  onClose?: () => void;
  params?: { id: string };
  preOpenFn?: (callback: ICallback) => void;
  promptName?: string;
  promptValue?: string;
  renderPrompt?: ReactNode;
  withDate?: boolean;
}

const ConfirmModal: FC<IConfirmModalProps> = ({
  cancelLabel = 'Cancel',
  children,
  confirmContent,
  confirmFn,
  confirmIcon = '',
  confirmLabel = 'Yes',
  confirmTitle = 'Are you sure you want to delete?',
  fullScreen = false,
  onClose,
  params = {},
  preOpenFn,
  promptName = '',
  promptValue = '',
  renderPrompt,
  withDate = false,
}) => {
  const [state, setState] = useState({
    open: false,
    disabledButton: false,
    closedDate: moment().format('YYYY-MM-DD[T]HH:mm'),
  });

  useEffect(() => {
    if (Boolean(renderPrompt)) {
      setState((state) => ({ ...state, disabledButton: true }));
    }
  }, []);

  useEffect(() => {
    if (Boolean(renderPrompt)) {
      setState((state) => ({ ...state, disabledButton: !promptValue }));
    }
  }, [renderPrompt, promptValue]);

  const openDialog = () => {
    if (preOpenFn) {
      preOpenFn(() => setState((state) => ({ ...state, open: true })));
      return;
    }

    setState((state) => ({ ...state, open: true }));
  };

  const closeDialog = () => {
    setState((state) => ({ ...state, open: false, disabledButton: false }));

    if (onClose) {
      onClose();
    }
  };

  const onConfirmFn = async (e: MouseEvent<HTMLButtonElement>) => {
    setState((state) => ({ ...state, disabledButton: true }));

    if (promptValue && promptName) {
      params[promptName] = promptValue;
    }

    confirmFn(e, params, state.closedDate);

    closeDialog();
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState((state) => ({ ...state, closedDate: e.target.value }));
  };

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child as any, {
      onClick: openDialog,
    }),
  );

  const content = Boolean(open && confirmContent) ? confirmContent(params) : null;
  const confirmButton = confirmIcon ? (
    <Button
      id="confirm:button"
      puppet-data="confirm:button"
      onClick={onConfirmFn}
      color="primary"
      variant="contained"
      disabled={state.disabledButton}
    >
      <Icon className="mr-3">{confirmIcon}</Icon>
      {confirmLabel}
    </Button>
  ) : (
    <Button
      id="confirm:button"
      puppet-data="confirm:button"
      onClick={onConfirmFn}
      color="secondary"
      disabled={state.disabledButton}
    >
      {confirmLabel}
    </Button>
  );

  return (
    <>
      {childrenWithProps}
      <Dialog open={state.open} onClose={closeDialog} fullScreen={fullScreen}>
        <DialogTitle id="alert-dialog-title">{confirmTitle}</DialogTitle>
        {Boolean(open && confirmContent) && <DialogContent id="alert-dialog-text">{content}</DialogContent>}
        {renderPrompt && <DialogContent id="alert-dialog-prompt">{renderPrompt}</DialogContent>}

        {withDate && (
          <TextField
            id="datetime-local"
            label="Closing date"
            type="datetime-local"
            value={state.closedDate}
            onChange={handleDateChange}
            style={{ margin: '0 24px' }}
          />
        )}

        <DialogActions>
          <Button id="confirm-cancel:button" onClick={closeDialog} color="secondary">
            {cancelLabel}{' '}
          </Button>
          {confirmButton}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmModal;
