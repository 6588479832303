import { validateTableItem } from 'modules/common/components/table/table.actions';
import { clearSelectedFindings, selectAll, selectFinding } from 'modules/findings/components/findings/findings.actions';
import { SELECTING_CONFIG } from 'modules/findings/components/findings/findings.config';

import Api from '../../../../services/api';
import Db from '../../../../services/db';
import Notifications from '../../../../services/notifications';
import { registerSync } from '../../../app/components/app/app.actions';
import {
  INSPECTION_STATUSES,
  INSPECTION_STATUSES_DATE_FIELDS,
  OM_TYPE,
  STATUS_TO_ENDPOINT,
} from '../../../app/config/config';
import { LOCAL_COUNTERS_MAP } from '../../../common/components/filesList/filesList.actions';
import NewFinding, { FINDING_TYPES } from '../../../findings/components/newFinding/newFinding.model';
import NewInspection, { INSPECTION_STRUCTURE } from '../newInspection/newInspection.model';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'INSPECTION';
const SET_INSPECTION = `${namespace}_SET_INSPECTIONS`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const CLEAR_DATA = `${namespace}_CLEAR_DATA`;
const SET_INVALID_FINDINGS = `${namespace}_SET_INVALID_FINDINGS`;
const SET_SIGN_MODE = `${namespace}_SET_SIGN_MODE`;
const MARK_ATTACHMENT_AS_MAIN = `${namespace}_MARK_ATTACHMENT_AS_MAIN`;
const UPDATE_FINDING_AFTER_SYNC = `${namespace}_UPDATE_FINDING_AFTER_SYNC`;
const SET_INSPECTION_FINDINGS = `${namespace}_SET_INSPECTION_FINDINGS`;
const GET_TODOS = `${namespace}_GET_TODOS`;
const SET_TODOS = `${namespace}_SET_TODOS`;
const ADD_TODOS = `${namespace}_ADD_TODOS`;

/*
 * REDUX ACTIONS
 */
const validateFinding = (finding, inspection) => {
  const findingModel = new NewFinding(finding, inspection);
  const parsedModel = findingModel.parseDetailsForSynchronization();
  const errors = findingModel.validateCloseInspection(parsedModel, inspection);
  return !errors;
};

const validateInspection = (inspection) => {
  const invalidFindings = [];
  if (inspection && inspection.findings) {
    for (let i = 0; i < inspection.findings.length; i += 1) {
      const finding = inspection.findings[i];
      if (!validateFinding(finding, inspection)) {
        invalidFindings.push(finding.id);
      }
    }
  }

  return invalidFindings;
};

const setInvalidFindings = (invalidFindings) => (dispatch, getState) => {
  const {
    inspection: {
      data: { findings },
    },
  } = getState();

  const newFindings = findings?.map((finding) => ({
    ...finding,
    invalid: invalidFindings.indexOf(finding.id) > -1,
  }));

  dispatch({
    type: SET_INVALID_FINDINGS,
    payload: newFindings,
  });
};

const checkFindings = (inspection, status) => (dispatch) => {
  if (status !== INSPECTION_STATUSES.finished) {
    return;
  }

  const invalidFindings = validateInspection(inspection);

  if (invalidFindings.length) {
    dispatch(setInvalidFindings(invalidFindings));
  }
};

const getInspectionWeb = async (id) => Api.get(`/api/inspections/${id}`);

const getInspectionAttachments = async (inspectionId) => {
  await Db.createIndex('sync_attachments', ['inspectionId']);

  const { docs } = await Db.find('sync_attachments', {
    selector: { inspectionId },
    fields: ['itemId', 'fileType', 'thumbnail', 'marked'],
  });

  return docs;
};

const getInspectionMobile = async (inspectionId) => {
  const inspectionAttachments = await getInspectionAttachments(inspectionId);
  const inspection = await Db.findOne('inspections', inspectionId);
  if (inspectionAttachments.length) {
    const localCounters = inspectionAttachments.reduce((acc, curr) => {
      const data = {
        ...acc,
        [curr.itemId]: {
          ...acc[curr.itemId],
          [LOCAL_COUNTERS_MAP[curr.fileType]]:
            (acc[curr.itemId] && acc[curr.itemId][LOCAL_COUNTERS_MAP[curr.fileType]] + 1) || 1,
          localNumberOfAttachments: (acc[curr.itemId] && acc[curr.itemId].localNumberOfAttachments + 1) || 1,
        },
      };

      if (curr.marked) {
        data[curr.itemId].thumbnail = curr.thumbnail;
      }

      return data;
    }, {});

    inspection.findings = inspection.findings?.map((finding) => {
      const id = finding.frontendId || finding.id;
      return localCounters[id] ? { ...finding, ...localCounters[id] } : { ...finding };
    });
  }

  if (inspection.structureType === INSPECTION_STRUCTURE.main) {
    const { docs } = await Db.find('inspections', {
      selector: {
        mainInspectionId: { $eq: inspection.id || inspection._id },
      },
    });

    inspection.partOfPlanInspections = docs;
  }

  return inspection;
};

const setInspectionFindings = (payload) => async (dispatch) => {
  dispatch({
    type: SET_INSPECTION_FINDINGS,
    payload,
  });
};

const getInspection = (id) => async (dispatch, getState) => {
  const {
    app: { isMobile },
  } = getState();

  dispatch({
    type: SET_IS_LOADING,
    payload: true,
  });

  try {
    const data = isMobile ? await getInspectionMobile(id) : await getInspectionWeb(id);

    dispatch({
      type: SET_INSPECTION,
      payload: data,
    });

    dispatch(checkFindings(data, data.status));

    return data;
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const getInspectionDetails = (id) => async (dispatch, getState) => {
  const {
    app: { isMobile },
  } = getState();

  try {
    const data = isMobile ? await Db.findOne('inspections', id) : await getInspectionWeb(id);
    return new NewInspection(data).parseDetails();
  } catch (e) {
    throw e;
  }
};

const STATUSES_TO_CHECK = [INSPECTION_STATUSES.finished, INSPECTION_STATUSES.completed];

const VALID_STATUSES_TO_FINISH = [
  INSPECTION_STATUSES.draft,
  INSPECTION_STATUSES.finished,
  INSPECTION_STATUSES.completed,
  INSPECTION_STATUSES.invalidated,
];

const VALID_STATUSES_TO_CLOSE = [
  INSPECTION_STATUSES.draft,
  INSPECTION_STATUSES.completed,
  INSPECTION_STATUSES.invalidated,
];

const validateSubInspections = async (inspection, status) => {
  if (inspection.structureType !== INSPECTION_STRUCTURE.main) {
    return;
  }

  if (!STATUSES_TO_CHECK.includes(status)) {
    return;
  }

  const { docs } = await Db.find('inspections', {
    selector: {
      mainInspectionId: { $eq: inspection.id || inspection._id },
    },
  });

  for (let i = 0; i < docs.length; i += 1) {
    if (
      (status === INSPECTION_STATUSES.finished && !VALID_STATUSES_TO_FINISH.includes(docs[i].status)) ||
      (status === INSPECTION_STATUSES.completed && !VALID_STATUSES_TO_CLOSE.includes(docs[i].status))
    ) {
      throw new Error('Subinspection(s) has wrong status');
    }
  }
};

const validateBeforeClose = async (inspection, status) => {
  if (status !== INSPECTION_STATUSES.completed) {
    return;
  }

  const invalidFindings = validateInspection(inspection);

  if (invalidFindings.length) {
    let errorMsg = 'Items marked in orange do not have full data.';

    if (inspection.type === OM_TYPE) {
      errorMsg += ' Please check if all findings have failure mode, subsystem and description provided.';
    }

    throw new Error(errorMsg);
  }

  const { docs: notSyncedAttachments } = await Db.find('sync_attachments', {
    selector: {
      inspectionId: { $eq: inspection.frontendId },
      sync: { $eq: false },
    },
  });

  if (notSyncedAttachments && notSyncedAttachments.length) {
    throw new Error('Finding(s) in this inspection has not synchronized attachment(s)');
  }
};

const setInspectionStatusMobile = (inspectionId, status, extraData) => async (dispatch) => {
  const inspection = await Db.findOne('inspections', inspectionId);

  if (inspection.structureType === INSPECTION_STRUCTURE.main && status === INSPECTION_STATUSES.completed) {
    await dispatch(setInspectionStatusMobile(inspectionId, INSPECTION_STATUSES.finished, extraData));
  }

  await validateBeforeClose(inspection, status);

  inspection.status = status;

  // if main inspection check statuses of subinspections
  await validateSubInspections(inspection, status);

  // check if subinspection and change status of main inspection
  if (inspection.mainInspectionId) {
    const mainInspection = await Db.findOne('inspections', inspection.mainInspectionId);

    if (status === INSPECTION_STATUSES.ongoing) {
      mainInspection.status = status;
      await Db.retryUntilWritten('inspections', mainInspection._id, (obj) => ({
        ...obj,
        ...mainInspection,
      }));
    }
  }

  await Db.retryUntilWritten('inspections', inspection._id, (obj) => ({
    ...obj,
    ...inspection,
  }));

  await Db.retryUntilWritten(
    `sync_inspections_statuses_${status}`,
    {
      selector: {
        inspectionId: { $eq: inspectionId },
      },
    },
    (obj = {}) => ({
      ...obj,
      inspectionId,
      status,
      main: inspection.structureType === INSPECTION_STRUCTURE.main,
      changeDate: new Date().toISOString(),
      extraData,
    }),
  );

  dispatch(registerSync());
};

const setInspectionStatusWeb = (inspectionId, status, changeDate) => async () => {
  const inspection = await getInspectionWeb(inspectionId);

  if (
    inspection.status === INSPECTION_STATUSES.ongoing &&
    inspection.structureType === INSPECTION_STRUCTURE.main &&
    status === INSPECTION_STATUSES.completed
  ) {
    await Api.post(`/api/inspections/${STATUS_TO_ENDPOINT[INSPECTION_STATUSES.finished]}`, {
      changeDate,
      inspectionId,
    });
  }

  await validateBeforeClose(inspection, status);

  inspection.status = status;

  // if main inspection check statuses of subinspections
  await validateSubInspections(inspection, status);

  // check if subinspection and change status of main inspection
  if (inspection.mainInspectionId && status === INSPECTION_STATUSES.ongoing) {
    const mainInspection = await getInspectionWeb(inspection.mainInspectionId);

    if ([INSPECTION_STATUSES.draft, INSPECTION_STATUSES.planned].includes(mainInspection.status)) {
      await Api.post(`/api/inspections/${STATUS_TO_ENDPOINT[status]}`, {
        changeDate,
        inspectionId: inspection.mainInspectionId,
      });
    }
  }

  await Api.post(`/api/inspections/${STATUS_TO_ENDPOINT[status]}`, {
    changeDate,
    inspectionId,
  });

  return getInspectionWeb(inspectionId);
};

const setInspectionStatus =
  (inspectionId, status = INSPECTION_STATUSES.planned, cb, extraData) =>
  async (dispatch, getState) => {
    const {
      app: { isMobile },
      inspection: { data },
    } = getState();

    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    try {
      const date = data.status === INSPECTION_STATUSES.finished ? extraData : new Date().toISOString();
      let inspectionData;

      if (isMobile) {
        await dispatch(setInspectionStatusMobile(inspectionId, status, extraData));
        inspectionData = data;
      } else {
        inspectionData = await dispatch(setInspectionStatusWeb(inspectionId, status, date));
      }

      if (!inspectionData) {
        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });

        return;
      }

      const inspection = {
        ...inspectionData,
        status,
        [INSPECTION_STATUSES_DATE_FIELDS[status]]: date,
      };

      dispatch({
        type: SET_INSPECTION,
        payload: inspection,
      });

      dispatch(checkFindings(inspection, status));

      if (data.status === INSPECTION_STATUSES.ongoing) {
        Notifications.showSuccess(
          !isMobile
            ? 'Inspection has been finished. Report generation is in progress. Please do not modify nor close inspection until you receive notification email'
            : 'Status has been changed.',
        );
      } else {
        Notifications.showSuccess('Status has been changed');
      }

      if (cb && data.status !== INSPECTION_STATUSES.finished) {
        cb();
      }
    } catch (e) {
      dispatch({
        type: SET_IS_LOADING,
        payload: false,
      });
      throw e;
    }
  };

const removeInspectionWeb = async (id) => Api.delete(`/api/inspections/${id}`);

const removeInspectionMobile = (inspectionId) => async (dispatch) => {
  const inspection = await Db.findOne('inspections', inspectionId);
  await Db.delete('inspections', inspection._id, inspection._rev);

  const inspectionToSync = await Db.findOne('sync_inspections', { selector: { localId: inspection._id } }, false);
  if (inspectionToSync) {
    await Db.delete('sync_inspections', inspectionToSync._id, inspectionToSync._rev);
  } else {
    await Db.insert('sync_inspections_delete', inspection);
  }
  const idToDelete = inspection.frontendId || inspection._id;
  await Db.deleteAll('sync_inspections_checklist', {
    selector: {
      inspectionId: idToDelete,
    },
  });

  await Db.deleteAll('sync_attachments', {
    selector: {
      inspectionId: { $eq: inspectionId },
    },
  });

  dispatch(registerSync());

  const inspectionRemoveMessage = inspection.status === INSPECTION_STATUSES.draft ? 'deleted' : 'invalidated';

  Notifications.showSuccess(`Inspection has been ${inspectionRemoveMessage}`);
};

const removeInspection = (inspectionId) => async (dispatch, getState) => {
  try {
    const {
      app: { isMobile },
    } = getState();

    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    isMobile ? await dispatch(removeInspectionMobile(inspectionId)) : await removeInspectionWeb(inspectionId);

    dispatch({
      type: CLEAR_DATA,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const clearData = () => ({ type: CLEAR_DATA });

const setSignMode = (payload) => ({
  type: SET_SIGN_MODE,
  payload,
});

const setTodos = (payload) => ({
  type: SET_TODOS,
  payload,
});

const getInspectionTodosWeb = (mainSystemId, subSystemId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });
    const todos = await Api.get(`/api/findings/todo-templates`, {
      mainSystemId,
      subSystemId,
    });

    dispatch(setTodos(todos));
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw err;
  }
};

const chooseTodo = (todoId, inspectionId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    await Api.post(`/api/findings/from-todo-template`, {
      inspectionId,
      todoFindingTemplateIds: [todoId],
    });

    dispatch(getInspection(inspectionId));

    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw err;
  }
};

const markAttachmentAsMain = (attachmentData) => async (dispatch, getState) => {
  const {
    inspection: {
      data: { findings },
    },
  } = getState();

  if (findings && findings.length) {
    const newFindings = findings?.map((finding) =>
      finding.id === attachmentData.findingId
        ? { ...finding, mainImageThumbnailPath: attachmentData.thumbnailPath }
        : finding,
    );

    dispatch({
      type: MARK_ATTACHMENT_AS_MAIN,
      payload: newFindings,
    });
  }
};

const selectInspectionFindings = (id) => (dispatch, getState) => {
  const {
    inspection: {
      data: { findings },
    },
  } = getState();
  const {
    inspection: { wbs },
  } = findings.filter((finding) => finding.id === id)[0];

  selectFinding(id, wbs, dispatch, getState);
};

const selectAllFindings = (selectAllStatus) => (dispatch, getState) => {
  if (!selectAllStatus) {
    dispatch(clearSelectedFindings());
    return true;
  }

  const {
    inspection: {
      data: { findings },
    },
    findings: {
      selected: { ids },
    },
  } = getState();
  const filteredByStatuses = findings.filter((finding) => !validateTableItem(SELECTING_CONFIG, finding));

  if (!filteredByStatuses.length) {
    return false;
  }

  const filteredByTypes = filteredByStatuses.filter((finding) => finding.findingType !== FINDING_TYPES.WORK_INFO.value);

  if (!filteredByTypes.length) {
    return false;
  }

  selectAll(filteredByTypes, ids, dispatch);
  return true;
};

const updateFindingAfterSync = (finding) => async (dispatch, getState) => {
  const { inspection } = getState();

  if (inspection.data.id) {
    const currentFindingIndex = inspection.data.findings.findIndex(
      (f) => f.id === finding.id || f.id === finding.frontendId,
    );

    const updatedFinding = {
      ...inspection.data.findings[currentFindingIndex],
      ...finding,
      syncCompletionDate: new Date().toISOString(),
    };

    dispatch({
      type: UPDATE_FINDING_AFTER_SYNC,
      payload: updatedFinding,
    });
  }
};

export {
  clearData,
  getInspection,
  getInspectionDetails,
  getInspectionWeb,
  markAttachmentAsMain,
  removeInspection,
  selectAllFindings,
  selectInspectionFindings,
  setInspectionFindings,
  setInspectionStatus,
  setSignMode,
  updateFindingAfterSync,
  getInspectionTodosWeb,
  chooseTodo,
  validateFinding,
};

export {
  CLEAR_DATA,
  INSPECTION_STATUSES,
  MARK_ATTACHMENT_AS_MAIN,
  SET_INSPECTION_FINDINGS,
  SET_INSPECTION,
  SET_INVALID_FINDINGS,
  SET_IS_LOADING,
  SET_SIGN_MODE,
  UPDATE_FINDING_AFTER_SYNC,
  SET_TODOS,
  ADD_TODOS,
  GET_TODOS,
};
