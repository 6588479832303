import React, { FC, ReactNode, createContext, useMemo, useState } from 'react';

interface INavigationContextInterface {
  opened: boolean;
  toggleNavigation: () => void;
  closeNavigation: () => void;
}

const NavigationContext = createContext<INavigationContextInterface>({
  opened: false,
  toggleNavigation: () => null,
  closeNavigation: () => null,
});

interface INavigationContextProviderProps {
  children: ReactNode;
}

const NavigationContextProvider: FC<INavigationContextProviderProps> = ({ children }) => {
  const [state, setState] = useState(false);

  const toggleNavigation = () => {
    setState((s) => !s);
  };

  const closeNavigation = () => {
    setState(false);
  };

  const value = useMemo(() => ({ opened: state, toggleNavigation, closeNavigation }), [state]);

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};

export { NavigationContext, NavigationContextProvider };
