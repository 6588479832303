import React from 'react';

import { GlobalActionKeyboardArrowLeft } from 'assets/icons/index';
import { bool, func } from 'prop-types';

import { Divider, Drawer, Icon, IconButton, Typography } from '@material-ui/core';

import Checklist from '../checklists/checklist.component';

const ChecklistDrawer = ({ open, onClose, goToFindingAdd, canCreateFinding, checkedOutForMobile }) => {
  const createFinding = (_, checklist) => {
    if (checkedOutForMobile) {
      return;
    }

    onClose();
    goToFindingAdd(_, checklist);
  };

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <div className="checklist-header">
        <span className="checklist-header__title">
          <Typography variant="h6" color="textPrimary">
            Checklist {!canCreateFinding ? 'preview' : ''}
          </Typography>
        </span>
        <span className="checklist-header__icon">
          <IconButton onClick={onClose}>
            <Icon className="svg-medium">
              <GlobalActionKeyboardArrowLeft className="icon-dark" />
            </Icon>
          </IconButton>
        </span>
      </div>
      <Divider />

      <div className="checklist-drawer">
        <Checklist
          onClose={onClose}
          goToFindingAdd={createFinding}
          canModify={canCreateFinding && !checkedOutForMobile}
        />
      </div>
    </Drawer>
  );
};

ChecklistDrawer.propTypes = {
  open: bool,
  onClose: func.isRequired,
  goToFindingAdd: func.isRequired,
  canCreateFinding: bool,
  checkedOutForMobile: bool,
};

ChecklistDrawer.defaultProps = {
  open: false,
  canCreateFinding: false,
  checkedOutForMobile: false,
};

export default ChecklistDrawer;
