import statusReports from 'modules/admin/components/statusReports/statusReports.reducer';
import wbsDetails from 'modules/admin/components/wbsDetails/wbsDetails.reducer';
import wbsesTree from 'modules/admin/components/wbsesTree/wbsesTree.reducer';
import app from 'modules/app/components/app/app.reducer';
import auth from 'modules/auth/components/login/login.reducer';
import checklistEditor from 'modules/checklists/components/checklistEditor.reducer';
import filtersConfig from 'modules/common/components/filters/global.reducer';
import dashboard from 'modules/dashboard/components/dashboard/dashboard.reducer';
import finding from 'modules/findings/components/findingDetails/findingDetails.reducer';
import findings from 'modules/findings/components/findings/findings.reducer';
import newFinding from 'modules/findings/components/newFinding/newFinding.reducer';
import checklists from 'modules/inspections/components/checklists/checklist.reducer';
import inspection from 'modules/inspections/components/inspectionDetails/inspectionDetails.reducer';
import inspections from 'modules/inspections/components/inspections/inspections.reducer';
import newInspection from 'modules/inspections/components/newInspection/newInspection.reducer';
import user from 'modules/users/components/user/user.reducer';
import users from 'modules/users/components/users/users.reducer';
import newWorkflow from 'modules/workflows/components/newWorkflow/newWorkflow.reducer';
import workflowDetails from 'modules/workflows/components/workflowDetails/workflowDetails.reducer';
import workflows from 'modules/workflows/components/workflows/workflows.reducer';
import { combineReducers } from 'redux';

export default combineReducers({
  app,
  auth,
  checklists,
  checklistEditor,
  dashboard,
  finding,
  filtersConfig,
  findings,
  inspection,
  inspections,
  newFinding,
  newInspection,
  newWorkflow,
  statusReports,
  user,
  users,
  wbsDetails,
  wbsesTree,
  workflowDetails,
  workflows,
});
