import React, { PureComponent, Fragment } from 'react';
import { Prompt } from 'react-router-dom';

import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';

import { generateUId } from '../../../app/helpers/utils';

export default (WrappedComponent) =>
  class UnsavedChanges extends PureComponent {
    constructor() {
      super();

      this.trigger = generateUId();
      this.state = {
        isDirty: false,
        open: false,
      };
    }

    componentDidMount() {
      window[this.trigger] = this.showDialog;
      window.addEventListener('beforeunload', this.onBeforeUnload);
    }

    componentWillUnmount() {
      delete window[this.trigger];
      window.removeEventListener('beforeunload', this.onBeforeUnload);
    }

    onBeforeUnload = (ev) => {
      // no custom string possible, anyway pass any text
      const dialogText = 'Changes you made are not saved. Are you sure you want to leave this page?';
      ev.returnValue = dialogText;
      return dialogText;
    };

    setDirty = (isDirty = true) => this.setState({ isDirty });

    showDialog = (allowTransitionCb) => {
      this.setState({ open: true });
      this.callback = allowTransitionCb;
    };

    handleCancel = () => {
      this.callback(false);
      this.setState({ open: false });
    };

    handleConfirm = () => {
      this.callback(true);
      this.setState({ open: false });
    };

    render() {
      const { isDirty, open } = this.state;

      return (
        <>
          <Prompt when={isDirty} message={this.trigger} />
          <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Are you sure you want to leave?</DialogTitle>
            <DialogActions>
              <Button onClick={this.handleCancel} color="secondary">
                Cancel
              </Button>
              <Button onClick={this.handleConfirm} color="secondary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <WrappedComponent {...this.props} setDirty={this.setDirty} />
        </>
      );
    }
  };
