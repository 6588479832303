import * as yup from 'yup';

const requiredText = 'This field is required';
const tooLongText = 'Maximum field length of 20000 was exceeded';

class DynamicFormModel {
  static parseErrors(err) {
    const error = {};
    err.inner.forEach((item) => {
      error[item.path] = item.message;
    });

    return error;
  }

  constructor(fields) {
    this.schema = {};

    fields.forEach((field) => {
      if (field.required) {
        this.schema[field.id] = yup.string().nullable().required(requiredText).max(20000, tooLongText);
      }
    });
  }

  validateSchema = (values) => {
    try {
      const schemaToValidate = yup.object().shape(this.schema);
      schemaToValidate.validateSync(values, {
        abortEarly: false,
      });

      return false;
    } catch (err) {
      return DynamicFormModel.parseErrors(err);
    }
  };
}
export default DynamicFormModel;
