import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { FindingPhoto } from 'assets/icons/index';
import classNames from 'classnames';
import _get from 'lodash/get';
import AttachmentPreviewMobile from 'mobile/common/attachmentPreviewMobile.component';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { isOnline } from 'modules/app/helpers/hooks';
import AttachmentEdit from 'modules/common/components/attachmentEdit/attachmentEdit.component';
import Image from 'modules/common/components/image/image.component';
import Loader from 'modules/common/components/loader/loader.component';
import PropTypes, { shape } from 'prop-types';
import { v4 } from 'uuid';

import { GridListTileBar, Icon } from '@material-ui/core';

import FileListNames from './FileListNames';
import FilesAllowed from './filesAllowed.component';
import {
  PHOTO_TYPE,
  AUDIO_TYPE,
  DOC_TYPE,
  getAttachmentsMobile,
  saveAttachment,
  groupAttachmentsByType,
} from './filesList.actions';
import FloatingIconWrapper from './floatingIconWrapper';

class FilesListMobile extends PureComponent {
  state = {
    docs: [],
    isLoading: true,
    isLoadingSave: false,
    editOpen: false,
    previewOpen: false,
    selectedFile: null,
  };

  fileListNames = new FileListNames();

  componentDidMount() {
    const { local, data } = this.props;

    local
      ? this.setState({
          isLoading: false,
          ...groupAttachmentsByType(data),
        })
      : this.getData();
  }

  componentDidUpdate({ refresh: prevRefresh }) {
    const { refresh } = this.props;

    if (refresh !== prevRefresh) {
      this.getData();
    }
  }

  getData = async () => {
    try {
      const { fileType, id, afterLoad } = this.props;

      this.setState({ isLoading: true });

      const docs = await getAttachmentsMobile(fileType, id);

      docs.forEach((file) => {
        this.fileListNames.setNameAndIncrement(file.title);
      });

      this.setState({
        docs,
        isLoading: false,
      });

      if (afterLoad) {
        afterLoad();
      }
    } catch (err) {
      genericErrorHandler(err);
      this.setState({ isLoading: false });
    }
  };

  openPreview = (e) => {
    const {
      currentTarget: { id },
    } = e;

    this.setState({
      selectedFile: id,
      previewOpen: true,
    });
  };

  closePreview = (reload) => {
    this.setState({
      previewOpen: false,
    });

    if (reload) {
      this.getData();
    }
  };

  openEdit = () => {
    this.setState({
      editOpen: true,
    });
  };

  closeEdit = (type) => {
    const { fileType, differentFileTypeCb } = this.props;

    this.setState({
      editOpen: false,
    });

    if (fileType === type) {
      this.getData();
    } else {
      differentFileTypeCb(type);
    }
  };

  openAttachmentEdit = (id) => {
    this.setState({
      selectedFile: id,
      editOpen: true,
    });
  };

  handleAddFile = async (e) => {
    const { id, extraParams, fileType, setPendingCb } = this.props;

    try {
      const file = _get(e, 'target.files[0]');

      if (!file) {
        return;
      }

      let newFile = file;
      if (file.type.includes('image')) {
        const ext = file.name.split('.').pop()?.toLowerCase();

        newFile = new File([file], `${v4()}.${ext}`, { type: file.type });
      }

      setPendingCb(true);
      this.setState({ isLoadingSave: true });

      const fileId = await saveAttachment(newFile, this.fileListNames, fileType, id, extraParams);

      this.getData();

      setPendingCb(false);
      this.setState({ isLoadingSave: false });
    } catch (err) {
      setPendingCb(false);
      genericErrorHandler(err);
      this.setState({ isLoadingSave: false });
    }
  };

  renderIcon = (data) => {
    if (data.sync || data.path) {
      return <Icon color="primary">{data.sync ? 'check' : 'public'}</Icon>;
    }

    return null;
  };

  render() {
    const { isLoading, docs, editOpen, isLoadingSave, previewOpen, selectedFile } = this.state;
    const { fileType, registerSync, tab, selectedTab, canAdd, canEdit, finding, allowedTxt } = this.props;

    const isPhotoTab = fileType === PHOTO_TYPE;
    const showFabButtons =
      tab.attachmentOptions && tab.id === selectedTab && canAdd && (!finding.syncCompletionDate || isOnline());

    const selectedFileDescription = _get(
      docs.find((attachement) => attachement._id === selectedFile),
      'description',
    );

    return (
      <>
        <div className="attachments-component">
          <div className="tiles-wrapper">
            {docs.map((attachment) => {
              const thumbnail = _get(attachment, 'thumbnail.data') || _get(attachment, 'thumbnailPath');
              const classes = classNames('tile', {
                marked: attachment.marked || attachment.main,
              });

              return (
                <div
                  key={attachment.id || attachment._id}
                  id={attachment.id || attachment._id}
                  className={classes}
                  onClick={this.openPreview}
                  onKeyPress={this.openPreview}
                  role="presentation"
                >
                  {fileType === PHOTO_TYPE && !!thumbnail && (
                    <Image src={thumbnail} alt={attachment.description} mimeType={attachment.mimeType} />
                  )}
                  {fileType === AUDIO_TYPE && <Icon>mic_none</Icon>}
                  {fileType === DOC_TYPE && <Icon>attachment</Icon>}
                  {(!!attachment.description || attachment.sync || attachment.path) && (
                    <GridListTileBar
                      subtitle={attachment.description}
                      className="tile-title"
                      actionIcon={this.renderIcon(attachment)}
                      classes={{ actionIcon: 'd-flex pr-1' }}
                    />
                  )}
                </div>
              );
            })}
          </div>
          {allowedTxt && <FilesAllowed txt={allowedTxt} />}
        </div>
        {(isLoading || isLoadingSave) && <Loader mask />}
        <AttachmentEdit
          open={editOpen}
          closeFn={this.closeEdit}
          registerSync={registerSync}
          id={selectedFile}
          description={selectedFileDescription}
        />
        <AttachmentPreviewMobile
          open={previewOpen}
          closeFn={this.closePreview}
          editFn={this.openAttachmentEdit}
          registerSync={registerSync}
          id={selectedFile}
          isMobile
          canEdit={canEdit}
        />
        {showFabButtons && (
          <FloatingIconWrapper
            handleAddFile={this.handleAddFile}
            icon={tab.attachmentOptions.icon}
            isLoadingSave={isLoadingSave}
            tab={tab}
          />
        )}

        {isPhotoTab && showFabButtons && (
          <FloatingIconWrapper
            handleAddFile={this.handleAddFile}
            icon={<FindingPhoto className="icon-light" />}
            isLoadingSave={isLoadingSave}
            isQuickPhoto
            tab={tab}
          />
        )}
      </>
    );
  }
}

FilesListMobile.propTypes = {
  id: PropTypes.string.isRequired,
  extraParams: PropTypes.shape({}),
  type: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isLoadingSave: PropTypes.bool,
  registerSync: PropTypes.func.isRequired,
  afterLoad: PropTypes.func,
  tab: PropTypes.shape({}).isRequired,
  selectedTab: PropTypes.string.isRequired,
  differentFileTypeCb: PropTypes.func,
  setPendingCb: PropTypes.func,
  refresh: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  canAdd: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  local: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  allowedTxt: PropTypes.string,
  finding: shape({}),
};

FilesListMobile.defaultProps = {
  isLoading: false,
  isLoadingSave: false,
  afterLoad: null,
  extraParams: {},
  differentFileTypeCb: () => {},
  setPendingCb: () => {},
  refresh: null,
  data: [],
  local: false,
  allowedTxt: null,
};

export default withRouter(FilesListMobile);
