import _find from 'lodash/find';
import _get from 'lodash/get';

const getCurrentWorkflowRole = ({ workflowDetails: { participants }, auth: { user } }) => {
  if (participants.length) {
    const currentUser = participants.find((participant) => _get(participant, 'user.id') === user.id);
    return currentUser && currentUser.role ? currentUser.role : '';
  }
  return '';
};

const getObservationDueDate = ({
  workflowDetails: {
    data: { workflowHistory },
  },
}) => {
  if (workflowHistory.length) {
    const dueDate = _find(workflowHistory[0].fields, {
      label: 'Due Date',
      uiControlType: 'DATE',
    });
    return _get(dueDate, 'value', null);
  }
  return null;
};

export { getCurrentWorkflowRole, getObservationDueDate };
