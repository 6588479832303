import React, { PureComponent } from 'react';

import {
  FindingPhotoMain,
  GlobalActionBack,
  GlobalActionDownload,
  GlobalActionEdit,
  GlobalActionMore,
  GlobalActionOpenInNew,
} from 'assets/icons/index';
import { saveAs } from 'file-saver';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import PropTypes from 'prop-types';

import {
  AppBar,
  Divider,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { RotateLeft, RotateRight } from '@material-ui/icons';

import { downloadAttachment } from '../attachmentPreview/attachmentPreview.actions';
import ConfirmModal from '../confirmModal/confirmModal';
import withAttachmentPreviewHeader, { menuOptions } from './attachmentPreviewHeader.hoc';

const styles = {
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
  },
  title: {
    color: '#FFF',
  },
};

class AttachmentPreviewHeaderWeb extends PureComponent {
  state = {
    disableMarkButton: false,
  };

  handleDownload = async () => {
    try {
      const { path, title, mimeType } = this.props;
      const response = await downloadAttachment(path);
      const blob = new Blob([response], { type: mimeType });
      saveAs(blob, title || `image.${mimeType.split('/')[1]}`);
    } catch (err) {
      genericErrorHandler(err);
    }
  };

  handleOpenInNewWindow = async () => {
    try {
      const { path } = this.props;
      const blob = await downloadAttachment(path);
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (err) {
      genericErrorHandler(err);
    }
  };

  onMark = () => {
    const { handleMark } = this.props;

    this.setState({ disableMarkButton: true });
    handleMark();
  };

  renderMenu = () => {
    const { anchorEl, handleConfirmDelete, handleMenuClose, canEdit } = this.props;

    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={menuOptions}
        transformOrigin={menuOptions}
        open={!!anchorEl}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={this.handleDownload}>
          <ListItemIcon>
            <Icon className="svg-medium">
              <GlobalActionDownload className="icon-dark" />
            </Icon>
          </ListItemIcon>
          <ListItemText>Download</ListItemText>
        </MenuItem>
        <MenuItem onClick={this.handleOpenInNewWindow}>
          <ListItemIcon>
            <Icon className="svg-medium">
              <GlobalActionOpenInNew className="icon-dark" />
            </Icon>
          </ListItemIcon>
          <ListItemText>Open in new window</ListItemText>
        </MenuItem>
        {canEdit && (
          <ConfirmModal confirmFn={handleConfirmDelete} confirmTitle="Are you sure you want to delete this attachment?">
            <Divider />
            <MenuItem>
              <ListItemIcon>
                <Icon>delete</Icon>
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </ConfirmModal>
        )}
      </Menu>
    );
  };

  render() {
    const { canEdit, classes, handleEditClick, handleMenuOpen, isWorkflow, onClose, rotateLeft, rotateRight, title } =
      this.props;
    const { disableMarkButton } = this.state;

    return (
      <>
        <AppBar position="fixed" className="header-component" classes={{ root: classes.root }}>
          <Toolbar>
            <IconButton className="left-button" aria-label="Go back" onClick={onClose}>
              <Icon className="svg-medium">
                <GlobalActionBack className="icon-light" />
              </Icon>
            </IconButton>
            {title && (
              <Typography
                color="primary"
                variant="body1"
                className="p-4 break-word"
                classes={{
                  colorPrimary: classes.title,
                }}
              >
                {title}
              </Typography>
            )}

            <div className="grow" />
            <Typography
              color="primary"
              variant="body2"
              className="p-4"
              classes={{
                colorPrimary: classes.title,
              }}
            >
              {`Rotation preview only ${canEdit ? '(for edit click pencil)' : ''}`}
            </Typography>

            <IconButton aria-label="Rotate left" onClick={rotateLeft}>
              <Icon className="svg-medium">
                <RotateLeft style={{ fill: '#fff' }} />
              </Icon>
            </IconButton>

            <IconButton aria-label="Rotate right" onClick={rotateRight}>
              <Icon className="svg-medium">
                <RotateRight style={{ fill: '#fff' }} />
              </Icon>
            </IconButton>
            {canEdit && (
              <>
                {!isWorkflow && (
                  <IconButton
                    className="right-button"
                    aria-label="Mark as Main"
                    onClick={this.onMark}
                    disabled={disableMarkButton}
                  >
                    <Icon className="svg-medium">
                      <FindingPhotoMain className="icon-light" />
                    </Icon>
                  </IconButton>
                )}
                <IconButton className="right-button" aria-label="Edit" onClick={handleEditClick}>
                  <Icon className="svg-medium">
                    <GlobalActionEdit className="icon-light" />
                  </Icon>
                </IconButton>
              </>
            )}
            <IconButton className="right-button" aria-label="Menu" onClick={handleMenuOpen}>
              <Icon className="svg-medium">
                <GlobalActionMore className="icon-light" />
              </Icon>
            </IconButton>
          </Toolbar>
        </AppBar>
        {this.renderMenu()}
      </>
    );
  }
}

AttachmentPreviewHeaderWeb.propTypes = {
  anchorEl: PropTypes.shape({}),
  canEdit: PropTypes.bool.isRequired,
  classes: PropTypes.shape({}).isRequired,
  handleConfirmDelete: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleMark: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  handleMenuOpen: PropTypes.func.isRequired,
  isWorkflow: PropTypes.bool.isRequired,
  mimeType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  rotateLeft: PropTypes.func.isRequired,
  rotateRight: PropTypes.func.isRequired,
  title: PropTypes.string,
};

AttachmentPreviewHeaderWeb.defaultProps = {
  anchorEl: null,
  title: '',
};

export default withAttachmentPreviewHeader(withStyles(styles)(AttachmentPreviewHeaderWeb));
