import React, { useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';
import {
  DEFAULT_PAGINATION_PAGE_SIZE,
  FILTER_COOKIE_NAMES,
  WORKFLOWS_TYPES,
  WORKFLOW_STATUSES,
} from 'modules/app/config/config';
import { eraseCookie, getCookie, setCookie, compareObjectStructure } from 'modules/app/helpers/utils';
import Filters from 'modules/common/components/filters';
import Table from 'modules/common/components/table';
import { TableUniqueKey } from 'modules/common/components/table/table.context';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

import { AREA_OPTIONS, SERIAL_DEFECTS, SEVERITY_OPTIONS } from '../newWorkflow/newWorkflow.model';
import TABLE_CONFIG from './workflows.config';

const WORKFLOW_STATUS_DELETED = 'DELETED';

const FILTERS_COOKE_NAME_MAP = new Map(Object.keys(FILTER_COOKIE_NAMES).map((key) => [key, FILTER_COOKIE_NAMES[key]]));
const WORKFLOWS_TYPE_MAP = new Map(Object.keys(WORKFLOWS_TYPES).map((key) => [key, WORKFLOWS_TYPES[key]]));

const Workflows = ({
  createCrumb,
  getWorkflows,
  history,
  isLoading,
  match: { params, path },
  totalCount,
  workflows,
}) => {
  const cookie = FILTERS_COOKE_NAME_MAP.get(params.type.toUpperCase());
  const workflowType = WORKFLOWS_TYPE_MAP.get(params.type);

  const initialValues = JSON.parse(getCookie(cookie));
  ``;

  const emptyFilters = {
    search: '',
    filters: {
      wbs: [],
      status: [],
      siteFacility: [],
      failureMode: [],
    },
    sort: { sortBy: 'lastActivityDate', sortDirection: 'desc' },
  };

  const filterStructureCheck = compareObjectStructure(emptyFilters, initialValues);

  const [state, setState] = useState({
    filters: filterStructureCheck ? initialValues : emptyFilters,
    size: DEFAULT_PAGINATION_PAGE_SIZE,
    page: 0,
  });

  useEffect(() => {
    createCrumb(path, params);

    getWorkflows(
      {
        sort: `${state.filters.sort.sortBy},${state.filters.sort.sortDirection.toUpperCase()}`,
        types: params.type.toUpperCase(),
        page: 0,
        size: DEFAULT_PAGINATION_PAGE_SIZE,
      },
      state.filters,
    );
  }, [params.type]);

  useEffect(() => {
    const { page, size, filters } = state;
    getWorkflows(
      {
        sort: `${filters.sort.sortBy},${filters.sort.sortDirection.toUpperCase()}`,
        types: params.type.toUpperCase(),
        page,
        size,
      },
      filters,
    );
  }, [state]);

  const onRowClick = (_, item) => {
    if (item.status !== WORKFLOW_STATUS_DELETED) {
      history.push(`/workflowDetails/${item.id}`);
    }
  };

  const filtersConfig = useMemo(
    () => ({
      name: cookie,
      initialFilters: state.filters,
      filters: {
        wbs: {
          caption: 'WBS',
          primaryText: 'description',
          secondaryText: 'name',
        },
        status: {
          caption: 'Status',
          primaryText: 'id',
          secondaryText: '',
        },
        siteFacility: {
          caption: 'Site Facility',
          primaryText: 'country',
          secondaryText: 'supplier',
        },
        failureMode: {
          caption: 'Failure Mode',
          primaryText: 'description',
          secondaryText: 'code',
        },
      },
      filtersOrderIndex: {
        wbs: 1,
        failureMode: 2,
        siteFacility: 3,
        status: 4,
      },
      sort: {
        status: 'status',
        lastActivityDate: 'lastActivityDate',
        currentStepDueDate: 'currentStepDueDate',
        siteFacility: 'siteFacility',
        title: 'title',
        failureMode: 'failureMode.description',
        wbs: 'wbs',
        workflowNumber: 'workflowNumber',
      },
      sortLabels: {
        status: 'Status',
        lastActivityDate: 'Last update',
        currentStepDueDate: 'Deadline',
        siteFacility: 'Site facility',
        title: 'Title',
        failureMode: 'Failure mode',
        wbs: 'Park/Package',
        workflowNumber: 'Workflow Number',
      },
      chipLabels: {
        wbs: 'Park/Package',
        siteFacility: 'Site Facility',
        failureMode: 'Failure Mode',
      },
      additionalFilters: {
        status: Object.entries(WORKFLOW_STATUSES).map(([, value]) => ({
          id: value,
        })),
      },
    }),
    [workflowType],
  );

  const setSort = (page, size) => {
    setState({
      ...state,
      page,
      size,
    });
  };

  const handleFiltersChange = (filterState, sortOnly) => {
    setCookie(cookie, JSON.stringify(filterState), 1);
    const newState = {
      ...state,
      filters: filterState,
    };
    if (!sortOnly) {
      newState.page = 0;
    }
    setState(newState);
  };

  const debounced = debounce(handleFiltersChange, 250, {
    maxWait: 1300,
  });

  const onClearAll = () => {
    eraseCookie(cookie);
  };

  const skipCells = [];
  const tableConfig = useMemo(() => {
    const tc = { ...TABLE_CONFIG };
    // Remove severity from tableConfig.columns if not already there
    if (tc.columns.find((column) => column.dataField === 'severity')) {
      tc.columns = tc.columns.filter((column) => column.dataField !== 'severity');
    }
    if (tc.columns.find((column) => column.dataField === 'reference')) {
      tc.columns = tc.columns.filter((column) => column.dataField !== 'reference');
    }

    return tc;
  }, [workflowType]);

  return (
    <>
      <div className="row no-gutters align-items-start justify-content-end pr-5 pl-5 pt-2 pb-2">
        <Filters
          withTextSearch
          withSort
          searchLabel="Title"
          callback={debounced}
          config={filtersConfig}
          onClearAll={onClearAll}
          preserveAdditionalFilters
        />
      </div>
      <div className="mt-3">
        <Table
          config={tableConfig}
          isLoading={isLoading}
          items={workflows}
          onRowClick={onRowClick}
          pagination
          paginationCallback={setSort}
          params={params}
          sort={state.filters.sort}
          totalCount={totalCount}
          skipCells={skipCells}
          tableUniqueKey={TableUniqueKey.WORKFLOWS}
        />
      </div>
    </>
  );
};

Workflows.propTypes = {
  getWorkflows: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  workflows: PropTypes.arrayOf(PropTypes.shape).isRequired,
  match: PropTypes.objectOf(PropTypes.shape).isRequired,
  createCrumb: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default Workflows;
