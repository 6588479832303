import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SaveHeaderWeb from 'modules/common/components/saveHeader/saveHeaderWeb.component';

import { Button, TextField } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

import { addGroup, deleteGroup, editGroup } from './checklistEditor.actions';

interface INewGroupProps {
  onClose: (newGroupId?: string) => void;
  group?: {
    id: string;
    description: string;
  };
  edit?: boolean;
}

const NewGroup: FC<INewGroupProps> = ({ onClose, group, edit = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [groupFieldValue, setGroupFieldValue] = useState(group?.description || '');
  const [groupFieldError, setGroupFieldError] = useState('');

  const checklistId = useSelector((state: IStore) => state.checklistEditor.currentChecklistDetails.id);
  const title = edit ? 'Edit group title' : ' Add new group';

  const onGroupFieldChange = (e) => {
    if (groupFieldValue.length > 100) setGroupFieldError('Title must be at most 100 characters');
    setGroupFieldValue(e.target.value);
  };

  const handleOnDeleteGroup = () => {
    dispatch(deleteGroup(group.id, checklistId));
  };

  const handleOnSubmitGroup = async (e) => {
    e.preventDefault();

    if (!groupFieldValue) {
      setGroupFieldError('This field is required');
      return;
    }

    if (groupFieldValue.length > 100) {
      setGroupFieldError('Title must be at most 100 characters');
      return;
    }

    let newGroupId: any = '';
    const values: {
      checkListDefinitionId?: string;
      description: string;
    } = {
      description: groupFieldValue,
    };

    if (edit && group?.id) {
      dispatch(editGroup(group.id, values));
    } else {
      values.checkListDefinitionId = checklistId;
      newGroupId = await dispatch(addGroup(values));
    }

    setGroupFieldValue('');
    onClose(newGroupId);
  };

  return (
    <div id="new-group" className={`shadow rounded ${edit && 'edit'}`}>
      <form onSubmit={handleOnSubmitGroup}>
        <SaveHeaderWeb title={title} onSave={handleOnSubmitGroup} onClose={onClose} withSave />
        <div className="input-submit-line">
          <TextField
            label="Title"
            id="description"
            name="description"
            className="grow"
            onChange={onGroupFieldChange}
            value={groupFieldValue}
            error={Boolean(groupFieldError)}
            helperText={groupFieldError}
          />
        </div>
        {edit && (
          <div className="input-submit-line mt-2">
            <div className="grow" />
            <Button
              className="delete-button noTextTransform"
              onClick={handleOnDeleteGroup}
              startIcon={<DeleteForever />}
            >
              Delete
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default NewGroup;
