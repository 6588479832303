import { SET_WORK_PACKAGE, CLEAR_DATA, SET_IS_LOADING } from './newWorkflow.actions';

const initialState = {
  data: {},
  isLoading: false,
  workPackage: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_WORK_PACKAGE:
      return {
        ...state,
        workPackage: payload,
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
