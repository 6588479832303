import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { Formik } from 'formik';
import FilteringTree from 'modules/common/components/filteringTree/filteringTree';
import InputModal from 'modules/common/components/inputModal/inputModal.container';
import SaveHeaderWeb from 'modules/common/components/saveHeader/saveHeaderWeb.component';
import { getAllSubSystems, getSubSystems } from 'modules/inspections/components/newInspection/newInspection.actions';
import * as yup from 'yup';

import { Button, TextField } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

import { addElement, deleteElement, editElement } from './checklistEditor.actions';

const requiredText = 'This field is required';

const elementSchema = yup.object().shape({
  checkListGroupDefinitionId: yup.string(),
  description: yup.string().max(500).required(requiredText).trim(),
  note: yup.string().max(500).trim(),
  subSystem: yup.object().nullable(),
  subSystemId: yup.string(),
});

interface INewElementProps {
  groupId: string;
  onClose: (newElementId?: string) => void;
  element?: {
    id: string;
    description: string;
    note: string;
    subSystem: {
      id: string;
      description: string;
    };
    subSystemId: string;
  };
  edit?: boolean;
}

const NewElement: FC<INewElementProps> = ({ groupId, onClose, element, edit = false }) => {
  const dispatch = useDispatch();

  const checklistDetails = useSelector((state: IStore) => state.checklistEditor.currentChecklistDetails);
  const checklistMainSystem = checklistDetails?.mainSystem;
  const title = edit ? 'Edit element title' : ' Add new element';

  const initialFormValues = {
    checkListGroupDefinitionId: groupId || '',
    description: element?.description || '',
    note: element?.note || '',
    subSystem: element?.subSystem || {},
    subSystemId: element?.subSystemId || '',
  };

  const handleOnDeleteElement = () => {
    dispatch(deleteElement(element.id, groupId));
  };

  const handleOnSubmitElement = async (values, { resetForm }) => {
    let newElementId: any = '';
    if (edit) {
      dispatch(editElement(element.id, values));
    } else {
      newElementId = await dispatch(addElement(values));
    }
    resetForm();
    onClose(newElementId);
  };

  return (
    <div id="new-element" className={classNames('shadow rounded checklistItem-editor', { edit })}>
      <Formik
        initialValues={initialFormValues}
        validationSchema={elementSchema}
        enableReinitialize
        onSubmit={handleOnSubmitElement}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => {
          const onSubSystemSelect = (_, subSystem) => {
            setFieldValue('subSystem', subSystem || {});
            setFieldValue('subSystemId', subSystem?.id);
          };

          return (
            <div className="content-wrapper row no-gutters p-0">
              <SaveHeaderWeb title={title} onSave={handleSubmit} onClose={onClose} />
              <div className="checklist-element-form">
                <TextField
                  label="Title"
                  className="mt-4 col-md-6"
                  id="description"
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                  error={errors && !!errors.description}
                  helperText={Boolean(errors.description) && String(errors.description)}
                />
                <InputModal
                  className="col-md-6 pr-md-3"
                  id="subSystem"
                  title="Sub system (optional)"
                  fieldName="subSystem"
                  placeholder="Sub system (optional)"
                  value={values?.subSystem?.description || ''}
                  clearFieldValue={onSubSystemSelect}
                  getNodes={checklistMainSystem ? getSubSystems : getAllSubSystems}
                  getNodesParams={
                    checklistMainSystem
                      ? { mainSystemDetails: checklistMainSystem }
                      : { parkId: checklistDetails?.park?.id }
                  }
                  error={!!errors && !!errors.subSystemId}
                  errorText={errors && errors.subSystemId}
                >
                  <FilteringTree
                    onSelect={onSubSystemSelect}
                    searchPlaceholderText="Search for Sub System"
                    primaryTextField="subSystemCode"
                    secondaryTextField="description"
                    checkForActive
                    withCheck={false}
                  />
                </InputModal>
                <TextField
                  label="Note (optional)"
                  className="col-md-12 mt-4"
                  id="note"
                  name="note"
                  onChange={handleChange}
                  value={values.note}
                  error={!!errors && !!errors.note}
                  helperText={Boolean(errors.note) && String(errors.note)}
                />
              </div>
              {edit && (
                <div className="input-submit-line w-100 mt-2">
                  <div className="grow" />
                  <Button
                    className="delete-button noTextTransform"
                    onClick={handleOnDeleteElement}
                    startIcon={<DeleteForever />}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default NewElement;
