import { SET_WORKFLOW, CLEAR_DATA, SET_IS_LOADING, SET_PARTICIPANTS, GET_NC_REPORT } from './workflowDetails.actions';

const initialState = {
  data: {
    workflowHistory: [],
    currentPart: {
      actions: [],
      fields: [],
    },
    status: '',
    id: '',
  },
  ncReport: {},
  participants: [],
  isLoading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_WORKFLOW:
      return {
        ...state,
        data: payload,
        isLoading: false,
      };
    case GET_NC_REPORT:
      return {
        ...state,
        ncReport: payload,
      };
    case SET_PARTICIPANTS:
      return {
        ...state,
        participants: payload,
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
