import { connect } from 'react-redux';

import { createCrumb } from 'modules/app/components/app/app.actions';

import { setTitle } from '../../../app/components/app/app.actions';
import { getWorkflow } from '../workflowDetails/workflowDetails.actions';
import DefectNotifications from './defectNotificationsList';
import { getWorkflows } from './workflows.actions';
import Workflows from './workflows.component';

const mapStateToProps = (state) => ({
  isLoading: state.workflows.isLoading,
  workflows: state.workflows.items,
  totalCount: state.workflows.totalCount,
});

const mapDispatchToProps = {
  getWorkflows,
  setTitle,
  createCrumb,
  getWorkflow,
};

const WorkflowsContainer = connect(mapStateToProps, mapDispatchToProps)(Workflows);
const DefectNotificationsContainer = connect(mapStateToProps, mapDispatchToProps)(DefectNotifications);

export { WorkflowsContainer, DefectNotificationsContainer };
