import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';

import {
  Chip,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  colors,
  makeStyles,
} from '@material-ui/core';

import { FiltersContext } from '../../filters.context';
import { actions } from '../../filters.reducer';
import { FilterUtilsProps } from '../../types';

const useStyles = makeStyles((theme) => ({
  filtersWrappers: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },

  chipNotApplicable: {
    backgroundColor: 'red',
  },

  filterButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '10px',
    flexWrap: 'wrap',
    rowGap: '1rem',
  },
  textField: {
    height: '50%',
    width: '250px',
  },
  '@media(max-width: 992px)': {
    textField: {
      width: '380px',
    },
  },
  selectSortDirection: {
    height: '50%',
    width: '120px',
  },
  selectSortBy: {
    height: '50%',
    width: '200px',
  },
  formControl: {
    marginLeft: '10px',
  },
  chip: {
    margin: '5px 10px',
  },
}));

const MAX_LENGTH = 1024;

const FilterUtils: FC<FilterUtilsProps> = ({
  callback,
  extendedParameters,
  onClearFuncs,
  openModalCallback,
  preserveAdditionalFilters,
  searchLabel,
  withSort,
  withTextSearch,
}) => {
  const { dispatch, state, config } = useContext(FiltersContext);
  const [intError, setError] = useState('');

  const classes = useStyles();

  useEffect(() => {
    if (!preserveAdditionalFilters) {
      dispatch(actions.setAdditionalValue<boolean>('cleanup', true));

      if (config.additionalFilters) {
        Object.keys(config.additionalFilters).forEach((key) => {
          if (Object.values(config.additionalFilters[key]).length > 0) {
            dispatch(actions.removeFilterFamily(key));
          }
        });
      }

      setTimeout(() => {
        dispatch(actions.setAdditionalValue<boolean>('cleanup', false));
      }, 1000);
    }
  }, [JSON.stringify(config.additionalFilters)]);

  useEffect(() => {
    if (callback && !state.cleanup) {
      callback(state, false);
    }
  }, [state.search, state.filters]);

  useEffect(() => {
    if (Object.entries(extendedParameters).length > 0) {
      Object.entries(extendedParameters).forEach(([key, val]) => {
        dispatch(actions.setAdditionalValue(key, val));
      });
    }
  }, [extendedParameters]);

  useEffect(() => {
    if (callback && !state.cleanup) {
      callback(state, true);
    }
  }, [state.sort]);

  const handleSetText = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;

    if (value.length > MAX_LENGTH) {
      setError('Typed search is too long');
    } else {
      setError('');
      dispatch(actions.setAdditionalValue('search', value));
    }
  };

  const handleSortChange = (e) => {
    e.preventDefault();

    dispatch(
      actions.setAdditionalValue('sort', {
        ...state.sort,
        sortBy: e.target.value,
      }),
    );
  };

  const handleSortDirectionChange = (e) => {
    e.preventDefault();

    dispatch(
      actions.setAdditionalValue('sort', {
        ...state.sort,
        sortDirection: e.target.value,
      }),
    );
  };

  const chipFilters =
    state.filters &&
    Object.entries(state.filters).map(([key, val]) => {
      if (val.length > 0) {
        const label = config.chipLabels[key];
        return {
          key,
          filterId: key,
          label: `${label || key.toUpperCase()} (${val.length})`,
        };
      }
      return null;
    });

  const chips = chipFilters?.map((data) => {
    if (data) {
      const removeFilterGroup = () => {
        if (data.key in onClearFuncs) {
          onClearFuncs[data.key]();
        }
        dispatch(actions.removeFilterFamily(data.key));
      };

      const isDisabled = config.chipsDisabled?.includes(data.key);
      const isHidden = config.chipsHidden?.includes(data.key);
      const isApplicable = Object.keys(config.filters).includes(data.key);

      if (!isHidden) {
        if (!isApplicable) {
          return (
            <Tooltip title="This filter is not applicable for current view.">
              <Chip
                key={data.key}
                label={data.label}
                onDelete={removeFilterGroup}
                className={classes.chip}
                disabled={isDisabled}
                style={{ backgroundColor: colors.red['500'], color: 'whitesmoke' }}
              />
            </Tooltip>
          );
        }

        return (
          <Chip
            key={data.key}
            label={data.label}
            onDelete={removeFilterGroup}
            className={classes.chip}
            disabled={isDisabled}
          />
        );
      }

      return null;
    }
    return null;
  });

  const renderSortDropdownItems =
    config.sort &&
    Object.entries(config.sort)
      .sort(([a], [b]) => {
        if (config.filtersOrderIndex) {
          return config.filtersOrderIndex[a] - config.filtersOrderIndex[b];
        }
        return a.localeCompare(b);
      })
      .map(([key, val]) => (
        <MenuItem value={val} key={val}>
          {config.sortLabels[key] || key}
        </MenuItem>
      ));

  return (
    <div className={classes.filtersWrappers}>
      <div className={classes.filterButtons}>
        {withTextSearch && (
          <TextField
            className={classes.textField}
            label={`Search ${searchLabel ? `by ${searchLabel}` : ''}`}
            value={state.search}
            onChange={handleSetText}
            error={Boolean(intError)}
            helperText={Boolean(intError) && intError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
            }}
          />
        )}

        {withSort && (
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="sort">Sort by</InputLabel>
              <Select
                className={classes.selectSortBy}
                value={state.sort.sortBy}
                onChange={handleSortChange}
                defaultValue={state.sort.sortBy}
                autoWidth
                inputProps={{
                  name: 'sort',
                  id: 'sort',
                }}
              >
                {renderSortDropdownItems}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="sort-dir">Sort direction</InputLabel>
              <Select
                className={classes.selectSortDirection}
                value={state.sort.sortDirection}
                onChange={handleSortDirectionChange}
                defaultValue={state.sort.sortBy}
                autoWidth
                inputProps={{
                  name: 'sort-dir',
                  id: 'sort-dir',
                }}
              >
                <MenuItem value="asc">Asc</MenuItem>
                <MenuItem value="desc">Desc</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        <Tooltip title="Filters" placement="top" disableFocusListener>
          <IconButton onClick={openModalCallback}>
            <Icon style={{ fontSize: 16 }}>filter_list</Icon>
          </IconButton>
        </Tooltip>
      </div>
      <div>{chips}</div>
    </div>
  );
};

export default FilterUtils;
