import React, { PureComponent } from 'react';

import { saveAs } from 'file-saver';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { INTERNAL_ERROR_CODE, ONLY_FILES_ATTACHED_MSG } from 'modules/app/config/config';
import Loader from 'modules/common/components/loader/loader.component';
import PropTypes from 'prop-types';

import { Icon, Typography } from '@material-ui/core';

import FileListNames from '../filesList/FileListNames';
import FileDropzone from '../filesList/fileDropzone.component.tsx';
import FileRow from '../filesList/fileRow.component';
import { addAttachmentsToSave, groupAttachmentsByType } from '../filesList/filesList.actions';
import ImagesFiles from '../filesList/imagesFiles.component';
import AttachmentPreviewUpload from './attachmentPreviewUpload.component';

export const UploadWarningMsg = ({ warningMsg }) => (
  <div className="col-md-12 d-flex justify-content-center align-items-center ">
    <Icon color="primary">warning</Icon>
    <Typography className="pl-3" color="textPrimary" variant="body1">
      {warningMsg}
    </Typography>
  </div>
);

UploadWarningMsg.propTypes = {
  warningMsg: PropTypes.string.isRequired,
};

class FileUpload extends PureComponent {
  state = {
    photos: [],
    audios: [],
    docs: [],
    previewOpen: false,
    isLoading: false,
    selectedFile: null,
  };

  fileListNames = new FileListNames();

  static getDerivedStateFromProps({ files }, { isLoading: isLoadingState }) {
    if (files && !isLoadingState) {
      return {
        isLoading: false,
        ...groupAttachmentsByType(files),
      };
    }

    return null;
  }

  onDrop = async (files) => {
    try {
      const { files: currentFiles, setFiles } = this.props;
      const filesWithContent = files.filter((file) => file.size > 0);
      if (filesWithContent.length) {
        this.setState({ isLoading: true });

        try {
          const newFiles = await addAttachmentsToSave(filesWithContent, this.fileListNames);
          setFiles([...currentFiles, ...newFiles]);
          this.setState({ isLoading: false });
        } catch (e) {
          if (Array.isArray(e)) {
            const internalErrors = e.filter((i) => i.code === INTERNAL_ERROR_CODE);

            if (internalErrors.length && files.length !== internalErrors.length) {
              genericErrorHandler(e);
            } else {
              throw e;
            }
          } else {
            throw e;
          }
        }
      }

      if (filesWithContent.length !== files.length) {
        throw new Error('Cannot add empty files');
      }
    } catch (err) {
      genericErrorHandler(err);
      this.setState({ isLoading: false });
    }
  };

  onCancel = () => {
    const { files, setFiles } = this.props;

    setFiles(files);
  };

  openPreview = (e) => {
    const {
      currentTarget: { id },
    } = e;
    const { files } = this.props;

    const selectedFile = files.find((file) => file.id === id);

    this.setState({
      selectedFile,
      previewOpen: true,
    });
  };

  closePreview = () => {
    this.setState({
      selectedFile: null,
      previewOpen: false,
    });
  };

  handleImageDelete = () => {
    const {
      selectedFile: { id },
    } = this.state;
    const { handleDelete } = this.props;

    handleDelete(id);
    this.closePreview();
  };

  handleFileDelete = (_, params) => {
    const { id } = params;
    const { handleDelete } = this.props;

    handleDelete(id);
  };

  handleDownload = (e) => {
    try {
      const { files } = this.props;
      const { currentTarget } = e;
      const title = currentTarget.getAttribute('data-title');
      const id = currentTarget.getAttribute('data-path');
      const fileToDownload = files.filter((file) => file.name === id)[0];

      saveAs(fileToDownload, title);
    } catch (err) {
      genericErrorHandler(err);
    }
  };

  goPrevious = () => {
    const { photos, selectedFile } = this.state;
    const index = photos.findIndex((file) => file.id === selectedFile.id);
    const prevIndex = index - 1;
    let prevSelected;

    if (prevIndex === -1) {
      prevSelected = photos[photos.length - 1];
    } else {
      prevSelected = photos[prevIndex];
    }

    this.setState({ selectedFile: prevSelected });
  };

  goNext = () => {
    const { photos, selectedFile } = this.state;
    const index = photos.findIndex((file) => file.id === selectedFile.id);
    const nextIndex = index + 1;
    let nextSelected;

    if (nextIndex > photos.length - 1) {
      nextSelected = photos[0];
    } else {
      nextSelected = photos[nextIndex];
    }

    this.setState({ selectedFile: nextSelected });
  };

  render() {
    const { photos, audios, docs, isLoading, previewOpen, selectedFile } = this.state;
    const { imagesPreview, warningMsg, isCreate, isWorkflow } = this.props;
    const showNextPrevButtons = photos.length > 1;

    return (
      <div className="row col-md-12 no-gutters py-3 attachments-component-web">
        {warningMsg && <UploadWarningMsg warningMsg={warningMsg} />}
        {imagesPreview && (
          <div className="col-md-8">
            <Typography color="textSecondary" variant="body2" className="pb-3">
              {ONLY_FILES_ATTACHED_MSG}
            </Typography>
            {photos.length > 0 && (
              <div className="row no-gutters">
                <ImagesFiles
                  photos={photos}
                  openPreview={this.openPreview}
                  isCreate={isCreate}
                  isWorkflow={isWorkflow}
                />
              </div>
            )}
          </div>
        )}
        <div className="col-md-4 pt-4">
          <FileDropzone onDrop={this.onDrop} />
        </div>

        {(audios.length > 0 || docs.length > 0) && (
          <div className="row col-md-12 mt-3 no-gutters">
            <Typography color="textSecondary" variant="body2" className="pb-3 mt-3">
              Attachments - other files
            </Typography>
            <div className="row col-md-12 mt-3">
              {audios.map((attachment) => (
                <div className="col-md-4" key={attachment.id}>
                  <FileRow
                    attachment={attachment}
                    handleDownload={this.handleDownload}
                    canEdit
                    onRemoveFile={this.handleFileDelete}
                  />
                </div>
              ))}
              {docs.map((attachment) => (
                <div className="col-md-4" key={attachment.id}>
                  <FileRow
                    attachment={attachment}
                    canEdit
                    handleDownload={this.handleDownload}
                    onRemoveFile={this.handleFileDelete}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {isLoading && <Loader inline mask />}
        <AttachmentPreviewUpload
          open={previewOpen}
          data={selectedFile}
          onClose={this.closePreview}
          handleDelete={this.handleImageDelete}
          showNextPrevButtons={showNextPrevButtons}
          onNext={this.goNext}
          onPrevious={this.goPrevious}
        />
      </div>
    );
  }
}

FileUpload.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape).isRequired,
  handleDelete: PropTypes.func.isRequired,
  imagesPreview: PropTypes.bool,
  setFiles: PropTypes.func.isRequired,
  warningMsg: PropTypes.string,
};

FileUpload.defaultProps = {
  imagesPreview: true,
  warningMsg: '',
};

export default FileUpload;
