import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { AppLogoWhite as LogoIcon } from 'assets/icons/index';
import LoginTurbines from 'assets/images/LoginTurbines';
import Logo from 'assets/images/Logo';
import PropTypes from 'prop-types';

import { useMsal } from '@azure/msal-react';
import { Fab, Icon } from '@material-ui/core';

const Login = ({ checkMobile, login, isLogged, isMobile, isLogin }) => {
  const { instance } = useMsal();

  useEffect(() => {
    checkMobile();
  }, []);

  const handleVattenfallLogin = async () => {
    await login(instance);
  };

  if (isLogged) {
    return <Redirect to="/" />;
  }

  if (isMobile) {
    return (
      <div className="login-component d-flex flex-column flex-fill h-100">
        <div className="logo-container d-flex flex-column flex-fill align-items-center justify-content-between">
          <div className="title p-4">Wind Inspection Tool</div>
          <img src={LoginTurbines} alt="" />
        </div>
        <div className="center-wrapper d-flex flex-column align-items-center justify-content-start">
          <img src={Logo} alt="" className="logo-img mb-1" />
          <Fab
            id="login:button"
            size="medium"
            variant="extended"
            color="primary"
            className="login-button mt-3"
            onClick={handleVattenfallLogin}
            disabled={isLogin}
          >
            <Icon className="mr-2">lock_open</Icon>
            Log in
          </Fab>
        </div>
      </div>
    );
  }

  return (
    <div className="login-component-web row no-gutters flex-fill">
      <div className="logo-container col-md-8 d-flex flex-column align-items-center">
        <LogoIcon className="cursor-pointer py-3" />
        <img src={LoginTurbines} alt="" />
      </div>
      <div className="col-md-4">
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          <img src={Logo} alt="" className="logo-img mb-5" />
          <Fab
            id="login:button"
            size="medium"
            variant="extended"
            color="primary"
            className="login-button"
            puppet-data="login:button"
            onClick={handleVattenfallLogin}
            disabled={isLogin}
          >
            <Icon className="mr-2">lock_open</Icon>
            Log in
          </Fab>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  checkMobile: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isLogin: PropTypes.bool.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default Login;
