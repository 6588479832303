import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import _get from 'lodash/get';
import { isOnline } from 'modules/app/helpers/hooks';
import PropTypes from 'prop-types';
import Acl, { PRIVILEGE_KEYS } from 'services/acl';

import {
  AppBar,
  Toolbar,
  IconButton,
  Icon,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import { GlobalActionBack } from '../../../../assets/icons/index';
import ConfirmModal from '../../../common/components/confirmModal/confirmModal';

const menuOptions = { vertical: 'top', horizontal: 'right' };

const FindingDetailsHeader = ({ history, location, title, showHeader, inspection, removeFinding }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const getParams = () => {
    return location.pathname.split('inspectionDetails/').pop().split('/findingDetails/');
  };

  const params = getParams();

  const canEditFinding = (wbs) => Acl.hasPrivilege(PRIVILEGE_KEYS.FINDINGS_EDIT, wbs);

  const canDeleteFinding = (wbs) => Acl.hasPrivilege(PRIVILEGE_KEYS.FINDINGS_DELETE, wbs);

  const handleCloseClick = () => {
    history.push(location.pathname.slice(0, location.pathname.indexOf('/findingDetails')), {
      checklist: location?.state?.checklist,
    });
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    history.push(`/inspectionDetails/${params[0]}/editFinding/${params[1]}`);
  };

  const handleRemove = async () => {
    await removeFinding(params[0], params[1]);

    history.replace(`/inspectionDetails/${params[0]}`);
  };

  if (!showHeader) {
    return null;
  }

  const isMenuOpen = !!anchorEl;
  const wbs = _get(inspection, 'wbsDetails.id', null);
  const findings = _get(inspection, 'findings', []);

  const currentFinding = findings.find((f) => f.localId === params[1]);

  const isAfterSync = currentFinding && !!currentFinding.syncCompletionDate && !isOnline;

  return (
    <>
      <AppBar position="fixed" color="primary" className="header-component">
        <Toolbar>
          <IconButton className="left-button" aria-label="Go back" onClick={handleCloseClick}>
            <Icon className="svg-medium">
              <GlobalActionBack />
            </Icon>
          </IconButton>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
          <div className="grow" />
          {(canEditFinding(wbs) || canDeleteFinding(wbs)) && !isAfterSync && (
            <IconButton className="right-button" aria-label="Menu" puppet-data="Finding-menu" onClick={handleMenuOpen}>
              <Icon className="svg-medium">more_vert</Icon>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={menuOptions}
        transformOrigin={menuOptions}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {canEditFinding(wbs) && !isAfterSync && (
          <MenuItem key="finding-details-edit:li" onClick={handleEdit} puppet-data="finding-details-edit">
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {canDeleteFinding(wbs) && !isAfterSync && (
          <ConfirmModal
            key="finding-details-delete:li"
            confirmFn={handleRemove}
            confirmTitle="Are you sure you want to delete this finding?"
            confirmIcon="delete"
          >
            <MenuItem puppet-data="finding-details-delete">
              <ListItemIcon>
                <Icon>delete</Icon>
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </ConfirmModal>
        )}
      </Menu>
    </>
  );
};

FindingDetailsHeader.propTypes = {
  title: PropTypes.string,
  showHeader: PropTypes.bool.isRequired,
  inspection: PropTypes.shape({}).isRequired,
};

FindingDetailsHeader.defaultProps = {
  title: 'Finding',
};

export default withRouter(FindingDetailsHeader);
