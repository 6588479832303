import React from 'react';

import PropTypes from 'prop-types';

import { Icon, Tooltip, IconButton } from '@material-ui/core';

const IconButtonTooltip = ({ tooltipTxt, onClick, icon, className }) => (
  <Tooltip title={tooltipTxt}>
    <IconButton id="icon:button" className={className} aria-label={tooltipTxt} onClick={onClick}>
      <Icon className="svg-medium">{icon}</Icon>
    </IconButton>
  </Tooltip>
);

IconButtonTooltip.propTypes = {
  tooltipTxt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  className: PropTypes.string,
};

IconButtonTooltip.defaultProps = {
  className: '',
};

export default IconButtonTooltip;
