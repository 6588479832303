import React from 'react';
import { useDispatch } from 'react-redux';

import { Formik } from 'formik';
import { string, object } from 'yup';

import { Modal, Box, TextField, Button } from '@material-ui/core';

import { addGuestUser } from './users.actions';

const UserDetailsModal = ({ open, handleClose, filterParams }) => {
  const dispatch = useDispatch();
  const requiredText = 'This field is required';

  const validationSchema = object().shape({
    firstName: string().required(requiredText).max(200, 'Provided name is too long.'),
    lastName: string().required(requiredText).max(200, 'Provided name is too long.'),
    id: string()
      .required(requiredText)
      .matches(
        RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i),
        'Provide correct UUID',
      ),
  });

  const initialFormValues = {
    firstName: '',
    lastName: '',
    id: '',
  };

  const handleClearDrawer = () => {
    handleClose();
  };

  const onSubmit = async (values, { resetForm }) => {
    const payload = {
      values,
      filterParams,
    };
    dispatch(addGuestUser(payload));
    resetForm();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClearDrawer}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="shadow box">
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          render={({ errors, handleSubmit, handleChange, values }) => (
            <>
              <TextField
                className="w-100 mb-3 mt-1"
                color="secondary"
                error={errors.firstName}
                helperText={errors.firstName}
                label="First name"
                multiline
                name="firstName"
                onChange={handleChange}
                outline
                placeholder="First name"
                required
                value={values.firstName}
                variant="outlined"
              />
              <TextField
                className="w-100 mb-3"
                color="secondary"
                error={errors.lastName}
                helperText={errors.lastName}
                label="Last name"
                multiline
                name="lastName"
                onChange={handleChange}
                outline
                placeholder="Last name"
                required
                value={values.lastName}
                variant="outlined"
              />
              <TextField
                className="w-100 mb-5"
                color="secondary"
                error={errors.id}
                helperText={errors.id}
                label="User id"
                multiline
                name="id"
                onChange={handleChange}
                outline
                placeholder="User id"
                required
                value={values.id}
                variant="outlined"
              />
              <div className="d-flex justify-content-end">
                <Button onClick={handleClearDrawer} className="mr-2">
                  Close
                </Button>
                <Button onClick={handleSubmit} type="submit" color="secondary">
                  Add user
                </Button>
              </div>
            </>
          )}
        />
      </Box>
    </Modal>
  );
};

export default UserDetailsModal;
