import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';

import { generateUId } from '../modules/app/helpers/utils';

PouchDB.plugin(PouchDBFind);

class Db {
  setUserId(id) {
    this.userId = id;
  }

  getCollectionName(collectionName) {
    return collectionName === 'token' ? collectionName : `${collectionName}${this.userId ? `_${this.userId}` : ''}`;
  }

  async reconnect(collectionName) {
    const name = this.getCollectionName(collectionName);
    await this[name].close();
    this[name] = new PouchDB(name, { revs_limit: 1, auto_compaction: true });
  }

  getDb(collectionName) {
    const name = this.getCollectionName(collectionName);

    if (!this[name]) {
      this[name] = new PouchDB(name, { revs_limit: 1, auto_compaction: true });
    }

    return this[name];
  }

  async removeDb(name) {
    try {
      const db = this.getDb(name);
      const { doc_count } = await db.info();

      if (doc_count !== 0) {
        const all = await this.getAll(name, { include_docs: false });
        const toDeleteDocs = all.rows.map((i) => ({
          _id: i.id,
          _rev: i.value.rev,
          _deleted: true,
        }));
        db.bulkDocs(toDeleteDocs);
        this[name] = null;
      }
    } catch (e) {
      throw e;
    }
  }

  batchInsert(dbName, docs) {
    return this.getDb(dbName).bulkDocs(docs);
  }

  insert(dbName, doc) {
    return this.getDb(dbName).post(doc);
  }

  update(dbName, doc, options = {}) {
    return this.getDb(dbName).put(doc, options);
  }

  retryUntilWritten(dbName, id, cb) {
    return this.findOne(dbName, id, false)
      .then((origDoc) => {
        const newDoc = cb(origDoc);

        if (!newDoc) {
          return null;
        }

        if (origDoc) {
          if (origDoc._id) {
            newDoc._id = origDoc._id;
          }
          if (origDoc._rev) {
            newDoc._rev = origDoc._rev;
          }
        }

        if (!newDoc._id) {
          newDoc._id = generateUId();
        }

        return this.update(dbName, newDoc);
      })
      .catch((err) => {
        if (err.status === 409) {
          return this.retryUntilWritten(dbName, id, cb);
        }

        throw err;
      });
  }

  async delete(dbName, id, rev) {
    try {
      await this.getDb(dbName).remove(id, rev);
      await this.getDb(dbName).viewCleanup();
      return this.getDb(dbName).compact();
    } catch (e) {
      throw e;
    }
  }

  async deleteAll(dbName, options) {
    try {
      const { docs } = await this.find(dbName, {
        ...options,
        fields: ['_id', '_rev'],
      });

      const toDeleteDocs = docs.map((i) => ({
        ...i,
        _deleted: true,
      }));

      await this.getDb(dbName).bulkDocs(toDeleteDocs);
      await this.getDb(dbName).viewCleanup();
      return this.getDb(dbName).compact();
    } catch (e) {
      throw e;
    }
  }

  getAll(dbName, options = {}) {
    return this.getDb(dbName).allDocs({
      include_docs: true,
      attachments: true,
      descending: true,
      ...options,
    });
  }

  getAllByQuery(dbName) {
    return this.getDb(dbName).query((doc, emit) => emit(doc), {
      include_docs: true,
    });
  }

  get(dbName, id) {
    return this.getDb(dbName).get(id);
  }

  createIndex(dbName, fields) {
    return this.getDb(dbName).createIndex({
      index: { fields },
    });
  }

  async find(dbName, options) {
    return this.getDb(dbName).find(options);
  }

  async findOne(dbName, params, throwError = true) {
    const options =
      typeof params === 'string'
        ? {
            selector: {
              $or: [{ _id: { $eq: params } }, { frontendId: { $eq: params } }, { id: { $eq: params } }],
            },
          }
        : params;
    const { docs } = await this.find(dbName, options);

    if (throwError && !docs.length) {
      throw new Error('Document not found');
    }
    return docs[0];
  }

  mapData = (data) => data.rows.map((i) => i.doc);

  mapData = (data, sortingFn = null, subSortingFn = null) => {
    if (sortingFn) {
      if (subSortingFn) {
        return data.rows
          .map((i) => i.doc)
          .sort(sortingFn)
          .map((wbs) => ({
            ...wbs,
            children: wbs.children.sort(subSortingFn),
          }));
      }
      return data.rows.map((i) => i.doc).sort(sortingFn);
    }
    return data.rows.map((i) => i.doc);
  };

  getAttachment(dbName, docId, attachmentId) {
    return this.getDb(dbName).getAttachment(docId, attachmentId);
  }

  putAttachment(dbName, docId, attachmentId, rev, attachment, type) {
    return this.getDb(dbName).putAttachment(docId, attachmentId, rev, attachment, type);
  }
}

export default new Db();
