import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { debounce } from 'lodash';
import { createCrumb, setTitle } from 'modules/app/components/app/app.actions';
import { DEFAULT_PAGINATION_PAGE_SIZE, FILTER_COOKIE_NAMES, INSPECTION_STATUSES } from 'modules/app/config/config';
import { compareObjectStructure, eraseCookie, getCookie, setCookie } from 'modules/app/helpers/utils';
import Filters from 'modules/common/components/filters';
import { setSimilarFiltersFrom } from 'modules/common/components/filters/global.actions';
import Table, { TableUniqueKey } from 'modules/common/components/table';
import {
  getFindings,
  clearSelectedFindings,
  getSimilarFindings,
  selectListFindings as selectFinding,
  selectAllFromList as selectAll,
  setSelectedFindings,
  addFindingsToWorkflow,
} from 'modules/findings/components/findings/findings.actions';
import { getSelectedIds } from 'modules/findings/components/findings/findings.selectors';
import Notifications from 'services/notifications';

import { Box, IconButton, Typography, Button, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { TABLE_CONFIG } from './config';

const useStyles = makeStyles((theme) => ({
  action: {
    color: theme.palette.secondary.light,
    marginLeft: '10px',
  },
  content: {
    padding: '0 0 50px 0',
  },
}));

const AddFindingsForm = ({
  onClose,
  findings,
  isLoading,
  totalCount,
  handleGetFindings,
  handleSelectFinding,
  selectedIds,
  workflow,
  handleSetSelectedFindings,
  match: { params, path },
}) => {
  const dispatch = useDispatch();
  const filterSetFrom = useSelector((state) => state.filtersConfig.similarFiltersFrom);
  const classes = useStyles();

  const emptyFilters = {
    search: '',
    filters: {
      mainSystem: [],
      assigned: [false],
      failureMode: [],
      inspector: [],
      status: [INSPECTION_STATUSES.completed],
      subSystem: [],
      wbs: [workflow?.wbsDetails?.id],
    },
    sort: { sortBy: 'lastActivityDate', sortDirection: 'desc' },
  };

  const [state, setState] = useState({
    selectedFindings: {},
  });

  const [filterState, setFilterState] = useState({
    filters: emptyFilters,
    size: DEFAULT_PAGINATION_PAGE_SIZE,
    page: 0,
  });

  const [additionalFilters, setAdditionalFilters] = useState({});

  const handleFiltersChange = (newFilterState, sortOnly) => {
    const filtersHaveChanged = JSON.stringify(filterState.filters) === JSON.stringify(newFilterState);

    if (!filtersHaveChanged) {
      const newState = {
        ...filterState,
        filters: newFilterState,
      };

      if (!sortOnly) {
        newState.page = 0;
      }

      setFilterState(newState);
    }
  };

  useEffect(() => {
    const { page, size, filters } = filterState;
    handleGetFindings(
      {
        sort: `${filters.sort.sortBy},${filters.sort.sortDirection.toUpperCase()}`,
        page,
        size,
      },
      filters,
    );
  }, [filterState, handleGetFindings, dispatch]);

  const debounced = debounce(handleFiltersChange, 250, {
    maxWait: 1300,
  });

  const filtersConfig = {
    name: FILTER_COOKIE_NAMES.WORKFLOW_ADD_FINDINGS,
    initialFilters: filterState.filters,
    filters: {
      mainSystem: {
        caption: 'Main System',
        primaryText: 'description',
        secondaryText: 'mainSystemCode',
        dependsOn: 'wbs',
        disabledTooltip: 'Select WBS first',
        dependentFilter: 'parkCodes',
        dependentFilterKey: 'parkCode',
      },
      subSystem: {
        caption: 'Sub System',
        primaryText: 'description',
        secondaryText: 'subSystemCode',
      },
      failureMode: {
        caption: 'Failure Mode',
        primaryText: 'description',
        secondaryText: 'code',
      },
      inspector: {
        caption: 'Inspector',
        primaryText: 'fullName',
        secondaryText: 'email',
      },
    },
    sort: {
      mainSystem: 'mainSystem.description',
      subSystem: 'subSystem.description',
      inspector: 'inspection.leadInspector',
      lastActivityDate: 'lastActivityDate',
      description: 'description',
      failureMode: 'failureMode.description',
    },
    sortLabels: {
      mainSystem: 'Main System',
      subSystem: 'Sub System',
      inspector: 'Inspector',
      lastActivityDate: 'Date and Time',
      description: 'Description',
      failureMode: 'Failure Mode',
    },
    filtersOrderIndex: {
      mainSystem: 1,
      subSystem: 2,
      failureMode: 3,
      inspector: 4,
      assigned: 5,
    },
    chipLabels: {
      wbs: 'Park/Package',
      mainSystem: 'Main System',
      failureMode: 'Failure Mode',
      subSystem: 'Sub System',
      similarFindings: 'Similar Findings',
    },
    chipsDisabled: ['wbs', 'status', 'assigned'],
    chipsHidden: ['wbs', 'status', 'assigned'],
    additionalFilters: {
      status: Object.values(INSPECTION_STATUSES)
        .filter((item) => !['INVALIDATED', 'DRAFT'].includes(item))
        .map((item) => ({ id: item })),
      assigned: [
        { label: 'YES', id: true },
        { label: 'NO', id: false },
      ],
    },
  };

  const onClearAll = () => {
    if (filterSetFrom && filterSetFrom.item.id) {
      dispatch(setSimilarFiltersFrom({}));
    }
    eraseCookie(FILTER_COOKIE_NAMES.WORKFLOW_ADD_FINDINGS);
    setFilterState({
      filters: { ...emptyFilters, search: filterState.filters.search },
      size: filterState.size,
      page: filterState.page,
    });
  };

  const onClearFuncs = {
    failureMode: () => {
      const newAdditionalFilters = {
        ...additionalFilters,
      };
      delete newAdditionalFilters.failureMode;
      dispatch(setSimilarFiltersFrom({}));
      setAdditionalFilters(newAdditionalFilters);
    },
    subSystem: () => {
      const newAdditionalFilters = {
        ...additionalFilters,
      };
      delete newAdditionalFilters.subSystem;
      dispatch(setSimilarFiltersFrom({}));

      setAdditionalFilters(newAdditionalFilters);
    },
  };

  const selectRelatedFindings = (findingId) => {
    if (state.selectedFindings[findingId]) {
      const newState = { ...state };
      delete newState.selectedFindings[findingId];
      setState(newState);
    } else {
      const finding = findings.find((item) => item.id === findingId);
      setState({
        ...state,
        selectedFindings: {
          ...state.selectedFindings,
          [findingId]: finding,
        },
      });
    }

    handleSelectFinding(findingId);
  };

  const handleSelectAllVisibleFindings = async (checkedAll, items) => {
    if (checkedAll) {
      handleSetSelectedFindings(Array.from(new Set([...selectedIds, ...items])));
    }

    if (!checkedAll) {
      handleSetSelectedFindings(Array.from(new Set(selectedIds.filter((id) => !items.includes(id)))));
    }

    return true;
  };

  const setPagination = (page, size) => {
    setFilterState({
      ...filterState,
      page,
      size,
    });
  };

  const handleClose = () => {
    dispatch(clearSelectedFindings());
    onClose();
  };

  const handleSubmit = () => {
    dispatch(addFindingsToWorkflow(workflow.id, selectedIds));
    handleClose();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '80vh',
        width: '80vw',
        padding: '32px',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',

          boxSizing: 'border-box',
        }}
      >
        <Typography variant="h6">Add Findings {selectedIds.length > 0 ? `(${selectedIds.length})` : ''}</Typography>
      </Box>
      <Box sx={{ height: '20%', boxSizing: 'border-box' }}>
        <Filters
          withTextSearch
          withSort
          searchLabel="description"
          callback={debounced}
          config={filtersConfig}
          onClearAll={onClearAll}
          onClearFuncs={onClearFuncs}
          additionalChips={additionalFilters}
          preserveAdditionalFilters
        />
      </Box>
      <Box
        sx={{
          height: '80%',
          position: 'relative',
          flex: 1,
          overflow: 'auto',
          boxSizing: 'border-box',
        }}
      >
        <Table
          config={TABLE_CONFIG}
          handleRowCheck={selectRelatedFindings}
          selectAllCheckboxes={handleSelectAllVisibleFindings}
          isLoading={isLoading}
          items={findings}
          pagination
          paginationCallback={setPagination}
          params={params}
          selectedIds={selectedIds}
          hideSelectAll={false}
          similarFiltersFrom={filterSetFrom.item}
          sort={filterState.filters.sort}
          totalCount={totalCount}
          withCheckboxes
          withMarkedRows
          tableUniqueKey={TableUniqueKey.ADD_FINDINGS_TO_WORKFLOW}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '16px',
          boxSizing: 'border-box',
        }}
      >
        <Button onClick={handleClose} variant="text">
          Close
        </Button>
        <Button onClick={handleSubmit} className={classes.action} disabled={selectedIds.length === 0}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.findings.isLoading,
  findings: state.findings.items,
  totalCount: state.findings.totalCount,
  selectedIds: getSelectedIds(state, ownProps),
});

const mapDispatchToProps = {
  handleGetFindings: getFindings,
  handleSelectFinding: selectFinding,
  clearSelectedFindings,
  getSimilarFindings,
  setTitle,
  createCrumb,
  handleSetSelectedFindings: setSelectedFindings,
  selectAll,
};

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFindingsForm));

export { Connected as AddFindingsForm };
