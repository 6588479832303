import {
  ADD_CHECKLIST_ELEMENT,
  ADD_CHECKLIST_GROUP,
  CLEAR_ACTIVE_CHECKLISTS,
  CLEAR_CHECKLIST_DETAILS,
  CLEAR_DATA,
  DELETE_CHECKLIST_GROUP,
  EDIT_CHECKLIST_ELEMENT,
  EDIT_CHECKLIST_GROUP,
  REORDER_CHECKLIST_ELEMENTS,
  REORDER_CHECKLIST_GROUPS,
  SET_ACTIVE_CHECKLISTS,
  SET_CHECKLIST_DETAILS,
  SET_CHECKLISTS,
  SET_CURRENT_WBS,
  SET_IS_LOADING,
  SET_IS_LOADING_BUILDER,
  SET_IS_LOADING_GROUPS,
  SET_WBSES,
  SET_WBSES_FOR_ADMIN,
  TOGGLE_DUPLICATE_MODAL,
} from './checklistEditor.actions';

const initialState = {
  activeChecklists: [],
  checklists: [],
  currentChecklistDetails: {
    inspectionId: '',
  },
  isDuplicateModalOpen: false,
  isLoading: false,
  isLoadingBuilder: false,
  isLoadingGroups: false,
  wbsesForAdmin: [],
  totalCount: 0,
  currentWbs: '',
};

export default (state = initialState, { type, payload, parentId }) => {
  const currentChecklist = state.currentChecklistDetails;

  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case SET_CHECKLIST_DETAILS:
      return {
        ...state,
        currentChecklistDetails: payload,
        isLoading: false,
        isLoadingBuilder: false,
      };
    case CLEAR_CHECKLIST_DETAILS:
      return {
        ...state,
        currentChecklistDetails: {
          inspectionId: '',
        },
      };
    case SET_CHECKLISTS:
      return {
        ...state,
        checklists: payload,
        totalCount: payload.length,
        isLoading: false,
      };
    case SET_ACTIVE_CHECKLISTS:
      return {
        activeChecklists: payload,
        isLoading: false,
      };

    case SET_IS_LOADING_BUILDER:
      return {
        ...state,
        isLoadingBuilder: payload,
      };

    case SET_IS_LOADING_GROUPS:
      return {
        ...state,
        isLoadingGroups: payload,
      };

    case ADD_CHECKLIST_GROUP:
      return {
        ...state,
        currentChecklistDetails: {
          ...currentChecklist,
          checkListGroupDefinitions: [...currentChecklist.checkListGroupDefinitions, payload],
        },
        isLoadingBuilder: false,
      };
    case EDIT_CHECKLIST_GROUP:
      return {
        ...state,
        currentChecklistDetails: {
          ...currentChecklist,
          checkListGroupDefinitions: currentChecklist.checkListGroupDefinitions.map((group) =>
            group.id === payload.id ? payload : group,
          ),
        },
        isLoadingGroups: false,
      };
    case REORDER_CHECKLIST_GROUPS:
      const updatedGroups = currentChecklist.checkListGroupDefinitions.map((group) => {
        const index = payload.groupsOrder[group.id];
        return { ...group, orderNumber: index };
      });

      return {
        ...state,
        currentChecklistDetails: {
          ...currentChecklist,
          checkListGroupDefinitions: updatedGroups,
        },
      };
    case DELETE_CHECKLIST_GROUP:
      const restOfGroups = currentChecklist.checkListGroupDefinitions.filter((group) => group.id !== payload);

      return {
        ...state,
        currentChecklistDetails: {
          ...currentChecklist,
          checkListGroupDefinitions: restOfGroups,
        },
        isLoadingBuilder: false,
      };

    case ADD_CHECKLIST_ELEMENT:
      return {
        ...state,
        currentChecklistDetails: {
          ...currentChecklist,
          checkListGroupDefinitions: currentChecklist.checkListGroupDefinitions.map((group) =>
            group.id === parentId
              ? {
                  ...group,
                  checkListElementDefinitions: [...group.checkListElementDefinitions, payload],
                }
              : group,
          ),
        },
        isLoadingGroups: false,
      };

    case SET_WBSES:
      return {
        ...state,
        wbsesForAdmin: payload,
      };
    case SET_WBSES_FOR_ADMIN:
      return {
        ...state,
        wbsesForAdmin: payload.filter((park) => park.roles.includes('ADMINISTRATOR')),
      };
    case EDIT_CHECKLIST_ELEMENT:
      return {
        ...state,
        currentChecklistDetails: {
          ...currentChecklist,
          checkListGroupDefinitions: currentChecklist.checkListGroupDefinitions.map((group) =>
            group.id === parentId
              ? {
                  ...group,
                  checkListElementDefinitions: group.checkListElementDefinitions.map((element) =>
                    element.id === payload.id ? payload : element,
                  ),
                }
              : group,
          ),
        },
        isLoadingGroups: false,
      };
    case REORDER_CHECKLIST_ELEMENTS:
      const updatedElements = (group) =>
        group.checkListElementDefinitions.map((element) => {
          const index = payload.elementsOrder[element.id];
          return { ...element, orderNumber: index };
        });

      return {
        ...state,
        currentChecklistDetails: {
          ...currentChecklist,
          checkListGroupDefinitions: currentChecklist.checkListGroupDefinitions.map((group) =>
            group.id === payload.checkListGroupDefinitionId
              ? {
                  ...group,
                  checkListElementDefinitions: updatedElements(group),
                }
              : group,
          ),
        },
        isLoadingGroups: false,
      };

    case CLEAR_DATA:
      return {
        ...initialState,
      };
    case CLEAR_ACTIVE_CHECKLISTS:
      return {
        activeChecklists: [],
      };

    case TOGGLE_DUPLICATE_MODAL:
      return {
        ...state,
        isDuplicateModalOpen: payload || !state.isDuplicateModalOpen,
      };

    case SET_CURRENT_WBS:
      return {
        ...state,
        currentWbs: payload,
      };

    default:
      return state;
  }
};
