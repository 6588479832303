import React, { PureComponent } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { AppBar, InputAdornment, IconButton, Icon, InputBase, Paper } from '@material-ui/core';

import { ClearTextInput, GlobalActionBack, GlobalActionSearch } from '../../../../assets/icons/index';

class SearchHeader extends PureComponent {
  state = {
    value: '',
  };

  handleCloseClick = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  handleCleanClick = (event) => {
    const { onChange } = this.props;
    this.setState({ value: '' });

    if (onChange) {
      onChange(event);
    }
  };

  handleChange = (event) => {
    const { onChange } = this.props;
    const { value } = event.target;
    this.setState({ value });

    if (onChange) {
      onChange(event);
    }
  };

  render() {
    const { onChange, value: inputValue, isMobile, dispatch, position, ...rest } = this.props;
    const { value } = this.state;

    const startAdornment = isMobile ? (
      <InputAdornment position="start" className="left-button">
        <IconButton onClick={this.handleCloseClick}>
          <Icon>
            <GlobalActionBack className="icon-dark" />
          </Icon>
        </IconButton>
      </InputAdornment>
    ) : (
      <InputAdornment position="start" className="left-button">
        <Icon>
          <GlobalActionSearch className="icon-dark" />
        </Icon>
      </InputAdornment>
    );

    const endAdornment = (
      <InputAdornment position="end" className="right-button">
        {value && (
          <IconButton onClick={this.handleCleanClick}>
            <Icon>
              <ClearTextInput />
            </Icon>
          </IconButton>
        )}
      </InputAdornment>
    );

    return (
      <AppBar position={isMobile ? 'fixed' : position} className="search-header-component">
        <Paper className="search-header-paper" elevation={1}>
          <InputBase
            className={classNames('search-input', { 'pl-3': !isMobile })}
            value={value}
            autoFocus
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            onChange={this.handleChange}
            {...rest}
          />
        </Paper>
      </AppBar>
    );
  }
}

SearchHeader.propTypes = {
  onChange: PropTypes.func.isRequired,
  position: PropTypes.string,
};

SearchHeader.defaultProps = {
  position: 'absolute',
};

export default SearchHeader;
