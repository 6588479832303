import React, { useEffect, useState } from 'react';

import { usePrevious } from 'hooks';
import _get from 'lodash/get';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';

import { Box, Dialog, Typography } from '@material-ui/core';

import {
  deleteAttachmentMobile,
  markAttachmentMobile,
} from '../../modules/common/components/attachmentPreview/attachmentPreview.actions';
import Header from '../../modules/common/components/attachmentPreviewHeader/attachmentPreviewHeaderMobile.component';
import AudioPlayer from '../../modules/common/components/audioPlayer/audioPlayer.component';
import { getAttachment } from '../../modules/common/components/filesList/filesList.actions';
import Image from '../../modules/common/components/image/image.component';
import Loader from '../../modules/common/components/loader/loader.component';

interface IAttachment {
  description: string;
  mimeType: string;
}

interface AttachmentPreviewMobileProps {
  canEdit: boolean;
  closeFn: (reload?: boolean) => boolean;
  editFn: (id: string) => void;
  id: string;
  open: boolean;
  registerSync: () => void;
}

const AttachmentPreviewMobile = ({
  canEdit,
  closeFn,
  editFn,
  id,
  open,
  registerSync,
}: AttachmentPreviewMobileProps) => {
  const [data, setData] = useState<Partial<IAttachment>>({});
  const [isLoading, setIsLoading] = useState(false);
  const prevOpen = usePrevious(open);

  const src = _get(data, 'file') || _get(data, 'path');
  const mimeType = _get(data, 'mimeType', '');
  const isImage = mimeType.includes('image');
  const isAudio = mimeType.includes('audio');

  const onClose = (reload?: boolean) => {
    setData({});
    setIsLoading(false);
    closeFn(reload === true);
  };

  const handleAttachmentDelete = async () => {
    try {
      setIsLoading(true);

      await deleteAttachmentMobile(data);

      if (registerSync) {
        registerSync();
      }

      onClose(true);
    } catch (err) {
      genericErrorHandler(err);
      setIsLoading(false);
    }
  };

  const handleAttachmentEdit = () => {
    onClose();
    editFn(id);
  };

  const handleMark = async () => {
    try {
      await markAttachmentMobile(data);
      onClose(true);
    } catch (err) {
      genericErrorHandler(err);
    }
  };

  const handleGetAttachment = async () => {
    try {
      setIsLoading(true);

      const data = await getAttachment(id);

      setData(data);
      setIsLoading(false);
    } catch (e) {
      genericErrorHandler(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!prevOpen && open) {
      handleGetAttachment();
    }
  }, [prevOpen, open]);

  return (
    <Dialog open={open} fullScreen>
      <Header
        onClose={onClose}
        onDelete={handleAttachmentDelete}
        onEdit={handleAttachmentEdit}
        onMark={handleMark}
        isImage={isImage}
        canEdit={canEdit}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Box className="attachment-preview-component container-wrapper mobilePreview" id="attachment-preview:button">
          {isImage && !!src && (
            <Box className="d-flex align-items-center justify-content-center image-wrapper">
              <Image className="adjust-img" src={src} alt={data.description} mimeType={data.mimeType} />
            </Box>
          )}
          {isAudio && !!src && <AudioPlayer src={src} mimeType={mimeType} />}
          {!isImage && !isAudio && <Box className="no-preview">Preview is not available for documents</Box>}
          {(isImage || isAudio) && !src && <Box className="no-preview">Preview is not available</Box>}
          {!!data.description && (
            <Typography color="textPrimary" variant="body1" className="p-4 break-word">
              {data.description}
            </Typography>
          )}
        </Box>
      )}
    </Dialog>
  );
};

export default AttachmentPreviewMobile;
