import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { createCrumb } from 'modules/app/components/app/app.actions';
import FindingDetailsWeb from 'modules/findings/components/findingDetails/findingDetailsWeb.component';
import { shape } from 'prop-types';

const RelatedFinding = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createCrumb(match.path, match.params));
  }, []);

  return <FindingDetailsWeb match={match} />;
};

RelatedFinding.propTypes = {
  match: shape({}).isRequired,
};

export default RelatedFinding;
