import { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

class FloatingElement extends Component {
  render() {
    const { render, isToastVisible } = this.props;
    const classes = classNames({
      'move-up': isToastVisible,
      'move-down': !isToastVisible,
    });

    return render(classes);
  }
}

FloatingElement.propTypes = {
  isToastVisible: PropTypes.bool.isRequired,
  render: PropTypes.func.isRequired,
};

export default FloatingElement;
