import React from 'react';

import PropTypes from 'prop-types';

import { Tab, Tabs } from '@material-ui/core';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabsHeader = ({ tabNames, tabsState, onChangeTab, style }) => (
  <Tabs variant="fullWidth" value={tabsState} onChange={onChangeTab} style={style}>
    {tabNames.map((name, i) => (
      <Tab label={name} {...a11yProps(i)} key={i} />
    ))}
  </Tabs>
);

TabsHeader.propTypes = {
  tabsState: PropTypes.number.isRequired,
  tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeTab: PropTypes.func.isRequired,
  style: PropTypes.object,
};

TabsHeader.defaultProps = {
  tabsState: 0,
  tabNames: [],
  onChangeTab: null,
  style: null,
};

export default TabsHeader;
