import React, { FC, MouseEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { NavigationContext } from 'context/navigation';

import { Box, Button, IconButton, Menu as MaterialMenu, Typography, useMediaQuery } from '@material-ui/core';
import { MenuOpenOutlined, MenuOutlined } from '@material-ui/icons';

import { Logo, MyAccount } from '../../../../assets/icons/index';
import Menu from '../menu/menu.container';
import withHeader from './header.hoc';

interface IHeaderProps {
  fullName: string;
  email: string;
}

const Header: FC<IHeaderProps> = ({ fullName, email }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const { toggleNavigation, opened } = useContext(NavigationContext);

  const media = useMediaQuery('(min-width: 1152px)');

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoClick = () => {
    history.push('/');
  };

  const user = `${fullName}`.trim() || email;

  return (
    <Box className="row justify-content-between align-items-center header-web-component pt-2">
      <Box className="d-flex align-items-center h-100">
        {!media && (
          <IconButton onClick={toggleNavigation}>{opened ? <MenuOpenOutlined /> : <MenuOutlined />}</IconButton>
        )}
        {(!opened || media) && <Logo className="logo cursor-pointer" onClick={onLogoClick} />}
      </Box>
      <Button
        className="row align-items-center no-gutters cursor-pointer"
        role="button"
        tabIndex={0}
        aria-owns={anchorEl ? 'logout-popup' : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <Typography className="title pr-2" variant="caption" noWrap>
          {user}
        </Typography>
        <MyAccount />
      </Button>
      <MaterialMenu id="logout-popup:menu" anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {/* @ts-ignore */}
        <Menu />
      </MaterialMenu>
    </Box>
  );
};

export default withHeader(Header);
