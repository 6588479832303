import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Icon, IconButton, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { ItemTypes } from './checklists.config';
import NewElement from './newElement.component';
import { useDnD } from './useDnD';

const ChecklistElement = ({
  changeOrder,
  element,
  groupId,
  index,
  isMobile,
  moveElement,
  scrolledId = '',
  viewMode,
}) => {
  const ref = useRef(null);

  const [editMode, setEditMode] = useState(false);
  const [noteVisible, setNoteVisible] = useState(false);

  const { drag, drop, isDragging, handlerId } = useDnD(
    ref,
    element.id,
    index,
    moveElement,
    changeOrder,
    ItemTypes.ELEMENT,
  );

  const openNote = () => element.note && setNoteVisible(!noteVisible);
  const toggleEditMode = () => setEditMode(!editMode);

  useEffect(() => {
    let timeoutID;
    if (scrolledId) {
      timeoutID = setTimeout(() => {
        const el = document.getElementById(`checklistItem-${scrolledId}`);

        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 100);
    }
    return () => {
      clearTimeout(timeoutID);
    };
  }, [scrolledId]);

  const maxHeight = noteVisible ? 1500 : 0;
  const opacity = isDragging ? 0 : 1;

  if (!viewMode) {
    drag(drop(ref));
  }

  if (editMode)
    return (
      <div key={element.id} className="checklist__item-wrapper">
        <NewElement groupId={groupId} onClose={toggleEditMode} element={element} edit />
      </div>
    );

  return (
    <div key={element.id} className="checklist__item-wrapper" style={{ opacity, backgroundColor: 'white' }}>
      <div
        ref={ref}
        data-handler-id={handlerId}
        className={classNames('checklist__item', {
          'checklist__item--isDrawer': !isMobile,
          viewMode,
          'note-open': noteVisible && element.note,
        })}
      >
        <div className="checklist__element">
          <div id={`checklistItem-${element.id}`} className="text-secondary checklist__element-desc mr-2">
            {element?.description}
          </div>
          <IconButton onClick={toggleEditMode}>
            <Edit />
          </IconButton>
        </div>
        <Icon onClick={openNote} className={`checklist__note ${element.note ? 'icon-dark' : 'icon-light'}`}>
          {noteVisible ? 'unfold_less' : 'unfold_more'}
        </Icon>
      </div>
      {element.note && (
        <div
          className={classNames('checklist__item-note', { open: noteVisible })}
          style={{ maxHeight: `${maxHeight}px` }}
        >
          <div className="text-secondary w-100 checklist__element-note">
            <Typography variant="body2" style={{ marginBottom: '5px' }}>
              Note:
            </Typography>
            {element.note}
          </div>
        </div>
      )}
    </div>
  );
};

ChecklistElement.propTypes = {
  changeOrder: PropTypes.func.isRequired,
  element: PropTypes.shape({}).isRequired,
  groupId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  moveElement: PropTypes.func.isRequired,
  scrolledId: PropTypes.string,
};

export default ChecklistElement;
