import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';

import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';

import { validateTableItem } from './table.actions';
import { TableContext, TableContextProvider, TableUniqueKey } from './table.context';
import Row from './tableBodyRow.component';
import { TableSettingsButton } from './tableExcelExportButton.component';
import HeaderRow from './tableHeaderRow.component';
import TableLoader from './tableLoader.component';
import NoItemsFound from './tableNoItemsFound.component';
import { TableExportToExcelButton } from './tableSettingsButton.component';
import { ITableConfig } from './types';

interface IEnhancedTableProps<T extends { id: string } = { id: string }> {
  className: string;
  config: ITableConfig;
  handleRowCheck: (id: string) => void;
  hideSelectAll: boolean;
  idProperty: string;
  isChecklistTable: boolean;
  isLoading: boolean;
  items: T[];
  onRowClick: (e: any, item: any) => void;
  pagination: boolean;
  paginationCallback: (page: number, size: number) => void;
  selectAllCheckboxes: (checked: boolean, selected: string[]) => Promise<boolean>;
  selectedIds: string[];
  similarFiltersFrom: any;
  totalCount: number;
  withCheckboxes: boolean;
  withMarkedRows: boolean;
  tableUniqueKey: TableUniqueKey;
  sort: {
    sortBy: string;
    sortDirection: 'desc' | 'asc';
  };
  skipCells: string[];
  excelExportParams?: {
    url: string;
    filters: { [key: string]: unknown[] };
    params: { [key: string]: unknown };
    search: string;
  };
  AdditionalSettingsComponent: ReactNode;
}

const EnhancedTable: FC<IEnhancedTableProps> = ({
  pagination,
  withCheckboxes,
  className,
  config: { columns, noItemsFoundText, disableFromRowSelecting },
  handleRowCheck,
  isChecklistTable,
  idProperty = 'id',
  isLoading,
  items,
  onRowClick,
  selectedIds,
  similarFiltersFrom,
  totalCount,
  withMarkedRows = false,
  hideSelectAll = false,
  selectAllCheckboxes,
  paginationCallback,
  sort = {
    sortBy: '',
    sortDirection: 'asc',
  },
  tableUniqueKey,
  skipCells = [],
  excelExportParams = null,
  AdditionalSettingsComponent,
}) => {
  const {} = useContext(TableContext);
  const [state, setState] = useState({
    pagination: {
      page: 0,
      size: 25,
    },
    checkedAll: false,
  });

  const [tableItems, setTableItems] = useState([]);

  useEffect(() => {
    setTableItems(items);
  }, [items]);

  useEffect(() => {
    if (selectedIds?.length === 0) {
      setState((s) => ({
        ...s,
        checkedAll: false,
      }));
    }
  }, [selectedIds, setState]);

  const handleChangePage = (_, newPage) => {
    const newState = {
      ...state,
      pagination: {
        ...state.pagination,
        page: newPage,
      },
      checkedAll: false,
    };
    setState(newState);
    paginationCallback(newPage, state.pagination.size);
  };

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev;

    setState({
      ...state,
      pagination: {
        page: 0,
        size: value,
      },
      checkedAll: false,
    });

    paginationCallback(0, value);
  };

  const isDisabledForSelection = (item) => {
    if (!disableFromRowSelecting.length) {
      return false;
    }

    return validateTableItem(disableFromRowSelecting, item);
  };

  const selectAll = async () => {
    if (!items.length) {
      return;
    }

    const selected = items.filter((item) => !isDisabledForSelection(item)).map((item) => item[idProperty]);

    const selectApproved = await selectAllCheckboxes(!state.checkedAll, selected);

    if (selectApproved) {
      setState({ ...state, checkedAll: !state.checkedAll });
    } else {
      setState({ ...state, checkedAll: false });
    }
  };

  const hidable = columns.some((col) => col.hidable);

  return (
    <TableContextProvider
      tableUniqueKey={tableUniqueKey}
      config={{ columns, noItemsFoundText, disableFromRowSelecting }}
      skipCells={skipCells}
    >
      <Table className={className}>
        <TableHead>
          <TableRow>
            <TableCell>
              {hidable && <TableSettingsButton />}
              {excelExportParams && <TableExportToExcelButton {...excelExportParams} />}
              {AdditionalSettingsComponent && AdditionalSettingsComponent}
            </TableCell>

            {pagination && (
              <TablePagination
                colSpan={columns.length + 1}
                count={totalCount}
                rowsPerPage={state.pagination.size * 1}
                page={state.pagination.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </TableRow>
          <HeaderRow
            config={columns}
            currentSort={sort.sortBy}
            currentSortDirection={sort.sortDirection}
            withCheckboxes={withCheckboxes}
            isCheckedAll={state.checkedAll}
            isChecklistTable={isChecklistTable}
            selectAllCheckboxes={selectAll}
            hideSelectAll={hideSelectAll}
            skipCells={skipCells}
          />
        </TableHead>
        {isLoading && <TableLoader columns={columns.length} />}
        {!isLoading && (
          <TableBody>
            {tableItems.map((item, index) => (
              <Row
                key={item[idProperty] || index}
                item={item}
                onRowClick={onRowClick}
                isChecklistTable={isChecklistTable}
                config={columns}
                idProperty={idProperty}
                withCheckboxes={withCheckboxes}
                withMarkedRows={withMarkedRows}
                marked={similarFiltersFrom?.id === item[idProperty]}
                isChecked={selectedIds?.includes(item.id)}
                handleRowCheck={handleRowCheck}
                disableFromRowSelecting={disableFromRowSelecting}
                skipCells={skipCells}
              />
            ))}
            {tableItems.length === 0 && <NoItemsFound columnsLength={columns.length} text={noItemsFoundText} />}
          </TableBody>
        )}
        <TableFooter>
          <TableRow>
            <TableCell>
              {hidable && <TableSettingsButton />}
              {excelExportParams && <TableExportToExcelButton {...excelExportParams} />}
              {AdditionalSettingsComponent && AdditionalSettingsComponent}
            </TableCell>
            {pagination && (
              <TablePagination
                colSpan={columns.length + 1}
                count={totalCount}
                rowsPerPage={state.pagination.size * 1}
                page={state.pagination.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContextProvider>
  );
};

export default EnhancedTable;
