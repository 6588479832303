import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterChildContext, useHistory, useParams } from 'react-router-dom';

import _get from 'lodash/get';
import { isMobile } from 'modules/app/helpers/hooks';
import Loader from 'modules/common/components/loader/loader.component';
import InspectionReport from 'modules/inspections/components/inspectionReports/inspectionReport.component';

import { Box, Card, CardContent, Grid, Icon, IconButton, List, ListItem, Typography } from '@material-ui/core';
import { Lock, LockOpen } from '@material-ui/icons';

import {
  FindingDescription,
  InspectionProjectStageConstruction,
  InspectionProjectStageOm,
  InspectionStatusClosed,
  InspectionStatusDraft,
  InspectionStatusFinished,
  InspectionStatusOngoing,
  InspectionStatusPlanned,
} from '../../../../assets/icons/index';
import { CONSTRUCTION_TYPE, INSPECTION_STATUSES, INSPECTION_STATUSES_DATE_LABELS } from '../../../app/config/config';
import { getInspections } from '../inspections/inspections.actions';

const goToDetails = (e, isReadOnly, history) => {
  e.stopPropagation();
  if (isReadOnly) {
    return;
  }
  history.push(`/inspectionDetails/${e.currentTarget.id}`);
};

interface ILocationState {
  direction?: 'asc' | 'desc';
  page: number;
  size: number;
  sort: string;
  filters: any[];
}

interface InspectionItemProps {
  inspection: any;
  index: number;
  idKey?: string;
  className?: string;
  stateFilters?: any;
}

const InspectionItem = ({
  inspection,
  index,
  className = '',
  idKey = 'localId',
  stateFilters,
}: InspectionItemProps) => {
  const mobile = isMobile();
  const { user } = useSelector((state: IStore) => state.auth);
  const dispatch = useDispatch();

  const history = useHistory<RouterChildContext['router']['history']>();
  const params = useParams<{ type: string }>();

  const isLeadInspector = (inspection) => inspection.leadInspectorDetails?.id === user.id;

  const showInspectionReportLoader =
    inspection.structureType !== 'MAIN' &&
    ((inspection.status === INSPECTION_STATUSES.finished && inspection.finishReportAttachmentId === null) ||
      (inspection.status === INSPECTION_STATUSES.completed && inspection.closeReportAttachmentId === null));

  const isWaiting = true;

  useEffect(() => {
    if (showInspectionReportLoader) {
      setTimeout(() => {
        dispatch(
          getInspections(
            {
              status: params.type,
              page: stateFilters.page,
              size: stateFilters.size,
              sort: stateFilters.sortDirection.toUpperCase(),
            },
            stateFilters.filters,
            isWaiting,
          ),
        );
      }, 10 * 1000);
    }
  }, [showInspectionReportLoader]);

  const isReadOnly = mobile && !inspection.checkedOutForMobile;

  const handleDetails = (e) => {
    if (!mobile) {
      goToDetails(e, isReadOnly, history);
    }

    if (isLeadInspector(inspection) || inspection.status === INSPECTION_STATUSES.draft) {
      goToDetails(e, isReadOnly, history);
    }
  };

  const showLockIcon = () => {
    if (inspection.status === 'CLOSED' || mobile) {
      return null;
    }
    return (
      <IconButton className="ml-2">
        {inspection.checkedOutForMobile && <Lock color="error" />}

        {!inspection.checkedOutForMobile && <LockOpen />}
      </IconButton>
    );
  };

  const checkInspectionStatus = (insp) => (
    <Icon className="svg-large">
      {insp.status === INSPECTION_STATUSES.planned && (
        <InspectionStatusPlanned className="icon-status large status--planned" />
      )}
      {insp.status === INSPECTION_STATUSES.ongoing && (
        <InspectionStatusOngoing className="icon-status large status--ongoing" />
      )}
      {insp.status === INSPECTION_STATUSES.draft && (
        <InspectionStatusDraft className="icon-status large status--draft" />
      )}
      {insp.status === INSPECTION_STATUSES.finished && (
        <InspectionStatusFinished className="icon-status large status--finished" />
      )}
      {insp.status === INSPECTION_STATUSES.completed && (
        <InspectionStatusClosed className="icon-status large status--closed" />
      )}
    </Icon>
  );

  const renderFields = (insp) => {
    const renderFindingsCount = () => {
      if (!insp.findings || insp.findings.length === 0) {
        return <span className="pr-1">No findings added</span>;
      }

      const syncFindings = insp.findings.filter((finding) => !!finding.syncCompletionDate);

      const synchronizedFindings = isMobile() ? syncFindings.length : insp.numberOfSynchronizedFindings;

      const overallFindings = isMobile() ? insp.findings.length : insp.numberOfFindings;

      return (
        <>
          <span className="pr-1">{`${synchronizedFindings} / ${overallFindings}`}</span>
          {`${insp.findings.length > 1 ? 'Findings' : 'Finding'} Synchronized`}
        </>
      );
    };

    return (
      <CardContent className="card-content">
        <Grid container className="align-items-center justify-content-between flex-nowrap">
          <Grid xs={6} container className="findings flex-nowrap">
            <Icon className="svg-small">
              <FindingDescription className="icon-dark" />
            </Icon>
            <Typography color="textSecondary" variant="body2" className="break-word">
              {renderFindingsCount()}
            </Typography>
          </Grid>
          <Grid xs={6} container className="align-items-center justify-content-end pl-1">
            <Typography color="textPrimary" className="break-word">
              {insp.siteFacility}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    );
  };

  const renderSubInspectionFields = (insp) => (
    <CardContent key={insp.id}>
      <Grid container className="align-items-center justify-content-between flex-nowrap">
        <Grid xs={6} container className="findings flex-nowrap">
          <Icon className="svg-small">
            <FindingDescription className="icon-dark" />
          </Icon>
          <Typography color="textSecondary" variant="body2" className="break-word">
            <span className="pr-1">{insp.partOfPlanInspections ? insp.partOfPlanInspections.length : 0}</span>
            {insp.partOfPlanInspections.length === 1 ? 'SubInspection' : 'SubInspections'}
          </Typography>
        </Grid>
        <Grid xs={6} container className="align-items-center justify-content-end pl-1">
          <Typography color="textPrimary" className="break-word">
            {insp.siteFacility}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  );

  const renderSubInspections = inspection.partOfPlanInspections.map((subInspection) => {
    const isSubReadOnly = (mobile && !subInspection.checkedOutForMobile) || (mobile && !isLeadInspector(subInspection));

    const handleSubDetails = (e) => {
      if (!mobile) {
        goToDetails(e, isSubReadOnly, history);
      }

      if (isLeadInspector(subInspection)) {
        goToDetails(e, isSubReadOnly, history);
      }
    };

    return (
      <ListItem
        key={subInspection.id}
        onClick={handleSubDetails}
        id={subInspection[idKey]}
        divider
        className={`d-flex justify-content-between ${isSubReadOnly ? 'sub-inspection--read-only' : ''}`}
      >
        <Grid container className="align-items-center">
          <Grid item xs={6}>
            <Box className="d-inline-flex flex-column">
              <Typography className="cursor-pointer" color="textPrimary" variant="subtitle2">
                {subInspection.title || subInspection.functionalLocation}
              </Typography>
              <Typography className="cursor-pointer" color="textSecondary" variant="caption">
                {subInspection.leadInspector}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="d-flex align-items-center flex-nowrap justify-content-end">
              <Box className="mr-3">
                <InspectionReport inspection={subInspection} />
              </Box>
              {checkInspectionStatus(subInspection)}
              <Typography variant="body2" color="textSecondary">
                {_get(INSPECTION_STATUSES_DATE_LABELS, subInspection.status, '')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </ListItem>
    );
  });

  const cardContentReadOnly = !isLeadInspector(inspection) && mobile && inspection.status !== INSPECTION_STATUSES.draft;

  return (
    <Card
      className={`inspection-card ${className} ${isReadOnly ? 'inspection-card--read-only' : ''}`}
      id={inspection[idKey]}
      onClick={handleDetails}
    >
      <CardContent
        className={`cursor-pointer card-content row no-gutters flex-nowrap align-items-center ${
          cardContentReadOnly ? 'inspection-card--read-only' : ''
        }`}
      >
        <Icon className="svg-large icon-light-blue">
          {inspection.type === CONSTRUCTION_TYPE ? (
            <InspectionProjectStageConstruction />
          ) : (
            <InspectionProjectStageOm />
          )}
        </Icon>
        <Box className="flex-fill w-100 ml-2">
          <Box className="title break-word">{inspection.title ? inspection.title : inspection.functionalLocation}</Box>
          <Typography color="textSecondary" className="subtitle break-word">
            {inspection.wbs}
          </Typography>
        </Box>

        <Box className="align-items-center row no-gutters flex-nowrap">
          <Box className="mr-3">
            {showInspectionReportLoader ? <Loader /> : <InspectionReport inspection={inspection} />}
          </Box>
          {checkInspectionStatus(inspection)}
          <Typography variant="body2" color="textSecondary">
            {_get(INSPECTION_STATUSES_DATE_LABELS, inspection.status, '')}
          </Typography>
          {showLockIcon()}
        </Box>
      </CardContent>
      {inspection.partOfPlanInspections.length > 0 ? renderSubInspectionFields(inspection) : renderFields(inspection)}
      {inspection.partOfPlanInspections.length > 0 && (
        <>
          <hr />
          <List className="subinspections-list" id={`subinspection-list-${index}:ul`}>
            {renderSubInspections}
          </List>
        </>
      )}
    </Card>
  );
};

export default InspectionItem;
