import React from 'react';

const LoginTurbines = (props) => (
  <svg width={360} height={361} {...props}>
    <g fill="#FFF">
      <circle cx={157.136} cy={121.136} r={14.936} />
      <path d="M182.945 105.972C223.967 31.96 230.98 2.44 227.038.164c-3.939-2.271-25.982 18.54-69.538 91.016 10.854.115 20.3 6.033 25.445 14.792zm-55.422 14.941c0-5.35 1.429-10.364 3.902-14.713C47.012 107.694 18 116.37 18 120.913c0 4.54 29.009 13.22 113.425 14.71a29.632 29.632 0 0 1-3.902-14.71zm55.393 14.987c-5.12 8.77-14.566 14.699-25.416 14.828 43.524 72.39 65.545 93.183 69.487 90.915 3.935-2.275-3.07-31.777-44.071-105.743zM142.2 154.8v205.6h29.87V202.252c-8.562-12.665-18.447-28.26-29.87-47.452z" />
      <circle cx={276.358} cy={216.058} r={9.958} />
      <path d="M259.825 204.386c-23.461-42.635-27.573-59.847-25.261-61.19 2.314-1.328 15.145 10.822 40.33 52.42-6.285.482-11.772 3.802-15.07 8.77zm36.567 11.297c0-3.128-.789-6.051-2.074-8.683 48.416 1.019 65.318 6.048 65.318 8.72 0 2.66-16.898 7.692-65.336 8.711 1.292-2.65 2.092-5.594 2.092-8.748zM259.82 226.8c3.3 4.972 8.78 8.29 15.076 8.777-25.178 41.598-38.02 53.751-40.33 52.42-2.316-1.354 1.796-18.562 25.253-61.197zm26.574 9l-.082 124.243H266.4l.083-92.772c5.587-8.125 12.182-18.46 19.911-31.471z" />
      <circle cx={42.358} cy={216.058} r={9.958} />
      <path d="M25.826 204.386C2.365 161.75-1.75 144.539.565 143.196c2.315-1.328 15.149 10.822 40.33 52.42-6.289.482-11.775 3.802-15.069 8.77zm36.58 11.297c0-3.128-.788-6.051-2.077-8.683 48.416 1.019 65.311 6.048 65.311 8.72 0 2.66-16.902 7.692-65.34 8.711 1.31-2.65 2.106-5.594 2.106-8.748zM25.819 226.8c3.301 4.972 8.784 8.29 15.077 8.777C15.714 277.164 2.88 289.317.565 287.982c-2.315-1.34 1.8-18.547 25.254-61.182zm26.575 9l-.082 124.243H32.4l.083-92.772c5.59-8.125 12.182-18.46 19.911-31.471z" />
    </g>
  </svg>
);

export default LoginTurbines;
