import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

import { getInspectionTodosWeb } from '../inspectionDetails.actions';
import { InspectionDetailsTodosListItem } from './inspectionDetailsTodosListItem.component';

const InspectionDetailsTodosList = ({ mainSystemId, inspectionId, subSystemId }) => {
  const dispatch = useDispatch();
  const { todos } = useSelector((state) => state.inspection);

  useEffect(() => {
    if (mainSystemId && subSystemId) {
      dispatch(getInspectionTodosWeb(mainSystemId, subSystemId));
    } else if (mainSystemId) {
      dispatch(getInspectionTodosWeb(mainSystemId, ''));
    }
  }, [mainSystemId, subSystemId]);

  const renderTodos = todos?.map((todo) => {
    return <InspectionDetailsTodosListItem key={todo.id} todo={todo} inspectionId={inspectionId} />;
  });

  if (todos.length === 0) {
    return null;
  }

  return (
    <div className="todo-list-wrapper">
      <Typography variant="body2" className="todo-header">
        Past Todos:
      </Typography>
      <div className="todo-list">{renderTodos}</div>
    </div>
  );
};

export { InspectionDetailsTodosList };
