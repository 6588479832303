import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import { PRIVILEGE_KEYS, GLOBAL_WBS } from 'modules/app/config/config';

const perms = {
  INSPECTOR: [
    'INSPECTIONS.LIST',
    'INSPECTIONS.DETAILS',
    'INSPECTIONS.CREATE',
    'INSPECTIONS.EDIT',
    'INSPECTIONS.DELETE',
    'INSPECTIONS.EXECUTE',
    'INSPECTIONS.FINISH',
    'INSPECTIONS.CLOSE',
    'FINDINGS.LIST',
    'FINDINGS.CREATE',
    'FINDINGS.DELETE',
    'FINDINGS.DETAILS',
    'FINDINGS.EDIT',
    'WBSES.LIST',
    'USERS.LIST',
    'USERS.DETAILS',
    'WORKFLOWS.LIST',
    'WORKFLOWS.CREATE',
    'WORKFLOWS.DETAILS',
    'FINDINGS.RELATED',
  ],
  VF_MANAGER: [
    'INSPECTIONS.LIST',
    'INSPECTIONS.DETAILS',
    'INSPECTIONS.EDIT',
    'FINDINGS.DETAILS',
    'USERS.LIST',
    'USERS.DETAILS',
    'FINDINGS.LIST',
    'WBSES.LIST',
    'WORKFLOWS.LIST',
    'WORKFLOWS.CREATE',
    'WORKFLOWS.DETAILS',
    'WORKFLOWS.DELETE',
    'REPORTS.LIST',
    'DASHBOARDS.VIEW',
    'FINDINGS.RELATED',
  ],
  CONTRACTOR: ['WORKFLOWS.LIST', 'WORKFLOWS.CREATE', 'WORKFLOWS.DETAILS', 'FINDINGS.RELATED'],
  QA_MANAGER: [
    'INSPECTIONS.LIST',
    'INSPECTIONS.DETAILS',
    'USERS.LIST',
    'USERS.DETAILS',
    'WBSES.LIST',
    'WORKFLOWS.LIST',
    'WORKFLOWS.CREATE',
    'WORKFLOWS.DETAILS',
    'REPORTS.LIST',
    'FINDINGS.RELATED',
  ],
  PCB_MEMBER: [
    'USERS.LIST',
    'USERS.DETAILS',
    'WBSES.LIST',
    'WORKFLOWS.LIST',
    'WORKFLOWS.CREATE',
    'WORKFLOWS.DETAILS',
    'FINDINGS.RELATED',
  ],
  ADMINISTRATOR: [
    'INSPECTIONS.LIST',
    'INSPECTIONS.DETAILS',
    'USERS.LIST',
    'USERS.DETAILS',
    'WBSES.LIST',
    'WBSES.EDIT',
    'REPORTS.LIST',
    'DASHBOARDS.VIEW',
    'FINDINGS.RELATED',
    'CHECKLISTS.LIST',
    'CHECKLISTS.EDIT',
  ],
  VIEWER: [
    'DASHBOARDS.VIEW',
    'INSPECTIONS.LIST',
    'INSPECTIONS.DETAILS',
    'FINDINGS.DETAILS',
    'USERS.LIST',
    'USERS.DETAILS',
    'WBSES.LIST',
    'WORKFLOWS.LIST',
    'WORKFLOWS.DETAILS',
    'FINDINGS.RELATED',
  ],
  REVIEWER: ['WORKFLOWS.LIST', 'WORKFLOWS.DETAILS'],
};

class Acl {
  setData(roles, wbses) {
    this.roles = roles;
    this.wbses = wbses;
  }

  clearData() {
    this.roles = null;
    this.wbses = null;
    this.hasPrivilege.cache.clear();
  }

  hasPrivilegeTmp(privilege, wbsId) {
    if ((wbsId && !this.wbses) || !this.roles || wbsId === null) {
      return false;
    }

    if (wbsId) {
      const wbsesKeys = Object.keys(this.wbses);

      let wbsRoles = wbsesKeys.reduce((acc, curr) => {
        if (wbsId.includes(curr)) {
          return acc.concat(this.wbses[curr]);
        }
        return acc;
      }, []);

      const globalWbs = wbsesKeys.includes(GLOBAL_WBS);

      if (globalWbs) {
        wbsRoles = wbsRoles.concat(this.wbses[GLOBAL_WBS]);
      }

      if (!wbsRoles.length) {
        return false;
      }

      return this.checkPrivilege(uniq(wbsRoles), privilege);
    }

    return this.checkPrivilege(this.roles, privilege);
  }

  hasPrivilege = memoize(this.hasPrivilegeTmp, (a, b) => `${a}-${b}`);

  checkPrivilege(userRoles = [], privilege) {
    if (!userRoles.length) {
      return false;
    }

    const userPerms = Object.keys(perms).reduce((acc, curr) => {
      if (userRoles.includes(curr)) {
        acc[curr] = perms[curr];
      }

      return acc;
    }, {});

    const roles = Object.keys(userPerms);

    for (let i = 0; i < roles.length; i += 1) {
      if (perms[roles[i]].includes(privilege)) {
        return true;
      }
    }

    return false;
  }
}

export { PRIVILEGE_KEYS };
export default new Acl();
