import React, { PureComponent, Fragment, memo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FindingSeverityObservation, FindingSeverityNonConformity, GlobalActionEdit } from 'assets/icons/index';
import { INSPECTION_STATUSES } from 'modules/app/config/config';
import { encodeParam } from 'modules/app/helpers/utils';
import ConfirmModal from 'modules/common/components/confirmModal/confirmModal';
import DropdownMenu from 'modules/common/components/dropdownMenu/dropdownMenu.component';
import { removeFinding } from 'modules/findings/components/findingDetails/findingDetails.actions';
import { FINDING_TYPES } from 'modules/findings/components/newFinding/newFinding.model';
import PropTypes from 'prop-types';

import { MenuItem, ListItemIcon, ListItemText, Divider, Icon } from '@material-ui/core';
import { NotificationImportant } from '@material-ui/icons';

const FindingActionItem = memo(({ icon, onClick, text, id, isWorkflowActive }) => (
  <MenuItem id={id} onClick={onClick} disabled={isWorkflowActive}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={text} />
  </MenuItem>
));

FindingActionItem.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isWorkflowActive: PropTypes.bool.isRequired,
};

FindingActionItem.defaultProps = {
  onClick: null,
};

class FindingActionDropdown extends PureComponent {
  editFinding = () => {
    const {
      history,
      finding: { inspection, id: findingId },
    } = this.props;

    history.push(`/inspectionDetails/${inspection.id}/editFinding/${findingId}`);
  };

  previewFinding = () => {
    const {
      history,
      finding: { inspection, id: findingId },
    } = this.props;

    history.push(`/inspectionDetails/${inspection.id}/findingDetails/${findingId}`);
  };

  reportFinding = (e) => {
    const {
      currentTarget: { id: type },
    } = e;
    const {
      history,
      finding: { inspection, id: findingId },
    } = this.props;

    history.push(`/workflow/${type}/${inspection.id}/${encodeParam([findingId])}`);
  };

  invalidateFinding = async () => {
    const {
      history,
      removeFinding,
      finding: { inspection, id: findingId },
    } = this.props;

    await removeFinding(inspection.id, findingId);
    history.replace(`/inspectionDetails/${inspection.id}`);
  };

  render() {
    const {
      checkedOutForMobile,
      finding: { findingType, inspection, workflows, id },
      inspectionStatus,
      user,
    } = this.props;

    const menuItems = [];
    const closedInspection = inspectionStatus === INSPECTION_STATUSES.completed;
    const isWorkflowActive = workflows.length > 0;
    const isOnlyViewerOrInstructor = user.roles.length === 1 && ['INSPECTOR', 'VIEWER'].includes(user.roles[0]);
    const isOnlyViewer = user.roles.length === 1 && user.roles[0] === 'VIEWER';
    const isTodoCheckedOutForMobile = findingType === FINDING_TYPES.TODO.value && checkedOutForMobile;

    if (!closedInspection && !isOnlyViewer) {
      menuItems.push(
        <Fragment key={`${id}-edit-delete-action`}>
          <FindingActionItem
            isWorkflowActive={isWorkflowActive}
            id="edit"
            text="Preview"
            icon={<FindingSeverityObservation className="icon-dark" />}
            onClick={this.previewFinding}
          />
          {!isTodoCheckedOutForMobile && (
            <>
              <FindingActionItem
                isWorkflowActive={isWorkflowActive}
                id="edit"
                text="Edit"
                icon={<GlobalActionEdit className="icon-dark" />}
                onClick={this.editFinding}
              />
              <ConfirmModal
                confirmFn={this.invalidateFinding}
                confirmTitle="Are you sure you want to delete this finding?"
                confirmIcon="delete"
              >
                <Divider />
                <FindingActionItem
                  isWorkflowActive={isWorkflowActive}
                  id="invalidate"
                  text="Delete"
                  icon={<Icon>delete</Icon>}
                />
              </ConfirmModal>
            </>
          )}
        </Fragment>,
      );

      menuItems.push();
    }

    if (closedInspection && !isOnlyViewerOrInstructor) {
      menuItems.push(
        <Fragment key={`${id}-create-workflow-action`}>
          <FindingActionItem
            isWorkflowActive={isWorkflowActive}
            id="observation"
            text="Register Observation"
            icon={<FindingSeverityObservation className="icon-severity-observation" />}
            onClick={this.reportFinding}
          />
          <Divider />
          <FindingActionItem
            isWorkflowActive={isWorkflowActive}
            id="defect_notification"
            text="Register Defect Notification"
            icon={<NotificationImportant />}
            onClick={this.reportFinding}
          />
          <Divider />
          <FindingActionItem
            isWorkflowActive={isWorkflowActive}
            id="nonconformity"
            text="Register NonConformity"
            icon={<FindingSeverityNonConformity />}
            onClick={this.reportFinding}
          />
        </Fragment>,
      );
    }

    return menuItems.length > 0 ? <DropdownMenu>{menuItems}</DropdownMenu> : null;
  }
}

FindingActionDropdown.propTypes = {
  finding: PropTypes.shape({
    workflows: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    inspection: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  checkedOutForMobile: PropTypes.bool.isRequired,
  inspectionStatus: PropTypes.string.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  inspectionStatus: state.inspection.data.status,
  checkedOutForMobile: state.inspection.data.checkedOutForMobile,
  user: state.auth.user,
});

const mapDispatchToProps = {
  removeFinding,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FindingActionDropdown));
