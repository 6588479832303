import React from 'react';

import { wordCaseNormaliser } from 'modules/app/helpers/utils';

import ChecklistsActionMenu from './checklistsActionMenu.component';

export const TABLE_CONFIG = {
  columns: [
    {
      caption: 'Checklist name',
      dataField: 'description',
      sortField: 'description',
      renderCell: (_, item) => {
        return <div>{item?.description}</div>;
      },
    },
    {
      caption: 'Park',
      dataField: 'park',
      sortField: 'park',
      renderCell: (_, item) => <div>{item.park?.description || item.park?.name}</div>,
    },
    {
      caption: 'Status',
      dataField: 'status',
      sortField: 'status',
      renderCell: (_, item) => <div>{wordCaseNormaliser(item.status)}</div>,
    },
    {
      caption: 'Creation date',
      dataField: 'creationDate',
      sortField: 'creationDate',
      renderCell: (_, item) => <div>{new Date(item.createDate).toLocaleString()}</div>, // TODO: rewrite the line
    },
    {
      caption: '',
      dataField: '',
      sortField: '',
      renderCell: (_, item) => <ChecklistsActionMenu item={item} />,
    },
  ],
};

export const ItemTypes = {
  GROUP: 'group',
  ELEMENT: 'element',
};
