import _get from 'lodash/get';
import { registerSync } from 'modules/app/components/app/app.actions';
import { INSPECTION_STATUSES } from 'modules/app/config/config';
import { generateUId, sortTree } from 'modules/app/helpers/utils';
import {
  setInspectionStatus,
  getInspectionWeb,
  getInspection,
} from 'modules/inspections/components/inspectionDetails/inspectionDetails.actions';
import Api from 'services/api';
import Db from 'services/db';

import NewFindingModel, { FINDING_TYPES } from './newFinding.model';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'NEW_FINDING';
const CLEAN_DATA = `${namespace}_CLEAN_DATA`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const SAVE_FINDING = `${namespace}_SAVE_FINDING`;

/*
 * REDUX ACTIONS
 */
const saveFindingWeb = (inspectionId, data) => async (dispatch) => {
  const inspection = await getInspectionWeb(inspectionId);
  const newData = { ...data, _id: generateUId() };

  if (inspection.status === INSPECTION_STATUSES.planned) {
    await dispatch(setInspectionStatus(inspectionId, INSPECTION_STATUSES.ongoing));
  }

  const parsedData = new NewFindingModel(
    { ...newData, inspectionId: inspection.id },
    inspection,
  ).parseDetailsForSynchronization();
  const newFinding = await Api.post('/api/findings', parsedData);

  return newFinding;
};

const saveFindingMobile = (inspectionId, data) => async (dispatch) => {
  try {
    const inspectionDetails = await Db.findOne('inspections', inspectionId);

    if (inspectionDetails.status === INSPECTION_STATUSES.planned) {
      await dispatch(setInspectionStatus(inspectionId, INSPECTION_STATUSES.ongoing));
    }

    const finding = {
      createdAt: new Date().toISOString(),
      inspectionLocalId: inspectionId,
      ...data,
    };

    const { id } = await Db.insert('sync_findings', finding);
    finding.id = id;

    const findings = inspectionDetails.findings ? inspectionDetails.findings.concat(finding) : [finding];

    await Db.retryUntilWritten('inspections', inspectionDetails._id, (obj) => ({
      ...obj,
      findings,
    }));

    dispatch(registerSync());

    return finding;
  } catch (e) {
    throw e;
  }
};

const saveFinding = (inspectionId, data) => async (dispatch, getState) => {
  const {
    app: { isMobile },
  } = getState();
  try {
    const finding = isMobile
      ? await dispatch(saveFindingMobile(inspectionId, data))
      : await dispatch(saveFindingWeb(inspectionId, data));

    return finding.id;
  } catch (e) {
    throw e;
  }
};

const updateFindingMobile = (inspectionId, data) => async (dispatch) => {
  try {
    const inspectionDetails = await Db.findOne('inspections', inspectionId);
    const findingIndex = inspectionDetails.findings.findIndex(
      (findingItem) => findingItem.id === data.id || findingItem.frontendId === data.id,
    );

    if (findingIndex < 0) {
      throw new Error('Cannot find finding');
    }

    if (inspectionDetails.status === INSPECTION_STATUSES.planned) {
      await dispatch(setInspectionStatus(inspectionId, INSPECTION_STATUSES.ongoing));
      inspectionDetails.status = INSPECTION_STATUSES.ongoing;
    }

    const findingPrevState = _get(inspectionDetails, `findings[${findingIndex}]`, {});

    const finding = {
      ...data,
      updatedAt: new Date().toISOString(),
      id: _get(findingPrevState, `id`, data.id),
    };

    inspectionDetails.findings.splice(findingIndex, 1, finding);

    await Db.retryUntilWritten('inspections', inspectionDetails._id, (obj) => ({
      ...obj,
      ...inspectionDetails,
    }));

    const synchronized = await Db.findOne('sync_findings', finding.id, false);

    if (synchronized) {
      await Db.retryUntilWritten('sync_findings', synchronized._id, (obj) => ({
        ...obj,
        ...finding,
      }));
    } else {
      const newFinding = {
        ...finding,
        inspectionLocalId: inspectionId,
      };

      await Db.retryUntilWritten('sync_findings_edit', finding.id, (obj = { _id: finding.id }) => ({
        ...obj,
        ...newFinding,
        updatedAt: new Date().toISOString(),
      }));
    }

    dispatch(registerSync());

    return finding;
  } catch (e) {
    throw e;
  }
};

const updateFindingWeb = (inspectionId, data) => async (dispatch) => {
  const inspection = await getInspectionWeb(inspectionId);

  if (inspection.status === INSPECTION_STATUSES.planned) {
    await dispatch(setInspectionStatus(inspectionId, INSPECTION_STATUSES.ongoing));
  }

  const parsedData = new NewFindingModel(
    {
      ...data,
      inspectionId: inspection.id,
      updatedAt: new Date().toISOString(),
    },
    inspection,
  ).parseDetailsForSynchronization(true);
  const updatedFinding = await Api.put('/api/findings', parsedData);

  await dispatch(getInspection(inspectionId));
  return updatedFinding;
};

const updateFinding = (inspectionId, data) => async (dispatch, getState) => {
  try {
    const {
      app: { isMobile },
    } = getState();
    const finding = isMobile
      ? await dispatch(updateFindingMobile(inspectionId, data))
      : await dispatch(updateFindingWeb(inspectionId, data));

    return isMobile ? finding.frontendId || finding.localId || finding.id : finding.id;
  } catch (e) {
    throw e;
  }
};

const createFailureModeChildren = (curr) => ({
  code: curr.code,
  id: curr.id,
  description: curr.description,
  active: curr.active,
});

const rebuildFailureModeTree = (data) => {
  const treeObject = data.reduce((acc, curr) => {
    const parentId = curr.parent.id;
    if (acc[parentId]) {
      acc[parentId] = {
        ...acc[parentId],
        children: [...acc[parentId].children, createFailureModeChildren(curr)],
      };
    } else {
      acc[parentId] = {
        ...curr.parent,
        children: [createFailureModeChildren(curr)],
      };
    }
    return acc;
  }, {});
  return Object.values(treeObject);
};

const getFailureModes = async ({ wbsDetails }) => {
  try {
    if (!wbsDetails) {
      return [];
    }
    const selector = {
      wbses: { $elemMatch: { $eq: wbsDetails.id } },
    };

    const { docs } = await Db.find('failure_modes_v2', { selector });

    const failureModeTree = rebuildFailureModeTree(docs);

    return sortTree(failureModeTree, 'code', 'asc');
  } catch (e) {
    throw e;
  }
};

const getTopFailureModes = async ({ wbsDetails }) => {
  try {
    if (!wbsDetails) {
      return [];
    }
    const selector = {
      workPackage: { $eq: wbsDetails.id },
    };

    const { docs } = await Db.find('top_failure_modes', { selector });

    const failureModes = docs[0]?.failureModes || [];

    return sortTree(failureModes, 'code', 'asc');
  } catch (e) {
    throw e;
  }
};

const cleanData = () => ({
  type: CLEAN_DATA,
});

const setIsLoading = (payload) => ({
  type: SET_IS_LOADING,
  payload,
});

export { cleanData, saveFinding, updateFinding, getFailureModes, getTopFailureModes, setIsLoading };

export { CLEAN_DATA, SET_IS_LOADING, SAVE_FINDING };
