import React, { PureComponent, Fragment } from 'react';
import { AutoSizer, List } from 'react-virtualized';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import ListItemText from '@material-ui/core/ListItemText';

import ConfirmModal from '../confirmModal/confirmModal';
import ListItemWrapper from '../listItemWrapper/listItemWrapper.component';
import SearchHeader from '../searchHeader/searchHeader.container';

class FilteringList extends PureComponent {
  fireWithDebounce = debounce((text) => {
    const { onSearch } = this.props;
    onSearch(text);
  }, 400);

  handleOnSearch = (e) => {
    this.fireWithDebounce(escape(e.target.value));
  };

  handleOnSelect = (item) => {
    const { onSelect, onClose } = this.props;

    onSelect(item);
    onClose();
  };

  onConfirmContent = (data) => {
    const { confirmContent } = this.props;

    return confirmContent(data);
  };

  rowRenderer = ({ index, key, style }) => {
    const { data, renderItem, renderItemSecondary, confirmFn, confirmIcon, confirmLabel, confirmTitle } = this.props;
    const item = data[index];

    const row = (
      <ListItemWrapper
        key={key}
        style={style}
        onClick={this.handleOnSelect}
        item={item}
        puppet-data={item.location || item}
        button
      >
        <ListItemText primary={renderItem(item, index)} secondary={renderItemSecondary(item, index)} />
      </ListItemWrapper>
    );

    return confirmFn ? (
      <ConfirmModal
        key={key}
        params={item}
        confirmFn={confirmFn}
        confirmLabel={confirmLabel}
        confirmIcon={confirmIcon}
        confirmTitle={confirmTitle}
        confirmContent={this.onConfirmContent}
      >
        {row}
      </ConfirmModal>
    ) : (
      row
    );
  };

  render() {
    const { onClose, data, rowHeight, searchPlaceholder, hideEmptyResults } = this.props;
    const showList = !hideEmptyResults || data.length > 0;
    return (
      <>
        <SearchHeader placeholder={searchPlaceholder} onChange={this.handleOnSearch} onClose={onClose} />
        <div className={`${showList ? 'filtering-list-component' : ''} content-wrapper-no-padding`}>
          {showList && (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  data={data}
                  height={height}
                  width={width}
                  rowCount={data.length}
                  rowHeight={rowHeight}
                  rowRenderer={this.rowRenderer}
                />
              )}
            </AutoSizer>
          )}
        </div>
      </>
    );
  }
}

FilteringList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  renderItem: PropTypes.func.isRequired,
  renderItemSecondary: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  rowHeight: PropTypes.number,
  searchPlaceholder: PropTypes.string,
  hideEmptyResults: PropTypes.bool,
  confirmFn: PropTypes.func,
  confirmIcon: PropTypes.string,
  confirmLabel: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmContent: PropTypes.func,
};

FilteringList.defaultProps = {
  rowHeight: 50,
  onClose: () => {},
  searchPlaceholder: 'Search',
  renderItemSecondary: () => {},
  hideEmptyResults: false,
  confirmFn: null,
  confirmIcon: '',
  confirmLabel: '',
  confirmTitle: '',
  confirmContent: null,
};

export default FilteringList;
