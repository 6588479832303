class FileListNames {
  names = {};

  setNameAndIncrement(fileName) {
    if (fileName in this.names) {
      this.names[fileName] += 1;
      return true;
    }
    this.names[fileName] = 0;
    return false;
  }

  incrementFileName(fileName) {
    return fileName.replace(/^([^.]+)$|(\.[^.]+)$/i, `$1(${this.names[fileName]})$2`);
  }
}

export default FileListNames;
