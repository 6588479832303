import React, { FC, memo } from 'react';

import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import '@material-ui/core/styles';

const DEFAULT_TEXT = 'No items found';

const useItemNotFoundStyles = makeStyles((theme) => ({
  cell: {
    textAlign: 'center',
  },
}));

interface INotItemsFoundProps {
  text?: string;
  columnsLength: number;
}

const NoItemsFound: FC<INotItemsFoundProps> = memo(({ text = DEFAULT_TEXT, columnsLength }) => {
  const classes = useItemNotFoundStyles();

  return (
    <TableRow>
      <TableCell colSpan={columnsLength} className={classes.cell}>
        {text}
      </TableCell>
    </TableRow>
  );
});

export default NoItemsFound;
