import Notifications from 'services/notifications';

const genericErrorHandler = (error) => {
  Notifications.showError(error || 'Something went wrong.');
  if (error) {
    console.trace(error);
  }
  console.log(error);
};

export default genericErrorHandler;
