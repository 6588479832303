import _get from 'lodash/get';
import { createSelector } from 'reselect';

const getLoggedUser = (state) => state.auth.user;

const getInspectionFindings = (state) => state.inspection.inspectionFindings;

const getInspectionDetails = (state) => state.inspection.data;

const makeIsLoggedUserLeadInspector = () =>
  createSelector(
    getLoggedUser,
    getInspectionDetails,
    (user, inspection) => _get(user, 'id') === _get(inspection, 'leadInspector.id'),
  );

export { makeIsLoggedUserLeadInspector, getInspectionFindings };
