import React, { PureComponent } from 'react';

import classNames from 'classnames';

import { CircularProgress } from '@material-ui/core';

class Loader extends PureComponent {
  render() {
    const { inline, mask, fixed, input, alignEnd, textField, ...rest } = this.props;

    const classes = classNames('loader-component d-flex flex-column justify-content-center align-items-center', {
      'flex-shrink-0 py-3': inline,
      'flex-fill h-100': !inline,
      'position-fixed': fixed,
      'input-size': input,
      'input-size right-offset': textField,
      mask,
    });

    const loaderClasses = classNames({
      'align-self-end': alignEnd,
      'svg-extra-large': !input && !textField,
    });

    return (
      <div className={classes}>
        <CircularProgress className={loaderClasses} color="secondary" {...rest} />
      </div>
    );
  }
}

Loader.defaultProps = {
  alignEnd: false,
  inline: false,
  fixed: false,
  input: false,
  textField: false,
};

export function InputLoader() {
  return (
    <span className="loader-input">
      <Loader input />
    </span>
  );
}

export default Loader;
