import { CLEAR_DATA, SET_USER_IS_ADDING, SET_IS_LOADING, SET_USERS } from './users.actions';

const initialState = {
  isLoading: false,
  isUserAdding: false,
  items: [],
  totalCount: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_USERS:
      return {
        ...state,
        items: payload.content,
        totalCount: payload.totalElements,
        isLoading: false,
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    case SET_USER_IS_ADDING:
      return {
        ...state,
        isUserAdding: payload,
      };
    default:
      return state;
  }
};
