import React, { FC } from 'react';

import _get from 'lodash/get';

import { Dialog, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Header from '../attachmentPreviewHeader/attachmentPreviewHeaderWeb.component';
import withAttachmentPreview from './attachmentPreview.hoc';
import ImageSlider from './imageSlider';
import usePrevNextPreview from './usePrevNextPreview';

const styles = {
  paper: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  description: {
    color: '#FFF',
  },
};

interface IAttachmentPreviewWebProps {
  canEdit: boolean;
  classes: any;
  data: any;
  handleAttachmentDelete: () => void;
  handleAttachmentEdit: () => void;
  handleMark: () => void;
  isLoading: boolean;
  isWorkflow: boolean;
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
  open: boolean;
  photo: object;
  showNextPrevButtons: boolean;
}

const AttachmentPreviewWeb: FC<IAttachmentPreviewWebProps> = ({
  canEdit,
  classes,
  data,
  handleAttachmentDelete,
  handleAttachmentEdit,
  handleMark,
  isLoading,
  isWorkflow,
  onClose,
  onNext,
  onPrevious,
  open,
  photo,
  showNextPrevButtons,
}) => {
  const [fadeOutClass, handleNext, handlePrev, onKeyDown, _, viewRotation, rotateLeft, rotateRight] =
    usePrevNextPreview({
      open,
      isLoading,
      showNextPrevButtons,
      onPrevious,
      onNext,
      onClose,
    });

  const id = _get(photo, 'id');
  const src = `/api/attachments/${id}/download` || '';
  const title = _get(photo, 'title') || '';
  const description = _get(data, 'description');
  const mimeType = _get(photo, 'mimeType') || '';

  return (
    <Dialog
      open={open}
      fullScreen
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}
      onKeyDown={onKeyDown as any}
      role="button"
      tabIndex={0}
    >
      <Header
        isWorkflow={isWorkflow}
        onClose={onClose}
        onDelete={handleAttachmentDelete}
        onEdit={handleAttachmentEdit}
        onMark={handleMark}
        canEdit={canEdit}
        title={title}
        path={src}
        mimeType={mimeType}
        rotateLeft={rotateLeft}
        rotateRight={rotateRight}
      />

      <div className="attachment-preview-component container-wrapper" id="attachment-preview:button">
        {!!data.file && (
          <ImageSlider
            description={description}
            fadeOutClass={fadeOutClass as any}
            handleNext={handleNext as any}
            handlePrev={handlePrev as any}
            isLoading={isLoading}
            showNextPrevButtons={showNextPrevButtons}
            src={data.file}
            viewRotation={viewRotation as any}
          />
        )}
        {!!description && (
          <div className="d-flex align-items-center justify-content-center">
            <Typography
              color="primary"
              variant="body1"
              className="p-4 break-word"
              classes={{
                colorPrimary: classes.description,
              }}
            >
              {description}
            </Typography>
          </div>
        )}
      </div>
    </Dialog>
  );
};

AttachmentPreviewWeb.defaultProps = {
  showNextPrevButtons: false,
};

export default withAttachmentPreview(withStyles(styles)(AttachmentPreviewWeb));
