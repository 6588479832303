import React, { FC, ReactNode } from 'react';

import { Box, TableCell, TableSortLabel, Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { useTableStyles } from './styles';

interface ITableHeaderCellProps {
  children: ReactNode;
  currentDirection: 'asc' | 'desc';
  sortingId: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  currentSort: string;
}

const TableHeaderCell: FC<ITableHeaderCellProps> = ({
  currentSort,
  currentDirection,
  children,
  align = 'center',
  sortingId,
}) => {
  const tableHeaderStyles: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  };

  const styles = useTableStyles();

  if (currentSort && currentSort === sortingId) {
    return (
      <TableCell className={styles.headerCell} align={align}>
        <Box style={tableHeaderStyles}>
          <TableSortLabel id={sortingId} active direction={currentDirection} className={`${styles.sortIcon} active`}>
            <Typography color="textSecondary" variant="subtitle2" style={{ width: '100%' }}>
              {children}
            </Typography>
          </TableSortLabel>
        </Box>
      </TableCell>
    );
  }

  return (
    <TableCell align={align} className={styles.headerCell}>
      <Box style={tableHeaderStyles}>
        <Typography color="textSecondary" variant="subtitle2" style={{ width: '100%' }}>
          {children}
        </Typography>
      </Box>
    </TableCell>
  );
};

export default TableHeaderCell;
