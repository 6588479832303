import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import { INSPECTION_STATUSES, WORKFLOWS_TYPES } from 'modules/app/config/config';
import { validateTableItem } from 'modules/common/components/table/table.actions';
import { getWorkflow } from 'modules/workflows/components/workflowDetails/workflowDetails.actions';
import Api from 'services/api';
import Notifications from 'services/notifications';

import { SELECTING_CONFIG } from './findings.config';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'FINDINGS';
const SET_FINDINGS = `${namespace}_SET_FINDINGS`;
const SET_SIMILAR_FINDINGS = `${namespace}_SET_SIMILAR_FINDINGS`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const SELECT_FINDING = `${namespace}_SELECT_FINDING`;
const SET_SELECTED_FINDING_WBS = `${namespace}_SET_SELECTED_FINDING`;
const CLEAR_SELECTED_FINDINGS = `${namespace}_CLEAR_SELECTED_FINDINGS`;
const CLEAR_DATA = `${namespace}_CLEAR_DATA`;
const SET_SIMILAR_FINDING_ID = `${namespace}_SET_SIMILAR_FINDING_ID`;

/*
 * REDUX ACTIONS
 */
const getFindings = (params, filterState) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const body = {};

    const mapNamesToParams = {
      inspector: 'inspectorIds',
      status: 'inspectionStatuses',
      siteFacility: 'siteFacilityIds',
      failureMode: 'failureModeIds',
      mainSystem: 'mainSystemIds',
      subSystem: 'subSystemIds',
      assigned: 'processed',
    };

    if (filterState) {
      Object.entries(filterState.filters).forEach(([key, val]) => {
        if (val && val.length > 0) {
          if (key === 'wbs') {
            body.wbsIds = val;
            return;
          }
          body[mapNamesToParams[key]] = val;
        }

        if (filterState.search) {
          body.description = filterState.search;
        }
      });
    }

    if (body.processed) {
      if (body.processed.length > 1) {
        delete body.processed;
      } else {
        [body.processed] = body.processed;
      }
    }

    const content = await Api.post('/api/findings/filter', body, params);

    dispatch({
      type: SET_FINDINGS,
      payload: content,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const getFindingsByIds = (ids) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const body = {
      findingIds: ids,
    };

    const content = await Api.post('/api/findings/list', body);

    dispatch({
      type: SET_FINDINGS,
      payload: { content, totalElements: content.length },
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const getSimilarFindings = (id, params) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const content = await Api.get(`/api/findings/${id}/similar`, params);

    dispatch({
      type: SET_SIMILAR_FINDINGS,
      payload: content,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const addFindingsToWorkflow = (id, findings) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    await Api.put(`/api/workflows/${id}/findings`, findings);

    await dispatch(getWorkflow(id, true));
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const selectFinding = (id, wbs, dispatch, getState) => {
  const {
    findings: {
      selected: { ids, wbs: selectedWbs },
    },
  } = getState();

  if (selectedWbs && selectedWbs !== wbs) {
    Notifications.showError(
      'Findings must originate from the same workpackage in order to start a workflow from them. Hint: Filter findings by package.',
    );
    return;
  }

  const payload = ids.includes(id) ? ids.filter((selectedId) => selectedId !== id) : [...ids, id];

  dispatch({
    type: SELECT_FINDING,
    payload,
  });

  const wbsToSet = payload.length ? wbs : '';

  if (!selectedWbs || !payload.length) {
    dispatch({
      type: SET_SELECTED_FINDING_WBS,
      payload: wbsToSet,
    });
  }
};

const clearSelectedFindings = () => ({ type: CLEAR_SELECTED_FINDINGS });

const clearData = () => ({ type: CLEAR_DATA });

const validateActiveWorkflows = (workflowType, findings) => {
  let result = true;

  const selectedFindingsWorkflows = findings
    .filter((finding) => finding.workflows.length)
    .map((finding) => finding.workflows)
    .flat();

  selectedFindingsWorkflows.forEach((workflow) => {
    if (workflow.type.toLowerCase() in WORKFLOWS_TYPES && workflow.type === workflowType) {
      Notifications.showError(`Finding was already used to create ${workflowType.toLowerCase()}.`);
      result = false;
    }
  });

  return result;
};

const selectListFindings = (id) => (dispatch, getState) => {
  const {
    findings: { items },
  } = getState();

  const {
    inspection: { wbs, status, parkName },
  } = items.filter((finding) => finding.id === id)[0];

  if (status !== INSPECTION_STATUSES.completed) {
    Notifications.showError('Inspection must be closed before starting a workflow from the findings');
    return;
  }

  selectFinding(id, `${parkName}-${wbs}`, dispatch, getState);
};

const selectAll = (items, ids, dispatch) => {
  const findingsToAdd = items.map((finding) => finding.id);
  const payload = _uniq([...ids, ...findingsToAdd]);

  dispatch({
    type: SELECT_FINDING,
    payload,
  });
};

const unselectAll = (items, ids, dispatch) => {
  const findingsToRemove = items.map((finding) => finding.id);
  const payload = ids.filter((id) => !findingsToRemove.includes(id));

  if (!payload.length) {
    dispatch({
      type: SET_SELECTED_FINDING_WBS,
      payload: '',
    });
  }

  dispatch({
    type: SELECT_FINDING,
    payload,
  });
};

const selectAllFromList = (selectAllStatus) => (dispatch, getState) => {
  const {
    findings: {
      items,
      selected: { ids, wbs },
    },
  } = getState();

  const filteredByStatuses = items.filter((finding) => !validateTableItem(SELECTING_CONFIG, finding));

  if (!filteredByStatuses.length) {
    return false;
  }

  if (selectAllStatus) {
    const findingsWBSes = _uniq(filteredByStatuses.map((finding) => finding.inspection.wbs));

    const newFindingsWbs = _get(filteredByStatuses[0], 'inspection.wbs', '');

    if (findingsWBSes.length > 1 || (wbs && newFindingsWbs !== wbs)) {
      Notifications.showError(
        `All selected findings must be for the same package (${newFindingsWbs}), to start a workflow for them.`,
      );
      return false;
    }

    dispatch({
      type: SET_SELECTED_FINDING_WBS,
      payload: newFindingsWbs || wbs,
    });
  }

  if (selectAllStatus) {
    selectAll(filteredByStatuses, ids, dispatch);
  } else {
    unselectAll(filteredByStatuses, ids, dispatch);
  }

  return true;
};

const setSelectedFindings = (payload) => (dispatch) => {
  dispatch({
    type: SELECT_FINDING,
    payload,
  });
};

export {
  getFindings,
  getSimilarFindings,
  clearData,
  selectFinding,
  clearSelectedFindings,
  validateActiveWorkflows,
  getFindingsByIds,
  selectListFindings,
  setSelectedFindings,
  selectAllFromList,
  selectAll,
  unselectAll,
  addFindingsToWorkflow,
};

export {
  SET_FINDINGS,
  SET_IS_LOADING,
  SELECT_FINDING,
  CLEAR_SELECTED_FINDINGS,
  SET_SELECTED_FINDING_WBS,
  SET_SIMILAR_FINDINGS,
  CLEAR_DATA,
  SET_SIMILAR_FINDING_ID,
};
