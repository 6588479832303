import React from 'react';

import { GlobalActionBack } from 'assets/icons/index';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import { AppBar, Dialog, Icon, IconButton, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { RotateLeft, RotateRight } from '@material-ui/icons';

import ImageSlider from '../attachmentPreview/imageSlider';
import usePrevNextPreview from '../attachmentPreview/usePrevNextPreview';

const styles = {
  paper: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  description: {
    color: '#FFF',
  },
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
  },
  white: {
    color: '#FFF',
  },
  moveToRight: {
    marginLeft: 'auto !important',
    display: 'flex',
    alignItems: 'center',
  },
};

const AttachmentPreviewWeb = ({
  open,
  data,
  onClose,
  classes,
  handleDelete,
  showNextPrevButtons,
  onPrevious,
  onNext,
}) => {
  const src = _get(data, 'data') || '';
  const title = _get(data, 'name', '');

  const [_, handleNext, handlePrev, onKeyDown, refresh, viewRotation, rotateLeft, rotateRight] = usePrevNextPreview({
    open,
    isLoading: false,
    showNextPrevButtons,
    onPrevious,
    onNext,
    onClose,
  });

  return (
    <Dialog
      open={open}
      fullScreen
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
    >
      <AppBar position="fixed" className="header-component" classes={{ root: classes.root }}>
        <Toolbar>
          <IconButton className="left-button" aria-label="Go back" onClick={onClose}>
            <Icon className="svg-medium">
              <GlobalActionBack className="icon-light" />
            </Icon>
          </IconButton>
          <Typography
            color="primary"
            variant="body1"
            className="p-4 break-word"
            classes={{
              colorPrimary: classes.white,
            }}
          >
            {title}
          </Typography>

          <div className={classes.moveToRight}>
            <Typography
              color="primary"
              variant="body2"
              classes={{
                colorPrimary: classes.white,
              }}
            >
              Rotation preview only
            </Typography>
            <IconButton aria-label="Rotate left" onClick={rotateLeft}>
              <Icon className="svg-medium">
                <RotateLeft style={{ fill: '#fff' }} />
              </Icon>
            </IconButton>

            <IconButton aria-label="Rotate right" onClick={rotateRight}>
              <Icon className="svg-medium">
                <RotateRight style={{ fill: '#fff' }} />
              </Icon>
            </IconButton>
            <IconButton aria-label="Delete" onClick={handleDelete}>
              <Icon color="primary" classes={{ colorPrimary: classes.white }}>
                delete
              </Icon>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <div className="attachment-preview-component container-wrapper" id="attachment-preview:button">
        {!!src && (
          <ImageSlider
            description={title}
            handleNext={handleNext}
            handlePrev={handlePrev}
            showNextPrevButtons={showNextPrevButtons}
            src={src}
            refresh={refresh}
            viewRotation={viewRotation}
          />
        )}
      </div>
    </Dialog>
  );
};

AttachmentPreviewWeb.propTypes = {
  classes: PropTypes.objectOf(PropTypes.shape).isRequired,
  data: PropTypes.objectOf(PropTypes.shape),
  handleDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  showNextPrevButtons: PropTypes.bool,
};

AttachmentPreviewWeb.defaultProps = {
  data: {},
  showNextPrevButtons: false,
};

export default withStyles(styles)(AttachmentPreviewWeb);
