import React from 'react';

const NonconformityStatusCarIssued = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M12 1C6.6 1 2.1 4.9 1.2 10h2c.9-4 4.5-7 8.8-7 5 0 9 4 9 9 0 4.3-3 7.9-7 8.8v2c5.1-.9 9-5.4 9-10.8 0-6.1-4.9-11-11-11z" />
    <path d="M12 5c-3.2 0-5.8 2.1-6.7 5h2.1C8.2 8.2 10 7 12 7c2.8 0 5 2.2 5 5 0 2-1.2 3.8-3 4.6v2.1c2.9-.9 5-3.5 5-6.7 0-3.9-3.1-7-7-7zM1.343 21.222l7.071-7.07 1.414 1.414-7.07 7.07z" />
    <path d="M6.3 12.1l3.5 3.5 2.1 2.1.8-6.4z" />
  </svg>
);

export default NonconformityStatusCarIssued;
