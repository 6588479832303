import { getDataForOffline } from 'modules/app/components/app/app.actions';
import { getUserRoles } from 'modules/auth/components/login/login.actions';
import Acl from 'services/acl';
import Api from 'services/api';

/*
 * REDUX ACTION TYPES
 */
const namespace = 'WBS_DETAILS';
const SET_DETAILS = `${namespace}_SET_DETAILS`;
const SET_IS_LOADING = `${namespace}_SET_IS_LOADING`;
const CLEAR_DATA = `${namespace}_CLEAR_DATA`;

/*
 * REDUX ACTIONS
 */

const IS_WEB = true;

const getDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const payload = await Api.get(`/api/user-management/wbs-role-users/${id}/details`);

    dispatch({
      type: SET_DETAILS,
      payload,
    });
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const addUser = (user, extraData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const {
      wbsDetails: {
        data: { id },
      },
    } = getState();
    const data = {
      userId: user.id,
      wbsId: id,
    };

    const updatedData = {
      ...data,
      ...extraData,
    };

    await Api.post('/api/user-management', updatedData);
    await dispatch(getDetails(id));
    await getDataForOffline(dispatch, IS_WEB);
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const deactivateUser = (user, extraData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const {
      wbsDetails: {
        data: { id },
      },
      auth: { user: loggedUser },
    } = getState();

    const data = {
      userId: user.id,
      wbsId: id,
    };

    const updatedData = {
      ...data,
      ...extraData,
    };

    await Api.put('/api/user-management/deactivate', updatedData);
    await dispatch(getDetails(id));

    if (loggedUser.id === user.id) {
      const { roles, wbses } = await getUserRoles(user.id);
      Acl.clearData();
      Acl.setData(roles, wbses);
    }

    await getDataForOffline(dispatch, IS_WEB);
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const setMainUser = (user, extraData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const {
      wbsDetails: {
        data: { id },
      },
    } = getState();
    const data = {
      main: true,
      userId: user.id,
      wbsId: id,
    };

    const updatedData = {
      ...data,
      ...extraData,
    };

    await Api.put('/api/user-management/main', updatedData);
    await dispatch(getDetails(id));
    await getDataForOffline(dispatch, IS_WEB);
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const clearData = () => ({
  type: CLEAR_DATA,
});

const addEmail = (email, siteFacilityId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const {
      wbsDetails: {
        data: { id: wbsId },
      },
    } = getState();

    await Api.post('/api/user-management/contractor-email-groups', {
      siteFacilityId,
      email,
      wbsId,
    });
    await dispatch(getDetails(wbsId));
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

const removeEmail = (email, siteFacilityId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const {
      wbsDetails: {
        data: { id: wbsId },
      },
    } = getState();

    await Api.put('/api/user-management/contractor-email-groups/remove-address', { siteFacilityId, email, wbsId });
    await dispatch(getDetails(wbsId));
  } catch (e) {
    dispatch({
      type: SET_IS_LOADING,
      payload: false,
    });
    throw e;
  }
};

export { getDetails, clearData, addUser, deactivateUser, setMainUser, addEmail, removeEmail };

export { SET_DETAILS, SET_IS_LOADING, CLEAR_DATA };
