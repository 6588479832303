import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';

import { FindingSeverityNonConformity, FindingSeverityObservation } from 'assets/icons/index';
import classNames from 'classnames';
import { WORKFLOWS_TYPES } from 'modules/app/config/config';
import { encodeParam } from 'modules/app/helpers/utils';
import PropTypes from 'prop-types';

import { Button, Typography, withStyles } from '@material-ui/core';
import { NotificationImportant } from '@material-ui/icons';

const styles = (theme) => ({
  createWorkflowButton: {
    color: theme.palette.secondary.light,
    textTransform: 'none',
    textAlign: 'left',
  },
});

const CreateWorkflowFromSelected = memo(
  ({ history, selectedIds, findings, withSelectedCounter, clearAll, classes }) => {
    const goToWorkflowForm = (workflowType) => {
      const selectedFindings = findings.filter((finding) => selectedIds.includes(finding.id));

      if (selectedFindings.length) {
        const inspectionId = selectedFindings[0].inspection.id;
        history.push(`/workflow/${workflowType.toLowerCase()}/${inspectionId}/${encodeParam(selectedIds)}`);
      }
    };

    const selected = withSelectedCounter && !!selectedIds.length;
    const rowClasses = classNames('col-md-12 pt-3 row align-items-center', {
      'justify-content-between': selected,
      'justify-content-end': !selected,
    });

    const disabledCreateDefectNotification =
      !selectedIds.length || findings.every((finding) => finding.mainSystem === null);

    return (
      <div className="w-100">
        <div className={rowClasses}>
          {selected && (
            <div className="d-inline-flex align-items-center">
              <Typography color="textSecondary" variant="subtitle2">
                <strong>{selectedIds.length}</strong> selected
              </Typography>
              <Button color="secondary" size="small" onClick={clearAll}>
                Clear All
              </Button>
            </div>
          )}
          <div className="d-inline-flex align-items-center">
            <Button
              startIcon={<NotificationImportant className="icon-severity-observation" />}
              disabled={disabledCreateDefectNotification}
              className={classes.createWorkflowButton}
              onClick={() => goToWorkflowForm(WORKFLOWS_TYPES.defect_notification)}
            >
              Create Defect Notification from selected
            </Button>
            <Button
              startIcon={<FindingSeverityObservation className="icon-severity-observation" />}
              disabled={!selectedIds.length}
              className={`ml-2 ${classes.createWorkflowButton}`}
              onClick={() => goToWorkflowForm(WORKFLOWS_TYPES.observation)}
            >
              Create Observation from selected
            </Button>
            <Button
              startIcon={<FindingSeverityNonConformity />}
              disabled={!selectedIds.length}
              className={`ml-2 ${classes.createWorkflowButton}`}
              onClick={() => goToWorkflowForm(WORKFLOWS_TYPES.nonconformity)}
            >
              Create Nonconformity from selected
            </Button>
          </div>
        </div>

        <div className={rowClasses}>
          <Typography color="textSecondary" variant="subtitle2" className="text-right">
            Workflows can be created only from findings
          </Typography>
        </div>
      </div>
    );
  },
);

CreateWorkflowFromSelected.propTypes = {
  history: PropTypes.shape({}).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  findings: PropTypes.arrayOf(PropTypes.shape),
  withSelectedCounter: PropTypes.bool,
  clearAll: PropTypes.func,
};

CreateWorkflowFromSelected.defaultProps = {
  findings: [],
  selectedIds: [],
  withSelectedCounter: false,
  clearAll: () => {},
};

export default withRouter(withStyles(styles)(CreateWorkflowFromSelected));
