import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { DrawerContextProvider } from 'context';
import Login from 'modules/auth/components/login/login.container';
import BannerComponent from 'modules/common/components/banner/banner.component';
import BannerContainer from 'modules/common/components/banner/banner.container';
import Loader from 'modules/common/components/loader/loader.component';
import ToastComponent from 'modules/common/components/toast/toast.component';
import Toast from 'modules/common/components/toast/toast.container';
import PropTypes from 'prop-types';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import ErrorBoundary from '../errorBoundary/errorBoundary.component';
import Home from '../home/home.container';

const getUserConfirmation = (dialogKey, callback) => {
  const dialogTrigger = window[dialogKey];
  if (dialogTrigger) {
    return dialogTrigger(callback);
  }

  return callback(true);
};

const App = ({ checkIsLogged }) => {
  const { instance } = useMsal();
  const isAuthorized = useIsAuthenticated();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isAuthorized) {
      setIsLoading(false);
      checkIsLogged(location.pathname, instance);
    }
  }, [isAuthorized]);

  const setToastRef = (ref) => {
    if (!ref) {
      return;
    }

    ToastComponent.toastInstance = ref;
  };

  const setBannerRef = (ref) => {
    if (!ref) {
      return;
    }

    BannerComponent.bannerInstance = ref;
  };

  if (isLoading) {
    return <Loader />;
  }

  const loginComponentRender = (props) => <Login {...props} />;
  const homeComponentRender = (props) => <Home {...props} />;

  return (
    <ErrorBoundary>
      <Router getUserConfirmation={getUserConfirmation}>
        <Switch>
          <DrawerContextProvider>
            <Route path="/login" component={loginComponentRender} />
            <Route path="/" component={homeComponentRender} />
          </DrawerContextProvider>
        </Switch>
      </Router>
      <Toast ref={setToastRef} />
      <BannerContainer ref={setBannerRef} />
    </ErrorBoundary>
  );
};

App.propTypes = {
  checkIsLogged: PropTypes.func.isRequired,
};

export default App;
