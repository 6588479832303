import { isMobile } from 'modules/app/helpers/hooks';
import moment from 'moment';
import 'whatwg-fetch';

import { API_URL } from '../modules/app/config/config';
import { acquireAccessToken, getToken, loginAzure } from './azure';

class Api {
  constructor() {
    this.headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'x-device-type': isMobile() ? 'MOBILE' : 'WEB',
    };
    this.baseUrl = API_URL;
    this.token = null;
    this.tokenValidTo = null;
  }

  async init() {
    if (navigator.onLine) {
      this.token = await acquireAccessToken(this.msalInstance);
    }
  }

  setBaseUrl(url) {
    this.baseUrl = url;
  }

  setAzureInstance(instance) {
    this.msalInstance = instance;

    this.init();
  }

  setStore(storeMiddleware) {
    this.dispatch = storeMiddleware.dispatch;
    this.getState = storeMiddleware.getState;
  }

  setToken(token) {
    this.token = token;
  }

  getRouteWithParams(route, params) {
    const tmp = Object.keys(params).map(
      (key) => `${key}=${Array.isArray(params[key]) ? params[key].join(`&${key}=`) : params[key]}`,
    );

    return `${route}?${tmp.join('&').replace('filters=', '')} `;
  }

  get(route, params, type, signal) {
    let newRoute = route;
    if (params) {
      newRoute = this.getRouteWithParams(route, params);
    }
    return this.xhr(newRoute, null, 'GET', this.headers, type, signal);
  }

  put(route, params) {
    return this.xhr(route, params, 'PUT');
  }

  patch(route, params) {
    return this.xhr(route, params, 'PATCH');
  }

  post(route, params, routeParams, headers, type) {
    let newRoute = route;
    if (routeParams) {
      newRoute = this.getRouteWithParams(route, routeParams);
    }
    return this.xhr(newRoute, params, 'POST', headers, type);
  }

  delete(route, params) {
    return this.xhr(route, params, 'DELETE');
  }

  async handle401() {
    if (navigator.online) {
      await loginAzure(this.msalInstance);
      return;
    }
    const error = new Error('Forbidden, you have to get online to refresh your session.');
    error.code = 401;
    throw error;
  }

  async xhr(route, params, method, headers = this.headers, type = 'json', signal) {
    const url = `${this.baseUrl}${route} `;
    const options = {
      method,
      headers,
    };

    if (params) {
      if (params instanceof FormData) {
        options.body = params;
        options.headers = undefined;
      } else {
        options.body = JSON.stringify(params);
      }
    }

    try {
      if ((!this.token || moment().isAfter(moment(this.token?.expiresOn))) && navigator.onLine) {
        this.token = await acquireAccessToken(this.msalInstance);
      }

      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${this.token.data}`,
      };
    } catch (e) {
      console.log('token error', e);
      if (navigator.onLine) {
        return this.handle401();
      }
    }

    try {
      const response = await fetch(url, { ...options, signal });
      switch (response.status) {
        case 200:
        case 201:
          return response[type]();
        case 400:
        case 403:
        case 422:
        case 404:
        case 500: {
          return response[type]().then((data) => Promise.reject(data));
        }
        case 401: {
          if (navigator.onLine) {
            return this.handle401();
          }
          break;
        }
        default: {
          if (navigator.onLine) {
            throw new Error('Something went wrong');
          }
        }
      }
    } catch (e) {
      if (navigator.onLine) {
        throw e;
      }
    }
  }
}

export default new Api();
