import {
  INSPECTION_FILTER_STATUSES,
  WORKFLOWS_TYPES_PLURAL,
  WORKFLOWS_TYPES,
  CONSTRUCTION_TYPE,
  OM_TYPE,
  FINDINGS_TYPES,
} from 'modules/app/config/config';
import { makeWbsName } from 'modules/app/helpers/utils';

const WORKFLOW_DRAFT = {
  [WORKFLOWS_TYPES.observation]: 'Draft_Observation',
  [WORKFLOWS_TYPES.nonconformity]: 'Draft_NonConformity',
  [WORKFLOWS_TYPES.defect_notification]: 'Draft_Defect_Notification',
};

const INSPECTION_DRAFT = {
  [OM_TYPE]: 'Draft_OM_Inspection',
  [CONSTRUCTION_TYPE]: 'Draft_Construction_Inspection',
};

const crumbGenerator = (title) => ({
  title: title ? `${title.charAt(0).toUpperCase()}${title.slice(1)}` : '',
});

const withLinkCrumbGenerator = (title, linkTo) => ({
  ...crumbGenerator(title),
  linkTo,
});

const getCustomInspectionsStatus = (status) =>
  Object.keys(INSPECTION_FILTER_STATUSES).find((filterStatus) =>
    INSPECTION_FILTER_STATUSES[filterStatus].includes(status),
  );

// Dashboard
const dashboardCrumb = () => [crumbGenerator('dashboard')];

// Users
const usersCrumb = () => [crumbGenerator('users')];

// Users > <fullName||email>
const userDetailsCrumb = ({
  user: {
    data: { fullName, email },
  },
}) => [withLinkCrumbGenerator('users', '/admin/users'), crumbGenerator(fullName || email)];

// Work Packages > User Managmenet
const wbsesCrumb = () => [crumbGenerator('Work Packages'), crumbGenerator('User Management')];

// Work Packages > User Management > <ParkName>
const wbsesDetailsCrumb = ({ wbsDetails: { data } }) => [
  crumbGenerator('Work Packages'),
  withLinkCrumbGenerator('User Management', '/admin/wbses'),
  crumbGenerator(makeWbsName(data)),
];

//
const statusReportsCrumb = () => [crumbGenerator('Work Packages'), crumbGenerator('Status Reports')];

// Checklists
const checklistsCrumb = () => [crumbGenerator('Checklists')];

// Checklists > <checklistIdentifier>
const editChecklistCrumb = ({
  checklistEditor: {
    currentChecklistDetails: { description },
  },
}) => [withLinkCrumbGenerator('Checklists', '/admin/checklists'), crumbGenerator(description)];

// Checklists > New
const newChecklistCrumb = () => [withLinkCrumbGenerator('Checklists', '/admin/checklists'), crumbGenerator('New')];

// Inspections > <type>
const inspectionsCrumb = ({ type }) => [crumbGenerator('Inspections'), crumbGenerator(type)];

// Inspections > New
const newInspectionCrumb = () => [withLinkCrumbGenerator('Inspections', '/inspections/open'), crumbGenerator('New')];

// Inspections > <inspectionIdentifier>
const editInspectionCrumb = ({
  inspection: {
    data: { identifier, type },
  },
}) => {
  let crumbs = [withLinkCrumbGenerator('Inspections', '/inspections/open')];

  if (type && !identifier) {
    crumbs = [...crumbs, crumbGenerator(INSPECTION_DRAFT[type])];
  }

  if (identifier) {
    crumbs = [...crumbs, crumbGenerator(identifier)];
  }

  return crumbs;
};

// Inspections > <inspectionIdentifier>
const inspectionDetailsCrumb = ({
  inspection: {
    data: { status, identifier, type },
  },
}) => {
  if (!status) {
    return [];
  }

  let crumbs = [withLinkCrumbGenerator('Inspections', `/inspections/${getCustomInspectionsStatus(status)}/asc`)];

  if (type && !identifier) {
    crumbs = [...crumbs, crumbGenerator(INSPECTION_DRAFT[type])];
  }

  if (identifier) {
    crumbs = [...crumbs, crumbGenerator(identifier)];
  }

  return crumbs;
};

// Inspections > <inspectionIdentifier> > New
const newFindingCrumb = (
  {
    inspection: {
      data: { status, identifier },
    },
  },
  { inspectionId },
) => {
  if (!status && !identifier && !inspectionId) {
    return [];
  }

  return [
    withLinkCrumbGenerator('Inspections', `/inspections/${getCustomInspectionsStatus(status)}/asc`),
    withLinkCrumbGenerator(identifier, `/inspectionDetails/${inspectionId}`),
    crumbGenerator('New'),
  ];
};

// Inspections > <inspectionIdentifier> > <findingIdentifier>
const findingDetailsCrumb = (state, { inspectionId }) => {
  const {
    finding: {
      data: { identifier: findingIdentifier },
    },
    inspection: {
      data: { status, identifier: inspectionIdentfier },
    },
  } = state;

  if (!inspectionIdentfier && !status && !inspectionId && !findingIdentifier) {
    return [];
  }

  return [
    withLinkCrumbGenerator('Inspections', `/inspections/${getCustomInspectionsStatus(status)}/asc`),
    withLinkCrumbGenerator(inspectionIdentfier, `/inspectionDetails/${inspectionId}`),
    crumbGenerator(findingIdentifier),
  ];
};

// Workflows > <type>
const workflowsCrumb = ({ type }) => [crumbGenerator('Workflows'), crumbGenerator(WORKFLOWS_TYPES_PLURAL[type])];

// Workflows > <type> > new
const newWorkflowCrumb = ({ type }) => [
  crumbGenerator('Workflows'),
  crumbGenerator(WORKFLOWS_TYPES_PLURAL[type]),
  crumbGenerator('New'),
];

// Findings > <type> > new
const findingsCrumb = ({ type }) => [crumbGenerator('Findings'), crumbGenerator(FINDINGS_TYPES[type])];

// Workflows > <type> > <workflowIdentifier>
const workflowDetailsCrumb = ({
  workflowDetails: {
    data: { type, identifier },
  },
}) => {
  let crumbs = [crumbGenerator('Workflows')];

  if (type) {
    const typeLower = type.toLowerCase();
    crumbs = [...crumbs, withLinkCrumbGenerator(WORKFLOWS_TYPES_PLURAL[typeLower], `/workflows/${typeLower}`)];
  }

  if (type && !identifier) {
    crumbs = [...crumbs, crumbGenerator(WORKFLOW_DRAFT[type])];
  }

  if (identifier) {
    crumbs = [...crumbs, crumbGenerator(identifier)];
  }

  return crumbs;
};

// Workflows > <type> > <workflowObservationIdentifier>
const nonconformityFromObservationCrumb = ({
  workflowDetails: {
    data: { identifier, type },
  },
}) => {
  let crumbs = [
    crumbGenerator('Workflows'),
    withLinkCrumbGenerator(WORKFLOWS_TYPES_PLURAL.nonconformity, '/workflows/nonconformity'),
  ];

  if (type && !identifier) {
    crumbs = [...crumbs, crumbGenerator(WORKFLOW_DRAFT[type])];
  }

  if (identifier) {
    crumbs = [...crumbs, crumbGenerator(identifier)];
  }
  return crumbs;
};

// external workflows
const externalWorkflowsNCR = () => [crumbGenerator('Workflows'), crumbGenerator('NCR')];

const externalWorkflowsNew = (lastCrumb = 'Add') => [
  crumbGenerator('Workflows'),
  withLinkCrumbGenerator('NCR', '/external-workflows/NCR/workflows'),
  crumbGenerator(lastCrumb),
];

const externalWorkflowsDetails = ({ id }) => [
  crumbGenerator('Workflows'),
  withLinkCrumbGenerator('NCR', '/external-workflows/NCR/workflows'),
  crumbGenerator(id),
];

const externalWorkflowsOverview = () => [
  crumbGenerator('Workflows'),
  withLinkCrumbGenerator('NCR', '/external-workflows/NCR/overview'),
  crumbGenerator('Overview'),
];

const externalWorkflowsAdminMasterData = () => [
  crumbGenerator('Workflows'),
  withLinkCrumbGenerator('NCR', '/external-workflows/NCR/overview'),
  crumbGenerator('Master Data'),
];

const externalWorkflowsPermissions = () => [
  crumbGenerator('Workflows'),
  withLinkCrumbGenerator('NCR', '/external-workflows/NCR/overview'),
  crumbGenerator('Permissions'),
];

const BREADCRUMB_ROUTING_CONFING = {
  '/admin/users': () => usersCrumb(),
  '/admin/users/:id': (state) => userDetailsCrumb(state),
  '/admin/reports': () => statusReportsCrumb(),
  '/admin/wbses': () => wbsesCrumb(),
  '/admin/wbses/:id': (state) => wbsesDetailsCrumb(state),
  '/admin/checklists': () => checklistsCrumb(),
  '/admin/checklists/:id': (state) => editChecklistCrumb(state),
  '/admin/newChecklist': () => newChecklistCrumb(),
  '/inspections/:type': (_, params) => inspectionsCrumb(params),
  '/newInspection': () => newInspectionCrumb(),
  '/newInspection/:inspectionId': () => newInspectionCrumb(),
  '/editInspection/:editInspectionId': (state) => editInspectionCrumb(state),
  '/inspectionDetails/:id': (state) => inspectionDetailsCrumb(state),
  '/inspectionDetails/:inspectionId/newFinding': (state, params) => newFindingCrumb(state, params),
  '/inspectionDetails/:inspectionId/editFinding/:editFindingId': (state, params) => findingDetailsCrumb(state, params),
  '/inspectionDetails/:inspectionId/findingDetails/:id': (state, params) => findingDetailsCrumb(state, params),
  '/findings/:type': (_, params) => findingsCrumb(params),
  '/findings/:type/:selectedIds': (_, params) => findingsCrumb(params),
  '/workflow/:type': (_, params) => newWorkflowCrumb(params),
  '/workflows/:type': (_, params) => workflowsCrumb(params),
  '/workflow/:type/:inspectionId/:findingId': (_, params) => newWorkflowCrumb(params),
  '/workflow/:type/copy/:workflowId': (state) => nonconformityFromObservationCrumb(state),
  '/workflowDetails/:workflowId': (state) => workflowDetailsCrumb(state),
  '/editWorkflow/:editWorkflowId': (state) => workflowDetailsCrumb(state),
  '/dashboard': () => dashboardCrumb(),
  '/relatedInspection/:inspectionId/relatedFinding/:id': (_, params) => relatedFinding(params),
  '/external-workflows/NCR/workflows': () => externalWorkflowsNCR(),
  '/external-workflows/NCR/workflows/add': () => externalWorkflowsNew(),
  '/external-workflows/NCR/workflows/:id/edit': () => externalWorkflowsNew('Edit'),
  '/external-workflows/NCR/workflows/:id': (_, params) => externalWorkflowsDetails(params),
  '/external-workflows/NCR/overview': () => externalWorkflowsOverview(),
  '/external-workflows/NCR/admin/master-data': () => externalWorkflowsAdminMasterData(),
  '/external-workflows/NCR/permissions': () => externalWorkflowsPermissions(),
};

export default BREADCRUMB_ROUTING_CONFING;
