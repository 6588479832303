import React, { PureComponent, Fragment } from 'react';

import PropTypes from 'prop-types';

import { MenuList, Popover, IconButton, Icon } from '@material-ui/core';

class DropdownMenu extends PureComponent {
  state = {
    anchorEl: null,
  };

  openDropdown = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  };

  closeDropdown = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { anchorEl } = this.state;
    const { children, icon } = this.props;

    return (
      <>
        <IconButton onClick={this.openDropdown}>{icon}</IconButton>
        <Popover
          id="finding actions dropdown"
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={this.closeDropdown}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuList>{children}</MenuList>
        </Popover>
      </>
    );
  }
}

DropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
};

DropdownMenu.defaultProps = {
  icon: <Icon className="svg-medium">more_vert</Icon>,
};

export default DropdownMenu;
