import EXIF from 'exif-js';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import _pick from 'lodash/pick';

const generateUId = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

const getImageOrientation = (image) =>
  new Promise((resolve) => {
    EXIF.getData(image, function () {
      resolve(EXIF.getTag(this, 'Orientation'));
    });
  });

const rotate = (ctx, degrees) => ctx.rotate((degrees * Math.PI) / 180);

const rotateCanvas = (canvas, image, ctx, orientation) => {
  switch (orientation) {
    case 2:
      ctx.scale(-1, 1);
      break;
    case 3:
      rotate(ctx, 180);
      break;
    case 4:
      ctx.scale(-1, 1);
      rotate(ctx, 180);
      break;
    case 5:
      ctx.scale(-1, 1);
      rotate(ctx, 270);
      break;
    case 6:
      canvas.width = image.height;
      canvas.height = image.width;
      ctx.translate(image.height / 2, image.width / 2);
      rotate(ctx, 90);
      break;
    case 7:
      canvas.width = image.height;
      canvas.height = image.width;
      ctx.translate(image.height / 2, image.width / 2);
      ctx.scale(-1, 1);
      rotate(ctx, 270);
      break;
    case 8:
      canvas.width = image.height;
      canvas.height = image.width;
      ctx.translate(image.height / 2, image.width / 2);
      rotate(ctx, 270);
      break;
    default:
      break;
  }
};

const rotateImage = (file) =>
  new Promise((resolve) => {
    const image = document.createElement('img');

    image.onload = async () => {
      const orientation = await getImageOrientation(image);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = image.width;
      canvas.height = image.height;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.translate(image.width / 2, image.height / 2);
      rotateCanvas(canvas, image, ctx, orientation);
      ctx.drawImage(image, -image.width / 2, -image.height / 2);

      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        file.type,
        1,
      );
    };

    image.src = URL.createObjectURL(file);
  });

const convertImage = (file, quality, maxHeight, maxWidth, rotate) =>
  new Promise((resolve) => {
    const image = document.createElement('img');

    image.onload = async () => {
      const orientation = await getImageOrientation(image);
      const canvas = document.createElement('canvas');
      let { width, height } = image;

      if (maxWidth && maxHeight) {
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');

      if (rotate) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.translate(image.width / 2, image.height / 2);
        rotateCanvas(canvas, image, ctx, orientation);
        ctx.drawImage(image, -width / 2, -height / 2, width, height);
      } else {
        ctx.drawImage(image, 0, 0, width, height);
      }

      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        file.type,
        quality,
      );
    };

    image.src = URL.createObjectURL(file);
  });

const blobToBase64 = (blob) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result.split(',')[1]);
    };
    reader.readAsDataURL(blob);
  });

const blobToBuffer = (blob) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(blob);
  });

const bufferToBlob = (buffer, type) => new Blob([new Uint8Array(buffer)], { type });

const parseFileSize = (size) => {
  if (size / 1024 > 1) {
    return `${(size / 1024 / 1000).toFixed(2)} MB`;
  }

  return `${(size / 1024).toFixed(2)} KB`;
};

const makeWbsName = (obj) => {
  const rootDescription = _get(obj, 'rootDescription');
  const description = _get(obj, 'description');
  const name = _get(obj, 'name');
  const sub = description || name || '';

  if (rootDescription && sub) {
    return `${rootDescription} - ${sub}`;
  }

  return sub;
};

const makeSiteFacilityName = (siteFacility) => {
  if (!siteFacility) {
    return '';
  }

  const siteFacilityName = Object.values(_pick(siteFacility, ['supplier', 'location', 'country']));
  return siteFacilityName.length ? siteFacilityName.join(', ') : '';
};

const findNode = (node, searchKey, searchValue, childrenKey = 'children', cb) => {
  if (node[searchKey] === searchValue) {
    if (cb) {
      cb(node);
    }

    return node;
  }

  if (node[childrenKey]) {
    let result = null;
    for (let i = 0; result === null && i < node[childrenKey].length; i += 1) {
      result = findNode(node[childrenKey][i], searchKey, searchValue, childrenKey, cb);
    }
    return result;
  }

  return null;
};

const findNodeInTree = (tree, searchKey, searchValue, childrenKey, cb) => {
  let result = null;

  for (let i = 0; result === null && i < tree.length; i += 1) {
    result = findNode(tree[i], searchKey, searchValue, childrenKey, cb);
  }
  return result;
};

const filterNodesInTree = (tree, searchKey, searchValue) =>
  tree.reduce((acc, node) => {
    const children = filterNodesInTree(node.children || [], searchKey, searchValue);

    if (node[searchKey] === searchValue || children.length) {
      acc.push(Object.assign({}, node, children.length && { children }));
    }
    return acc;
  }, []);

const sortNode = (node, sortField, sortDirection, childrenKey) => {
  if (node[childrenKey]) {
    const result = [];

    for (let i = 0; i < node[childrenKey].length; i += 1) {
      result.push(sortNode(node[childrenKey][i], sortField, sortDirection, childrenKey));
    }

    return { ...node, children: _orderBy(result, sortField, sortDirection) };
  }

  return node;
};

const sortTree = (tree, sortField, sortDirection, childrenKey = 'children') => {
  const result = [];

  for (let i = 0; i < tree.length; i += 1) {
    result.push(sortNode(tree[i], sortField, sortDirection, childrenKey));
  }

  return _orderBy(result, sortField, sortDirection);
};

const isIOS = () => !!navigator.platform && /iPad|iPhone/.test(navigator.platform);

const addSeparator = (title, separator) => (title.length ? separator : '');

const generateFindingTitle = (mainSystem, subSystem, failureMode, failureModeDetails) => {
  let title = '';

  if (mainSystem) {
    title += mainSystem;
  }

  if (subSystem) {
    title += `${addSeparator(title, ' ')}${subSystem}`;
  }

  if (failureMode && failureMode.description) {
    title += addSeparator(title, ' - ');
    title +=
      failureMode.description.toLowerCase() === 'other' && failureModeDetails
        ? failureModeDetails
        : failureMode.description;
  }

  return title || 'Untitled Finding';
};

/**
 * Replaces all accented chars with regular ones
 */
const replaceAccents = (str) => {
  // verifies if the String has accents and replace them
  if (str.search(/[\xC0-\xFF]/g) > -1) {
    str = str
      .replace(/[\xC0-\xC5]/g, 'A')
      .replace(/[\xC6]/g, 'AE')
      .replace(/[\xC7]/g, 'C')
      .replace(/[\xC8-\xCB]/g, 'E')
      .replace(/[\xCC-\xCF]/g, 'I')
      .replace(/[\xD0]/g, 'D')
      .replace(/[\xD1]/g, 'N')
      .replace(/[\xD2-\xD6\xD8]/g, 'O')
      .replace(/[\xD9-\xDC]/g, 'U')
      .replace(/[\xDD]/g, 'Y')
      .replace(/[\xDE]/g, 'P')
      .replace(/[\xE0-\xE5]/g, 'a')
      .replace(/[\xE6]/g, 'ae')
      .replace(/[\xE7]/g, 'c')
      .replace(/[\xE8-\xEB]/g, 'e')
      .replace(/[\xEC-\xEF]/g, 'i')
      .replace(/[\xF1]/g, 'n')
      .replace(/[\xF2-\xF6\xF8]/g, 'o')
      .replace(/[\xF9-\xFC]/g, 'u')
      .replace(/[\xFE]/g, 'p')
      .replace(/[\xFD\xFF]/g, 'y');
  }

  return str;
};

/**
 * Remove non-word chars.
 */
const removeNonWord = (str) => str.replace(/[^0-9a-zA-Z\xC0-\xFF -]/g, '');

/**
 * "Safer" String.toUpperCase()
 */
const upperCase = (str) => str.toUpperCase();

/**
 * "Safer" String.toLowerCase()
 */
const lowerCase = (str) => str.toLowerCase();

const wordCaseNormaliser = (str) => (str ? str[0].toUpperCase() + str.substring(1).toLowerCase() : '');

const camelCase = (str) => {
  let newStr = replaceAccents(str);
  newStr = removeNonWord(newStr)
    .replace(/-/g, ' ') // convert all hyphens to spaces
    .replace(/\s[a-z]/g, upperCase) // convert first char of each word to UPPERCASE
    .replace(/\s+/g, '') // remove spaces
    .replace(/^[A-Z]/g, lowerCase); // convert first char to lowercase
  return newStr;
};

const encodeParam = (arr) => (Array.isArray(arr) ? btoa(arr.toString()) : '');

const decodeParam = (arr) => {
  try {
    const param = atob(arr).split(',');
    return Array.isArray(param) && param.length ? param : [];
  } catch (e) {
    return [];
  }
};

const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const eraseCookie = (name) => {
  setCookie(name, '', -1);
};

const getActionTaker = ({ currentActionTaker, currentActionTakerRole, siteFacility, createdBy, ...rest }) => {
  const actionTaker = currentActionTakerRole === 'CONTRACTOR' ? siteFacility : currentActionTaker;
  return actionTaker || currentActionTakerRole || createdBy;
};

const charsLeft = (text, max) => {
  if (!text) {
    return `Max ${max} characters.`;
  }

  const left = max - text.length;

  return left >= 0 ? `${left} characters remaining.` : `Input text is too long: ${text.length} / ${max}`;
};

const compareObjectStructure = (obj1, obj2) => {
  if (!obj1 || !obj2) {
    return false;
  }

  if (Object.keys(obj1).sort().length !== Object.keys(obj2).sort().length) {
    return false;
  }

  Object.keys(obj1)
    .sort()
    .forEach((i) => {
      if (!obj2.hasOwnProperty(i)) {
        return false;
      }

      if (typeof obj1[i] === 'object' && typeof obj2[i] === 'object') {
        return compareObjectStructure(obj1[i], obj2[i]);
      }
    });

  return true;
};

const renderItemFacilities = ({ supplier, location, country }) => `${supplier}, ${location}, ${country}`;

const isChromeBrowser = () => /Chrome/.test(navigator.userAgent);
const isSafariBrowser = () => /iPad|iPhone/.test(navigator.userAgent);

const divideWithFilter = (array, filter) => {
  return array.reduce(
    ([pass, fail], elem) => (filter(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]),
    [[], []],
  );
};

const isObjectEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export {
  blobToBase64,
  blobToBuffer,
  bufferToBlob,
  camelCase,
  charsLeft,
  compareObjectStructure,
  convertImage,
  decodeParam,
  divideWithFilter,
  encodeParam,
  eraseCookie,
  filterNodesInTree,
  findNodeInTree,
  generateFindingTitle,
  generateUId,
  getActionTaker,
  getCookie,
  isChromeBrowser,
  isIOS,
  isObjectEmpty,
  isSafariBrowser,
  makeSiteFacilityName,
  makeWbsName,
  parseFileSize,
  renderItemFacilities,
  rotateImage,
  setCookie,
  sortTree,
  wordCaseNormaliser,
};
