import React, { FC, MouseEvent, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ChecklistsMenu from 'assets/icons/ChecklistsMenu';
import { NavigationContext } from 'context/navigation';
import { useOutsideClick } from 'hooks';
import PropTypes from 'prop-types';
import Acl, { PRIVILEGE_KEYS } from 'services/acl';

import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@material-ui/core';
import {
  AccountTreeOutlined,
  Assignment,
  BuildOutlined,
  DashboardOutlined,
  ExpandLess,
  ExpandMore,
  FiberManualRecordOutlined,
  GroupOutlined,
  NotificationImportant,
  PlaylistAddCheckOutlined,
  SwapCallsOutlined,
} from '@material-ui/icons';

import {
  FindingSeverityNonConformity,
  FindingSeverityObservation,
  InspectionProjectStageConstruction,
  InspectionProjectStageOm,
  Logo,
} from '../../../../assets/icons/index';
import { CONSTRUCTION_TYPE, FINDINGS_TYPES, OM_TYPE, WORKFLOWS_TYPES_PLURAL } from '../../../app/config/config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  main: {
    borderRadius: '12px',
    paddingTop: '5px',
    paddingBottom: '5px',
    marginBottom: '5px',
  },
  active: {
    backgroundColor: '#e8f0fe',
  },
  nested: {
    borderRadius: '12px',
    nested: {
      paddingLeft: theme.spacing(4),
      paddingTop: '5px',
      paddingBottom: '5px',
      marginBottom: '5px',
    },
    paddingLeft: theme.spacing(4),
    paddingTop: '5px',
    paddingBottom: '5px',
    marginBottom: '5px',
  },
  iconWidth: {
    width: '36px',
    minWidth: 'unset',
  },
  selectedIconWidth: {
    width: '30px',
    minWidth: 'unset',
  },
}));

const CustomMenuItem = ({ id, icon, desc, onClick, ...props }) => {
  const classes = useStyles();
  return (
    <MenuItem onClick={onClick} id={id} {...props}>
      <ListItemIcon className={classes.iconWidth}>{icon}</ListItemIcon>
      <ListItemText primary={desc} />
    </MenuItem>
  );
};

CustomMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  desc: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const MENU_ITEMS_SUBMENU = {
  INSPECTIONS: 'INSPECTIONS',
  WORK_PACKAGES: 'WORK_PACKAGES',
  WORKFLOWS: 'WORKFLOWS',
};

interface ITempState {
  auth: {
    user: {
      roles: string[];
    };
  };
}

const MenuWeb: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const menuRef = useRef(null);
  const { opened, toggleNavigation, closeNavigation } = useContext(NavigationContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuOpened, setSubMenuOpened] = useState(Object.values(MENU_ITEMS_SUBMENU));

  const classes = useStyles();

  const { roles } = useSelector((state: ITempState) => state.auth.user);

  const isSubmenuOpened = (menu: string) => {
    return subMenuOpened.includes(menu);
  };

  const toggleSubMenu = (menu: string) => {
    if (isSubmenuOpened(menu)) {
      setSubMenuOpened(subMenuOpened.filter((item) => item !== menu));
      return;
    }
    setSubMenuOpened([...subMenuOpened, menu]);
  };

  useOutsideClick(menuRef, () => {
    if (opened) {
      toggleNavigation();
    }
  });

  const canCreateInspection = Acl.hasPrivilege(PRIVILEGE_KEYS.INSPECTIONS_CREATE);

  const canCreateWorkflow = Acl.hasPrivilege(PRIVILEGE_KEYS.WORKFLOWS_CREATE);

  const canShowInspectionsList = Acl.hasPrivilege(PRIVILEGE_KEYS.INSPECTIONS_LIST);

  const canShowDashboards = Acl.hasPrivilege(PRIVILEGE_KEYS.DASHBOARDS_VIEW);

  const canShowWorkflowsList = Acl.hasPrivilege(PRIVILEGE_KEYS.WORKFLOWS_LIST);

  const canShowWbsesList = Acl.hasPrivilege(PRIVILEGE_KEYS.WBSES_LIST);

  const canShowUsersList = Acl.hasPrivilege(PRIVILEGE_KEYS.USERS_LIST);

  const canShowFindingsList = Acl.hasPrivilege(PRIVILEGE_KEYS.FINDINGS_LIST);

  const canShowReportsList = Acl.hasPrivilege(PRIVILEGE_KEYS.REPORTS_LIST);

  const canShowChecklistsList = Acl.hasPrivilege(PRIVILEGE_KEYS.CHECKLISTS_LIST);

  const media = useMediaQuery('(min-width: 1152px)');

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConstructionClick = () => {
    history.push('/newInspection', { type: CONSTRUCTION_TYPE });
    handleClose();
    closeNavigation();
  };

  const handleOMClick = () => {
    history.push('/newInspection', { type: OM_TYPE });
    handleClose();
    closeNavigation();
  };

  const handleChecklistClick = () => {
    history.push('/admin/newChecklist');
    handleClose();
    closeNavigation();
  };

  const handleNewWorkflowClick = (e: MouseEvent<HTMLDivElement>) => {
    const { currentTarget } = e;
    const type = currentTarget.getAttribute('data-type');

    history.push(`/workflow/${type}`);
    handleClose();
    closeNavigation();
  };

  const onLogoClick = () => {
    history.push('/');
    closeNavigation();
  };

  const onInspectionClick = (e: MouseEvent<HTMLDivElement>) => {
    const {
      currentTarget: { id: inspectionStatus },
    } = e;

    history.push(`/inspections/${inspectionStatus}`);
    closeNavigation();
  };

  const onFindingsClick = (e: MouseEvent<HTMLDivElement>) => {
    const {
      currentTarget: { id },
    } = e;

    history.push(`/findings/${id}`);
    closeNavigation();
  };

  const onWorkflowClick = (e: MouseEvent<HTMLDivElement>) => {
    const {
      currentTarget: { id },
    } = e;

    history.push(`/workflows/${id}`);
    closeNavigation();
  };

  const onUsersClick = () => {
    history.push('/admin/users');
    closeNavigation();
  };

  const onDashboardsClick = () => {
    history.push('/dashboard');
    closeNavigation();
  };

  const onWorkPackagesClick = () => {
    history.push('/admin/wbses');
    handleClose();
    closeNavigation();
  };

  const onReportsClick = () => {
    history.push('/admin/reports');
    handleClose();
    closeNavigation();
  };

  const onChecklistClick = () => {
    history.push('/admin/checklists');

    handleClose();
    closeNavigation();
  };

  const switchActiveUrl = (items: string[]) => {
    for (const item of items) {
      if (location.pathname.includes(item)) {
        return classes.active;
      }
    }
    return '';
  };

  const isActiveSubMenu = (item: string) => {
    if (location.pathname.includes(item)) {
      return true;
    }

    return false;
  };

  const isOnlyContractor = () => {
    return roles && roles.length === 1 && roles[0] === 'CONTRACTOR';
  };

  const menu = [];

  if (canCreateInspection) {
    menu.push(
      <CustomMenuItem
        onClick={handleConstructionClick}
        icon={<InspectionProjectStageConstruction className="icon-blue" />}
        desc="Construction Inspection"
        puppet-data="construction:button"
        id="construction:button"
        key="construction:button"
      />,
      <CustomMenuItem
        onClick={handleOMClick}
        icon={<InspectionProjectStageOm className="icon-blue" />}
        desc="O&M Inspection"
        puppet-data="on:button"
        id="om:button"
        key="om:button"
      />,
      <Divider key="menu:divider" />,
    );
  }

  if (canShowChecklistsList) {
    menu.push(
      <CustomMenuItem
        onClick={handleChecklistClick}
        icon={<ChecklistsMenu className="icon-blue" />}
        desc="Checklist"
        puppet-data="on:button"
        id="om:button"
        key="om:button"
      />,
      <Divider key="menu:divider" />,
    );
  }

  if (canCreateWorkflow) {
    if (!isOnlyContractor()) {
      // TODO: remove role props and change it to ACL
      menu.push(
        <CustomMenuItem
          onClick={handleNewWorkflowClick}
          icon={<FindingSeverityObservation className="icon-severity-observation" />}
          desc="Observation"
          id="open-point:button"
          puppet-data="open-point:button"
          data-type="observation"
          key="open-point:button"
        />,

        <CustomMenuItem
          onClick={handleNewWorkflowClick}
          icon={<NotificationImportant />}
          desc="Defect Notification "
          id="defect_notification:button"
          puppet-data="defect_notification:button"
          data-type="defect_notification"
          key="nonconformity:button"
        />,
      );
    }

    menu.push(
      <CustomMenuItem
        onClick={handleNewWorkflowClick}
        icon={<FindingSeverityNonConformity />}
        desc="NonConformities"
        id="nonconformity:button"
        puppet-data="nonconformity:button"
        data-type="nonconformity"
        key="nonconformity:button"
      />,
    );

    // menu.push(
    //   <CustomMenuItem
    //     onClick={handleNewWorkflowClick}
    //     icon={<AssignmentLate />}
    //     desc="Audit"
    //     id="audit:button"
    //     puppet-data="audit:button"
    //     data-type="audit"
    //     key="audit:button"
    //   />
    // );
  }

  return (
    <>
      {/* @ts-ignore */}
      <Box id="menu-component:div" puppet-data="menu" className={`menu-web ${opened ? 'open' : ''}`} ref={menuRef}>
        {!media && (
          <Box className="logo__wrapper">
            <Logo className="logo cursor-pointer" onClick={onLogoClick} />
          </Box>
        )}
        {menu.length > 0 && (
          <>
            <Button
              id="add-new:button"
              puppet-data="add-new:button"
              className="outlined-fab add-button"
              fullWidth
              variant="outlined"
              color="secondary"
              aria-owns={anchorEl ? 'add-new-menu' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              +&nbsp;New
            </Button>
            <Menu id="add-new-menu" anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
              {menu}
            </Menu>
          </>
        )}
        <List component="nav" className={classes.root}>
          {canShowDashboards && (
            <ListItem
              button
              onClick={onDashboardsClick}
              className={`${classes.main} ${switchActiveUrl(['dashboard'])}`}
            >
              <ListItemIcon className={classes.iconWidth}>
                <DashboardOutlined />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          )}
          {canShowInspectionsList && (
            <>
              <ListItem
                button
                onClick={() => toggleSubMenu(MENU_ITEMS_SUBMENU.INSPECTIONS)}
                className={`${classes.main} ${switchActiveUrl(['inspections'])}`}
              >
                <ListItemIcon className={classes.iconWidth}>
                  <Assignment />
                </ListItemIcon>
                <ListItemText primary="Inspections" />
                {isSubmenuOpened(MENU_ITEMS_SUBMENU.INSPECTIONS) ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isSubmenuOpened(MENU_ITEMS_SUBMENU.INSPECTIONS)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} id="open" onClick={onInspectionClick}>
                    <ListItemIcon className={classes.selectedIconWidth}>
                      {isActiveSubMenu('inspections/open') && <FiberManualRecordOutlined fontSize="small" />}
                    </ListItemIcon>
                    <ListItemText primary="Open" />
                  </ListItem>
                  <ListItem button className={classes.nested} id="finished" onClick={onInspectionClick}>
                    <ListItemIcon className={classes.selectedIconWidth}>
                      {isActiveSubMenu('inspections/finished') && <FiberManualRecordOutlined fontSize="small" />}
                    </ListItemIcon>
                    <ListItemText primary="Finished" />
                  </ListItem>
                  <ListItem button className={classes.nested} id="closed" onClick={onInspectionClick}>
                    <ListItemIcon className={classes.selectedIconWidth}>
                      {isActiveSubMenu('inspections/closed') && <FiberManualRecordOutlined fontSize="small" />}
                    </ListItemIcon>
                    <ListItemText primary="Closed" />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}

          {canShowChecklistsList && (
            <ListItem
              button
              onClick={onChecklistClick}
              className={`${classes.main} ${switchActiveUrl(['checklists'])}`}
            >
              <ListItemIcon className={classes.iconWidth}>
                <PlaylistAddCheckOutlined />
              </ListItemIcon>
              <ListItemText primary="Checklists" />
            </ListItem>
          )}

          {canShowFindingsList && (
            <ListItem
              button
              id={FINDINGS_TYPES.list.toLowerCase()}
              onClick={onFindingsClick}
              className={`${classes.main} ${switchActiveUrl(['findings'])}`}
            >
              <ListItemIcon className={classes.iconWidth}>
                <BuildOutlined />
              </ListItemIcon>
              <ListItemText primary="Findings" />
            </ListItem>
          )}

          {canShowWorkflowsList && (
            <>
              <ListItem
                button
                onClick={() => toggleSubMenu(MENU_ITEMS_SUBMENU.WORKFLOWS)}
                className={`${classes.main} ${switchActiveUrl(['workflows'])}`}
              >
                <ListItemIcon className={classes.iconWidth}>
                  <SwapCallsOutlined />
                </ListItemIcon>
                <ListItemText primary="Workflows" />
                {isSubmenuOpened(MENU_ITEMS_SUBMENU.WORKFLOWS) ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isSubmenuOpened(MENU_ITEMS_SUBMENU.WORKFLOWS)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} id="observation" onClick={onWorkflowClick}>
                    <ListItemIcon className={classes.selectedIconWidth}>
                      {isActiveSubMenu('workflows/observation') && <FiberManualRecordOutlined fontSize="small" />}
                    </ListItemIcon>
                    <ListItemText primary={WORKFLOWS_TYPES_PLURAL.observation} />
                  </ListItem>
                  <ListItem button className={classes.nested} id="nonconformity" onClick={onWorkflowClick}>
                    <ListItemIcon className={classes.selectedIconWidth}>
                      {isActiveSubMenu('workflows/nonconformity') && <FiberManualRecordOutlined fontSize="small" />}
                    </ListItemIcon>
                    <ListItemText primary={WORKFLOWS_TYPES_PLURAL.nonconformity} />
                  </ListItem>
                  <ListItem button className={classes.nested} id="defect_notification" onClick={onWorkflowClick}>
                    <ListItemIcon className={classes.selectedIconWidth}>
                      {isActiveSubMenu('workflows/defect_notification') && (
                        <FiberManualRecordOutlined fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={WORKFLOWS_TYPES_PLURAL.defect_notification} />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}

          {(canShowWbsesList || canShowReportsList) && (
            <ListItem
              button
              onClick={() => toggleSubMenu(MENU_ITEMS_SUBMENU.WORK_PACKAGES)}
              className={`${classes.main} ${switchActiveUrl(['admin/wbses', 'admin/reports'])}`}
            >
              <ListItemIcon className={classes.iconWidth}>
                <AccountTreeOutlined />
              </ListItemIcon>
              <ListItemText primary="Workpackages" />
              {isSubmenuOpened(MENU_ITEMS_SUBMENU.WORK_PACKAGES) ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
          <Collapse in={isSubmenuOpened(MENU_ITEMS_SUBMENU.WORK_PACKAGES)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {canShowWbsesList && (
                <ListItem button className={classes.nested} onClick={onWorkPackagesClick}>
                  <ListItemIcon className={classes.selectedIconWidth}>
                    {isActiveSubMenu('admin/wbses') && <FiberManualRecordOutlined fontSize="small" />}
                  </ListItemIcon>
                  <ListItemText primary="User Management" />
                </ListItem>
              )}
              {canShowReportsList && (
                <ListItem button className={classes.nested} onClick={onReportsClick}>
                  <ListItemIcon className={classes.selectedIconWidth}>
                    {isActiveSubMenu('admin/reports') && <FiberManualRecordOutlined fontSize="small" />}
                  </ListItemIcon>
                  <ListItemText primary="Status reports" />
                </ListItem>
              )}
            </List>
          </Collapse>

          {canShowUsersList && (
            <ListItem button onClick={onUsersClick} className={`${classes.main} ${switchActiveUrl(['admin/users'])}`}>
              <ListItemIcon className={classes.iconWidth}>
                <GroupOutlined />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          )}
        </List>
      </Box>
    </>
  );
};

export default MenuWeb;
