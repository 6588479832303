import { SET_DETAILS, SET_IS_LOADING, CLEAR_DATA } from './wbsDetails.actions';

const initialState = {
  data: {},
  isLoading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_DETAILS:
      return {
        ...state,
        data: payload,
        isLoading: false,
      };
    case CLEAR_DATA: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
