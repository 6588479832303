import { connect } from 'react-redux';

import { createCrumb } from 'modules/app/components/app/app.actions';

import { getWorkPackages } from './wbsesTree.actions';
import WbsesTreeWeb from './wbsesTreeWeb.component';

const mapStateToProps = (state) => ({
  workPackages: state.wbsesTree.items,
  isLoading: state.wbsesTree.isLoading,
  user: state.auth.user,
});

const mapDispatchToProps = {
  createCrumb,
  getWorkPackages,
};

export default connect(mapStateToProps, mapDispatchToProps)(WbsesTreeWeb);
