import React, { PureComponent, Fragment } from 'react';
import { Route } from 'react-router-dom';

import classNames from 'classnames';
import checkAccess from 'modules/common/hoc/checkAccess/checkAccess.hoc';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

import { USER_ROLES } from '../../../app/config/config';
import ExpandableTree from '../../../common/components/expandableTree/expandableTree';
import Loader from '../../../common/components/loader/loader.component';
import WbsDetails from '../wbsDetails/wbsDetails.container';
import decorators from './wbsesTreeDecorators';

const WbsDetailsWithAuth = checkAccess(WbsDetails, 'WBSES.EDIT');

class WbsesTreeWeb extends PureComponent {
  state = {
    workPackages: [],
    isLoading: true,
  };

  async componentDidMount() {
    const { getWorkPackages } = this.props;
    const workPackages = await getWorkPackages();

    this.setState({
      workPackages,
      isLoading: false,
    });
  }

  componentDidUpdate({ location: prevLocation }) {
    const {
      createCrumb,
      location,
      match: { path },
    } = this.props;

    createCrumb(path);

    if (location !== prevLocation) {
      this.onRouteChanged();
    }
  }

  onRouteChanged = async () => {
    const {
      location: { state },
      getWorkPackages,
    } = this.props;

    if (state && state.reload) {
      const workPackages = await getWorkPackages();

      this.setState({
        workPackages,
      });
    }
  };

  setWorkPackages = (workPackages) => this.setState({ workPackages });

  getDetailsComponent = () => {
    const { user } = this.props;
    const { workPackages } = this.state;

    return <WbsDetailsWithAuth loggedUser={user} workPackages={workPackages} setWorkPackages={this.setWorkPackages} />;
  };

  render() {
    const { getWorkPackages, match } = this.props;
    const { workPackages, isLoading } = this.state;
    const containerClass = classNames({ 'd-none': !match.isExact });
    const rootWorkpackages = workPackages?.length
      ? workPackages.map((wbs) => ({ ...wbs, isRoot: true }))
      : workPackages;

    return (
      <>
        <div className={containerClass}>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="wbs-tree pt-4">
              <div className="row no-gutters align-items-center justify-content-between table-row border-bottom">
                <div className="title">Work package</div>
                <div className="display-table">
                  {USER_ROLES.map((item) => (
                    <span className="table-cell" key={item.key}>
                      <Typography color="textSecondary" variant="subtitle2">
                        {item.label}
                      </Typography>
                    </span>
                  ))}
                  <span className="table-cell" />
                </div>
              </div>
              <ExpandableTree nodes={rootWorkpackages} getChildren={getWorkPackages} decorators={decorators} />
            </div>
          )}
        </div>
        <Route path="/admin/wbses/:id" component={this.getDetailsComponent} />
      </>
    );
  }
}

WbsesTreeWeb.propTypes = {
  createCrumb: PropTypes.func.isRequired,
  getWorkPackages: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.shape).isRequired,
  user: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default WbsesTreeWeb;
