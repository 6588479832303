import React, { FC, useState } from 'react';

import { FindingListXLS } from 'assets/icons';
import { saveAs } from 'file-saver';
import api from 'services/api';

import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { Error } from '@material-ui/icons';

interface TableExportToExcelButtonProps {
  url: string;
  params: { [key: string]: unknown };
  filters: { [key: string]: unknown[] };
  search: string;
}

const TableExportToExcelButton: FC<TableExportToExcelButtonProps> = ({ url, params, filters, search }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleExcelExport = async () => {
    try {
      setIsLoading(true);
      setIsError(false);

      const { types, ...rest } = params;
      const nonEmptyFilters = Object.fromEntries(Object.entries(filters).filter(([key, value]) => value.length > 0));

      const body: { [key: string]: any } = {};

      const mapNamesToParams = {
        actionee: 'actioneeIds',
        status: 'statuses',
        siteFacility: 'siteFacilityIds',
        failureMode: 'failureModeIds',
        severity: 'severities',
        area: 'areas',
        serialDefect: 'serialDefect',
      };

      if (nonEmptyFilters) {
        Object.entries(nonEmptyFilters).forEach(([key, val]) => {
          if (val && val.length > 0) {
            if (key === 'wbs') {
              body.wbsIds = val;
              return;
            }

            if (key === 'serialDefect') {
              body.serialDefect = val[val.length - 1];

              return;
            }

            body[mapNamesToParams[key]] = val;
          }

          if (nonEmptyFilters.search) {
            body.text = nonEmptyFilters.search;
          }
        });
      }
      const response = await api.post(
        url,
        { ...body, types, text: search },
        { ...rest },
        {
          'Content-Type': 'application/json',
        },
        'blob',
      );
      const jsonBlob = new Blob([response], { type: 'charset=utf-8' });

      saveAs(jsonBlob, 'defectNotifications.xlsx');
    } catch (err) {
      setIsError(true);
      setIsLoading(false);
      console.error(err);
    }

    await setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <Tooltip title="Export to xlsx">
      <IconButton onClick={handleExcelExport}>
        {isLoading ? (
          <CircularProgress size={25} color="secondary" variant="indeterminate" />
        ) : isError ? (
          <Error />
        ) : (
          <FindingListXLS className="icon-dark" />
        )}
      </IconButton>
    </Tooltip>
  );
};

export { TableExportToExcelButton };
