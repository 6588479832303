import { FiletypeAudio, FiletypeExcell, FiletypeOther, FiletypeWord, FiletypePDF } from 'assets/icons/index';
import {
  FILE_SIZE_LIMIT,
  INTERNAL_ERROR_CODE,
  ALLOWED_FILE_EXTENSIONS,
  WORD_MIMETYPES,
  EXCEL_MIMETYPES,
} from 'modules/app/config/config';
import { convertImage, blobToBuffer, generateUId, isIOS, blobToBase64 } from 'modules/app/helpers/utils';
import Api from 'services/api';
import Db from 'services/db';
import Notifications from 'services/notifications';

const PHOTO_TYPE = 'photos';
const AUDIO_TYPE = 'audios';
const DOC_TYPE = 'docs';

const DEFAULT_FIELDS = ['_id', 'description', 'sync', 'marked', 'mimeType'];
const PHOTOS_FIELDS = DEFAULT_FIELDS.concat(['thumbnail']);

const TYPE_TO_MIME = {
  [PHOTO_TYPE]: 'image/',
  [AUDIO_TYPE]: 'audio/',
  [DOC_TYPE]: 'application/',
};

const LOCAL_COUNTERS_MAP = {
  [PHOTO_TYPE]: 'localNumberOfImages',
  [AUDIO_TYPE]: 'localNumberOfAudios',
  [DOC_TYPE]: 'localNumberOfDocuments',
};

const MIME_TO_TYPE = {
  image: PHOTO_TYPE,
  audio: AUDIO_TYPE,
  application: DOC_TYPE,
  text: DOC_TYPE,
};

const ALLOWED_FILES_MIMES = [
  ...ALLOWED_FILE_EXTENSIONS.photos.mimes,
  ...ALLOWED_FILE_EXTENSIONS.audios.mimes,
  ...ALLOWED_FILE_EXTENSIONS.docs.mimes,
  ...ALLOWED_FILE_EXTENSIONS.webOnly.mimes,
];

const ALLOWED_FILES_DESC = [
  ALLOWED_FILE_EXTENSIONS.photos.desc,
  ALLOWED_FILE_EXTENSIONS.audios.desc,
  ALLOWED_FILE_EXTENSIONS.docs.desc,
  ALLOWED_FILE_EXTENSIONS.webOnly.desc,
].join(', ');

const mimetypeToFileIcon = (mimeType) => {
  if (mimeType === 'application/pdf') {
    return FiletypePDF;
  }

  if (WORD_MIMETYPES.includes(mimeType)) {
    return FiletypeWord;
  }

  if (EXCEL_MIMETYPES.includes(mimeType)) {
    return FiletypeExcell;
  }

  if (mimeType.includes('audio')) {
    return FiletypeAudio;
  }

  return FiletypeOther;
};

const getMarkedPhotos = async (itemId) => {
  try {
    const { docs } = await Db.find('sync_attachments', {
      selector: {
        marked: { $eq: true },
        itemId: { $eq: itemId },
      },
      fields: ['id'],
    });

    return docs;
  } catch (e) {
    throw e;
  }
};

const getDocs = (itemId, type) =>
  Db.find('sync_attachments', {
    selector: {
      $and: [
        { itemId: { $eq: itemId } },
        { fileType: { $eq: type } },
        { createdAt: { $gte: null } },
        { marked: { $gte: null } },
      ],
    },
    sort: [{ marked: 'desc' }, { createdAt: 'desc' }],
    fields: type === PHOTO_TYPE ? PHOTOS_FIELDS : DEFAULT_FIELDS,
  });

const groupAttachmentsByType = (files) =>
  files.reduce(
    (reduced, item) => {
      const mime = (item.mimeType || item.type).split('/')[0];
      const mimeType = MIME_TO_TYPE[mime] || DOC_TYPE;

      reduced[mimeType].push(item);
      return reduced;
    },
    {
      photos: [],
      audios: [],
      docs: [],
    },
  );

const alreadyHaveMain = (files) => {
  const withMain = files.filter((file) => file.main);
  return !!withMain.length;
};

const checkFileName = (file, fileListNames, showErrorMessage = true) => {
  const fileName = file.name;
  const incremented = fileListNames.setNameAndIncrement(fileName);

  if (incremented) {
    if (showErrorMessage) {
      Notifications.showError(`${fileName} is already attached.`);
    }

    const newFile = new File([file], fileListNames.incrementFileName(fileName), { type: file.type });
    return newFile;
  }

  return file;
};

const getAttachmentsWeb = async (itemId, type, fileListNames, firstTime = false) => {
  let files = await Api.get(`/api/attachments/${type}/${itemId}`);

  if (firstTime) {
    files.forEach((file) => {
      fileListNames.setNameAndIncrement(file.title);
    });
  }

  if (type === 'Finding' && files.length && !alreadyHaveMain(files)) {
    const mainFile = await Api.put('/api/attachments', {
      ...files[0],
      main: true,
    });
    files = [mainFile, ...files.slice(1)];
  }

  return groupAttachmentsByType(files);
};

const getAttachmentsMobile = async (fileType, itemId) => {
  try {
    await Db.createIndex('sync_attachments', ['marked', 'createdAt', 'itemId', 'fileType']);
    const { docs } = await getDocs(itemId, fileType);

    return docs;
  } catch (err) {
    throw err;
  }
};

const validateFileSize = ({ size, name = '' }) => {
  if (size > FILE_SIZE_LIMIT * 1024 * 1024) {
    const error = new Error(`${name} could not be uploaded. The maximum file size is ${FILE_SIZE_LIMIT}MB`);
    error.code = INTERNAL_ERROR_CODE;
    throw error;
  }
};

const validateFileExtensions = ({ type, name = '' }, fileGroupType = null) => {
  let error;
  if (
    fileGroupType &&
    ALLOWED_FILE_EXTENSIONS[fileGroupType] &&
    !ALLOWED_FILE_EXTENSIONS[fileGroupType].mimes.includes(type)
  ) {
    error = new Error(
      `${name} could not be uploaded. Only file types ${ALLOWED_FILE_EXTENSIONS[fileGroupType].desc} are allowed`,
    );
  } else if (!ALLOWED_FILES_MIMES.includes(type)) {
    error = new Error(`${name} could not be uploaded. Only file types ${ALLOWED_FILES_DESC} are allowed`);
  }

  if (error) {
    error.code = INTERNAL_ERROR_CODE;
    throw error;
  }
};

const validateFiles = (files = []) => {
  const errors = [];

  files.forEach((file) => {
    try {
      validateFileSize(file);
      validateFileExtensions(file);
    } catch (e) {
      errors.push(e);
    }
  });

  return errors;
};

const saveAttachmentWeb = async (file, parentId, parentType, fileListNames) => {
  try {
    validateFileSize(file);
    validateFileExtensions(file);
    const fileToAdd = checkFileName(file, fileListNames);

    const mime = fileToAdd.type.split('/')[0];
    const formData = new FormData();

    if (MIME_TO_TYPE[mime] === PHOTO_TYPE) {
      const attachmentEnchanted = await attachmentThumbnailEnhancement(file, file.size, false);
      const rotatedImage = attachmentEnchanted.rotatedImage;

      formData.append('file', rotatedImage);
    } else {
      formData.append('file', fileToAdd);
    }

    formData.append(
      'attachment',
      JSON.stringify({
        parentId,
        parentType,
        title: fileToAdd.name,
        createDate: new Date().toISOString(),
        frontendId: generateUId(),
      }),
    );

    await Api.post('/api/attachments', formData);
    return MIME_TO_TYPE[mime];
  } catch (e) {
    throw e;
  }
};

const saveAttachmentWebWithResponse = async (file, parentId, parentType, fileListNames, description = '') => {
  try {
    validateFileSize(file);
    validateFileExtensions(file);
    const fileToAdd = checkFileName(file, fileListNames, false);

    const formData = new FormData();

    formData.append('file', fileToAdd);
    formData.append(
      'attachment',
      JSON.stringify({
        parentId,
        parentType,
        title: fileToAdd.name,
        createDate: new Date().toISOString(),
        frontendId: generateUId(),
        description,
      }),
    );

    const response = await Api.post(`/api/attachments`, formData);

    return response;
  } catch (e) {
    throw e;
  }
};

const saveAttachmentsWeb = async (files, parentId, parentType, fileListNames) => {
  const promises = files.map((file) => saveAttachmentWeb(file, parentId, parentType, fileListNames).catch((e) => e));
  const data = await Promise.all(promises);
  const errors = data.filter((i) => i instanceof Error);

  if (errors.length) {
    throw errors;
  }
};

const putAttachmentToDB = async (file, id, rev) => {
  try {
    await Db.putAttachment('sync_attachments_files', id, id, rev, file, file.type);
  } catch (e) {
    if (e.status === 500) {
      try {
        await Db.reconnect('sync_attachments_files');

        const base64 = await blobToBase64(file);
        const attachmentToPut = await (await fetch(`data:${file.type};base64,${base64}`)).blob();
        await putAttachmentToDB(attachmentToPut, id, rev);
      } catch (err) {
        throw err;
      }
    } else {
      throw e;
    }
  }
};

const attachmentThumbnailEnhancement = async (file, docSize, isWeb = false) => {
  const rotatedImage = await convertImage(file, 0.7, 0, 0, isWeb);
  const thumbnail = await convertImage(rotatedImage, 0.5, 300, 300);
  const newDocSize = docSize + thumbnail.size;
  const thumbnailData = {
    content_type: file.type,
    data: isIOS ? await blobToBuffer(thumbnail) : thumbnail,
  };

  return { thumbnailData, rotatedImage, newDocSize };
};

const getParsedType = (file) => {
  if (file.type.includes('image')) {
    return PHOTO_TYPE;
  }

  if (file.type.includes('audio')) {
    return AUDIO_TYPE;
  }

  return DOC_TYPE;
};

const addAttachmentToSave = async (file, fileListNames) => {
  validateFileSize(file);
  validateFileExtensions(file);
  const fileToAdd = checkFileName(file, fileListNames);

  const parsedType = getParsedType(fileToAdd);
  let fileToSave = Object.assign(fileToAdd, { id: generateUId() });

  if (parsedType === PHOTO_TYPE) {
    const attachmentEnchanted = await attachmentThumbnailEnhancement(fileToSave, 0, true);
    fileToSave = Object.assign(fileToSave, {
      thumbnailPath: attachmentEnchanted.thumbnailData.data,
      data: attachmentEnchanted.rotatedImage,
    });
  }
  return fileToSave;
};

const addAttachmentsToSave = async (files, fileNames) => {
  const promises = files.map((file) => addAttachmentToSave(file, fileNames).catch((e) => e));
  const data = await Promise.all(promises);
  const errors = data.filter((i) => i instanceof Error);

  if (errors.length) {
    throw errors;
  }

  return data;
};

const saveAttachment = async (file, fileListNames, type, itemId, extraParams) => {
  try {
    let docSize = file.size;
    let rotatedImage;

    if (!docSize) {
      throw new Error('Cannot add empty file');
    }
    const fileToAdd = checkFileName(file, fileListNames);
    const parsedType = getParsedType(fileToAdd);

    const doc = {
      itemId,
      ...extraParams,
      createdAt: new Date().toISOString(),
      fileType: parsedType,
      mimeType: fileToAdd.type,
      title: fileToAdd.name,
      sync: false,
      local: true,
    };

    if (parsedType === PHOTO_TYPE) {
      const attachmentEnchanted = await attachmentThumbnailEnhancement(file, docSize, false);
      docSize = attachmentEnchanted.newDocSize;
      doc.thumbnail = attachmentEnchanted.thumbnailData;
      rotatedImage = attachmentEnchanted.rotatedImage;

      const markedPhotos = await getMarkedPhotos(itemId);
      doc.marked = markedPhotos.length === 0;
    }

    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const { quota, usage } = await navigator.storage.estimate();

      if (docSize * 4 > quota - usage) {
        throw new Error('Available space exceeded');
      }
    }

    validateFileSize(rotatedImage || fileToAdd);
    validateFileExtensions(rotatedImage || fileToAdd, parsedType);

    const { id } = await Db.insert('sync_attachments', doc);
    const { rev } = await Db.insert('sync_attachments_files', {
      _id: id,
      ...extraParams,
    });

    await putAttachmentToDB(rotatedImage || fileToAdd, id, rev);
    const inspection = await Db.findOne('inspections', extraParams.inspectionId, false);

    const finding = inspection.findings.find((f) => {
      return f.frontendId === itemId || f.id === itemId;
    });

    if (finding.frontendId) {
      await Db.retryUntilWritten('sync_findings_edit', itemId, (obj = { _id: itemId }) => ({
        ...obj,
        ...finding,
        inspectionLocalId: inspection.frontendId ? inspection.frontendId : inspection._id,
        updatedAt: new Date().toISOString(),
      }));
    }

    return id;
  } catch (e) {
    console.log('saveAttachment', e);
    throw e;
  }
};

const getAttachment = async (id) => {
  try {
    let attachment = await Db.findOne('sync_attachments', id, false);

    if (attachment === undefined) {
      attachment = await Api.get(`/api/attachments/${id}/download?thumbnailType=PREVIEW`, null, 'blob');
      return { file: attachment };
    } else {
      try {
        attachment.file = await Db.getAttachment('sync_attachments_files', id, id);
      } catch (e) {
        if (e.status !== 404) {
          throw e;
        }
      }
    }
    return attachment;
  } catch (e) {
    if (e.status !== 404) {
      throw e;
    }
  }
};

const removeAttachment = (id) => Api.delete(`/api/attachments/${id}`);

const removeAttachmentsWeb = async (files, parentId, parentType) => {
  const promises = files.map((file) => removeAttachment(file, parentId, parentType).catch((e) => e));
  const data = await Promise.all(promises);
  const errors = data.filter((i) => i instanceof Error);

  if (errors.length) {
    throw errors;
  }
};

const getNotSynchronizedAttachments = () => async () => {
  try {
    const { docs } = await Db.find('sync_attachments', {
      selector: {
        sync: { $eq: false },
      },
      fields: ['id'],
    });

    return docs;
  } catch (e) {
    throw e;
  }
};

export {
  addAttachmentsToSave,
  getAttachment,
  getAttachmentsMobile,
  getAttachmentsWeb,
  getNotSynchronizedAttachments,
  groupAttachmentsByType,
  mimetypeToFileIcon,
  removeAttachment,
  removeAttachmentsWeb,
  saveAttachment,
  saveAttachmentsWeb,
  saveAttachmentWeb,
  saveAttachmentWebWithResponse,
  validateFiles,
};

export { PHOTO_TYPE, AUDIO_TYPE, DOC_TYPE, LOCAL_COUNTERS_MAP, TYPE_TO_MIME, MIME_TO_TYPE, ALLOWED_FILES_DESC };
