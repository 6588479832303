import { connect } from 'react-redux';

import { saveCoInspector, deleteCoInspector } from '../newInspection/newInspection.actions';
import CoInspectors from './coInspectors.component';

const mapStateToProps = (state) => ({
  coInspectors: state.newInspection.coInspectors,
  isMobile: state.app.isMobile,
});

const mapDispatchToProps = {
  saveCoInspector,
  deleteCoInspector,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoInspectors);
