import { connect } from 'react-redux';

import { checkMobile } from 'modules/app/components/app/app.actions';

import { login } from './login.actions';
import Login from './login.component';

const mapStateToProps = (state) => ({
  isLogged: state.auth.isLogged,
  isLogin: state.auth.isLogin,
  isMobile: state.app.isMobile,
  redirectPage: state.auth.redirectPage,
});

const mapDispatchToProps = {
  login,
  checkMobile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
