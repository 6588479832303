import React from 'react';

const NonconformityStatusQaNonCompliant = (props) => (
  <svg width={24} height={24} {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H3V5h18v14zM9.41 15.95L12 13.36l2.59 2.59L16 14.54l-2.59-2.59L16 9.36l-1.41-1.41L12 10.54 9.41 7.95 8 9.36l2.59 2.59L8 14.54z" />
  </svg>
);

export default NonconformityStatusQaNonCompliant;
