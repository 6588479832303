import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FindingsMenu } from 'assets/icons';
import { FINDING_TYPES } from 'modules/findings/components/newFinding/newFinding.model';
import PropTypes from 'prop-types';

import { Icon, Checkbox, Typography } from '@material-ui/core';
import { ArrowForward, WorkOutline } from '@material-ui/icons';

const ChecklistGroup = ({
  canModify,
  checked,
  checklist,
  defaultExpanded,
  index,
  onAddFindingClick,
  onElementCheck,
  renderGroup,
  onClose,
  scrolledId,
}) => {
  const history = useHistory();
  const { isMobile } = useSelector((state) => state.app);
  const { data: inspection } = useSelector((state) => state.inspection);
  const [expanded, setExpanded] = useState(defaultExpanded);
  const toggleExpand = () => setExpanded(!expanded);

  const openClass = `${expanded ? 'open' : ''}`;

  const checklistElements = checklist?.checkListElementDefinitions?.map((element) => {
    const [noteVisible, setNoteVisible] = useState(false);
    const notesClass = noteVisible ? 'open' : '';

    const handleCreateNewFinding = (e) => {
      if (element.isChecked === false) {
        onElementCheck(element)(e);
      }
      onAddFindingClick(e, element);
    };

    const filteredFindings = inspection.findings.filter(
      (finding) =>
        finding.checkListElementDefinition?.id === element.id || finding.checkListElementDefinitionId === element.id,
    );

    const isExpandable = element.note || filteredFindings.length > 0;
    const openNote = () => isExpandable && setNoteVisible(!noteVisible);

    const renderFindings = filteredFindings?.map((finding) => {
      const handleClick = () => {
        const findingId = finding.inspection.localId || finding.inspection.id;

        history.push(`/inspectionDetails/${findingId}/findingDetails/${finding.id}`);
        onClose();
      };
      return (
        <div key={finding.id} className="checklist__element-finding" onClick={handleClick}>
          <div>
            {finding.findingType === FINDING_TYPES.WORK_INFO.value && (
              <WorkOutline style={{ fill: '#2071B5' }} className="mr-2" />
            )}

            {finding.findingType === FINDING_TYPES.ACTIONABLE.value && (
              <FindingsMenu className="mr-2 text-secondary menu-icon--findings" />
            )}
            {finding.description || finding.identifier}
          </div>

          <ArrowForward />
        </div>
      );
    });

    useEffect(() => {
      let timeoutID;
      if (scrolledId) {
        timeoutID = setTimeout(() => {
          const el = document.getElementById(`checklistItem-${scrolledId}`);

          if (el) {
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }, 100);
      }
      return () => {
        clearTimeout(timeoutID);
      };
    }, [scrolledId]);

    const maxHeight = noteVisible ? 1500 : 0;

    return (
      <div key={element.id} className="checklist__item-wrapper">
        <div className={`checklist__item ${isMobile ? '' : 'checklist__item--isDrawer'}`}>
          <Checkbox
            puppet-data={`checkbox-${element.id}`}
            checked={element.isChecked}
            onChange={onElementCheck(element)}
            disabled={!canModify}
          />
          <div className="checklist__element">
            <div id={`checklistItem-${element.id}`} className="text-secondary w-100 checklist__element-desc">
              {element.description}
            </div>
          </div>
          <Icon onClick={openNote} className={`checklist__note ${isExpandable ? 'icon-dark' : 'icon-light'}`}>
            {noteVisible ? 'unfold_less' : 'unfold_more'}
          </Icon>
          {canModify && (
            <Icon className="checklist__add-finding" onClick={handleCreateNewFinding}>
              <FindingsMenu className="mr-2 menu-icon--findings" />
            </Icon>
          )}
        </div>
        {isExpandable && (
          <div className={`checklist__item-note ${notesClass}`} style={{ maxHeight: `${maxHeight}px` }}>
            <div className="text-secondary w-100 checklist__element-note">
              <Typography variant="body2" style={{ marginBottom: '5px' }}>
                Note:
              </Typography>
              {element.note}
            </div>
            {renderFindings?.length > 0 && (
              <>
                <hr style={{ margin: '10px 0' }} />
                <div className="checklist__element-findings w-100">
                  <Typography variant="body2" style={{ marginBottom: '5px' }}>
                    Findings:
                  </Typography>

                  {renderFindings}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  });

  // rendering subGroups
  const renderSubGroups = checklist?.childrenCheckListGroupDefinitions?.map((subGroup) => renderGroup(subGroup));

  // adjusting styles for given group level
  const checklistGroupStyle = {
    paddingLeft: `${index}rem`,
  };

  return (
    <div className="checklist__group" style={checklistGroupStyle}>
      <div className="checklist__title" onClick={toggleExpand}>
        {checked && <Icon>check</Icon>}
        {checklist.description}
        <Icon className={`${openClass}`}>expand_less</Icon>
      </div>
      <div className={`${openClass} checklist__items ${isMobile ? '' : 'checklist__items--isDrawer'}`}>
        {checklistElements}
        {renderSubGroups}
      </div>
    </div>
  );
};

ChecklistGroup.propTypes = {
  canModify: PropTypes.bool,
  checked: PropTypes.bool,
  checklist: PropTypes.shape({}).isRequired,
  defaultExpanded: PropTypes.bool,
  index: PropTypes.number,
  onAddFindingClick: PropTypes.func.isRequired,
  onElementCheck: PropTypes.func.isRequired,
  renderGroup: PropTypes.func.isRequired,
  scrolledId: PropTypes.string,
};

ChecklistGroup.defaultProps = {
  canModify: false,
  checked: false,
  defaultExpanded: false,
  index: 0,
  scrolledId: '',
};

export default ChecklistGroup;
