import Api from '../../../../services/api';
import Db from '../../../../services/db';

const editAttachment = async (id, newData, currentData) => {
  try {
    const extendedNewData = {
      ...newData,
      updatedAt: new Date().toISOString(),
      title: currentData.title || '',
      id,
    };

    if (currentData.local) {
      let updatedDoc;

      await Db.retryUntilWritten('sync_attachments', currentData._id, (org) => {
        updatedDoc = { ...org, ...extendedNewData };
        return updatedDoc;
      });

      if (currentData.sync && updatedDoc) {
        const { file, thumbnail, ...toSyncDoc } = updatedDoc;
        await Db.retryUntilWritten('sync_attachments_edit', updatedDoc._id, (o = {}) => ({ ...o, ...toSyncDoc }));
      }
    } else {
      await Api.put(`/api/attachments`, { ...extendedNewData });
    }
  } catch (e) {
    console.log('editAttachment', e);
    throw e;
  }
};

export { editAttachment };
