import React, { useState } from 'react';

import { number } from 'prop-types';

import { TextField } from '@material-ui/core';

export default (Base) => {
  const withPrompt = (props) => {
    const { placeholder, promptName, required, maxLength } = props;
    const [promptValue, setPromptValue] = useState('');
    const [isError, setError] = useState('');

    const handleInputChange = (e) => {
      const { value } = e.target;
      const text = value.trim();

      if (text.length === 0) {
        setError('Reason is empty');
      } else if (text.length > maxLength) {
        setError('Reason is too long');
      } else {
        setError('');
        setPromptValue(text);
      }
    };

    const renderPrompt = (
      <TextField
        className="w-100"
        id="prompt-confirm"
        label={placeholder}
        multiline
        onChange={handleInputChange}
        required={required}
        error={!!isError}
        helperText={isError}
      />
    );

    return (
      <Base {...props} renderPrompt={renderPrompt} promptValue={isError ? '' : promptValue} promptName={promptName} />
    );
  };

  withPrompt.propTypes = {
    maxLength: number,
  };

  withPrompt.defaultProps = {
    maxLength: 1024,
  };

  return withPrompt;
};
