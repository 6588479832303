import React from 'react';

import { Logo } from 'assets/icons/index';
import HeaderMobile from 'modules/common/components/header/headerMobile.component';
import Loader from 'modules/common/components/loader/loader.component';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

const MobileLoader = () => (
  <>
    <HeaderMobile forcedTitle="Loading data..." />
    <Loader />
  </>
);

const WebLoader = () => (
  <div className="container d-flex flex-column flex-fill">
    <div className="row justify-content-between align-items-center header-web-component">
      <Logo className="logo cursor-pointer" />
    </div>
    <div className="row justify-content-center align-items-center">
      <Typography color="textSecondary" variant="subtitle2">
        Loading data...
      </Typography>
    </div>
    <Loader />
  </div>
);

const LoadingPage = ({ isMobile }) => <>{isMobile ? <MobileLoader /> : <WebLoader />}</>;

LoadingPage.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default LoadingPage;
