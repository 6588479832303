import React, { Dispatch, FC, createContext, useEffect, useMemo, useReducer } from 'react';

import filtersReducer, { FilterActionTypes, actions } from './filters.reducer';
import { FiltersConfig, IFilterState } from './types';

interface FiltersContextInterface {
  state: IFilterState;
  dispatch: Dispatch<FilterActionTypes>;
  initialValues: IFilterState;
  config?: FiltersConfig;
}

const initialFilterState: IFilterState = {
  filters: {},
  cleanup: false,
  search: '',
  sort: {
    sortBy: '',
    sortDirection: '',
  },
};

const FiltersContext = createContext<FiltersContextInterface>({
  state: initialFilterState,
  dispatch: () => {},
  initialValues: initialFilterState,
});

interface FiltersContextProviderProps {
  initialValues: IFilterState;
  children: React.ReactNode;
  config: FiltersConfig;
  resetOn?: boolean;
}

const FiltersContextProvider: FC<FiltersContextProviderProps> = ({
  initialValues = initialFilterState,
  children,
  config,
  resetOn = false,
}) => {
  const [state, dispatch] = useReducer(filtersReducer, initialValues);

  useEffect(() => {
    dispatch(actions.clearFilters(initialValues)());
  }, [resetOn]);

  const memoizedValue = useMemo(
    () => ({ state, dispatch, initialValues, config }),
    [state, dispatch, initialValues, config],
  );

  return <FiltersContext.Provider value={memoizedValue}>{children}</FiltersContext.Provider>;
};

export { FiltersContext, FiltersContextProvider as default };
