import React, { PureComponent } from 'react';

import classNames from 'classnames';
import _get from 'lodash/get';
import genericErrorHandler from 'modules/app/components/genericErrorHandler/genericErrorHandler';
import { FILE_PARENTS, PRIVILEGE_KEYS, WORKFLOW_STATUSES } from 'modules/app/config/config';
import DynamicForm, { assigneeData } from 'modules/common/components/dynamicForm/dynamicForm.component';
import DynamicFormDetails from 'modules/common/components/dynamicFormDetails/dynamicFormDetails.component';
import FilesList from 'modules/common/components/filesList/filesListWeb.component';
import FindingsList from 'modules/common/components/findingsList/findingList.component';
import Loader from 'modules/common/components/loader/loader.component';
import withTabs from 'modules/common/hoc/withTabs/withTabs.hoc';
import NewWorkflowModel from 'modules/workflows/components/newWorkflow/newWorkflow.model';
import WorkflowDetailsFields from 'modules/workflows/components/workflowDetailsFields/workflowDetailsFields.component';
import WorkflowDetailsHeader from 'modules/workflows/components/workflowDetailsHeader/workflowDetailsHeader.component';
import PropTypes from 'prop-types';
import Acl from 'services/acl';

import { Fab, Tooltip } from '@material-ui/core';

import WorkflowDueDateDialog from '../workflowDueDateDialog/workflowDueDateDialog.component';
import WorkflowParticipation from '../workflowParticipants/workflowParticipants.component';

const tabsBase = {
  id: 'wf-overview',
  label: 'Overview',
};

const relatedFindingsTab = {
  id: 'wf-findings',
  label: 'Related Findings',
  hideTab: true,
};

const pariticipantTab = {
  id: 'wf-participants',
  label: 'Participants',
};

const getTabs = (showParticipants, showRelatedFindings) => {
  let base = [tabsBase];

  if (showRelatedFindings) {
    base = [...base, { ...relatedFindingsTab, hideTab: false }];
  } else {
    base = [...base, relatedFindingsTab];
  }

  if (showParticipants) {
    base = [...base, pariticipantTab];
  }

  return base;
};

class WorkflowDetails extends PureComponent {
  static canDelete(wbs, user, workflowUser) {
    return _get(user, 'id') === _get(workflowUser, 'id') || Acl.hasPrivilege(PRIVILEGE_KEYS.WORKFLOWS_DELETE, wbs);
  }

  constructor(props) {
    super(props);
    this.getWorkflowDetails = this.getWorkflowDetails.bind(this);
    this.onStartWorkflow = this.onStartWorkflow.bind(this);
    this.state = {
      disabledStartButton: false,
    };
  }

  componentDidMount() {
    this.getWorkflowDetails(true);
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params: prevParams },
    } = prevProps;

    const {
      createCrumb,
      match: { params, path },
    } = this.props;

    if (prevParams.workflowId !== params.workflowId) {
      this.getWorkflowDetails();
    }

    createCrumb(path, params);
  }

  componentWillUnmount() {
    const { clearData } = this.props;

    clearData();
  }

  getWorkflowDetails = async (getParticipants = false) => {
    const {
      getWorkflow,
      match: { params },
    } = this.props;

    await getWorkflow(params.workflowId, getParticipants);
  };

  onStartWorkflow = async () => {
    try {
      const {
        getWorkflow,
        startWorkflow,
        match: { params },
      } = this.props;

      this.setState({ disabledStartButton: true });
      await startWorkflow(params.workflowId);
      await getWorkflow(params.workflowId, true);
    } catch (err) {
      this.setState({ disabledStartButton: false });
      genericErrorHandler(err);
    }
  };

  render() {
    const { disabledStartButton } = this.state;
    const {
      isLoading,
      workflow,
      removeWorkflow,
      getWorkflow,
      setIsLoading,
      match: { params },
      user,
      participants,
      changeDueDate,
      createNc,
      currentWorkflowRole,
      tabIndex,
      renderTabs,
      invalidateWorkflow,
      generateNCReport,
      getNcReport,
    } = this.props;

    const filterParticipants = participants.filter((item) => item.role !== 'CONTRACTOR');
    const workflowDetails = new NewWorkflowModel(workflow).parseDetails();
    const isFrozen = _get(workflow, 'frozen');
    const canEdit = workflowDetails.status === WORKFLOW_STATUSES.created && !isFrozen;
    const canEditFiles =
      [WORKFLOW_STATUSES.created, WORKFLOW_STATUSES.started].indexOf(workflowDetails.status) >= 0 && !isFrozen;

    const wbsId = _get(workflow, 'wbs.id');
    const canDelete = WorkflowDetails.canDelete(wbsId, user, workflow.createdBy);

    const showRelatedFindings = !!(workflowDetails.findings && workflowDetails.findings.length);

    const detailsClasses = classNames('border-bottom mt-3', {
      'd-none': tabIndex !== 0,
      'd-block': tabIndex === 0,
    });

    const relatedFindingsClasses = classNames('mt-3', {
      'd-none': tabIndex !== 1,
      'd-block': tabIndex === 1,
    });

    const participantsClasses = classNames('mt-3', {
      'd-none': tabIndex !== 2,
      'd-block': tabIndex === 2,
    });

    const siteFacilityId = _get(workflowDetails, 'siteFacilityDetails.id');
    const isOnlyContractor = user.roles && user.roles.length === 1 && user.roles[0] === 'CONTRACTOR';

    return (
      <div id="workflow-details:div" className="d-flex flex-column workflow-details-component">
        <WorkflowDetailsHeader
          removeWorkflow={removeWorkflow}
          workflow={workflowDetails}
          canDelete={canDelete}
          canEdit={canEdit}
          getNcReport={getNcReport}
          invalidateWorkflow={invalidateWorkflow}
          isFrozen={isFrozen}
        />
        {renderTabs(getTabs(!isOnlyContractor, showRelatedFindings))}
        <div className={participantsClasses}>
          {wbsId && !isOnlyContractor && (
            <WorkflowParticipation
              data={filterParticipants}
              wbsId={wbsId}
              workflowId={params.workflowId}
              getWorkflow={getWorkflow}
              setIsLoading={setIsLoading}
              siteFacilityId={siteFacilityId}
            />
          )}
        </div>
        <div className={relatedFindingsClasses}>
          <FindingsList
            findings={workflow.findings}
            isContractor={isOnlyContractor} // For test change isOnlyContractor to true
            type={workflow.type}
          />
        </div>
        <div className={detailsClasses}>
          <WorkflowDetailsFields workflow={workflowDetails} />
          <FilesList
            title="Attachments from findings"
            id={params.workflowId}
            type={FILE_PARENTS.workflow}
            data={workflowDetails.findingAttachments}
            isLoading={isLoading}
            callbackFn={this.getWorkflowDetails}
            canEdit={false}
            showDropzone={false}
            groupedByMainSystem
            local
          />
          <FilesList
            title="Attachments"
            id={params.workflowId}
            type={FILE_PARENTS.workflow}
            data={workflowDetails.attachments}
            isLoading={isLoading}
            canEdit={canEdit}
            callbackFn={this.getWorkflowDetails}
          />
          {canEdit && (
            <Tooltip title={assigneeData(workflow.startWorkflowAssignee)}>
              <Fab
                id="start-workflow:button"
                puppet-data="start-workflow:button"
                className="mt-3 mb-5 float-right"
                size="medium"
                variant="extended"
                color="primary"
                onClick={this.onStartWorkflow}
                disabled={disabledStartButton}
              >
                {workflow.startWorkflowButtonLabel || 'Start Workflow'}
              </Fab>
            </Tooltip>
          )}
          {workflowDetails.workflowHistory && (
            <DynamicFormDetails
              workflowDetails={workflowDetails}
              data={workflowDetails.workflowHistory}
              workflowId={workflowDetails.id}
              callbackFn={this.getWorkflowDetails}
              generateNCReport={generateNCReport}
              canEdit={false}
            />
          )}
          {!isLoading && canEditFiles && workflowDetails.currentPart && (
            <DynamicForm
              workflowDetails={workflowDetails}
              getWorkflow={getWorkflow}
              setIsLoading={setIsLoading}
              callbackFn={this.getWorkflowDetails}
              generateNCReport={generateNCReport}
            />
          )}
        </div>
        {isLoading && <Loader fixed mask />}
        {isFrozen && currentWorkflowRole && (
          <WorkflowDueDateDialog
            changeDueDate={changeDueDate}
            createNc={createNc}
            workflowId={workflowDetails.id}
            isLoading={isLoading}
          />
        )}
      </div>
    );
  }
}

WorkflowDetails.defaultProps = {
  workflow: {},
  participants: [],
};

WorkflowDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  workflow: PropTypes.objectOf(PropTypes.shape),
  getWorkflow: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  removeWorkflow: PropTypes.func.isRequired,
  invalidateWorkflow: PropTypes.func.isRequired,
  renderTabs: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.shape).isRequired,
  setIsLoading: PropTypes.func.isRequired,
  createCrumb: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.shape).isRequired,
  participants: PropTypes.arrayOf(PropTypes.shape),
  generateNCReport: PropTypes.func.isRequired,
  changeDueDate: PropTypes.func.isRequired,
  createNc: PropTypes.func.isRequired,
  currentWorkflowRole: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired,
  getNcReport: PropTypes.func.isRequired,
};

export default withTabs(WorkflowDetails);
