import React, { PureComponent, Fragment } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button, Fab, IconButton, Icon, List, ListItem, Typography, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { INSPECTION_STATUSES } from '../../../app/config/config';
import SignModal from './signModal.component';

const styles = (theme) => ({
  button: {
    color: theme.palette.secondary.light,
  },
});

class Sign extends PureComponent {
  state = {
    open: false,
    signs: {},
  };

  startSigning = (e) => {
    const {
      currentTarget: { id },
    } = e;

    this.setState({
      open: true,
      selectedIndex: id,
    });
  };

  cancelSingning = () => {
    this.setState({
      open: false,
      selectedIndex: null,
    });
  };

  saveSign = (data) => {
    this.setState((prev) => {
      let signs;

      if (!data) {
        const { ...rest } = prev.signs;
        signs = rest;
      } else {
        signs = { ...prev.signs, [prev.selectedIndex]: data };
      }

      return {
        open: false,
        signs,
        selectedIndex: null,
      };
    });
  };

  handleFinish = () => {
    const { setInspectionStatus, inspection, setSignMode } = this.props;
    const { signs } = this.state;

    setInspectionStatus(inspection.localId || inspection.id, INSPECTION_STATUSES.finished, null, Object.values(signs));
    setSignMode(false);
  };

  render() {
    const { inspectors, inspection, classes } = this.props;
    const { open, selectedIndex, signs } = this.state;

    return (
      <>
        <List className="pl-3">
          {inspectors.map((inspector, index) => (
            <Fragment key={inspector.id}>
              <ListItem
                className={classNames('justify-content-between pl-0', {
                  'py-0': !!signs[index],
                  'py-2': !signs[index],
                })}
              >
                <Typography variant="subtitle1" color={signs[index] ? 'textSecondary' : undefined}>
                  {inspector.fullName}
                </Typography>
                <div>
                  {!signs[index] && (
                    <Button
                      id={index}
                      onClick={this.startSigning}
                      className={classes.button}
                      puppet-data="sign-inspection"
                    >
                      Sign
                    </Button>
                  )}
                  {!!signs[index] && (
                    <IconButton id={index} onClick={this.startSigning}>
                      <Icon>check</Icon>
                    </IconButton>
                  )}
                </div>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
        <SignModal
          inspector={inspectors[selectedIndex]}
          inspection={inspection}
          signData={signs[selectedIndex]}
          open={open}
          onClose={this.cancelSingning}
          onSave={this.saveSign}
        />
        <Fab
          id="finish-inspection:button"
          size="small"
          variant="extended"
          color="primary"
          className="additional-button"
          puppet-data="finish-inspection:button"
          disabled={Object.keys(signs).length !== inspectors.length}
          onClick={this.handleFinish}
        >
          Finish
        </Fab>
      </>
    );
  }
}

Sign.propTypes = {
  inspectors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
    }),
  ),
  inspection: PropTypes.shape({
    title: PropTypes.string,
    wbs: PropTypes.string,
  }),
  setInspectionStatus: PropTypes.func.isRequired,
  setSignMode: PropTypes.func.isRequired,
};

Sign.defaultProps = {
  inspectors: [],
  inspection: {},
};

export default withStyles(styles)(Sign);
