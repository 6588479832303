import { uniqBy } from 'lodash/array';

import {
  CLEAR_DATA,
  MARK_ATTACHMENT_AS_MAIN,
  SET_INSPECTION_FINDINGS,
  SET_INSPECTION,
  SET_INVALID_FINDINGS,
  SET_IS_LOADING,
  SET_SIGN_MODE,
  UPDATE_FINDING_AFTER_SYNC,
  SET_TODOS,
} from './inspectionDetails.actions';

const initialState = {
  data: {
    wbs: {
      name: '',
    },
    siteFacility: {
      value: '',
    },
    functionalLocationCode: '',
    deadline: '',
    leadInspector: {},
    title: '',
  },
  todos: [],
  isLoading: false,
  signMode: false,
  inspectionFindings: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_INSPECTION:
      return {
        ...state,
        data: payload,
        isLoading: false,
      };
    case SET_INVALID_FINDINGS:
      return {
        ...state,
        data: {
          ...state.data,
          findings: payload,
        },
      };
    case MARK_ATTACHMENT_AS_MAIN:
      return {
        ...state,
        data: {
          ...state.data,
          findings: payload,
        },
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    case SET_SIGN_MODE:
      return {
        ...state,
        signMode: payload,
      };

    case SET_INSPECTION_FINDINGS:
      return {
        ...state,
        inspectionFindings: payload,
      };

    case UPDATE_FINDING_AFTER_SYNC:
      return {
        ...state,
        data: {
          ...state.data,
          findings: uniqBy([...state.data.findings.filter((f) => f.id !== payload.frontendId), payload], 'id'),
        },
      };

    case SET_TODOS:
      return {
        ...state,
        todos: payload,
      };
    default:
      return state;
  }
};
