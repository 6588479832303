import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { AppBar, Toolbar, IconButton, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { GlobalActionClose, GlobalActionDone } from '../../../../assets/icons/index';

const styles = (theme) => ({
  checkIcon: {
    fill: theme.palette.secondary.light,
  },
});

class SaveHeader extends PureComponent {
  handleCloseClick = () => {
    const { history, onClose } = this.props;

    if (onClose) {
      onClose();
    } else {
      history.goBack();
    }
  };

  render() {
    const { classes, disableSave, title, onSave, withSave } = this.props;

    return (
      <AppBar position="fixed" className="header-component save-header-component">
        <Toolbar>
          <IconButton
            id="close:button"
            className="left-button"
            puppet-data="header-button:close"
            aria-label="Close"
            onClick={this.handleCloseClick}
          >
            <Icon className="svg-medium">
              <GlobalActionClose className="icon-dark" />
            </Icon>
          </IconButton>
          <Typography className="title" puppet-data={`mobile-header:${title}`} noWrap>
            {title}
          </Typography>
          <div className="grow" />
          {withSave && (
            <IconButton
              id="save:button"
              className="right-button"
              aria-label="Save"
              puppet-data="header-button:save"
              onClick={onSave}
              disabled={disableSave}
            >
              <Icon className={`${classes.checkIcon} svg-medium`}>
                <GlobalActionDone />
              </Icon>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

SaveHeader.propTypes = {
  title: PropTypes.string,
  withSave: PropTypes.bool,
  disableSave: PropTypes.bool,
  onSave: PropTypes.func,
  history: PropTypes.object,
  onClose: PropTypes.func,
};

SaveHeader.defaultProps = {
  title: '',
  withSave: true,
  disableSave: false,
};

export default withStyles(styles)(SaveHeader);
