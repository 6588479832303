import { makeStyles } from '@material-ui/core';

const useTableStyles = makeStyles((theme) => ({
  cell: {
    maxWidth: '15rem',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textAlign: 'center',

    '&:first-child': {
      paddingLeft: '0.25rem',
    },
  },
  headerCell: {
    maxWidth: '15rem',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textAlign: 'left',

    '&:first-child': {
      textAlign: 'left',
      paddingLeft: '0.25rem',
    },

    '&:last-of-type': {
      textAlign: 'right',
    },
  },
  sortIcon: {
    color: 'grey',
    cursor: 'initial',

    '&.active': {
      color: 'steelblue',
    },
  },
  action: {
    color: theme.palette.secondary.light,
    marginLeft: '10px',
  },
  content: {
    padding: '0 0 50px 0',
  },
}));

export { useTableStyles };
