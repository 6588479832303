import React from 'react';

import { DATETIME_FORMAT, DATE_FORMAT } from 'modules/app/config/config';
import { getActionTaker } from 'modules/app/helpers/utils';
import moment from 'moment';

import { Typography } from '@material-ui/core';

import { SEVERITY_OPTIONS } from '../newWorkflow/newWorkflow.model';

const TABLE_CONFIG = {
  columns: [
    {
      caption: 'Park/Package',
      dataField: 'wbs',
      sortField: 'wbs',
      hidable: true,
      align: 'left',
    },
    {
      caption: 'Workflow Number',
      dataField: 'workflowNumber',
      sortField: 'workflowNumber',
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Failure Mode',
      dataField: 'failureMode',
      sortField: 'failureMode.description',
      hidable: true,
      align: 'center',
    },
    { caption: 'Reference', dataField: 'reference', sortField: 'reference', hidable: true, align: 'center' },
    {
      caption: 'Title',
      dataField: 'title',
      sortField: 'title',
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Severity',
      dataField: 'severity',
      sortField: 'severity',
      renderCell: (_, item) => {
        const severityLabel = SEVERITY_OPTIONS.get(item.severity)?.label;
        return (
          <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
            {severityLabel || '-'}
          </Typography>
        );
      },
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Site / Facility',
      dataField: 'siteFacility',
      sortField: 'siteFacility',
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Last update',
      dataField: 'lastActivityDate',
      sortField: 'lastActivityDate',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
          {moment(item.lastActivityDate).format(DATETIME_FORMAT)}
        </Typography>
      ),
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Deadline',
      dataField: 'currentStepDueDate',
      sortField: 'currentStepDueDate',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
          {item.currentStepDueDate ? moment(item.currentStepDueDate).format(DATE_FORMAT) : '—'}
        </Typography>
      ),
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Actionee',
      dataField: 'modifiedBy',
      sortField: 'modifiedBy',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
          {getActionTaker(item)}
        </Typography>
      ),
      hidable: true,
      align: 'center',
    },
    {
      caption: 'Status',
      dataField: 'status',
      sortField: 'status',
      renderCell: (_, item) => (
        <Typography color="textSecondary" variant="caption" style={{ width: '100%', textAlign: 'inherit' }}>
          {item.status}
        </Typography>
      ),
      hidable: true,
      align: 'right',
    },
  ],
  noItemsFoundText: 'No workflows found',
};

export { TABLE_CONFIG as default };
