import { SET_WORK_PACKAGES, SET_IS_LOADING } from './wbsesTree.actions';

const initialState = {
  items: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_WORK_PACKAGES:
      return {
        ...state,
        items: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
