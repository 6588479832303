import React from 'react';

import { GlobalActionBack } from 'assets/icons';
import { FILTER_COOKIE_NAMES, GUTTER_SIZE, SORT_COOKIE_NAMES, SORT_DIRECTION } from 'modules/app/config/config';
import Loader from 'modules/common/components/loader/loader.component';
import InspectionItem from 'modules/inspections/components/inspectionItem/inspectionItem.component';
import PropTypes from 'prop-types';

import { IconButton, TablePagination, Table, TableFooter, TableRow, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import withInspections from './inspections.hoc';

const styles = (theme) => ({
  addButton: {
    position: 'fixed',
    backgroundColor: theme.palette.secondary.light,
    bottom: GUTTER_SIZE,
    right: GUTTER_SIZE,
  },
  addIcon: {
    marginLeft: -6,
    marginRight: 6,
  },
  drawerIcon: {
    color: theme.palette.text.secondary,
    fontSize: 45,
    marginBottom: 8,
  },
  action: {
    color: theme.palette.secondary.light,
  },
  content: {
    padding: '0 0 50px 0',
  },
});

const Inspections = ({
  inspections,
  inspectionsTotal,
  isLoading,
  match: { params },
  pagination: { size, page, setPageSize, setPageNumber },
  setSorting,
  state,
}) => {
  const handleChangePageSize = ({ target: { value } }) => {
    setPageSize(value);
  };

  const handleChangePageNumber = (e, newPage) => {
    setPageNumber(newPage);
  };

  const onSortDirectionChangeClick = (ev) => {
    const { currentTarget } = ev;

    setSorting(currentTarget.id);
  };

  if (isLoading) {
    return <Loader />;
  }

  const renderInspections = inspections.map((inspection, index) => (
    <InspectionItem
      className={`w-100 ${index === 0 ? 'mt-1' : 'mt-4'}`}
      key={inspection.id}
      puppet-data={inspection.id}
      inspection={inspection}
      index={index}
      idKey="id"
      stateFilters={state}
      params={params}
    />
  ));

  return (
    <div id="inspection-list" className="inspections-list-web-component flex-fill">
      <div className="row no-gutters align-items-center justify-content-end pr-5 pl-5 pt-2 pb-2">
        <div className="d-flex align-items-center">
          {state.sortDirection === SORT_DIRECTION.ASC && (
            <Typography className="cursor-pointer pr-1" color="textSecondary" variant="body2">
              Latest activity: Oldest first
            </Typography>
          )}
          {state.sortDirection === SORT_DIRECTION.DESC && (
            <Typography className="cursor-pointer pr-1" color="textSecondary" variant="body2">
              Latest activity: Newest first
            </Typography>
          )}
          <IconButton puppet-data="inspections:sort" onClick={onSortDirectionChangeClick}>
            {state.sortDirection === SORT_DIRECTION.ASC && <GlobalActionBack className="icon-dark icon-asc" />}
            {state.sortDirection === SORT_DIRECTION.DESC && <GlobalActionBack className="icon-dark icon-desc" />}
          </IconButton>
        </div>
      </div>
      <div className="px-5">{renderInspections}</div>
      {inspections.length > 0 && (
        <div className="px-5 inspections-list-web-component__pagination">
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={inspectionsTotal}
                  rowsPerPage={size}
                  page={page}
                  onChangePage={handleChangePageNumber}
                  onChangeRowsPerPage={handleChangePageSize}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      )}
    </div>
  );
};

Inspections.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  inspections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  inspectionsTotal: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.objectOf(PropTypes.shape).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    setPageNumber: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    size: PropTypes.number,
  }).isRequired,
  setSorting: PropTypes.func.isRequired,
};

export default withInspections(withStyles(styles)(Inspections));
