import { connect } from 'react-redux';

import { createCrumb } from 'modules/app/components/app/app.actions';

import { getUser, clearData } from './user.actions';
import User from './user.component';

const mapStateToProps = (state) => ({
  isLoading: state.user.isLoading,
  user: state.user.data,
  roles: state.user.roles,
});

const mapDispatchToProps = {
  getUser,
  clearData,
  createCrumb,
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
