import React, { PureComponent } from 'react';

import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';

const updateWithHours = (datepickerMoment) => {
  if (datepickerMoment) {
    const hour = moment().get('hour');
    const date = datepickerMoment.clone();
    return date.set('hour', hour);
  }
  return datepickerMoment;
};

class DatepickerWrapper extends PureComponent {
  handleChange = (value) => {
    const { onChange, name, setError } = this.props;
    const updatedDate = updateWithHours(value);

    if (name) {
      onChange(name, updatedDate ? updatedDate.toISOString() : '');
    } else {
      onChange(updatedDate);
    }
    if (setError) {
      setError(null);
    }
  };

  handleError = (_, error) => {
    const { setError } = this.props;

    if (setError) {
      setError(error);
    }
  };

  render() {
    const { onChange, onError, setError, maxDate, ...rest } = this.props;

    const commonMaxDate = maxDate || moment().add(5, 'year').toDate();

    return <DatePicker onChange={this.handleChange} onError={this.handleError} maxDate={commonMaxDate} {...rest} />;
  }
}

export default DatepickerWrapper;
