import React from 'react';
import { useDispatch } from 'react-redux';

import { IconButton, Paper, Typography, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Add } from '@material-ui/icons';

import { chooseTodo } from '../inspectionDetails.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

const InspectionDetailsTodosListItem = ({ todo, inspectionId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChooseTodo = () => {
    dispatch(chooseTodo(todo.id, inspectionId));
  };

  return (
    <Paper
      variant="outlined"
      square
      style={{ marginBottom: '10px', padding: '10px', boxSizing: 'border-box' }}
      className="todo-list-item"
    >
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={4} className={classes.paper}>
          <Typography variant="body2" color="textPrimary">
            Description
          </Typography>
          <Typography>{todo.description}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.paper}>
          <Typography variant="body2" color="textPrimary">
            Main System
          </Typography>
          <Typography>{todo.mainSystem?.description || '—'}</Typography>
          <Typography>{todo.mainSystem?.mainSystemCode || '—'}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.paper}>
          <Typography variant="body2" color="textPrimary">
            Sub System
          </Typography>
          <Typography>{todo.subSystem?.description || '—'}</Typography>
          <Typography>{todo.subSystem?.subSystemCode || '—'}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.paper}>
          <Typography variant="body2" color="textPrimary">
            Failure mode
          </Typography>
          <Typography>{todo.failureMode?.description || '—'}</Typography>
        </Grid>
      </Grid>

      <IconButton onClick={handleChooseTodo}>
        <Add style={{ color: '#3ab312' }} />
      </IconButton>
    </Paper>
  );
};

export { InspectionDetailsTodosListItem };
