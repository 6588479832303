import { IFilterState } from './types';

const namespace = '[FILTERS]';

export const SET_FILTER = `${namespace} SET_FILTER`;
export const REPLACE_FILTER = `${namespace} REPLACE_FILTER`;
export const SET_ADDITIONAL_VALUE = `${namespace} SET_ADDITIONAL_VALUE`;
export const REMOVE_FILTER = `${namespace} REMOVE_FILTER`;
export const REMOVE_FILTER_FAMILY = `${namespace} REMOVE_FILTER_FAMILY`;
export const CLEAR_FILTERS = `${namespace} CLEAR_FILTERS`;

export const initialState = {
  filters: {},
};
interface SetFilterAction {
  type: typeof SET_FILTER;
  payload: {
    filterType: string;
    filterIds: string[];
  };
}

interface ReplaceFilterAction {
  type: typeof REPLACE_FILTER;
  payload: {
    filterType: string;
    filterIds: string[];
  };
}

interface SetAdditionalValueAction<T> {
  type: typeof SET_ADDITIONAL_VALUE;
  payload: {
    key: string;
    val: T;
  };
}

interface RemoveFilterAction {
  type: typeof REMOVE_FILTER;
  payload: {
    filterType: string;
    filterIds: string[];
  };
}

interface RemoveFilterFamilyAction {
  type: typeof REMOVE_FILTER_FAMILY;
  payload: {
    filterType: string;
  };
}

interface ClearFiltersAction {
  type: typeof CLEAR_FILTERS;
  payload: typeof initialState;
}

export type FilterActionTypes =
  | SetFilterAction
  | ReplaceFilterAction
  | SetAdditionalValueAction<any>
  | RemoveFilterAction
  | RemoveFilterFamilyAction
  | ClearFiltersAction;

const setFilter = (filterType: string, filterIds: string[]): FilterActionTypes => ({
  type: SET_FILTER,
  payload: {
    filterType,
    filterIds,
  },
});

const replaceFilter = (filterType: string, filterIds: string[]): FilterActionTypes => ({
  type: REPLACE_FILTER,
  payload: {
    filterType,
    filterIds,
  },
});

const setAdditionalValue = <T>(key: string, val: T): FilterActionTypes => ({
  type: SET_ADDITIONAL_VALUE,
  payload: {
    key,
    val,
  },
});

const removeFilter = (filterType: string, filterIds: string[]): FilterActionTypes => ({
  type: REMOVE_FILTER,
  payload: {
    filterType,
    filterIds,
  },
});

const removeFilterFamily = (filterType: string): FilterActionTypes => ({
  type: REMOVE_FILTER_FAMILY,
  payload: {
    filterType,
  },
});

const clearFilters = (initialFilters: typeof initialState) => (): FilterActionTypes => ({
  type: CLEAR_FILTERS,
  payload: initialFilters,
});

export const actions = {
  setFilter,
  setAdditionalValue,
  removeFilter,
  removeFilterFamily,
  replaceFilter,
  clearFilters,
};

export default (state: IFilterState, action: FilterActionTypes) => {
  const { type, payload } = action;

  switch (type) {
    case SET_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.filterType]: [...new Set([...state.filters[payload.filterType], ...payload.filterIds])],
        },
      };
    }

    case REPLACE_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.filterType]: [...payload.filterIds],
        },
      };
    }

    case SET_ADDITIONAL_VALUE: {
      return {
        ...state,
        [payload.key]: payload.val,
      };
    }

    case REMOVE_FILTER: {
      const newFilters = state.filters[payload.filterType].filter((f) => !payload.filterIds.includes(f));

      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.filterType]: newFilters,
        },
      };
    }

    case REMOVE_FILTER_FAMILY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.filterType]: [],
        },
      };
    }

    case CLEAR_FILTERS: {
      return payload;
    }

    default:
      return state;
  }
};
