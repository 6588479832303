import React, { FC, useCallback, useContext, useMemo } from 'react';

import { camelCase } from 'modules/app/helpers/utils';
import FilteringTree from 'modules/common/components/filteringTree/filteringTree';

import { FiltersContext } from '../../../filters.context';
import { actions } from '../../../filters.reducer';
import { IFilter } from '../../../types';

interface SwitchActiveTabProps {
  activeTab: number;
  nodes: { [key: string]: any };
}

const SwitchActiveTab: FC<SwitchActiveTabProps> = ({ activeTab, nodes }) => {
  const { state, dispatch, config } = useContext(FiltersContext);

  const currentFilterKey = Object.keys(state.filters).sort((a, b) => {
    if (config.filtersOrderIndex) {
      return config.filtersOrderIndex[a] - config.filtersOrderIndex[b];
    }
    return a.localeCompare(b);
  })[activeTab];

  const currentTabConfig = config.filters[currentFilterKey];

  const currentCategory =
    currentTabConfig?.caption &&
    Object.keys(nodes).find((item) => item.toLowerCase() === camelCase(currentTabConfig?.caption).toLowerCase());

  const checkFunction = (filter: { id: string; label?: string }) => {
    const checked = state.filters[currentFilterKey]?.includes(filter.id);

    return !!checked;
  };

  const handleSelect = useCallback(
    (filter: string, selectedNode: { id: string; parents: string[] }) => {
      const isActive = checkFunction(selectedNode);
      const isSingle = config.filters[currentFilterKey].singleChoice;

      const selectedNodeFilter = nodes[currentCategory].find((node) => node.id === selectedNode.id);

      let newFilters = [selectedNode.id];

      const selectedNodeFilterHasChildren =
        selectedNodeFilter && Array.isArray(selectedNodeFilter.children) && selectedNodeFilter.children.length;

      if (selectedNodeFilterHasChildren) {
        const childIds = selectedNodeFilter.children.map((childNode) => childNode.id);
        newFilters = [...newFilters, ...childIds];
      }

      if (config.filters[currentFilterKey].onChangeClear?.length > 0) {
        for (const toBeClearedKey of config.filters[currentFilterKey].onChangeClear) {
          dispatch(actions.removeFilterFamily(toBeClearedKey));
        }
      }

      if (isActive) {
        const parentId = selectedNode.parents[0];

        newFilters = [...newFilters, parentId];
        dispatch(actions.removeFilter(currentFilterKey, newFilters));
        return;
      }

      if (!isSingle) {
        dispatch(actions.setFilter(currentFilterKey, newFilters));
        return;
      }

      dispatch(actions.replaceFilter(currentFilterKey, newFilters));
    },
    [state.filters, currentFilterKey, nodes, currentCategory, config.filters],
  );

  const currentNodes = useMemo(() => {
    const findCurrentDeps = (nodes, filterVal: IFilter) => {
      const found = [];

      nodes.forEach((child) => {
        if (filterVal.includes(child.id)) {
          found.push(child);
        }

        if (Array.isArray(child.children) && child.children.length) {
          found.push(...findCurrentDeps(child.children, filterVal));
        }
      });

      return found;
    };

    if (config.filters[currentFilterKey]?.dependsOn) {
      const findDependentFilter = findCurrentDeps(
        nodes[config.filters[currentFilterKey]?.dependsOn],
        state.filters[config.filters[currentFilterKey]?.dependsOn],
      );

      return nodes[currentCategory].filter((node) => {
        const filterVal = findDependentFilter.flatMap(
          (filter) => filter[config.filters[currentFilterKey].dependentFilter],
        );

        return filterVal.includes(node[config.filters[currentFilterKey].dependentFilterKey]);
      });
    }

    return nodes[currentCategory];
  }, [currentCategory, currentFilterKey, config.filters, nodes, state.filters]);

  return (
    <FilteringTree
      nodes={currentNodes}
      withCheck
      fieldName={currentFilterKey}
      searchPosition="top"
      onSelect={handleSelect}
      searchPlaceholderText={`Search for ${config.chipLabels[currentFilterKey] || currentTabConfig?.caption}`}
      checkFunction={checkFunction}
      primaryTextField={currentTabConfig?.primaryText}
      secondaryTextField={currentTabConfig?.secondaryText}
      alternativePrimaryTextField="name"
    />
  );
};

export default SwitchActiveTab;
