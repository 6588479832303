import React from 'react';

const NonconformityStatusCarRequested = (props) => (
  <svg data-name="Layer 1" viewBox="0 0 24 24" {...props}>
    <path d="M12 1a11 11 0 0 0-10.8 9h2A9 9 0 1 1 14 20.8v2A11 11 0 0 0 12 1z" />
    <path d="M12 5a7 7 0 0 0-6.7 5h2.1A5.05 5.05 0 0 1 12 7a5 5 0 0 1 5 5 5.05 5.05 0 0 1-3 4.6v2.1A7 7 0 0 0 12 5z" />
  </svg>
);

export default NonconformityStatusCarRequested;
